import OrganizationShow from '../components/organization/Show.vue'
import OrganizationHome from '../components/organization/view/home'
import OrganizationData from '../components/organization/view/data'
import OrganizationResources from '../components/organization/view/resources'
import OrganizationForum from '../components/organization/view/forum'
import OrganizationAnalysis from '../components/organization/view/analysis'
import OrganizationResults from '../components/organization/view/results'
import OrganizationEdit from '../components/organization/view/edit/organizationEdit.vue'

export default [
  {
    name: 'OrganizationShow', path: '/organizations/:name', component: OrganizationShow, meta: { requiresAuth: false }, redirect: {name: 'OrganizationHome'},
    children: [
      {
        path: '',
        name: 'OrganizationHome',
        component: OrganizationHome
      },
      {
        path: 'results',
        name: 'OrganizationResults',
        component: OrganizationResults,
      },
      {
        path: 'data',
        name: 'OrganizationData',
        component: OrganizationData,
      },
      {
        path: 'resources',
        name: 'OrganizationResources',
        component: OrganizationResources,
      },
      {
        path: 'forum',
        name: 'OrganizationForum',
        component: OrganizationForum,
        props: true,
      },
      {
        path: 'analysis',
        name: 'OrganizationAnalysis',
        component: OrganizationAnalysis,
      },
      {
        path: 'edit',
        name: 'OrganizationEdit',
        component: OrganizationEdit,
        meta: { requiresAuth: true }
      },
      // {
      //   path: 'members',
      //   name: 'OrganizationMembers',
      //   component: OrganizationMembers,
      // },
      {
        path: '*',
        name: 'Organization404',
        component: OrganizationHome
      },
    ]
  },
]
