<template>
  <form @submit.prevent="handleSubmit(item)">
    <div class="form-group">
      <label
        for="user_projects"
        class="form-control-label">projects</label>
      <input
        id="user_projects"
        v-model="item.projects"
        :class="['form-control', isInvalid('projects') ? 'is-invalid' : '']"
        type="text"
        placeholder="The projects this user created."
        @input="handleUpdateField('projects', $event.target.value)">
      <div
        v-if="isInvalid('projects')"
        class="invalid-feedback">{{ violations.projects }}</div>
    </div>

    <button
      type="submit"
      class="btn btn-success">Submit</button>
  </form>
</template>

<script>
export default {
  props: {
    handleSubmit: {
      type: Function,
      required: true
    },

    handleUpdateField: {
      type: Function,
      required: true
    },

    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    // eslint-disable-next-line
    item () {
      return this.initialValues || this.values
    },

    violations () {
      return this.errors || {}
    }
  },

  methods: {
    isInvalid (key) {
      return Object.keys(this.violations).length > 0 && this.violations[key]
    }
  }
}
</script>
