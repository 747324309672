<template>
  <b-row class="row mb-2">
    <b-col class="d-none d-md-table-cell col-md-4 col-lg-3 mb-2">
      <i class="fas fa-calendar d-sm-inline d-md-none" aria-hidden="true"></i>
      Observation Date:
    </b-col><!-- col-md-8 col-lg-5 mb-2 -->
    <b-col class="col-md-8 col-lg-9 mb-2" v-if="!showEditDate"><!-- col-md-8 col-lg-5 mb-2 -->
      {{ dateTimeString }}
      <b-button
        v-if="canEdit"
        class="ml-3"
        variant="secondary"
        size="sm"
        title="Edit Observation Date"
        alt="Edit Observation Date"
        @click="editDateStart"
      >
        <font-awesome-icon icon="pencil-alt" size="1x" class="mx-auto" />
        Edit Date
      </b-button>
    </b-col>
    <div class="col-md-8 col-lg-4" v-else>
      <div class="input-group">
        <date-picker
          :name="'date-observation'"
          v-model="dateTimeValue"
          :config="pickerOptions"
          class="form-input"
        ></date-picker>
        <div class="input-group-append" v-if="!isLoading">
          <button
            class="btn btn-success"
            type="button"
            title="Update"
            alt="Update"
            @click="editDateSave"
          >
            Save
          </button>
          <button
            class="btn btn-outline-danger"
            type="button"
            title="Cancel Editing"
            alt="Cancel Editing"
            @click="editDateCancel"
          >
            Cancel
          </button>
        </div>
        <div class="input-group-append" v-else>
          <span class="input-group-text">
            <i class="fa fa-spin fa-circle-notch mr-2"></i>Saving
          </span>
        </div>
      </div>
    </div>
  </b-row>
</template>

<script>
import DatePicker from "vue-bootstrap-datetimepicker";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    DatePicker,
  },
  props: {
    dateTime: {
      type: String,
      required: true,
    },
    dateFormat: {
      type: String,
      default: "YYYY/MM/DD h:mm:ss A",
    },
    observationId: {
      type: String,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showEditDate: false,
      dateTimeValue: null,
      pickerOptions: {
        format: this.dateFormat,
        showClose: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      isLoading: "observation/show/isLoading",
      error: "observation/show/error",
    }),
    dateTimeLocal() {
      // observed ats are always shown in the local time of the user
      // & stored in the database in that raw format.
      // When the API returns these values, it erronously adds +000 for UTC when it is not
      return moment(this.dateTime.split("+")[0]);
    },
    dateTimeString() {
      return this.dateTimeLocal.format(this.dateFormat);
    },
  },
  created() {
    this.dateTimeValue = this.dateTimeString;
  },
  watch: {
    dateTimeString: function (dateTimeNew) {
      this.dateTimeValue = dateTimeNew;
    },
  },
  methods: {
    ...mapActions({
      updateDate: "observation/show/updateObservationDate",
    }),
    editDateStart() {
      this.showEditDate = true;
    },
    editDateSave() {
      const dateTimeUpdated = moment(
        this.dateTimeValue,
        this.pickerOptions.format
      ).format("YYYY-MM-DDTHH:mm:ss");
      this.updateDate({ dateTimeUpdated, id: this.observationId }).then(() => {
        if (this.error.length === 0) {
          this.showEditDate = false;
        }
      });
    },
    editDateCancel() {
      this.showEditDate = false;
      this.dateTimeValue = this.dateTimeString;
    },
  },
};
</script>

<style>
</style>
