import SubmissionError from '../../../../error/SubmissionError'
import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const create = ({ commit }, values) => {
  commit(types.DATASHEET_CREATE_SET_ERROR, '')
  commit(types.DATASHEET_CREATE_TOGGLE_LOADING)

  return fetch('/observations', {
    method: 'POST',
    body: JSON.stringify(values)
  })
    .then(response => {
      commit(types.DATASHEET_CREATE_TOGGLE_LOADING)

      return response.json()
    })
    .then(data => {
      commit(types.OBSERVATION_CREATE_SET_CREATED, data)
    })
    .catch((e) => {
      commit(types.DATASHEET_CREATE_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.DATASHEET_CREATE_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.DATASHEET_CREATE_SET_ERROR, e.errors._error)
        return
      }

      commit(types.DATASHEET_CREATE_SET_ERROR, e.message)
    })
}


export const retrieveMembersAssignedLocations = ({ commit }, memberId) => {
  commit(types.MEMBERS_ASSIGNED_LOCATIONS_RETRIEVE_TOGGLE_LOADING)

  return fetch(memberId + '/members_datasheets_locations')
    .then(response => response.json())
    .then((data) => {
      commit(types.MEMBERS_ASSIGNED_LOCATIONS_RETRIEVE_TOGGLE_LOADING)
      commit(types.MEMBERS_ASSIGNED_LOCATIONS_RETRIEVE_SET_RETRIEVED, data['hydra:member'])
    })
    .catch((e) => {
      commit(types.MEMBERS_ASSIGNED_LOCATIONS_RETRIEVE_TOGGLE_LOADING)
      commit(types.MEMBERS_ASSIGNED_LOCATIONS_RETRIEVE_SET_ERROR, e.message)
    })
}


export const createLocation = ({ commit }, values) => {

  commit(types.LOCATION_CREATE_SET_ERROR, '')
  commit(types.LOCATION_CREATE_TOGGLE_LOADING)

  return fetch('/areas', {
    method: 'POST',
    body: JSON.stringify(values)
  })
    .then((response) => {
      return response.json()
    })
    .then((data) => {
      commit(types.LOCATION_CREATE_TOGGLE_LOADING)
      commit(types.LOCATION_CREATE_SET_CREATED, data)
      return data
    })
    .catch((e) => {
      commit(types.LOCATION_CREATE_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.LOCATION_CREATE_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.LOCATION_CREATE_SET_ERROR, e.errors._error)
        return
      }

      commit(types.LOCATION_CREATE_SET_ERROR, e.message)
    })
}
