import SubmissionError from '../../../../error/SubmissionError'
import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const create = ({ dispatch, commit, rootState }, value) => {
  commit(types.PROJECT_CREATE_SET_ERROR, '')
  commit(types.PROJECT_CREATE_TOGGLE_LOADING)

  let values = Object.assign({}, value.projectData);

  if (typeof values.picture != 'undefined' && values.picture != null && typeof values.picture['@id'] != 'undefined') {
    values.picture = values.picture['@id']
  }

  if (typeof values.bannerPicture != 'undefined' && values.bannerPicture != null && typeof values.bannerPicture['@id'] != 'undefined') {
    values.bannerPicture = values.bannerPicture['@id']
  }

  if (values.urlField && values.urlField !== 'undefined' && values.urlField !== null) {
    const urlParts = values.urlField.split('/');
    values.urlField = urlParts[urlParts.length - 1];
  }

  return fetch('/projects', { method: 'POST', body: JSON.stringify(values) })
    .then((response) => {
      commit(types.PROJECT_CREATE_TOGGLE_LOADING)
      return response.json()
    })
    .then((data) => {
      commit(types.PROJECT_CREATE_SET_CREATED, data)
      dispatch('user/profile/fetchSession', null, { root: true })
      // If we should create the SciStarter project...
      if ( ! data.isDraft && data.isSciStarter === true ) {
        dispatch('project/show/createSciStarterProject', data.id, { root: true })
      }
      // Send affiliation requests
      value.organizations.forEach(req => {
        dispatch('organization_projects/get/createAffiliation',
          { organization: req,
            project: data['@id'],
            requester: rootState.user.profile.sessionData['@id']
          }, {root:true})
      })
    })
    .catch((e) => {
      commit(types.PROJECT_CREATE_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.PROJECT_CREATE_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.PROJECT_CREATE_SET_ERROR, e.errors._error)
        return
      }

      commit(types.PROJECT_CREATE_SET_ERROR, e.message)
    })
}

export const setPictureProfile = ({ commit }, picture) => {
  commit(types.PROJECT_SET_PICTURE_TOGGLE_LOADING, picture)
}

export const setBannerPicture = ({ commit }, picture) => {
  commit(types.PROJECT_SET_BANNER_TOGGLE_LOADING, picture)
}

export const setError = ({ commit }, error) => {
  commit(types.PROJECT_CREATE_SET_ERROR, error)
}

export const reset = ({ commit }) => {
  commit(types.PROJECT_CREATE_RESET)
}
