import fetch from '../../../../utils/fetch'
import SubmissionError from "../../../../error/SubmissionError";

const initialState = {
  isLoading: false,
  observations: null,
  error: null,
}

const getters = {
  observations(state) { return state.observations },
  isLoading(state) { return state.isLoading },
  error(state) { return state.error },
}

const actions = {
  /**
   * @function getObservations- retrieve from API observations
   * @param commit
   * @param data
   * @param organizationName {String}
   * @returns {Promise<T | never> | * | *}
   */
  get({ commit }, data) {
    commit(types.SET_ORG_OBSERVATIONS_LOADING);
    let urlParams = data.urlParams
    // Filter observations by projects in this organization
    if (data.id) {
      if (data.id.length > 1) {
        urlParams = urlParams + "&project.id[]=" + data.id.join("&project.id[]=")
      } else {
        urlParams = urlParams + "&project.id=" + data.id
      }
    }
    if (data.locId) {
      urlParams = urlParams + "&location.id[]=" + data.locId.join("&location.id[]=")
    }
    return fetch('/observations/' + urlParams)
      .then(response => response.json())
      .then((observations) => {
        commit(types.SET_ORG_OBSERVATIONS, observations)
        return observations
      })
      .catch((error) => {
        commit(types.SET_ORG_OBSERVATIONS_ERROR, error)
      })
  },
}

const types = {
  SET_ORG_OBSERVATIONS_LOADING: 'SET_ORG_OBSERVATIONS_LOADING',
  SET_ORG_OBSERVATIONS: 'SET_ORG_OBSERVATIONS',
  SET_ORG_OBSERVATIONS_ERROR: 'SET_ORG_OBSERVATIONS_ERROR',
  RESET_ERROR: 'RESET_ERROR',
  RESET: 'RESET',
}

const mutations = {
  [types.SET_ORG_OBSERVATIONS_LOADING](state) {
    state.isLoading = true;
  },
  [types.SET_ORG_OBSERVATIONS](state, organization) {
    state.organization = organization
    state.isLoading = false
  },
  [types.SET_ORG_OBSERVATIONS_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },
  [types.RESET_ERROR](state) {
    state.isLoading = false;
    state.error = false;
  },
  [types.RESET](state) {
    state = initialState
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters: getters,
  actions: actions,
  mutations: mutations
}
