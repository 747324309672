import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,
  state: {
    error: '',
    isLoading: false,
    resetPassword: false,
    violations: null,
    tokenBearer: window.localStorage.getItem('tokenBearer'),
    tokenRefresh: window.localStorage.getItem('tokenRefresh'),
  },
  actions,
  getters,
  mutations
}
