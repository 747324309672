import fetch from '../../../../utils/fetch'
import member from "../../../../components/project/buttons/Member.vue";
import {urlBuilder} from "../../../../helpers/url_builder_helper";

const getInitialState = () => {
  return {
    isLoading: false,
    projectAssignedLocations: null,
    datasheetMemberAssignedLocations: [],
    error: null,
  }
}

const state = getInitialState();

const getters = {
  isLoading(state) {
    return state.isLoading
  },
  projectAssignedLocations(state) {
    return state.projectAssignedLocations
  },
  datasheetMemberAssignedLocations(state) {
    return state.datasheetMemberAssignedLocations
  },
  error(state) {
    return state.error
  },
}

function getDatasheetMemberAssignedLocationsBlock({commit}, {url, ctx}) {
  return fetch(url + `${urlBuilder(ctx)}`)
    .then(response => response.json())
    .then((data) => {
      commit(types.STOP_LOADING)
      commit(types.ADD_ASSIGNED_LOCATIONS, data['hydra:member'])
      if (ctx.currentPage === 1) return data
    })
    .catch((error) => {
      commit(types.STOP_LOADING)
      commit(types.SET_ASSIGNED_LOCATIONS_ERROR, error)
    })
}

const actions = {
  getMember({commit, state, rootGetters, rootState}, urlParams) {
    commit(types.RESET)
    commit(types.SET_ASSIGNED_LOCATIONS_LOADING)
    let memberId = rootGetters["user/profile/membershipMap"][rootState.project.get.project.id]['@id']
    return fetch(memberId + '/assigned_locations' + urlParams)
      .then(response => response.json())
      .then((data) => {
        commit(types.SET_ASSIGNED_LOCATIONS, data['hydra:member'])
        return data
      })
      .catch((error) => {
        commit(types.SET_ASSIGNED_LOCATIONS_ERROR, error)
      })
  },

  getManager({commit, state, rootState}, urlParams) {
    commit(types.RESET)
    commit(types.SET_ASSIGNED_LOCATIONS_LOADING)
    return fetch('/projects/' + rootState.project.get.project.id + '/assigned_locations' + urlParams)
      .then(response => response.json())
      .then((data) => {
        commit(types.SET_ASSIGNED_LOCATIONS, data['hydra:member'])
        return data
      })
      .catch((error) => {
        commit(types.SET_ASSIGNED_LOCATIONS_ERROR, error)
      })
  },

  getDatasheetMemberAssignedLocations({commit}, datasheet) {
    commit(types.RESET);
    commit(types.SET_ASSIGNED_LOCATIONS_LOADING);
    const maxFetch = 100;
    const ctx = {currentPage: 1, perPage: maxFetch};
    let url = '/datasheets/' + datasheet.id + '/member_assigned_locations';
    let totalItems = null;
    getDatasheetMemberAssignedLocationsBlock({commit}, {url, ctx}).then(async (data) => {
      totalItems = data['hydra:totalItems'];
      if (totalItems > maxFetch) {
        for (let i = 1; i < (totalItems / maxFetch); i++) {
          const ctx = {currentPage: i + 1, perPage: maxFetch};
          await getDatasheetMemberAssignedLocationsBlock({commit}, {url, ctx})
        }
      }
    });
  },

  create({commit}, data) {
    commit(types.SET_ASSIGNED_LOCATIONS_LOADING)
    return fetch('/project_member_locations', {
      method: 'POST',
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then((resource) => {
        return resource
      })
      .catch((error) => {
        commit(types.SET_ASSIGNED_LOCATIONS_ERROR, error.message)
      })
  },

  delete({commit}, data) {
    return fetch((data), {
      method: 'DELETE'
    })
      .then((response) => {
        return response.status === 204
      })
      .then((isDeleted) => {
        return isDeleted
      })
      .catch((error) => {
        commit(types.SET_ASSIGNED_LOCATIONS_ERROR, error)
      })
  },

  search({commit, state, rootState}, data) {
    let url = '/projects/' + rootState.project.get.project.id + '/assigned_locations'
    url = url + '?member.id=' + data.member.id
    url = url + '&location.id=' + data.location.id
    url = url + (data.datasheet ? '&datasheet.id=' + data.datasheet.id : '&exists[datasheet]=false')
    return fetch(url)
      .then(response => response.json())
      .then(data => {
        return data['hydra:member']
      })
      .catch((e) => {

      })
  }
}

const types = {
  SET_ASSIGNED_LOCATIONS_LOADING: 'SET_ASSIGNED_LOCATIONS_LOADING',
  STOP_LOADING: 'STOP_LOADING',
  SET_ASSIGNED_LOCATIONS: 'SET_ASSIGNED_LOCATIONS',
  ADD_ASSIGNED_LOCATIONS: 'ADD_ASSIGNED_LOCATIONS',
  SET_ASSIGNED_LOCATIONS_ERROR: 'SET_ASSIGNED_LOCATIONS_ERROR',
  RESET_ERROR: 'RESET_ERROR',
  RESET: 'RESET',
}

const mutations = {
  [types.SET_ASSIGNED_LOCATIONS_LOADING](state) {
    state.isLoading = true;
  },
  [types.SET_ASSIGNED_LOCATIONS](state, observations) {
    state.projectAssignedLocations = observations
    state.isLoading = false
  },
  [types.SET_ASSIGNED_LOCATIONS_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },
  [types.STOP_LOADING](state) {
    state.isLoading = false;
  },
  [types.ADD_ASSIGNED_LOCATIONS](state, locations) {
    state.datasheetMemberAssignedLocations = [...state.datasheetMemberAssignedLocations, ...locations];
  },
  [types.RESET_ERROR](state) {
    state.isLoading = false;
    state.error = false;
  },
  [types.RESET](state) {
    Object.assign(state, getInitialState());
  },
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
}
