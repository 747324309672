<template>
  <div v-if="allOptionValues">
    <div v-for="i in numBins" :key="i">
      <CategoricalBinMultiselect
        :items="getDropdownOptions"
        :order-number="i"
        :bin-pct="i*weight"
        @select="getSelected"
      >
      </CategoricalBinMultiselect>
    </div>
  </div>
</template>

<script>
import CategoricalBinMultiselect from "./CategoricalBinMultiselect.vue";
export default {
  name: "CategoricalBins",
  props: {
    allOptionValues: {
      type: Array,
      default: () => {
        return []
      },
      required: true,
    },
    numBins: {
      type: Number,
      default: () => {
        return 1
      },
      required: true,
    },
  },
  components: {
    CategoricalBinMultiselect,
  },
  inject: [ 'parentValidator' ],
  data() {
    return {
      selected: [],
      binValues: [],
    }
  },
  created () {
    // this.$validator = this.parentValidator
  },
  watch: {
    numBins: {
      // Watcher to add back allOptionValues from removed bins
      handler (newVal, oldVal) {
        if (newVal < oldVal) {
          // #Bins reduced, reclaim used values
          this.binValues = this.binValues.filter((arr) => {
            return arr.orderNumber <= newVal
          });
          this.updateSelected()
        }
      }
    }
  },
  computed: {
    getDropdownOptions() {
      if (this.allOptionValues) {
        return this.allOptionValues.filter((i) => {
          return this.selected.indexOf(i) === -1
        })
      } else {
        return []
      }
    },
    weight() {
      switch (this.numBins) {
        case 1: return 100;
        case 2: return 50;
        case 3: return 33.33;
        case 4: return 25;
        case 5: return 20;
        case 6: return 16.66;
        case 7: return 14.28;
        }
      },
  },
  methods: {
    getSelected(val) {
      // Manage the shared list of dropdown items
      let bIndex = this.binValues.findIndex(b => b.orderNumber === val.orderNumber)
      if (bIndex === -1) {
        this.binValues.push(val)
      } else {
        this.binValues[bIndex] = val
      }
      this.updateSelected()
    },
    updateSelected() {
      // Extract selected allOptionValues from all dropdowns
      this.selected = this.binValues.flatMap(bv => {
        return bv.optionsValues
      })
      // Emit values to parent
      this.$emit('update', this.binValues)
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
