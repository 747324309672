import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('user/resetPassword')

export default {
  data () {
    return {
      item: {}
    }
  },

  created () {
    this.checkToken(decodeURIComponent(this.$route.params.token))
    this.$validator.validateAll()
  },

  computed: {
    ...mapGetters([
      'error',
      'isLoading',
      'violations',
      'updated',
      'expiredToken',
      'invalidToken',
    ]),

    isFilled () {
      return this.item.password && !this.errors.items.length
    }
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    updated: function (updated) {
      if (!updated) {
        return
      }

      this.$router.push({ name: 'Login' })
    }
  },

  methods: {
    ...mapActions([
      'update',
      'checkToken',
    ]),

    onPasswordUpdate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.update({
            item: this.item,
            resetToken: decodeURIComponent(this.$route.params.token)
          })
        }
      })
    },

    isInvalid (key) {
      return (this.violations !== null && Object.keys(this.violations).length > 0 && this.violations[key]) || this.errors.has(key)
    },

    getError (key) {
      if (this.errors.has(key)) {
        return this.errors.first(key)
      }

      if (this.violations && Object.keys(this.violations).length > 0 && this.violations[key]) {
        return this.violations.key
      }
    }
  },
}
