import fetch from '../../../utils/fetch'

const getInitialState = () => {
  return {
    isLoading: false,
    authStatus: null,
    error: null,
  }
}

const state = getInitialState();

const getters = {
  isLoading(state) {
    return state.isLoading
  },
  projectId() {
    let id = null
    id = localStorage.getItem('tempAirtableProjectId')
    return id
  },
  authStatus(state) {
    return state.authStatus
  },
  error(state) {
    return state.error
  },
}

const actions = {

  saveProjectId({rootState}) {
    localStorage.setItem('tempAirtableProjectId', rootState.project.get.project.urlField);
  },
  clearProjectId() {
    localStorage.removeItem('tempAirtableProjectId');
  },
  /**
   * @param commit
   * @param dispatch
   * @param rootState
   * @param data
   * @returns {Promise<T | never> | * | *}
   */
  authorize({commit, dispatch, rootState}, data) {
    commit(types.SET_AIRTABLE_OAUTH_LOADING);
    return fetch(rootState.user.profile.sessionData['@id'] + '/airtable/authorize', {
      method: 'POST',
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then((data) => {
        commit(types.SET_AIRTABLE_OAUTH, data)
        // Refresh user data so that airtable booleans are set
        dispatch('user/profile/fetchSession', {}, {root: true})
        return true
      })
      .catch((error) => {
        commit(types.SET_AIRTABLE_OAUTH_ERROR, error)
      })
  },
  reset({commit, state}) {
    commit(types.SET_AIRTABLE_OAUTH_LOADING);
    commit(types.RESET)
  },
}

const types = {
  SET_AIRTABLE_OAUTH_LOADING: 'SET_AIRTABLE_OAUTH_LOADING',
  SET_AIRTABLE_PROJECT: 'SET_AIRTABLE_PROJECT',
  SET_AIRTABLE_OAUTH: 'SET_AIRTABLE_OAUTH',
  SET_AIRTABLE_OAUTH_ERROR: 'SET_AIRTABLE_OAUTH_ERROR',
  RESET_ERROR: 'RESET_ERROR',
  RESET: 'RESET',
}

const mutations = {
  [types.SET_AIRTABLE_OAUTH_LOADING](state) {
    state.isLoading = true;
  },
  [types.SET_AIRTABLE_PROJECT](state, id) {
    state.projectId = id
    state.isLoading = false
  },
  [types.SET_AIRTABLE_OAUTH](state, data) {
    state.authStatus = data
    state.isLoading = false
  },
  [types.SET_AIRTABLE_OAUTH_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },
  [types.RESET_ERROR](state) {
    state.isLoading = false;
    state.error = false;
  },
  [types.RESET](state) {
    Object.assign(state, getInitialState());
  },
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
}
