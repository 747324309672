<template>
  <div>
    <div v-if="summaryStats.hasOwnProperty('num')">
      <table class="table col-md-4">
        <tbody>
        <tr>
          <th scope="row" class="col-10">Sample Size:</th>
          <td class="text-right">{{ summaryStats.num }}</td>
        </tr>
        <tr>
          <th scope="row">Sum:</th>
          <td class="text-right">{{ summaryStats.sum }}</td>
        </tr>
        <tr>
          <th scope="row">Min:</th>
          <td class="text-right">{{ summaryStats.min }}</td>
        </tr>
        <tr>
          <th scope="row">Average:</th>
          <td class="text-right">{{ Math.round(summaryStats.avg * 10) / 10 }}</td>
        </tr>
        <tr>
          <th scope="row">Max:</th>
          <td class="text-right">{{ summaryStats.max }}</td>
        </tr>
        <tr>
          <th scope="row">Std Dev:</th>
          <td class="text-right" v-if="summaryStats.num > 1">{{ Math.round(summaryStats.sd * 10) / 10 }}</td>
          <td class="text-right" v-else></td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else><span>No Observations</span></div>
  </div>
</template>

<script>
export default {
  name: 'NumberStats',
  props: {
    summaryStats: {
      type: Object,
      default: () => {
        return {}
      },
      required: true,
    },
  },
  data() {
    return {}
  },
  watch: {}
}
</script>
