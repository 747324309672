<template>
  <div class="card-body">
    <div class="row mb-2">
      <div class="col-md-12">
        <label><b>Select a Project:</b></label>
        <b-form-select
          id="projectSelect"
          class="mb-2"
          v-model="selectedProjectForAnalysis"
        >
          <option v-for="project in publicProjects" :value="project.id">
            {{ project.name }}
          </option>
        </b-form-select>
      </div>
    </div>
    <div
      class="row mb-2"
      v-if="this.selectedProjectForAnalysis && !this.isLoadingAllLocations"
    >
      <div class="col-md-12" v-if="this.allProjectLocations.length > 0">
        <label><b>Select Location:</b></label>
        <b-form-select
          id="projectLocationsSelect"
          class="mb-2"
          v-model="selectedLocationForAnalysis"
        >
          <option v-for="location in allProjectLocations" :value="location.id">
            {{ location.name }} ({{ location.observationsTotal }} Observations)
          </option>
        </b-form-select>
      </div>
      <div class="col-md-12" v-else>
        <label>No Locations with Observations for this Project</label>
      </div>
    </div>
    <LocationTrends
      v-if="selectedLocationForAnalysis !== null"
      :key="selectedLocationForAnalysis"
      :location-id="selectedLocationForAnalysis"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LocationTrends from "../../../location/LocationTrends";

export default {
  name: "otherHubsAnalysis",
  components: {
    LocationTrends
  },
  data() {
    return {
      publicProjects: null,
      selectedProjectForAnalysis: null,
      selectedLocationForAnalysis: null,
    };
  },
  created() {
    if (this.projects) {
      this.publicProjects = Object.values(this.projects).filter(p => !p.isPrivate)
      this.selectedProjectForAnalysis = this.publicProjects[0].id;
      if (this.allProjectLocations && this.allProjectLocations.length > 0) {
        this.selectedLocationForAnalysis = this.allProjectLocations[0].id;
      }
    }
  },
  watch: {
    projects(newVal) {
      if (newVal) {
        // Select first available project
        //  - this handles scenario where user navigates directly to /trends
        this.publicProjects = Object.values(newVal).filter(p => !p.isPrivate)
        this.selectedProjectForAnalysis = this.publicProjects[0].id;
      }
    },
    selectedProjectForAnalysis(newVal) {
      if (newVal && newVal.length > 0) {
        this.selectedLocationForAnalysis = null;
        this.getAllProjectLocations({ id: newVal   });
      }
    },
    allProjectLocations(newVal) {
      if (newVal && newVal.length > 0) {
        this.selectedLocationForAnalysis = newVal[0].id;
      }
    }
  },
  computed: {
    ...mapGetters({
      organization: "organization/get/organization",
      projects: "organization/get/projects",
      isLoadingAllLocations: "project/show/isLoadingAllLocations",
      allProjectLocations: "project/show/allProjectLocations"
    })
  },
  methods: {
    ...mapActions({
      getAllProjectLocations: "project/show/getAllProjectLocations"
    })
  }
};
</script>

<style lang="scss"></style>
