<template>
  <b-nav card-header tabs fill class="project-nav flex-wrap-reverse">
    <b-nav-item
      link-classes="project-nav-link"
      :to="{ name: 'ProjectHome', params: { id: $route.params.id } }"
      exact-active-class="active"
    >
      <i class="fa fa-home mr-1 mr-lg-2"></i>
      Home
    </b-nav-item>
    <b-nav-item-dropdown :toggle-class="isDataActive" right>
      <template #button-content>
        <i class="fa fa-binoculars mr-1 mr-lg-2"></i>
        Data
      </template>
      <b-dropdown-item
        :to="{ name: 'ProjectObservations', params: { id: $route.params.id } }"
        exact-active-class="active"
      >
        <i class="fa fa-binoculars mr-1 mr-lg-2"></i>Observations
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'ProjectLocations', params: { id: $route.params.id } }"
        exact-active-class="active"
      >
        <i class="fa fa-map-marker-alt mr-1 mr-lg-2"></i>Locations
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'ProjectMap', params: { id: $route.params.id } }"
        exact-active-class="active"
      >
        <i class="fa fa-map-marked-alt mr-1 mr-lg-2"></i>Map
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'ProjectDownloads', params: { id: $route.params.id } }"
        exact-active-class="active"
      >
        <i class="fa fa-download mr-1 mr-lg-2"></i>Downloads
      </b-dropdown-item>
    </b-nav-item-dropdown>
    <b-nav-item
      link-classes="project-nav-link"
      :to="{ name: 'ProjectResources', params: { id: $route.params.id } }"
      exact-active-class="active"
    >
      <i class="fa fa-file-alt mr-1 mr-lg-2"></i>
      Resources
    </b-nav-item>
    <b-nav-item
      link-classes="project-nav-link"
      :to="{ name: 'ProjectForum', params: { id: $route.params.id } }"
      :active-class="isForumActive"
    >
      <i class="fa fa-comments mr-1 mr-lg-2"></i>
      Forum
    </b-nav-item>
    <b-nav-item
      link-classes="project-nav-link"
      :to="{ name: 'ProjectAnalyses', params: { id: $route.params.id } }"
      exact-active-class="active"
    >
      <i class="fa fa-chart-bar mr-1 mr-lg-2"></i>
      Analyses
    </b-nav-item>
    <b-nav-item
      link-classes="project-nav-link"
      :to="{ name: 'ProjectResults', params: { id: $route.params.id } }"
      exact-active-class="active"
      ><i class="fa fa-poll mr-1 mr-lg-2"></i>
      Results
    </b-nav-item>
    <b-nav-item
      v-if="isManager"
      class="project-nav-link-manager"
      link-classes="project-nav-link"
      :to="{ name: 'ProjectEdit', params: { id: $route.params.id } }"
      exact-active-class="active"
      ><i class="fa fa-pencil-alt mr-1 mr-lg-2"></i>
      Edit Project
    </b-nav-item>
    <b-nav-item
      :class="{ 'project-nav-link-manager': isManager }"
      link-classes="project-nav-link"
      :to="{ name: 'ProjectDatasheets', params: { id: $route.params.id } }"
      exact-active-class="active"
      ><i class="fa fa-bars mr-1 mr-lg-2"></i>
      Datasheets
    </b-nav-item>
    <b-nav-item-dropdown
      :toggle-class="isMembersActive"
      :class="{ 'project-nav-link-manager': isManager }"
      right
    >
      <template #button-content>
        <i class="fa fa-users mr-1 mr-lg-2"></i>
        Members
      </template>
      <b-dropdown-item
        :to="{ name: 'ProjectMembers', params: { id: $route.params.id } }"
        exact-active-class="active"
      >
        <i class="fa fa-user-plus mr-1 mr-lg-2"></i>Project Members
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'ProjectAssignedLocations', params: { id: $route.params.id } }"
        exact-active-class="active"
      >
        <i class="fa fa-thumb-tack mr-1 mr-lg-2"></i>Assigned Locations
      </b-dropdown-item>
    </b-nav-item-dropdown>
<!--    <b-nav-item-->
<!--      link-classes="project-nav-link"-->
<!--      :class="{ 'project-nav-link-manager': isManager }"-->
<!--      :to="{ name: 'ProjectMembers', params: { id: $route.params.id } }"-->
<!--      exact-active-class="active"-->
<!--      ><i class="fa fa-users mr-1 mr-lg-2"></i>-->
<!--      Members-->
<!--    </b-nav-item>-->
    <b-nav-item-dropdown
      v-if="isManager"
      class="project-nav-link project-nav-link-manager"
      :toggle-class="isIntegrationActive"
      right
    >
      <template #button-content>
        <i class="fa fa-cogs mr-1 mr-lg-2"></i>
        Integrations
      </template>
      <b-dropdown-item
        :to="{ name: 'ProjectZooniverse', params: { id: $route.params.id } }"
        exact-active-class="active"
      >
        Zooniverse
      </b-dropdown-item>
      <b-dropdown-item
          :to="{ name: 'ProjectAirtable', params: { id: $route.params.id } }"
          exact-active-class="active"
      >
        Airtable
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'ProjectSciStarter', params: { id: $route.params.id } }"
        exact-active-class="active"
      >
        SciStarter
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'ProjectVera', params: { id: $route.params.id } }"
        exact-active-class="active"
      >
        Vera
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </b-nav>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      projectId: "project/get/id",
      isManager: "project/get/isManager",
    }),
    isForumActive() {
      const forumTabs = "ProjectForum";
      if (this.$route.name.indexOf(forumTabs) > -1) {
        return "active";
      }
      return "";
    },
    isDataActive() {
      const integrations = [
        "ProjectObservations",
        "ProjectLocations",
        "ProjectMap",
        "ProjectDownloads"
      ];
      if (integrations.includes(this.$route.name)) {
        return "active";
      }
      return "project-nav-link";
    },
    isMembersActive() {
      const members = [
        "ProjectMembers",
        "ProjectAssignedLocations"
      ];
      if (members.includes(this.$route.name)) {
        return "active";
      }
      return "project-nav-link";
    },
    isIntegrationActive() {
      const integrations = [
        "ProjectZooniverse",
        "ProjectSciStarter",
        "ProjectVera"
      ];
      if (integrations.includes(this.$route.name)) {
        return "active";
      }
      return "project-nav-link";
    },
    // isManager() {
    //   return (
    //     this.projectId &&
    //     this.membershipMap[this.projectId] &&
    //     this.membershipMap[this.projectId].isManager
    //   );
    // },
    hasManagerFeatures() {
      return false;
    }
  }
};
</script>

<style lang="scss">
.project-nav-link {
  /*letter-spacing: -1px;*/
  color: #3b3b3b;
  /* 7E7E7E BDBDBD 22252C */
  /*font-weight: bold; */

  &:hover {
    color: #007fb1;
  }
}

.project-nav-link-manager {
  background-color: #ffd966;

  &:active {
    /*background-color: #000000*/
  }
}
</style>
