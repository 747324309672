<template>
  <div class="mt-0 mb-5">
    <OrganizationHeader></OrganizationHeader>
    <div class="alert alert-danger" v-if="error">
      {{ error }}
    </div>
    <div class="container">
      <b-card no-body header-tag="nav">
        <template #header>
          <OrganizationNav></OrganizationNav>
        </template>
        <router-view></router-view>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import OrganizationHeader from "./view/header/organizationHeader";
import OrganizationNav from "./view/nav";
import PieDonutScoreCard from "./view/home/scorecards/Donut.vue";

export default {
  components: {
    OrganizationHeader,
    OrganizationNav,
    PieDonutScoreCard
  },
  provide() {
    return {
      parentValidator: this.$validator
    };
  },
  created() {
    // Prevent organization fetch on tab switch
    if (!this.organizationVuex || (this.organizationVuex.url !== this.$route.params.name)) {
        this.getOrganization(this.$route.params.name);
    }
  },
  computed: {
    ...mapGetters({
      organizationVuex: "organization/get/organization",
      error: "organization/get/error"
    })
  },
  methods: {
    ...mapActions({
      getOrganization: "organization/get/get"
    })
  }
};
</script>

<style lang=""></style>
