import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,
  state: {
    error: '',
    isLoading: false,
    isLoadingLocations: false,
    isLoadingAllLocations: false,
    retrieved: null,
    datasheetIsLoading: false,
    datasheetError: '',
    datasheetCreated: null,
    datasheetViolations: null,
    searchTerm: null,
    violations: '',
    projectLocations: [],
    allProjectLocations: [],
    stats: null,
    getProjectIdByName: '',
    isLiked: false,
    discussionForumData: [],
    isDeleted: false,
    updatedPostAfterReply: null,
    isLoadingDiscussionData: false,
    errorCheckLocationExists: '',
    isLoadingCheckLocationExists: false,
    checkLocationExists: null,
    isLoadingZooniverse: false,
    isLoadingSciStarter: false,
    isLoadingContactManager: false,
    contactManagerSuccess: '',
    isLoadingMembers: false,
    projectMembers: [],
  },
  actions,
  getters,
  mutations
}
