export const PROJECT_SHOW_RESET = 'PROJECT_SHOW_RESET'
export const PROJECT_SHOW_SET_ERROR = 'PROJECT_SHOW_SET_ERROR'
export const PROJECT_SHOW_SET_RETRIEVED = 'PROJECT_SHOW_SET_RETRIEVED'
export const PROJECT_SHOW_TOGGLE_LOADING = 'PROJECT_SHOW_TOGGLE_LOADING'

export const DATASHEET_CREATE_RESET = 'DATASHEET_CREATE_RESET'
export const DATASHEET_CREATE_SET_CREATED = 'DATASHEET_CREATE_SET_CREATED'
export const DATASHEET_CREATE_SET_ERROR = 'DATASHEET_CREATE_SET_ERROR'
export const DATASHEET_CREATE_SET_VIOLATIONS = 'DATASHEET_CREATE_SET_VIOLATIONS'
export const DATASHEET_CREATE_TOGGLE_LOADING = 'DATASHEET_CREATE_TOGGLE_LOADING'

export const SET_SEARCH_TERM = 'SET_SEARCH_TERM'

export const UPDATE_ITEM_PROPERTY = 'UPDATE_ITEM_PROPERTY'

export const GET_PROJECT_MEMBERS_LIST_TOGGLE_LOADING = 'GET_PROJECT_MEMBERS_LIST_TOGGLE_LOADING'
export const GET_PROJECT_MEMBERS_LIST_SET_ERROR = 'GET_PROJECT_MEMBERS_LIST_SET_ERROR'
export const SET_PROJECT_MEMBERS_LIST = 'SET_PROJECT_MEMBERS_LIST'

export const JOIN_MEMBER_LOADING = 'JOIN_MEMBER_LOADING'
export const JOIN_MEMBER_SET_ERROR = 'JOIN_MEMBER_SET_ERROR'
export const JOIN_MEMBER_SET_VIOLATIONS = 'JOIN_MEMBER_SET_VIOLATIONS'
export const JOIN_MEMBER_DONE_CREATED = 'JOIN_MEMBER_DONE_CREATED'

export const APPROVE_MEMBER_LOADING = 'APPROVE_MEMBER_LOADING'
export const APPROVE_MEMBER_SET_VIOLATIONS = 'APPROVE_MEMBER_SET_VIOLATIONS'
export const APPROVE_MEMBER_SET_ERROR = 'APPROVE_MEMBER_SET_ERROR'
export const APPROVE_MEMBER_DONE = 'APPROVE_MEMBER_DONE'

export const DELETE_MEMBER_LOADING = 'DELETE_MEMBER_LOADING'
export const DELETE_MEMBER_DONE = 'DELETE_MEMBER_DONE'
export const DELETE_MEMBER_SET_VIOLATIONS = 'DELETE_MEMBER_SET_VIOLATIONS'
export const DELETE_MEMBER_SET_ERROR = 'DELETE_MEMBER_SET_ERROR'

export const UPDATE_MEMBER_LOADING = 'UPDATE_MEMBER_LOADING'
export const UPDATE_MEMBER_DONE = 'UPDATE_MEMBER_DONE'
export const UPDATE_MEMBER_SET_VIOLATIONS = 'UPDATE_MEMBER_SET_VIOLATIONS'
export const UPDATE_MEMBER_SET_ERROR = 'UPDATE_MEMBER_SET_ERROR'

export const INVITE_MEMBER_LOADING = 'INVITE_MEMBER_LOADING'
export const INVITE_MEMBER_DONE = 'INVITE_MEMBER_DONE'
export const INVITE_MEMBER_SET_VIOLATIONS = 'INVITE_MEMBER_SET_VIOLATIONS'
export const INVITE_MEMBER_SET_ERROR = 'INVITE_MEMBER_SET_ERROR'

export const DELETE_DATASHEET_LOADING = 'DELETE_DATASHEET_LOADING'
export const DELETE_DATASHEET_DONE = 'DELETE_DATASHEET_DONE'
export const DELETE_DATASHEET_SET_VIOLATIONS = 'DELETE_DATASHEET_SET_VIOLATIONS'
export const DELETE_DATASHEET_SET_ERROR = 'DELETE_DATASHEET_SET_ERROR'

export const GET_PROJECT_LOCATIONS_TOGGLE_LOADING = 'GET_PROJECT_LOCATIONS_TOGGLE_LOADING'
export const SET_PROJECT_LOCATIONS = 'SET_PROJECT_LOCATIONS'

export const GET_ALL_PROJECT_LOCATIONS_TOGGLE_LOADING = 'GET_ALL_PROJECT_LOCATIONS_TOGGLE_LOADING'
export const SET_ALL_PROJECT_LOCATIONS = 'SET_ALL_PROJECT_LOCATIONS'
export const RESET_ALL_PROJECT_LOCATIONS = 'RESET_ALL_PROJECT_LOCATIONS'

export const GET_PROJECT_STATS_LOADING = 'GET_PROJECT_STATS_LOADING'
export const GET_PROJECT_STATS_DONE = 'GET_PROJECT_STATS_DONE'
export const GET_PROJECT_STATS_SET_VIOLATIONS = 'GET_PROJECT_STATS_SET_VIOLATIONS'
export const GET_PROJECT_STATS_SET_ERROR = 'GET_PROJECT_STATS_SET_ERROR'

export const PROJECT_PROFILE_TOGGLE_PICTURE_UPLOADING = 'PROJECT_PROFILE_TOGGLE_PICTURE_UPLOADING'

export const GET_PROJECT_ID_BY_PROJECT_NAME = 'GET_PROJECT_ID_BY_PROJECT_NAME';
export const GET_PROJECT_ID_BY_PROJECT_NAME_TOGGLE_LOADING = 'GET_PROJECT_ID_BY_PROJECT_NAME_TOGGLE_LOADING';
export const GET_PROJECT_ID_BY_PROJECT_NAME_ERROR = 'GET_PROJECT_ID_BY_PROJECT_NAME_ERROR';

export const ADD_PROJECT_LIKE = 'ADD_PROJECT_LIKE';
export const ADD_PROJECT_LIKE_BEFORE_LOGIN = 'ADD_PROJECT_LIKE_BEFORE_LOGIN';
export const ADD_PROJECT_LIKE_ERROR = 'ADD_PROJECT_LIKE_ERROR';
export const REMOVE_PROJECT_LIKE = 'ADD_PROJECT_LIKE';
export const REMOVE_PROJECT_LIKE_ERROR = 'ADD_PROJECT_LIKE_ERROR';

export const GET_PROJECT_DISCUSSION_FORUM_DATA = 'GET_PROJECT_DISCUSSION_FORUM_DATA';
export const GET_PROJECT_DISCUSSION_FORUM_DATA_ERROR = 'GET_PROJECT_DISCUSSION_FORUM_DATA_ERROR';
export const ADD_DISCUSSION_DATA = 'ADD_DISCUSSION_DATA';
export const ADD_DISCUSSION_DATA_TOGGLE_LOADING = 'ADD_DISCUSSION_DATA_TOGGLE_LOADING';
export const ADD_DISCUSSION_DATA_ERROR = 'ADD_DISCUSSION_DATA_ERROR';
export const REMOVE_DISCUSSION_POST = 'REMOVE_DISCUSSION_POST';
export const REMOVE_DISCUSSION_POST_ERROR = 'REMOVE_DISCUSSION_POST_ERROR';
export const ADD_REPLY_TO_DISCUSSION = 'ADD_REPLY_TO_DISCUSSION';
export const ADD_REPLY_TO_DISCUSSION_ERROR = 'ADD_REPLY_TO_DISCUSSION_ERROR';

export const PROJECT_CHECK_LOCATION_EXISTS_TOGGLE_LOADING = 'PROJECT_CHECK_LOCATION_EXISTS_TOGGLE_LOADING'
export const PROJECT_CHECK_LOCATION_EXISTS_SET_ERROR = 'PROJECT_CHECK_LOCATION_EXISTS_SET_ERROR'
export const PROJECT_CHECK_LOCATION_EXISTS_SET_RETRIEVED = 'PROJECT_CHECK_LOCATION_EXISTS_SET_RETRIEVED'

export const ZOONIVERSE_TOGGLE_LOADING = 'ZOONIVERSE_TOGGLE_LOADING'
export const SCISTARTER_TOGGLE_LOADING = 'SCISTARTER_TOGGLE_LOADING'

export const SEND_EMAIL_TO_MANAGER_LOADING = 'SEND_EMAIL_TO_MANAGER_LOADING'
export const SEND_EMAIL_TO_MANAGER_SUCCESS = 'SEND_EMAIL_TO_MANAGER_SUCCESS'
