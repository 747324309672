<template>
  <highcharts :options="chartOptions" ref="chart"></highcharts>
</template>

<script>
import Highcharts from "highcharts";
import exporting from "highcharts/modules/exporting";
import offlineExporting from "highcharts/modules/offline-exporting";

exporting(Highcharts);
offlineExporting(Highcharts);

export default {
  name: 'ScatterChart',
  props: {
    chartTitle: {
      type: String,
      default: () => {
        return null
      },
      required: true,
    },
    chartData: {
      type: Array,
      default: () => {
        return []
      },
      required: true,
    },
    chartYCategories: {
      type: Array,
      default: () => {
        return []
      },
      required: true,
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'scatter',
          zoomType: 'xy',
          borderColor: '#000000',
          borderWidth: 1,
        },
        title: {
          text: this.chartTitle
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            millisecond: '%H:%M:%S.%L',
            second: '%H:%M:%S',
            minute: '%H:%M',
            hour: '%H:%M',
            day: '%e. %b',
            week: '%e. %b',
            month: '%b \'%y',
            year: '%Y'
          },
          labels: {
            format: "{value: %b. %Y}"
          },
        },
        yAxis: {
          type: 'category',
          title: '',
          categories: this.chartYCategories,
          labels: {
            overflow: "justify",
            format: "{value}"
          }
        },
        tooltip: {
          formatter: function () {
            return '<b>x: </b>' + Highcharts.dateFormat('%e-%b-%Y', new Date(this.x))
                   + '<br>' +
                   '<b>y: </b>' + this.y
          }
        },
        height: 200,
        credits: {
          enabled: true,
          text: 'Powered by CitSci via HighCharts',
          href: ''
        },
        series: this.chartData,
      }
    }
  },
  watch: {
    chartData(newVal) {
      this.chartOptions  = Highcharts.merge(this.chartOptions, {
        series: newVal,
      })
    },
    chartTitle(newVal) {
      this.chartOptions = Highcharts.merge(this.chartOptions, {
        title: {
          text: 'Trend of - ' + newVal
        }
      })
    },
    chartYCategories(newVal) {
      this.chartOptions = Highcharts.merge(this.chartOptions, {
        yAxis: {
          categories: newVal,
        }
      })
    },
  }
}
</script>
