<template>
  <div class="d-flex" @click="(evt) => { onCardClick(evt, project) }"
       :title="project.name + ' citizen science project'">
    <b-card tag="article" class="my-1 mx-3 text-center pb-2 mb-4" no-body>
      <b-card-body>
        <div class="d-flex card-content" :id="'card-content'">
          <div class="card-image-container overflow-hidden">
            <b-card-img
              :src="project.picture && project.picture.path ? project.picture.path : '/assets/images/generic_img.png'"
              alt="Image" :top="true" :fluid="true" class="pt-1 px-1"/>
          </div>

          <p class="membership">
            <b-img :src="'/assets/images/' + (project.approveContacts ? 'closed' : 'open') + '-access.png'"
                   width="50" height="50"
                   :alt="((project.approveContacts ? 'Closed' : 'Opened') + 'Membership - requires approval from manager')"
                   :title="((project.approveContacts ? 'Closed' : 'Opened') + ' Membership - requires approval from manager')"/>
          </p>

          <p class="mx-2 members-date">
            <span class="members">{{ addThousandsPoint(project.measurementsTotal) }} <span class="ml-1"><i
              class="fas fa-binoculars"></i></span></span>
            <span class="date color-gray">{{ displayDate(project.createdAt) }}</span>
          </p>

          <h5 class="mt-2 mb-3">{{ ellipsizeCharacters(project.name, 23) }}</h5>
          <p class="text-left mx-2 project-description">{{ ellipsize(project.description, 120) }}</p>
        </div>
        <ProjectJoinButton :project="project" class='card-btn mx-2'></ProjectJoinButton>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>

import ProjectJoinButton from "../../../../assets/scripts/project/projectJoinButton.vue";

export default {
  components: {
    ProjectJoinButton,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  methods: {
    addThousandsPoint(number) {
      // Fixes browser debugger error when number is undefined
      if (number == null)
        number = 0;

      number = number.toString()
      while (true) {
        const n2 = number.replace(/(\d)(\d{3})($|,|\.)/g, '$1,$2$3');
        if (number === n2) break
        number = n2
      }
      return number
    },
    onCardClick(evt, projectData) {
      evt.preventDefault();

      if (evt.target.getAttribute('class').indexOf('btn-join') !== -1) {
        if (JSON.parse(localStorage.getItem('sessionData')) == null) {
          this.$root.$emit('bv::show::modal', 'not-logged-in-modal');
          return;
        } else {
          this.joinToProject({id: projectData.id});
          return;
        }
      }

      this.$router.push('/projects/' + projectData.urlField);
    },
  },
};
</script>

<!-- TODO: Migrate any Home specific styles here. -->
<style lang="scss" scoped>

.section {
  width: 100%;

  &.featured-project {
    @media screen and (max-width: 1050px) {
      padding-right: 4rem !important;
      padding-left: 4rem !important;
    }
  }

  .card-holder {
    justify-content: center;
  }

  .card-image-container {
    min-height: 150px;
    max-height: 150px;
  }

  .card-body {
    max-width: 243px;
    max-height: 300px;
    min-height: 300px;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    .card-title {
      display: none;
    }

    .card-content, .card-btn {
      flex-direction: column;
      overflow: hidden;
    }

    .project-description {
      color: #8F9AA4;
      font-size: 11px;
      margin-top: -10px;
    }

    .membership {
      max-width: 39px;
      max-height: 39px;
      margin-top: -50px;
      align-self: flex-end;
      margin-right: 10px;

      img {
        max-width: 39px;
        max-height: 39px;
      }
    }

    .members-date {
      display: flex;
      justify-content: space-between;
      font-size: 11px;
      margin-bottom: 0px !important;
    }

    .members {
      font-weight: bold;
    }
  }
}

</style>
