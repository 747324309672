import { createNamespacedHelpers } from 'vuex'
import ProjectForm from '../../../components/project/Form'

const { mapGetters, mapActions } = createNamespacedHelpers('project/create')

export default {
  components: {
    ProjectForm
  },

  provide () {
    return { parentValidator: this.$validator }
  },

  data () {
    return {
      item: {
        projectState: null,
        approveContacts: true,
        isPrivate: false,
        isSciStarter: true,
        isZooniverse: true,
        isAirtable: false,
        picture: null,
        bannerPicture: null,
        organizations: []
      },
      publishButtonTitle: 'Publish Project',
      draftButtonTitle: 'Save As Draft'
    }
  },

  computed: {
  ...mapGetters([
    'error',
    'isLoading',
    'created',
    'violations',
    // 'itemUpdated'
    ]),
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    created: function (created) {
      if (!created) {
        return
      }

      this.$router.push({ name: 'ProjectShow', params: { id: created['urlField'] } })
    },

    error: function (err) {
      if( err && err.length > 0 && err.toLowerCase().indexOf('validate') !== -1 ) {
        this.$refs.myHeader.scrollIntoView({behavior: "smooth", block: "end", inline: "start"});
      }
    }
  },

  methods: {
    ...mapActions([
      'create'
    ]),

    /**
     * @function onItemChange
     * @param data
     */
    onItemChange(data) {
      Object.assign(this.item, data)
    },

    onSendForm (item, orgRequests, isDraft) {
      // On button press run validation and then create project
      this.$validator.validateAll()
      .then(result => {
        if (result) {
          this.item.latitude = parseFloat(this.item.latitude);
          this.item.longitude = parseFloat(this.item.longitude);

          // Project state is set to published | draft
          this.item.projectState = isDraft ? this.PROJECT_STATE_DRAFT : this.PROJECT_STATE_PUBLISHED;
          this.create( { projectData: this.item, organizations: orgRequests } )
        } else {
          this.$el.getElementsByClassName('is-invalid')[0].parentElement.parentElement.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
          /*this.$refs.formUIError.$el.scrollIntoView({behavior: "smooth", block: "start", inline: "center"});
          this.$refs.formUIError.$el.scroll(xCoord, yCoord);*/
        }
      })
    },

    updateField (field, value) {
      Object.assign(this.item, { [field]: value })
    }
  },

  created () {
    this.$validator.validateAll()
  }
}
