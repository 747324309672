<template>
  <b-container mx-auto class="logo-container" fluid :style="getImageUrlStyle()">
    <div v-if="currentLogoImage && (currentLogoImage !== '/assets/images/logo.png')" class="row row-bottom-logo">
      <div class="col col-margin-logo-replace">
        <label for="replaceLogo" class="btn btn-sm btn-tertiary">
          <font-awesome-icon icon="image"/>
          &nbsp;Replace
        </label>
        <input id="replaceLogo"
               ref="input"
               type="file"
               name="image"
               accept="image/*"
               @change="setupLogoCropper"/>
      </div>
      <div class="col col-margin-logo">
        <b-btn class="btn btn-sm" @click="$bvModal.show('removeLogoModal');" variant="danger">
          <font-awesome-icon icon="trash"/>
          &nbsp;Remove
        </b-btn>
      </div>
    </div>
    <div v-else class="row row-bottom-logo">
      <div class="col col-margin-logo">
        <label for="addLogo" class="btn btn-sm btn-primary">
          <font-awesome-icon icon="image"/>
          &nbspAdd Logo
        </label>
        <input id="addLogo"
               ref="input"
               type="file"
               name="image"
               accept="image/*"
               @change="setupLogoCropper"/>
      </div>
    </div>

    <b-modal id="cropLogoModal" title="Crop Logo" size="md"
             no-close-on-backdrop no-close-on-esc centered>
      <div>
        <vue-cropper v-show="logoToCrop"
                     class="vue-cropper"
                     ref="logoCropper"
                     id="logoCropper"
                     :guides="true"
                     :view-mode="3"
                     :aspectRatio="1"
                     drag-mode="move"
                     :zoomOnWheel="true"
                     :background="true"
                     :rotatable="false"
                     :src="logoToCrop"
                     alt="Logo">
        </vue-cropper>
      </div>
      <template slot="modal-footer">
        <div v-if="isUploading">
          <font-awesome-icon v-if="isUploading" icon="spinner"/>
          &nbsp;Uploading Logo...
        </div>
        <div :disabled="isUploading">
          <b-btn variant="primary" @click="uploadLogoImage">
            Upload Logo
          </b-btn>
          <b-btn @click="newLogoImage=null; closeModal('cropLogoModal');">
            Cancel
          </b-btn>
        </div>
      </template>
    </b-modal>

    <b-modal id="removeLogoModal" title="Remove Project Logo?"
             no-close-on-backdrop no-close-on-esc centered>
      <div>
        Confirm you want to remove the Logo from the organization. This cannot
        be undone.
      </div>
      <template slot="modal-footer">
        <b-btn variant="danger" @click="removeLogoImage">
          Yes, Remove Logo
        </b-btn>
        <b-btn @click="closeModal('organization_logo')">
          Cancel
        </b-btn>
      </template>
    </b-modal>

  </b-container>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import VueCropper from "vue-cropperjs";
import VueUploadComponent from 'vue-upload-component';
export default {
  components: {
    VueCropper,
    VueUploadComponent,
  },
  data() {
    return {
      organization: {...this.organizationVuex},
      newLogoImage: null,
      logoToCrop: null,
      isUploading: false,
    }
  },
  watch: {
    newLogoImage(newLogo) {
      if (newLogo) {
        // User picked an image, cropper is initialized
        this.$bvModal.show('cropLogoModal');
      } else {
        // User canceled upload
        this.logoToCrop = null
      }
    },
    uploadLogoStatus(newStatus) {
      if (newStatus) {
        let currentStatus = newStatus.status
        if (currentStatus === 'SUCCESS') {
          this.isUploading = false
          this.closeModal('cropLogoModal')
        } else {
          this.isUploading = currentStatus === 'IS_UPLOADING';
          if (!this.isUploading) {
            // Handle errors here
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      organizationVuex: "organization/get/organization",
      uploadLogoStatus: "organization/images/upload",
    }),
    currentLogoImage() {
      return (this.organizationVuex && this.organizationVuex.logo && this.organizationVuex.logo.path)
        ? this.organizationVuex.logo.path
        : null; //'/assets/images/logo.png'
    },
  },
  methods: {
    ...mapActions({
      updateOrganization: "organization/get/updateOrganization",
      uploadImage: "organization/images/uploadFile",
    }),
    getImageUrlStyle() {
      return this.currentLogoImage ?
        'background-image: url(' + this.currentLogoImage + ')' :
        'background-image: url(' + '/assets/images/generic-logo.png' + ')'
    },
    removeLogoImage() {
      this.updateOrganization({ logo: null })
      this.closeModal('removeLogoModal');
    },
    closeModal(modalName) {
      this.$bvModal.hide(modalName);
    },
    setupLogoCropper(event) {
      const file = event.target.files[0];
      // Check for image file
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }
      // Check for image less than 10mb
      if (file.size > 10 * 1000000) {
        alert('Please select an image file not exceeding 10mb');
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (evt) => {
          this.logoToCrop = evt.target.result;
          // Init the cropper
          this.$refs.logoCropper.replace(evt.target.result)
        };
        this.newLogoImage = file;
        reader.readAsDataURL(file);
      } else {
        console.log('FileReader API not supported by Browser');
      }
    },
    uploadLogoImage() {
      // logo image is uploaded
      const canvas = this.$refs.logoCropper.getCroppedCanvas()
      canvas.toBlob(blob => {
        this.uploadImage({
          blob: blob,
          name: this.newLogoImage.name,
          type: 'organization_logo'
        })
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.logo-container {
  background-size: cover;
  background-position: center bottom;
  background-color: grey;
  height: 160px !important;
  overflow: hidden;
}
.row-bottom-logo {
  margin-top: 130px;
  margin-left: -10px;
}
.col-margin-logo {
  margin-left: -15px;
  margin-right: -15px;
}
.col-margin-logo-replace {
  margin-left: -12px;
  margin-right: -15px;
}
input[type="file"] {
  display: none;
}
</style>
