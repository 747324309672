import SubmissionError from '../../../../error/SubmissionError'
import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const uploadFile = ({ commit }, formData = {}) => {
  commit(types.FILE_UPLOAD_TOGGLE_LOADING)

  const recordId = formData.get('recordId')
  formData.delete('recordId')

  return fetch('/file_objects', {
    method: 'POST',
    body: formData
  })
  .then((response) => {
    commit(types.FILE_UPLOAD_TOGGLE_LOADING)

    return response.json()
  })
  .then((data) => {
    if (data.path) {
      commit(types.FILE_UPLOAD_SET_RETRIEVED, {
        recordId: recordId,
        data: data
      })
    }
  })
  .catch((e) => {
    commit(types.FILE_UPLOAD_TOGGLE_LOADING)

    if (e instanceof SubmissionError) {
      commit(types.FILE_UPLOAD_SET_ERROR, e.errors._error)
      return
    }

    commit(types.FILE_UPLOAD_SET_ERROR, e.message)
  })
}
