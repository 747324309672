<template>
  <div class="card bg-light">
    <div class="card-body">
      <div v-if="!userVuex.zooniverseUsername && !projectVuex.zooniverseUserName">
        <!-- No Zooniverse Username for Project and No Zooniverse Username for Manager -->
        <b-form-group
          id="zooniverse-name-input-group"
          class="mx-auto"
          label-for="zooniverse-username-input"
          label-class="font-weight-bold">
          <template v-slot:label>
            <div><u>Zooniverse Collaborator:</u> Not Linked</div>
          </template>
          <b-form-input
            id="zooniverse-username-input"
            type="text"
            maxlength="100"
            name="name"
            data-vv-scope="zooniverse"
            placeholder="Enter Your Zooniverse Username"
            v-validate="'required|min:1'"
            v-model.trim="lookupUsername"
            :disabled="isLoading"
            aria-describedby="zooniverse-username-input-live-feedback"
          >
          </b-form-input>
          <i v-show="errors.has('zooniverse.name')" class="fa fa-warning"></i>
          <span
            v-show="errors.has('zooniverse.name')"
            class="help is-danger">{{ errors.first("zooniverse.name") }}</span>
          <b-form-invalid-feedback id="zooniverse-username-input-live-feedback">
            {{ errors.first("zooniverse.name") }}
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="card-footer">
          <b-button
            type="submit"
            variant="primary"
            @click="validateZooniverseUsername()"
            :class="{ disabled: isLoadingUser }"
            :disabled="isLoadingUser"
          >
            Look Up Username
            <i class="fas fa-spinner fa-spin ml-2" v-if="isLoadingUser"></i>
          </b-button><br>
          <span v-if="errorUser">{{ errorUser }}</span>
        </div>
      </div>

      <div v-else-if="userVuex.zooniverseUsername && !projectVuex.zooniverseUserName">
        <!-- No Zooniverse Username for Project but Zooniverse Username exists for Manager
             Set Manager as Collaborator -->
        <label><u><b>Zooniverse Username: :</b></u> {{ userVuex.zooniverseUsername }} </label><br>
        <label><u><b>Zooniverse Collaborator:</b></u> Not Associated</label>
        <div class="card-footer">
          <b-button
            type="submit"
            variant="primary"
            @click="setCollaborator"
            :class="{ disabled: isLoadingUser }"
            :disabled="isLoadingUser">
            Associate Username
            <i class="fas fa-spinner fa-spin ml-2" v-if="isLoadingUser"></i>
          </b-button>
        </div>
      </div>

      <div v-else>
        <label><u><b>Zooniverse Collaborator:</b></u> Linked
          <i class="fa fa-check"></i>
        </label><br>
        <label>
          <b>Zooniverse Username: </b>
          {{ projectVuex.zooniverseUserName }}<br/>
          <b>Associated Project Manager: </b>
          {{ projectVuex.zooniverseUserDisplayName }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ZooniverseUsername",
  props: {},
  components: {},
  data() {
    return {
      lookupUsername: null,
    };
  },
  created() {
  },
  watch: {
  },

  computed: {
    ...mapGetters({
      isLoading: "project/get/isLoading",
      projectVuex: "project/get/project",
      userVuex: "user/profile/sessionData",
      isLoadingUser: "project/get/zooniverse/isLoadingUser",
      errorUser: "project/get/zooniverse/messageLoadingUser"
    }),
  },

  methods: {
    ...mapActions({
      checkZooniverseUsername: "project/get/zooniverse/setUser",
      setCollaborator: "project/get/zooniverse/setCollaborator",
    }),
    validateZooniverseUsername() {
      if (this.projectVuex.zooniverseUserName) {
        return;
      }

      // Validate the input field
      this.$validator.validateScopes("zooniverse").then(result => {
        if (result) {
          this.checkZooniverseUsername(this.lookupUsername);
          // this.lookupUsername = null
        }
      });
    },
  }
};
</script>

<style lang="scss" scoped></style>
