<template lang="html">
  <b-dropdown :html="(!isPending) ? '<i class=\'fas fa-plus-circle\'></i>&nbsp;Add Data' : 'Add Data (Pending)'"
              size="lg"
              variant="primary"
              boundary="addDataDropDownSection"
              class="shadow"
              :disabled='isPending'
              style="z-index: 999!important;"
              no-flip
              no-caret>
    <b-dropdown-item v-for="datasheet in publishedDatasheets" :key="datasheet.id"
                     :to="{ name: 'DatasheetView', params: { id: datasheet['id']}}"
                     :data-datasheet-id="datasheet['id']"
                     :alt="datasheet.name"
                     :title="datasheet.name"
                     style="z-index: 999 !important;">
      {{ datasheet.name }}
    </b-dropdown-item>
    <b-dropdown-item v-if="publishedDatasheets.length === 0">
      No Published Datasheets
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters({
      project: 'project/get/project',
      projectId: 'project/get/id',
      isPending: 'project/get/isPending',
      publishedDatasheets: 'project/get/publishedDatasheets'
    }),
  }
}
</script>

<style lang="scss" scoped>
.dropdown-menu {
  position: absolute !important;
  transform: translate3d(28px, 46px, 0px) !important;
  top: 0px !important;
  left: 0px !important;
  will-change: transform;
}
</style>
