import * as types from './mutation_types'

export default {
  [types.PROJECT_CREATE_SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.PROJECT_CREATE_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.PROJECT_CREATE_SET_CREATED] (state, created) {
    Object.assign(state, { created })
  },

  [types.PROJECT_SET_PICTURE_TOGGLE_LOADING] (state, picture) {
    Object.assign(state, { picture })
  },

  [types.PROJECT_SET_BANNER_TOGGLE_LOADING] (state, bannerPicture) {
    Object.assign(state, { bannerPicture })
  },

  [types.PROJECT_CREATE_SET_VIOLATIONS] (state, violations) {
    Object.assign(state, { violations })
  },

  [types.PROJECT_CREATE_RESET] (state) {
    Object.assign(state, {
      created: null,
      error: '',
      isLoading: false,
      violations: null,
      picture: null,
      bannerPicture: null,
    })
  }
}
