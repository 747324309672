import { mapActions, mapGetters } from 'vuex'
import BootstrapVueNavbar from '../../../components/layout/BootstrapVueNavbar'
import BootstrapVueFooter from '../../../components/layout/BootstrapVueFooter'

import {
  NOTIFICATION_MESSAGE,
  NOTIFICATION_TYPE,
  NOTIFICATION_LINK,
  NOTIFICATION_LINK_MESSAGE,
  NOTIFICATION_IMAGE,
} from '../../../config/_entrypoint'

export default {
  name: 'app',
  components:{
    BootstrapVueNavbar,
    BootstrapVueFooter
  },
  methods: {
    ...mapActions({
      checkTokenExpiration: 'user/auth/checkTokenExpiration',
      fetchSession: 'user/profile/fetchSession',

    }),

    getHeaderData: function (value) {
      this.headerData = value
    },

    getProjectProfileFlag(value) {
      this.isProjectProfile = value
    },

    getHomepageFlag(value) {
      this.isHomepage = value
    },

    reset: function() {
      this.headerData = {
        backgroundPath: '',
        headerTitle: '',
        picturePath: '',
        isPrivate: false,
        approveContacts: false,
        members: 0,
        observations: 0,
        locations: 0,
        measurements: 0,
      }
    }
  },

  created (to, from, next) {
    this.checkTokenExpiration(this)
    if(this.isAuthenticated){this.fetchSession();}
    this.reset()

    // Subscribe to account verification mutation
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'user/activate/ACTIVATE_ACCOUNT_SET_ACTIVATED') {
        this.newlyActivated = true
      }
    });

  },

  computed: {
    ...mapGetters({
      forgotSent: 'user/forgotPassword/sent',
      passwordUpdated: 'user/resetPassword/updated',
      userCreated: 'user/register/created',
      isVerified: 'user/profile/isVerified',
      isAuthenticated: 'user/auth/isAuthenticated'
    }),
    displayHeaderBanner() {
      return this.$route.meta.showHeaderBanner
    },

    displayFooter() {
      return !this.$route.meta.showFooter
    },

    displaySponsors() {
      return this.$route.meta.showSponsors
    }
  },

  data() {
    return {
      headerData: {
        backgroundPath: '',
        headerTitle: '',
        picturePath: '',
        isPrivate: false,
        approveContacts: false,
        members: 0,
        observations: 0,
        locations: 0,
        measurements: 0,
      },
      isProjectProfile: false,
      isHomepage: false,
      newlyActivated: false,
      NOTIFICATION_MESSAGE: NOTIFICATION_MESSAGE,
      NOTIFICATION_TYPE: NOTIFICATION_TYPE,
      NOTIFICATION_LINK: NOTIFICATION_LINK,
      NOTIFICATION_LINK_MESSAGE: NOTIFICATION_LINK_MESSAGE,
      NOTIFICATION_IMAGE: NOTIFICATION_IMAGE,
    }
  },

  watch: {
    '$route' (to, from) {
      this.checkTokenExpiration(this)
      this.isHomepage = this.$route.meta.isHomepage || false
    }
  },

  beforeDestroy () {
    this.reset()
  }
}
