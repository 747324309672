import DatasheetCreator from '../components/datasheet/Creator'
import DatasheetPreview from '../components/datasheet/Preview'
import DatasheetView from '../components/datasheet/View'

export default [
  { name: 'DatasheetCreator', path: '/datasheets/creator/:id', component: DatasheetCreator, meta: { hideFooter:true, requiresAuth: true }},
  { name: 'DatasheetPreview', path: '/datasheets/preview/:id/', component: DatasheetPreview, meta: { requiresAuth: true }},
  { name: 'DatasheetView', path: '/datasheets/view/:id/', component: DatasheetView, meta: { requiresAuth: true }},

  // added by Romica
  { name: 'DatasheetCreatorWithLocation', path: '/datasheets/creator/:id/:location', component: DatasheetCreator, meta: { requiresAuth: true }},
  { name: 'DatasheetPrint', path: '/datasheets/print/:id/', component: DatasheetPreview, meta: { requiresAuth: true }}
]
