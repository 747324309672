export const created = state => state.created
export const error = state => state.error
export const createdObservationViolations = state => state.createdObservationViolations
export const isLoading = state => state.isLoading
export const retrievedMembersAssignedLocations = state => state.retrievedMembersAssignedLocations
export const retrievedMembersAssignedLocationsIsLoading = state => state.retrievedMembersAssignedLocationsIsLoading
export const retrievedMembersAssignedLocationsError = state => state.retrievedMembersAssignedLocationsError
export const createdLocationError = state => state.createdLocationError
export const createdLocationIsLoading = state => state.createdLocationIsLoading
export const createdLocation = state => state.createdLocation
export const createdLocationViolations = state => state.createdLocationViolations
