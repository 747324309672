<template>
<div class='bg-light border-bottom'>
    <div class='row no-gutters'>
        <div class='col-lg-2 col-md-3 col-sm-4' v-for="file in files" :key="file.id">
            <div class='position-relative'>
                <img class="forum-photo" v-if="file.url" :src="file.url" />
                <div class="overlay">
                    <div class='btn-group float-right'>
                        <div class='btn btn-danger' @click="$refs.upload.remove(file)"> <i class='fa fa-trash'></i> </div>
                    </div>
                </div>
            </div>

        </div>
        <div class='col text-center p-1'>
            <VueUploadComponent v-bind="options" v-model='files' @input-filter="inputFilter" ref="upload" class='h-100 w-100 forum-photo-add'>
                <div class='d-flex flex-column justify-content-center my-1 h-100'>
                    <div v-show="$refs.upload && !$refs.upload.dropActive" class='flex-none'>
                        <div class=''><i class="fa fa-plus fa-2x pr-3"></i><i class='fa fa-image fa-2x'></i></div>
                        <div class=' bold'> Add Photos </div>
                        <div class=' small'> or drag & drop </div>
                        <div class='text-danger' v-if='fileTypeIssue'>Files must be .jpg, .jpeg, or .png</div>
                        <div class='text-danger' v-if='fileSizeIssue'>Files must be less than 10MB each</div>
                    </div>
                    <div v-show="$refs.upload && $refs.upload.dropActive" class="flex-none drop-active">
                        <h3>Drop files to upload </h3>
                    </div>
                </div>
            </VueUploadComponent>
        </div>

    </div>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import VueUploadComponent from 'vue-upload-component'

export default {
    name: "projectForumFile",
    components: {
        VueUploadComponent
    },

    props: {
        fileURIs: {
            required: false,
            type: Array
        },
        isUploading: {
            default: false
        }

    },

    provide() {
        return {
            parentValidator: this.$validator,
        };
    },

    data() {
        return {
            filesLocal: [],
            options: {
                multiple: true,
                drop: true,

                accept: 'image/png,image/jpeg',

                extensions: 'jpg,jpeg,png',
                size: 1024 * 1024 * 10,
                valueDeterminate: 100,
                imageUploadedSuccessfully: false,
                showUploadedImage: 0
            },
            fileTypeIssue: false,
            fileSizeIssue: false,
        }
    },
    created() {
        // Forum data is fetched
    },

    computed: {
        ...mapGetters({
            membershipMap: "user/profile/membershipMap",
            uploads: "file/multiupload/uploads",
            vuexFiles: "file/multiupload/files",
        }),
        files: {
            get() {
                return this.vuexFiles;
            },
            set(files) {
                this.addFiles(files);
            }
        }
    },

    methods: {
        ...mapActions({
            addFiles: "file/multiupload/addFiles",
        }),

        getNumReplies(posts) {
            return 0;
        },
        inputFilter(newFile, oldFile, prevent) {
            if (newFile && !oldFile) {
                let fileTypeIssue = false;
                let fileSizeIssue = false;
                if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
                    fileTypeIssue = true;
                }
                if (newFile.size > 10 * 1000000) {
                    fileSizeIssue = true;

                }
                if (fileTypeIssue || fileSizeIssue) {
                    this.fileTypeIssue = (fileTypeIssue || this.fileTypeIssue);
                    this.fileSizeIssue = (fileSizeIssue || this.fileSizeIssue);
                    return prevent()
                }
            }
            if (newFile && (!oldFile || newFile.file !== oldFile.file)) {

            }
        },
    },
};
</script>

<style lang="css" scoped>
.forum-photo {
    width: 100%;
    height: auto;
}

.forum-photo-add {
    border: 2px dashed #ffffff;
}

.forum-photo-add:hover {
    border: 2px dashed #666666;
    transition: all .5s ease 0.1s
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
</style>
