<template>
  <div class="row">
    <b-col md="8" sm="12" class="ml-2">
      <b-form-group
        label-class="font-weight-bold ">
      <template slot="label"><u>Select an Option for Auto-Assigning Locations:</u></template>

        <b-form-radio-group
          id="radio-group-2"
          v-model="autoAssign"
          name="radio-sub-component">
          <b-form-radio :value=0>Don't Auto-Assign (Default)</b-form-radio>
          <b-form-radio :value=1>Auto-Assign to Observer</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <b-button variant="secondary" size="sm" @click="collapse = !collapse">
        <i class="fa fa-question-circle mr-2"></i>Find out more
      </b-button>
    </b-col>
    <div class="mt-2 d-flex justify-content-between">
      <b-collapse v-model="collapse">
        <ul class="ml-2">
          <u>Here is how the Auto-Assign functionality works</u>
          <li>
            When making an observation, if the member creates a new location, that new location will be available to
            that member for all datasheets
          </li>
          <li>
            Thereby, saving the member from having to recreate this location for further observations when using different
            datasheets
          </li>
          <li>
            Turn this on if you want to share locations across datasheets for each member
          </li>
          <li>
            Remember that, assigned locations show up only when the datasheet location format is set to 'List of Locations' or 'Both'
          </li>
        </ul>
      </b-collapse>
    </div>
  </div>
</template>

<script>
export default {
  name: "DatasheetAutoAssignSelector",
  props: {
    isAutoAssign: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      autoAssign: this.isAutoAssign,
      collapse: false,
    }
  },
  watch: {
    autoAssign(newVal) {
      // Send the selection to the parent component
      this.$emit('update', newVal)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
