<template>
  <div v-if="organizationId" class="">
    <div class="card-body">
      <h2 class="m-0">Observations</h2>
      <p>From public projects</p>

      <div class="row mb-2">
        <div class="col-md-12">
          <label><b>Select a Project:</b></label>
          <b-form-select
            id="projectSelect"
            class="mb-2"
            v-model="selectedProjectForData"
          >
            <option v-for="project in publicProjects" :value="project.id">
              {{ project.name }}
            </option>
          </b-form-select>
        </div>
      </div>
      <b-row align-h="between">
        <div class="my-1 col-md-3">
          Showing {{ fromItem }} to {{ toItem }} of
          {{ totalItems }} observations
        </div>

        <div class="col-md-3 my-1">
          <b-pagination
            :total-rows="totalItems"
            :per-page="perPage"
            v-model="currentPage"
            class="my-0 pagination-holder"
            prev-text="Prev"
            next-text="Next"
            align="fill"
          />
        </div>

        <div class="col-md-3 my-1">
          <b-form-group
            label="Per Page"
            label-align="right"
            label-cols-sm="7"
            label-cols-md="8"
            label-for="perPageSelect"
            class="mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
              align="right"
            >
            </b-form-select>
          </b-form-group>
        </div>
      </b-row>
    </div>
    <b-table
      show-empty
      class="mb-0"
      stacked="md"
      id="observationsTable"
      ref="observationsTable"
      :busy="isBusy"
      :items="observations"
      :fields="tableFields"
      :current-page="currentPage"
      :per-page="perPage"
      sort-icon-left
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
    >
      <template v-slot:cell(location.name)="row">
        <router-link
          :to="{ name: 'LocationShow', params: { id: row.item.location.id } }"
        >
          {{ row.item.location.name }}
        </router-link>
      </template>
      <template v-slot:cell(options)="row">
        <div>
          <b-btn
            class="mt-1 mr-1"
            :to="{ name: 'ObservationShow', params: { id: row.item.id } }"
            alt="View Observation"
            title="View Observation"
          >
            <font-awesome-icon icon="eye" /> View
          </b-btn>
        </div>
      </template>
      <template #table-busy>
        <VueLoader style="height: 200px" text="Loading Observations..." />
      </template>
    </b-table>
    <div class="card-body border-top">
      <b-row align-h="between">
        <div class="col-md-3 my-1">
          Showing {{ fromItem }} to {{ toItem }} of
          {{ totalItems }} observations
        </div>

        <div class="col-md-3 my-1">
          <b-pagination
            :total-rows="totalItems"
            :per-page="perPage"
            v-model="currentPage"
            class="my-0 pagination-holder"
            prev-text="Prev"
            next-text="Next"
            align="fill"
          />
        </div>

        <div class="col-md-3 my-1">
          <b-form-group
            label="Per Page"
            label-align="right"
            label-cols-sm="7"
            label-cols-md="8"
            label-for="perPageSelect"
            class="mb-0"
          >
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              align="right"
            ></b-form-select>
          </b-form-group>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueLoader from "../../../layout/Loader";
export default {
  name: "OrgObservationsTable",
  components: {
    VueLoader
  },
  data() {
    return {
      selectedProjectForData: null,
      selectedProjectName: null,
      publicProjects: null,
      pageOptions: [10, 25, 50, 100, 250, 500],
      isBusy: false,
      showModal: null,
      selectedObservation: null,
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      searchText: "",
      sortBy: "observedAt",
      sortDesc: true,
      sortDirection: "desc",
      tableFields: [
        {
          key: "project.name",
          label: "Project Name",
          sortable: true,
          sortDirection: "asc",
          tdClass: "text-left"
        },
        {
          key: "observedAt",
          label: "Date Observed",
          sortable: true,
          sortDirection: "asc",
          tdClass: "text-left",
          formatter: val => {
            return this.displayDate(val);
          }
        },
        {
          key: "createdAt",
          label: "Date Created",
          sortable: true,
          sortDirection: "asc",
          tdClass: "text-left",
          formatter: val => {
            return this.displayDate(val);
          }
        },
        // {
        //   key: "datasheet.name",
        //   label: "Datasheet",
        //   sortable: true,
        //   tdClass: "text-dark",
        //   formatter: (val) => {
        //     return val;
        //   },
        // },
        {
          key: "location.name",
          label: "Location name",
          sortable: true,
          sortDirection: "asc",
          tdClass: "text-left",
          formatter: (val, fieldName, item) => {
            return item && item.location && item.location.name;
          }
        },
        {
          key: "observers.user.displayName",
          label: "Observed By",
          sortable: true,
          sortDirection: "asc",
          tdClass: "text-left",
          formatter: (val, fieldName, item) => {
            return (
              item &&
              item.observers &&
              item.observers[0] &&
              item.observers[0].user &&
              item.observers[0].user.displayName
            );
          }
        },
        {
          key: "options",
          label: "Options",
          class: "text-right"
        }
      ]
    };
  },
  created() {
    if (this.projects) {
      // Object.fromEntries(Object.entries(projects).filter(p => !p.isPrivate))
      this.publicProjects = Object.values(this.projects).filter(p => !p.isPrivate)
      this.selectedProjectForData = this.publicProjects[0].id;
      this.selectedProjectName = this.publicProjects[0].name;
    }
  },
  watch: {
    projects(newVal) {
      if (newVal) {
        // Select first available project
        //  - this handles scenario where user navigates directly to /trends
        this.publicProjects = Object.values(newVal).filter(p => !p.isPrivate)
        this.selectedProjectForData = this.publicProjects[0].id;
        this.selectedProjectName = this.publicProjects[0].name;
      }
    },
    selectedProjectForData(newVal) {
      if (newVal && newVal.length > 0) {
        this.selectedProjectName = this.publicProjects.filter(p => p.id === newVal).name;
        this.$refs.observationsTable.refresh();
      }
    },
  },
  computed: {
    ...mapGetters({
      organizationId: "organization/get/id",
      projects: "organization/get/projects"
    }),
    fromItem() {
      return this.totalItems > 0
        ? (this.currentPage - 1) * this.perPage + 1
        : 0;
    },
    toItem() {
      return this.totalItems < this.currentPage * this.perPage
        ? this.totalItems
        : this.currentPage * this.perPage;
    }
  },
  methods: {
    ...mapActions({
      fetchObservations: "observation/organizationList/get/get"
    }),
    observations(ctx) {
      if (this.selectedProjectForData) {
        this.isBusy = true;
        let urlParams = `${this.urlBuilder(ctx)}`;
        const data = { urlParams };
        if (this.selectedProjectName === 'Rapid Stream-Riparian Assessment' ||
            this.selectedProjectForData === '24c609bf-5e00-4499-9cb4-4ff22d309e95') {
          data.locId = ['b4e2fef0-ef1a-4a10-a5fb-0606e0ce70ea']
        } else if (this.selectedProjectForData === '3e2b9d9c-5d3f-4bc7-83a8-f303904ead8c') {
          data.locId = ['f0bfbd7c-3d5e-4eba-b9fe-966006071a6c', '8f541cd1-f359-4005-86d2-876fa2365ff1']
        } else {
          data.id = [this.selectedProjectForData]
        }
        return this.fetchObservations(data).then(data => {
          this.totalItems = data["hydra:totalItems"];
          this.currentPage = ctx.currentPage || 1;
          this.sortBy = ctx.sortBy || "";
          this.isBusy = false;
          return data["hydra:member"].map(observation => {
            return {
              ...observation
            };
          });
        });
      }
    }
  },
  mounted() {}
};
</script>

<style></style>
