import * as types from './mutation_types'

export default {
  // invite people form mutations
  [types.INVITE_PEOPLE_SET_ERROR] (state, invitePeopleError) {
    Object.assign(state, { invitePeopleError })
  },

  [types.INVITE_PEOPLE_TOGGLE_LOADING] (state) {
    Object.assign(state, { invitePeopleIsLoading: !state.invitePeopleIsLoading })
  },

  [types.INVITE_PEOPLE_SET_CREATED] (state, invitePeopleCreated) {
    Object.assign(state, { invitePeopleCreated })
  },

  [types.INVITE_PEOPLE_SET_VIOLATIONS] (state, invitePeopleViolations) {
    Object.assign(state, { invitePeopleViolations })
  },

  [types.INVITE_PEOPLE_RESET] (state) {
    Object.assign(state, {
      invitePeopleCreated: null,
      invitePeopleError: '',
      invitePeopleIsLoading: false,
      invitePeopleViolations: null
    })
  },
  // email members form mutations
  [types.EMAIL_MEMBERS_SET_ERROR] (state, emailMembersError) {
    Object.assign(state, { emailMembersError })
  },

  [types.EMAIL_MEMBERS_TOGGLE_LOADING] (state) {
    Object.assign(state, { emailMembersIsLoading: !state.emailMembersIsLoading })
  },

  [types.EMAIL_MEMBERS_SET_CREATED] (state, emailMembersCreated) {
    Object.assign(state, { emailMembersCreated })
  },

  [types.EMAIL_MEMBERS_SET_VIOLATIONS] (state, emailMembersViolations) {
    Object.assign(state, { emailMembersViolations })
  },

  [types.EMAIL_MEMBERS_RESET] (state) {
    Object.assign(state, {
      emailMembersCreated: null,
      emailMembersError: '',
      emailMembersIsLoading: false,
      emailMembersViolations: null
    })
  }
}
