<template>
  <div>
    <div v-if="isPublicOrMember" class="card-body">
      <h3>Analyses</h3>
      <div class="row mb-2">
        <div class="col-md-12">
          <label><b>Select Location:</b></label>
          <b-form-select
            id="projectLocationsSelect"
            class="mb-2"
            v-model="selectedLocationForAnalysis"
          >
            <option v-for="location in allProjectLocations" :value="location.id">
              {{ location.name }} ({{ location.observationsTotal }} Observations)
            </option>
          </b-form-select>
        </div>
      </div>
      <div class="row">
        <LocationTrends
          v-if="selectedLocationForAnalysis !== null"
          :key="selectedLocationForAnalysis"
          :location-id="selectedLocationForAnalysis"
        />
      </div>
    </div>
    <RequiresMember v-else page="Analysis"></RequiresMember>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LocationTrends from "../../../location/LocationTrends";
import RequiresMember from "../tabpermission/requiresMember.vue";

export default {
  components: {
    RequiresMember,
    LocationTrends
  },

  data() {
    return {
      selectedLocationForAnalysis: null
    };
  },
  created() {
    this.getAllProjectLocations({
      id: this.projectId
    });
  },
  watch: {
    allProjectLocations(newVal) {
      if (newVal && newVal.length > 0) {
        this.selectedLocationForAnalysis = newVal[0].id;
      }
    }
  },
  computed: {
    ...mapGetters({
      projectId: "project/get/id",
      projectError: "project/get/error",
      isPublicOrMember: "project/get/isPublicOrMember",
      isLoadingAllLocations: "project/show/isLoadingAllLocations",
      allProjectLocations: "project/show/allProjectLocations"
    })
  },
  methods: {
    ...mapActions({
      getAllProjectLocations: "project/show/getAllProjectLocations"
    })
  }
};
</script>

<style lang="scss"></style>
