<template>
  <div v-if="project">
    <div class="card-body" v-if="isPublicOrMember">
      <div class="row mb-3">
        <b-col cols="3">
          <h3 class="mb-0">Map</h3>
        </b-col>
      </div>
      <ProjectMap
        :longitude="project.longitude"
        :latitude="project.latitude"
        :projectHome="{lat:project.latitude, lng:project.longitude}"
        :locations="allProjectLocations">
      </ProjectMap>
      <br>
      <div class="ml-4">
        <b-row>
          <h5><u>Legend:</u></h5>
        </b-row>
        <b-row>
          <b-col class="col-md-2">
            <figure>
              <img src="/assets/images/unselected-project-marker.png">
              <figcaption>
                Project Headquarters
              </figcaption>
            </figure>
          </b-col>
          <b-col class="col-md-2">
            <figure class="mt-3">
              <img src="/assets/images/orange-black-outline-dot.png">
              <figcaption>
                Observation Location
              </figcaption>
            </figure>
          </b-col>
        </b-row>
      </div>
    </div>
    <RequiresMember v-else page="Map"></RequiresMember>
  </div>
</template>
<script>
import {
    mapActions,
    mapGetters,
    mapState,
} from 'vuex'
import RequiresMember from "../tabpermission/requiresMember.vue";
import ProjectMap from "../../ProjectMap.vue";

export default {
  components: {
    RequiresMember,
    ProjectMap,
    // VueGoogleMap
  },
  created() {
    // This is needed to load all locations when accessed with the router
    console.log('created project map', this.project, this.isPublicOrMember)
    if (this.project && this.isPublicOrMember) {
      // Get all the locations
      // this.getAllProjectLocations({id: this.project.id});
    }
    // }
  },
  watch: {
    // This is needed to load all locations when accessed with url directly outside router
    project(newValue) {
      if (newValue && this.isPublicOrMember) {
        // Get all the locations
        // this.getAllProjectLocations({id: newValue.id});
      }
    }
  },
  computed: {
    ...mapGetters({
      project: "project/get/project",
      isPublicOrMember: "project/get/isPublicOrMember",
      // allProjectLocations: "project/show/allProjectLocations",
    }),
    ...mapState({
        isLoadingLocations: state => state.project.get.locations.isLoading,
        allProjectLocations: state => state.project.get.locations.allLocations,
      }
    ),

    // mapLocations() {
    //   if (!this.project) {
    //     return null;
    //   }
    //   return {
    //     latitude: this.project.latitude,
    //     longitude: this.project.longitude,
    //     locations: this.allProjectLocations
    //   };
    // }
  },
  methods: {
    ...mapActions({
      // getAllProjectLocations: "project/show/getAllProjectLocations"
    })
  }
};
</script>
<style lang="scss" scoped>
figure {
  display: inline-block;
  text-align: center;
}
</style>
