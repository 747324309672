<template>
  <div v-if="projectId">
    <div class="card-body">
      <div class="row justify-content-between">
        <div class="col-12 h3">
          Members
        </div>
      </div>
      <b-row align-h="between">
        <b-col cols md="3" class="my-1">
          Showing {{ fromItem }} to {{ toItem }} of {{ totalItems }} requests
        </b-col>

        <b-col sm="2" md="3" class="my-1">
          <b-pagination
            :total-rows="totalItems"
            :per-page="perPage"
            v-model="currentPage"
            class="my-0 pagination-holder"
            prev-text="Prev"
            next-text="Next"
            align="fill"
          />
        </b-col>

        <b-col sm="2" md="3" class="my-1">
          <b-form-group
            label="Per Page"
            label-align="right"
            label-cols-sm="7"
            label-cols-md="8"
            label-for="perPageSelect"
            class="mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
              align="right"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <b-table
      show-empty
      stacked="md"
      :items="projectMembers"
      :fields="tableFields"
      :current-page="currentPage"
      :per-page="perPage"
      sort-icon-left
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
    >
      <template slot="dateTitle" slot-scope="data">
        <b-link :to="'/my-profile'">
          {{ data.value }}
        </b-link>
      </template>
      <template #table-busy>
        <VueLoader style="height: 300px" text="Loading Members..." />
      </template>
    </b-table>
    <div class="card-footer border-bottom">
      <b-row align-h="between">
        <b-col cols md="3" class="my-1">
          Showing {{ fromItem }} to {{ toItem }} of {{ totalItems }} requests
        </b-col>

        <b-col sm="2" md="3" class="my-1">
          <b-pagination
            :total-rows="totalItems"
            :per-page="perPage"
            v-model="currentPage"
            class="my-0 pagination-holder"
            prev-text="Prev"
            next-text="Next"
            align="fill"
          />
        </b-col>

        <b-col sm="2" md="3" class="my-1">
          <b-form-group
            label="Per Page"
            label-align="right"
            label-cols-sm="7"
            label-cols-md="8"
            label-for="perPageSelect"
            class="mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
              align="right"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueLoader from "../../../layout/Loader.vue";

export default {
  components: {
    VueLoader
  },
  data() {
    return {
      pageOptions: [10, 25, 50, 100, 250, 500],
      isLoading: false,
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      sortBy: "approvedAt",
      sortDesc: true,
      sortDirection: "desc",
      tableFields: [
        { key: "displayName", label: "Name", sortable: true },
        {
          key: "role",
          label: "Role",
          formatter: val => {
            return this.displayRole(val);
          },
          sortable: true
        },
        {
          key: "approvedAt",
          label: "Date Joined",
          tdClass: "color-gray",
          formatter: val => {
            return this.displayDate(val);
          },
          sortable: true
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      projectId: "project/get/id",
      membershipMap: "user/profile/membershipMap"
    }),
    fromItem() {
      return this.totalItems > 0
        ? (this.currentPage - 1) * this.perPage + 1
        : 0;
    },
    toItem() {
      return this.totalItems < this.currentPage * this.perPage
        ? this.totalItems
        : this.currentPage * this.perPage;
    }
  },
  watch: {
    sortBy(newVal) {
      if (newVal === "displayName") {
        this.sortBy = "member.displayName";
      } else {
        this.sortBy = newVal;
      }
    }
  },
  methods: {
    ...mapActions({
      joinToProject: "project/show/joinToProject",
      fetchProjectMembers: "project/show/fetchProjectMembers"
    }),
    projectMembers(ctx) {
      ctx.sortBy = this.sortBy;
      let urlParams = `${this.urlBuilder(ctx)}&approved=true`;
      const data = { projectId: this.projectId, urlParams };
      return this.fetchProjectMembers(data).then(data => {
        this.totalItems = data["hydra:totalItems"];
        this.currentPage = ctx.currentPage || 1;
        this.sortBy = ctx.sortBy || "";
        return data["hydra:member"].map(member => {
          return {
            approvedAt: member.approvedAt,
            displayName: member.displayName,
            role: member.role
          };
        });
      });
    }
  }
};
</script>

<style></style>
