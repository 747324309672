import fetch from '../../../../utils/fetch'

const getInitialState = () => {
  return {
    isLoading: false,
    observations: null,
    bigBendObservations: [],
    error: null,
  }
}

const state = getInitialState();

const getters = {
  observations(state) {
    return state.observations
  },
  bigBendObservations(state) {
    if (state.bigBendObservations.length > 0) {
      // Sort and return only top 6
      return state.bigBendObservations.sort(function (a, b) {
        return new Date(b.observedAt) - new Date(a.observedAt)
      }).slice(0, 6)
    } else {
      return state.bigBendObservations
    }
  },
  isLoading(state) {
    return state.isLoading
  },
  error(state) {
    return state.error
  },
}

const actions = {
  /**
   * @function getRecentPhotoObservations - retrieve recent photo observations from projects in this hub
   * @param commit
   * @param state
   * @param rootGetters
   * @param itemsPerPage
   * @returns {Promise<T | never> | * | *}
   */
  get({commit, state, rootGetters}, itemsPerPage) {
    commit(types.RESET)
    commit(types.SET_PHOTO_OBSERVATIONS_LOADING, true)
    if (!itemsPerPage) {
      itemsPerPage = 6
    }
    let projects = rootGetters["organization/get/projects"]
    let projArr = Object.keys(projects).map(key => {
      return projects[key].id;
    });
    if (projArr.length > 0) {
      let urlParams = "?order[observedAt]=desc&" +
        "project.id[]=" + projArr.join("&project.id[]=") +
        "&records.recordType=image&itemsPerPage=" + itemsPerPage
      return fetch('/observations' + urlParams)
        .then(response => response.json())
        .then((data) => {
          commit(types.SET_PHOTO_OBSERVATIONS, data['hydra:member'])
        })
        .catch((error) => {
          commit(types.SET_PHOTO_OBSERVATIONS_ERROR, error)
        })
    } else {
      commit(types.SET_PHOTO_OBSERVATIONS, null)
    }
  },

  getBigBend({commit, state, rootGetters}, itemsPerPage) {
    commit(types.RESET)
    commit(types.SET_PHOTO_OBSERVATIONS_LOADING, true)
    if (!itemsPerPage) {
      itemsPerPage = 6
    }
    let projects = rootGetters["organization/get/projects"]
    let projArr = Object.keys(projects).map(key => {
      return {id: projects[key].id, urlField: projects[key].urlField, name: projects[key].name}
    });
    if (projArr.length < 1) {
      commit(types.SET_BIGBEND_PHOTO_OBSERVATIONS, null)
    } else {
      let urlParams = "?order[observedAt]=desc" + "&records.recordType=image&itemsPerPage=" + itemsPerPage
      let locId = null
      projArr.forEach((proj) => {
        if (proj.urlField === 'rapid-stream-riparian-assessment') {
          // RSRA
          locId = ['b4e2fef0-ef1a-4a10-a5fb-0606e0ce70ea']
        } else if (proj.urlField === 'utah-water-watch') {
          // UWW
          locId = ['f0bfbd7c-3d5e-4eba-b9fe-966006071a6c', '8f541cd1-f359-4005-86d2-876fa2365ff1']
        }
        if (locId) {
          urlParams = urlParams + "&location.id[]=" + locId.join("&location.id[]=")
        }
        return fetch('/projects/' + proj.id + '/observations' + urlParams)
          .then(response => response.json())
          .then((data) => {
            if (data) {
              let obs = data['hydra:member']
              obs.forEach(o => {
                o.project = proj
              })
              commit(types.SET_BIGBEND_PHOTO_OBSERVATIONS, obs)
            }
          })
          .catch((error) => {
            commit(types.SET_PHOTO_OBSERVATIONS_ERROR, error)
          })
      })
    }
    commit(types.SET_PHOTO_OBSERVATIONS_LOADING, false)
  },
}

const types = {
  SET_PHOTO_OBSERVATIONS_LOADING: 'SET_PHOTO_OBSERVATIONS_LOADING',
  SET_PHOTO_OBSERVATIONS: 'SET_PHOTO_OBSERVATIONS',
  SET_BIGBEND_PHOTO_OBSERVATIONS: 'SET_BIGBEND_PHOTO_OBSERVATIONS',
  SET_PHOTO_OBSERVATIONS_ERROR: 'SET_PHOTO_OBSERVATIONS_ERROR',
  RESET_ERROR: 'RESET_ERROR',
  RESET: 'RESET',
}

const mutations = {
  [types.SET_PHOTO_OBSERVATIONS_LOADING](state, value) {
    state.isLoading = value;
  },
  [types.SET_PHOTO_OBSERVATIONS](state, observations) {
    state.observations = observations;
    state.isLoading = false;
  },
  [types.SET_BIGBEND_PHOTO_OBSERVATIONS](state, observations) {
    state.bigBendObservations = [...state.bigBendObservations, ...observations];
  },
  [types.SET_PHOTO_OBSERVATIONS_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },
  [types.RESET_ERROR](state) {
    state.isLoading = false;
    state.error = false;
  },
  [types.RESET](state) {
    Object.assign(state, getInitialState());
  },
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
}
