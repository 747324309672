<template>
  <button class="btn btn-secondary" @click="onClickAuthorizeAirtable">Authorize</button>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {airTableOAuth} from "../../../../main";

export default {
  data() {
    return {};
  },
  created() {},
  watch: {},
  computed: {
    ...mapGetters({
      userSession: 'user/profile/sessionData',
    }),
  },
  methods: {
    ...mapActions({
      saveProjectId: 'integration_airtable/authorize/saveProjectId',
    }),

    // Handle authorize with airtable
    async onClickAuthorizeAirtable() {
      // Current project id is saved to local storage
      //  - this facilitates returning the user to the project after authorizing with airtable
      this.saveProjectId()
      // Set the data param that will be round tripped
      let randData = window.crypto.getRandomValues(new Uint32Array(28));
      airTableOAuth.createSigninRequest({state: {bar: randData}})
        .then(function (req) {
          window.location = req.url;
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }
}
</script>

<style lang="scss">
</style>
