/**
 * @function sendDataToHeader - send data to header to display the header banner
 * @param {object} data - the object that populates banner header and will have the follow structure
 *    {
 *        backgroundPath: 'path/of/banner/image/url',
 *        picturePath: 'path/of/company/logo',
 *        headerTitle:  'Page name', // default is 'CitSci 2.0'
 *    }
 */
export const sendDataToHeader = (data, that) => {
  let messageError = ''

  if (typeof data == "undefined") {
    messageError = 'Please provide data to send to the header component.'
    messageError += '\nObject structure: \n{\n' +
      '    backgroundPath: (string),\n' +
      '    picturePath: (string),\n' +
      '    headerTitle: (string)\n' +
      '  }'
    throw new TypeError(messageError)
  }

  if (typeof that == "undefined") {
    messageError = "Please provide us the object instance (this variable)."
    throw new TypeError(messageError)
  }

  if (typeof that.$emit == "undefined") {
    messageError = "Please provide us the object instance (this variable)."
    throw new TypeError(messageError)
  }

  var dataToSend = {
    backgroundPath: '',
    picturePath: '',
    headerTitle: 'CitSci 2.0'
  }

  that.$emit('header-data-evt', data || dataToSend)
}
