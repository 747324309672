<template>
  <div v-if="projectVuex">
    <div v-if="isPublicOrMember">
      <List v-if="!isManager"></List>
      <Invites v-if="isManager"></Invites>
      <Pending v-if="isManager"></Pending>
      <Manage v-if="isManager"></Manage>
    </div>
    <RequiresMember v-else page="Members"></RequiresMember>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import List from "./list";
import Invites from "./invites";
import Pending from "./pending";
import Manage from "./manage";
import RequiresMember from "../tabpermission/requiresMember.vue";

export default {
  components: {
    RequiresMember,
    List,
    Manage,
    Pending,
    Invites
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      projectVuex: "project/get/project",
      isPublicOrMember: "project/get/isPublicOrMember",
      isManager: "project/get/isManager",
    }),
  }
};
</script>

<style lang=""></style>
