import list from './list'
import create from './create'
import update from './update'
import show from './show'
import del from './delete'
import register from './register'
import auth from './auth'
import forgotPassword from './forgot_password'
import resetPassword from './reset_password'
import profile from './profile'
import activate from './activate'

export default {
  namespaced: true,
  modules: {
    list,
    create,
    update,
    show,
    del,
    register,
    auth,
    forgotPassword,
    resetPassword,
    profile,
    activate,
  }
}
