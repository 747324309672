import * as types from './mutation_types'

export default {
  [types.RESET_OBSERVATIONS] (state) {
    Object.assign(state, {
      error: '',
      violations: null,
      isLoading: false,
      items: null
    })
  },

  [types.OBSERVATION_LIST_SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.OBSERVATION_LIST_RESET_ERROR] (state) {
    Object.assign(state, { error: '' })
  },

  [types.OBSERVATION_LIST_SET_VIOLATIONS] (state, observationViolations) {
    Object.assign(state, { observationViolations })
  },

  [types.OBSERVATION_LIST_SET_RETRIEVED] (state, items) {
    Object.assign(state, { items: items })
  },

  [types.OBSERVATION_LIST_LOADING_TOGGLE] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.OBSERVATIONS_ALL_LIST_SET_RETRIEVED] (state, allItems) {
    Object.assign(state, { allItems: allItems })
  },

  [types.OBSERVATIONS_ALL_LIST_LOADING_TOGGLE] (state) {
    Object.assign(state, { isLoadingAll: !state.isLoadingAll })
  }
}
