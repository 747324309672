import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,
  state: {
    // invite people form values
    invitePeopleIsLoading: false,
    invitePeopleError: '',
    invitePeopleCreated: null,
    invitePeopleViolations: null,
    // email members form values
    emailMembersIsLoading: false,
    emailMembersError: '',
    emailMembersCreated: null,
    emailMembersViolations: null
  },
  actions,
  getters,
  mutations
}
