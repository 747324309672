import { mapActions, mapGetters } from "vuex";
// import { googleOIDC, sciStarterOIDC } from "../../../main";

export default {
  data() {
    return {
      creds: {}
    };
  },

  beforeDestroy() {
    this.reset();
  },

  created() {
    this.$validator.validateAll();
  },

  mounted () {
    let myEle = document.getElementById("forgotSent");
    if(myEle) {
        document.getElementById("UniqueLoginContainer").innerHTML =
          "<div class='w-75 ml-auto mr-auto'>" +
          "<h1>Thanks.</h1>" +
          "<p>You're almost done!</p>" +
          "<br/><br/>" +
          "<p>Please check your email for a reset password link.</p" +
          "><p>Click the link in the email, so you can enter a new password.</p>" +
          "<p>Once you click the link provided and enter your new password, please log in.</p>" +
          "<br/><br/><p>Thanks,</p>" +
          "<p>The CitSci Team</p>" +
          "</div><br/><br/><br/><br/><br/><br/><br/><br/>";
    }
  },

  computed: {
    ...mapGetters({
      error: "user/auth/error",
      isLoading: "user/auth/isLoading",
      isLoadingSession: "user/profile/isLoadingSession",
      hasSession: "user/profile/hasSession",
      isAuthenticated: "user/auth/isAuthenticated",
      resetPassword: "user/auth/resetPassword",
      violations: "user/auth/violations"
    }),

    isFilled() {
      return (
        this.creds.email && this.creds.password && !this.errors.items.length
      );
    }
  },

  watch: {
    hasSession() {
      this.redirectOnLogin();
    },
    isAuthenticated() {
      this.redirectOnLogin();
    },
    resetPassword(resetPassword) {
      if (!resetPassword) {
        return;
      }
      this.$router.push({ name: "UpdatePassword" });
    }
  },

  methods: {
    ...mapActions({
      authenticate: "user/auth/authenticate",
      forgotReset: "user/forgotPassword/reset",
      resetPasswordReset: "user/resetPassword/reset",
      activateReset: "user/activate/reset"
    }),

    redirectOnLogin() {
      if ((this.hasSession === true) && (this.isAuthenticated === true)) {
        if (
          !this.$route.query.redirect ||
          this.$route.query.redirect.indexOf("logout") !== -1 ||
          this.$route.query.redirect.indexOf("/404") !== -1
        ) {
          this.$router.push({ name: "UserProfile" });
        } else {
          this.$router.push({ path: this.$route.query.redirect });
        }
      }
    },
    // Handle sign in with scistarter
    // onClickSignInWithSciStarter() {
    //   sciStarterOIDC.clearStaleState();
    //   sciStarterOIDC
    //     .createSigninRequest({ state: { bar: 15 } })
    //     .then(function(req) {
    //       // console.log("scistarter signin request", req)
    //       window.location = req.url;
    //     })
    //     .catch(function(err) {
    //       console.log(err);
    //     });
    // },
    //
    // // Handle sign in with google
    // onClickSignInWithGoogle() {
    //   googleOIDC.clearStaleState();
    //   googleOIDC
    //     .createSigninRequest({ state: { bar: 15 } })
    //     .then(function(req) {
    //       // console.log("google signin request", req)
    //       window.location = req.url;
    //     })
    //     .catch(function(err) {
    //       console.log(err);
    //     });
    // },

    onClickLogin() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.authenticate({ creds: this.creds });
        }
      });
    },

    isInvalid(key) {
      return (
        (this.violations !== null &&
          Object.keys(this.violations).length > 0 &&
          this.violations[key]) ||
        this.errors.has(key)
      );
    },

    getError(key) {
      if (this.errors.has(key)) {
        return this.errors.first(key);
      }

      if (
        this.violations &&
        Object.keys(this.violations).length > 0 &&
        this.violations[key]
      ) {
        return this.violations.key;
      }
    },

    reset() {
      this.forgotReset();
      this.resetPasswordReset();
      this.activateReset();
    }
  }
};
