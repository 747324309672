import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'
import SubmissionError from "../../../../error/SubmissionError";


/**
 * @function getLocationDetails
 * @param commit
 * @param data
 * @returns {Promise<T | never> | * | *}
 */
export const getLocationDetails = ({ commit }, data) => {
  commit(types.LOCATION_SHOW_DETAILS_TOGGLE_LOADING)
  return fetch('/areas/' + data)
    .then(response => response.json())
    .then((data) => {
      commit(types.LOCATION_SHOW_DETAILS_TOGGLE_LOADING)
      commit(types.LOCATION_SHOW_DATA, data)
      return data
    })
    .catch((e) => {
      commit(types.LOCATION_SHOW_DETAILS_TOGGLE_LOADING)
      commit(types.LOCATION_SHOW_DETAILS_SET_ERROR, e.message)
    })
}

/**
 * @function getAllObservationForTheLocation
 * @param commit
 * @param data
 * @returns {Promise<T | never> | * | *}
 */
export const getObservationForLocation = ({ commit }, data) => {
  commit(types.LOCATION_SHOW_DETAILS_TOGGLE_LOADING)
  commit(types.LOCATION_SHOW_DETAILS_SET_ERROR, '')
  let id = data.id
  let urlParams = data.urlParams
  return fetch('/areas/' + id + '/observations' + urlParams)
    .then(response => response.json())
    .then((data) => {
      commit(types.LOCATION_SHOW_DETAILS_TOGGLE_LOADING)
      commit(types.LOCATION_SHOW_OBSERVATIONS, data['hydra:member'])

      return data
    })
    .catch((e) => {
      commit(types.LOCATION_SHOW_DETAILS_TOGGLE_LOADING)
      commit(types.LOCATION_SHOW_DETAILS_SET_ERROR, e.message)
    })
}



/**
 * @function updateLocationInfo
 * @param commit
 * @param data
 * @returns {Promise<T | never> | * | *}
 */
export const updateLocationInfo = ({ commit }, data) => {
  commit(types.LOCATION_SHOW_DETAILS_SET_ERROR, '')
  commit(types.LOCATION_SHOW_DETAILS_TOGGLE_LOADING)
  const id = data.id;

  debugger;
  return fetch('/areas/'+id , {
    method: 'PATCH',
    headers: new Headers({ 'Content-Type': 'application/ld+json' }),
    body: JSON.stringify(data.locationInfo)
  })
    .then(response => response.json())
    .then((data) => {
      debugger;
      commit(types.LOCATION_SHOW_DETAILS_TOGGLE_LOADING)
      commit(types.AREA_SHOW_SET_UPDATED, data);

      return data
    })
    .catch((e) => {
      commit(types.LOCATION_SHOW_DETAILS_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        // eslint-disable-next-line
        commit(types.LOCATION_SHOW_DETAILS_SET_ERROR, e.errors._error)

        return
      }

      // eslint-disable-next-line
      commit(types.LOCATION_SHOW_DETAILS_SET_ERROR, e.errors)
    })
}
