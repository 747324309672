<template>
  <b-nav card-header tabs fill class="organization-nav flex-wrap-reverse">
    <b-nav-item
      link-classes="organization-nav-link"
      :to="{ name: 'OrganizationHome', params: { id: $route.params.id } }"
      exact-active-class="active"
    >
      <i class="fa fa-home mr-2"></i>
      Home
    </b-nav-item>

    <b-nav-item
      link-classes="organization-nav-link"
      :to="{ name: 'OrganizationResults', params: { id: $route.params.id } }"
      exact-active-class="active"
    >
      <i class="fa fa-flask mr-2"></i>
      Results
    </b-nav-item>

    <b-nav-item
      link-classes="organization-nav-link"
      :to="{ name: 'OrganizationData', params: { id: $route.params.id } }"
      exact-active-class="active"
    >
      <i class="fa fa-binoculars mr-2"></i>
      Data
    </b-nav-item>

    <b-nav-item
      link-classes="organization-nav-link"
      :to="{ name: 'OrganizationResources', params: { id: $route.params.id } }"
      exact-active-class="active"
    >
      <i class="fa fa-file-alt mr-2"></i>
      Resources
    </b-nav-item>

<!--    <b-nav-item-->
<!--      link-classes="organization-nav-link"-->
<!--      :to="{ name: 'OrganizationForum', params: { id: $route.params.id } }"-->
<!--      exact-active-class="active"-->
<!--    >-->
<!--      <i class="fa fa-comments mr-2"></i>-->
<!--      Forum-->
<!--    </b-nav-item>-->

    <b-nav-item
      link-classes="organization-nav-link"
      :to="{ name: 'OrganizationAnalysis', params: { id: $route.params.id } }"
      exact-active-class="active"
    >
      <i class="fa fa-line-chart mr-2"></i>
      Trends
    </b-nav-item>

    <b-nav-item
      v-if="isManager"
      class="organization-nav-link-manager"
      link-classes="organization-nav-link"
      :to="{ name: 'OrganizationEdit', params: { id: $route.params.id } }"
      exact-active-class="active"
      ><i class="fa fa-tasks mr-2"></i>
      Manage Hub
    </b-nav-item>
    <!--    <b-nav-item-->
    <!--      link-classes="organization-nav-link"-->
    <!--      :class="{ 'organization-nav-link-manager': isManager }"-->
    <!--      :to="{ name: 'OrganizationMembers', params: { id: $route.params.id } }"-->
    <!--      exact-active-class="active"-->
    <!--    ><i class="fa fa-users mr-2"></i>-->
    <!--      Members-->
    <!--    </b-nav-item>-->
  </b-nav>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      organizationId: "organization/get/id",
      isLoading: "organization/get/isLoading",
      membershipMap: "user/profile/organizationMembershipMap"
    }),
    isManager() {
      return (
        this.organizationId &&
        this.membershipMap[this.organizationId] &&
        this.membershipMap[this.organizationId].isManager
      );
    }
  }
};
</script>

<style lang="scss">
.organization-nav-link {
  color: #3b3b3b;
  font-weight: bold;
  &:hover {
    color: #00AAEF; /*#00AAEF #007fb1 */
    font-weight: bold;
  }
}
b-nav-item.active {
  border-bottom: 2px solid grey;
}
.organization-nav-link-manager {
  background-color: #ffd966;
  &:active {
    //background-color: #000000;
  }
}
</style>
