<template>
<div>
    <h3>Observation Data</h3>
    <div v-if="true" class="alert alert-warning">
        <i class="fa fa-warning"></i>
        Only existing data can be edited at this time. We are working on letting
        you add new data based on unanswered questions from the datasheet.
    </div>
    <div class="observation-record-list" v-if="records.length > 0">
        <observation-edit-record-item v-for="record in records" :record="record" v-bind:key="record['@id']">
        </observation-edit-record-item>
    </div>
</div>
</template>

<script>
import ObservationEditRecordItem from "./RecordItem.vue";
export default {
    name: "ObservationRecordList",
    components: {
        ObservationEditRecordItem
    },
    props: {
        records: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
};
</script>

<style>
</style>
