<template>
  <b-modal id="assign-locations-modal"
           title="Add Assigned Locations"
           ref="assignLocationsModal"
           size="xl"
           button-size="sm"
           hide-header-close
           no-close-on-backdrop
           no-close-on-esc
           no-stacking
           centered
           hide-footer>
    <div class="card-body p-0">
      <div class="row mt-2 mb-2 justify-content-start">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <b-form-group
            id="select-member"
            label-cols="12"
            content-cols="12"
            label="Select a Project Member:"
            label-class="font-weight-bold pt-0"
            label-for="member-select"
            description="Search Members by their First or Last name...">
            <multiselect v-model="selectedMember"
                         id="member-select"
                         track-by="id"
                         :custom-label="memberFirstLast"
                         placeholder="Start typing to search Members..."
                         open-direction="bottom"
                         :options="memberSearchResults"
                         :multiple="false"
                         :searchable="true"
                         :loading="isBusyMember"
                         :internal-search="false"
                         :clear-on-select="true"
                         :close-on-select="true"
                         :options-limit="30"
                         :limit="3"
                         :max-height="200"
                         :show-no-results="true"
                         :show-no-options="false"
                         :hide-selected="true"
                         @search-change="memberSearch">
              <span slot="noResult">No members found... Consider changing the search...</span>
            </multiselect>
            <b-button class="float-right" size="sm" :disabled="!selectedMember" @click="clearMember">
              Clear Selected Member
            </b-button>
          </b-form-group>
        </div>
      </div>

      <div class="row mt-2 mb-2 justify-content-start">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <b-form-group
            id="select-location"
            label-cols="12"
            content-cols="12"
            label="Select Project Locations:"
            label-class="font-weight-bold pt-0"
            label-for="location-select"
            description="Search Locations by name... Multiple selections allowed...">
            <multiselect v-model="selectedLocations"
                         id="location-select"
                         track-by="id"
                         :custom-label="locationLatLon"
                         placeholder="Start typing to search Locations..."
                         open-direction="bottom"
                         :options="locationSearchResults"
                         :multiple="true"
                         :searchable="true"
                         :loading="isBusyLocation"
                         :internal-search="false"
                         :clear-on-select="false"
                         :close-on-select="false"
                         :options-limit="30"
                         :max-height="200"
                         :show-no-results="true"
                         :show-no-options="false"
                         :hide-selected="true"
                         @search-change="locationSearch">
              <span slot="noResult">No locations found... Consider changing the search...</span>
            </multiselect>
            <b-button class="float-right" size="sm" :disabled="selectedLocations.length === 0" @click="clearLocation">
              Clear Selected Locations
            </b-button>
          </b-form-group>
        </div>
      </div>

      <div class="row mt-2 mb-2 justify-content-start">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <b-form-group
            id="select-assignment-type"
            label-cols="12"
            content-cols="12"
            label="Select Assignment Type:"
            label-class="font-weight-bold pt-0"
            label-for="assigned-location-type-input"
            description="Select between Project Level(assigned to all datasheets) and Datasheet Specific(assigned to one datasheet)">
            <b-form-radio-group
              id="assigned-location-type-input"
              :options=assignmentTypes
              name="assignment type"
              v-model="assignmentType"
              v-validate="'required'"
              :class="{ 'is-invalid': errors.has('assignment type') }"
              aria-describedby="assignment-type-input-live-feedback"></b-form-radio-group>
            <b-form-invalid-feedback id="resource-type-input-live-feedback">
              {{ errors.first('assignment type') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group v-if="assignmentType && assignmentType === assignmentTypes[1].value"
                        id="select-datasheet"
                        label-cols="12"
                        content-cols="12"
                        label="Select a Project Datasheet:"
                        label-class="font-weight-bold pt-0"
                        label-for="datasheet-select"
                        description="Search Datasheets by name... Only datasheets with Location Format set to List of Locations or Both are available...">
            <multiselect v-model="selectedDatasheet"
                         id="datasheet-select"
                         track-by="id"
                         :custom-label="datasheetLabel"
                         placeholder="Start typing to search Datasheets..."
                         open-direction="bottom"
                         :options="eligibleDatasheets"
                         :multiple="false"
                         :searchable="true"
                         :internal-search="true"
                         :clear-on-select="true"
                         :close-on-select="true"
                         :options-limit="30"
                         :limit="3"
                         :max-height="200"
                         :show-no-results="true"
                         :show-no-options="false"
                         :hide-selected="true">
              <span slot="noResult">No datasheets found... Consider changing the search...</span>
            </multiselect>
            <b-button class="float-right" size="sm" :disabled="!selectedDatasheet" @click="clearDatasheet">
              Clear Selected Datasheet
            </b-button>
          </b-form-group>
        </div>
      </div>

      <div class="row mt-2 mb-2 justify-content-start">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <b-button class="md"
                    variant="tertiary"
                    title="Add New Assigned Location"
                    alt="Add New Assigned Location"
                    @click="addAssignment"
                    :disabled="isBusyMember || isBusyLocation">
            Add New Assigned Location to Table Below
          </b-button>
          <div v-show="showRequiredError" class="required-feedback">
            <span>One or more required selection's are missing...</span>
          </div>
          <div v-show="showDuplicateError" class="required-feedback">
            <span>{{ duplicateErrorString }}</span>
          </div>
        </div>
      </div>
      <hr>
      <div class="row mt-2 mb-2 justify-content-start">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <h5>New Assigned Locations: (unsaved, click 'Save')</h5>
          <div class="float-right" v-if="totalItems > 0">
            <b-button size="sm" variant="secondary" @click="tempAssignedLocations = []">Remove All</b-button>
          </div>
          <div class="row justify-content-between">
            <b-col md="4" class="my-1">
              Showing {{ fromItem }} to {{ toItem }} of
              {{ totalItems }} new assigned location<span
              v-if="totalItems === 0 || totalItems > 1"
            >s</span
            >
            </b-col>
            <b-col sm="2" md="3" class="my-1">
              <b-pagination
                :total-rows="totalItems"
                :per-page="perPage"
                v-model="currentPage"
                class="my-0 pagination-holder"
                prev-text="Prev"
                next-text="Next"
                align="fill"
              />
            </b-col>

            <b-col sm="2" md="3" class="my-1">
              <b-form-group
                label="Per Page"
                label-align="right"
                label-cols-sm="7"
                label-cols-md="8"
                label-for="perPageSelect"
                class="mb-0"
              >
                <b-form-select
                  v-model="perPage"
                  id="perPageSelect"
                  size="sm"
                  :options="pageOptions"
                >
                </b-form-select>
              </b-form-group>
            </b-col>
          </div>
          <b-table
            :items="tempAssignedLocations"
            :fields="fieldList"
            :current-page="currentPage"
            :per-page="perPage"
            :total-rows="totalItems"
            sort-icon-left
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :empty-text="emptyText"
            :striped="false"
            responsive="md"
            small
            pagination
            show-empty
            fixed
            hover
          >
            <template v-slot:empty="scope">
              <h6>{{ scope.emptyText }}</h6>
            </template>
            <template #cell(type)="row">
              {{ row.item.datasheet ? 'Datasheet Specific' : 'Project Level' }}
            </template>
            <template #cell(location)="row">
              {{ row.item.location.name }} ({{ row.item.location.latitude }}, {{ row.item.location.longitude }})
            </template>
            <template v-slot:cell(options)="row">
              <div class="options-holder float-right">
                <b-btn
                  size="sm"
                  class="my-1 mr-1"
                  @click="deleteAssignment(row.item)"
                  alt="Remove this Assigned Location"
                  title="Remove this Assigned Location"
                >
                  <font-awesome-icon icon="times"/>
                  Remove
                </b-btn>
              </div>
            </template>
          </b-table>
          <div v-if="totalItems > 10" class="row justify-content-between">
            <b-col md="4" class="my-1">
              Showing {{ fromItem }} to {{ toItem }} of
              {{ totalItems }} new assigned location<span
              v-if="totalItems === 0 || totalItems > 1"
            >s</span
            >
            </b-col>
            <b-col sm="2" md="3" class="my-1">
              <b-pagination
                :total-rows="totalItems"
                :per-page="perPage"
                v-model="currentPage"
                class="my-0 pagination-holder"
                prev-text="Prev"
                next-text="Next"
                align="fill"
              />
            </b-col>

            <b-col sm="2" md="3" class="my-1">
              <b-form-group
                label="Per Page"
                label-align="right"
                label-cols-sm="7"
                label-cols-md="8"
                label-for="perPageSelect"
                class="mb-0"
              >
                <b-form-select
                  v-model="perPage"
                  id="perPageSelect"
                  size="sm"
                  :options="pageOptions"
                >
                </b-form-select>
              </b-form-group>
            </b-col>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <div class="row mt-2 justify-start-ns justify-start">
          <div class="col-3 mr-2">
            <b-button class="md"
                      variant="primary "
                      type="submit"
                      title="Save New Assigned Locations"
                      alt="Save New Assigned Locations"
                      @click="onSaveClick"
                      :disabled="tempAssignedLocations.length === 0">
              Save New Assigned Locations
            </b-button>
          </div>
          <div class="col-2 ml-2">
            <b-button variant="secondary"
                      title="Discard Unsaved Assigned Locations"
                      alt="Discard Unsaved Assigned Locations"
                      @click="resetModal()">Cancel
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import VueLoader from "../../../layout/Loader.vue";

export default {
  components: {
    VueLoader
  },

  props: {},

  data() {
    return {
      componentKey: 0,

      isBusyMember: false,
      selectedMember: null,

      selectedLocations: [],
      isBusyLocation: false,

      selectedDatasheet: null,
      isBusyDatasheet: false,

      assignmentType: null,
      assignmentTypes: [
        {text: "Project Level", value: "project"},
        {text: "Datasheet Specific", value: "datasheet"}
      ],

      tempAssignedLocations: [],
      showRequiredError: false,
      showDuplicateError: false,
      duplicateErrorString: "Duplicate assigned locations were ignored...",

      fieldList: [
        {key: "member.displayName", label: "Member", sortable: true},
        {key: "type", label: "Assignment Type", sortable: true},
        {key: "datasheet.name", label: "Datasheet", sortable: true},
        {key: "location", label: "Location", sortable: true},
        {key: "options", label: "Options", sortable: false, class: "text-right"}
      ],

      totalItems: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      sortBy: "member.displayName",
      sortDesc: false,
      sortDirection: "asc",
      emptyText: "No New Assigned Locations Added",
    };
  },

  created() {
    this.$_debounceTimer = null;
  },
  watch: {
    assignmentType(newVal) {
      if (newVal && newVal === 'project') {
        // this.clearDatasheet()
      }
      if (newVal) {
        this.showDuplicateError = false
        this.showRequiredError = false
      }
    },
    selectedMember(newVal) {
      if (newVal) {
        this.showDuplicateError = false
        this.showRequiredError = false
      }
    },
    selectedLocations(newVal) {
      if (newVal) {
        this.showDuplicateError = false
        this.showRequiredError = false
      }
    },
    selectedDatasheet(newVal) {
      if (newVal) {
        this.showDuplicateError = false
        this.showRequiredError = false
      }
    },
    tempAssignedLocations(newVal) {
      this.totalItems = this.tempAssignedLocations.length
    }
  },

  methods: {
    ...mapActions({
      searchMembers: "project/members/search",
      resetMemberSearch: "project/members/reset",
      searchLocations: "project/locations/search",
      resetLocationSearch: "project/locations/reset",
      searchAssignedLocation: "project/assigned_locations/search",
    }),

    debounce(method, timer) {
      if (this.$_debounceTimer !== null) {
        clearTimeout(this.$_debounceTimer)
      }
      this.$_debounceTimer = setTimeout(() => {
        method()
      }, timer)
    },

    memberFirstLast(member) {
      return member.firstName + ' ' + member.lastName
    },
    memberSearch(memberSearchText) {
      if (!memberSearchText) return;
      this.isBusyMember = true
      this.debounce(() => {
        this.searchMembers(memberSearchText).then(data => {
          this.isBusyMember = false
        })
      }, 1000)
    },
    clearMember() {
      this.selectedMember = null;
      this.resetMemberSearch();
    },

    locationLatLon(location) {
      return location.name + ' (' + location.latitude + ', ' + location.longitude + ')'
    },
    locationSearch(locationSearchText) {
      if (!locationSearchText) return;
      this.isBusyLocation = true
      this.debounce(() => {
        this.searchLocations(locationSearchText).then(data => {
          this.isBusyLocation = false
        })
      }, 1000)
    },
    clearLocation() {
      this.selectedLocations = [];
      this.resetLocationSearch();
    },

    datasheetLabel(datasheet) {
      let format = ' (LocationFormat: ' + (datasheet.locationFormat === 1 ? 'List of Locations;' : 'Both;')
      let assign = ' AutoAssign: ' + (datasheet.locationAutoAssign === 0 ? 'Off)' : 'On)')
      return datasheet.name + format + assign
    },
    clearDatasheet() {
      this.selectedDatasheet = null;
    },

    clearAll() {
      this.clearMember();
      this.clearLocation();
      this.assignmentType = null;
      this.clearDatasheet();
      this.tempAssignedLocations = [];
    },

    addAssignment() {
      this.showRequiredError = false
      this.showDuplicateError = false

      // Validate required selections
      if (!this.selectedMember || this.selectedLocations.length === 0 || !this.assignmentType ||
        (this.assignmentType && this.assignmentType === this.assignmentTypes[1].value && !this.selectedDatasheet)) {
        // Required selection missing
        this.showRequiredError = true
        return;
      }

      // Check if each assignment exists and add
      this.selectedLocations.forEach(loc => {
        // Check duplicate in local table
        let newAssignedLocation = {
          member: this.selectedMember,
          type: this.assignmentType === this.assignmentTypes[1].value ? 'Datasheet Specific' : 'Project Level',
          location: loc,
          datasheet: this.assignmentType === this.assignmentTypes[1].value ? this.selectedDatasheet : null
        }

        // Check for duplicates
        let localDup = this.tempAssignedLocations.some(val => {
          return JSON.stringify(val) === JSON.stringify(newAssignedLocation)
        })
        let serverDup = false;
        this.searchAssignedLocation(newAssignedLocation).then(val => {
          if (val && val.length > 0) serverDup = true;
          if (localDup || serverDup) {
            this.showDuplicateError = true
          } else {
            // All good, add the assignment
            this.tempAssignedLocations.push(newAssignedLocation)
          }
        })
      })
    },
    deleteAssignment(item) {
      this.tempAssignedLocations.splice(this.tempAssignedLocations.indexOf(item), 1)
    },
    onSaveClick() {
      // Send new assignments to parent component
      let temp = this.tempAssignedLocations.map(val => {
        return {
          member: val.member['@id'],
          location: val.location['@id'],
          datasheet: val.datasheet ? val.datasheet['@id'] : null
        }
      });
      this.$emit('on-add-assignments', temp)
    },
    resetModal() {
      this.clearAll()
      this.$bvModal.hide('assign-locations-modal');
    }
  },

  computed: {
    ...mapGetters({
      isLoadingMembers: "project/members/isLoading",
      memberSearchResults: "project/members/searchResults",
      errorMembers: "project/members/error",
      isLoadingLocations: "project/locations/isLoading",
      locationSearchResults: "project/locations/searchResults",
      errorLocations: "project/locations/error",
      datasheets: "project/get/publishedDatasheets",
    }),
    eligibleDatasheets() {
      // return datasheets where location format is not lat/lon
      return this.datasheets.filter(ds => {
        return ds.locationFormat !== 0
      })
    },
    fromItem() {
      return this.totalItems > 0
        ? (this.currentPage - 1) * this.perPage + 1
        : 0;
    },
    toItem() {
      return this.totalItems < this.currentPage * this.perPage
        ? this.totalItems
        : this.currentPage * this.perPage;
    },
  }
};
</script>

<style lang="scss" scoped>
.required-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
</style>
