<template>
  <b-modal id="confirmation-pop-up" ref="confirmationPopUup" title="Confirmation" hide-footer>
    <p class="my-4">Are you sure you want to {{action}} the {{subject}}?</p>
    <div class="float-right">
      <button class="btn btn-secondary" @click="onNoClick">Cancel</button>
      <button class="btn btn-primary" @click="onYesClick">Delete</button>
    </div>
  </b-modal>
</template>

<script>
    import VueLoader from './Loader'

    export default {
        components: {
            VueLoader
        },
        props: {
            action : {
                type: String,
                default : () => {
                    return ''
                }
            } ,
            subject : {
                type: String,
                default : () => {
                    return ''
                }
            }
        },
        created() {
            this.projectId = window.ClickedProjectId;
        },
        methods: {

          /**
           * @function onNoClick - when the user click on No button
           */
          onNoClick() {
              this.$refs.confirmationPopUup.hide()

          },

          /**
           * @function onYesClick - when the user click on Delete button - to delete the discussion/post
           */
          onYesClick( discussionData ) {
             this.$emit('on-delete-post');
             this.$refs.confirmationPopUup.hide();
          },
        }
    }

</script>

<style scoped>

</style>
