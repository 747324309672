<template>
  <b-container>
    <div v-if="isError" class="login text-center mt-5">
      <h4>Airtable Authorization timed out... Retry from the Project Page...</h4>
    </div>
    <div class="login text-center mt-5">
      <h3 v-show="isLoading">Completing Airtable Authorization ... Please wait...</h3>
      <font-awesome-icon icon="spinner" pulse v-show="isLoading"></font-awesome-icon>
      <div class="message-holder" v-show="error">
        <p class="message-holder">{{ error }}</p>
        <router-link v-if="!isLoading && localProjectId"
                     :to="{name:'ProjectAirtable', params: { id: this.localProjectId }, replace: true }">
          <i class="fa fa-arrow-right mr-1"></i>Back To Project
        </router-link>
      </div>
      <div class="" v-if="authStatus">
        <h3>{{ authStatus.message }}</h3>
        <p v-if="localProjectId">Redirecting to the Project Airtable page in {{ timerCount }}...</p>
        <p v-else>Return to the Project Manually...</p>
      </div>
    </div>
  </b-container>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import {airTableOAuth} from "../../../../main";

export default {
  data() {
    return {
      isError: false,
      timerCount: -1,
      localProjectId: null,
    };
  },

  created() {
    // Validate the response by matching against localStorage
    // - url is passed
    // - true removes the state from localstorage once it is processed
    this.isError = false
    airTableOAuth.readSigninResponseState(window.location.href, true).then(({response, state}) => {
      let creds = {authcode: response.code, pkcecodeverifier: state.code_verifier}
      // Token claim is performed server-side
      this.authenticateAirtable(creds).then(data => {
        if (data) {
          // Set redirect timer
          this.timerCount = 10
        }
      })
    }).catch(function (err) {
      this.isError = true
      console.log(err)
    })

    // Project ID is set for redirect and then cleared from local storage
    this.localProjectId = this.getParsedProjectId()
    if (this.localProjectId) this.clearProjectId()
  },

  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else if (value === 0) {
          this.$router.push({ name: 'ProjectAirtable', params: { id: this.localProjectId }, replace: true })
        }
      },
      immediate: false,
    },
  },

  computed: {
    ...mapGetters({
      projectId: "integration_airtable/authorize/projectId",
      isLoading: "integration_airtable/authorize/isLoading",
      authStatus: "integration_airtable/authorize/authStatus",
      error: "integration_airtable/authorize/error",
    }),
  },

  methods: {
    ...mapActions({
      authenticateAirtable: "integration_airtable/authorize/authorize", //method in authorize.js
      clearProjectId: "integration_airtable/authorize/clearProjectId"
    }),
    getParsedProjectId() {
      // Returns the project.url identifier
      return this.projectId && this.projectId.split('/').pop()
    }
  },
}
</script>

<style lang="scss"></style>
