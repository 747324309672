import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

/**
 * @function getOrganizations - Get paginated organizations list
 * @param {Mutation} commit
 * @param data
 * @returns {Promise<T | never> | * | *}
 */
export const getOrganizations = ({ commit, data }, page = '/organizations') => {
  commit(types.ORGANIZATION_TOGGLE_LOADING)

  fetch(page)
    .then(response => response.json())
    .then((data) => {
      commit(types.ORGANIZATION_TOGGLE_LOADING)
      commit(types.ORGANIZATION_SET_ITEMS, data['hydra:member'])
    })
    .catch((e) => {
      commit(types.ORGANIZATION_TOGGLE_LOADING)
      commit(types.ORGANIZATION_SET_ERROR, e.message)
    })
}
