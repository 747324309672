<template>
<div>
    <div class='bg-dark rounded-top p-2'>
        <button class='btn btn-primary' type="button" @click="geolocationStart()">
            <span class='d-none d-md-inline'>Get My Location</span>
            <i class='fa  fa-location-arrow ml-md-3' v-if='!isLocating'></i>
            <i class="fa fa-spinner fa-spin fa-fw" v-if='isLocating'></i>

        </button>
    </div>
    <LMap class="recordLocation" :options="mapOptions" :bounds="bounds" @update:zoom="mapSetZoomCurrent" @update:center="mapSetCenterCurrent" @update:bounds="mapSetBoundsCurrent" ref="map">
        <LTileLayer :url="url" :attribution="attribution"></LTileLayer>
        <LMarker :lat-lng="markerLatLng" @update:latLng='dragMarker' v-if='latitude && longitude' :draggable='true'></LMarker>
        <MyLocationMarker></MyLocationMarker>
    </LMap>
    <div class='bg-dark rounded-bottom p-2'>

        <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text"> <img src='/marker-icon.png' class='marker-legend'>
                </span>

                <span class="input-group-text d-md-none" id="">Lat</span>
                <span class="input-group-text d-none d-md-inline" id="">Latitude</span>

            </div>
            <input v-model="latitude" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" type="number"> </input>
            <div class="input-group-prepend">
                <span class="input-group-text d-md-none">Lng</span>
                <span class="input-group-text d-none d-md-inline">Longitude</span>

            </div>
            <input v-model="longitude" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" type="number"></input>
            <div class="input-group-append" v-if='hasChanges'>
                <button class='btn btn-warning' title="Undo changes to this location" :disabled='!(latitude && longitude)' type="button" @click="undoLocationChanges()">
                    <span class='d-none d-md-inline'>Undo</span>
                    <i class="fa fa-undo fa-sm" aria-hidden="true"></i>
                </button>

            </div>
        </div>

    </div>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters,
    mapMutations
} from "vuex";
import {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LControlZoom,
    LControlScale,
} from "vue2-leaflet";
import {
    latLng,
    latLngBounds,
    divIcon,
    circleMarker
} from "leaflet";

import MyLocationMarker from "../MyLocationMarker";

export default {
    name: "RecordLocation",
    props: {
        value: {
            type: String,
            default: ''
        },
    },

    components: {
        LMap,
        LTileLayer,
        LMarker,
        LControlZoom,
        LControlScale,
        MyLocationMarker,
    },
    data() {
        return {
            valueOriginal: {
                latitude: JSON.parse(this.value).latitude,
                longitude: JSON.parse(this.value).longitude,
            },
            latitude: JSON.parse(this.value).latitude,
            longitude: JSON.parse(this.value).longitude,

            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            storyLayerEsriObject: null,
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            mapOptions: {
                preferCanvas: true,
                zoomSnap: 0.5,
                worldCopyJump: true,
                zoomDelta: 0.5,
                maxZoom: 24,
                minZoom: 2,
                maxBounds: latLngBounds(latLng(110, -200), latLng(-75, 200)),
                zoomControl: true,
            },
            showMap: true,
            bounds: null,
            zoom: 4,
            center: {
                lat: JSON.parse(this.value).latitude,
                lng: JSON.parse(this.value).longitude,
            }

        };

    },
    computed: {
        ...mapGetters({

            mapBounds: "mapGetBoundsNew",
            geoLocationHistory: "geoLocationHistory",
            isLocating: "isLocating",
        }),
        markerLatLng: {
            get() {
                return {
                    lat: this.latitude,
                    lng: this.longitude,
                }
            },
            set(val) {
                if (!val) {
                    return;
                }
                this.latitude = val.lat
                this.longitude = val.lng
            }
        },
        hasChanges() {
            if (this.latitude === this.valueOriginal.latitude && this.longitude === this.valueOriginal.longitude) {
                return false
            }
            return true
        },
    },
    methods: {
        ...mapActions({
            geolocationStart: "geolocationStart",
        }),
        ...mapMutations([
            "mapSetZoomCurrent",
            "mapSetCenterCurrent",
            "mapSetBoundsCurrent",
        ]),
        addMarker(event) {
            if (!event.latlng) {
                return
            }
            if (this.lat || this.lng) {
                return
            }
            this.latitude = event.latlng.lat || null;
            this.longitude = event.latlng.lng || null;

        },
        dragMarker(latlng) {
            if (!latlng) {
                return
            }

            this.latitude = latlng.lat || null;
            this.longitude = latlng.lng || null;
        },
        undoLocationChanges() {
            this.latitude = this.valueOriginal.latitude;
            this.longitude = this.valueOriginal.longitude;
        },
    },
    mounted() {
        this.$refs.map.mapObject.flyTo(this.markerLatLng, 16, {
            duration: 1,
        });
    },
    emits: ['input'],
    watch: {
        markerLatLng(val) {
            if (!val.lat || !val.lng) {
                this.$emit('input', null)
                return;
            }
            this.$emit('input', JSON.stringify({
                latitude: val.lat,
                longitude: val.lng
            }))
        },
        mapBounds: function (newObject) {
            this.$nextTick(() => {
                console.log("mapBounds watcher");
                this.$refs.map.mapObject.flyToBounds(newObject.bounds, {
                    duration: newObject.duration,
                });
            });
        },
        basemap: function (newBaseMap, oldBaseMap) {
            this.$refs.map.mapObject.removeLayer(oldBaseMap);
            this.$refs.map.mapObject.addLayer(newBaseMap);
        },
    },
};
</script>

<style lang="css">
.recordLocation {
    height: 300px !important;
    z-index: 0;
}

.marker-legend {
    height: 16px;
}
</style>
