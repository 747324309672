<template>
  <div>
    <b-modal id="create-edit-resource" centered ref="createEditResource" header-class="border-0 text-center"
             footer-class="mr-auto ml-auto border-0" header-text-variant="secondary" body-text-variant="secondary"
             @hide="onHideEvent" :no-close-on-backdrop="true">
      <template slot="modal-header" class="mx-auto">
        <h5 class="custom-title" v-if="editedResource">Edit Project Resource
          <font-awesome-icon icon="question-circle" id="create-resource-info"/>
        </h5>
        <h5 class="custom-title" v-else>Create Project Resource
          <font-awesome-icon icon="question-circle" id="create-resource-info"/>
        </h5>
        <button type="button" aria-label="Close" class="close ml-0 pl-0"
                @click="this.$refs.createEditResource.hide()">&times;
        </button>
      </template>

      <b-container fluid>
        <b-row>
          <b-alert variant="danger" dismissible v-model="error.length > 0">
            {{ error }}
          </b-alert>
        </b-row>
        <b-row class="mb-3" no-gutters>
          <b-col cols="4">Display Label:</b-col>
          <b-col cols="8">
            <b-form-input type="text" size="sm" id="name" name="name" required class="gray-input"
                          v-model="editedResource.name"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="mb-3" no-gutters>
          <b-col cols="4">Options:</b-col>
          <b-col cols="8">
            <b-form-radio-group id="resource-type" v-model="editedResource.type" name="resourceType">
              <b-row>
                <b-col>
                  <b-form-radio value="link">Add a link</b-form-radio>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-radio value="file">Upload a file (20 MB max)</b-form-radio>
                  <div class='ml-3 small'>jpg, jpeg, png, pdf, doc, docx, txt, xls, xlsx, csv, ppt, or pptx</div>
                </b-col>
              </b-row>
            </b-form-radio-group>
          </b-col>
        </b-row>


        <b-row class="mb-3" no-gutters v-if="editedResource.type === 'link'">
          <b-col cols="4">URL:</b-col>
          <b-col cols="8">
            <b-form-input type="text" size="sm"
                          id="resource-location"
                          name="resource-location"
                          required
                          class="gray-input"
                          v-model="editedResource.url"></b-form-input>
          </b-col>
        </b-row>

        <b-row class="mb-3" no-gutters v-if="editedResource.type === 'file'">
          <b-col cols="4">Upload file:</b-col>
          <b-col cols="8">
            <input class="d-none" @change="onFileUploadChange" type="file" ref="addFileChoose"/>
            <b-button class="btn-green font-weight-bold" @click="$refs.addFileChoose.click()">
              Select file
              <span></span>
            </b-button>
          </b-col>
        </b-row>
      </b-container>

      <template slot="modal-footer" mr-auto ml-auto>
        <div>
          <b-btn class="btn-blue font-weight-bold" @click="onClickResourceSave">Submit</b-btn>
        </div>
      </template>

    </b-modal>

    <b-tooltip target="create-resource-info" placement="right">
      Some add resource(s) information. We need text that should be added here!
    </b-tooltip>

    <VueLoader v-if="isUploadingFile"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import VueLoader from "../layout/Loader";

export default {
  components: {
    VueLoader
  },

  data() {
    return {
      editedResource: {
        name: '',
        type: 'link',
        file: '',
        url: '',
        id: 0,
        '@id': ''
      },
      file: null,
      fd: new FormData()
    }
  },
  created() {
    this.projectId = window.ClickedProjectId;
  },
  computed: {
    ...mapGetters({
      error: 'project/resources/error',
      isLoading: 'project/resources/isLoading',
      isUploadingFile: 'project/resources/isUploadingFile'
    })
  },

  props: {
    passedResourceData: {
      type: Object,
      default: () => {
        return {
          name: '',
          type: 'link',
          file: '',
          url: '',
          id: 0,
          '@id': ''
        }
      }
    }
  },

  watch: {
    passedResourceData(val) {
      this.editedResource = {
        id: val.id,
        '@id': val['@id'],
        createdAt: val.createdAt,
        file: val.file,
        name: val.name,
        url: val.url,
        type: val.type,
      }
    },
    error(newErr) {
    }
  },

  methods: {
    ...mapActions({
      createResource: 'project/resources/createResource',
      startUpload: 'project/resources/startUpload',
      uploadFile: 'project/resources/uploadFile',
      setUploadFrontendError: 'project/resources/setUploadFrontendError'
    }),
    /**
     * @function onFileUploadChange - change handler for the file input type
     * @param {object} file - event object
     */
    onFileUploadChange(files) {
      const _files = files.target.files || files.dataTransfer.files
      const fileTypesAllowed = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt']
      let object = {}

      if (!_files.length)
        return

      const filename = _files[0].name

      if (filename.lastIndexOf('.') <= 0 || fileTypesAllowed.indexOf(filename.split('.').pop().toLowerCase()) === -1) {
        return this.setUploadFrontendError('Invalid file type')
      }

      this.fd.append('file', _files[0], _files[0].name)
      this.fd.append('actionType', 'project_resource')
    },

    /**
     * @function onAddPhotoClick - click handler for Add Photos button
     */
    onAddPhotoClick() {
      this.$refs.addFileChoose.$el.click()
    },

    /**
     * @function onClickResourceSave - save the new/existing resource item
     * @param {object} evt - event object
     * @param {object} item - resource item object
     */
    onClickResourceSave(evt, item) {
      evt.preventDefault()

      let isCreateRes = true

      if (this.editedResource.id) {
        isCreateRes = false
      }

      if (this.editedResource.type === 'link') {
        let saveData = {
          project: '/projects/' + this.projectId,
          name: this.editedResource.name,
          url: this.editedResource.url,
          isCreateRes: isCreateRes,
          resourceId: this.editedResource.id,
          file: null
        }

        this.createResource(saveData).then((data) => {
          if (typeof data === 'undefined') {
            return
          }

          this.$root.$emit('bv::hide::modal', 'create-edit-resource')
          this.$emit('refresh-table', 'resource-table')
        })
      } else if (this.editedResource.type === 'file') {
        let object = null

        if (this.editedResource.file != null) {
          object = {fd: this.fd, isCreateRes: false, currentId: this.editedResource.file.id}
        } else {
          object = {fd: this.fd, isCreateRes: true}
        }

        this.uploadFile(object).then((data) => {
          this.file = data
          this.editedResource.url = null
          let saveData = {
            project: '/projects/' + this.projectId,
            name: this.editedResource.name,
            file: this.file['@id'],
            url: null,
            resourceId: this.editedResource.id,
            isCreateRes: isCreateRes
          }

          this.createResource(saveData).then((data) => {
            if (typeof data === 'undefined') {
              return
            }

            this.$root.$emit('bv::hide::modal', 'create-edit-resource')
            this.$emit('refresh-table', 'resource-table')
            this.fd = new FormData()
          })
        })
      }
    },
    onHideEvent(data) {
      this.setUploadFrontendError('')
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-title {
  width: 100%;
  text-align: center;
}
</style>
