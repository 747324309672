<template>
  <div class="image-record-wrapper">
    <v-data-table
      :class="[
        'mb-2',
        element.files && element.files.length > 0 ? '' : 'd-none'
      ]"
      :items="element.files"
      :key="showUploadedImage"
      hide-headers
      hide-actions
      no-data-text
    >
      <template
        slot="items"
        slot-scope="props"
      >
        <td>
          <img v-if="props.item.thumb" :src="props.item.thumb" width="80" height="auto" />
          <span v-else>No Image</span>
        </td>
        <td>
          <div class="props.itemname">
            {{ props.item.name }}
          </div>
          <v-progress-linear v-model="valueDeterminate" :indeterminate="element.isUploading" />
        </td>
        <td>{{ props.item.size | formatSize }}</td>
        <td v-if="uploadError">{{ uploadError }}</td>
        <td v-else-if="imageUploadedSuccessfully || !element.isUploading">
          <v-icon>done</v-icon>
        </td>
        <td v-else></td>
      </template>
    </v-data-table>

    <v-layout>
      <v-flex xs12>
        <VueUploadComponent
          :input-id="element.marker"
          class="m-0 p-0 v-btn v-btn--block v-btn--outline v-btn--depressed theme--light"
          style="color: rgb(135, 152, 63); caret-color: rgb(135, 152, 63);"
          :class="{'is-invalid' : errors.has(element['@id'])}"
          :extensions="extensions"
          :accept="accept"
          :size="size || 0"
          v-model="element.files"
          v-validate="addFieldValidation ? element.validatorRules : ''"
          :error-messages="addFieldValidation ? errors.collect(element['@id'])[0] : ''"
          :data-vv-name="element['@id'] || 'name'"
          :required="element.requiredField"
          @input-filter="inputFilter"
          :disabled="disabledInput"
        >
          Press here to choose image file
          <v-icon right dark>cloud_upload</v-icon>
        </VueUploadComponent>
      </v-flex>
    </v-layout>

    <div
      v-if="(!hiddenHint && element.description) || errors.has(element['@id'])"
      class="v-text-field__details mt-2"
    >
      <div class="v-messages theme--light">
        <div class="v-messages__wrapper">
          <div
            :class="[
              'v-messages__message',
              {'invalid-feedback' : errors.has(element['@id'])}
            ]"
          >
            {{ errors.first(element['@id']) || element.description }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueUploadComponent from 'vue-upload-component'

export default {
  name: "vue-file-upload",

  components: {
    VueUploadComponent
  },

  inject: [ 'parentValidator' ],

  props: {
    element: {
      type: Object,
      default: () => {}
    },

    addFieldValidation: {
      type: Boolean,
      default: true
    },

    disabledInput: {
      type: Boolean,
      default: false
    },

    hiddenHint: {
      type: Boolean,
      default: false
    },

    actionType: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      accept: 'image/png,image/jpeg',
      extensions: 'jpg,jpeg,png',
      size: 1024 * 1024 * 10,
      valueDeterminate: 100,
      imageUploadedSuccessfully: false,
      showUploadedImage: 0
    }
  },

  computed: mapGetters({
      uploadIsLoading: 'file/upload/uploadIsLoading',
      uploadError: 'file/upload/uploadError',
      retrievedFile: 'file/upload/retrievedFile'
  }),

  watch: {
    retrievedFile (file) {
      if (!file) {
        return
      }

      this.imageUploadedSuccessfully = true
      this.element.isUploading = false
      this.element.fileObject.push({
        recordId: file.recordId,
        fileId: file.data["@id"]
      })
    },

    uploadIsLoading (uploadLoadingStatus) {
      this.$emit('uploadLoadingStatus', uploadLoadingStatus)
    }
  },

  created () {
    this.$validator = this.parentValidator
  },

  methods: {
    ...mapActions({
      uploadFile: 'file/upload/uploadFile'
    }),

    inputFilter (newFile, oldFile, prevent) {
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }

        // Thumbnails
        newFile.thumb = ''
        if (newFile.url && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.url
        }
      }

      const image = newFile.file
      const fd = new FormData()
      fd.append('file', image, image.name)
      fd.append('actionType', this.actionType)
      fd.append('recordId', this.element.id)

      this.imageUploadedSuccessfully = false
      this.element.isUploading = true
      this.showUploadedImage++
      this.uploadFile(fd)
    }
  }
}
</script>

<style lang="scss" scoped>
#custom-vuetify-style .datasheet-image-record-preview-side .image-record-wrapper {
  .v-text-field__details {
    margin-top: 8px;

    .invalid-feedback {
      display: block;
      width: 100%;
      margin-top: 0;
      font-size: 100%;
      color: #dc3545;
    }
  }

  /deep/ .v-progress-linear .primary {
    background-color: rgb(135, 152, 63) !important;
    border-color: rgb(135, 152, 63) !important;
  }

  .v-icon {
    color: rgb(135, 152, 63) !important;
  }

  .file-uploads {
    width: 100%;
    height: 34px;
    line-height: 36px;
  }
}
</style>
