import * as types from './mutation_types'

export default {
  [types.ABOUT_SHOW_RESET] (state) {
    Object.assign(state, {
      error: '',
      isLoading: false,
      stats:  {
        usersTotal: 0,
        projectsTotal: 0,
        locationsTotal: 0,
        observationsTotal: 0,
        protocolsTotal: 0,
      },
      isLoadingDiscussionData : false
    })
  },

  [types.ABOUT_SHOW_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.ABOUT_SET_STATS] (state, stats) {
    Object.assign(state, { stats: stats })
  },
}
