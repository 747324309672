<template>
  <div>
    <b-modal
      id="contact-project-manager"
      ref="contactProjectManager"
      title="Contact Project Manager"
      size="md"
      centered
      ok-title="Submit"
      @ok="handleSubmit"
      @hidden="resetModal"
      header-class="border-0 text-center"
      header-text-variant="dark"
      body-text-variant="tertiary"
      ok-only
    >
      <h6>Message to Manager:</h6>

      <b-form-textarea
        id="note-to-project-manager"
        rows="6"
        name="noteToProjectManager"
        required
        :state="emailMessage.length >= 10"
        placeholder="Enter your message here... (at least 10 characters)"
        v-model="emailMessage"
        autofocus
      ></b-form-textarea>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueLoader from "../layout/Loader";

export default {
  name: "contactProjectManager",
  components: {
    VueLoader
  },

  props: {
    // Incoming project id
  },

  data() {
    return {
      emailMessage: ""
    };
  },

  computed: {
    ...mapGetters({
      project: "project/get/project"
    })
  },

  methods: {
    ...mapActions({
      sendEmailToManager: "project/show/sendEmailToManager"
    }),

    // Handle submit button click
    handleSubmit(evt) {
      evt.preventDefault();
      if (this.emailMessage.length < 10) return;
      this.sendEmailToManager({
        id: this.project.id,
        body: { message: this.emailMessage }
      });
      this.closeModal();
    },

    resetModal() {
      this.emailMessage = "";
    },

    closeModal() {
      // Hide the modal manually
      this.$nextTick(() => {
        this.$root.$emit("bv::hide::modal", "contact-project-manager");
      });
    }
  }
};
</script>

<style></style>
