<template>
  <b-modal id="create-org-resource"
           title="Create a Hub Resource"
           ref="createOrgResource"
           size="md"
           button-size="sm"
           hide-header-close
           no-close-on-backdrop
           no-close-on-esc
           no-stacking
           centered
           @cancel="resetModal">

    <div class="container mb-2 p-0">
      <!-- Name, Type -->
      <b-card bg-variant="light">
        <b-form-group
          id="resource-name"
          label-cols-lg="3"
          content-cols-lg="9"
          label="Name:"
          label-class="font-weight-bold pt-0"
          label-for="input-name"
          description="Name of this Resource">
          <b-form-input id="input-name"
                        name="name"
                        v-model="resourceName"
                        placeholder="Enter a name"
                        v-validate="'required|min:5|max:220'"
                        data-vv-as="Resource Name"
                        :class="{ 'is-invalid': errors.has('name') }"
                        aria-describedby="resource-name-input-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="resource-name-input-live-feedback">
            {{ errors.first("name") }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="resource-type"
          label-cols-lg="3"
          content-cols-lg="9"
          label="Type:"
          label-class="font-weight-bold pt-0"
          label-for="input-type"
          description="Is this Resource a File or a Link?">
          <b-form-radio-group
            id="input-type"
            name="type"
            v-model="resourceType"
            :options="resourceTypeOptions"
            v-validate="'required'"
            data-vv-as="Resource Type"
            :class="{ 'is-invalid': errors.has('type') }"
            aria-describedby="resource-type-input-live-feedback"
          ></b-form-radio-group>
          <b-form-invalid-feedback id="resource-type-input-live-feedback">
            {{ errors.first("type") }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group v-if="resourceType && resourceType === 'link'"
                      id="resource-url"
                      label-cols-lg="3"
                      content-cols-lg="9"
                      label="Link/URL:"
                      label-class="font-weight-bold pt-0"
                      label-for="input-url"
                      description="Link/URL for this Resource">
          <b-input-group>
            <b-form-input id="input-url"
                          name="url"
                          v-model="resourceURL"
                          placeholder="resource.url.com"
                          v-validate="'required|min:5|max:100'"
                          data-vv-as="Resource URL"
                          :class="{ 'is-invalid': errors.has('url') }"
                          aria-describedby="resource-url-input-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="resource-url-input-live-feedback">
              {{ errors.first("url") }}
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
        <b-form-group v-if="resourceType && resourceType === 'file'"
                      id="resource-file"
                      label-cols-lg="3"
                      content-cols-lg="9"
                      label="File:"
                      label-class="font-weight-bold pt-0"
                      label-for="input-file"
                      description="Please make sure you have permission to upload this file.">
          <b-form-file id="input-file"
                       ref="inputFile"
                       v-model="resourceFile"
                       :accept="fileExtensions.toString()"
                       placeholder="Choose a file or drop it here..."
                       drop-placeholder="Drop file here..."
          ></b-form-file>
          <!-- Hidden field used only for file validation purposes -->
          <b-form-input v-show="false"
                        id="file"
                        name="file"
                        v-model="resourceFile && resourceFile.name"
                        v-validate="'required'"
                        data-vv-as="Resource File"
                        :class="{ 'is-invalid': errors.has('file') }"
                        aria-describedby="resource-file-input-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="resource-file-input-live-feedback">
            {{ errors.first("file") }}
          </b-form-invalid-feedback>
        </b-form-group>
        <div v-if="resourceFile" class="mt-3">Selected file: {{ resourceFile ? resourceFile.name : '' }}</div>
      </b-card>
      <b-card v-if="resourceType && resourceType === 'file'">
        <span>Note: If the document or resource you are uploading is already publicly available elsewhere, consider sharing the link (URL) rather than uploading it here.</span>
      </b-card>
    </div>
    <b-form-checkbox v-if="resourceType && resourceType === 'file'"
                     id="checkbox-permission"
                     v-model="permission"
                     name="checkbox-permission"
                     :class="{ shake: animateCheckbox, checkboxError: animateCheckbox }">
      I have permission to upload and share this document.
    </b-form-checkbox>
    <template slot="modal-footer">
      <b-btn variant="secondary" @click="() => { this.resetModal(); this.$refs.createOrgResource.hide() }">Cancel
      </b-btn>
      <b-btn variant="primary" @click="saveResource" :disabled="isFileUploading || isLoading">
        <b-spinner v-if="isFileUploading || isLoading" small></b-spinner>
        Save
      </b-btn>
    </template>
  </b-modal>
</template>


<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  name: "CreateOrgResourceModal",
  components: {},
  props: {},
  data() {
    return {
      resourceName: null,
      resourceType: null,
      resourceURL: null,
      resourceFile: null,
      resourceTypeOptions: [
        {text: 'File', value: 'file'},
        {text: 'Link / URL', value: 'link'}
      ],
      permission: false,
      animateCheckbox: false,
      isFileUploading: false,
    }
  },
  watch: {
    resourceFile(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal) {
          // Check for image/document extensions
          let bValidExtension = false
          this.fileExtensions.map(ext => ext.slice(1)).forEach((ext) => {
            if (this.resourceFile.type.indexOf(ext) > -1) {
              bValidExtension = true
            }
          })
          if (!bValidExtension) {
            alert('Please select an image or document');
            this.$refs['inputFile'].reset()
            return;
          }
          // Check for image less than 10mb
          if (this.resourceFile.size > 10 * 1000000) {
            alert('Please select a file not exceeding 10mb');
            this.$refs['inputFile'].reset()
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      fileExtensions: "organization/images/resourceExtensions",
      organization: "organization/get/organization",
      isLoading: "organization/resources/isLoading",
    }),
  },
  methods: {
    ...mapActions({
      createResource: "organization/resources/createResource",
      uploadFile: "organization/images/uploadFile",
    }),
    resetModal() {
      this.resourceName = null
      this.resourceType = null
      this.resourceURL = null
      this.resourceFile = null
      this.permission = false
      this.animateCheckbox = false
      this.isFileUploading = false
    },
    createResourceCall(resource) {
      // Resource is created
      this.isFileUploading = false
      this.createResource(resource).then(result => {
        if (result) {
          this.resetModal()
          this.$refs.createOrgResource.hide()
          this.$emit('refresh-table', 'resource-table')
        }
      })
    },
    saveResource() {
      if (this.resourceType && this.resourceType === 'file' && !this.permission) {
        this.animateCheckbox = true
        setTimeout(() => {
          this.animateCheckbox = false
        }, 5000)
        return
      }
      this.$validator.validateAll().then(result => {
        if (result) {
          // Resource to be created
          // - if URL, manually add https after removing (if exists)
          let resource = {
            name: this.resourceName,
            file: null,
            url: null,
            organization: this.organization['@id']
          }

          // File is uploaded and IRI is set
          if (this.resourceType === 'file') {
            this.isFileUploading = true
            this.uploadFile({
              blob: this.resourceFile,
              name: this.resourceFile.name,
              type: 'organization_resource'
            }).then(data => {
              resource.file = data['@id']
              this.createResourceCall(resource)
            })
          } else {
            let userUrl = this.resourceURL
            if (this.resourceURL.indexOf('http') === -1) {
              userUrl = 'http://' + this.resourceURL
            }
            resource.url = new URL(userUrl).href
            this.createResourceCall(resource)
          }
        }
      })
    },
  }
}
</script>

<style class="scss" scoped>
.shake {
  animation: shake 0.82s;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.checkboxError {
  color: red;
}
</style>
