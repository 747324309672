export default {
  props: {
    tabs: {
      type: Array,
      required: true
    },
    showSearch: {
      type: Boolean,
      required: false,
      default: true
    },
    filter: {
      type: String,
      default: () => {
        return null
      }
    },
    searchValue: {
      type: String,
      default: () => {
        return null
      }
    },
    navClass: {
      type: String,
      default: () => {
        return ''
      }
    }
  },

  data() {
    return {
      filterValue: this.filter,
      justMounted: false
    }
  },

  computed: {
    filterVal: {
      // getter
      get: function () {
        return this.filterValue
      },
      // setter
      set: function (newValue) {
        this.filterValue = newValue
        this.$emit('filter-change', newValue)
      }
    },
  },
  methods: {
    /**
     * Send to parent the current active tab
     * @param tabIndex
     */
    onTabChange(tabIndex) {
      let tabHash = this.tabs[tabIndex].name.toLowerCase()
      tabHash = tabHash.replace(/\s/g, '-')
      tabHash = ('#' + tabHash)

      if (this.justMounted) {
        this.justMounted = false
        return
      }

      this.$emit('search-filter-for-tab', { name: this.tabs[tabIndex].name, isActive: true, hash: tabHash })
    },
    // downloadData(evt) {
    //   this.$root.$emit('bv::show::modal', 'feature-coming-soon')
    // }
  },

  watch: {
    searchValue(newVal) {
      this.filterVal = newVal
    }
  },

  mounted() {
    this.justMounted = true
    this.activeTab = 0
  }
}
