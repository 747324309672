import {mapGetters, mapActions} from 'vuex'
import VueRecaptcha from 'vue-recaptcha'
import { RECAPTCHA_SITE_KEY } from '../../../config/_entrypoint'

export default {
  components: {
    'vue-recaptcha': VueRecaptcha
  },

  props: {
    errorsProp: {
      type: Object,
      default: () => {
        return null
      }
    }
  },

  provide () {
    return { parentValidator: this.$validator }
  },

  data () {
    return {
      dismissSecs: 5,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      recaptchaToken: '',
      siteKey: RECAPTCHA_SITE_KEY,
      errorsVal: this.errorsProp,
      errorStr: ''
    }
  },

  computed: {
    ...mapGetters({
      isAuthenticated: 'user/auth/isAuthenticated',
      userData: 'user/profile/sessionData',
      validationErrors: 'contact/show/error',
      isLoading: 'contact/show/isLoading'
    }),
    errors: {
      get() {
        return this.errorsVal
      },
      set(newErrors) {
        this.errorsVal = newErrors
      }
    }
  },
  methods: {
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
    showAlert () {
      this.dismissCountDown = this.dismissSecs
    },
    onCaptchaVerified (token) {
      this.recaptchaToken = token
    },
    onCaptchaExpired () {
      this.clearRecaptchaReferences()
    },

    clearRecaptchaReferences () {
      this.recaptchaToken = ''

      if (this.$refs.recaptcha) {
        this.$refs.recaptcha.reset()
      }
    },

    onSubmitContactForm() {
      this.errors.items = []

      if (this.recaptchaToken.length > 0) {
        this.$root.$emit('bv::show::modal', 'feature-coming-soon')
        return
      }

      this.showAlert()
      this.errorStr = 'Captcha invalid!'

    }
  },

  watch: {
    validationErrors(newErrors) {
      this.errors.items = []

      if (newErrors.items.length) {
        newErrors.items.forEach((item, index) => {
          this.errorStr += '<p>' + item + '</p>'
          this.showAlert()
        })
      }

      this.errors = ''
    }
  }
}
