import fetch from '../../../../utils/fetch'

const initialState = {
    isLiking: false,
    likes: [],
}

const getters = {
    count(state, getters, rootState) {
        return state.likes.length
    },
    hasCurrentUserLiked(state, getters, rootState) {
        if (
            state.likes &&
            rootState.user.profile.sessionData &&
            rootState.user.profile.sessionData.id
        ) {
            const userlike = state.likes.find((like) => {
                return (like.userId === rootState.user.profile.sessionData.id)
            })
            return userlike;
        }
        return false

    },
}

const actions = {
    /**
     * @function getProject - retrieve from API project data
     * @param commit
     * @returns {Promise<T | never> | * | *}
     */
    setLike({ commit, getters, rootGetters, rootState }) {
        commit(types.SET_PROJECT_LIKE_LOADING);
        if (getters['hasCurrentUserLiked']) {
            // remove like
            return fetch('/project_likes/' + getters['hasCurrentUserLiked'].id, { method: 'DELETE' })
                .then()
                .then(() => {
                    commit(types.REMOVE_PROJECT_LIKE, getters['hasCurrentUserLiked'].id)
                })
                .catch((error) => {
                    commit(types.SET_PROJECT_LIKE_ERROR, error.message)
                    //commit(types.REMOVE_PROJECT_LIKE, getters['hasCurrentUserLiked'].id)

                })
        } else {
            // add like
            const body = {
                user: rootState.user.profile.sessionData['@id'],
                project: rootState.project.get.project['@id'],
                isLiked: true,
            }
            return fetch('/project_likes', { method: 'POST', body: JSON.stringify(body) })
                .then(response => response.json())
                .then((data) => {
                    commit(types.SET_PROJECT_LIKE, data)
                })
                .catch((error) => {
                    commit(types.SET_PROJECT_LIKE_ERROR, error.message)
                })
        }

    },

}

const types = {
    SET_PROJECT_LIKE_LOADING: 'SET_PROJECT_LIKE_LOADING',
    SET_PROJECT_LIKE: 'SET_PROJECT_LIKE',
    REMOVE_PROJECT_LIKE: 'REMOVE_PROJECT_LIKE',
    SET_PROJECT_LIKES: 'SET_PROJECT_LIKES',
    SET_PROJECT_LIKE_ERROR: 'SET_PROJECT_LIKE_ERROR'
}

const mutations = {
    [types.SET_PROJECT_LIKE_LOADING](state) {
        state.isLiking = true;
        state.error = false;
    },
    [types.SET_PROJECT_LIKE](state, like) {
        state.likes = [...state.likes, like],
            state.isLiking = false;
    },
    [types.REMOVE_PROJECT_LIKE](state, removeLikeId) {
        state.likes = state.likes.filter((like) => {
            return like.id !== removeLikeId;
        })
        state.isLiking = false;
    },
    [types.SET_PROJECT_LIKES](state, likes) {
        state.likes = likes
    },
    [types.SET_PROJECT_LIKE_ERROR](state, error) {
        state.error = error;
        state.isLiking = false;
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters: getters,
    actions: actions,
    mutations: mutations
}
