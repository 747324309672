<template>
  <div class="row mt-2 mb-2 justify-center">
    <div class="col-lg-8 col-md-6 col-sm-4">
      <label><b>Tags:</b></label>
      <multiselect
        v-model="discussion.tags"
        tag-placeholder="Add new"
        placeholder="Add tags here..."
        :options="allTags"
        :multiple="true"
        :taggable="true"
        @tag="addTag"
        :hideSelected="true"></multiselect>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
  name: "postTag",
  props: {
    // Incoming discussion
    discussion: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      required: true,
      default: false,
    }
  },
  data() {
    return {

    };
  },
  computed: {
    ...mapGetters({
      allTags: "project/forum/allTags"
    }),
  },
  methods: {
    addTag(newTag) {
      this.discussion.tags.push(newTag.toLowerCase())
    },
  },
}
</script>

<style scoped lang="scss">

</style>
