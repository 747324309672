import SubmissionError from '../../../../error/SubmissionError'
import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'
import Vue from 'vue'

/**
 * Handles login from form, google, scistarter
 * - params must be of the format: { page: '', creds: {} }
 *   - page is the appropriate api endpoint
 *   - creds contains either email+password or OAuth parameters
 * @param commit
 * @param params
 * @returns {Promise<void>}
 */
export const authenticate = ({ dispatch, commit }, params) => {
  commit(types.USER_AUTH_SET_ERROR, '')
  commit(types.USER_AUTH_RESET_PASSWORD_ERROR, false)
  commit(types.USER_AUTH_LOADING)

  return fetch('/login', { method: 'POST', body: JSON.stringify(params.creds) })
    .then((response) => {
      commit(types.USER_AUTH_LOADED)
      return response.json()
    })
    .then((data) => {
      commit(types.USER_AUTH_SET_BEARER, data.token)
      commit(types.USER_AUTH_SET_REFRESH, data.refresh_token)
      dispatch('user/profile/fetchSession', null, {root:true});
    })
    .catch((e) => {
      if (e instanceof SubmissionError) {
        commit(types.USER_AUTH_SET_VIOLATIONS, e.errors)
        commit(types.USER_AUTH_SET_ERROR, e.errors._error)
      }
      commit(types.USER_AUTH_SET_ERROR, e.message)
      commit(types.USER_AUTH_RESET_PASSWORD_ERROR, e.resetPassword)
    })
}

/**
 * Reset localStorage and commit USER_AUTH_RESET mutation
 * @param commit
 * @param refresh
 * @param that
 */
export const logout = ({ commit }, refresh, that) => {
  commit(types.USER_AUTH_LOGOUT)

  // we refresh the page when the session expired to be sure that
  // we redirect the user to login page if the page is a restricted page
  // TODO: we don't have access to next like in router.beforeEach that's why we kept router.beforeEach from main.js too. Can we change this?
  if (refresh) {
    that.$router.go(that.$router.currentRoute)
  }
}

/**
 * Get the new token based on token for refresh
 * @param commit
 * @param state
 * @param that
 */
export const getNewTokenFromServer = ({ commit, state }, that) => {
  if (!Vue.$jwt.hasToken()) {
    logout({ commit })
    return
  }

  fetch('/token/refresh', {method: 'POST', body: JSON.stringify({ refresh_token: state.tokenRefresh })})
    .then(response => response.json())
    .then((data) => {
      commit(types.USER_AUTH_SET_BEARER, data.token)
      commit(types.USER_AUTH_SET_REFRESH, data.refresh_token)
    })
    .catch((e) => {
      commit(types.USER_AUTH_SET_ERROR, e.message)
      logout({ commit }, true, that)
    })
}

/**
 * Get the authentication status
 * @param commit
 * @param state
 * @param that
 */
export const checkTokenExpiration = ({ commit, state }, that) => {
  const now = Math.ceil(new Date() / 1000)

  // we set expiration time in the past if we have no token,
  const exp = Vue.$jwt.hasToken() ? Vue.$jwt.decode().exp : (now - 1)

  // the difference between exp time and current time - when we trigger the refresh token method (in seconds)
  const refreshDiff = 600 // 10 minutes

  if (!Vue.$jwt.hasToken() || (Vue.$jwt.hasToken() && exp < now))
  {
    // if we have no token or token is expired, reset the session
    logout({commit}, Vue.$jwt.hasToken(), that)
    return

  } else if (Vue.$jwt.hasToken() && exp >= now) {
    // the token is not expired yet
    const diff = exp - now

    if (diff >= refreshDiff) {
      setTimeout(function({ commit, state }, that) {
        getNewTokenFromServer({ commit, state }, that)
      }, (diff - refreshDiff), { commit, state }, that)
    } else {
      getNewTokenFromServer({ commit, state }, that)
    }
  }
}
