import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import { mapActions } from 'vuex'

export default {
  components: {
    GmapCluster
  },
  name: "vue-google-map",

  inject: ['parentValidator'],

  props: {
    updateMap: {
      type: Boolean,
      default: () => {
        return false
      }
    },

    latitude: {
      type: Number,
      default: () => {
        return 42.00
      }
    },

    longitude: {
      type: Number,
      default: () => {
        return -92.00
      }
    },

    zoomVal: {
      type: Number,
      default: () => {
        return 11
      }
    },

    markerItems: {
      type: Array,
      default: () => {
        return []
      }
    },

    item: {
      type: Object,
      default: () => {
        return {}
      }
    },

    isLoading: {
      type: Boolean,
      default: () => {
        return false
      }
    },

    cssStyle: {
      type: String,
      default: () => {
        return "width: 100%; height: 300px"
      }
    },

    showNameField: {
      type: Boolean,
      default: () => {
        return false
      }
    },

    showLatLngFields: {
      type: Boolean,
      default: () => {
        return true
      }
    },

    showLocateAddress: {
      type: Boolean,
      default: () => {
        return true
      }
    },

    zoomMapToMarkers: {
      type: Number,
      default: () => {
        return 5
      }
    },

    customMarker: {
      type: Boolean,
      default: () => {
        return false
      }
    },

    showMapLegend: {
      type: Boolean,
      default: () => {
        return false
      }
    },

    searchByLocation: {
      type: String,
      default: () => {
        return ''
      }
    },

    mapOptions: {
      type: Object,
      default: () => {
        return {
          streetViewControl: false,
          scaleControl: true,
          zoomControl: true,
          mapTypeControl: true
        }
      }
    },

    addMarkerOnClick: {
      type: Boolean,
      default: () => {
        return true
      }
    },

    showAddressFlag: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    mapClusterSize: {
      type: Number,
      default: () => {
        return 2
      }
    }
  },

  data() {
    return {
      address: '',
      isProjectLocation: false,
      markers: this.markerItems,
      cluster: false,
      zoom: this.zoomVal,
      mcClusterIconFolder: '/assets/images/m',
      center: {
        lat: this.latitude,
        lng: this.longitude
      },
      searchByLocationValue: this.searchByLocation,
      infoLocation: {
        name: '',
        latitude: 0,
        longitude: 0
      },
      nameVal: this.item.name,
      infoContent: '',
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        maxWidth: 630,
        pixelOffset: {
          width: 0,
          height: -5
        }
      },
      datasheetItem: {
        name: '',
        instructions: '',
        project: 'projects/' + this.$route.params.id,
        projection: 'geographic',
        dateFormat: 'date',
        allowCustomLocation: true,
        locations: [{
          name: '',
          latitude: 0,
          longitude: 0
        }],
        published: false
      }
    }
  },

  methods: {
    ...mapActions({
      createDatasheet: 'project/show/createDatasheet',
    }),
    /**
     * @function showAddress - set marker by address and center the map to the new added marker
     */
    showAddress() {
      this.$refs.mapRef.$mapPromise.then((map) => {
        const geocoder = new google.maps.Geocoder()
        this.zoom = null

        geocoder.geocode({address: this.address}, (results, status) => {
          // If geocode was successful - status OK
          if (status == google.maps.GeocoderStatus.OK) {
            this.clearOverlays()

            const marker = {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng()
            }

            this.center = marker
            this.zoom = 15
            this.markers.push({position: marker})

            // capture lat/lng of clicked location
            this.item.latitude = Math.round(results[0].geometry.location.lat() * 100000) / 100000
            this.item.longitude = Math.round(results[0].geometry.location.lng() * 100000) / 100000
          } else {
            this.address = ''
          }
        })
      })
    },

    /**
     * @function clearOverlays - clear the markers array
     */
    clearOverlays: function () {
      if (this.markers) {
        for (let i = 0; i < this.markers.length; i++) {
          this.markers[i] = null
        }

        this.markers = []
      }
    },

    /**
     * @function onMarkerClick - event handler for click on a marker and open infoWindow
     * @param {object} marker - a marker object that should contains lat, lng, name
     * @param {integer} index - the index of the marker in the markers property
     */
    onMarkerClick(marker, index) {
      this.isProjectLocation = marker.isProjectLocation
      this.infoWindowPos = marker.position;
      this.infoContent = marker.title + '(' + marker.position.lat + ',' + marker.position.lng + ')';
      this.infoLocation = marker

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == index) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = index;
      }
    },

    /**
     * @function addMarker - Add a Marker on the map on the passed location coordinates
     * @param { object } location - object with location lat lng properties
     */
    addMarker: function (location, name, id) {
      this.clearOverlays()

      if (this.customMarker) {
        this.markers.push({
          position: location,
          title: this.item && this.item.name || '',
          icon: '/assets/images/unselected-project-marker.png',
          isProjectLocation: true
        })
      } else {
        this.markers.push({
          position: location
        })
      }

      // capture lat/lng of clicked location
      this.item.latitude = Math.round(location.lat * 100000) / 100000
      this.item.longitude = Math.round(location.lng * 100000) / 100000
    },

    /**
     * @function addMarkerOnMapClick - Add a marker on the map where the user click
     * @param {object} event - mouse event
     */
    addMarkerOnMapClick: function (event) {
      if (!this.addMarkerOnClick) {
        return
      }

      const zoomMark = 5

      // capture lat/lng of click location
      let myLatLng = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }

      // check to see if visitor is zoomed out to a level not conducive to setting an accurate marker
      if (this.zoom < zoomMark) {
        // center map on click location
        this.center = myLatLng

        // zoom in
        this.zoom = zoomMark
      } else {
        this.addMarker(myLatLng)
      }
    },

    /**
     * @function updateLocation - Update the project location coordinates
     */
    updateLocation: function () {
      if (this.item != null && (!isNaN(this.item.latitude)) && (!isNaN(this.item.longitude))) {
        const latVal = /^-?([0-8]?[0-9]|90)(\.)?[0-9]{1,6}$/
        const lonVal = /^-?((1?[0-7]?|[0-9]?)[0-9]|180)(\.)?[0-9]{1,6}$/

        if (latVal.test(this.item.latitude) && lonVal.test(this.item.longitude)) {
          this.zoomMap(this.item.latitude, this.item.longitude)
        }
      }
    },

    /**
     * @function zoomMap - Zoom in to the marker
     * @param {float} lat - latitude
     * @param {float} lng - longitude
     */
    zoomMap: function (lat, lng) {
      // create map latlng object
      const myLatLng = {
        lat: parseFloat(lat),
        lng: parseFloat(lng)
      }

      // center map on latlng object location
      this.center = myLatLng

      // zoom in
      this.zoom = this.zoomMapToMarkers

      // set marker
      this.addMarker(myLatLng)
    },

    /**
     * @function setObservationsLocations - get the locations of the observations that are passed from parent component
     * @param {array} data - an array of object that contains the location data (name, lat, lng)
     */
    setObservationsLocations: function (data) {
      if (typeof data != 'undefined' && data != null && typeof data.locations !== 'undefined' && data.locations != null && data.locations.length > 0) {
        if (data.locations.length > 30) {
          this.cluster = true
        }

        data.locations.forEach((location) => {
          let position = {
            lat: parseFloat(location.latitude),
            lng: parseFloat(location.longitude)
          }

          this.markers.push({
            position: position,
            id: location.id,
            title: location.name,
            icon: '/assets/images/orange-black-outline-dot.png',
          })
        })
      }
    },

    /**
     * Recenter the map to the current Project Location coordinates
     */
    resetMap() {
      const myLatLng = {
        lat: this.item.latitude,
        lng: this.item.longitude
      }

      this.$refs.mapRef.$mapPromise.then((map) => {
        map.setZoom(this.zoomMapToMarkers)
        map.setCenter(myLatLng)
      })
    },

    /**
     * @function addNewDatasheet - create a new datasheet with preloaded location
     */
    addNewDatasheet() {
      this.createDatasheet(this.datasheetItem)
    },
    /**
     * @function clickAddDatasheet - function that handle the Add a datasheet button press. Check if user is logged in.
     *    If the user is not logged in display a popup with a message if it is redirecting the user to the create a datasheet
     * @param {object} evt - the event object
     */
    clickAddDatasheet(datasheet, location) {
      if (JSON.parse(localStorage.getItem('sessionData')) == null) {
        this.$root.$emit('bv::show::modal', 'not-logged-in-modal')
        return
      } else {
        localStorage.setItem('location', location)
        this.$router.push({name: 'DatasheetCreator', params: { id: evt.target.getAttribute('data-datasheet-id'), location: 1}})
      }
    },

    /**
     * @function onLatLngInputChange
     */
    onLatLngInputChange() {
      if (this.showLatLngFields) {
        this.updateLocation()
      }
    }
  },

  computed: {

    name: {
      get() {
        return this.nameVal
      },
      set(newValue) {
       this.nameVal = newValue
      }
    },

    mapItems: function (data) {
      if (this.item != null && typeof this.item != 'undefined' && this.item.locations != null && typeof this.item.locations instanceof Array) {
        this.setObservationsLocations(this.item)
      }

      return this.item
    },

    searchByLocationVal: {
      // getter
      get: function () {
        return this.searchByLocationValue
      },
      // setter
      set: function (newValue) {
        this.searchByLocationValue = newValue
      }
    }
  },

  watch: {
    mapItems: function (data) {
      this.updateLocation()
    },

    item(data) {
      this.updateLocation()
      this.setObservationsLocations(data)
    },

    infoLocation(location) {
      this.infoLocation = location
    }
  },

  created() {
    if (this.parentValidator) {
      this.$validator = this.parentValidator
    }

    if (this.updateMap) {
      this.updateLocation()
      this.setObservationsLocations(this.item != null ? this.item : null)
    }
  }
}
