<template>
  <div class="mt-3">
    <h5 class="w-50 border-bottom">
      {{ datasheetRecordLabel }}
    </h5>
    <div class="ml-4">
      <div v-if="isLoadingResults">
        <i class="fa fa-spin fa-spinner fa-3x mr-3"></i> Loading results...
      </div>
      <div v-else class="col col-md-6 pl-2">
        <PieChartCategorical
          v-if="datasheetRecordType === 'dropdown' || datasheetRecordType === 'radio'"
          :chart-data=categoricalResults
          :sample-size=categoricalSampleSize>
        </PieChartCategorical>
        <div v-else-if="datasheetRecordType === 'number'">
          <NumberSummaryStats :summary-stats=numericalResults>
          </NumberSummaryStats>
        </div>
        <div v-else-if="datasheetRecordType === 'organism'">
          <!-- <span>{{ datasheetRecordId }} - Organism - {{ organismResults }}</span> -->
          <OrganismSummaryStats
            :chart-data=organismResults
            :sample-size=organismSampleSize>
          </OrganismSummaryStats>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import PieChartCategorical from "./PieChartCategorical";
import NumberSummaryStats from "./NumberSummaryStats";
import OrganismSummaryStats from "./OrganismSummaryStats";

export default {
  components: {PieChartCategorical, NumberSummaryStats, OrganismSummaryStats},
  props: {
    datasheetRecordId: {
      type: Number, default: () => {
        return null
      },
      required: true,
    },
    datasheetRecordLabel: {
      type: String, default: () => {
        return null
      }
    },
    datasheetRecordType: {
      type: String, default: () => {
        return null
      }
    }
  },
  data() {
    return {
      categoricalResults: [],
      categoricalSampleSize: 0,
      numericalResults: {},
      organismResults: [],
      organismSampleSize: 0,
    }
  },
  watch: {},

  computed: {
    ...mapGetters({
      projectId: "project/get/id",
      isLoadingResults: "project/results/isLoadingResults",
    })
  },
  methods: {
    ...mapActions({
      getDatasheetRecordResults: "project/results/getDatasheetRecordResults",
    }),
  },
  created() {
    this.getDatasheetRecordResults(this.datasheetRecordId).then(output => {
      if (!output['noObservations']) {
        if (this.datasheetRecordType === 'number') {
          this.numericalResults = output['numerical']
        } else if (this.datasheetRecordType === 'dropdown' || this.datasheetRecordType === 'radio') {
          // Sample size is computed
          this.categoricalSampleSize = output['categorical'].reduce( function(a, b){
            return a + b['num'];
          }, 0);
          output['categorical'].map(val => {
            this.categoricalResults.push({
              name: val.name,
              y: Number(val.y),
              num: val['num']
            })
          })
        } else if (this.datasheetRecordType === 'organism') {
           // Sample size is computed
           this.organismSampleSize = output['organism'].reduce( function(a, b){
            return a + b['total'];
          }, 0);
          console.log('sample size: ', this.organismSampleSize)
          output['organism'].map(val => {
            this.organismResults.push([val['taxonomygroup'], val['total']])
          })

          // let organismResults=[["plants",7],["animals",1],["birds",3]];
          // console.log(output);
          // console.log(output['organism']);
          //console.log(this.organismResults);
          //console.log("organismResults"+organismResults);
          //this.organismResults = output['organism'];
          //console.log('organismResults = ', this.organismResults);
        }
      }
    })
  },
}

</script>

