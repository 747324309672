// import store modules
import Vue from 'vue'
import Vuex from 'vuex';
Vue.use(Vuex)

import session from './modules/session'
import user from './modules/user/'
import project from './modules/project/'
import datasheet from './modules/datasheet/'
import about from './modules/about/'
import staff from './modules/staff/'
import donate from './modules/donate/'
import contact from './modules/contact/'
import file from './modules/file/'
import observation from './modules/observation/'
import organization from './modules/organization'
import organization_projects from './modules/organization_projects'
import location from './modules/location'
import map from './modules/map/map.js'
import geolocation from './modules/map/geolocation'
import analysis from './modules/analysis';
import integration_airtable from './modules/integration_airtable';


// Add the modules in the store
const store = new Vuex.Store({
    modules: {
        session,
        user,
        project,
        datasheet,
        about,
        staff,
        contact,
        donate,
        file,
        observation,
        organization,
        organization_projects,
        location,
        map,
        geolocation,
        analysis,
        integration_airtable,
        }
});

export default store
