/**
 * @function urlBuilder
 * @returns {string} - returns an empty string if no parameters | returns API params if parameters are passed
 * @param params - Parameters passed to the API <perPage, currentPage, projectState, sortBy, sortDesc, filter>
 */
export const urlBuilder = function (params) {
  if (typeof params == 'undefined' || params == null) {
    return ''
  }

  let urlParams = ''

  for(let param in params) {
    if (param === 'projectState' && params[param] != null && params[param] > 0) {
      urlParams = (urlParams + (urlParams.length === 0 ? '?' : '&') + ('projectState=' + params[param]))
    }

    if (param === 'perPage' && params[param] != null && params[param] > 0) {
      urlParams = (urlParams + (urlParams.length === 0 ? '?' : '&') + ('itemsPerPage=' + params[param]))
    }

    if (param === 'currentPage' && params[param] != null && params[param] > 0) {
      urlParams = (urlParams + (urlParams.length === 0 ? '?' : '&') + ('page=' +  + params[param]))
    }

    if (param === 'sortBy' && params[param] != null && params[param].length) {
      urlParams = (urlParams + (urlParams.length === 0 ? '?' : '&') + ('order[' + params[param] + ']=' + (params['sortDesc'] ? 'desc' : 'asc') ))
    }

    if (param === 'filter' && params[param] != null && params[param].length) {
      if (params[param].indexOf('=') > -1) {
        // console.log('urlbuilder: ', params[param])
        urlParams = (urlParams + (urlParams.length === 0 ? '?' : '&') + (params[param]))
      } else {
        urlParams = (urlParams + (urlParams.length === 0 ? '?' : '&') + ('partial_name=' + params[param]))
      }
    }
  }

  return urlParams
}
