import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

/**
 * @function getObservationsList
 * @param commit
 * @param data
 * @returns {Promise<T | never> | * | *}
 */
export const getObservationsList = ({ commit }, data) => {
  commit(types.OBSERVATION_LIST_LOADING_TOGGLE)
  commit(types.OBSERVATION_LIST_SET_ERROR, '')
  let id = data.id
  let urlParams = data.urlParams

  return fetch('/projects/' + id + '/observations' + urlParams)
    .then(response => response.json())
    .then((data) => {
      commit(types.OBSERVATION_LIST_LOADING_TOGGLE)
      commit(types.OBSERVATION_LIST_SET_RETRIEVED, data)

      return data
    })
    .catch((e) => {
      commit(types.OBSERVATION_LIST_LOADING_TOGGLE)
      commit(types.OBSERVATION_LIST_SET_ERROR, e.message)
    })
}

export const resetError = ({ commit }) => {
  commit(types.OBSERVATION_LIST_RESET_ERROR)
}

/**
 * @function getAllObservationsList
 * @param {Mutation} commit
 * @param {object} data object that contains urlParams property generated by urlBuilder helper from context object
 * @returns {Promise<T | never> | * | *}
 */
export const getAllObservationsList = ({ commit }, data) => {
  commit(types.OBSERVATIONS_ALL_LIST_LOADING_TOGGLE)
  commit(types.OBSERVATION_LIST_SET_ERROR, '')
  let urlParams = typeof data != 'undefined' && data != null && typeof data.urlParams != 'undefined' && data.urlParams != null ? data.urlParams : ''

  return fetch('/observations' + urlParams)
    .then(response => response.json())
    .then((data) => {
      commit(types.OBSERVATIONS_ALL_LIST_LOADING_TOGGLE)
      commit(types.OBSERVATIONS_ALL_LIST_SET_RETRIEVED, data['hydra:member'])

      return data
    })
    .catch((e) => {
      commit(types.OBSERVATIONS_ALL_LIST_LOADING_TOGGLE)
      commit(types.OBSERVATION_LIST_SET_ERROR, e.message)
    })
}
