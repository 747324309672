import { latLng, latLngBounds } from "leaflet";
const initialState = {
  accuracy: null,
  altitude: null,
  altitudeAccuracy: null,
  heading: null,
  latitude: null,
  longitude: null,
  speed: null,
  timestamp: null,

  enableHighAccuracy: true,
  maximumAge: 30000,
  timeout: 27000,
  history: [],
  geolocator: null,
  canLocate: null,
  isLocating: false,
}


const map = {
  state: {
    ...initialState,
  },
  getters: {
    value: (state) => {
      return {
        latitude: state.latitude,
        longitude: state.longitude,
        accuracy: state.accuracy,
      }
    },
    isLocating: (state) => {
      return state.isLocating;
    },
    geoLocationHistory: (state) => {
      return state.history;
    },
    geoLocation: (state) => {
      return {
        accuracy: state.accuracy,
        altitude: state.altitude,
        altitudeAccuracy: state.altitudeAccuracy,
        heading: state.heading,
        latitude: state.latitude,
        longitude: state.longitude,
        speed: state.speed,
        timestamp: state.timestamp,
      }

    }
  },
  mutations: {
    setGeolocation: (state, payload) => {
      state.accuracy = payload.accuracy
      state.altitude = payload.altitude
      state.altitudeAccuracy = payload.altitudeAccuracy
      state.heading = payload.heading
      state.latitude = payload.latitude
      state.longitude = payload.longitude
      state.speed = payload.speed
      state.timestamp = payload.timestamp
      state.history = [payload, ...state.history]

    },
    setGeolocationError: (state, error) => {
      state.error = error;
    },
    setGeolocator: (state, geolocator) => {
      state = {
        geolocator: geolocator,
        isLocating: true,
      }
    },
    clearGeolocator: (state) => {
      navigator.geolocation.clearWatch(state.geolocator);
      state = {
        geolocator: null,
        isLocating: false
      }
    }

  },
  actions: {
    geolocationStart({ commit, dispatch, state }) {
      if ('geolocation' in navigator) {
        /* geolocation is available */
      } else {
        const message = 'geolocation services are not available, please enable the browse location permission'
        commit('setError', message)
      }
      const options = {
        maximumAge: state.maximumAge,
        timeout: state.timeout,
        enableHighAccuracy: state.enableHighAccuracy
      }
      function success(position) {
        const newLocation = {
          accuracy: position.coords.accuracy,
          altitude: position.coords.altitude,
          altitudeAccuracy: position.coords.altitudeAccuracy,
          heading: position.coords.heading,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          speed: position.coords.speed,
          timestamp: position.timestamp,
        }
        commit('setGeolocation', newLocation)
      }

      function error(error) {
        commit('setGeolocationError', error)
      }
      const geolocator = navigator.geolocation.watchPosition(success, error, options);
      commit('setGeolocator', geolocator)


    }
  },
};

export default map;
