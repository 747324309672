<template>
  <div>
    <div v-if="isPublicOrMember || userProfile">
      <b-container class="mx-auto">
        <b-row class="mx-auto" align-h="between" no-gutters>
          <b-col cols="3">
            <h3 class="mb-0">Downloads</h3>
          </b-col>
          <b-col>
            <div
              class="flex-shrink-1 text-right"
              v-if="(!userProfile && isMember) && this.observationsTotal > 0">
              <button class="btn btn-primary" @click="onCreateDownload">
                <i class="fa fa-plus text-white"></i>
                New Download
              </button>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <b-table
        show-empty
        stacked="md"
        id="projectDownloadsTable"
        ref="projectDownloadsTable"
        :items="projectDownloads"
        :fields="visibleFields"
        :current-page="currentPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :empty-text="emptyText"
        :sort-icon-left="true"
      >
        <!-- <template v-slot:cell(projectName)="row" v-if="this.userProfile"> -->
        <!-- Add router b-link to Project here -->
        <!-- </template> -->
        <template v-slot:cell(options)="row">
          <div class="options-holder right">
            <b-btn
              v-if="row.item.status === 'completed' && row.item.path !== null"
              @click="onClickViewDownload(row.item)"
              class="mt-1 mr-1 my-1"
              alt="Download File"
              title="Download File"
            >
              <font-awesome-icon icon="download" />
              Download
            </b-btn>

            <b-btn
              v-if="
                row.item.status !== 'completed' && row.item.status !== 'failed'
              "
              @click="onClickRefresh()"
              class="mt-1 mr-1 my-1"
              alt="Refresh Status"
              title="Refresh Status"
            >
              <font-awesome-icon icon="sync" />&nbsp;&nbsp;&nbsp;Refresh
            </b-btn>
          </div>
        </template>
        <template #table-busy>
          <VueLoader style="height: 200px" text="Loading Project Downloads..." />
        </template>
      </b-table>
    </div>
    <RequiresMember v-else page="Downloads"></RequiresMember>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import VueLoader from "../../../layout/Loader";
import * as dateFormatHelper from "../../../../helpers/date_format_helper";
import RequiresMember from "../tabpermission/requiresMember.vue";

export default {
  name: "ProjectDownload",
  components: {
    RequiresMember,
    VueLoader
  },
  props: {
    // Boolean indicating project/user context
    userProfile: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      allDownloads: [],
      isBusy: false,
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      sortBy: "createdAt",
      sortDesc: true,
      sortDirection: "desc",
      emptyText: "No Downloads Yet!",

      fieldList: [
        {
          key: "projectName",
          label: "Project Name",
          sortable: true,
          class: "text-left",
          visible: this.userProfile,
          formatter: (key, val, item) => {
            return item.project.name;
          }
        },
        {
          key: "createdAt",
          label: "Date Created",
          sortable: true,
          class: "text-left",
          visible: true,
          formatter: val => {
            return dateFormatHelper.displayFormattedDate(val);
          }
        },
        {
          key: "status",
          label: "Status",
          sortable: false,
          class: "text-center",
          visible: true,
          formatter: val => {
            return val.charAt(0).toUpperCase() + val.slice(1);
          }
        },
        {
          key: "options",
          label: "Options",
          class: "text-right",
          visible: true
        }
      ]
    };
  },

  computed: {
    ...mapGetters({
      membershipMap: "user/profile/membershipMap",
      userDataSession: "user/profile/sessionData",
      projectId: "project/get/id",
      isPublicOrMember: "project/get/isPublicOrMember",
      isMember: "project/get/isMember",
    }),
    ...mapState({
      observationsTotal: state => state.project.get.stats.observationsTotal
    }),
    visibleFields() {
      // Property returns only the fields where visible = true
      return this.fieldList.filter(field => field.visible);
    }
  },

  methods: {
    ...mapActions({
      createProjectDownload: "project/projectDownload/createProjectDownload",
      getAllUserDownloads: "project/projectDownload/getAllUserDownloads",
      // getSingleDownload: 'project/projectDownload/getSingleDownload',
      getUserDownloadsForProject:
        "project/projectDownload/getUserDownloadsForProject"
    }),

    onCreateDownload() {
      let data = {
        user: "/users/" + this.userDataSession.id,
        project: "/projects/" + this.projectId
      };
      this.createProjectDownload(data).then(data => {
        this.onClickRefresh();
      });
      // setTimeout(()=>{ this.onClickRefresh() },5000);
    },

    onClickRefresh() {
      this.$refs.projectDownloadsTable.refresh();
    },

    onClickViewDownload(data) {
      if (data.path !== null || data.path !== "") {
        window.open(data.path, "_blank");
      }
    },

    projectDownloads(ctx) {
      this.isBusy = true;
      let urlParams = `${this.urlBuilder(ctx)}`;
      const data = {
        userId: this.userDataSession.id,
        projectId: this.projectId,
        urlParams
      };

      return this.getUserDownloadsForProject(data).then(data => {
        this.totalItems = data["hydra:totalItems"];
        this.currentPage = ctx.currentPage || 1;
        this.sortBy = ctx.sortBy || "";
        this.isBusy = false;

        this.allDownloads = data["hydra:member"].map(item => {
          return {
            ...item
          };
        });

        if (this.userProfile) {
          // Return all records for user profile page
          return this.allDownloads;
        } else {
          // Filter by project id for project details page
          return this.allDownloads.filter(projectDownload => {
            return projectDownload.project.id === this.projectId;
          });
          // Uncomment to color rows based on status column
          //   .map(item => {
          //   item._rowVariant  = this.getRowColor(item.status)
          //   return item
          // })
        }
      });
    },

    getRowColor(status) {
      // Returns row color
      switch (status) {
        case "completed":
          return "success";
        case "submitted":
        case "in progress":
          return "active";
        case "failed":
          return "danger";
      }
    }
  }
};
</script>

<style></style>
