<template>
  <div class="mt-3">
    <div class="row justify-content-center flex-none">
      <div class="col-lg-8">
        <p>{{ organization.description }}</p>
      </div>
    </div>
    <!--<p class="col-9">{{ organization.description }}</p>-->
    <div v-if="organization.recentNews">
      <div class="row justify-content-center flex-none">
        <div class="col-lg-8">
          <p><b>Recent News:</b></p>
        </div>
        </div>
      <div class="row justify-content-center flex-none">
        <div class="col-lg-8">
          <p>{{ organization.recentNews }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    organization: {
      type: Object,
      required: true
    }
  }
};
</script>
<style lang=""></style>
