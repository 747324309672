import fetch from '../../../../utils/fetch'

const initialState = {
  isLoading: false,
  posts: null,
  error: null,

  singlePost: null,

  isLoadingTags: false,
  tags: null,
  tagsError: null,
}

const getters = {
  posts(state) {
    return state.posts
  },
  singlePost(state) {
    return state.singlePost;
  },
  allTags(state) {
    if (state.tags) {
      return state.tags.all;
    } else {
      return []
    }
  },
  popularTags(state) {
    if (state.tags) {
      return state.tags.popular;
    } else {
      return []
    }
  },
  isLoading(state) {
    return state.isLoading
  },
  error(state) {
    return state.error
  },
}

const actions = {

  get({commit, rootState}, data) {
    commit(types.RESET_ERROR)
    commit(types.SET_PROJECT_POSTS_LOADING);
    return fetch('/projects/' + rootState.project.get.project.id + '/project_posts' + data.urlParams)
      .then(response => response.json())
      .then((data) => {
        commit(types.SET_PROJECT_POSTS, data['hydra:member'])
        return data
      })
      .catch((error) => {
        commit(types.SET_PROJECT_POSTS_ERROR, error)
      })
  },

  getSingle({commit}, data) {
    commit(types.RESET_ERROR)
    commit(types.SET_PROJECT_POSTS_LOADING);
    return fetch('/project_posts/' + data.id)
      .then(response => response.json())
      .then((data) => {
        commit(types.SET_PROJECT_POST_SINGLE, data)
        return data
      })
      .catch((error) => {
        commit(types.SET_PROJECT_POSTS_ERROR, error)
      })
  },

  create({commit, rootGetters, dispatch}, data) {
    // commit(types.SET_PROJECT_POSTS_LOADING);
    // User and Project IRI's are set
    data.user = rootGetters['user/profile/sessionData']['@id']
    data.project = rootGetters["project/get/project"]['@id']

    // Files are uploaded before post is created
    return dispatch("file/multiupload/uploadFiles", null, {root: true}).then(() => {
      // files attached to this post
      data.files = rootGetters["file/multiupload/uploadIRIS"]
      // Post is created
      return fetch('/project_posts', {method: 'POST', body: JSON.stringify(data)})
        .then(response => response.json())
        .then((data) => {
          if (typeof data === 'object') {
            // uploaded files are removed
            commit("file/multiupload/REMOVE_UPLOADS", null, {root: true});
            return data;
          }
        })
        .catch((error) => {
          commit(types.SET_PROJECT_POSTS_ERROR, error)
        })
    })
  },

  // edit({ commit, rootState }, data) {
  //   commit(types.SET_PROJECT_POSTS_LOADING)
  //   return fetch(('/project_POSTS/' + data.id), {
  //     method: 'PUT',
  //     body: JSON.stringify(data)
  //   })
  //     .then(response => response.json())
  //     .then((post) => {
  //       return post
  //     })
  //     .catch((error) => {
  //       commit(types.SET_PROJECT_POSTS_ERROR, error.message)
  //     })
  // },

  delete({commit}, data) {
    return fetch(('/project_posts/' + data.id), { method: 'DELETE' })
      .then((response) => {
        return response.status === 204
      })
      .then((isDeleted) => {
        return isDeleted
      })
      .catch((error) => {
        commit(types.SET_PROJECT_POSTS_ERROR, error.message)
      })
  },

  tags({commit, rootState}) {
    commit(types.SET_PROJECT_POSTS_LOADING);
    return fetch('/projects/' + rootState.project.get.project.id + '/project_posts_tags')
      .then(response => response.json())
      .then((data) => {
        commit(types.SET_PROJECT_POSTS_TAGS, data)
        return data
      })
      .catch((error) => {
        commit(types.SET_PROJECT_POSTS_ERROR, error)
      })
  },

  updateForumNotify({commit, dispatch}, data) {
    return fetch('/project_member_settings/' + data.id, {
      method: 'PATCH',
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then((data) => {
        // Refresh membership data
        dispatch('user/profile/fetchSession', null, {root:true});
        return data
      })
      .catch((error) => {
        commit(types.SET_PROJECT_POSTS_ERROR, error)
      })
  },
}

const types = {
  SET_PROJECT_POSTS_LOADING: 'SET_PROJECT_POSTS_LOADING',
  SET_PROJECT_POSTS: 'SET_PROJECT_POSTS',
  SET_PROJECT_POST_SINGLE: 'SET_PROJECT_POST_SINGLE',
  SET_PROJECT_POSTS_ERROR: 'SET_PROJECT_POSTS_ERROR',

  SET_PROJECT_POSTS_TAGS_LOADING: 'SET_PROJECT_POSTS_TAGS_LOADING',
  SET_PROJECT_POSTS_TAGS: 'SET_PROJECT_POSTS_TAGS',
  SET_PROJECT_POSTS_TAGS_ERROR: 'SET_PROJECT_POSTS_TAGS_ERROR',

  RESET_ERROR: 'RESET_ERROR',
  RESET: 'RESET',
}

const mutations = {
  [types.SET_PROJECT_POSTS_LOADING](state, value=true) {
    state.isLoading = true;
  },
  [types.SET_PROJECT_POSTS](state, posts) {
    state.posts = posts
    state.isLoading = false
  },
  [types.SET_PROJECT_POST_SINGLE](state, post) {
    state.singlePost = post
    state.isLoading = false
  },
  [types.SET_PROJECT_POSTS_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },

  [types.SET_PROJECT_POSTS_TAGS_LOADING](state) {
    state.isLoadingTags = true;
  },
  [types.SET_PROJECT_POSTS_TAGS](state, posts) {
    state.tags = posts
    state.isLoadingTags = false
  },
  [types.SET_PROJECT_POSTS_TAGS_ERROR](state, error) {
    state.tagsError = error;
    state.isLoadingTags = false;
  },

  [types.RESET_ERROR](state) {
    state.isLoading = false;
    state.error = null;
    state.isLoadingTags = false;
    state.tagsError = null;
  },
  [types.RESET](state) {
    state = initialState
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters: getters,
  actions: actions,
  mutations: mutations
}
