import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,
  state: {
    error: '',
    isLoading: false,
    violations: null,
    updated: false,
    expiredToken: false,
    invalidToken: false,
  },
  actions,
  getters,
  mutations
}
