import fetch from '../../../../utils/fetch'

const getInitialState = () => {
  return {
    isLoading: false,
    photoObservations: null,
    projectId: null,
    error: null,
  }
}

const state = getInitialState();

const getters = {
  isLoading(state) {
    return state.isLoading
  },
  projectId(state) {
    return state.projectId
  },
  photoObservations(state) {
    return state.photoObservations
  },
  error(state) {
    return state.error
  },
}

const actions = {
  /**
   * @function getRecentPhotoObservations - retrieve recent photo observations for a project
   * @param commit
   * @param state
   * @param rootGetters
   * @param itemsPerPage
   * @returns {Promise<T | never> | * | *}
   */
  get({commit, state, rootGetters}, itemsPerPage) {
    commit(types.RESET)
    commit(types.SET_PHOTO_OBSERVATIONS_LOADING)
    if (!itemsPerPage) {
      itemsPerPage = 6
    }
    commit(types.SET_PROJECT_ID, rootGetters['project/get/id'])
      let urlParams = "?order[observedAt]=desc&records.recordType=image&itemsPerPage=" + itemsPerPage
      return fetch('/projects/' + state.projectId +'/observations' + urlParams)
        .then(response => response.json())
        .then((data) => {
          commit(types.SET_PHOTO_OBSERVATIONS, data['hydra:member'])
        })
        .catch((error) => {
          commit(types.SET_PHOTO_OBSERVATIONS_ERROR, error)
        })
  },
}

const types = {
  SET_PHOTO_OBSERVATIONS_LOADING: 'SET_PHOTO_OBSERVATIONS_LOADING',
  SET_PROJECT_ID: 'SET_PROJECT_ID',
  SET_PHOTO_OBSERVATIONS: 'SET_PHOTO_OBSERVATIONS',
  SET_PHOTO_OBSERVATIONS_ERROR: 'SET_PHOTO_OBSERVATIONS_ERROR',
  RESET_ERROR: 'RESET_ERROR',
  RESET: 'RESET',
}

const mutations = {
  [types.SET_PHOTO_OBSERVATIONS_LOADING](state) {
    state.isLoading = true;
  },
  [types.SET_PROJECT_ID](state, id) {
    state.projectId = id
  },
  [types.SET_PHOTO_OBSERVATIONS](state, observations) {
    state.photoObservations = observations
    state.isLoading = false
  },
  [types.SET_PHOTO_OBSERVATIONS_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },
  [types.RESET_ERROR](state) {
    state.isLoading = false;
    state.error = false;
  },
  [types.RESET](state) {
    Object.assign(state, getInitialState());
  },
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
}
