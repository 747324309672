import fetch from '../../../../utils/fetch'

const initialState = {
  isLoading: false,
  invites: null,
  error: null,
  inviteExists: false,
  memberExists: false,
}

const getters = {
  invites(state) {
    return state.invites
  },
  isLoading(state) {
    return state.isLoading
  },
  inviteExists(state) {
    return state.inviteExists
  },
  memberExists(state) {
    return state.memberExists
  },
  error(state) {
    return state.error
  },
}

const actions = {

  get({commit, rootState}, data) {
    commit(types.SET_PROJECT_INVITES_LOADING);
    return fetch('/projects/' + rootState.project.get.project.id + '/invites' + data.urlParams)
      .then(response => response.json())
      .then((invites) => {
        commit(types.SET_PROJECT_INVITES, invites)
        return invites
      })
      .catch((error) => {
        commit(types.SET_PROJECT_INVITES_ERROR, error)
      })
  },

  create({ commit, rootState }, data) {
    commit(types.SET_PROJECT_INVITES_LOADING)
    return fetch(('/projects/' + rootState.project.get.project.id + '/invite'), {
      method: 'POST',
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then((invite) => {
        return invite
      })
      .catch((error) => {
        commit(types.SET_PROJECT_INVITES_ERROR, error.message)
      })
  },

  // edit({ commit, rootState }, data) {
  //   commit(types.SET_PROJECT_INVITES_LOADING)
  //   return fetch(('/project_INVITES/' + data.id), {
  //     method: 'PUT',
  //     body: JSON.stringify(data)
  //   })
  //     .then(response => response.json())
  //     .then((invite) => {
  //       return invite
  //     })
  //     .catch((error) => {
  //       commit(types.SET_PROJECT_INVITES_ERROR, error.message)
  //     })
  // },

  checkInviteExists({commit, rootState}, data) {
    commit(types.SET_PROJECT_INVITES_LOADING);
    return fetch('/projects/' + rootState.project.get.project.id + '/invites?email=' + encodeURIComponent(data.email))
      .then(response => response.json())
      .then((data) => {
        commit(types.SET_INVITE_EXISTS, data['hydra:member'].length !== 0)
        return data['hydra:member']
      })
      .catch((error) => {
        commit(types.SET_PROJECT_INVITES_ERROR, error)
      })
  },

  checkMemberExists({commit, rootState}, data) {
    commit(types.SET_PROJECT_INVITES_LOADING);
    return fetch('/projects/' + rootState.project.get.project.id + '/members?member.email=' + encodeURIComponent(data.email))
      .then(response => response.json())
      .then((data) => {
        commit(types.SET_MEMBER_EXISTS, data['hydra:member'].length !== 0)
        return data['hydra:member']
      })
      .catch((error) => {
        commit(types.SET_PROJECT_INVITES_ERROR, error)
      })
  },

  delete ({ commit }, data) {
    commit(types.SET_PROJECT_INVITES_LOADING)
    return fetch(('/project_member_invites/' + data.id), {
      method: 'DELETE'
    })
      .then((response) => {
        return response.status === 204
      })
      .then((isDeleted) => {
        return isDeleted
      })
      .catch((error) => {
        commit(types.SET_PROJECT_INVITES_ERROR, error.message)
      })
  }
}

const types = {
  SET_PROJECT_INVITES_LOADING: 'SET_PROJECT_INVITES_LOADING',
  SET_PROJECT_INVITES: 'SET_PROJECT_INVITES',
  SET_PROJECT_INVITES_ERROR: 'SET_PROJECT_INVITES_ERROR',

  SET_INVITE_EXISTS: 'SET_INVITE_EXISTS',
  SET_MEMBER_EXISTS: 'SET_MEMBER_EXISTS',

  RESET_ERROR: 'RESET_ERROR',
  RESET: 'RESET',
}

const mutations = {
  [types.SET_PROJECT_INVITES_LOADING](state) {
    state.isLoading = true;
  },
  [types.SET_PROJECT_INVITES](state, resources) {
    state.resources = resources
    state.isLoading = false
  },
  [types.SET_INVITE_EXISTS](state, value) {
    state.inviteExists = value
    state.isLoading = false
  },
  [types.SET_MEMBER_EXISTS](state, value) {
    state.memberExists = value
    state.isLoading = false
  },
  [types.SET_PROJECT_INVITES_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },
  [types.RESET_ERROR](state) {
    state.isLoading = false;
    state.error = null;
  },
  [types.RESET](state) {
    state = initialState
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters: getters,
  actions: actions,
  mutations: mutations
}
