<template>
  <div class="d-flex w-100 justify-content-center align-items-center">
    <span class="flex-none ">
      <v-progress-circular :size="50" color="#00AAEF" indeterminate />
    </span>

    <span v-if="text" class="ml-3 h3 flex-none ">
      {{ text }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      default: null,
      type: String,
    },
  },
};
</script>
<style scoped>
.flex-none {
flex: unset!important;
}
</style>