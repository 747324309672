<template>
  <div>
    <b-row>
      <b-col>
        <b-card class="col-6 mb-2" border-variant="light" bg-variant="light">
          <b><u>Authorization Status:</u></b>
          <b-container fluid>
            <div v-if="(userSession['isAirtable'] && !userSession['isAirtableExpired'])">
              Authorized<i class="fa fa-check ml-3"></i>
            </div>
            <div v-else-if="(userSession['isAirtable'] && userSession['isAirtableExpired'])">
              Expired<i class="fa fa-hourglass-end ml-3"></i><br>
              <b-card class="col-12">
                <span>Oops! Looks like this integration hasn't been used in a while...</span><br>
                <span>Just click 'Authorize' to renew the Integration.</span><br>
                <AuthorizeButton></AuthorizeButton>
              </b-card>
            </div>
            <div v-else>
              Not Initiated<i class="fa fa-times ml-3"></i><br><br>
              <AuthorizeButton></AuthorizeButton>
              <br><br>
              <b-button class="btn btn-sm dropdown-toggle" @click="collapse = !collapse" variant="secondary"
                        h-align="center">
                <font-awesome-icon icon="info"/>
                &emsp;Instructions
              </b-button>
              <br>
              <b-row class="justify-content-lg-center">
                <b-card class="col-12" v-show="!collapse" border-variant="secondary" bg-variant="light">
                  <span>
                    1. Click on 'Authorize' to be redirected to Airtable.com.<br>
                    2. Login with your Airtable credentials (if you are not already logged in).<br>
                    3. Select the Airtable Bases you want CitSci to have access to.<br>
                    4. Click 'Grant access'.<br>
                    5. You will be redirected back to CitSci to complete the Integration.<br>
                    6. Success! Get ready to send your data to your Airtable Bases.<br>
                  </span>
                </b-card>
              </b-row>
            </div>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {airTableOAuth} from "../../../../main";
import AuthorizeButton from "./authorizeButton.vue";

export default {
  components: {AuthorizeButton},
  data() {
    return {
      collapse: true,
    };
  },
  created() {
  },
  watch: {},
  computed: {
    ...mapGetters({
      userSession: 'user/profile/sessionData',
    }),
  },
  methods: {
    ...mapActions({
      saveProjectId: 'integration_airtable/authorize/saveProjectId',
    }),

    // Handle authorize with airtable
    async onClickAuthorizeAirtable() {
      // Current project id is saved to local storage
      //  - this facilitates returning the user to the project after authorizing with airtable
      this.saveProjectId()
      // Set the data param that will be round tripped
      let randData = window.crypto.getRandomValues(new Uint32Array(28));
      airTableOAuth.createSigninRequest({state: {bar: randData}})
        .then(function (req) {
          window.location = req.url;
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }
}
</script>

<style lang="scss">

</style>
