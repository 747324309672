import SubmissionError from "../error/SubmissionError";
import { BITLY_URL } from '../config/_entrypoint'
import { BITLY_LOGIN } from '../config/_entrypoint'
import { BITLY_KEY } from '../config/_entrypoint'

export const bitlyURL = (longUrl) => {
  // send API request
  return fetch(BITLY_URL + 'apiKey=' + BITLY_KEY + '&format=json' + '&login=' + BITLY_LOGIN + "&longUrl=" + encodeURI(longUrl),{ method: 'GET'}
  ).then((response) => {
    if (response.ok) return response.json()

    return response
      .json()
      .then((json) => {
        let error = ''

        if (json['hydra:description']) {
          error = json['hydra:description']
        } else if (json['message']) {
          error = json['message']
        } else {
          error = response.statusText
        }

        if (!json.violations) throw Error(error)

        const errors = { _error: error }
        json.violations.map(violation =>
          Object.assign(errors, { [violation.propertyPath]: violation.message }))

        throw new SubmissionError(errors)
      })
  }).catch((e) => {
    if (e instanceof SubmissionError) {
      return
    }
  })
}
