<template>
  <modal class="vue-js-modal-box"
          id="vue-js-modal-box"
          transition="nice-modal-fade"
          :name="modalName"
          :adaptive="true"
          :draggable="false"
          :resizable="true"
          :clickToClose="false"
          :reset="true"
          :width="modalWidth"
          :height="modalHeight"
          :minWidth="modalMinWidth"
          :minHeight="modalMinHeight">
    <header class="modal-header1">
      <div>
        <h4 class="mb-0 px-2">
          {{ modalTitle }}
          <span v-if="addOrganismsModalAllSpeciesSelected || (addedSpeciesToOrganismRecord && addedSpeciesToOrganismRecord.allowAnyOrganism)" class="count-items">(all Items)</span>
          <span v-else-if="itemsSelected !== 0" class="count-items">({{ itemsSelected }} Item<span v-if="itemsSelected > 1">s</span>)</span>
        </h4>

        <v-icon
          class="top-right-close-button"
          @click="$modal.hide(modalName)"
        >
          close
        </v-icon>
      </div>
      <hr>
    </header>

    <section class="modal-body pt-0 add-organisms-scroll-top">
      <VueLoader
        v-if="isLoading"
      />
      <slot v-show='!isLoading' name="body"></slot>
    </section>

    <footer class="modal-footer">
      <v-btn
        @click="$modal.hide(modalName)"
        class="px-5"
        color="#00AAEF"
        dark
      >Cancel</v-btn>
      <v-btn
        @click="modalName === 'location-picklist' ? handleSubmit('location') : handleSubmit()"
        class="px-5"
        color="#00AAEF"
        dark
      >{{ submitButtonName }}</v-btn>
    </footer>


  </modal>
</template>

<script>
  import VueLoader from './Loader'

  export default {
    name: 'VueCustomModal',

    components: {
      VueLoader
    },

    props: {
      handleSubmit: {
        type: Function,
        default: () => {}
      },

      modalName: {
        type: String,
        default: () => {
          return 'modal-name'
        }
      },

      modalTitle: {
        type: String,
        default: () => {
          return 'Modal Title'
        }
      },

      submitButtonName: {
        type: String,
        default: 'Done'
      },

      modalWidth: {
        type: Number,
        default: () => {
          return 1050
        }
      },

      modalHeight: {
        type: Number,
        default: () => {
          return 580
        }
      },

      modalMinWidth: {
        type: Number,
        default: () => {
          return 945
        }
      },

      modalMinHeight: {
        type: Number,
        default: () => {
          return 350
        }
      },

      itemsSelected: {
        type: Number,
        default: 0
      },

      totalItems: {
        type: Number,
        default: 0
      },

      isLoading: {
        type: Boolean,
        default: false
      },

      addedSpeciesToOrganismRecord: {
        type: Object,
        default: () => {}
      },

      addOrganismsModalAllSpeciesSelected: {
        type: Boolean,
        default: false
      }
    },

  }
</script>

<style lang="scss" scoped>
  .vue-js-modal-box {
    .modal-header1 {
      text-align: center;
      padding-top: 25px;
      height: 50px;

      hr {
        width: 100%;
        margin-top: -15px;
        border-color: rgba(191, 191, 191, 0.5)
      }

      h4 {
        display: inline;
        background: #ffffff;
        color: rgb(135, 152, 63);
        font-size: 20px;
        margin: auto;
      }

      .count-items {
        color: rgba(0, 170, 239, 1);
        font-weight: 100;
        font-size: 22px;
      }

      .top-right-close-button {
        position: absolute;
        top: 14px;
        right: 10px;
      }
    }

    .modal-body {
      height: calc(100% - 136px);
      overflow: auto;
      overflow-x: hidden;
    }

    .modal-footer {
      bottom: 0;
      background: #ffffff;
    }
  }

  // START -- Add Organisms Modal Tabs -- style
  .custom-modal-styles /deep/ {
      .v--modal-background-click .v--modal-box {
          overflow: initial;

          .modal-body {
              padding-left: 0;
              padding-right: 0;

              .add-organisms-table {
                  .v-table__overflow {
                      overflow: hidden;

                      thead {
                          tr {
                              height: 32px;

                              &.add-organisms-table-header {
                                  background-color: #707070;
                              }

                              th.column {
                                  color: #FFFFFF;
                                  font-weight: bold;
                                  padding: 0 10px;

                                  i {
                                      color: #BFBFBF;
                                      opacity: 0.3;
                                      font-size: 24px;
                                      margin-bottom: -4px;
                                  }

                                  &.active,
                                  &:hover {
                                      color: #ffffff;
                                  }

                                  &.active i {
                                      color: #BFBFBF;
                                      opacity: 1;
                                  }

                                  &.options-column {
                                      width: 100px;
                                      text-align: center;
                                  }
                              }
                          }
                      }

                      tbody {

                          tr {

                              &:hover,
                              &[active] {
                                  background: rgba(0, 170, 239, 0.1);
                              }

                              td {
                                  padding: 0;
                                  font-size: 15px;
                                  font-weight: bold;
                                  color: rgba(50, 60, 71, 0.5);

                                  &.add-organisms-table-scientific-name-column {
                                      color: rgba(50, 60, 71, 1);
                                  }

                                  i.add-organisms-table-common-name-icon {
                                      color: #DEDEDE;
                                      font-size: 19px;
                                  }

                                  .item-details {
                                      padding: 0 15px;
                                      display: block;
                                      line-height: 50px;
                                  }

                                  &.add-organisms-table-rank-column,
                                  &.add-organisms-table-appearance-on-datasheet-column {
                                      .item-details {
                                          padding-left: 34px;
                                      }
                                  }

                                  &.add-organisms-table-options-icon {
                                      float: right;
                                      width: 100%;

                                      .accent--text {
                                          color: rgba(0, 170, 239, 1) !important;
                                          caret-color: rgba(0, 170, 239, 1) !important;
                                      }

                                      .item-details {
                                          padding: 13px 15px;

                                          .v-input--checkbox {
                                              margin: auto;
                                              width: 24px;
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }

          .modal-footer {
              .v-btn {
                  margin: auto;
                  height: 32px;
              }
          }
      }
  }
  // END -- Add Organisms Modal Tabs -- style
</style>
