
<template>
  <div v-if="record.recordType === 'organism'" class="">
    <v-alert :value="!!retrievedOrganismsError" type="error">
      {{ retrievedOrganismsError }}
    </v-alert>
    <button class="btn btn-block btn-success w-50 mb-4" @click="openOrganismsModal">
      Add or Edit Organisms
    </button>
    <div v-if="record.allowAnyOrganism">
      <h4>The user will be able to select any organism.</h4>
    </div>
    <div v-else-if="record.organisms.length === 0">
      <h4>Please select an organism</h4>
    </div>
    <b-table
      v-else
      ref="projectMembersTable"
      size="sm"
      :items="record.organisms"
      :fields="tableFields"
    >
    </b-table>
    <VueCustomModal
      v-if="addOrganismsModalIsDisplayed"
      class="custom-modal-styles"
      modal-title="Add Organisms"
      modal-name="add-organisms"
      :handle-submit="onSubmitSelectedSpecies"
      :items-selected="modalOrganisms.length"
      :total-items="retrievedOrganisms.length"
      :is-loading="retrievedOrganismsIsLoading"
      :added-species-to-organism-record="addedSpeciesToOrganismRecord"
      :add-organisms-modal-all-species-selected="modalAllowAnyOrganism"
    >
      <template slot="body">
        <VueTable
          class="add-organisms-table"
          table-name="add-organisms-table"
          :headers="dcAddOrganismsModalTableColumns"
          :items="retrievedOrganisms"
          :species-next-page-info="dOrganismRecordEditModeSpeciesNextPageObject"
          :selected="modalOrganisms"
          @interface="modalOrganisms = $event"
          :retrieved-organisms-error="retrievedOrganismsError"
          :retrieved-organisms-view="retrievedOrganismsView"
          :active-record="record"
          :get-data="retrieveOrganisms"
          :added-species-to-organism-record="addedSpeciesToOrganismRecord"
          :add-organisms-modal-all-species-selected="modalAllowAnyOrganism"
          @selectedAllSpecies="modalAllowAnyOrganism = $event"
        />
      </template>
    </VueCustomModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueCustomModal from "../../components/layout/CustomModal";
import VueTable from "../../components/layout/Table";

const dcAddOrganismsModalTableColumns = [
  {
    value: "scientificName",
    text: "Scientific Name",
    align: "left",
    sortable: true,
  },
  {
    value: "canonicalName",
    text: "Common Name(s)",
    align: "left",
    sortable: true,
  },
];
export default {
  name: "OrganismSelect",
  components: {
    VueCustomModal,
    VueTable,
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      tableFields: [
        {
          key: "customName",
          label: "Custom Name",
          align: "left",
          sortable: true,
        },
        {
          key: "scientificName",
          label: "Scientific Name",
          align: "left",
          sortable: true,
        },
        {
          key: "canonicalName",
          label: "Common Name(s)",
          align: "left",
          sortable: true,
        },
      ],
      addOrganismsModalIsDisplayed: false,

      modalOrganisms: [],
      modalAllowAnyOrganism: true,
      dcAddOrganismsModalTableColumns: dcAddOrganismsModalTableColumns,
      dOrganismRecordEditModeSpeciesNextPageObject: {},
      sendPageDetailsToOrganismsPopup: false,

      dOrganismRecordSpecies: [],
      dOrganismRecordEditModeSelectedSpecies: [],
      dOrganismRecordEditModeSpeciesPerPage: [],
      dOrganismRecordEditModeSpeciesNextPageObject: {},
    };
  },
  created() {
    this.modalOrganisms = this.record.organisms;
    this.modalAllowAnyOrganism = this.record.allowAnyOrganism;
  },
  watch: {
    "record.organisms": function (organisms) {
      this.modalOrganisms = organisms.organisms;
      this.modalAllowAnyOrganism = organisms.allowAnyOrganism;
    },
  },
  computed: {
    ...mapGetters("datasheet/creator", [
      "retrievedOrganisms",
      "retrievedOrganismsView",
      "retrievedOrganismsError",
      "retrievedOrganismsIsLoading",
      "addedSpeciesToOrganismRecord",
    ]),
  },

  methods: {
    ...mapActions({
      retrieveOrganisms: "datasheet/creator/retrieveOrganisms",
    }),
    openOrganismsModal() {
      this.sendPageDetailsToOrganismsPopup = true;
      this.addOrganismsModalIsDisplayed = true;
      this.modalAllowAnyOrganism = false;
      this.$nextTick(() => {
      this.$modal.show("add-organisms");
      });
    },
    onSubmitSelectedSpecies() {
      //
      let newRecord = {};
      if (this.modalAllowAnyOrganism) {
        newRecord = {
          ...this.record,
          organisms: [],
          allowAnyOrganism: true,
        };
      } else {
        let organisms = [];
        this.modalOrganisms.forEach((species) => {
          if (species.hasOwnProperty("id")) {
            if (
              this.dOrganismRecordSpecies.filter(
                (item) => item.organismId === species.id
              ).length === 0
            ) {
              organisms.push({
                organism: species["@id"],
                customName: species.customName || null,
                scientificName: species.scientificName,
                canonicalName: species.canonicalName,
              });
            } else {
              if (
                species.customName !==
                this.dOrganismRecordSpecies.filter(
                  (item) => item.organismId === species.id
                )[0].initialCustomName
              ) {
                organisms.push({
                  "@id": this.dOrganismRecordSpecies.filter(
                    (item) => item.organismId === species.id
                  )[0]["@id"],
                  customName: species.customName,
                  scientificName: species.scientificName,
                  canonicalName: species.canonicalName,
                });
              } else {
                organisms.push(
                  this.dOrganismRecordSpecies.filter(
                    (item) => item.organismId === species.id
                  )[0]["@id"]
                );
              }
            }
          } else {
            if (species.customName !== species.initialCustomName) {
              organisms.push({
                "@id": species["@id"],
                customName: species.customName,
                scientificName: species.scientificName,
                canonicalName: species.canonicalName,
              });
            } else {
              organisms.push(species["@id"]);
            }
          }
        });
        newRecord = {
          ...this.record,
          organisms: organisms,
          allowAnyOrganism: false,
        };
        // add the selected species to organism record
        // this.addSelectedSpeciesToOrganismRecord({
        //   id: this.record["@id"],
        //   allowAnyOrganism: false,
        //   organisms: organisms,
        // });
      }
      // hide the modal
      this.$emit("updateRecord", newRecord);
      this.addOrganismsModalIsDisplayed = false;
      this.$modal.hide("add-organisms");
    },
  },
  watch: {
    addedSpeciesToOrganismRecord(record) {
      if (!record) {
        return;
      }

      this.dObject.records = this.dObject.records.map((obj) =>
        record.id === obj.id ? record : obj
      );
      this.modalOrganisms = record.organisms;
      this.dActiveRecord.allowAnyOrganism = record.allowAnyOrganism;
      this.dActiveRecord.organisms = record.organisms;
      this.dOrganismRecordSpecies = record.organisms;
      this.dOrganismRecordSpecies.forEach((species) => {
        species.initialCustomName = species.customName;
      });

      if (
        !this.dActiveRecord ||
        !this.dActiveRecord.allowAnyOrganism ||
        !this.addOrganismsModalIsDisplayed ||
        (this.dActiveRecord &&
          this.dActiveRecord.allowAnyOrganism &&
          this.addOrganismsModalIsDisplayed &&
          this.retrievedOrganismsView["@id"].indexOf("page=1") !== -1)
      ) {
        this.dOrganismRecordEditModeSelectedSpecies = this.dActiveRecord.organisms;
      }

      if (
        record.recordType === "organism" &&
        !record.allowAnyOrganism &&
        record.organisms &&
        record.organisms.length === 0
      ) {
        this.updateDatasheetPublished(false);
      }
    },

    retrievedOrganisms(species) {
      if (!species) {
        return;
      }

      if (!this.addOrganismsModalIsDisplayed) {
        this.dOrganismRecordEditModeSpeciesPerPage = species;
      }
    },

    retrievedOrganismsView(pageDetails) {
      if (!pageDetails) {
        return;
      }

      if (
        !this.addOrganismsModalIsDisplayed ||
        this.sendPageDetailsToOrganismsPopup
      ) {
        this.dOrganismRecordEditModeSpeciesNextPageObject = pageDetails;
        this.sendPageDetailsToOrganismsPopup = false;
      }
    },
  },
};
</script>
<style scoped>
</style>