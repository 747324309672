<template>
  <div class="logout text-center mt-5" style="margin-bottom:600px;">
    <h1>Thank you for visiting us!</h1>
    <p>You are now logged out of CitSci</p>
    <p>Redirecting to the Home page in {{ timerCount }}...</p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      timerCount: 13,
    };
  },
  computer: {
    ...mapGetters({
      hasSession: "user/profile/hasSession",
    })
  },
  methods: {
    ...mapActions({
      logout: "user/auth/logout",
      clearUserData: "user/profile/reset",
    }),
  },
  created() {
    this.clearUserData();
    this.logout();
    if(this.$route.query.redirect){
        // accept invite / other redirects if present
        this.$router.push({ path: this.$route.query.redirect });                    
    }
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        } else if (value === 0) {
          this.$router.push({ name: "HomePage" });
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
