<template>
  <highcharts v-if="chartData.length > 0" :options="chartOptions" ref="pie"></highcharts>
  <span v-else>No Observations</span>
</template>

<script>
import Highcharts from "highcharts";
import exporting from "highcharts/modules/exporting";
import offlineExporting from "highcharts/modules/offline-exporting";

exporting(Highcharts);
offlineExporting(Highcharts);

export default {
  name: 'PieChart',
  props: {
    sampleSize: {
      type: Number,
      default: () => {
        return 0
      },
      required: true,
    },
    chartData: {
      type: Array,
      default: () => {
        return []
      },
      required: true,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'pie',
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
        },
        tooltip: {
          formatter: function () {
            return '<b>'+this.key+': </b>' +this.y+ '% (' +this.point.num+ ')'
          }
        },
        title: {
          text: "Sample Size: " + this.sampleSize,
          align: "left",
          style: {
            fontSize: "1",
            fontWeight: "regular",
          }
        },
        credits: {
          enabled: true,
          text: 'Powered by CitSci via HighCharts',
          href: ''
        },
        series: [{ data: this.chartData }]
      }
    }
  },
  watch: {
    chartData(newVal) {
      this.chartOptions  = Highcharts.merge(this.chartOptions, {
          series: [{ data: newVal }]
      })
    },
  sampleSize(newVal) {
    this.chartOptions = Highcharts.merge(this.chartOptions, {
      title: {
        text: "Sample Size: " + newVal,
      }
    })
    },
  }
}
</script>
