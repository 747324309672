<template>
  <div v-if="project" class="mr-3 mt-3 ml-3 mb-3">
    <h3 v-if="!hasSession">
      <i class="fa fa-exclamation-circle mr-3 text-primary" aria-hidden="true"></i>
      You are not Logged In
    </h3>
    <h3 v-else>
      <i class="fa fa-exclamation-circle mr-3 text-primary" aria-hidden="true"></i>
      You are not a Member of this Project
    </h3>
    <p>
      <span class="font-weight-bold">{{ project.name }}</span> requires you to
      be {{ hasSession ? 'a member ' : ' logged in ' }} to view the '{{ page }}' page.
    </p>
    <div class="row">
      <div class="col-md-2">
        <ProjectJoinButton
          v-bind:project="project"
          btnClass="btn-md"></ProjectJoinButton>
      </div>
      <div v-if="!hasSession" class="col-md-2">
        <router-link
          :to="{ name: 'Register', query: { redirect: $route.fullPath } }"
          class="btn btn-md btn-block btn-primary mt-1"
        >
          Sign Up
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProjectJoinButton from "../../../../assets/scripts/project/projectJoinButton.vue";

export default {
  name: "RequiresMember",
  components: {ProjectJoinButton},
  props: {
    page: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapGetters({
      project: "project/get/project",
      hasSession: "user/profile/hasSession",
    })
  },
};
</script>
