import creator from './creator'
import form from './form'
import get from './get'
import record from './record'

export default {
  namespaced: true,
  modules: {
    creator,
    form,
    get,
    record,
  }
}
