// invite people form mutation types
export const INVITE_PEOPLE_RESET = 'INVITE_PEOPLE_RESET'
export const INVITE_PEOPLE_SET_CREATED = 'INVITE_PEOPLE_SET_CREATED'
export const INVITE_PEOPLE_SET_ERROR = 'INVITE_PEOPLE_SET_ERROR'
export const INVITE_PEOPLE_SET_VIOLATIONS = 'INVITE_PEOPLE_SET_VIOLATIONS'
export const INVITE_PEOPLE_TOGGLE_LOADING = 'INVITE_PEOPLE_TOGGLE_LOADING'
// email members form mutation types
export const EMAIL_MEMBERS_RESET = 'EMAIL_MEMBERS_RESET'
export const EMAIL_MEMBERS_SET_CREATED = 'EMAIL_MEMBERS_SET_CREATED'
export const EMAIL_MEMBERS_SET_ERROR = 'EMAIL_MEMBERS_SET_ERROR'
export const EMAIL_MEMBERS_SET_VIOLATIONS = 'EMAIL_MEMBERS_SET_VIOLATIONS'
export const EMAIL_MEMBERS_TOGGLE_LOADING = 'EMAIL_MEMBERS_TOGGLE_LOADING'
