function injectStyle (context) {
  require("!!vue-style-loader!css-loader!../../../node_modules/vue-loader/lib/style-compiler/index?{\"optionsId\":\"0\",\"vue\":true,\"id\":\"data-v-053946d6\",\"scoped\":true,\"sourceMap\":false}!sass-loader!../../assets/styles/contact/show.scss")
}
/* script */
export * from "!!babel-loader!../../assets/scripts/contact/show.js"
import __vue_script__ from "!!babel-loader!../../assets/scripts/contact/show.js"/* template */
import {render as __vue_render__, staticRenderFns as __vue_static_render_fns__} from "!!../../../node_modules/vue-loader/lib/template-compiler/index?{\"id\":\"data-v-053946d6\",\"hasScoped\":true,\"optionsId\":\"0\",\"buble\":{\"transforms\":{}}}!../../templates/contact/show.html"
/* template functional */
var __vue_template_functional__ = false
/* styles */
var __vue_styles__ = injectStyle
/* scopeId */
var __vue_scopeId__ = "data-v-053946d6"
/* moduleIdentifier (server only) */
var __vue_module_identifier__ = null
import normalizeComponent from "!../../../node_modules/vue-loader/lib/runtime/component-normalizer"
var Component = normalizeComponent(
  __vue_script__,
  __vue_render__,
  __vue_static_render_fns__,
  __vue_template_functional__,
  __vue_styles__,
  __vue_scopeId__,
  __vue_module_identifier__
)

export default Component.exports
