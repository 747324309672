<template>
<div class="col-12 pt-1">
    <div class="font-weight-bold text-muted">
        {{ record.label }} <span v-if="false" class="text-danger">&#42;</span>
    </div>
    <div v-if="record.description">
        {{ record.description }}
    </div>
    <div v-if="!record.datasheetRecord || !record.datasheetRecord.isActive">
        <i class="fa fa-trash text-warning"></i>
        This question has been removed from the current datasheet
    </div>
    <div v-if="typeOriginal !== record.recordType">
        <i class="fa fa-warning text-danger"></i>
        This field has been modified from {{ typeOriginal }} to
        {{ record.recordType }} as the options were deleted
    </div>
    <div v-if="
          record.datasheetRecord &&
          record.datasheetRecord.label !== record.label
        ">
        <i class="fa fa-tag text-warning"></i>
        When this observation was created, the question was "{{
          record.label
        }}". Since then a project manager has modified the question to "{{
          record.datasheetRecord.label
        }}". Answer the Original question if possible.
    </div>
    <div v-if="
          record.datasheetRecord &&
          record.datasheetRecord.description !== record.description
        ">
        <i class="fa fa-align-left text-warning"></i>
        When this observation was created, the question description was "{{
          record.description
        }}". Since then a project manager has modified the question description
        to "{{ record.datasheetRecord.description }}"
    </div>
    <div class="input-group">
        <input type="number" v-model.number="value" class="form-control" :min='min' :max='max'>
        <div class="input-group-append">
            <button class="btn px-4 btn-outline-secondary" type="button" @click="increment('decrease')"><i class="fa fa-minus"></i></button>
            <button class="btn px-4 btn-secondary" type="button" @click="increment('increase')"><i class="fa fa-plus"></i></button>
        </div>

    </div>
    <div v-if="value !== valueOriginal" class="mt-1">
        Original value: {{ valueOriginal }}
        <button class="btn btn-sm py-0 btn-warning" @click="onUndoChange()" title="Undo changes to this data">
            <i class="fa fa-undo fa-sm"></i>
        </button>
    </div>
</div>

</div>
</template>

<script>
export default {
    name: "observation-record-number",
    components: {},
    data() {
        return {
            valueOriginal: Number(this.record.value) ? Number(this.record.value) : null,
            typeOriginal: this.record.recordType,
            min: null,
            max: null,
        };
    },
    props: {
        record: {
            type: Object,
            required: true,
        },
    },
    created() {
        const maxObj = this.record.datasheetRecord.validators.find((validator) => {
            return validator.validatorType === "max"
        })
        const minObj = this.record.datasheetRecord.validators.find((validator) => {
            return validator.validatorType === "min"
        })
        if (maxObj && maxObj.value) {
            this.max = Number(maxObj.value);
        }
        if (minObj && minObj.value) {
            this.min = Number(minObj.value);
        }
    },
    computed: {
        value: {
            get() {
                return Number(this.record.value) ? Number(this.record.value) : null;
            },
            set(newVal) {
                this.record.value = newVal.toString();
            }
        }
    },
    methods: {
        increment(direction) {
            let newValue = 0;
            if ((!Number.isFinite(this.value)) && direction === 'increase') {
                newValue = 1;
            } else if (direction === 'increase') {
                newValue = Number(this.value) + 1;
            } else if ((!Number.isFinite(this.value)) && direction === 'decrease') {
                newValue = 0;
            } else if (direction === 'decrease') {
                newValue = Number(this.value) - 1;
            }
            /// check to see if value is in bounds
            if (this.max && this.max < newValue) {
                newValue = this.max;
            }
            if (this.min && this.min > newValue) {
                newValue = this.min;
            }
            this.value = newValue
        },
        onUndoChange() {
            this.value = this.valueOriginal;
        },
    },
};
</script>
