import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,
  state: {
    uploadIsLoading: false,
    uploadError: '',
    retrievedFile: null
  },
  actions,
  getters,
  mutations
}
