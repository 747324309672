import fetch from '../../../../utils/fetch'
import {urlBuilder} from "../../../../helpers/url_builder_helper";

const getInitialState = () => {
  return {
    isLoading: false,
    locations: [],
    error: null,
  }
}

const state = getInitialState();

const getters = {
  isLoading(state) {
    return state.isLoading
  },
  searchResults(state) {
    return state.locations
  },
  error(state) {
    return state.error
  },
}

const actions = {
  search({commit, rootState}, searchText) {
    commit(types.RESET)
    commit(types.SET_PROJECT_LOCATIONS_LOADING)
    return fetch('/projects/' + rootState.project.get.project.id + '/locations?name[searchAny]=' + searchText)
      .then(response => response.json())
      .then((data) => {
        commit(types.SET_PROJECT_LOCATIONS, data['hydra:member'])
        return data
      })
      .catch((e) => {
        commit(types.SET_PROJECT_LOCATIONS_ERROR, e.message)
      })
  },
  reset({commit}) {
    commit(types.RESET)
  },
}

const types = {
  SET_PROJECT_LOCATIONS_LOADING: 'SET_PROJECT_LOCATIONS_LOADING',
  SET_PROJECT_LOCATIONS: 'SET_PROJECT_LOCATIONS',
  SET_PROJECT_LOCATIONS_ERROR: 'SET_PROJECT_LOCATIONS_ERROR',
  RESET_ERROR: 'RESET_ERROR',
  RESET: 'RESET',
}

const mutations = {
  [types.SET_PROJECT_LOCATIONS_LOADING](state) {
    state.isLoading = true;
  },
  [types.SET_PROJECT_LOCATIONS](state, locations) {
    state.locations = locations
    state.isLoading = false
  },
  [types.SET_PROJECT_LOCATIONS_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },
  [types.RESET_ERROR](state) {
    state.isLoading = false;
    state.error = false;
  },
  [types.RESET](state) {
    Object.assign(state, getInitialState());
  },
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
}
