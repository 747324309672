<template>
  <div>
    <h3>Observation Data</h3>
    <div class="observation-record-list" v-if="records.length > 0">
      <observation-record-item
        v-for="record in records"
        :record="record"
        v-bind:key="record['@id']"
      >
      </observation-record-item>
    </div>
  </div>
</template>

<script>
import ObservationRecordItem from "./ObservationRecordItem.vue";
export default {
  name: "ObservationRecordList",
  components: { ObservationRecordItem },
  props: {
    records: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>

<style>
</style>