<template>
  <footer class="footer">
    <app-sponsors v-if="displaySponsors" />
    <div class="footer-links">

    <div class="container">
      <div class="row">
        <div class="col-sm column">
          <h5 title="About our global citizen science support organization" alt="About our global citizen science support organization">
            <b-link :to="{ name: 'About' }" id="FooterTitleLink">About</b-link>
          </h5>
          <ul>
            <li title="Our impact" alt="Our impact">
              <b-link :to="{ name: 'Impact' }">Our Impact</b-link>
            </li>
            <li title="Our mission" alt="Our mission">
              <b-link :to="{ name: 'Mission' }">Mission</b-link>
            </li>
            <li title="Our team" alt="Our team">
              <b-link :to="{ name: 'Staff' }">Team</b-link>
            </li>
            <li title="Our citizen science partners" alt="Our partners">
              <b-link :to="{ name: 'Partners' }">Partners</b-link>
            </li>
            <li title="Participatory Science Publications" alt="Participatory Science Publications">
              <b-link :to="{ name: 'Publications' }">Publications</b-link>
            </li>
            <!--li title="How to cite CitSci" alt="How to cite CitSci">
              <b-link :to="{ name: 'Cite' }">How To Cite</b-link>
            </li>-->
          </ul>
        </div>
        <div class="col-sm column">
          <h5 title="Our citizen science solutions" alt="Our citizen science solutions">
            <b-link :to="{ name: 'Solutions' }" id="FooterTitleLink">Solutions</b-link>
          </h5>
          <ul>
            <li>
              <b-link :to="{ name: 'Create' }">Create</b-link>
            </li>
            <li>
              <b-link :to="{ name: 'Build' }">Build</b-link>
            </li>
            <li>
              <b-link :to="{ name: 'Measure' }">Measure</b-link>
            </li>
            <li>
              <b-link :to="{ name: 'Learn' }">Learn</b-link>
            </li>
            <li>
              <b-link :to="{ name: 'Act' }">Act</b-link>
            </li>
          </ul>
        </div>
        <div class="col-sm column">
          <h5 title="Our citizen science services" alt="Our citizen science services">
            <b-link :to="{ name: 'Services' }" id="FooterTitleLink">Services</b-link>
          </h5>
          <ul>
            <li>
              <b-link :to="{ name: 'Services' }">Platform</b-link>
            </li>
            <li>
              <b-link :to="{ name: 'Services' }">Portals</b-link>
            </li>
            <li>
              <b-link :to="{ name: 'Services' }">Integrations</b-link>
            </li>
            <li>
              <b-link :to="{ name: 'Services' }">Consulting</b-link>
            </li>
            <li>
              <b-link :to="{ name: 'Services' }">Apps</b-link>
            </li>
          </ul>
        </div>
        <div class="col-sm column">
          <h5 title="For Developers" alt="For Developers">
            <b-link :to="{ name: 'Developers' }" id="FooterTitleLink">Developers</b-link>
          </h5>
          <ul>
            <li>
              <b-link :to="{ name: 'Apis' }">APIs</b-link>
            </li>
            <li>
              <b-link :to="{ name: 'Widgets' }">Widgets</b-link>
            </li>
            <li>
              <b-link :to="{ name: 'Apps' }">Mobile Apps</b-link>
            </li>
            <li>
              <b-link :to="{ name: 'Architecture' }">Architecture</b-link>
            </li>
            <li>
              <b-link :to="{ name: 'Developers' }">PPSR_CORE</b-link>
            </li>
          </ul>
        </div>
        <div class="col-sm column">
          <h5 title="Connect" alt="Connect">
            <b-link :to="{ name: 'Connect' }" id="FooterTitleLink">Connect</b-link>
          </h5>
          <ul>
            <li>
              <a href="https://blog.citsci.org/">Blog</a>
            </li>
            <li>
              <b-link :to="{ name: 'Newsletter' }">Newsletter</b-link>
            </li>
            <li>
              <b-link :to="{ name: 'Webinars' }">Webinars</b-link>
            </li>
            <li>
              <b-link :to="{ name: 'Surveys' }">Surveys</b-link>
            </li>
            <li>
              <b-link href="https://docs.google.com/forms/d/e/1FAIpQLScJoBZtMM_zqSmkO3_cVuPXlFs1_bCtIpFe5pvSVyh5ZGr5ZA/viewform?vc=0&c=0&w=1&flr=0"
                target="NewWindow">Contact Us</b-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    </div>
    <div class="licence">
      <b-container id="footer-container">
      <div class="row">
        <div class="column col-sm-6 mx-auto">
          <div class="icon">
            <a style="float:left; margin-right: 12px; margin-bottom: 2px;" rel="license" href="https://creativecommons.org/licenses/by/3.0/deed.en_US">
              <img alt="Creative Commons License" style="border-width:0; margin-top:6px;" src="https://i.creativecommons.org/l/by/3.0/88x31.png">
            </a>
          </div>
          <div style="color: #22252C;">
            <span xmlns:dct="https://purl.org/dc/terms/" property="dct:title">CitSci.org</span> data are licensed under a <a id="CreativeCommonsLink" rel="license" href="https://creativecommons.org/licenses/by/3.0/deed.en_US">CC-BY 3.0 License</a>.
            Please see our <b-link :to="{ name: 'terms' }" id="CreativeCommonsLink">Terms</b-link>
            and <b-link :to="{ name: 'Privacy' }" id="CreativeCommonsLink">Privacy</b-link> for further details.
          </div>
        </div>
        <div class="column col-sm-6 mx-auto">
          <div class="CCIcons">
            <a href="https://www.colostate.edu/" target="_blank">
              <img class="footer-image" src="/assets/images/footer/CSU-Official-wrdmrk-Rev.png" alt="CSU" title="CSU" />
            </a>

            <a href="https://nrel.colostate.edu" target="_blank">
              <img class="footer-image" src="/assets/images/footer/SupportedBy_NREL.png" alt="NREL" title="NREL" />
            </a>

            <a href="https://www.nsf.gov/" target="_blank">
              <img class="footer-image" src="/assets/images/footer/SupportedBy_NSF.png" alt="NSF" title="NSF" />
            </a>

            <a href="https://www.facebook.com/pages/CitSciorg/172382202839106" target="_blank" alt="Visit our facebook page" title="Visit our facebook page">
              <img class="footer-image" src="/assets/images/footer/facebook_footer.png">
            </a>
            <a href="https://twitter.com/citsci/" target="_blank" alt="Tweet about CitSci.org" title="Tweet about CitSci.org">
              <img class="footer-image" src="/assets/images/footer/twitter_footer.png">
            </a>
            <span class="h-100 d-inline-block" style="vertical-align: bottom;">
            <a href="https://www.youtube.com/@citsci7428" class="text-white" target="_blank" alt="Visit our You Tube Channel" title="Visit our You Tube Channel">
              <font-awesome-icon :icon="['fab', 'youtube']" class="footer-image" style="font-size:24pt;"/>  
            </a>
            </span>
          </div>
        </div>
      </div>
      </b-container>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'BootstrapVueFooter',

  props: {
    displaySponsors: {
      type: Boolean,
      default: false
    }
  }
}

</script>
<style lang="scss" src="../../assets/styles/layout/BSFooter.scss"></style>
