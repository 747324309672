import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,
  state: {
    error: '',
    isLoading: false,
    stats: {
      usersTotal: 0,
      projectsTotal: 0,
      locationsTotal: 0,
      observationsTotal: 0,
      protocolsTotal: 0,
    }
  },
  actions,
  getters,
  mutations
}
