<template>
  <div class="row mt-2 mb-2 justify-content-start">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <multiselect
        v-model="selectedTag"
        :options="allTags"
        :taggable="false"
        :showLabels="true"
        placeholder="Filter Forum Posts By Tag..."
      >
        <template slot="clear" slot-scope="props">
          <div class="multiselect__clear" v-if="selectedTag" @mousedown.prevent.stop="selectedTag = null"></div>
        </template>
        <span slot="noResult">No Matching Tags Found...</span>
        <span slot="noOptions">No Tags Yet...</span>
      </multiselect>
      <b-button v-show="selectedTag" size="sm" :disabled="!selectedTag" @click="clearTag">Clear
      </b-button>
    </div>
  </div>
</template>

<script>

import {mapGetters} from 'vuex';

export default {
  name: "TagFilter",
  props: {
    setTag: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      selectedTag: null,
      isSetTag: false,
    };
  },
  watch: {
    setTag: {
      immediate: true,
      handler(newVal, oldVal) {
        this.setSelectedTag = newVal
      }
    },
    selectedTag(newVal, oldVal) {
      this.$emit('selectedTag', newVal)
    }
  },
  computed: {
    ...mapGetters({
      allTags: "project/forum/allTags"
    }),
    setSelectedTag: {
      get() {
        return this.selectedTag
      },
      set(newVal) {
        this.selectedTag = newVal
      }
    }
  },
  methods: {
    clearTag() {
      this.setSelectedTag = null;
    }
  },
}
</script>

<style scoped lang="scss">

</style>
