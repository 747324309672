<template>
  <!-- map component wrapper -->
  <div class="map-wrapper my-3">
    
    <!-- disable the entire component if needed -->
    <div v-if="disableContent" class="disable-map-content"></div>

    <div class="row">

      <!-- "Create new location" form and "Add existing location" dropdown -->
      <div :class="[locationType === 'previewPanelLocationPicklist' ? 'col-lg-12' : 'col-lg-6']">

        <div class="row">

          <!-- Left "Select location" dropdown -->
          <div
            v-if="locationType === 'previewPanelLocationBoth'"
            class="panel-preview-location-both col-lg-5"
            
          >
            <v-select
              class="add-existing-location-select d-block pt-1 pb-0 px-0"
              @change="changedValue"
              @click:clear="updateAddLocationsForm"
              item-text="name"
              :item-value="currentPage === 'datasheetView' ? 'locationId' : '@id'"
              v-model="selectedLocationVal"
              :items="locationsList"
              label="Select Location"
              color="#000000"
              single-line
              clearable
              outline
            >
              <template slot="prepend">
                <div class="preview-panel-location-picklist-label"><strong>Location</strong></div>
              </template>
            </v-select>
          </div>

          <!-- Separator -->
          <h5 class="px-3 mt-1 col-lg-1 text-center" v-if="locationType === 'previewPanelLocationBoth'">OR</h5>

          <!-- "Create new location" form -->

          <div
            v-if="createNewLocation"
            :class="[locationType === 'previewPanelLocationBoth' ? 'col-lg-6' : 'col-lg-12']"
          >
            <!-- Form title -->
            <h4
              v-if="createNewLocationTitle"
              :class="[
                'text-xs-center',
                locationType === 'previewPanelLocationBoth' ? 'subheading pt-1 mb-0 create-new-location-text-height' : ''
              ]"
            >
              Add New Location To Your List
            </h4>

            <!-- Form input forms -->
            <v-form
              :class="[
                'add-new-location-form',
                locationType === 'datasheetSettingsLocationsPopup' ? 'big-location-input-form' : 'small-location-input-form',
                locationType === 'previewPanelLocationBoth' ? 'p-0' : '',
                locationType === 'previewPanelLocationCoordinates' ? 'pl-0' : ''
              ]"
            >
              
              <!-- Location name field -->
              <v-text-field
                class="my-0 p-0 outlined-text-field"
                v-model.trim="locationVal.name"
                v-validate="currentPage === 'datasheetView' && locationType !== 'previewPanelLocationBoth' ? 'required' : ''"
                :error-messages="currentPage === 'datasheetView' && locationType !== 'previewPanelLocationBoth' ? errors.collect('Location name') : ''"
                data-vv-name="Location name"
                :required="currentPage === 'datasheetView' && locationType !== 'previewPanelLocationBoth' ? true : false"
                :placeholder="locationType === 'datasheetSettingsLocationsPopup' ? '' : 'Location name'"
                :disabled="disabledAddLocationForm"
                single-line
                outline
              >
                <template slot="prepend">
                  <span v-if="locationType === 'datasheetSettingsLocationsPopup'">Location Name</span>
                  <div v-else>
                    <span>Name</span>
                    <span v-if="locationType !== 'previewPanelLocationBoth'" class="custom-asterisk-added">&nbsp;&#42;</span>
                  </div>
                </template>
              </v-text-field>

              <!-- Location latitude field -->
              <v-text-field
                class="my-0 p-0 outlined-text-field"
                v-model.trim="locationVal.latitude"
                v-validate="currentPage === 'datasheetView' && locationType !== 'previewPanelLocationBoth' ? 'required' : ''"
                :error-messages="currentPage === 'datasheetView' && locationType !== 'previewPanelLocationBoth' ? errors.collect('Latitude') : ''"
                data-vv-name="Latitude"
                :required="currentPage === 'datasheetView' && locationType !== 'previewPanelLocationBoth' ? true : false"
                :placeholder="locationType === 'datasheetSettingsLocationsPopup' ? '' : 'Latitude'"
                @keyup.native="updateLocation(locationVal, 'new')"
                :disabled="disabledAddLocationForm"
                single-line
                outline
                type="number"
              >
                <template slot="prepend">
                  <span>Latitude</span>
                  <span v-if="locationType !== 'datasheetSettingsLocationsPopup' && locationType !== 'previewPanelLocationBoth'" class="custom-asterisk-added">&nbsp;&#42;</span>
                </template>
              </v-text-field>

              <!-- Location longitude field -->
              <v-text-field
                class="my-0 p-0 outlined-text-field"
                v-model.trim="locationVal.longitude"
                v-validate="currentPage === 'datasheetView' && locationType !== 'previewPanelLocationBoth' ? 'required' : ''"
                :error-messages="currentPage === 'datasheetView' && locationType !== 'previewPanelLocationBoth' ? errors.collect('Longitude') : ''"
                data-vv-name="Longitude"
                :required="currentPage === 'datasheetView' && locationType !== 'previewPanelLocationBoth' ? true : false"
                :placeholder="locationType === 'datasheetSettingsLocationsPopup' ? '' : 'Longitude'"
                @keyup.native="updateLocation(locationVal, 'new')"
                :disabled="disabledAddLocationForm"
                single-line
                outline
                type="number"
              >
                <template slot="prepend">
                  <span>Longitude</span>
                  <span v-if="locationType !== 'datasheetSettingsLocationsPopup' && locationType !== 'previewPanelLocationBoth'" class="custom-asterisk-added">&nbsp;&#42;</span>
                </template>
              </v-text-field>
              <!-- "Add Location" button -->
              <div xs5 offset-xs4 class="mt-2 pl-2" v-if="locationType === 'datasheetSettingsLocationsPopup'">
                <v-btn
                  class="add-location-button"
                  @click="onSendDataAndClearMap"
                  color="#87983f"
                  block
                  dark
                >
                  Add New Location
                </v-btn>
              </div>
            </v-form>

          </div>

          <!-- Separator -->
          <h4 class="px-3 mt-1" v-if="locationType === 'datasheetSettingsLocationsPopup'">OR</h4>

          <!-- Right "Select location" dropdown -->
          <div
            v-if="addExistingLocation"
            class="col-12"
          >
            <!-- Add existing location title -->
            <h4 v-if="locationType === 'datasheetSettingsLocationsPopup'" class="text-xs-center">Add Existing Project Locations</h4>

            <!-- Select form -->
            <v-select
              :class="[
                'add-existing-location-select',
                locationType === 'previewPanelLocationPicklist' ? 'p-0' : ''
              ]"
              v-model="selectedLocationVal"
              v-validate="currentPage === 'datasheetView' ? 'required' : ''"
              :items="locationsList"
              item-text="name"
              :item-value="currentPage === 'datasheetView' ? 'locationId' : '@id'"
              :error-messages="currentPage === 'datasheetView' ? errors.collect('selectLocationField') : ''"
              label="Select a location"
              data-vv-name="selectLocationField"
              :required="currentPage === 'datasheetView' ? true : false"
              @change="changedValue"
              @click:clear="updateAddLocationsForm"
              color="#000000"
              single-line
              clearable
              outline
            >
              <template
                v-if="locationType === 'previewPanelLocationPicklist'"
                slot="prepend"
              >
                <div class="preview-panel-location-picklist-label">
                  <span class="select-location-label"><strong>Location</strong></span>
                  <span class="custom-asterisk-added">&#42;</span>
                </div>
              </template>
            </v-select>

            <v-form v-if="locationType === 'datasheetSettingsLocationsPopup'" :class="['add-new-location-form','small-location-input-form']">
              <div v-if="this.selectedLocationLatVal">
                <v-text-field
                  class="my-0 p-0 outlined-text-field"
                  :disabled="true"
                  :placeholder="'--Not Selected--'"
                  v-model="this.selectedLocationLatVal"
                  single-line
                  outline
                >
                  <template slot="prepend">
                    <span>Latitude</span>
                  </template>
                </v-text-field>

                <v-text-field
                  class="my-0 p-0 outlined-text-field"
                  :disabled="true"
                  :placeholder="'--Not Selected--'"
                  v-model="this.selectedLocationLonVal"
                  single-line
                  outline
                >
                  <template slot="prepend">
                    <span>Longitude</span>
                  </template>
                </v-text-field>
              </div>

              <v-flex offset-xs2>
              <v-btn
                class="add-location-button"
                @click="addAllExistingLocations"
                color="#87983f"
                dark
              >
                Add All
              </v-btn>
              <v-btn
                class="add-location-button"
                @click="addSingleExistingLocation"
                color="#87983f"
                dark
              >
                Add Selected Location
              </v-btn>
              </v-flex>
            </v-form>
          </div>

        </div>
      </div>

      <!-- Map container -->
      <div
        class="map-container"
        :class="[locationType === 'previewPanelLocationPicklist' ? 'col-lg-12' : 'col-lg-6']"
      >
        <!-- "Search location" form -->
        <v-form @submit.prevent="showAddress">
          <v-layout
            v-if="searchLocationForm"
            row
            wrap
          >
            <!-- Text input form -->
            <v-flex
              xs8
              :class="locationType === 'datasheetSettingsLocationsPopup' ? 'mt-0' : 'mt-0'"
            >
              <v-text-field
                class="my-0 p-0 outlined-text-field search-location-field"
                v-model.trim="address"
                placeholder="Address, City, Landmark"
                single-line
                outline
                required
              />
            </v-flex>

            <!-- Button form -->
            <v-flex
              xs4
              :class="[
                'pl-2',
                locationType === 'datasheetSettingsLocationsPopup' ? 'mt-2' : 'mt-0'
              ]"
            >
              <v-btn
                class="search-location-button"
                @click="showAddress"
                color="#707070"
                block
                dark
              >Search</v-btn>
            </v-flex>
          </v-layout>
        </v-form>

        <!-- Map itself -->
        <v-layout row wrap>
          <v-flex
            xs12
            :class="locationType === 'datasheetSettingsLocationsPopup' ? 'mt-2' : 'mt-0'"
          >
            <!-- Map -->
            <GmapMap
              ref="mapRef"
              :center="center"
              :zoom="zoom"
              :options="mapOptions"
              :style="cssStyle"
              map-type-id="terrain"
              @click="clickOnMapEvent ? addMarkerOnMapClick($event) : null"
            >
              <!-- Marker -->
              <GmapMarker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                :clickable="true"
                :title="m.title"
                :icon="m.icon"
                @click="center=m.position"
              />
            </GmapMap>
          </v-flex>
        </v-layout>
      </div>

      <!-- show error messages -->
      <div xs10 offset-xs1>
        <v-alert
          :value="errorMessage"
          type="error"
          class="mx-3"
        >
          {{ errorMessage }}
        </v-alert>

        <v-alert
          :value="locationErrorMessage"
          type="error"
          class="mx-3"
        >
          {{ locationErrorMessage }}
        </v-alert>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "LocationSelect",

  inject: [ 'parentValidator' ],

  props: {
    addLocation: {
      type: Function,
      default: () => {}
    },

    latitude: {
      type: Number,
      default: function () {
        return 42.00
      }
    },

    longitude: {
      type: Number,
      default: function () {
        return -92.00
      }
    },

    zoomVal: {
      type: Number,
      default: function () {
        return 3
      }
    },

    markerItems: {
      type: Array,
      default: function () {
        return []
      }
    },

    item: {
      type: Object,
      default: function () {
        return {}
      }
    },

    isLoading: {
      type: Boolean,
      default: function () {
        return false
      }
    },

    cssStyle: {
      type: String,
      default: function () {
        return "width: 100%; height: 250px"
      }
    },

    showLocateAddress: {
      type: Boolean,
      default: function () {
        return true
      }
    },

    zoomMapToMarkers: {
      type: Number,
      default: function () {
        return 13
      }
    },

    customMarker: {
      type: Boolean,
      default: function () {
        return false
      }
    },

    showMapLegend: {
      type: Boolean,
      default: function () {
        return false
      }
    },

    searchByLocation: {
      type: String,
      default: function () {
        return ''
      }
    },

    mapOptions: {
      type: Object,
      default: () => {
        return {
          streetViewControl: false,
          scaleControl: true,
          zoomControl: true,
          mapTypeControl: true,
          draggable: true,
          scrollwheel: true,
          disableDoubleClickZoom: true
        }
      }
    },

    addMarkerOnClick: {
      type: Boolean,
      default: function () {
        return true
      }
    },

    locationType: {
      type: String,
      default: ""
    },

    disableContent: {
      type: Boolean,
      default: false
    },

    locationsList: {
      type: Array,
      default: () => []
    },

    errorMessage: {
      type: String,
      default: ""
    },

    selectedLocation: {
      type: String,
      default: ""
    },

    currentPage: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      address: '',
      markers: this.markerItems,
      zoom: this.zoomVal,
      center: {
        lat: this.latitude,
        lng: this.longitude
      },
      searchByLocationValue: this.searchByLocation,
      locationValue: this.item,
      locationErrorMessage: null,
      contentSize: "small",
      contentMarginTop: 1,
      createNewLocation: true,
      createNewLocationSize: "small",
      createNewLocationTitle: false,
      addExistingLocation: false,
      mapContainerSize: "small",
      searchLocationForm: false,
      clickOnMapEvent: false,
      disabledAddLocationForm: false,

      selectedLocationValue: this.selectedLocation,

      selectedLocationName: null,
      selectedLocationLat: null,
      selectedLocationLon: null,
    }
  },

  computed: {
    mapItems () {
      return this.locationVal
    },

    searchByLocationVal: {
      // getter
      get: function () {
        return this.searchByLocationValue
      },
      // setter
      set: function (newValue) {
        this.searchByLocationValue = newValue
      }
    },

    locationVal: {
      // getter
      get: function () {
        return this.locationValue
      },
      // setter
      set: function (newValue) {
        this.locationValue = newValue
      }
    },

    selectedLocationVal: {
      // getter
      get: function () {
        return this.selectedLocationValue
      },
      // setter
      set: function (newValue) {
        this.selectedLocationValue = newValue

        // handle data and give it back to parent by location
        this.$emit('location', this.selectedLocationVal)
      }
    },

    selectedLocationNameVal: {
      // getter
      get: function () {
        return this.selectedLocationName
      },
      // setter
      set: function (newValue) {
        this.selectedLocationName = newValue
      }
    },

    selectedLocationLatVal: {
      // getter
      get: function () {
        return this.selectedLocationLat
      },
      // setter
      set: function (newValue) {
        this.selectedLocationLat = newValue
      }
    },

    selectedLocationLonVal: {
      // getter
      get: function () {
        return this.selectedLocationLon
      },
      // setter
      set: function (newValue) {
        this.selectedLocationLon = newValue
      }
    }

  },

  watch: {
    mapItems () {
      this.updateLocation(this.locationVal, 'new')
    },

    locationType (location) {
      this.checkMapStatus(location)
    }
  },

  created () {
    if (this.parentValidator) {
      this.$validator = this.parentValidator
    }

    this.checkMapStatus(this.locationType)
  },

  methods: {

    onSendDataAndClearMap () {
      if (this.locationVal.name && this.locationVal.latitude && this.locationVal.longitude) {
        this.locationErrorMessage = null
        // send data to parent component
        this.addLocation(this.locationVal)
        // clear form and map
        this.clearCreateNewLocationForm()
        this.clearOverlays()
        this.resetMap()

        // // clear select location dropdown
        // this.selectedLocationVal = ""

        // enable the add new location form
        this.disabledAddLocationForm = false

        this.locationVal = {}
      } else {
        this.locationErrorMessage = "Location name, latitude and longitude are required."
      }
    },

    addSingleExistingLocation() {

      if (this.selectedLocationVal) {
        this.locationErrorMessage = null
        const locTemplate = {
          name: this.selectedLocationNameVal,
          latitude: this.selectedLocationLatVal,
          longitude: this.selectedLocationLonVal
        }
        this.addLocation(locTemplate)
        this.selectedLocationVal = null

      } else {
        this.locationErrorMessage = "Select an existing Location and try again."
      }
    },

    addAllExistingLocations () {

      // All existing locations are added
      this.locationsList.forEach((loc) => {
        const locTemplate = {
          name: loc.name,
          latitude: loc.latitude,
          longitude: loc.longitude
        }
        this.addLocation(locTemplate)
      })
    },

    clearCreateNewLocationForm () {
      this.address = ""
    },

    showAddress () {
      this.$refs.mapRef.$mapPromise.then((map) => {
        const geocoder = new google.maps.Geocoder()
        this.zoom = null

        geocoder.geocode({ address: this.address }, (results, status) => {
          // If geocode was successful - status OK
          if (status == google.maps.GeocoderStatus.OK) {
            this.locationErrorMessage = null
            this.clearOverlays()

            const marker = {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng()
            }

            this.center = marker
            this.zoom = 15
            this.markers.push({ position: marker })

            this.locationVal.name = this.address
            // capture lat/lng of clicked location
            this.locationVal.latitude = Math.round(results[0].geometry.location.lat() * 100000) / 100000
            this.locationVal.longitude = Math.round(results[0].geometry.location.lng() * 100000) / 100000

            // clear select location dropdown
            this.selectedLocationVal = ""

            // remove location fields error messages
            this.$validator.fields.items.forEach(input => {
              if (this.$validator.errors.has(input.name)) {
                const field = this.$validator.fields.find({name:input.name})

                if (field.name === "Location name" || field.name === "Latitude" || field.name === "Longitude") {
                  this.$validator.errors.remove(input.name)
                }
              }
            })

            // enable the add new location form
            this.disabledAddLocationForm = false
          } else {
            this.address = ""

            // enable the add new location form
            this.disabledAddLocationForm = false
          }
        })
      })
    },

    // Receive the value selected (return an array if is multiple)
    changedValue (value) {
      let locationData = {}

      if (this.currentPage === "datasheetView") {
        locationData = this.$_.find(this.locationsList, location => {
          return location.locationId === value
        })
      } else {
        locationData = this.$_.find(this.locationsList, location => {
          return location["@id"] === value
        })
      }

      // The existing location's lat and long are set below the dropdown and in the map
      if (locationData) {
        this.selectedLocationNameVal = locationData.name
        this.selectedLocationLatVal = locationData.latitude
        this.selectedLocationLonVal = locationData.longitude
        this.updateLocation(locationData, 'existing')
        this.clearCreateNewLocationForm()
      }

      // if (locationData) {
      //   this.locationVal.name = locationData.name
      //   this.locationVal.latitude = locationData.latitude
      //   this.locationVal.longitude = locationData.longitude
      //   this.disabledAddLocationForm = true
      // }
      //
      // this.updateLocation(locationData, 'existing')
      // // clear form and map
      // this.clearCreateNewLocationForm()
    },

    updateAddLocationsForm () {
      // clear form and map
      this.clearCreateNewLocationForm()
      this.clearOverlays()
      this.resetMap()

      // this.locationVal = {}
      this.selectedLocationVal = {}
      this.selectedLocationNameVal = null
      this.selectedLocationLatVal = null
      this.selectedLocationLonVal = null
      this.disabledAddLocationForm = false
    },

    checkMapStatus (location) {
      switch (location) {
        case "datasheetSettingsLocationsPopup":
          this.contentSize = "large"
          this.contentMarginTop = 2
          this.clickOnMapEvent = true
          this.mapContainerSize = "small"
          this.searchLocationForm = true
          this.addExistingLocation = true
          this.createNewLocation = true
          this.createNewLocationTitle = true
          this.createNewLocationSize = "small"
          break

        case "previewPanelLocationCoordinates":
          this.contentSize = "small"
          this.clickOnMapEvent = true
          this.mapContainerSize = "small"
          this.searchLocationForm = true
          this.addExistingLocation = false
          this.createNewLocation = true
          this.createNewLocationTitle = false
          this.createNewLocationSize = "large"
          break

        case "previewPanelLocationPicklist":
          this.contentSize = "large"
          this.contentMarginTop = 0
          this.clickOnMapEvent = false
          this.mapContainerSize = "large"
          this.searchLocationForm = false
          this.addExistingLocation = true
          this.createNewLocation = false
          this.createNewLocationTitle = false
          this.createNewLocationSize = "large"
          this.mapOptions.draggable = false
          this.mapOptions.scrollwheel = false
          this.mapOptions.disableDoubleClickZoom = false
          break

        case "previewPanelLocationBoth":
          this.contentSize = "large"
          this.contentMarginTop = 0
          this.clickOnMapEvent = true
          this.mapContainerSize = "large"
          this.searchLocationForm = true
          this.addExistingLocation = false
          this.createNewLocation = true
          this.createNewLocationTitle = true
          this.createNewLocationSize = "small"
          break
      }
    },

    clearOverlays: function() {
      if (this.markers) {
          for( let i = 0; i < this.markers.length; i++ ){
            this.markers[i] = null
          }

          this.markers = []
      }
    },

    addMarker (location, status) {
      this.clearOverlays()

      if (this.customMarker) {
        this.markers.push({
          position: location,
          title: location.name,
          icon: '/assets/images/unselected-project-marker.png'
        })
      } else {
        this.markers.push({
          position: location
        })
      }

      if (status === 'new') {
        // capture lat/lng of clicked location
        this.locationVal.latitude = Math.round(location.lat * 100000) / 100000
        this.locationVal.longitude = Math.round(location.lng * 100000) / 100000

        // clear select location dropdown
        this.selectedLocationVal = ""
      }
    },

    addMarkerOnMapClick: function (event) {
      if (!this.addMarkerOnClick) {
        return
      }

      const zoomMark = 5

      // capture lat/lng of click location
      let myLatLng = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }

      // check to see if visitor is zoomed out to a level not conducive to setting an accurate marker
      if (this.zoom < zoomMark) {
        // center map on click location
        this.center = myLatLng

        // zoom in
        this.zoom = zoomMark
      } else {
        this.addMarker(myLatLng, 'new')

        // clear search location input
        this.address = ""
      }
    },

    updateLocation (location, status) {
      if (location != null && (!isNaN(location.latitude)) && (!isNaN(location.longitude))) {
        const latVal = /^-?([0-8]?[0-9]|90)(\.)?[0-9]{1,6}$/
        const lonVal = /^-?((1?[0-7]?|[0-9]?)[0-9]|180)(\.)?[0-9]{1,6}$/

        if (latVal.test(location.latitude) && lonVal.test(location.longitude)) {
          this.zoomMap(location.latitude, location.longitude, status)
        }
      }
    },

    zoomMap (lat, lng, status) {
      // create map latlng object
      const myLatLng = {
        lat: parseFloat(lat),
        lng: parseFloat(lng)
      }

      // center map on latlng object location
      this.center = myLatLng

      // zoom in
      this.zoom = this.zoomMapToMarkers

      // set marker
      this.addMarker(myLatLng, status)
    },

    resetMap() {
      const myLatLng = {
        lat: this.latitude,
        lng: this.longitude
      }

      this.$refs.mapRef.$mapPromise.then((map) => {
        map.setZoom(this.zoomVal)
        map.setCenter(myLatLng)
      })
    }
  }
}
</script>

<style lang="scss" scoped>

#custom-vuetify-style {
  .custom-asterisk-added {
    color: #ff5252;
  }

  .add-new-location-form,
  .add-existing-location-select {
    padding: 5px 30px 0px;
  }
  .add-existing-location-select {
    margin-bottom: -15px;
  }

  .add-existing-location-select /deep/ {
    .v-input__prepend-outer .preview-panel-location-picklist-label {
      min-width: 180px;

      .select-location-label {
        color: rgba(0,0,0,.87);
      }
    }

    .v-input__slot {
      border-width: thin;
      height: 34px;
      min-height: 24px;
      padding: 0 6px;
      margin-top: 5px;
      background-color: white !important;

      &:hover {
        border-width: thin !important;
      }

      .v-label {
        top: 7px;
        animation: none;
      }

      .v-input__append-inner {
        margin-top: 5px;
      }
    }

    .v-text-field__details {
      margin-bottom: 4px;
    }
  }

  .add-existing-location-select.error--text {
    margin-bottom: 10px;
  }

  .search-location-field /deep/,
  .add-new-location-form /deep/ {
    .v-input__slot {
      background: transparent !important;
    }
  }

  .search-location-button,
  .add-location-button {
    height: 34px;
    margin: 5px 0;
  }

  .add-location-button {
    max-width: 250px;
    margin: 0 auto;
  }

  .add-new-location-form {
    /deep/ .v-input__prepend-outer {
      margin-top: 15px;
      margin-right: 6px;
      font-size: 15px;
      color: #929292 !important;
    }

    &.big-location-input-form {
      /deep/ .v-input__prepend-outer {
        min-width: 120px;
      }
    }

    &.small-location-input-form {
      /deep/ .v-input__prepend-outer {
        min-width: 85px;
      }
    }
  }

  .preview-panel-location-form {
    margin-top: 12px;
  }

  .map-container {
    padding: 0 16px;
  }

  .panel-preview-location-both /deep/ .v-input__prepend-outer {
    margin: 0;
  }

  .create-new-location-text-height {
    line-height: 1.5;
  }

  .map-wrapper {
    position: relative;

    .disable-map-content {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
  }
}
</style>
