<template>
  <div class="row text-center text-light no-gutters">
    <div class="col-6 col-sm-3">
      <div v-if="projectsTotal" class="stat-box py-2 mt-2 mx-1 stat-box-bg">
        <div class="stat-value">
          {{ projectsTotal }}
        </div>
        <div class="stat-label">
          <i class="mr-2 fa fa-project-diagram"></i>
          <span class="" v-if="projectsTotal > 1">
            projects
          </span>
          <span class="" v-else>
            project
          </span>
        </div>
      </div>
    </div>
    <div class="col-6 col-sm-3">
      <div v-if="usersTotal" class="stat-box py-2 mt-2 mx-1 stat-box-bg">
        <div class="stat-value">
          {{ usersTotal }}
        </div>
        <div class="stat-label">
          <i class="mr-2 fa fa-user"></i>
          <span class="" v-if="usersTotal > 1">
            observers
          </span>
          <span class="" v-else>
            observer
          </span>
        </div>
      </div>
    </div>
    <div class="col-6 col-sm-3">
      <div v-if="observationsTotal" class="stat-box py-2 mt-2 mx-1 stat-box-bg">
        <div class="stat-value">
          {{ observationsTotal }}
        </div>
        <div class="stat-label">
          <i class="mr-2 fa fa-binoculars"></i>
          <span class="" v-if="observationsTotal > 1">
            observations
          </span>
          <span class="" v-else>
            observation
          </span>
        </div>
      </div>
    </div>
    <!--    <div class="col-6 col-sm-3">-->
    <!--        <div v-if="locationsTotal" class="stat-box bg-dark py-2 mt-2 mx-1">-->
    <!--            <div class="stat-value">-->
    <!--                {{locationsTotal}}-->
    <!--            </div>-->
    <!--            <div class="stat-label">-->
    <!--                <i class="mr-2 fa fa-map-marker-alt"></i>-->
    <!--                <span class="">-->
    <!--                    Locations-->
    <!--                </span>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--    </div>-->
    <div class="col-6 col-sm-3">
      <div v-if="measurementsTotal" class="stat-box py-2 mt-2 mx-1 stat-box-bg">
        <div class="stat-value">
          {{ measurementsTotal }}
        </div>
        <div class="stat-label">
          <i class="mr-2 fa fa-pencil"></i>
          <span class="" v-if="measurementsTotal > 1">
            measurements
          </span>
          <span class="" v-else>
            measurement
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  created() {
    if (!this.organizationVuex || (this.organizationVuex.url !== this.$route.params.name)) {
      this.getOrganizationStats()
    }
  },
  computed: {
    ...mapGetters({
      projectsTotal: 'organization/stats/projectsTotal',
      locationsTotal: 'organization/stats/locationsTotal',
      measurementsTotal: 'organization/stats/measurementsTotal',
      observationsTotal: 'organization/stats/observationsTotal',
      usersTotal: 'organization/stats/usersTotal',
      organizationVuex: "organization/get/organization",
    }),
  },
  methods: {
    ...mapActions({
      getOrganizationStats: 'organization/stats/get',
    })
  }
}
</script>

<style lang="">

.stat-box-bg {
  background: rgba(34, 37, 44, 0.75);
}

.stat-value {
  letter-spacing: -2px;
  height: 42px;
  line-height: 48px;
  font-size: 30px;
}

.stat-label {
  letter-spacing: -0.5px;
  color: #b0bb81;
  margin-top: -7px;
  font-size: 16px;
}
</style>
