<template>
  <div>
    <label class="ml-2"><u><b>Transfer History: :</b></u></label><br>
    <b-card class="col-12" border-variant="light" bg-variant="light">
      <b-container fluid>
        <b-table
          show-empty
          stacked="md"
          id="zooniverseHistoryTable"
          ref="zooniverseHistoryTable"
          :items="zooniverseHistory"
          :fields="fieldList"
          :current-page="currentPage"
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :empty-text="emptyText"
          :sort-icon-left="true">

          <template #table-busy>
            <small>Fetching Transfer History...</small>
            <i class="fa fa fa-spin fa-spinner ml-2"></i>
          </template>

        </b-table>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
  props: {
    // Datasheet ID
    datasheetId: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      history: [],
      isBusy: false,
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      sortBy: "createdAt",
      sortDesc: true,
      sortDirection: "desc",
      emptyText: "No Transfers Yet for Selected Datasheet!",

      fieldList: [
        {
          key: "createdAt",
          label: "Date Transferred",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center",
          formatter: val => {
            return this.displayDate(val);
          }
        },
        {
          key: "datasheetName",
          label: "Datasheet Name",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center"
        },
        {
          key: "imageCount",
          label: "# Images Transferred",
          sortable: true,
          tdClass: "text-center",
          thClass: "text-center"
        }
      ],
    }
  },
  computed: {
    ...mapGetters({
      historyForDatasheet: "project/get/zooniverse/historyForDatasheet",
    }),
    zooniverseHistory() {
      return this.historyForDatasheet(this.datasheetId)
    }
  },
};
</script>

<style lang="scss">

</style>
