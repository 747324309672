import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,
  state: {
    error: '',
    isLoading: false,
    retrieved: {
      location: {
        latitude: 0,
        longitude: 0
      }
    },
    observationData: null,
    observationViolations: null,
    deletedSuccess: null
  },
  actions,
  getters,
  mutations
}
