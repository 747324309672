<template>
  <div class="page-wrap d-flex flex-row align-items-center px-4">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="text-center">
          <span class="display-1 d-block">404 - Not Found</span>
          <div class="mb-4 lead">
            The page you are looking for was not found.
          </div>
        </div>
        <p>
          Welcome to the new CitSci. We recently completed a major upgrade. Any
          bookmarks you may have had to the old site have changed.
        </p>
        <div class="row">
          <div class="col-md-4 col-sm-4 pb-3">
            <router-link to="/" class="btn btn-block btn-tertiary"
              >Home</router-link
            >
          </div>
          <div class="col-md-4 col-sm-4 pb-3">
            <router-link to="/projects" class="btn btn-block btn-tertiary"
              >All Projects</router-link
            >
          </div>
          <div class="col-md-4 col-sm-4 pb-3">
            <router-link
              v-if="isAuthenticated"
              to="/users/profile"
              class="btn btn-block btn-tertiary"
              >My Profile</router-link
            >
            <router-link
              v-if="!isAuthenticated"
              to="/login"
              class="btn btn-block btn-tertiary"
              >Login</router-link
            >
          </div>
        </div>
        <p>
          For information on all our changes, please read our
          <a
            href="https://blog.citsci.org/2021/06/05/site-upgrades-and-maintenance-june-9-13-2021/"
            >blog post!</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "NotFound404",
  computed: {
    ...mapGetters({
      isAuthenticated: "user/auth/isAuthenticated",
    }),
  },
};
</script>

<style>
</style>
<style lang="scss" scoped>
.page-wrap {
  min-height: calc(100vh - 389px);
}
</style>
