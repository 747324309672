import * as types from './mutation_types'

export default {
  [types.CONTACT_RESET] (state) {
    Object.assign(state, {
      error: '',
      isLoading: false
    })
  },

  [types.CONTACT_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.CONTACT_SET_ERROR] (state, stats) {
    Object.assign(state, { stats: stats })
  },
}
