<template>
  <div v-if="project" class="mb-0">
    <div class="" v-if="isPublicOrMember">
      <div class="row mb-1">
        <h3 class="mb-0 ml-3">Map of Observations</h3>
        <div v-if="isLoadingLocations" class="ml-5">
          <span>Loading Observation Locations...</span>
          <i class="fa fa-spin fa-spinner ml-3"></i>
        </div>
      </div>

      <ProjectMapLeaflet
        :longitude="project.longitude"
        :latitude="project.latitude"
        :locations="allLocations">
      </ProjectMapLeaflet>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapState,} from 'vuex';
import RequiresMember from "../tabpermission/requiresMember.vue";
import ProjectMapLeaflet from "../../ProjectMapLeaflet.vue";

export default {
  components: {
    RequiresMember,
    ProjectMapLeaflet,
  },
  created() {
  },
  watch: {},
  computed: {
    ...mapGetters({
      project: "project/get/project",
      isPublicOrMember: "project/get/isPublicOrMember",
    }),
    ...mapState({
        isLoadingLocations: state => state.project.get.locations.isLoading,
        allLocations: state => state.project.get.locations.allLocations,
      }
    ),
  },
  methods: {
    ...mapActions({})
  }
};
</script>

<style lang="scss" scoped>
</style>
