import * as types from './mutation_types'

export default {
  [types.ACTIVATE_ACCOUNT_RESET] (state) {
    Object.assign(state, {
      error: '',
      isLoading: false,
      violations: null,
      activated: false,
      expiredToken: false,
      invalidToken: false,
      emailSent: false,
    })
  },

  [types.ACTIVATE_ACCOUNT_SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.ACTIVATE_ACCOUNT_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.ACTIVATE_ACCOUNT_SET_ACTIVATED] (state, activated) {
    Object.assign(state, { activated })
  },

  [types.ACTIVATE_ACCOUNT_SET_EXPIRED_TOKEN] (state, expiredToken) {
    Object.assign(state, { expiredToken })
  },

  [types.ACTIVATE_ACCOUNT_SET_INVALID_TOKEN] (state, invalidToken) {
    Object.assign(state, { invalidToken })
  },

  [types.ACTIVATE_ACCOUNT_SET_EMAIL] (state, emailSent) {
    Object.assign(state, { emailSent })
  },
}
