export const USER_AUTH_SET_BEARER = 'USER_AUTH_SET_BEARER'
export const USER_AUTH_SET_REFRESH = 'USER_AUTH_SET_REFRESH'

export const USER_AUTH_LOGOUT = 'USER_AUTH_LOGOUT'

export const USER_AUTH_LOADING = 'USER_AUTH_LOADING'
export const USER_AUTH_LOADED = 'USER_AUTH_LOADED'

export const USER_AUTH_SET_ERROR = 'USER_AUTH_SET_ERROR'
export const USER_AUTH_RESET_PASSWORD_ERROR = 'USER_AUTH_RESET_PASSWORD_ERROR'
export const USER_AUTH_SET_VIOLATIONS = 'USER_AUTH_SET_VIOLATIONS'

