<template>
  <div>
    <div v-if="isPublicOrMember">
      <div v-if="projectId && !projectLoading" class="">
        <div class="card-body">
          <div class="row mb-3">
            <h3 class="mb-0 col-md-6">Observations</h3>
            <div class="input-group col-md-6">
              <b-input-group-prepend>
                <span
                  class="btn btn-secondary"
                  title="Search by location"
                >
                  <i v-if="!isBusy" class="fas fa-search"></i>
                  <i v-if="isBusy" class="fas fa-spinner fa-spin"></i>
                </span>
              </b-input-group-prepend>
              <b-form-input
                placeholder="Search by Location Name"
                name="search"
                type="search"
                v-model="searchText"
                debounce="1000"
              ></b-form-input>
              <div class="input-group-append" v-if="searchText">
                <b-button @click="searchText = ''"
                ><i class="fas fa-times-circle"></i
                ></b-button>
              </div>
            </div>
          </div>
          <div class="row justify-content-between">
            <div class="my-1 col-md-4">
              Showing {{ fromItem }} to {{ toItem }} of
              {{ totalItems }}
              {{ isManager ? 'observation' :
              isMember ? 'public and your private observation' :
                'public observation'}}<span v-if="totalItems === 0 || totalItems > 1">s</span>
            </div>

            <div class="col-md-3 my-1">
              <b-pagination
                v-if="totalItems > 0"
                :total-rows="totalItems"
                :per-page="perPage"
                v-model="currentPage"
                class="my-0 pagination-holder"
                prev-text="Prev"
                next-text="Next"
                align="fill"
              />
            </div>

            <div class="col-md-3 my-1">
              <b-form-group
                label="Per Page"
                label-align="right"
                label-cols-sm="7"
                label-cols-md="8"
                label-for="perPageSelect"
                class="mb-0"
              >
                <b-form-select
                  v-model="perPage"
                  id="perPageSelect"
                  size="sm"
                  :options="pageOptions"
                  align="right"
                >
                </b-form-select>
              </b-form-group>
            </div>
          </div>
        </div>

        <b-table
          show-empty
          stacked="md"
          id="observationsTable"
          ref="observationsTable"
          :filter="filterVal"
          :busy="isBusy"
          :items="observations"
          :fields="tableFields"
          :current-page="currentPage"
          :per-page="perPage"
          sort-icon-left
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
        >
          <template v-slot:cell(location.name)="row">
            <router-link
              :to="{ name: 'LocationShow', params: { id: row.item.location.id } }"
            >
              {{ row.item.location.name }}
            </router-link>
          </template>
          <template v-slot:cell(records.fileObject.path)="row">
            <b-row class="mx-auto fit-content">
              <b-col
                class="mx-0 px-0 overflow-hidden"
                style="max-height: 68px; max-width: 100px;"
              >
                <b-img
                  class="mx-0 h-100"
                  rounded="0"
                  :src="
                    row.item.records.fileObject
                      ? row.item.records.fileObject.path
                      : '/assets/images/no-project-image.jpg'
                  "
                  alt="Observation Image"
                />
              </b-col>
            </b-row>
          </template>
          <template v-slot:cell(options)="row">
            <div>
              <b-btn
                class="mt-1 mr-1"
                :to="{ name: 'ObservationShow', params: { id: row.item.id } }"
                alt="View Observation"
                title="View Observation"
              >
                <font-awesome-icon icon="eye"/>
                View
              </b-btn>
              <b-btn
                v-if="isManager"
                class="mt-1"
                @click="onClickDelete(row.item)"
                alt="Delete this Observation from the Project"
                title="Delete this Observation from the Project"
              >
                <i class="fa fa-trash"></i> Delete
              </b-btn>
            </div>
          </template>
          <template #table-busy>
            <VueLoader style="height: 200px" text="Loading Observations..."/>
          </template>
        </b-table>
        <div class="card-footer">
          <div class="row justify-content-between">
            <div class="my-1 col-md-4">
              Showing {{ fromItem }} to {{ toItem }} of
              {{ totalItems }}
              {{ isManager ? 'observation' :
              isMember ? 'public and your private observation' :
                'public observation'}}<span v-if="totalItems === 0 || totalItems > 1">s</span>
            </div>

            <div class="col-md-3 my-1">
              <b-pagination
                v-if="totalItems > 0"
                :total-rows="totalItems"
                :per-page="perPage"
                v-model="currentPage"
                class="my-0 pagination-holder"
                prev-text="Prev"
                next-text="Next"
                align="fill"
              />
            </div>

            <div class="col-md-3 my-1">
              <b-form-group
                label="Per Page"
                label-align="right"
                label-cols-sm="7"
                label-cols-md="8"
                label-for="perPageSelect"
                class="mb-0"
              >
                <b-form-select
                  v-model="perPage"
                  id="perPageSelect"
                  size="sm"
                  :options="pageOptions"
                  align="right"
                >
                </b-form-select>
              </b-form-group>
            </div>
          </div>
        </div>

        <b-modal id="deleteObservationModal" title="Delete Observation" centered>
          <div v-if="selectedObservation">
            <p class="my-4">
              Do you want to remove this observation from the project? This cannot
              be undone.
            </p>
            <div>
              <span class="font-weight-bold">Observed at: </span>
              {{ displayDate(selectedObservation.observedAt) }}
            </div>
            <div>
              <span class="font-weight-bold">Located at: </span>
              {{ selectedObservation.location.name }}
            </div>
          </div>
          <template slot="modal-footer">
            <div>
              <b-btn variant="danger" @click="onConfirmDelete()">Yes, delete</b-btn>
              <b-btn @click="closeModal">No</b-btn>
            </div>
          </template>
        </b-modal>
      </div>
    </div>
    <RequiresMember v-else page="Observations"></RequiresMember>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import VueLoader from "../../../layout/Loader";
import RequiresMember from "../tabpermission/requiresMember.vue";

export default {
  name: "ObservationsTable",
  components: {
    RequiresMember,
    VueLoader
  },
  data() {
    return {
      pageOptions: [10, 25, 50, 100, 250, 500],
      isBusy: false,
      showModal: null,
      selectedObservation: null,

      tableFields: [
        {
          key: "observedAt",
          label: "Date Observed",
          sortable: true,
          sortDirection: "asc",
          tdClass: "text-left",
          formatter: val => {
            return this.displayDate(val);
          }
        },
        {
          key: "createdAt",
          label: "Date Created",
          sortable: true,
          sortDirection: "asc",
          tdClass: "text-left",
          formatter: val => {
            return this.displayDate(val);
          }
        },
        {
          key: "datasheet.name",
          label: "Datasheet",
          sortable: true,
          tdClass: "text-dark",
          formatter: val => {
            return val;
          }
        },
        {
          key: "location.name",
          label: "Location name",
          sortable: true,
          sortDirection: "asc",
          tdClass: "text-left",
          formatter: (val, fieldName, item) => {
            return item && item.location && item.location.name;
          }
        },
        {
          key: "observers.user.displayName",
          label: "Observed By",
          sortable: true,
          sortDirection: "asc",
          tdClass: "text-left",
          formatter: (val, fieldName, item) => {
            return (
              item &&
              item.observers &&
              item.observers[0] &&
              item.observers[0].user &&
              item.observers[0].user.displayName
            );
          }
        },
        // { key: 'records.fileObject.path', label: 'Photo', sortable: false },
        {
          key: "options",
          label: "Options",
          sortable: false,
          class: "text-right"
        }
      ]
    };
  },

  computed: {
    ...mapGetters({
      projectId: "project/get/id",
      projectLoading: "project/get/isLoading",
      isPublicOrMember: "project/get/isPublicOrMember",
      isManager: "project/get/isManager",
      isMember: "project/get/isMember",

      observationPage: "project/get/observationPage",
      observationItems: "project/get/observationItems",
      observationTotal: "project/get/observationTotal",
      observationSearch: "project/get/observationSearch",
      observationSortBy: "project/get/observationSortBy",
      observationSortDirection: "project/get/observationSortDirection",
      observationSortDesc: "project/get/observationSortDesc",
    }),
    currentPage: {
      get() {
        return this.observationPage
      },
      set(newValue) {
        this.setObservationTablePage(newValue)
      }
    },
    totalItems: {
      get() {
        return this.observationTotal
      },
      set(newValue) {
        this.setObservationTableTotal(newValue)
      }
    },
    perPage: {
      get() {
        return this.observationItems
      },
      set(newValue) {
        this.setObservationTableItems(newValue)
      }
    },
    searchText: {
      get() {
        return this.observationSearch
      },
      set(newValue) {
        this.setObservationTableSearch(newValue)
      }
    },
    sortBy:  {
      get() {
        return this.observationSortBy
      },
      set(newValue) {
        this.setObservationSortBy(newValue)
      }
    },
    sortDirection:  {
      get() {
        return this.observationSortDirection
      },
      set(newValue) {
        this.setObservationSortDirection(newValue)
      }
    },
    sortDesc: {
      get() {
        return this.observationSortDesc
      },
      set(newValue) {
        this.setObservationSortDesc(newValue)
      }
    },

    fromItem() {
      return this.totalItems > 0
        ? (this.currentPage - 1) * this.perPage + 1
        : 0;
    },
    toItem() {
      return this.totalItems < this.currentPage * this.perPage
        ? this.totalItems
        : this.currentPage * this.perPage;
    },

    filterVal: {
      get: function () {
        // This is our multi-filter computed value
        // - search observations by location name
        //More filters can be added here
        // - also add an equivalent to the store

        let retVal = ''
        if (this.searchText) {
          retVal = retVal.length === 0 ? 'location.name=' + this.searchText : retVal + '&location.name=' + this.searchText
        }
        return retVal
      }
    },
  },
  methods: {
    ...mapActions({
      fetchObservations: "observation/list/getObservationsList",
      deleteObservation: "observation/show/deleteObservation",

      setObservationTablePage: "project/get/setObservationTablePage",
      setObservationTableItems: "project/get/setObservationTableItems",
      setObservationTableTotal: "project/get/setObservationTableTotal",
      setObservationTableSearch: "project/get/setObservationTableSearch",
      setObservationSortBy: "project/get/setObservationTableSortBy",
      setObservationSortDirection: "project/get/setObservationTableSortDirection",
      setObservationSortDesc: "project/get/setObservationTableSortDesc",
    }),
    observations(ctx) {
      this.isBusy = true;
      let urlParams = `${this.urlBuilder(ctx)}`;
      const data = {id: this.projectId, urlParams};
      return this.fetchObservations(data).then(data => {
        this.totalItems = data["hydra:totalItems"];
        this.currentPage = ctx.currentPage || 1;
        this.sortBy = ctx.sortBy || "";
        this.isBusy = false;
        return data["hydra:member"].map(observation => {
          return {
            ...observation
          };
        });
      });
    },
    closeModal() {
      this.$bvModal.hide("deleteObservationModal");
      this.selectedObservation = null;
    },
    onClickDelete(observation) {
      this.selectedObservation = {...observation};
      this.$bvModal.show("deleteObservationModal");
    },
    onConfirmDelete() {
      this.deleteObservation({
        id: this.selectedObservation.id
      }).then(() => {
        this.$refs.observationsTable.refresh();
        // Call parent to update stats
        this.$emit("update-stats", true);
      });
      this.closeModal();
    }
  }
};
</script>

<style></style>
