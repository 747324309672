<template>
  <div>
    <div v-if="isPublicOrMember">
      <div class="card-body">
        <div class="row">
          <h3 class="col-6 col-md-3 mb-2">
            Datasheets
          </h3>

          <div v-if="isManager"
               class="col-6 col-md-3 text-right mb-2 order-md-3">
            <button
              class="btn btn-tertiary"
              @click="onCreateDatasheet"
              title="Create Datasheet"
            >
              <i class="fa fa-plus text-white mr-2"></i
              ><span class="text-white">Create Datasheet</span>
            </button>
          </div>

          <div class="col-md-6 mb-2 order-md-2">
            <div class="input-group">
              <b-input-group-prepend>
              <span
                class="btn btn-secondary"
                title="Search by location"
              >
                <i v-if="!isBusy" class="fas fa-search"></i>
                <i v-if="isBusy" class="fas fa-spinner fa-spin"></i>
              </span>
              </b-input-group-prepend>
              <b-form-input
                class="form-control"
                placeholder="Search by datasheet name"
                name="search"
                type="search"
                v-model="filter"
                debounce="300"
              ></b-form-input>
              <div class="input-group-append" v-if="filter">
                <b-button @click="filter = null"
                ><i class="fas fa-times-circle"></i
                ></b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-table
        :items="datasheets"
        :busy="isBusy"
        :fields="fields"
        :filter="filter"
        :filter-included-fields="filterFields"
        sort-icon-left
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
      >
        <template v-slot:cell(name)="row">
        <span v-if="row.item.name" class="font-weight-bold">
          {{ row.item.name }}
        </span>
          <span v-else class="text-danger">
          Unnamed Datasheet
        </span>
        </template>
        <template v-slot:cell(status)="row">
        <span v-if="row.item.status === 'draft'" class="text-danger">
          {{ row.item.status }}
        </span>
          <span v-else>
          {{ row.item.status }}
        </span>
        </template>
        <template v-slot:cell(options)="row">
          <div class="text-right">
            <b-btn v-if="isMember && row.item.published"
                   class="my-1 mr-1 text-white"
                   :to="{ name: 'DatasheetView', params: { id: row.item.id } }"
                   alt="Make Observation"
                   title="Make Observation">
              <i class="fa fa-plus text-white"></i>
              Add Data
            </b-btn>
            <b-btn
              class="my-1 mr-1 text-white"
              :to="{ name: 'DatasheetCreator', params: { id: row.item.id } }"
              alt="Edit Datasheet"
              title="Edit Datasheet"
              v-if="isManager">
              <font-awesome-icon icon="pen"/>
              Edit
            </b-btn>
          </div>
        </template>
        <template #table-busy>
          <VueLoader style="height: 200px" text="Loading Datasheets..."/>
        </template>
      </b-table>
    </div>
    <RequiresMember v-else page="Datasheets"></RequiresMember>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import VueLoader from "../../../layout/Loader";
import RequiresMember from "../tabpermission/requiresMember.vue";

export default {
  components: {
    RequiresMember,
    VueLoader
  },
  data() {
    return {
      filter: null,
      filterFields: ["name"],
      sortBy: "name",
      sortDesc: true,
      sortDirection: "asc",
      fields: [
        {
          key: "name",
          label: "Name",
          sortable: true
        },
        {
          key: "status",
          label: "Status",
          sortable: true
        },
        {
          key: "instructions",
          label: "Instructions",
          sortable: true
        },
        {
          key: "updatedAt",
          label: "Date Last Modified",
          sortable: true,
          formatter: val => {
            return this.displayDate(val);
          }
        },
        {
          key: "createdAt",
          label: "Date Created",
          sortable: true,
          formatter: val => {
            return this.displayDate(val);
          }
        },

        {
          key: "options",
          label: "Options",
          sortable: false,
          class: "text-right",
          thStyle: {
            minWidth: "200px!important"
          }
        }
      ]
    };
  },
  watch: {
    datasheetCreated(datasheet) {
      if (!datasheet) {
        return;
      }
      this.$router.push({
        name: "DatasheetCreator",
        params: {id: datasheet.id}
      });
    }
  },
  computed: {
    ...mapGetters({
      projectId: "project/get/id",
      project: "project/get/project",
      isLoading: "project/get/isLoading",
      isPublicOrMember: "project/get/isPublicOrMember",
      isManager: "project/get/isManager",
      isMember: "project/get/isMember",
      isPending: "project/get/isPending",
      membershipMap: "user/profile/membershipMap",
      publishedDatasheets: "project/get/publishedDatasheets",
      datasheetCreated: "project/show/datasheetCreated"
    }),
    datasheets() {
      if (this.project && this.project.datasheets) {
        if (
          this.membershipMap[this.projectId] &&
          this.membershipMap[this.projectId].isManager
        ) {
          return this.project.datasheets;
        } else {
          return this.publishedDatasheets;
        }
      } else {
        return [];
      }
    },
    isBusy() {
      return this.isLoading;
    },
    newDatasheet() {
      return {
        name: "",
        instructions: "",
        project: "projects/" + this.projectId,
        projection: "geographic",
        dateFormat: "date_hour_minutes",
        allowCustomLocation: true,
        published: false
      };
    }
  },
  methods: {
    ...mapActions({
      createDatasheet: "project/show/createDatasheet"
    }),
    onCreateDatasheet() {
      this.createDatasheet(this.newDatasheet);
    }
  }
};
</script>

<style lang=""></style>
