import authorize from './authorize';
import bases from "./bases";
import data from "./data";
export default {
  namespaced: true,
  modules: {
    authorize,
    bases,
    data
  }
}
