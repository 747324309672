import * as types from './mutation_types'

export default {
  [types.RESET_PASSWORD_RESET] (state) {
    Object.assign(state, {
      error: '',
      isLoading: false,
      violations: null,
      updated: false,
      expiredToken: false,
      invalidToken: false,
    })
  },

  [types.RESET_PASSWORD_SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.RESET_PASSWORD_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.RESET_PASSWORD_SET_VIOLATIONS] (state, violations) {
    Object.assign(state, { violations })
  },

  [types.RESET_PASSWORD_SET_UPDATED] (state, updated) {
    Object.assign(state, { updated })
  },

  [types.RESET_PASSWORD_SET_EXPIRED_TOKEN] (state, expiredToken) {
    Object.assign(state, { expiredToken })
  },

  [types.RESET_PASSWORD_SET_INVALID_TOKEN] (state, invalidToken) {
    Object.assign(state, { invalidToken })
  },
}
