<template>
  <div class="m-0 p-0 floating-button-wrapper">
    <b-button class="btn btn-secondary floating-button"
              :title='buttonType && buttonType[0].toUpperCase() + buttonType.slice(1) + " Indicator"'
              :alt='buttonType && buttonType[0].toUpperCase() + buttonType.slice(1) + " Indicator"' @click="onClick">
      <i v-if="buttonType === 'edit'" class="fa fa-pencil text-white"></i>
      <i v-if="buttonType === 'delete'" class="fa fa-trash text-white"></i>
    </b-button>
  </div>
</template>
<script>
export default {
  props: {
    indicatorId: {
      type: Number,
      required: true
    },
    buttonType: {
      type: String,
      default: () => {
        return 'Edit'
      },
      required: true
    }
  },
  methods:{
    onClick() {
      this.$emit('overlayClicked', {id: this.indicatorId})
    }
  },
}
</script>
<style lang="scss" scoped>

.floating-button-wrapper {
  position: inherit;
}

.floating-button {
  width: 35px;
  height: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: all 0.1s ease-in-out;
  opacity: 0.75;
  &:hover{
    transform: scale(1.1);
    border-radius: 50%;
  }
}
</style>
