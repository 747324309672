export const API_HOST = process.env.VUE_APP_API_ENTRYPOINT
export const API_PATH = '/'
export const RECAPTCHA_SITE_KEY = process.env.RECAPTCHA_SITE_KEY
export const GOOGLE_MAPS_KEY = process.env.GOOGLE_MAPS_KEY
export const BITLY_URL = process.env.BITLY_URL
export const BITLY_LOGIN = process.env.BITLY_LOGIN
export const BITLY_KEY = process.env.BITLY_KEY

export const OIDC_SCISTARTER_CLIENT_ID = process.env.OIDC_SCISTARTER_CLIENT_ID
export const OIDC_SCISTARTER_PROVIDER_URL = process.env.OIDC_SCISTARTER_PROVIDER_URL
export const OIDC_SCISTARTER_REDIRECT_URI = process.env.OIDC_SCISTARTER_REDIRECT_URI

export const OIDC_GOOGLE_CLIENT_ID = process.env.OIDC_GOOGLE_CLIENT_ID
export const OIDC_GOOGLE_PROVIDER_URL = process.env.OIDC_GOOGLE_PROVIDER_URL
export const OIDC_GOOGLE_REDIRECT_URI = process.env.OIDC_GOOGLE_REDIRECT_URI

export const OIDC_AIRTABLE_CLIENT_ID = process.env.OIDC_AIRTABLE_CLIENT_ID
export const OIDC_AIRTABLE_PROVIDER_URL = process.env.OIDC_AIRTABLE_PROVIDER_URL
export const OIDC_AIRTABLE_TOKEN_URL = process.env.OIDC_AIRTABLE_TOKEN_URL
export const OIDC_AIRTABLE_REDIRECT_URI = process.env.OIDC_AIRTABLE_REDIRECT_URI
export const OIDC_AIRTABLE_SCOPES = process.env.OIDC_AIRTABLE_SCOPES

export const NOTIFICATION_MESSAGE= process.env.NOTIFICATION_MESSAGE
export const NOTIFICATION_TYPE= process.env.NOTIFICATION_TYPE
export const NOTIFICATION_LINK= process.env.NOTIFICATION_LINK
export const NOTIFICATION_LINK_MESSAGE= process.env.NOTIFICATION_LINK_MESSAGE
export const NOTIFICATION_IMAGE= process.env.NOTIFICATION_IMAGE
