<template>
  <div class="mt-2 ml-2 mr-2">
    <div v-if="isManager">
      <b-row>
        <b-col class="col">
          <b-card class="mb-2" border-variant="light" bg-variant="light">
            <p>Welcome to the SciStarter Integration!<br>
              You can connect your CitSci project with <a href="https://scistarter.com" target="_blank">
                SciStarter</a> <span><i class="fas fa-external-link-alt fa-xs"></i></span>
              <br> This functionality is under development and will be Coming Soon!
            </p>
            <br>
            <h6>Read more about this integration which uses the Participation API
              <a href="https://scistarter.org/api" target="_blank">here</a>
              <span><i class="fas fa-external-link-alt fa-xs"></i></span>
            </h6>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <RequiresManager v-else page="SciStarter Integration"></RequiresManager>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import RequiresManager from "../tabpermission/requiresManager.vue";

export default {
  components: {RequiresManager},
  computed: {
    ...mapGetters({
      isManager: "project/get/isManager",
    })
  },
};
</script>

<style lang=""></style>
