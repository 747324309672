import list from './list'
import get from './get';
import stats from "./stats";
import images from "./images";
import resources from "./resources";
import scorecards from "./scorecards";
import photo_observations from "./photo_observations";
export default {
  namespaced: true,
  modules: {
    list,
    get,
    stats,
    images,
    resources,
    scorecards,
    photo_observations,
  }
}
