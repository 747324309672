<template>
  <div class="container">
    <h1>Hubs</h1>
    <div class="container p-0" data-aos="fade-up">
      <div class="w-75 mx-auto">
        <p>Do you or your organization run many participatory science projects? Need a centralized 
          dashboard to share out all your projects and key indicators? Look no further than CitSci 
          Hubs. Interested? <b-link to="contact">Reach out</b-link> and we'll be happy to discuss 
          available options, share more details, and get you started today.
        </p>
        <div class="d-none d-lg-block">
          <ul class="list-group list-group-horizontal mb-5 mt-5">
            <li class="list-group-item flex-fill rounded-pill mr-4 text-center p-down text-grey bg-light">
              <i class="mr-2 fa fa-lightbulb" aria-hidden="true"></i>
              Simple
            </li>
            <li class="list-group-item flex-fill rounded-pill mr-4 text-center p-down text-grey bg-light">
              <i class="mr-2 fa fa-project-diagram" aria-hidden="true"></i>
              Organized
            </li>
            <li class="list-group-item flex-fill rounded-pill text-center p-down text-grey bg-light">
              <i class="mr-2 fa fa-pencil" aria-hidden="true"></i>
              Configurable
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="container p-0" data-aos="fade-up">
      <h2>Featured</h2>
      <div class="row d-flex align-items-stretch" v-if="!isOrganizationsLoading">
        <div v-for="featuredHub in featuredHubs" :key="featuredHub.id" class="col-md-2 mb-2 mx-0">
          <div class="card p-0 mx-0">
            <div v-if="featuredHub.banner" v-bind:style="{
              'background-image': 'url(' + featuredHub.banner.path + ')',
              'height': '120px',
              'background-color': '#cccccc',
              'background-position': 'center',
              'background-repeat': 'no-repeat',
              'background-size': 'cover'
            }">
            </div>
            <div v-else style="height: 120px; background-color:grey;">&nbsp;</div>
            <router-link :to="{ name: 'OrganizationShow', params: { name: featuredHub.url }}"
                          class="stretched-link" :alt="featuredHub.name + ' Hub'" :title="featuredHub.name + ' Hub'">
            </router-link>
            <div class="card-footer pl-2 pr-0" style="background-color: #F5F4E8">
              <h5 class="d-none d-md-block">{{ ellipsizeCharacters(featuredHub.name, 16) }}</h5>
              <h5 class="d-md-none">{{ featuredHub.name }}</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="" v-else>
        <i class="fa fa-spin fa-spinner fa-3x mr-3"></i> Loading Hubs...
      </div>
    </div>
    <div class="container p-0" data-aos="fade-up">
      <h2>All</h2>
      <div class="row d-flex align-items-stretch" v-if="!isOrganizationsLoading">
        <div v-for="organization in organizationsList" :key="organization.id" class="col-md-2 mb-4 mx-0">
          <div class="card p-0 mx-0">
            <div v-if="organization.banner" v-bind:style="{
              'background-image': 'url(' + organization.banner.path + ')',
              'height': '120px',
              'background-color': '#cccccc',
              'background-position': 'center',
              'background-repeat': 'no-repeat',
              'background-size': 'cover'
            }">
            </div>
            <div v-else style="height: 120px; background-color:grey;">&nbsp;</div>
            <router-link :to="{ name: 'OrganizationShow', params: { name: organization.url }}"
                        class="stretched-link" :alt="organization.name + ' Hub'" :title="organization.name + ' Hub'">
            </router-link>
            <div class="card-footer pl-2 pr-0" style="background-color: #F5F4E8">
              <h5 class="d-none d-md-block">{{ ellipsizeCharacters(organization.name, 16) }}</h5>
              <h5 class="d-md-none">{{ organization.name }}</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="" v-else>
        <i class="fa fa-spin fa-spinner fa-3x mr-3"></i> Loading Hubs...
      </div>
    </div>
    <div class="container p-0 mb-5 mt-5">
      <p>Please <b-link to="contact">let us know</b-link> your ideas about how we can improve these hubs 
        as we continue to build out new capabilities.
      </p>
    </div>
  </div>
</template>

<style></style>

<script>

  import { mapActions, mapGetters } from 'vuex'
  //import 'aos/dist/aos.css'

  export default {
    components: { },
    data() {
      return {
        //impactStories: impactStories,
        organizations: [],
      };
    },
    created() {
      this.getOrganizations()
    },
    getters: {
      featuredHubs (state) {
        //return state.organization.list.find(list => list.id === id)
        return state.organizations.list.filter(
          //list => list.name
          { key: 'name', value: 'The Land Institute' },
          { key: 'name', value: 'Leave No Trace ' },
        )
      }
    },
    computed: {
      ...mapGetters({
        //isLoading: 'project/list/isLoading',
        //allProjects: 'project/list/projects',
        //error: 'project/list/error',

        isOrganizationsLoading: 'organization/list/isOrganizationsLoading',
        organizationsList: 'organization/list/organizations',
        organizationsError: 'organization/list/organizationsError',

        //isProjectARoleManager: 'user/profile/isProjectARoleManager',
        //isProjectARoleMember: 'user/profile/isProjectARoleMember',
        //isProjectARolePending: 'user/profile/isProjectARolePending',
        //isProjectARoleNone: 'user/profile/isProjectARoleNone',

        //itemPage: "project/list/page",
        //itemPerPage: "project/list/perPage",
        //itemTotal: "project/list/total",
        //itemFilterText: "project/list/searchFilterText",
        //itemFilterOrg: "project/list/searchFilterOrg",
        //itemSortBy: "project/list/sortBy",
        //itemSortDirection: "project/list/sortDirection",
        //itemSortDesc: "project/list/sortDesc",
        //filteredOrgs () {
          //console.log('filteredOrgs test')
          //return this.$store.state.organization.list.filter(list => list.name).length
        //}

        //featuredHubs () {
          //return this.$store.getters.featuredHubs
        //}
        featuredHubs: 'organization/list/featuredHubs'
        
      }),
     },
     methods: {
      ...mapActions({
      getOrganizations: 'organization/list/getOrganizations',
      //filterOrganizations: function (organizationsList) {
        //store.getters.getfeaturedHubs('13960d06-616d-414f-94eb-a2822071a4e4') // -> { id: 2, text: '...', done: false }
        //var featuredOrgs = []
        //console.log('start filter'+this.organizationsList)
        //var allOrgs = this.organizationsList
        //console.log('allOrgs'+allOrgs)

        //for (organization in allOrgs) {
          //console.log('here'+organization)
          //if (organization.name = 'The Land Institute') {
            //console.log("here")
            //featuredOrgs.push(organization)
          //}
        //}
        //console.log(featuredOrgs)
        //return featuredOrgs
      //}
      }),
    },
    mounted() {
      //this.filterOrganizations(this.organizationsList)
    },
    filters: {
      capitalize: function (value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
      },
    }
  }

</script>
