<template>
  <div class="">
    <div v-if="isManager">
      <IndicatorsAdminView></IndicatorsAdminView>
    </div>
    <div v-else>
      <IndicatorsNonAdminView></IndicatorsNonAdminView>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import IndicatorsAdminView from "./scorecards/IndicatorsAdminView.vue";
import IndicatorsNonAdminView from "./scorecards/IndicatorsNonAdminView.vue";

export default {
  components: {
    IndicatorsAdminView,
    IndicatorsNonAdminView
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters({
      organizationId: "organization/get/id",
      membershipMap: "user/profile/organizationMembershipMap"
    }),
    isManager() {
      return (
        this.organizationId &&
        this.membershipMap[this.organizationId] &&
        this.membershipMap[this.organizationId].isManager
      );
    },
  },
  methods: {
  }
};
</script>
