var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.project)?_c('div',{staticClass:"d-block"},[(!_vm.hasSession)?_c('b-btn',{staticClass:"my-1 btn-block btn-tertiary",class:_vm.btnClass,attrs:{"to":{
      name: 'Login',
      query: {
        redirect: ("projects/" + (this.project.urlField))
      }
    },"alt":"Login and then you can join this project","title":"Login and then you can join this project"},on:{"click":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"fa fa-sign-in"}),_vm._v(" Login\n  ")]):(_vm.isLoading)?_c('router-link',{staticClass:"my-1 btn btn-secondary btn-block text-white",class:_vm.btnClass,attrs:{"to":{ name: 'ProjectShow', params: { id: this.project.urlField } },"alt":"Joining project","title":"Joining project"},on:{"click":function($event){$event.stopPropagation();}}},[_c('font-awesome-icon',{attrs:{"icon":"spinner","pulse":""}}),_vm._v(" Joining\n  ")],1):(
      _vm.membershipMap[_vm.project.id] && _vm.membershipMap[_vm.project.id].isManager
    )?_c('router-link',{staticClass:"my-1 btn btn-secondary btn-block text-white",class:_vm.btnClass,attrs:{"to":{ name: 'ProjectShow', params: { id: this.project.urlField } },"alt":"Project manager - view your project profile","title":"Project manager - view your project profile"},on:{"click":function($event){$event.stopPropagation();}}},[_c('font-awesome-icon',{attrs:{"icon":"users"}}),_vm._v(" Manager\n  ")],1):(
      _vm.membershipMap[_vm.project.id] && _vm.membershipMap[_vm.project.id].isMember
    )?_c('router-link',{staticClass:"my-1 btn btn-secondary btn-block text-white",class:_vm.btnClass,attrs:{"to":{ name: 'ProjectShow', params: { id: this.project.urlField } },"alt":"Member - view this project profile","title":"Member - view this project profile"},on:{"click":function($event){$event.stopPropagation();}}},[_c('font-awesome-icon',{attrs:{"icon":"user"}}),_vm._v(" Member\n  ")],1):(
      _vm.membershipMap[_vm.project.id] && _vm.membershipMap[_vm.project.id].isPending
    )?_c('router-link',{staticClass:"my-1 btn btn-secondary btn-block text-white",class:_vm.btnClass,attrs:{"to":{ name: 'ProjectShow', params: { id: this.project.urlField } },"alt":"Approval Pending - view this project profile","title":"Approval Pending - view this project profile"},on:{"click":function($event){$event.stopPropagation();}}},[_c('font-awesome-icon',{attrs:{"icon":"hourglass"}}),_vm._v(" Pending Approval\n  ")],1):(!_vm.project.approveContacts)?_c('b-btn',{staticClass:"my-1 btn-block btn-tertiary",class:_vm.btnClass,attrs:{"alt":"Join this open citizen science project","title":"Join this open citizen science project"},on:{"click":[function($event){$event.stopPropagation();},function($event){return _vm.clickJoin($event, 'joined')}]}},[_c('font-awesome-icon',{attrs:{"icon":"plus-circle"}}),_vm._v(" Join\n  ")],1):(_vm.project.approveContacts)?_c('b-btn',{staticClass:"my-1 btn-block btn-tertiary",class:_vm.btnClass,attrs:{"alt":"Ask to join this member-based project","title":"Ask to join this member-based project"},on:{"click":[function($event){$event.stopPropagation();},function($event){return _vm.clickJoin($event, 'pending')}]}},[_c('font-awesome-icon',{attrs:{"icon":"user-plus"}}),_vm._v(" Ask to Join\n  ")],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []
export { render, staticRenderFns }