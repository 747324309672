import VueDraggable from 'vuedraggable'

export default {
  components: {
    VueDraggable
  },

  props: {
    recordObject: {
      type: Object,
      default: () => {}
    },

    activeRecord: {
      type: Object,
      default: () => {}
    },

    recordType: {
      type: String,
      default: ''
    },

    updateRecordLabel: {
      type: Function,
      default: () => {}
    },

    updateRecordAllowReplica: {
      type: Function,
      default: () => {}
    },

    isDatasheetRecordEdited: {
      type: Function,
      default: () => {}
    },

    addRecordOption: {
      type: Function,
      default: () => {}
    },

    removeRecordOption: {
      type: Function,
      default: () => {}
    },

    updateRecordOptions: {
      type: Function,
      default: () => {}
    },

    updateRecordRequiredValidator: {
      type: Function,
      default: () => {}
    },

    updateRecordDefaultOptionValue: {
      type: Function,
      default: () => {}
    },

    updateRecordMinLengthValidator: {
      type: Function,
      default: () => {}
    },

    updateRecordMaxLengthValidator: {
      type: Function,
      default: () => {}
    },
  },

  data () {
    return {
      isSelected: false
    }
  },

  methods: {
    onFullTextSelection (event) {
      if (!this.isSafari) {
        event.target.select()
      }
    },

    onFullTextSelectionConditioned (event) {
      if (this.isSafari && !this.isSelected) {
        event.target.select()
        this.isSelected = true
      }
    },

    onFullTextDeselection () {
      if (this.isSafari && this.isSelected) {
        this.isSelected = false
      }
    },

    checkUserAgent () {
      if (
        navigator.userAgent.indexOf('Safari') !== -1 &&
        navigator.userAgent.indexOf('Chrome') === -1
      ) {
        this.isSafari = true
      }
    }
  },

  created() {
    this.checkUserAgent ()
  },
}
