<template>
<div>
    <div class='bg-dark rounded-top p-2'>
        <button class='btn btn-primary' type="button" @click="geolocationStart()">
            <span class='d-none d-md-inline'>Get My Location</span>
            <i class='fa fa-arrow ml-md-2'></i>
            <i class="fa fa-spinner fa-spin fa-fw" v-if='isLocating'></i>

        </button>
    </div>
    <LMap class="recordLocation" :options="mapOptions" :zoom="zoom" :center="center" @update:zoom="zoomUpdated" @update:center="centerUpdated" ref="map">
        <LTileLayer :url="url" :attribution="attribution"></LTileLayer>
        <LMarker :lat-lng="markerLatLng" v-if='latitude && longitude'></LMarker>

        <MyLocationMarker></MyLocationMarker>

    </LMap>
    <div class='bg-dark rounded-bottom p-2'>

        <div class="input-group">
            <div class="input-group-prepend">
                <span class="input-group-text"> <img src='/marker-icon.png' class='marker-legend'>
                </span>

                <span class="input-group-text d-md-none" id="">Lat</span>
                <span class="input-group-text d-none d-md-inline" id="">Latitude</span>

            </div>
            <input v-model="latitude" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" type="number" disabled> </input>
            <div class="input-group-prepend">
                <span class="input-group-text d-md-none">Lng</span>
                <span class="input-group-text d-none d-md-inline">Longitude</span>

            </div>
            <input v-model="longitude" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" type="number" disabled></input>
        </div>

    </div>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters,
    mapMutations
} from "vuex";
import {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LControlZoom,
    LControlScale,
} from "vue2-leaflet";
import {
    latLng,
    latLngBounds,
    divIcon,
    circleMarker
} from "leaflet";
import MyLocationMarker from "./MyLocationMarker";

export default {
    name: "ObservationRecordItemLocation",
    props: {
        value: {
            type: String,
            default: ''
        },
    },

    components: {
        LMap,
        LTileLayer,
        LMarker,
        LControlZoom,
        LControlScale,
        MyLocationMarker,
    },
    data() {
        return {
            latitude: JSON.parse(this.value).latitude,
            longitude: JSON.parse(this.value).longitude,

            url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            storyLayerEsriObject: null,
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            mapOptions: {
                preferCanvas: true,
                zoomSnap: 0.5,
                worldCopyJump: true,
                zoomDelta: 0.5,
                maxZoom: 24,
                minZoom: 2,
                zoomControl: true,
            },
            showMap: true,
            zoom: 4,
            center: {
                lat: JSON.parse(this.value).latitude,
                lng: JSON.parse(this.value).longitude,
            }
        };
    },
    computed: {
        ...mapGetters({
            geoLocationHistory: "geoLocationHistory",
            isLocating: "isLocating",
        }),
        markerLatLng: {
            get() {
                return {
                    lat: this.latitude,
                    lng: this.longitude,
                }
            },
            set(val) {
                if (!val) {
                    return;
                }
                this.latitude = val.lat
                this.longitude = val.lng
            }
        },
    },
    methods: {
        ...mapActions({
            geolocationStart: "geolocationStart",
        }),
        zoomUpdated(zoom) {
            this.zoom = zoom;
        },
        centerUpdated(center) {
            this.center = center;
        },
    },
    watch: {
        geoLocationHistory(val) {
            const valArray = val.map((geo)=>{return {lat: geo.latitude,lng: geo.longitude }})
            this.$refs.map.mapObject.flyToBounds([this.markerLatLng, ...valArray ]);      
        },
    },
    mounted() {
        this.$refs.map.mapObject.flyTo(this.markerLatLng, 16, {
            duration: 8,
        });
    },
};
</script>

<style lang="css">
.recordLocation {
    height: 300px !important;
    z-index: 0;
}

.marker-legend {
    height: 16px;
}
</style>
