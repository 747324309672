import * as types from './mutation_types'

export const initialState = {
  // datasheet creator form values
  retrievedDatasheetIsLoading: false,
  retrievedDatasheetError: '',
  retrievedDatasheet: null,
  retrievedViolations: null,
  updatedDatasheetIsLoading: false,
  updatedDatasheet: null,
  updatedViolations: null,
  updatedDatasheetRecords: '',
  updatedDatasheetRecordsIsLoading: false,
  updatedDatasheetRecordsViolations: null,
  retrievedDatasheetRecords: null,
  updatedDatasheetTextRecord: null,
  updatedDatasheetLocations: null,
  updatedDatasheetPublished: null,
  updatedDatasheetSubRecords: null,
  updatedDatasheetRecordDetailsError: '',
  addedSpeciesToOrganismRecord: null,
  retrievedLocations: [],
  retrievedLocationsError: '',
  retrievedLocationsIsLoading: null,
  checkedLocationExistence: [],
  checkLocationExistenceError: '',

  // get organisms values
  retrievedOrganisms: [],
  retrievedOrganismsView: {},
  retrievedOrganismsError: '',
  retrievedOrganismsIsLoading: false,

  // new state
  datasheet: null,
  datasheetRecords: [],
  datasheetLocations: [],
  datasheetProject: null,
  datasheetIsLoading: false,
  datasheetIsUploading: false,
  datasheetIsPublishing: false,
  datasheetError: null,
  datasheetViolations: null,
}

export default {
  // datasheet creator form mutations
  [types.DATASHEET_RETRIEVE_SET_ERROR] (state, retrievedDatasheetError) {
    Object.assign(state, { retrievedDatasheetError })
  },

  [types.DATASHEET_RETRIEVE_TOGGLE_LOADING] (state) {
    Object.assign(state, { retrievedDatasheetIsLoading: !state.retrievedDatasheetIsLoading })
  },

  [types.DATASHEET_RETRIEVE_SET_RETRIEVED] (state, retrievedDatasheet) {

    const recordsArray = Array.isArray(retrievedDatasheet.records)? 
    retrievedDatasheet.records:
    Object.keys(retrievedDatasheet.records).map((key) => {
      return retrievedDatasheet.records[key]
    });
    const recordsFiltered = recordsArray.filter((record)=>{
      return !record.parentRecord
    })

    const records = recordsFiltered.map((parentRecord)=>{
      const subRecordsArray = Array.isArray(parentRecord.records)?
          parentRecord.records : 
          Object.keys(parentRecord.records).map((key) => {
            return parentRecord.records[key]
          });
      const subRecordsNoParent = subRecordsArray.map((subRecord)=>{
        return {
          ...subRecord,
          parentRecord: undefined
        }
      })
      const subRecordsSorted = subRecordsNoParent.sort((a,b)=>{
        return a.orderNumber - b.orderNumber;
      })
      return {
        ...parentRecord,
        records: subRecordsSorted
      }
    })

    
    Object.assign(state, { retrievedDatasheet:{
      ...retrievedDatasheet,
      records: records
    }})
  },

  [types.DATASHEET_RETRIEVE_RECORDS_SET_RETRIEVED] (state, retrievedDatasheetRecords) {
    Object.assign(state, { retrievedDatasheetRecords })
  },

  [types.DATASHEET_RETRIEVE_SET_VIOLATIONS] (state, retrievedViolations) {
    Object.assign(state, { retrievedViolations })
  },

  [types.DATASHEET_RETRIEVE_RESET] (state) {
    Object.assign(state, {
      retrievedDatasheet: null,
      retrievedDatasheetError: '',
      retrievedDatasheetIsLoading: false,
      retrievedViolations: null,
      updatedDatasheetRecordDetailsError: ''
    })
  },

  [types.DATASHEET_UPDATE_SET_ERROR] (state, retrievedDatasheetError) {
    Object.assign(state, { retrievedDatasheetError })
  },

  [types.DATASHEET_UPDATE_RECORD_DETAILS_SET_ERROR] (state, updatedDatasheetRecordDetailsError) {
    Object.assign(state, { updatedDatasheetRecordDetailsError })
  },

  [types.DATASHEET_UPDATE_TOGGLE_LOADING] (state) {
    Object.assign(state, { updatedDatasheetIsLoading: !state.updatedDatasheetIsLoading })
  },

  [types.DATASHEET_UPDATE_SET_UPDATED] (state, updatedDatasheet) {
    Object.assign(state, { updatedDatasheet })
  },

  [types.DATASHEET_UPDATE_SET_VIOLATIONS] (state, updatedViolations) {
    Object.assign(state, { updatedViolations })
  },

  [types.DATASHEET_UPDATE_RESET] (state) {
    Object.assign(state, {
      updatedDatasheet: null,
      updatedDatasheetIsLoading: false,
      updatedViolations: null
    })
  },

  [types.DATASHEET_RECORD_SET_ERROR] (state, retrievedDatasheetError) {
    Object.assign(state, { retrievedDatasheetError })
  },

  [types.DATASHEET_RECORD_TOGGLE_LOADING] (state) {
    Object.assign(state, { updatedDatasheetRecordsIsLoading: !state.updatedDatasheetRecordsIsLoading })
  },

  [types.DATASHEET_RECORD_SET_CREATED] (state, updatedDatasheetRecords) {
    Object.assign(state, { updatedDatasheetRecords })
  },

  [types.DATASHEET_RECORD_SET_VIOLATIONS] (state, updatedDatasheetRecordsViolations) {
    Object.assign(state, { updatedDatasheetRecordsViolations })
  },

  [types.DATASHEET_UPDATE_RESET] (state) {
    Object.assign(state, {
      updatedDatasheetRecords: null,
      updatedDatasheetRecordsIsLoading: false,
      updatedDatasheetRecordsViolations: null
    })
  },

  [types.DATASHEET_ORGANISM_RECORD_SPECIES_SELECTED] (state, addedSpeciesToOrganismRecord) {
    Object.assign(state, { addedSpeciesToOrganismRecord })
  },

  [types.DATASHEET_TEXT_RECORD_SET_UPDATED] (state, updatedDatasheetTextRecord) {
    Object.assign(state, { updatedDatasheetTextRecord })
  },

  [types.DATASHEET_LOCATIONS_SET_UPDATED] (state, updatedDatasheetLocations) {
    Object.assign(state, { updatedDatasheetLocations })
  },

  [types.DATASHEET_PUBLISHED_SET_UPDATED] (state, updatedDatasheetPublished) {
    Object.assign(state, { updatedDatasheetPublished })
  },

  [types.DATASHEET_UPDATE_RECORDS_SET_UPDATED] (state, updatedDatasheetSubRecords) {
    Object.assign(state, { updatedDatasheetSubRecords })
  },

  [types.DATASHEET_RETRIEVE_LOCATIONS_SET_RETRIEVED] (state, retrievedLocations) {
    Object.assign(state, { retrievedLocations })
  },

  [types.DATASHEET_RETRIEVE_LOCATIONS_SET_ERROR] (state, retrievedLocationsError) {
    Object.assign(state, { retrievedLocationsError })
  },

  [types.DATASHEET_RETRIEVE_LOCATIONS_TOGGLE_LOADING] (state) {
    Object.assign(state, { retrievedLocationsIsLoading: !state.retrievedLocationsIsLoading })
  },

  [types.DATASHEET_CHECK_LOCATION_EXISTENCE_SET_RETRIEVED] (state, checkedLocationExistence) {
    Object.assign(state, { checkedLocationExistence })
  },

  [types.DATASHEET_CHECK_LOCATION_EXISTENCE_SET_ERROR] (state, checkLocationExistenceError) {
    Object.assign(state, { checkLocationExistenceError })
  },

  [types.DATASHEET_CHECK_LOCATION_EXISTENCE_TOGGLE_LOADING] (state) {
    Object.assign(state, { retrievedLocationsIsLoading: !state.retrievedLocationsIsLoading })
  },

  // get organisms mutations
  [types.DATASHEET_RETRIEVE_ORGANISMS_SET_ITEMS] (state, retrievedOrganisms) {
    Object.assign(state, { retrievedOrganisms })
  },

  [types.DATASHEET_RETRIEVE_ORGANISMS_SET_VIEW] (state, retrievedOrganismsView) {
    Object.assign(state, { retrievedOrganismsView })
  },

  [types.DATASHEET_RETRIEVE_ORGANISMS_SET_ERROR] (state, retrievedOrganismsError) {
    Object.assign(state, { retrievedOrganismsError })
  },

  [types.DATASHEET_RETRIEVE_ORGANISMS_TOGGLE_LOADING] (state) {
    Object.assign(state, { retrievedOrganismsIsLoading: !state.retrievedOrganismsIsLoading })
  },




  
  [types.DATASHEET_SET_LOADING] (state) {
    Object.assign(state, { 
      datasheetIsLoading: true,
      datasheetViolations: null,
      datasheetErrors: null,
    })
  },
  [types.DATASHEET_SET_UPLOADING] (state) {
    Object.assign(state, { 
      datasheetIsUploading: true,
      datasheetViolations: null,
      datasheetError: null,
    })
  },
  [types.DATASHEET_SET_PUBLISHING] (state) {
    Object.assign(state, { 
      datasheetIsPublishing: true,
    })
  },
  [types.DATASHEET_SET_PUBLISHED] (state) {
    Object.assign(state, { 
      datasheetIsPublishing: false,
    })
  },
  [types.DATASHEET_SET_RETRIEVED] (state, datasheet) {

      const recordsArray = Array.isArray(datasheet.records)? 
          datasheet.records:
          Object.keys(datasheet.records).map((key) => {
            return datasheet.records[key]
          });
      const recordsFiltered = recordsArray.filter((record)=>{
        return !record.parentRecord
      })

      const records = recordsFiltered.map((parentRecord)=>{
        const subRecordsArray = Array.isArray(parentRecord.records)?
            parentRecord.records : 
            Object.keys(parentRecord.records).map((key) => {
              return parentRecord.records[key]
            });
        const subRecordsNoParent = subRecordsArray.map((subRecord)=>{
          return {
            ...subRecord,
            parentRecord: undefined
          }
        })
        const subRecordsSorted = subRecordsNoParent.sort((a,b)=>{
          return a.orderNumber - b.orderNumber;
        })
        return {
          ...parentRecord,
          records: subRecordsSorted
        }
      })
      const locations = datasheet.locations.sort((a, b)=>{
        return  a.name.localeCompare(b.name) 
      });
      const datasheetStripped = {
        ...datasheet,
        records: undefined,
        locations: undefined,
        project: undefined
      }
    Object.assign(state, { 
      datasheet: datasheetStripped,
      datasheetProject: datasheet.project,
      datasheetRecords: records,
      datasheetLocations: locations,
      datasheetIsLoading: false,
      datasheetIsUploading: false,
      datasheetViolations: null,
      datasheetError: null,
    })
  },
  [types.DATASHEET_SET_ERROR] (state, error) {
    Object.assign(state, { 
      datasheetIsLoading: false,
      datasheetIsUploading: false,
      datasheetError: error,
    })
  },
  [types.DATASHEET_SET_VIOLATIONS] (state, issue) {
    Object.assign(state, { 
      datasheetIsLoading: false,
      datasheetIsUploading: false,
      datasheetError: issue.error,
      datasheetViolations: issue.violations
    })
  },
  [types.DATASHEET_RESET] (state) {
    Object.assign(state, {
      ...initialState
    })
  }



}
