import SubmissionError from '../../../../error/SubmissionError'
import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const createProjectDownload = ({ commit }, data) => {
  commit(types.PROJECT_DOWNLOAD_ERROR, '')
  commit(types.PROJECT_DOWNLOAD_LOADING_TOGGLE)

  return fetch('/project_downloads', { method: 'POST', body: JSON.stringify(data) })
    .then(response => response.json())
    .then((data) => {
      commit(types.PROJECT_DOWNLOAD_LOADING_TOGGLE)
      commit(types.PROJECT_DOWNLOAD_CREATED, data)

      return data
    })
    .catch((e) => {
      commit(types.PROJECT_DOWNLOAD_LOADING_TOGGLE)
      commit(types.PROJECT_DOWNLOAD_ERROR, e.message)
    })
}

export const getAllUserDownloads = ({ commit }, data) => {
  commit(types.PROJECT_DOWNLOAD_ERROR, '')
  commit(types.PROJECT_DOWNLOAD_LOADING_TOGGLE)

  // + '&project='+data.projectId
  return fetch('/users/'+ data.userId +'/project_downloads' + data.urlParams)
    .then(response => response.json())
    .then((data) => {
      commit(types.PROJECT_DOWNLOAD_LOADING_TOGGLE)
      commit(types.PROJECT_DOWNLOAD_ALL, data)

      return data
    })
    .catch((e) => {
      commit(types.PROJECT_DOWNLOAD_LOADING_TOGGLE)
      commit(types.PROJECT_DOWNLOAD_ERROR, e.message)
    })
}

export const getUserDownloadsForProject = ({ commit }, data) => {
  commit(types.PROJECT_DOWNLOAD_ERROR, '')
  commit(types.PROJECT_DOWNLOAD_LOADING_TOGGLE)

  return fetch('/users/'+ data.userId +'/project_downloads' + data.urlParams + '&project='+data.projectId)
    .then(response => response.json())
    .then((data) => {
      commit(types.PROJECT_DOWNLOAD_LOADING_TOGGLE)
      commit(types.PROJECT_DOWNLOAD_ALL, data)

      return data
    })
    .catch((e) => {
      commit(types.PROJECT_DOWNLOAD_LOADING_TOGGLE)
      commit(types.PROJECT_DOWNLOAD_ERROR, e.message)
    })
}

export const getSingleDownload = ({ commit }, data) => {
  commit(types.PROJECT_DOWNLOAD_ERROR, '')
  commit(types.PROJECT_DOWNLOAD_LOADING_TOGGLE)

  return fetch('/project_downloads/'+ data.id)
    .then(response => response.json())
    .then((data) => {
      commit(types.PROJECT_DOWNLOAD_LOADING_TOGGLE)
      commit(types.PROJECT_DOWNLOAD_SINGLE, data)

      return data
    })
    .catch((e) => {
      commit(types.PROJECT_DOWNLOAD_LOADING_TOGGLE)
      commit(types.PROJECT_DOWNLOAD_ERROR, e.message)
    })
}
