<template>
  <div class="organizationHeader" :style="headerStyle">
    <div class="container h-320 d-flex flex-column justify-content-between">
      <div class="row pb-3 flex-none justify-content-between">
        <div class="col-md-9">
          <h1 v-if="isLoading" class="organizationName p-3 mb-3 mt-0">
            Loading Hub...
          </h1>
          <h1 v-else class="organizationName p-3 mb-3 mt-0">
            {{ organization.name }} Hub
          </h1>
          <i class="fa fa-3x fa-spin fa-spinner ml-4" v-if="isLoading"></i>
        </div>
        <div class="col-md-3 text-right pr-5">
          <img v-if="logo" class="organizationPicture rounded-circle" :src="logo" alt="logo" />
        </div>
      </div>
      <div class="row justify-content-center flex-none">
        <div class="col-lg-8">
          <organizationStats v-if="!isLoading"></organizationStats>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import organizationStats from "./organizationStats";

export default {
  components: {
    organizationStats
  },
  computed: {
    ...mapGetters({
      organization: "organization/get/organization",
      isLoading: "organization/get/isLoading"
    }),
    headerStyle() {
      return {
        backgroundImage: `url(${
          this.organization && this.organization.banner
            ? this.organization.banner.path
            : "/assets/images/header/default-project-header-banner.png"
        })`,
        backgroundSize: "cover",
        backgroundPosition: "center bottom",
        backgroundColor: "grey"
      };
    },
    logo() {
      return this.organization && this.organization.logo
        ? this.organization.logo.path
        : null; // '/assets/images/logo.png';
    },
    // name() {
    //   if (this.organization) {
    //     return this.organization.name;
    //   }
    //   return this.$route.params.name.replace(/-/g, " ");
    // }
  }
};
</script>

<style lang="scss" scoped>
.h-320 {
  min-height: 320px;
}

.organizationName {
  color: white;
  background: rgba(34, 37, 44, 0.75);
  display: inline-block;
  font-size: 3.5rem !important;
}
.organizationPicture {
  max-width: 160px;
  max-height: 160px;
  width: 100px;
  height: 100px;
  /*border: solid 2px white;*/
  border-style: solid;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.5);
}
</style>
