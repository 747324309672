import SubmissionError from '../../../../error/SubmissionError'
import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

/**
 * @function getStats - get the stats for the fields from server
 * @param commit
 * @returns {PromiseLike<T | never>}
 */
export const getStats = ({commit}) => {
  commit(types.ABOUT_SHOW_TOGGLE_LOADING)

  return fetch('stats')
    .then(response => response.json())
    .then((data) => {
      commit(types.ABOUT_SHOW_TOGGLE_LOADING)
      commit(types.ABOUT_SET_STATS, data)
      return data
    })
}

/**
 * @function reset - reset the object to default values
 * @param commit
 */
export const reset = ({ commit }) => {
  commit(types.ABOUT_SHOW_RESET)
}
