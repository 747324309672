export const error = state => state.error
export const isLoading = state => state.isLoading
export const violations = state => state.violations
export const retrieved = state => state.retrieved

export const isLoadingLocations = state => state.isLoadingLocations
export const isLoadingAllLocations = state => state.isLoadingAllLocations

export const datasheetCreated = state => state.datasheetCreated
export const datasheetError = state => state.datasheetError
export const datasheetIsLoading = state => state.datasheetIsLoading
export const datasheetViolations = state => state.datasheetViolations

export const searchTerm = state => state.searchTerm

export const projectLocations = state => state.projectLocations
export const allProjectLocations = state => state.allProjectLocations

export const stats = state => state.stats

export const isUploading = state => state.isUploading

export const getIdByProjectName = state => state.getProjectIdByName

export const isLiked = state => state.isLiked

export const discussionForumData = state => state.discussionForumData
export const isDeleted = state => state.isDeleted
export const updatedPostAfterReply = state => state.updatedPostAfterReply
export const isLoadingDiscussionData = state => state.isLoadingDiscussionData

export const isLoadingCheckLocationExists = state => state.isLoadingCheckLocationExists
export const errorCheckLocationExists = state => state.errorCheckLocationExists
export const checkLocationExists = state => state.checkLocationExists

export const isLoadingZooniverse = state => state.isLoadingZooniverse

export const isLoadingContactManager = state => state.isLoadingContactManager
export const contactManagerSuccess = state => state.contactManagerSuccess

export const isLoadingMembers = state => state.isLoadingMembers
export const projectMembers = state => state.projectMembers
