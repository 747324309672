import * as types from './mutation_types'

export const initialState = {
  error: '',
  violations: null,
  isLoadingSession: false,
  isLoadingUpdate: false,
  isLoadingProjects: false,
  isLoadingStats: false,
  isLoadingLocations: false,
  isLoadingObservations: false,
  sessionData: JSON.parse( window.localStorage.getItem('sessionData')),
  locations: null,
  projects: null,
  observations: null,
  allProjects: [],
  stats: null,

  isPictureRemoving: false,
  isPictureUploading: false,
  pictureError: '',
  
  isEditingProfile: false,

  resetPassword: false,
  resetPasswordIsLoading: false,
  passwordUpdated: false,
  resetPasswordError: '',
  resetPasswordViolations: null,

} 

export default {
  [types.USER_PROFILE_RESET] (state) {
    window.localStorage.removeItem('sessionData');
    Object.assign(state, {
      ...initialState,
      sessionData: null,
    })
  },

  [types.USER_PROFILE_SET_ERROR] (state, error) {
    Object.assign(state, { error: error,
      isLoadingSession: false,
      isLoadingProjects: false ,
      isLoadingStats: false,
      isLoadingLocations: false,
    })
  },

  [types.USER_PROFILE_SET_RETRIEVED] (state, sessionData) {


    Object.assign(state, {
      sessionData: sessionData,
      isLoadingSession: false,
      error: ''
    })
    localStorage.setItem('sessionData', JSON.stringify(sessionData));
  },

  [types.USER_PROFILE_SET_STATS] (state, stats) {
    Object.assign(state, { stats: stats,
      isLoadingStats: false 
    })
  },

  [types.USER_PROFILE_SET_PROJECTS] (state, projects) {
    Object.assign(state, { projects: projects,
      isLoadingProjects: false
    })
  },

  [types.USER_PROFILE_SET_ALL_PROJECTS] (state, allProjects) {
    Object.assign(state, { allProjects: allProjects,
      isLoadingProjects: false 
    })
  },

  [types.USER_PROFILE_SET_LOCATIONS] (state, locations) {
    Object.assign(state, { 
      locations: locations,
      isLoadingLocations: false,
    })
  },
  [types.USER_PROFILE_LOCATIONS_LOADING] (state) {
    Object.assign(state, { isLoadingLocations: true })
  },


  [types.USER_PROFILE_SET_OBSERVATIONS] (state, observations) {
    Object.assign(state, { 
      observations: observations,
      isLoadingObservations: false,
    })
  },
  [types.USER_PROFILE_OBSERVATIONS_LOADING] (state) {
    Object.assign(state, { isLoadingObservations: true })
  },



  [types.USER_PROFILE_UPDATE_LOADING] (state) {
    Object.assign(state, { isLoadingUpdate: true })
  },

  [types.USER_PROFILE_PROJECT_LOADING] (state) {
    Object.assign(state, { isLoadingProjects: true })
  },

  [types.USER_PROFILE_STATS_LOADING] (state) {
    Object.assign(state, { isLoadingStats: true })
  },

  [types.USER_PROFILE_READ_LOADING] (state) {
    Object.assign(state, { isLoadingSession: true })
  },

  [types.USER_PROFILE_TOGGLE_EDITING] (state, status = null) {
    Object.assign(state, { isEditingProfile: (status !== null ? status : !state.isEditingProfile) })
  },

  [types.USER_PROFILE_UPDATE_VIOLATIONS] (state, violations) {
    Object.assign(state, { 
      violations: violations,
      isLoadingUpdate: false
    })
  },


  [types.USER_PROFILE_PICTURE_UPLOADING] (state) {
    Object.assign(state, {
      isPictureUploading: true,
      pictureError: ''
    })
  },
  [types.USER_PROFILE_PICTURE_REMOVING] (state) {
    Object.assign(state, {
      isPictureRemoving: true,
      pictureError: ''
    })
  },
  [types.USER_PROFILE_PICTURE_SET_RETRIEVED] (state, newPicture) {
    Object.assign(state, {
      ...state,
      isPictureUploading: false,
      isPictureRemoving: false,
      pictureError: '',
      sessionData: {
        ...state.sessionData,
        picture: newPicture
      }
    })
    localStorage.setItem('sessionData', JSON.stringify(state.sessionData));
  },

  [types.USER_PROFILE_PICTURE_SET_ERROR] (state, pictureError) {
    Object.assign(state, { 
      isPictureUploading: false,
      isPictureRemoving: false,
      pictureError: pictureError
    })
  },



  [types.USER_PROFILE_TOGGLE_RESET_PASSWORD] (state, status = null) {
    Object.assign(state, { resetPassword: (status !== null ? status : !state.resetPassword) })
  },

  [types.USER_PROFILE_TOGGLE_RESET_PASSWORD_LOADING] (state) {
    Object.assign(state, { resetPasswordIsLoading: !state.resetPasswordIsLoading })
  },

  [types.USER_PROFILE_RESET_PASSWORD_SET_UPDATED] (state, passwordUpdated) {
    Object.assign(state, { passwordUpdated })
  },

  [types.USER_PROFILE_RESET_PASSWORD_SET_ERROR] (state, resetPasswordError) {
    Object.assign(state, { resetPasswordError })
  },

  [types.USER_PROFILE_RESET_PASSWORD_SET_VIOLATIONS] (state, resetPasswordViolations) {
    Object.assign(state, { resetPasswordViolations })
  },
}