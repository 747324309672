/**
 * @function dateForServer:
 * @param dateValue in DD/MM/YYYY h:mm A format
 * @returns {Date}
 */

const mimeTypeMap = {
  'image/jpeg': 'image',
  'image/png': 'image',
  'image/gif': 'image',
  'image/x-ms-bmp': 'image',
  'image/webp': 'image',
  'image/tiff': 'image',
  'image/heic': 'image',
  'image/heif': 'image',
  'application/pdf': 'pdf',
  'application/msword': 'word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'document',
  'application/vnd.ms-excel': 'excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'spreadsheet',
  'application/vnd.ms-powerpoint': 'powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'slides',
  'application/zip': 'archive',
  'application/x-tar': 'archive',
  'text/plain': 'text',
  'text/csv': 'csv',
}

const mimeTypeIconMap = {
  'image': 'fa-picture-o',
  'pdf': 'fa-file-pdf-o',
  'word': 'fa-file-word-o',
  'document': 'fa-file-word-o',
  'excel': 'fa-file-excel-o',
  'spreadsheet': 'fa-file-excel-o',
  'powerpoint': 'fa-file-powerpoint-',
  'slides': 'fa-file-powerpoint-o',
  'archive': 'file-archive-o',
  'text': 'fa-file-text-o',
  'csv': 'fa-file-csv',
}

export const convertMimeType = (mimeType) => {
  return mimeTypeMap[mimeType]
}

export const convertMimeTypeIcon = (mimeType) => {
  return mimeTypeIconMap[convertMimeType(mimeType)]
}
