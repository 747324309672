<template lang="">
<l-layer-group ref="myLocation">
    <LMarker v-if='current.lat && current.lng' :lat-lng="current" :icon="icon" ref="marker" v-on:click="clickMarker">
    </LMarker>
    <LCircle
      :lat-lng="current"
      :radius="geoLocation.accuracy"
      :opacity="accuracyOpacity"
      :fillOpacity="accuracyOpacity"
    ></LCircle>
    <LPolyline 
    v-if='history.length > 0'
     :lat-lngs="history"
    :lineColor="lineColor"
     :fillOpacity="historyOpacity"
     ></LPolyline>
</l-layer-group>
</template>

<script>
import {
    LMarker,
    LIcon,
    LPolyline,
    LLayerGroup,
    LCircle,
} from "vue2-leaflet";
import {
    latLng,
    divIcon,
    point
} from "leaflet";
import {
    mapGetters,
    mapMutations
} from "vuex";

export default {
    components: {
        LMarker,
        LIcon,
        LPolyline,
        LLayerGroup,
        LCircle
    },
    data() {
        return {
            options: {
                autoPanPaddingTopLeft: [160, 20],
                autoPanPaddingBottomRight: [20, 20],
            },
            lineColor: "#666666",
            historyOpacity: 0.8,
            accuracyOpacity: 0.1,
        };
    },

    computed: {
        ...mapGetters({
            geoLocationHistory: "geoLocationHistory",
            geoLocation: "geoLocation",
        }),
        current: function () {
            return {
                lat: this.geoLocation.latitude,
                lng: this.geoLocation.longitude
            }

        },
        history: function () {
            return this.geoLocationHistory.map((geo) => {
                return {
                    lat: geo.latitude,
                    lng: geo.longitude
                }

            })
        },
        icon: function () {
            const icon = divIcon({
                className: "",
                iconAnchor: [10, 10],
                iconSize: [20, 20],
                html: `<i class='fa fa-2x fa-location-arrow text-primary'></i>`,
            });
            return icon;
        },
    },
    methods: {
        clickMarker: function () {
            return;
        },
    },
};
</script>

<style lang="scss"></style>
