<template>
  <div>
    <v-alert
      :value="!!retrievedOrganismsError"
      type="error"
    >
      {{ retrievedOrganismsError }}
    </v-alert>

    <v-layout
      v-if="tableName === 'add-organisms-table' || tableName === 'location-picklist-table' || tableName === 'species-table'"
      :class="[
        'mb-4 modal-toolbar-section',
        tableName === 'add-organisms-table' || tableName === 'species-table' ? 'mt-2' : '',
      ]"
      row
      wrap
    >
      <v-flex
        class="add-organisms-modal-search-field mt-2"
        xs6
      >
        <v-form @submit.prevent="onSearchOrganism">
          <v-toolbar
            color="white"
            dense
            extense
          >
            <v-text-field
              v-model="search"
              label="Search"
              color="#00AAEF"
              single-line
              hide-details
              full-width
              @input="v => { search = v.toLowerCase()}"
            />
            <v-btn
              type="submit"
              icon
            >
              <v-icon>search</v-icon>
            </v-btn>
          </v-toolbar>
        </v-form>
      </v-flex>

      <v-flex
        v-if="tableName === 'add-organisms-table'"
        class="add-organisms-modal-right-buttons"
        xs6
      >
        <v-btn flat :ripple="false" @click.native="onToggleAllSpecies" :class="selectedAllSpecies ? 'add-organisms-button-active' : ''">Select All</v-btn>
        <v-btn flat :ripple="false" @click.native="onShowSelectedSpecies" :class="isActiveShowSelectedLink ? 'add-organisms-button-active' : ''">Show/Edit Selected</v-btn>
      </v-flex>
    </v-layout>

    <div v-if="isActiveShowSelectedLink" class="text-xs-center mb-2">
      <v-btn
        class="material-link-button"
        @click.native="onShowSelectedSpecies"
        :ripple="false"
        flat
      >
        Return to Search
      </v-btn>
    </div>

    <v-data-table
      v-model="selectedRows"
      :headers="headers"
      :items="itemValues"
      :total-items="addTableTotalItems ? totalItems : null"
      :pagination.sync="pagination"
      :search="tableName !== 'add-organisms-table' && tableName !== 'species-table' ? search : null"
      item-key="scientificName"
      select-all
      :hide-actions="hideTableActions"
    >
      <template slot="headers" slot-scope="props">
        <tr v-if="tableName === 'dc-edit-mode-list-species-table'">
          <th
            v-for="header in props.headers"
            :key="header.text"
            class="column text-uppercase"
          >
            {{ header.text }}
          </th>

          <th
            key="Rank"
            class="column text-uppercase"
          >
            Rank
          </th>
        </tr>
        <tr v-else-if="tableName === 'add-organisms-table' || tableName === 'location-picklist-table' || tableName === 'species-table'" class="add-organisms-table-header">
          <th
            v-for="header in props.headers"
            :key="header.text"
            :class="[
              'column text-uppercase',
              header.sortable ? 'sortable' : '',
              pagination.descending && header.sortable ? 'desc' : 'asc',
              header.value === pagination.sortBy && header.sortable ? 'active' : ''
            ]"
            @click="header.sortable ? changeSort(header.value) : null"
          >
            <v-icon v-if="header.sortable">arrow_drop_down</v-icon>
            {{ header.text }}
          </th>

          <th
            v-if="(!isActiveShowSelectedLink && tableName === 'add-organisms-table') || tableName === 'species-table'"
            key="Rank"
            :class="[
              'column sortable text-uppercase',
              pagination.descending ? 'desc' : 'asc',
              'rank' === pagination.sortBy ? 'active' : ''
            ]"
            @click="changeSort('rank')"
          >
            <v-icon>arrow_drop_down</v-icon>
            Rank
          </th>

          <th
            v-if="isActiveShowSelectedLink"
            key="Appearance on datasheet"
            :class="[
              'column sortable text-uppercase',
              pagination.descending ? 'desc' : 'asc',
              'customName' === pagination.sortBy ? 'active' : ''
            ]"
            @click="changeSort('customName')"
          >
            <v-icon>arrow_drop_down</v-icon>
            Appearance on datasheet
          </th>

          <th class="column options-column text-uppercase">
            Options
          </th>
        </tr>
      </template>

      <template slot="items" slot-scope="props">
        <tr v-if="tableName === 'dc-edit-mode-list-species-table'">
          <td class="add-organisms-table-scientific-name-column">
            <span class="item-details">{{ props.item.scientificName }}</span>
          </td>
          <td>
            <span class="item-details">{{ props.item.canonicalName }}</span>
          </td>
          <td class="add-organisms-table-rank-column">
            <span class="item-details">{{ props.item.rank || '&nbsp;' }}</span>
          </td>
        </tr>

        <tr v-else-if="tableName === 'add-organisms-table' || tableName === 'species-table'" :active="props.selected">
          <td @click="selectSpeciesRow(props)" class="add-organisms-table-scientific-name-column">
            <span class="item-details">{{ props.item.scientificName }}</span>
          </td>
          <td @click="selectSpeciesRow(props)">
            <span class="item-details"> {{ props.item.canonicalName }}</span>
          </td>
          <td v-if="!isActiveShowSelectedLink" @click="selectSpeciesRow(props)" class="add-organisms-table-rank-column">
            <span class="item-details">{{ props.item.rank || '&nbsp;' }}</span>
          </td>
          <td v-if="isActiveShowSelectedLink" class="add-organisms-table-appearance-on-datasheet-column">
            <span class="item-details">
              <v-text-field
                class="outlined-text-field"
                v-model="props.item.customName"
                single-line
                outline
                :height="40"
              />
            </span>
          </td>
          <td @click="selectSpeciesRow(props)" class="add-organisms-table-options-icon">
            <span class="item-details">
              <v-checkbox primary
                          hide-details
                          v-model="props.selected"
                          :on-icon="isActiveShowSelectedLink ? 'cancel' : 'check_circle'"
                          off-icon="radio_button_unchecked"
                          disabled />
            </span>
          </td>
        </tr>

        <tr v-else-if="tableName === 'location-picklist-table'">
          <td class="add-organisms-table-appearance-on-datasheet-column locations-table-columns">
            <span class="item-details">{{ props.item.name }}</span>
          </td>
          <td class="add-organisms-table-appearance-on-datasheet-column locations-table-columns">
            <span class="item-details">{{ props.item.latitude }}</span>
          </td>
          <td class="add-organisms-table-appearance-on-datasheet-column locations-table-columns">
            <span class="item-details">{{ props.item.longitude }}</span>
          </td>
          <td class="add-locations-table-options">
            <span class="item-details locations-table-options-column">
              <button class='btn btn-danger'
                @click="removeLocation(props.index)"

              >
                <i class='fa fa-trash'></i>
              </button>
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-flex v-if="pages && tableName !== 'location-picklist-table'" xs12 class="text-xs-center pt-4 add-organisms-table-pagination">
      <v-pagination v-model="pagination.page" :length="pages" :total-visible="1"></v-pagination>
    </v-flex>

    <vue-modal
      :dialog="addOrganismsMaximumSpeciesDialog"
      dialog-title="Warning Message"
      :handle-submit="onToggleAllSpecies"
      @interface="addOrganismsMaximumSpeciesDialog = $event"
    >
      <template slot="body">
        You selected already 100 species. Please consider to use the "all organism picklist" if you need more.
      </template>
    </vue-modal>

    <vue-modal
      :dialog="addOrganismsCustomSelectionDialog"
      dialog-title="Warning Message"
      :handle-submit="onToggleAllSpecies"
      @interface="addOrganismsCustomSelectionDialog = $event"
    >
      <template slot="body">
        Do you want to use a custom selection?
      </template>
    </vue-modal>

    <vue-modal
      :dialog="isOpenLocationModal"
      @interface="isOpenLocationModal = $event"
      dialog-title="Edit Location"
      :handle-submit="updateLocationData"
      agree-button-text="Edit"
      disagree-button-text="Cancel"
      :max-width="600"
      :is-loading="isLoading"
    >
      <template slot="body">
        <v-alert
          :value="locationErrorMsg"
          type="error"
        >
          {{ locationErrorMsg }}
        </v-alert>

        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex xs12 sm6 md4>
              <v-text-field v-model.trim="editedLocation.name" label="Location name"></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-text-field v-model.number.trim="editedLocation.latitude" label="Latitude"></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md4>
              <v-text-field v-model.number.trim="editedLocation.longitude" label="Longitude"></v-text-field>
            </v-flex>
          </v-layout>
        </v-container>
      </template>
    </vue-modal>

    <VueLoader
      v-if="retrievedOrganismsIsLoading "
    />
  </div>
</template>

<script>
  import VueModal from './Modal'
  import VueLoader from './Loader'

  export default {
    components: {
      VueModal,
      VueLoader
    },

    props: {
      tableName: {
        default: ''
      },

      headers: {
        type: Array,
        default: () => []
      },

      items: {
        type: Array,
        default: () =>  []
      },

      selected: {
        type: Array,
        default: () => []
      },

      retrievedOrganismsView: {
        type: Object,
        default: () => {}
      },

      retrievedOrganismsError: {
        type: String,
        default: ''
      },

      getData: {
        type: Function,
        default: () => {}
      },

      addedSpeciesToOrganismRecord: {
        type: Object,
        default: () => {}
      },

      activeRecord: {
        type: Object,
        default: () => {}
      },

      allItems: {
        type: Array,
        default: () => []
      },

      speciesNextPageInfo: {
        type: Object,
        default: () => {}
      },

      retrievedOrganismsIsLoading: {
        type: Boolean,
        default: false
      },

      modalIsDisplayed: {
        type: Boolean,
        default: false
      },

      addOrganismsModalAllSpeciesSelected: {
        type: Boolean,
        default: false
      },

      addTableTotalItems: {
        type: Boolean,
        default: true
      },

      hideTableActions: {
        type: Boolean,
        default: true
      },

      updateLocation: {
        type: Function,
        default: () => {}
      },

      removeLocation: {
        type: Function,
        default: () => {}
      },

      locationErrorMessage: {
        type: String,
        default: ''
      },

      editedLocationIndex: {
        type: Number,
        default: -1
      },

      isOpenEditLocationModal: {
        type: Boolean,
        default: false
      },

      isLoading: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        search: '',
        selectedArray: this.selected,
        itemValues: this.items,
        pagination: {
          sortBy: 'scientificName',
          triggerPagination: false
        },
        totalItems: this.items.length,
        perPageItems: this.items.length,
        addOrganismsMaximumSpeciesDialog: false,
        addOrganismsCustomSelectionDialog: false,
        allOrganismsSelectedExceptFor: [],
        isActiveShowSelectedLink: false,
        selectedAllSpeciesValue: this.addOrganismsModalAllSpeciesSelected,
        editedLocation: {
          name: '',
          latitude: '',
          longitude: ''
        },
        locationIndex: this.editedLocationIndex,
        openLocationModal: this.isOpenEditLocationModal,
        locationErrMsg: this.locationErrorMessage
      }
    },

    watch: {
      isOpenEditLocationModal (val) {
        if (!val) {
          this.isOpenLocationModal = false
        }
      },

      locationErrorMessage (val) {
        this.locationErrorMsg = val
      },

      items (val) {
        this.itemValues = val

        if (this.itemValues.length) {
          this.perPageItems = this.itemValues.length
        }

        if (this.selectedAllSpecies) {
          this.selectedRows = this.itemValues
        }
      },

      retrievedOrganismsView (val) {
        if (val['hydra:next']) {
          this.totalItems = this.items.length * this.pagination.page + 1
        }
      },

      speciesNextPageInfo (val) {
        if (val['hydra:next']) {
          this.pagination.page = parseInt(val['@id'].split("page=")[1])
          this.perPageItems = 30
          this.totalItems = this.perPageItems * this.pagination.page + 1
        }
      },

      addedSpeciesToOrganismRecord (val) {
        if (!val) {
          return
        }

        if (val.allowAnyOrganism) {
          this.selectedAllSpecies = true
          this.selectedRows = this.items

          if (!this.isActiveShowSelectedLink && !this.selectedAllSpecies) {
            this.isActiveShowSelectedLink = true
            this.itemValues = this.selectedRows
            this.totalItems = this.selectedRows.length
          } else {
            this.pagination.page = 1
            this.itemValues = this.items
            this.totalItems = this.items.length * this.pagination.page + 1
            if (this.isActiveShowSelectedLink) {
              if (this.pagination.page && !isNaN(this.pagination.page)) {
                this.getData(this.pagination)
              }
              this.isActiveShowSelectedLink = false
            }
          }
        } else {
          this.selectedAllSpecies = false
          this.selectedRows = []
          setTimeout(() => {
            if (this.isActiveShowSelectedLink) {
              this.isActiveShowSelectedLink = false
              this.onShowSelectedSpecies()
            }
          }, 50)
        }
      },

      activeRecord () {
        this.pagination.page = 1
        this.pagination.triggerPagination = !this.pagination.triggerPagination
      },

      allItems (allItems) {
        this.itemValues = allItems
        this.perPageItems = allItems.length
      },

      pagination: {
        handler () {
          const { descending, page, sortBy } = this.pagination

          if (this.tableName === 'add-organisms-table' || this.tableName === 'species-table') {
            if (!this.isActiveShowSelectedLink) {
              if (page && !isNaN(page)) {
                this.getData({
                  descending: descending,
                  page: page,
                  sortBy: sortBy,
                  search: this.search
                })
              }
            } else {
              let itemValues = this.selectedRows.filter(el =>
                (el.canonicalName && el.canonicalName.toLowerCase().indexOf(this.search.toLowerCase()) > -1) ||
                (el.customName && el.customName.toLowerCase().indexOf(this.search.toLowerCase()) > -1) ||
                (el.scientificName && el.scientificName.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
              )

              itemValues = itemValues.sort((a, b) =>
                descending ? ((b[sortBy] > a[sortBy]) ? 1 : (a[sortBy] > b[sortBy]) ? -1 : 0) : ((a[sortBy] > b[sortBy]) ? 1 : (b[sortBy] > a[sortBy]) ? -1 : 0)
              )

              this.itemValues = itemValues.slice((page - 1) * this.perPageItems, page * this.perPageItems)
              this.totalItems = itemValues.length
            }

            if (document.querySelector(".add-organisms-scroll-top")) {
              document.querySelector(".add-organisms-scroll-top").scrollTo({
                top: 0,
                behavior: "smooth"
              })
            }

            if (this.activeRecord && this.activeRecord.allowAnyOrganism) {
              this.selectedAllSpecies = true
            }
          } else if (this.tableName === 'dc-edit-mode-list-species-table') {
            if (this.activeRecord) {
              if (this.activeRecord.allowAnyOrganism) {
                if (page && !isNaN(page)) {
                  this.getData(this.pagination)
                }
              } else {
                this.perPageItems = 30
                this.totalItems = this.items.length
                this.itemValues = this.$_.first(this.$_.rest(this.items, (page - 1) * this.perPageItems), this.perPageItems)
              }
            }
          }
        },
        deep: true
      }
    },

    computed: {
      pages () {
        if (this.perPageItems == null || this.totalItems == null)
          return 0

        return Math.ceil(this.totalItems / this.perPageItems)
      },

      selectedRows: {
        // getter
        get: function () {
          return this.selectedArray
        },
        // setter
        set: function (newValue) {
          this.selectedArray = newValue

          // handle data and give it back to parent
          this.$emit('interface', this.selectedRows)
        }
      },

      selectedAllSpecies: {
        // getter
        get: function () {
          return this.selectedAllSpeciesValue
        },
        // setter
        set: function (newValue) {
          this.selectedAllSpeciesValue = newValue

          // handle data and give it back to parent
          this.$emit('selectedAllSpecies', this.selectedAllSpecies)
        }
      },

      locationObjectIndex: {
        get: function () {
          return this.locationIndex
        },
        set: function (newValue) {
          this.locationIndex = newValue

          // handle data and give it back to parent
          this.$emit('editedLocationIndex', this.locationObjectIndex)
        }
      },

      isOpenLocationModal: {
        get: function () {
          return this.openLocationModal
        },
        set: function (newValue) {
          this.openLocationModal = newValue

          // handle data and give it back to parent
          this.$emit('isOpenEditLocationModal', this.isOpenLocationModal)
        }
      },

      locationErrorMsg: {
        get: function () {
          return this.locationErrMsg
        },
        set: function (newValue) {
          this.locationErrMsg = newValue
        }
      }
    },

    methods: {
      onSearchOrganism () {
        this.pagination.triggerPagination = !this.pagination.triggerPagination
        this.pagination.page = 1
      },

      onToggleAllSpecies () {
        this.addOrganismsMaximumSpeciesDialog = false
        this.addOrganismsCustomSelectionDialog = false

        if (!this.selectedAllSpecies) {
          this.selectedAllSpecies = true
          this.selectedRows = this.items

          if (!this.isActiveShowSelectedLink && !this.selectedAllSpecies) {
            this.isActiveShowSelectedLink = true
            this.itemValues = this.selectedRows
            this.totalItems = this.selectedRows.length
          } else {
            this.pagination.page = 1
            this.itemValues = this.items
            this.totalItems = this.items.length * this.pagination.page + 1
            if (this.isActiveShowSelectedLink) {
              if (this.pagination.page && !isNaN(this.pagination.page)) {
                this.getData(this.pagination)
              }
              this.isActiveShowSelectedLink = false
            }
          }
        } else {
          this.selectedAllSpecies = false
          this.selectedRows = []
          setTimeout(() => {
            if (this.isActiveShowSelectedLink) {
              this.isActiveShowSelectedLink = false
              this.onShowSelectedSpecies()
            }
          }, 50)
        }
      },

      onShowSelectedSpecies () {
        this.selectedRows.forEach(row => {
          if (!row.customName) {
            row.customName = (row.canonicalName && row.scientificName) ? (row.canonicalName + ' (' + row.scientificName + ')') : row.canonicalName ? row.canonicalName : row.scientificName ? row.scientificName : null
          }
        })

        // toggle the value of the isActiveShowSelectedLink
        this.isActiveShowSelectedLink = !this.isActiveShowSelectedLink

        // reset search form
        this.search = ''

        // reset pagination to default
        this.pagination.descending = false
        this.pagination.page = 1
        this.pagination.sortBy = 'scientificName'

        if (!this.selectedAllSpecies) {
          if (this.isActiveShowSelectedLink) {
            this.totalItems = this.selectedRows.length

            if (this.totalItems > 0) {
              this.pagination.page = 1
            }

            this.itemValues = this.$_.first(this.$_.rest(this.selectedRows, (this.pagination.page - 1) * this.perPageItems), this.perPageItems)
          } else {
            if (this.selectedRows.length > 0) {
              this.pagination.page = 1
            }

            this.pagination.triggerPagination = !this.pagination.triggerPagination
          }
        }
      },

      selectSpeciesRow (species) {
        if (this.tableName === "species-table") {
          species.selected = !species.selected
          this.selectedRows = []

          if (!species.selected) {
            this.selectedRows.push(species.item);
          }
        } else {
          if (!this.selectedAllSpecies) {
            if (this.selectedRows.length < 100 || !!species.selected) {
              species.selected = !species.selected

              const totalListedItems = this.itemValues.length * this.pagination.page

              if ((this.selectedRows.length === totalListedItems) && (totalListedItems >= this.totalItems)) {
                this.selectedAllSpecies = true
              } else {
                this.selectedAllSpecies = false
              }

              setTimeout(() => {
                if (this.isActiveShowSelectedLink) {
                  this.isActiveShowSelectedLink = false
                  this.onShowSelectedSpecies()
                }
              }, 50)
            } else {
              this.addOrganismsMaximumSpeciesDialog = true
            }
          } else {
            this.addOrganismsCustomSelectionDialog = true
          }
        }
      },

      changeSort (column) {
        if (this.pagination.sortBy === column) {
          this.pagination.descending = !this.pagination.descending
        } else {
          this.pagination.sortBy = column
          this.pagination.descending = false
        }

        this.pagination.page = 1
      },

      openEditLocationPopup (location) {
        this.editedLocation = Object.assign({}, location)
        this.locationObjectIndex = this.itemValues.indexOf(location)
        this.locationErrorMsg = ""
        this.$emit('locationErrorMessage', this.locationErrorMsg)
        this.isOpenLocationModal = true
      },

      updateLocationData () {
        if (this.editedLocation.name && this.editedLocation.latitude && this.editedLocation.longitude) {
          this.updateLocation(this.editedLocation)
        } else {
          this.locationErrorMsg = "Location name, latitude and longitude are required."
          this.$emit('locationErrorMessage', this.locationErrorMsg)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .add-organisms-modal-search-field /deep/ {
    padding-left: 35px;

    .v-toolbar .v-toolbar__content {
      height: 38px !important;
    }

    .v-label,
    input {
      color: #BFBFBF;
    }
  }

  .add-organisms-modal-right-buttons {
    padding-right: 27px;

    .v-btn {
      border-bottom: 4px solid rgba(191, 191, 191, 0.3);
      color: #BFBFBF;
      top: 7px;
      float: right;
      font-size: 16px;
      font-weight: bold;

      &:hover {
        color: rgba(0, 170, 239, 0.5);
        border-color: rgba(0, 170, 239, 0.5);
      }

      &.add-organisms-button-active {
        color: rgba(0, 170, 239, 1);
        border-color: rgba(0, 170, 239, 1);
      }
    }

    .v-btn--active:before,
    .v-btn:focus:before,
    .v-btn:hover:before {
      background-color: transparent;
    }
  }

  .add-organisms-table-pagination /deep/ .v-pagination {
    .v-pagination__item,
    .v-pagination__more {
      display: none;
    }

    .v-pagination__item {
      &.primary {
        background-color: rgb(0, 170, 239) !important;
        border-color: rgb(0, 170, 239) !important;
      }
    }
  }

  #custom-vuetify-style .add-organisms-table-appearance-on-datasheet-column .item-details /deep/ {
    padding-top: 3px !important;
    padding-bottom: 3px !important;

    .v-text-field .v-input__control .v-input__slot {
      background: #ffffff !important;
    }
  }

  .add-locations-table-options .v-icon {
    float: right;
    padding: 14px 0;
  }

  .add-organisms-table .locations-table-columns .item-details {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .locations-table-options-column {
    min-height: 60px;
  }

  .material-link-button.v-btn {
    color: rgb(0, 170, 239);
    font-size: 16px;
    padding: 0 4px;
    height: 22px;

    &:hover {
      border-bottom: 1px solid rgb(0, 170, 239);
    }

    /deep/ .v-btn__content {
      text-transform: none;
    }
  }

  .material-link-button.v-btn--active:before,
  .material-link-button.v-btn:focus:before,
  .material-link-button.v-btn:hover:before {
    background-color: transparent;
  }
</style>
