export const OBSERVATION_CREATE_SET_CREATED = 'OBSERVATION_CREATE_SET_CREATED'
export const DATASHEET_CREATE_SET_ERROR = 'DATASHEET_CREATE_SET_ERROR'
export const DATASHEET_CREATE_SET_VIOLATIONS = 'DATASHEET_CREATE_SET_VIOLATIONS'
export const DATASHEET_CREATE_TOGGLE_LOADING = 'DATASHEET_CREATE_TOGGLE_LOADING'
export const MEMBERS_ASSIGNED_LOCATIONS_RETRIEVE_SET_RETRIEVED = 'MEMBERS_ASSIGNED_LOCATIONS_RETRIEVE_SET_RETRIEVED'
export const MEMBERS_ASSIGNED_LOCATIONS_RETRIEVE_TOGGLE_LOADING = 'MEMBERS_ASSIGNED_LOCATIONS_RETRIEVE_TOGGLE_LOADING'
export const MEMBERS_ASSIGNED_LOCATIONS_RETRIEVE_SET_ERROR = 'MEMBERS_ASSIGNED_LOCATIONS_RETRIEVE_SET_ERROR'
export const LOCATION_CREATE_SET_ERROR = 'LOCATION_CREATE_SET_ERROR'
export const LOCATION_CREATE_TOGGLE_LOADING = 'LOCATION_CREATE_TOGGLE_LOADING'
export const LOCATION_CREATE_SET_CREATED = 'LOCATION_CREATE_SET_CREATED'
export const LOCATION_CREATE_SET_VIOLATIONS = 'LOCATION_CREATE_SET_VIOLATIONS'
