
<template src="../../templates/datasheet/recordEdit.html"></template>

<script>
import VueDraggable from "vuedraggable";
import OrganismSelect from "./OrganismSelect";

export default {
  components: {
    VueDraggable,
    OrganismSelect,
  },
  props: {
    record: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      customLength: this.record.validators.some((v) => {
        return v.validatorType === "min" || v.validatorType === "max";
      }),
    };
  },

  computed: {
    isRequired: {
      get() {
        const isRequired = this.record.validators.some((v) => {
          return v.validatorType === "isRequired";
        });
        return isRequired;
      },
      set(value) {
        if (value) {
          const validators = [
            ...this.record.validators,
            {
              validatorType: "isRequired",
              value: "true",
            },
          ];
          this.record.validators = validators;
        } else {
          const validators = this.record.validators.filter((validator) => {
            return validator.validatorType !== "isRequired";
          });
          this.record.validators = validators;
        }
        this.$emit("updateRecord", this.record);
      },
    },
    min: {
      get() {
        const validator = this.record.validators.find((v) => {
          return v.validatorType === "min";
        });
        return validator ? validator.value : "";
      },
      set(value) {
        // clear out existing validator
        let validators = this.record.validators.filter((validator) => {
          return validator.validatorType !== "min";
        });
        // add value if set
        if (value) {
          validators = [
            ...validators,
            {
              validatorType: "min",
              value: value,
            },
          ];
        }
        this.record.validators = validators;
        this.$emit("updateRecord", this.record);
      },
    },
    max: {
      get() {
        const validator = this.record.validators.find((v) => {
          return v.validatorType === "max";
        });
        return validator ? validator.value : "";
      },
      set(value) {
        // clear out existing validator
        let validators = this.record.validators.filter((validator) => {
          return validator.validatorType !== "max";
        });
        // add value if set
        if (value) {
          validators = [
            ...validators,
            {
              validatorType: "max",
              value: value,
            },
          ];
        }
        this.record.validators = validators;
        this.$emit("updateRecord", this.record);
      },
    },
  },

  methods: {
    updateRecord(record){
        this.$emit("updateRecord", record);
    },
    setIsRequired(value) {},
    setMin(value) {},
    removeMin() {},
    setMax(value) {},
    removeMax() {},

    addOption(value) {},
    removeOption(value) {},
    updateOptions(value) {},

    //updateRecordAllowReplica="onUpdateRecordAllowReplica"
    //isDatasheetRecordEdited="isDatasheetRecordEdited"
    //addRecordOption="onAddRecordOption"
    //removeRecordOption="onRemoveRecordOption"
    //updateRecordOptions="onUpdateRecordOptions"
    //updateRecordRequiredValidator="onUpdateRecordRequiredValidator"
    //updateRecordIntegerValidator="onUpdateRecordIntegerValidator"
    //updateRecordDefaultOptionValue="onUpdateRecordDefaultOptionValue"
    //updateRecordMinLengthValidator="onUpdateRecordMinLengthValidator"
    //updateRecordMaxLengthValidator="onUpdateRecordMaxLengthValidator"
    setCustomLength(value) {
      this.customLength = value;
      if (!value) {
        this.min = false;
        this.max = false;
      } else {
        this.min = "0";
        this.max = "100";
      }
    },

    addRecordOption (orderNumber) {
      this.record.optionsSet.optionsValues.splice(orderNumber, 0, {
        "value": "Choice " + (this.record.optionsSet.optionsValues.length + 1),
        "dataType": "text",
        "orderNumber": orderNumber + 1
      })

      const defaultOption = this.$_.find(this.record.optionsSet.optionsValues, value => { return value.isDefault })

      if (defaultOption) {
        const defaultOptionOrderNumber = defaultOption.orderNumber

        if (orderNumber < defaultOptionOrderNumber) {
          this.dRecordObject.defaultOption = defaultOption.orderNumber.orderNumber + 1
        }
      }

      let valueOrderNumber = 1
      this.record.optionsSet.optionsValues.forEach(value => {
        value.orderNumber = valueOrderNumber++
      })

            this.isEditedValue = true

    },

    removeRecordOption (orderNumber) {
      if (this.record.optionsSet.optionsValues[orderNumber - 1].isDefault) {
        this.dRecordObject.defaultOption = null
      }

      this.record.optionsSet.optionsValues.splice(orderNumber - 1, 1)

      const defaultOption = this.$_.find(this.record.optionsSet.optionsValues, value => { return value.isDefault })

      if (defaultOption) {
        const defaultOptionOrderNumber = defaultOption.orderNumber

        if (orderNumber < defaultOptionOrderNumber) {
          this.dRecordObject.defaultOption = defaultOption.orderNumber - 1
        }
      }

      let valueOrderNumber = 1
      this.record.optionsSet.optionsValues.forEach(value => {
        value.orderNumber = valueOrderNumber++
      })

            this.isEditedValue = true
    },
    chooseDefault(defaultIndex){
      this.record.optionsSet.optionsValues = this.record.optionsSet.optionsValues.map((option)=>{
        return {
          ...option,
          isDefault: (defaultIndex === option.orderNumber)
        }
      })
      this.$emit("updateRecord", this.record);
    },
    updateRecordOptions (event) {
      // update order
      // this is triggered by any update to the list, including add / remove
      this.record.optionsSet.optionsValues= this.renumberOptions(this.record.optionsSet.optionsValues);
    },
    renumberOptions(options){
      return options.map((option, index)=>{
        return {
          ...option,
          orderNumber: index + 1
        }
      })
    },

  },
  watch: {
    'record.defaultOption': function(value){
      this.chooseDefault(value);
    }
  },
  created() {},
};
</script>
<style scoped>
.v-icon {
  margin-top: 12px;
}
.datasheet-record-edit-mode-file-max-size {
  color: #bc7610;
}
</style>