<template>
  <div>
    <v-tabs slot="extension"
            v-model="activeTabValue"
            grow
            icons-and-text>
      <v-tabs-slider :color="sliderColor"></v-tabs-slider>
      <v-tab
        v-for="(item, index) in items"
        :key="index"
        :id="item.tabId"
        :ripple="false"
      >
        <span class="tab-name">
          {{ item.tabName }}
        </span>
        <font-awesome-icon v-if="item.iconName" :icon="item.iconName" :rotation="item.iconRotation" class="tab-icon" />
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeTabValue">
      <v-tab-item v-for="(item, index) in items" :key="index">
        <slot :name="item.tabName" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<style lang="scss" scoped>

</style>

<script>
  export default {
    props: {
      items: {
        type: Array,
        required: true
      },

      sliderColor: {
        type: String,
        default: "#87983F"
      },

      activeTab: {
        type: Number,
        default: 0
      }
    },


    computed: {
      activeTabValue: {
        // getter
        get: function () {
          return this.activeTab
        },
        // setter
        set: function (newValue) {
          this.$emit('interface', newValue)
        }
      }
    }
  }
</script>
