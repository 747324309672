<template>
  <div>
    <div v-if="isPublicOrMember">
      <div class="card-body">
        <div class="row mb-3">
          <b-col cols="3">
            <h3 class="mb-0">Locations</h3>
          </b-col>

          <div class="input-group col-md-6">
            <b-input-group-prepend>
              <span
                class="btn btn-secondary"
                title="Start typing to search locations by name"
              >
                <i v-if="!isBusy" class="fas fa-search"></i>
                <i v-if="isBusy" class="fas fa-spinner fa-spin"></i>
              </span>
            </b-input-group-prepend>
            <b-form-input
              v-model="searchText"
              type="search"
              id="filterInputName"
              placeholder="Type to Search Locations by Name"
              debounce="4000"
            ></b-form-input>
            <div class="input-group-append" v-if="searchText">
              <b-button @click="searchText = ''"
                ><i class="fas fa-times-circle"></i
              ></b-button>
            </div>
          </div>

          <b-col>
            <div
              class="flex-shrink-1 text-right"
              v-if="isManager"
            >
              <button class="btn btn-primary" @click="onCreateLocation">
                <i class="fa fa-plus text-white"></i>
                New Location
              </button>
            </div>
          </b-col>
        </div>
        <div class="row justify-content-between">
          <b-col md="3" class="my-1">
            Showing {{ fromItem }} to {{ toItem }} of
            {{ totalItems }} location<span
              v-if="totalItems === 0 || totalItems > 1"
              >s</span
            >
          </b-col>

          <b-col sm="2" md="3" class="my-1">
            <b-pagination
              :total-rows="totalItems"
              :per-page="perPage"
              v-model="currentPage"
              class="my-0 pagination-holder"
              prev-text="Prev"
              next-text="Next"
              align="fill"
            />
          </b-col>

          <b-col sm="2" md="3" class="my-1">
            <b-form-group
              label="Per Page"
              label-align="right"
              label-cols-sm="7"
              label-cols-md="8"
              label-for="perPageSelect"
              class="mb-0"
            >
              <b-form-select
                v-model="perPage"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
                align="right"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
        </div>
      </div>

      <b-table
        show-empty
        stacked="md"
        id="projectLocationsTable"
        ref="projectLocationsTable"
        :items="projectLocationsProvider"
        :fields="fieldList"
        :current-page="currentPage"
        :per-page="perPage"
        sort-icon-left
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filterVal"
        @filtered="onFiltered"
        :empty-text="emptyText"
        :empty-filtered-text="emptyFilteredText"
      >
        <template v-slot:cell(options)="row">
          <div class="options-holder float-right">
            <router-link
              :to="{ name: 'LocationShow', params: { id: row.item.id } }"
            >
              <b-btn
                size=""
                class="my-1 mr-1"
                alt="View Location"
                title="View Location"
              >
                <font-awesome-icon icon="eye" /> View
              </b-btn>
            </router-link>

            <b-btn
              v-if="isManager"
              class="mt-1"
              @click="onClickDelete(row.item)"
              alt="Delete this Location from the Project"
              title="Delete this Location from the Project"
            >
              <i class="fa fa-trash"></i> Delete
            </b-btn>
          </div>
        </template>
        <template #table-busy>
          <VueLoader style="height: 200px" text="Loading Locations..." />
        </template>
      </b-table>

      <div class="card-footer">
        <div class="row justify-content-between">
          <b-col cols md="3" class="my-1">
            Showing {{ fromItem }} to {{ toItem }} of
            {{ totalItems }} location<span
              v-if="totalItems === 0 || totalItems > 1"
              >s</span
            >
          </b-col>

          <b-col sm="2" md="3" class="my-1">
            <b-pagination
              :total-rows="totalItems"
              :per-page="perPage"
              v-model="currentPage"
              class="my-0 pagination-holder"
              prev-text="Prev"
              next-text="Next"
              align="fill"
            />
          </b-col>

          <b-col sm="2" md="3" class="my-1">
            <b-form-group
              label="Per Page"
              label-align="right"
              label-cols-sm="7"
              label-cols-md="8"
              label-for="perPageSelect"
              class="mb-0"
            >
              <b-form-select
                v-model="perPage"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
                align="right"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
        </div>
      </div>

      <b-modal id="deleteLocationModal" title="Delete Location" centered>
        <div class="font-weight-bold">
          Deleting locations is temporarily unavailible. We are working on fixing
          this in a future update. This is on our development schedule.
        </div>
        <div v-if="false && selectedLocation">
          <p
            v-if="selectedLocation && selectedLocation.observationsTotal === 0"
            class="my-4"
          >
            Do you want to remove this Location from the project? This cannot be
            undone.
          </p>
          <p v-else class="my-4">
            This Location has Observations linked to it and cannot be deleted.
          </p>
          <div>
            <span class="font-weight-bold">Location Name: </span>
            {{ selectedLocation.name }}
          </div>
          <div>
            <span class="font-weight-bold">Latitude: </span>
            {{ selectedLocation.latitude }}
          </div>
          <div>
            <span class="font-weight-bold">Longitude: </span>
            {{ selectedLocation.longitude }}
          </div>
        </div>
        <template slot="modal-footer">
          <div>
            <b-btn
              variant="danger"
              class="d-none"
              :disabled="
                true ||
                  (selectedLocation && selectedLocation.observationsTotal > 0)
              "
              @click="onConfirmDelete()"
            >
              Yes, delete</b-btn
            >
            <b-btn @click="closeModal">Cancel</b-btn>
          </div>
        </template>
      </b-modal>

      <app-add-location-modal
        @add-locations="onAddLocations"
      ></app-add-location-modal>
    </div>
    <RequiresMember v-else page="Locations"></RequiresMember>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueLoader from "../../../layout/Loader.vue";
import RequiresMember from "../tabpermission/requiresMember.vue";

export default {
  name: "projectLocations",
  components: {
    RequiresMember,
    VueLoader
  },

  data() {
    return {
      pageOptions: [10, 25, 50, 100, 250, 500],
      isBusy: false,
      selectedLocation: null,

      emptyText: "No Project Locations Available",
      emptyFilteredText: "No Project Locations Available for Search Criteria",

      fieldList: [
        {
          key: "name",
          label: "Location Name",
          sortable: true,
          sortDirection: "asc",
          formatter: (val, fieldName, item) => {
            return item && item.name;
          }
        },
        {
          key: "latitude",
          label: "Latitude",
          sortable: true,
          sortDirection: "asc",
          class: "text-left",
          formatter: (val, fieldName, item) => {
            return item && item.latitude;
          }
        },
        {
          key: "longitude",
          label: "Longitude",
          sortable: true,
          sortDirection: "asc",
          class: "text-left",
          formatter: (val, fieldName, item) => {
            return item && item.longitude;
          }
        },
        {
          key: "observationsTotal",
          label: "# Observations",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
          tdClass: "text-center"
        },
        {
          key: "options",
          label: "Options",
          sortable: false,
          class: "text-right",
          tdClass: "text-right"
        }
      ]
    };
  },

  computed: {
    ...mapGetters({
      projectLocations: "project/show/projectLocations",
      isPublicOrMember: "project/get/isPublicOrMember",
      isManager: "project/get/isManager",
      projectId: "project/get/id",

      locationPage: "project/get/locationPage",
      locationItems: "project/get/locationItems",
      locationTotal: "project/get/locationTotal",
      locationSearch: "project/get/locationSearch",
      locationSortBy: "project/get/locationSortBy",
      locationSortDirection: "project/get/locationSortDirection",
      locationSortDesc: "project/get/locationSortDesc",
    }),
    currentPage: {
      get() {
        return this.locationPage
      },
      set(newValue) {
        this.setLocationTablePage(newValue)
      }
    },
    totalItems: {
      get() {
        return this.locationTotal
      },
      set(newValue) {
        this.setLocationTableTotal(newValue)
      }
    },
    perPage: {
      get() {
        return this.locationItems
      },
      set(newValue) {
        this.setLocationTableItems(newValue)
      }
    },
    searchText: {
      get() {
        return this.locationSearch
      },
      set(newValue) {
        this.setLocationTableSearch(newValue)
      }
    },
    sortBy:  {
      get() {
        return this.locationSortBy
      },
      set(newValue) {
        this.setLocationSortBy(newValue)
      }
    },
    sortDirection:  {
      get() {
        return this.locationSortDirection
      },
      set(newValue) {
        this.setLocationSortDirection(newValue)
      }
    },
    sortDesc: {
      get() {
        return this.locationSortDesc
      },
      set(newValue) {
        this.setLocationSortDesc(newValue)
      }
    },

    fromItem() {
      return this.totalItems > 0
        ? (this.currentPage - 1) * this.perPage + 1
        : 0;
    },
    toItem() {
      return this.totalItems < this.currentPage * this.perPage
        ? this.totalItems
        : this.currentPage * this.perPage;
    },

    filterVal: {
      get: function() {
        // Search by the location name
        let retVal = "";
        if (this.searchText) {
          retVal =
            retVal.length === 0
              ? "name[searchAny]=" + this.searchText
              : retVal + "&name[searchAny]=" + this.searchText;
        }
        return retVal;
      }
    }
  },

  methods: {
    ...mapActions({
      getProjectLocations: "project/show/getProjectLocations",

      setLocationTablePage: "project/get/setLocationTablePage",
      setLocationTableItems: "project/get/setLocationTableItems",
      setLocationTableTotal: "project/get/setLocationTableTotal",
      setLocationTableSearch: "project/get/setLocationTableSearch",
      setLocationSortBy: "project/get/setLocationTableSortBy",
      setLocationSortDirection: "project/get/setLocationTableSortDirection",
      setLocationSortDesc: "project/get/setLocationTableSortDesc",

      removeProjectLocation: "project/show/removeProjectLocation",
      createLocation: "datasheet/form/createLocation",
    }),

    projectLocationsProvider(ctx) {
      this.isBusy = true;
      let urlParams = `${this.urlBuilder(ctx)}`;
      const data = {
        id: this.projectId,
        urlParams
      };

      return this.getProjectLocations(data).then(data => {
        this.totalItems = data["hydra:totalItems"];
        this.currentPage = ctx.currentPage || 1;
        this.sortBy = ctx.sortBy || "";
        this.isBusy = false;
        return data["hydra:member"].map(locations => {
          return {
            ...locations
          };
        });
      });
    },

    /**
     * @function onFiltered
     * @param filteredItems
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      if (
        this.totalItems !== filteredItems.length &&
        filteredItems.length > this.perPage
      ) {
        this.totalItems = filteredItems.length;
        this.currentPage = 1;
      }
    },

    onCreateLocation() {
      // Show the create location modal
      this.$bvModal.show("add-location-modal");
    },

    // Handle return from create location modal
    onAddLocations(data) {
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("add-location-modal");
      });

      this.isBusy = true;

      let promises = [];
      data.forEach(val => {
        // Adding project IRI to link the location to this project
        val.project = "/projects/" + this.projectId;
        let promise = this.createLocation(val);
        promises.push(promise);
      });

      // wait till all request are complete <shows loading screen only one time>
      Promise.all(promises).then(values => {
        this.isBusy = false;
        this.$refs.projectLocationsTable.refresh();
        // Call parent to update stats
        this.$emit("update-stats", true);
      });
    },

    /**
     * @function onClickViewLocation
     * @param evt
     * @param locationData
     */
    onClickViewLocation(evt, locationData) {
      evt.preventDefault();
    },

    closeModal() {
      this.$bvModal.hide("deleteLocationModal");
      this.selectedLocation = null;
    },
    onClickDelete(location) {
      this.selectedLocation = {
        ...location
      };
      this.$bvModal.show("deleteLocationModal");
    },
    onConfirmDelete() {
      this.removeProjectLocation({
        id: this.selectedLocation.id
      }).then(() => {
        this.$refs.projectLocationsTable.refresh();
      });
      this.closeModal();
    }
  }
};
</script>

<style></style>
