<template>
  <div class="row mt-2 mb-2">
    <label class="h4 ml-3"><b>Popular Tags</b></label>
    <div class="col-lg-12 col-md-12 col-sm-12">
      <span v-if="popularTags.length === 0">
        No Tags Yet...
      </span>
      <div v-for="(tag, index) in popularTags" :key="index">
        <div class="row h5">
          <div class="col-md-auto">
            <b-button pill size="sm"
                      variant="tertiary"
                      data-toggle="tooltip"
                      data-placement="top"
                      :title="'Click to View ' + tag['count'] + ' Post' + (tag['count'] === '1' ? '' : 's')"
                      @click="handleTagClick(tag.tag)">
              <span><b>{{ tag['tag'] }} </b><b-badge pill variant="light">{{ tag['count'] }}</b-badge></span>
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from 'vuex';

export default {
  name: "popularTags",
  props: {},
  data() {
    return {};
  },
  watch: {},
  computed: {
    ...mapGetters({
      popularTags: "project/forum/popularTags"
    }),
  },
  methods: {
    handleTagClick(clickedTag) {
      this.$emit('popularTagClicked', clickedTag)
    }
  },
}
</script>

<style scoped lang="scss">

</style>
