<template>
    <div class="card mb-3">
        <div class="row no-gutters">
        
        <div class="col-md-2">
            <div class="side-img overflow-hidden">
                <!--https://images.pexels.com/photos/534151/pexels-photo-534151.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260-->
                <img :src="story.ImagePath ? story.ImagePath : './assets/images/no-project-image.jpg'" class="card-img h-100">
            </div>
        </div>
        <div class="col-md-10">
            <div class="card-body">
            <h5 class="card-title">
                <b-link :to="'/projects/'+story.urlTitle+''">
                    {{ story.Title }}
                </b-link>
            </h5>
            <p class="card-text">{{ story.Impact }}</p>
            <p class="card-text">
                <small class="text-muted">
                    <strong>Partners:</strong> {{ story.Partners }}
                    &nbsp;
                    <span v-if="story.publicationURL.length>1">
                        <strong>Publication:</strong> <b-link :href="story.publicationURL" target="NewWindow">publication</b-link>
                    </span>
                </small>
            </p>
            </div>
        </div>
        </div>
    </div>
</template>

<style>

.side-img {
    max-height:160px;
  }

</style>

<script>
    export default {
        props: { story: Object },
    }
</script>