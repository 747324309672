<template>
  <div v-if="projectId" class="mt-3 border-top border-dark pt-3">
    <div class="card-body">
      <div class="d-flex justify-content-between">
        <h3>Member Requests</h3>
      </div>
      <b-row align-h="between">
        <b-col md="3" class="my-1">
          Showing {{ fromItem }} to {{ toItem }} of {{ totalItems }} requests
        </b-col>

        <b-col sm="2" md="3" class="my-1">
          <b-pagination
            :total-rows="totalItems"
            :per-page="perPage"
            v-model="currentPage"
            class="my-0 pagination-holder"
            prev-text="Prev"
            next-text="Next"
            align="fill"
          />
        </b-col>

        <b-col sm="2" md="3" class="my-1">
          <b-form-group
            label="Per Page"
            label-align="right"
            label-cols-sm="7"
            label-cols-md="8"
            label-for="perPageSelect"
            class="mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
              align="right"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <b-table
      show-empty
      stacked="md"
      id="projectMembersTable"
      ref="projectMembersTable"
      :busy.sync="isLoading"
      :items="getMembers"
      :fields="tableFields"
      :current-page="currentPage"
      :per-page="perPage"
      sort-icon-left
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
    >
      <template v-slot:cell(options)="row">
        <div class="options-holder float-right">
          <b-btn
            size=""
            class="my-1 mr-1"
            @click="onApproveRequest(row.item)"
            alt="Approve Membership Request"
            title="Approve Membership Request"
          >
            <i class="fa fa-check"></i> Approve
          </b-btn>
          <b-btn
            size=""
            class="my-1 mr-1"
            @click="onRejectRequest(row.item)"
            alt="Reject Membership Request"
            title="Reject Membership Request"
          >
            <font-awesome-icon icon="times" /> Reject
          </b-btn>
        </div>
      </template>
      <template #table-busy>
        <VueLoader style="height: 200px" text="Loading Requests..." />
      </template>
      <template #empty>
        <h4 class="text-center">
          There are no pending requests to join this project
        </h4>
      </template>
    </b-table>
    <b-row class="mx-auto" align-h="between" v-if="projectMembers.length > 0">
      <b-col cols md="3" class="my-1">
        Showing {{ fromItem }} to {{ toItem }} of {{ totalItems }} requests
      </b-col>

      <b-col sm="2" md="3" class="my-1">
        <b-pagination
          :total-rows="totalItems"
          :per-page="perPage"
          v-model="currentPage"
          class="my-0 pagination-holder"
          prev-text="Prev"
          next-text="Next"
          align="fill"
        />
      </b-col>

      <b-col sm="2" md="3" class="my-1">
        <b-form-group
          label="Per Page"
          label-align="right"
          label-cols-sm="7"
          label-cols-md="8"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            align="right"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueLoader from "../../../layout/Loader.vue";

export default {
  name: "projectMembersPending",
  components: {
    VueLoader,
  },
  data() {
    return {
      pageOptions: [10, 25, 50, 100, 250, 500],
      isLoading: false,
      isLocal: false,
      showModal: null,
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      sortBy: "createdAt",
      sortDesc: false,
      sortDirection: "asc",
      projectMembers: [],
      tableFields: [
        // { key: "displayName", label: "Requestor Name" },
        {
          key: "firstName",
          label: "First Name",
          sortable: true,
          sortDirection: "asc"
        },
        {
          key: "lastName",
          label: "Last Name",
          sortable: true,
          sortDirection: "asc"
        },
        {
          key: "email",
          label: "Email Address",
          sortable: true,
          sortDirection: "asc"
        },
        {
          key: "createdAt",
          label: "Date Requested",
          formatter: val => {
            return this.displayDate(val);
          },
          sortable: true,
          sortDirection: "asc"
        },
        { key: "options", label: "Options", sortable: false, class: "text-right" }
      ]
    };
  },
  computed: {
    ...mapGetters({
      projectId: "project/get/id",
      membershipMap: "user/profile/membershipMap"
    }),
    fromItem() {
      return this.totalItems > 0
        ? (this.currentPage - 1) * this.perPage + 1
        : 0;
    },
    toItem() {
      return this.totalItems < this.currentPage * this.perPage
        ? this.totalItems
        : this.currentPage * this.perPage;
    }
  },
  methods: {
    ...mapActions({
      fetchProjectMembers: "project/show/fetchProjectMembers",
      deleteProjectMember: "project/show/deleteProjectMember",
      approveProjectMember: "project/show/approveProjectMember"
    }),

    getMembers(ctx) {
      if (this.isLocal) {
        // This fanciness is to modify to local pending members list without calling back to the server to get the entire list
        this.isLocal = false;
        return this.projectMembers;
      }

      // Handle sorting by nested properies
      switch (ctx.sortBy) {
        case "firstName":
          ctx.sortBy = "member.firstName";
          break;
        case "lastName":
          ctx.sortBy = "member.lastName";
          break;
        case "email":
          ctx.sortBy = "member.email";
          break;
      }

      let urlParams = `${this.urlBuilder(ctx)}&approved=false`;
      const data = { projectId: this.projectId, urlParams };
      return this.fetchProjectMembers(data).then(data => {
        this.totalItems = data["hydra:totalItems"];
        this.currentPage = ctx.currentPage || 1;
        // this.sortBy = ctx.sortBy || "";
        // this.sortDesc = ctx.sortDesc || "";
        this.isLoading = false;

        this.projectMembers = data["hydra:member"].map(member => {
          return {
            ...member
          };
        });
        return this.projectMembers;
      });
    },

    onApproveRequest: function(selectedMember) {
      let sendData = {
        projectId: this.projectId,
        userData: selectedMember
      };
      this.approveProjectMember(sendData).then(() => {});
      // modify list in place

      this.isLocal = true;
      this.perPage--;
      this.totalItems--;
      this.projectMembers = this.projectMembers.filter(member => {
        return member.id !== selectedMember.id;
      });
      this.$root.$emit("pendingMemberApproved");
    },

    onRejectRequest: function(selectedMember) {
      this.deleteProjectMember({
        userData: selectedMember,
        projectId: this.projectId
      }).then(() => {});
      // modify list in place
      this.isLocal = true;
      this.perPage--;
      this.totalItems--;
      this.projectMembers = this.projectMembers.filter(member => {
        return member.id !== selectedMember.id;
      });
    },

    onInviteNewUser() {
      this.$bvModal.show("invite-user-modal");
    }
  }
};
</script>

<style></style>
