import * as types from './mutation_types'

export default {
  [types.ORGANIZATION_RESET] (state) {
    Object.assign(state, {
      organizationsError: '',
      organizations: [],
      isOrganizationsLoading: false,
    })
  },

  [types.ORGANIZATION_SET_ERROR] (state, organizationsError) {
    Object.assign(state, { organizationsError })
  },

  [types.ORGANIZATION_SET_ITEMS] (state, organizations) {
    Object.assign(state, { organizations })
  },

  [types.ORGANIZATION_TOGGLE_LOADING] (state) {
    Object.assign(state, { isOrganizationsLoading: !state.isOrganizationsLoading })
  }

}
