<template>
  <div class="indicator-hover">
    <highcharts :options="chartOptions" ref="chart"></highcharts>
  </div>
</template>

<script>

import Highcharts from "highcharts";

export default {
  name: 'OrgDonutScoreCard',
  props: {
    scoreId: {
      type: Number | null,
      required: true,
    },
    scoreName: {
      type: String,
      default: () => {
        return ""
      },
      required: false,
    },
    scoreValue: {
      type: String,
      default: () => {
        return ""
      },
      required: false,
    },
    scoreData: {
      type: Array,
      default: () => {
        return []
      },
      required: true,
    },
    scoreHeight: {
      type: Number,
      default: () => {
        return 200
      },
      required: false,
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'pie',
          height: this.scoreHeight,
          // width: 200,
          spacing: [-10, -10, -10, -10]
        },
        plotOptions: {
          pie: {
            shadow: false,
            borderWidth: 1.50,
          },
        },
        title: {
          // text: this.scoreValue,
          text: '',
          align: 'center',
          verticalAlign: 'middle',
          // y: 17,
          style: {
            fontWeight: 'bold',
            fontSize: '10px'
          }
        },
        tooltip: {
          enabled: (this.scoreId !== null && this.scoreValue !== null),
          hideDelay: 500,
          formatter: function () {
            return 'Click to View Indicator';
          },
        },
        series: [{
          name: '',
          data: this.scoreData,
          point: {
            events: {
              click: () => {
                this.showMoreDetails()
              }
            }
          },
          size: '100%',
          innerSize: '65%',
          showInLegend: false,
          colors: ['#869740', '#c8c8c8'],
          dataLabels: {
            enabled: false,
          },
          enableMouseTracking: (this.scoreId !== null && this.scoreValue !== null),
          states: {
            hover: {
              enabled: false
            }
          }
        }],
        exporting: {
          enabled: false,
        },
        credits: {
          enabled: false,
        },
      },
    }
  },
  computed: {},
  methods: {
    showMoreDetails() {
      this.$emit('view', this.scoreId)
    }
  },
  watch: {
    scoreData(newVal) {
      this.chartOptions = Highcharts.merge(this.chartOptions, {
        series: [{
          name: 'Metric',
          data: newVal,
          size: '100%',
          innerSize: '65%',
          showInLegend: false,
          colors: ['#869740', '#c8c8c8'], //#4AAF4FFF
          dataLabels: {
            enabled: false,
          },
          enableMouseTracking: false,
          states: {
            hover: {
              enabled: false
            }
          }
        }]
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.indicator-hover {
  opacity: 0.85;
  &:hover {
    opacity: 1.0;
  }
};
.tooltip-container {
  width: 50px;
  height: 50px;
  pointer-events: auto;
}
</style>
