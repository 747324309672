import { mapActions } from 'vuex'

export default {
  methods: {
    ...mapActions({
      inviteProjectMember: 'project/show/inviteProjectMember'
    }),
    onInviteClick() {
      // this.$root.$emit('bv::show::modal', 'feature-coming-soon')
      let sendData = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        message: this.message,
        projectId: window.ClickedProjectId
      }

      this.inviteProjectMember(sendData).then((result) => {
        this.$root.$emit('bv::hide::modal', 'invite-new-user')
      })
    }
  },
  data() {
    return {
      firstNameVal: '',
      lastNameVal: '',
      emailVal: '',
      messageVal: ''
    }
  },
  computed: {
    firstName: {
      get() {
        return this.firstNameVal
      },
      set(newFisrtNameVal) {
        this.firstNameVal = newFisrtNameVal
      }
    },
    lastName: {
      get() {
        return this.lastNameVal
      },
      set(newLastNameVal) {
        this.lastNameVal = newLastNameVal
      }
    },
    email: {
      get() {
        return this.emailVal
      },
      set(newEmailVal) {
        this.emailVal = newEmailVal
      }
    },
    message: {
      get() {
        return this.messageVal
      },
      set(newMessageVal) {
        this.messageVal = newMessageVal
      }
    }
  }
}
