<template>
  <highcharts v-if="chartData.length > 0" :options="chartOptions" ref="pie"></highcharts>
  <span v-else>No Observations</span>
</template>

<script>
import Highcharts from "highcharts";
import exporting from "highcharts/modules/exporting";
import offlineExporting from "highcharts/modules/offline-exporting";

exporting(Highcharts);
offlineExporting(Highcharts);

export default {
  name: 'PieChart',
  props: {
    sampleSize: {
      type: Number,
      default: () => {
        return 0
      },
      required: true,
    },
    chartData: {
      type: Array,
      default: () => {
        return []
      },
      required: true,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'pie',
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
        },
        tooltip: {
          formatter: function () {
            return '<b>'+this.key+': </b>' +this.y+ ''
          }
        },
        title: {
          text: "Total number of species: " + this.sampleSize,
          align: "left",
          style: {
            fontSize: "1",
            fontWeight: "regular",
          }
        },
        subtitle: {
            useHTML: true,
            text: '<h4><strong>' + this.sampleSize +' Species</strong></h4>',
            floating: true,
            verticalAlign: 'middle',
            y: 30
        },
        credits: {
          enabled: true,
          text: 'Powered by CitSci via HighCharts',
          href: ''
        },
        series: [
          { 
            name: "taxonomygroup",
            data: this.chartData,
            size: '100%',
            innerSize: '80%',
          }
        ]
      }
    }
  },
  watch: {
    chartData(newVal) {
      this.chartOptions  = Highcharts.merge(this.chartOptions, {
        series: [
          {
            data: newVal,
            size: '100%',
            innerSize: '80%',
          }
        ]
      })
    },
  sampleSize(newVal) {
    this.chartOptions = Highcharts.merge(this.chartOptions, {
      title: {
          text: "Total number of species: " + newVal,
      },
      subtitle: {
        text: '<h1>' + newVal +'<br/> Species</h1>',
      }
    })
  },
  }
}
</script>
