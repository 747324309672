import * as types from './mutation_types'

export default {
  [types.OBSERVATION_SHOW_RESET] (state) {
    Object.assign(state, {
      error: '',
      isLoading: false,
      retrieved: {
        location: {
          latitude: 0.0,
          longitude: 0.0
        }},
      observationData: null,
      observationViolations: null,
      deletedSuccess: null
    })
  },

  [types.OBSERVATION_SHOW_SET_ERROR] (state, error) {
    Object.assign(state, {error: error })
  },

  [types.OBSERVATION_SHOW_SET_RETRIEVED] (state, retrieved) {
    const recordsArray = Array.isArray(retrieved.records)? 
      retrieved.records:
      Object.keys(retrieved.records).map((key) => {
        return retrieved.records[key]
      });
    const recordsFiltered = recordsArray.filter((record)=>{
      return !record.parentRecord
    })
    const records = recordsFiltered.map((parentRecord)=>{
      const subRecordsArray = Array.isArray(parentRecord.records)?
          parentRecord.records : 
          Object.keys(parentRecord.records).map((key) => {
            return parentRecord.records[key]
          });
      const subRecordsNoParent = subRecordsArray.map((subRecord)=>{
        return {
          ...subRecord,
          parentRecord: undefined
        }
      })
      const subRecordsSorted = subRecordsNoParent.sort((a,b)=>{
        return a.orderNumber - b.orderNumber;
      })
      return {
        ...parentRecord,
        records: subRecordsSorted
      }
    })
    Object.assign(state, { retrieved: {
      ...retrieved,
      records: records
    } })

  },

  [types.OBSERVATION_SHOW_SET_UPDATED] (state, retrieved) {
    Object.assign(state, { retrieved })
  },

  [types.OBSERVATION_SHOW_SET_DELETED] (state, deleteSuccess) {
    Object.assign(state, {deleted: deleteSuccess })
  },

  [types.OBSERVATION_RECORD_SHOW_SET_UPDATED] (state, retrieved) {
    let records = []
    let oldState = state
    state.retrieved.records.forEach((record, recordIndex) => {
      if (record.id === retrieved.id) {
        records.push(retrieved)
      } else {
        records.push(record)
      }
    })

    Object.assign(oldState.retrieved.records, records)
    Object.assign(state, {records})
  },

  [types.OBSERVATION_RECORD_SHOW_CREATED] (state, retrieved) {
    let records = state.retrieved.records
    records.push(retrieved)
    Object.assign(state, { records })
  },

  [types.OBSERVATION_SHOW_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.OBSERVATION_SHOW_DONE] (state, observationShow) {
    Object.assign(state, { observationShow })
  },

  [types.OBSERVATION_SHOW_SET_VIOLATIONS] (state, observationViolations) {
    Object.assign(state, { observationViolations })
  },

  [types.OBSERVATION_UPDATE_SET_VIOLATIONS] (state, observationViolations) {
    Object.assign(state, { observationViolations })
  }
}
