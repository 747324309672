import { mapActions, mapGetters } from 'vuex'
import VueLoader from '../../../components/layout/Loader'

export default {
  created() {

  },

  computed: {
    ...mapGetters({
      isLoading: 'staff/show/isLoading'
    })
  },

  methods: {
    ...mapActions({
      preparePage: 'staff/show/preparePage'
    })
  },

  components: {
    VueLoader
  }
}
