export const USER_PROFILE_RESET = 'USER_PROFILE_RESET'
export const USER_PROFILE_SET_ERROR = 'USER_PROFILE_SET_ERROR'
export const USER_PROFILE_SET_RETRIEVED = 'USER_PROFILE_SET_RETRIEVED'
export const USER_PROFILE_SET_STATS = 'USER_PROFILE_SET_STATS'
export const USER_PROFILE_SET_PROJECTS = 'USER_PROFILE_SET_PROJECTS'
export const USER_PROFILE_SET_ALL_PROJECTS = 'USER_PROFILE_SET_ALL_PROJECTS'

export const USER_PROFILE_SET_LOCATIONS = 'USER_PROFILE_SET_LOCATIONS'
export const USER_PROFILE_LOCATIONS_LOADING = 'USER_PROFILE_LOCATIONS_LOADING'

export const USER_PROFILE_SET_OBSERVATIONS = 'USER_PROFILE_SET_OBSERVATIONS'
export const USER_PROFILE_OBSERVATIONS_LOADING = 'USER_PROFILE_OBSERVATIONS_LOADING'

export const USER_PROFILE_UPDATE_LOADING = 'USER_PROFILE_UPDATE_LOADING'
export const USER_PROFILE_PROJECT_LOADING = 'USER_PROFILE_PROJECT_LOADING'
export const USER_PROFILE_STATS_LOADING = 'USER_PROFILE_STATS_LOADING'
export const USER_PROFILE_READ_LOADING = 'USER_PROFILE_READ_LOADING'
export const USER_PROFILE_TOGGLE_EDITING = 'USER_PROFILE_TOGGLE_EDITING'
export const USER_PROFILE_UPDATE_VIOLATIONS = 'USER_PROFILE_UPDATE_VIOLATIONS'

// upload user picture (TODO: should be a separate component)
export const USER_PROFILE_PICTURE_UPLOADING = 'USER_PROFILE_PICTURE_UPLOADING'
export const USER_PROFILE_PICTURE_REMOVING = 'USER_PROFILE_PICTURE_REMOVING'
export const USER_PROFILE_PICTURE_SET_RETRIEVED = 'USER_PROFILE_PICTURE_SET_RETRIEVED'
export const USER_PROFILE_PICTURE_SET_ERROR = 'USER_PROFILE_PICTURE_SET_ERROR'

// password reset form (TODO: should be a separate component)
export const USER_PROFILE_TOGGLE_RESET_PASSWORD = 'USER_PROFILE_TOGGLE_RESET_PASSWORD'
export const USER_PROFILE_TOGGLE_RESET_PASSWORD_LOADING = 'USER_PROFILE_TOGGLE_RESET_PASSWORD_LOADING'
export const USER_PROFILE_RESET_PASSWORD_SET_UPDATED = 'USER_PROFILE_RESET_PASSWORD_SET_UPDATED'
export const USER_PROFILE_RESET_PASSWORD_SET_ERROR = 'USER_PROFILE_RESET_PASSWORD_SET_ERROR'
export const USER_PROFILE_RESET_PASSWORD_SET_VIOLATIONS = 'USER_PROFILE_RESET_PASSWORD_SET_VIOLATIONS'
