<template>
  <b-container mx-auto class="banner-container" fluid :style="getImageUrlStyle()">
    <div v-if="currentBannerImage" class="row row-bottom-banner">
      <div class="col-md-4 col-replace-margin-banner">
        <label for="replaceBanner" class="btn btn-sm btn-tertiary">
          <font-awesome-icon icon="image"/>
          &nbsp;Replace Banner
        </label>
        <input id="replaceBanner"
               ref="input"
               type="file"
               name="image"
               accept="image/*"
               @change="setupBannerCropper"/>
      </div>
      <div class="col-md-4 col-margin-banner">
        <b-btn class="btn btn-sm" @click="$bvModal.show('removeBannerModal');" variant="danger">
          <font-awesome-icon icon="trash"/>
          &nbspRemove Banner
        </b-btn>
      </div>
    </div>
    <div v-else class="row row-bottom-banner">
      <div class="col-md-3">
        <label for="addBanner" class="btn btn-sm btn-primary">
          <font-awesome-icon icon="image"/>
          &nbsp;Add Banner
        </label>
        <input id="addBanner"
               ref="input"
               type="file"
               name="image"
               accept="image/*"
               @change="setupBannerCropper"/>
      </div>
    </div>

    <b-modal id="cropBannerModal" title="Crop Banner Image" size="lg"
             no-close-on-backdrop no-close-on-esc centered>
      <div>
        <vue-cropper v-show="bannerToCrop"
                     class="vue-cropper"
                     ref="bannerCropper"
                     id="bannerCropper"
                     :guides="true"
                     :view-mode="3"
                     :aspectRatio="36/9"
                     drag-mode="move"
                     :zoomOnWheel="true"
                     :background="true"
                     :rotatable="false"
                     :src="bannerToCrop"
                     alt="Banner">
        </vue-cropper>
      </div>
      <template slot="modal-footer">
        <div v-if="isUploading">
          <font-awesome-icon v-if="isUploading" icon="spinner"/>
          &nbsp;Uploading Banner...
        </div>
        <div :disabled="isUploading">
          <b-btn variant="primary" @click="uploadBannerImage">
            Upload Banner
          </b-btn>
          <b-btn @click="newBannerImage=null; closeModal('cropBannerModal');">
            Cancel
          </b-btn>
        </div>
      </template>
    </b-modal>

    <b-modal id="removeBannerModal" title="Remove Project Banner?"
             no-close-on-backdrop no-close-on-esc centered>
      <div>
        Confirm you want to remove the Banner image from the organization. This cannot
        be undone.
      </div>
      <template slot="modal-footer">
        <b-btn variant="danger" @click="removeBannerImage">
          Yes, Remove Banner
        </b-btn>
        <b-btn @click="closeModal('removeBannerModal')">
          Cancel
        </b-btn>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import VueCropper from "vue-cropperjs";
import VueUploadComponent from 'vue-upload-component';
export default {
  components: {
    VueCropper,
    VueUploadComponent,
  },
  data() {
    return {
      organization: {...this.organizationVuex},
      newBannerImage: null,
      bannerToCrop: null,
      isUploading: false,
    }
  },
  watch: {
    newBannerImage(newBanner) {
      if (newBanner) {
        // User picked an image, cropper is initialized
        this.$bvModal.show('cropBannerModal');
      } else {
        // User canceled upload
        this.bannerToCrop = null
      }
    },
    uploadBannerStatus(newStatus) {
      if (newStatus) {
        let currentStatus = newStatus.status
        if (currentStatus === 'SUCCESS') {
          this.isUploading = false
          this.closeModal('cropBannerModal')
        } else {
          this.isUploading = currentStatus === 'IS_UPLOADING';
          if (!this.isUploading) {
            // Handle errors here
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      organizationVuex: "organization/get/organization",
      uploadBannerStatus: "organization/images/upload",
    }),
    currentBannerImage() {
      return (this.organizationVuex &&
        this.organizationVuex.banner &&
        this.organizationVuex.banner.path)
        ? this.organizationVuex.banner.path
        : null;
    },
  },
  methods: {
    ...mapActions({
      updateOrganization: "organization/get/updateOrganization",
      uploadImage: "organization/images/uploadFile",
    }),
    getImageUrlStyle() {
      return this.currentBannerImage ?
        'background-image: url(' + this.currentBannerImage + ')' :
        'background-image: url(' + '/assets/images/generic-banner-image.png' + ')'
    },
    removeBannerImage() {
      this.updateOrganization({ banner: null })
      this.closeModal('removeBannerModal');
    },
    closeModal(modalName) {
      this.$bvModal.hide(modalName);
    },
    setupBannerCropper(event) {
      const file = event.target.files[0];
      // Check for image file
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }
      // Check for image less than 10mb
      if (file.size > 10 * 1000000) {
        alert('Please select an image file not exceeding 10mb');
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (evt) => {
          this.bannerToCrop = evt.target.result;
          // Init the cropper
          this.$refs.bannerCropper.replace(evt.target.result)
        };
        this.newBannerImage = file;
        reader.readAsDataURL(file);
      } else {
        console.log('FileReader API not supported by Browser');
      }
    },
    uploadBannerImage() {
      // Banner image is uploaded
      const canvas = this.$refs.bannerCropper.getCroppedCanvas()
      canvas.toBlob(blob => {
        this.uploadImage({
          blob: blob,
          name: this.newBannerImage.name,
          type: 'organization_banner'
        })
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-container {
  background-size: cover;
  background-position: center bottom;
  background-color: grey;
  height: 200px;
  overflow: hidden;
}
.row-bottom-banner {
  margin-top: 167px;
}
.col-margin-banner {
  margin-left: -20px;
  margin-right: -20px;
}
.col-replace-margin-banner {
  margin-left: -10px;
  margin-right: -20px;
}
input[type="file"] {
  display: none;
}
</style>
