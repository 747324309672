import { mapActions, mapGetters } from 'vuex'

import DatePicker from 'vue-bootstrap-datetimepicker'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'

export default {
  components: {
    DatePicker
  },
  props: {
    /**
     * @var {Array} tableItems - a list with items to be displayed in table
     */
    tableItems: {
      type: Array,
      default: () => {
        return []
      }
    },

    /**
     * @var {Array} tableFields - a list with fields to be displayed (columns)
     */
    tableFields: {
      type: Array,
      default: () => {
        return [
          {key: 'observation__date', label: 'Date', sortable: true, sortDirection: 'desc', thClass: 'text-center', tdClass: 'align-middle text-center'},
          {key: 'observation__location', label: 'Location Name', sortable: true, thClass: 'text-center', tdClass: 'align-middle text-center'},
          {key: 'observation__recorder', label: 'Recorder', sortable: true, thClass: 'text-center', tdClass: 'align-middle text-center'},
          {key: 'observation__options', label: 'Options', thClass: 'text-center', tdClass: 'align-middle text-center'}
        ]
      }
    },

    /**
     * @var {Boolean} canEdit - a flag that display/hide edit buttons for users with the proper rights
     */
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    },

    /**
     * @var {Number} tableDeepLevel
     */
    tableDeepLevel: {
      type: Number,
      default: () => {
        return 0
      }
    },

    /**
     * @var {Object} item
     */
    item: {
      type: Object,
      default: () => {
        return {}
      }
    },

    /**
     * @var {Boolean} editActive
     */
    editActive: {
      type: Boolean,
      default: () => {
        return false
      }
    },

    /**
     * @var {Number} organismId
     */
    organismId: {
      type: Number,
      default: () => {
        return 0
      }
    }
  },

  data() {
    return {
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.tableItems.length
  },
  methods: {
    ...mapActions({
      resetError: 'observation/show/resetError',
      deleteRecord: 'observation/show/deleteRecord',
      observationUpdate: 'observation/show/observationUpdate',
      saveObservationRecord: 'observation/show/saveObservationRecord',
      uploadObservationImage: 'observation/show/uploadObservationImage',
      updateOrganismSubRecord: 'observation/show/updateOrganismSubRecord'
    }),

    /*
    onObservationShow( routeName, observationId ) {
      this.$router.push({name: routeName, params: { id: observationId } });
      return;
    },
    */

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    beforeCreate: function () {
        this.$options.components.ObservationRecordsTable = require('../../../components/location/ObservationRecordsTable').default
      }
    }
}
