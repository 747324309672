<template>
  <div>
    <b-form-group class="mb-0"
                  label-cols-lg="3"
                  content-cols-lg="12"
                  label="Bin Definition:"
                  label-class="font-weight-bold pt-0 label_underline">
    </b-form-group>
    <!--Overall Min-->
    <b-form-group id="min"
                  label-cols-lg="2"
                  content-cols-lg="2"
                  label="Overall Min.:"
                  label-class="font-weight-bold pt-0"
                  label-for="input-min">
      <b-form-input id="input-min"
                    name="minOverall"
                    type="number"
                    v-model.number="overallMinValue"
                    debounce="500"
                    :disabled="defaultMin !== null"
                    placeholder="Minimum Value"
                    v-validate="{ required: true, integer: true }"
                    data-vv-as="Overall Minimum Value"
                    :class="{ 'is-invalid': errors.has('minOverall') }"
                    aria-describedby="bin-min-input-live-feedback"
      ></b-form-input>
      <div v-if="this.defaultMin !== null">
        <small>Min. set from Datasheet</small>
      </div>
      <b-form-invalid-feedback id="bin-min-input-live-feedback">
        {{ errors.first("minOverall") }}
      </b-form-invalid-feedback>
    </b-form-group>
    <!--Overall Max-->
    <b-form-group id="max"
                  label-cols-lg="2"
                  content-cols-lg="2"
                  label="Overall Max.:"
                  label-class="font-weight-bold pt-0"
                  label-for="input-max">
      <b-form-input id="input-max"
                    name="maxOverall"
                    type="number"
                    v-model.number="overallMaxValue"
                    debounce="500"
                    :disabled="defaultMax !== null || getMaxOverallDisabled"
                    placeholder="Maximum Value"
                    v-validate="{ required: true, integer: true, min_value: overallMinValue+1 }"
                    data-vv-as="Overall Maximum Value"
                    :class="{ 'is-invalid': errors.has('maxOverall') }"
                    aria-describedby="bin-max-input-live-feedback"
      ></b-form-input>
      <div v-if="this.defaultMax !== null">
        <small>Max. set from Datasheet</small>
      </div>
      <b-form-invalid-feedback id="bin-max-input-live-feedback">
        {{ errors.first("maxOverall") }}
      </b-form-invalid-feedback>
    </b-form-group>
    <!--Num Bins-->
    <b-form-group v-if="isMinMaxValid"
                  id="bins"
                  label-cols-lg="2"
                  content-cols-lg="2"
                  label="# Bins:"
                  label-class="font-weight-bold pt-0"
                  label-for="input-bins">
      <b-form-select id="input-bins"
                     name="numBins"
                     v-model.number="numBins"
                     :options="binOptions">
      </b-form-select>
    </b-form-group>
    <div v-if="isMinMaxValid" v-for="i in numBins" :key="i">
      <NumericalBinInput :min-value="getBinValues(i).min"
                         :initial-max="getBinValues(i).max"
                         :initial-label="getBinValues(i).label"
                         :order-number="i"
                         :max-allowed="overallMaxValue"
                         :num-bins="getNumBins"
                         :bin-pct="i*weight"
                         @maxchange="handleMaxUpdate">
      </NumericalBinInput>
    </div>
  </div>
</template>

<script>

import NumericalBinInput from "./NumericalBinInput.vue";

export default {
  name: "NumericalBins",
  props: {
    defaultMin: {
      type: Number | null,
      required: false
    },
    defaultMax: {
      type: Number | null,
      required: false
    }
  },
  components: {
    NumericalBinInput,
  },
  inject: ['parentValidator'],
  data() {
    return {
      numBins: 1,
      maxBinCount: [1, 2, 3, 4, 5, 6, 7],
      binValues: [],
      parentBinValues: [],
      overallMinValue: this.defaultMin ? this.defaultMin : null,
      overallMaxValue: this.defaultMax ? this.defaultMax : null,
      diffMinMax: 0,
    }
  },
  created() {
    if (this.defaultMax && this.defaultMin) {
      this.handleChanges()
    }
  },
  watch: {
    defaultMin: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.defaultMin = newVal
          this.overallMinValue = newVal
          this.$validator.reset()
        }
      },
    },
    defaultMax: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.defaultMax = newVal
          this.overallMaxValue = newVal
          this.$validator.reset()
        }
      },
    },
    overallMinValue(newVal) {
      if (newVal !== null) {
        this.handleChanges()
      }
    },
    overallMaxValue(newVal) {
      if (newVal !== null) {
        this.handleChanges()
      }
    },
    numBins(newVal, oldVal) {
      if (newVal) {
        this.updateBinRange(oldVal)
      }
    },
  },
  computed: {
    isMinMaxValid() {
      if (this.overallMinValue !== null && this.overallMaxValue !== null) {
        let retVal = null
        if (this.overallMinValue >= 0) {
          retVal = Math.abs(this.overallMaxValue) - Math.abs(this.overallMinValue) > 0
        } else {
          retVal = Math.abs(this.overallMinValue) - Math.abs(this.overallMaxValue) > 0
        }
        if (retVal) this.updateBinRange()
        return retVal
      } else {
        return false
      }
    },
    weight() {
      switch (this.numBins) {
        case 1:
          return 100;
        case 2:
          return 50;
        case 3:
          return 33.33;
        case 4:
          return 25;
        case 5:
          return 20;
        case 6:
          return 16.66;
        case 7:
          return 14.28;
      }
    },
    getMaxOverallDisabled() {
      return this.overallMinValue === null
    },
    getNumBins() {
      return this.numBins
    },
    binOptions() {
      // Return the number of bins
      // - the maximum is 7 or the difference between min and max values
      if (this.diffMinMax < this.maxBinCount.length) {
        if (this.numBins > this.diffMinMax) {
          this.numBins = this.maxBinCount.length - this.diffMinMax
        }
        return this.maxBinCount.filter(val => val <= this.diffMinMax)
      } else {
        return this.maxBinCount
      }
    },
  },
  methods: {
    getBinValues(index) {
      if (this.binValues.length > 0) {
        return this.binValues.find(val => val.orderNumber === index)
      } else {
        return []
      }
    },
    handleChanges() {
      if (this.overallMinValue !== null && this.overallMaxValue !== null) {
        if (this.overallMinValue < 0 && this.overallMaxValue > 0) {
          // handle scenario where min < 0
          this.diffMinMax = Math.abs(Math.abs(this.overallMaxValue) - this.overallMinValue)
        } else {
          this.diffMinMax = Math.abs(Math.abs(this.overallMinValue) - Math.abs(this.overallMaxValue))
        }
        // Need to update first and last bin with new min and max values
        if (this.numBins && this.binValues.length > 0) {
          if (this.numBins === 1) {
            this.binValues[0].min = this.overallMinValue
            this.binValues[0].max = this.overallMaxValue
          } else {
            this.binValues[0].min = this.overallMinValue
            this.binValues[this.numBins - 1].max = this.overallMaxValue
          }
        }
      }
    },
    updateBinRange(oldBinNum) {
      if (!this.numBins) return;

      if (this.binValues.length === 0) {
        // Initial call, no user-entered values yet
        for (let i = 0; i < this.numBins; i++) {
          if (i === 0) {
            if (this.numBins === 1) {
              this.binValues.push({
                label: null,
                orderNumber: i + 1,
                min: this.overallMinValue,
                max: this.overallMaxValue,
              })
            } else {
              this.binValues.push({label: null, orderNumber: i + 1, min: null, max: null})
            }
          } else {
            if (i === this.numBins - 1) {
              this.binValues.push({label: null, orderNumber: i + 1, min: null, max: this.overallMaxValue})
            } else {
              this.binValues.push({label: null, orderNumber: i + 1, min: null, max: null})
            }
          }
        }
      } else {
        // Bin size changed
        if (this.binValues.length > this.numBins) {
          // - Remove bins > this.numBins
          this.binValues = this.binValues.filter(bin => bin.orderNumber <= this.numBins)
          // Update last bin max value
          this.binValues[this.numBins - 1].max = this.overallMaxValue
        } else {
          // modify existing bins
          for (let i = 0; i < oldBinNum; i++) {
            // Clear the max value we set
            if (this.binValues[i].max >= this.overallMaxValue) {
              this.binValues[i].max = null
            }
          }
          // - add new bins
          for (let i = oldBinNum; i < this.numBins; i++) {
            if (i === oldBinNum) {
              // take the max value from the prior bin
              if (i !== this.numBins - 1) {
                this.binValues.push({
                  label: null,
                  orderNumber: i + 1,
                  min: this.binValues[i - 1].max,
                  max: null,
                })
              } else {
                this.binValues.push({
                  label: null,
                  orderNumber: i + 1,
                  min: this.binValues[i - 1].max,
                  max: this.overallMaxValue,
                })
              }
            } else if (i === this.numBins - 1) {
              this.binValues.push({label: null, orderNumber: i + 1, min: null, max: this.overallMaxValue})
            } else {
              this.binValues.push({label: null, orderNumber: i + 1, min: null, max: null})
            }
          }
        }
      }
    },
    handleMaxUpdate(val) {
      // Manage when user updates the max of a bin
      // - set the min value of the next bin to the current bin's max value
      let bIndex = this.binValues.findIndex(b => b.orderNumber === val.orderNumber)
      if (bIndex === -1) {
        this.binValues.push(val)
      } else {
        this.binValues[bIndex] = val
        if (this.binValues.hasOwnProperty(bIndex + 1)) {
          let adjBin = this.binValues[bIndex + 1]
          adjBin.min = val.max
          this.binValues[bIndex + 1] = adjBin
        }
      }
      this.$emit('update', this.binValues)
    },
  }
  ,
}
;
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
