import DatePicker from 'vue-bootstrap-datetimepicker'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'

// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import '@fortawesome/fontawesome-free/css/regular.css'
import '@fortawesome/fontawesome-free/css/solid.css'

import FileUpload from '../../../components/layout/FileUpload'
import RecordLocation from '../../../components/datasheet/RecordLocation.vue'

// Using font-awesome 5 icons
$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle'
  }
})

const DATETIME_PICKER_OPTIONS = {
  allowInputToggle: true,
  format: "YYYY/MM/DD h:mm:ss A",
  showClear: true,
  showClose: true,
  useStrict: true,
}

const UNTITLED_RECORD = "Untitled Question"

export default {
  name: 'datasheetRecord',
  inject: ['parentValidator'],

  components: {
    DatePicker,
    FileUpload,
    RecordLocation
  },

  props: {
    element: {
      type: Object,
      default: () => { }
    },

    activeRecordMarker: {
      type: String,
      default: () => { }
    },
    duplicateDatasheetRecord: {
      type: Function,
      default: () => { }
    },
    removeDatasheetRecordModalIsOpen: {
      type: Boolean,
      default: false
    },

    showUntitledLabel: {
      type: Boolean,
      default: true
    },

    uploadLoadingStatus: {
      type: Boolean,
      default: false
    },

    page: {
      type: String,
      default: ""
    }
  },

  data() {
    return {
      untitledRecord: UNTITLED_RECORD,
      removeRecordModalIsOpen: this.removeDatasheetRecordModalIsOpen,
      datetimePickerOptions: DATETIME_PICKER_OPTIONS,
      uploadLoadingStatusValue: this.uploadLoadingStatus,
    }
  },

  created() {
    this.$validator = this.parentValidator
  },
  methods: {
    increment(direction) {
      console.log('increment')
      const oldString = this.element.defaultValue;
      let oldNum = 0
      const isUndefined = (oldString === undefined)
      const isNanString = (oldString === 'NaN')
      const isNotANumber = isNaN(oldString)

      if(!isUndefined && !isNanString && !isNotANumber){
        oldNum = Number(oldString)
      } 
      let newValue = 0;
      if (direction === 'increase') {
          newValue = Number(oldNum) + 1;
      } else if (direction === 'decrease') {
          newValue = Number(oldNum) - 1;
      }
      // check to see if value is in bounds
      const maxObj = this.element.validators.find((validator) => {
        return validator.validatorType === "max"
      })
      const minObj = this.element.validators.find((validator) => {
          return validator.validatorType === "min"
      })
      if (maxObj && maxObj.value && Number(maxObj.value) < newValue) {
        newValue = Number(maxObj.value);
      }
      if (minObj && minObj.value && Number(minObj.value) > newValue) {
        newValue = Number(minObj.value);
      }
      this.element.defaultValue = newValue.toString();
    },
  },
  computed: {
    modalIsOpen: {
      get: function () {
        return this.removeRecordModalIsOpen
      },
      set: function (newValue) {
        this.removeRecordModalIsOpen = newValue

        this.$emit('modalIsOpen', this.modalIsOpen)
      }
    },

    uploadLoadingStatusVal: {
      get: function () {
        return this.uploadLoadingStatusValue
      },
      set: function (newValue) {
        this.uploadLoadingStatusValue = newValue

        this.$emit('uploadLoadingStatus', this.uploadLoadingStatusVal)
      }
    }
  }
}
