<template>
  <div v-if="items">
    <b-form-group id="score-record"
                  label-cols-lg="0"
                  content-cols-lg="12"
                  label-class="font-weight-bold pt-0"
                  label-for="input-bin">
      <template slot="label">Bin {{orderNumber}}:</template>
      <b-row>
        <b-col cols="2">
          <Donut class="justify-content-center"
                 :score-id=null
                 :score-data="example"
                 :score-height=50></Donut>
        </b-col>
        <b-col cols="2">
          <b-form-input id="input-label"
                        :name="'label'+orderNumber"
                        v-model="binLabel"
                        debounce="1000"
                        placeholder="Bin label"
                        v-validate="'required|min:3|max:20'"
                        data-vv-as="label"
                        :class="{ 'is-invalid': errors.has('label'+orderNumber) }"
                        aria-describedby="bin-label-input-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="bin-label-input-live-feedback">
            {{ errors.first('label'+orderNumber) }}
          </b-form-invalid-feedback>
        </b-col>
        <b-col cols="6" class="" align-v="center">
          <multiselect id="input-bin"
                       :name="'bin-values'+orderNumber"
                       v-model="selected"
                       :options="items"
                       label="name" track-by="id"
                       open-direction="bottom"
                       select-label="" deselect-label=""
                       :block-keys="['Tab']" :multiple="true"
                       placeholder="Pick values"
                       v-validate="'required|min:1'"
                       :class="{ 'is-invalid': errors.has('bin-values'+orderNumber) }"
                       aria-describedby="bin-values-input-live-feedback"
          >
          </multiselect>
          <b-form-invalid-feedback id="bin-values-input-live-feedback">
            {{ errors.first('bin-values'+orderNumber) ? 'Select at least one value for this bin.' : '' }}
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
    </b-form-group>
  </div>
</template>

<script>
import Donut from './Donut.vue';
export default {
  name: "CategoricalBinMultiselect",
  // props: ["items", "orderNumber", "binPct"],
  props: {
    items: {
      type: Array,
      required: true,
    },
    orderNumber: {
      type: Number,
      required: true
    },
    binPct: {
      type: Number,
      required: true
    }
  },
  components: { Donut },
  inject: [ 'parentValidator' ],
  data() {
    return {
      binLabel: null,
      selected: null,
      binPercent: this.binPct,
    }
  },
  created () {
    this.$validator = this.parentValidator
  },
  watch: {
    binPct: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.binPercent = newVal
          // Reset validation when bin value changes
          this.$validator.reset()
        }
      }
    },
    binLabel(newVal) {
      if (newVal) {
        // Parent is notified of bin label change
        this.$emit('select', { orderNumber: this.orderNumber, label: newVal, optionsValues: this.selected })
      }
    },
    selected(newVal) {
      // Parent is notified of bin options change
      this.$emit('select', { orderNumber: this.orderNumber, label: this.binLabel, optionsValues: newVal })
    },
  },
  computed: {
    example() {
      return [{
        name: "fill",
        y: this.binPercent
      },
      {
        name: "empty",
        y: 100 - this.binPercent
      }]
    }
  },
  methods: {
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
