import * as types from './mutation_types'

export default {
  [types.PROJECT_DOWNLOAD_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.PROJECT_DOWNLOAD_LOADING_TOGGLE] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.PROJECT_DOWNLOAD_CREATED] (state, created) {
    Object.assign(state, { created })
  },

  [types.PROJECT_DOWNLOAD_ALL] (state, allProjectDownloads) {
    Object.assign(state, { allProjectDownloads })
  },

  [types.PROJECT_DOWNLOAD_SINGLE] (state, singleProjectDownload) {
    Object.assign(state, { singleProjectDownload })
  },

  [types.PROJECT_DOWNLOAD_VIOLATIONS] (state, violations) {
    Object.assign(state, { violations })
  },

  [types.PROJECT_DOWNLOAD_RESET] (state) {
    Object.assign(state, {
      created: null,
      allProjectDownloads: null,
      singleProjectDownload: null,
      error: '',
      isLoading: false,
      violations: null
    })
  }
}
