import * as types from './mutation_types'

export default {
  [types.PROJECT_SHOW_RESET] (state) {
    Object.assign(state, {
      error: '',
      isLoading: false,
      isLoadingLocations: false,
      retrieved: null,
      searchTerm: '',
      violations: '',
      projectLocations: [],
      stats: null,
      isUploading: false,
      getProjectIdByName: '',
      discussionForumData: [],
      isDeleted: false,
      updatedPostAfterReply : null,
      isLoadingDiscussionData: false,

      errorCheckLocationExists: '',
      isLoadingCheckLocationExists: false,
      checkLocationExists: null,

      isLoadingZooniverse: false,

      isLoadingContactManager: false,
      contactManagerSuccess: '',

      isLoadingMembers: false,
      projectMembers: [],
      allProjectLocations: [],
    })
  },

  [types.PROJECT_SHOW_SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.GET_PROJECT_MEMBERS_LIST_SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.JOIN_MEMBER_SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.APPROVE_MEMBER_SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.DELETE_MEMBER_SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.UPDATE_MEMBER_SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.INVITE_MEMBER_SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.DELETE_DATASHEET_SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.GET_PROJECT_STATS_SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.PROJECT_SHOW_SET_RETRIEVED] (state, retrieved) {
    Object.assign(state, { retrieved })
  },

  [types.SET_PROJECT_LOCATIONS] (state, projectLocations) {
    Object.assign(state, {projectLocations: projectLocations })
  },

  [types.SET_ALL_PROJECT_LOCATIONS] (state, allProjectLocations) {
    state.allProjectLocations = [...state.allProjectLocations, ...allProjectLocations];
  },

  [types.RESET_ALL_PROJECT_LOCATIONS](state) {
    state.allProjectLocations = []
  },

  [types.PROJECT_SHOW_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.GET_PROJECT_STATS_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.GET_PROJECT_LOCATIONS_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoadingLocations: !state.isLoadingLocations })
  },

  [types.GET_ALL_PROJECT_LOCATIONS_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoadingAllLocations: !state.isLoadingAllLocations })
  },

  [types.SET_PROJECT_MEMBERS_LIST] (state, projectMembers) {
    Object.assign(state, {projectMembers: projectMembers })
  },

  [types.GET_PROJECT_MEMBERS_LIST_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoadingMembers: !state.isLoadingMembers })
  },

  [types.JOIN_MEMBER_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.APPROVE_MEMBER_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.UPDATE_MEMBER_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.INVITE_MEMBER_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.DELETE_DATASHEET_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.DELETE_MEMBER_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.DATASHEET_CREATE_RESET] (state) {
    Object.assign(state, {
      datasheetCreated: null,
      datasheetError: '',
      datasheetIsLoading: false,
      datasheetViolations: null
    })
  },

  [types.DATASHEET_CREATE_SET_ERROR] (state, datasheetError) {
    Object.assign(state, { datasheetError })
  },

  [types.DATASHEET_CREATE_SET_CREATED] (state, datasheetCreated) {
    Object.assign(state, { datasheetCreated })
  },

  [types.JOIN_MEMBER_DONE_CREATED] (state, memberJoined) {
    Object.assign(state, { memberJoined })
  },

  [types.APPROVE_MEMBER_DONE] (state, memberApproved) {
    Object.assign(state, { memberApproved })
  },

  [types.DELETE_MEMBER_DONE] (state, memberDeleted) {
    Object.assign(state, { memberDeleted })
  },

  [types.GET_PROJECT_STATS_DONE] (state, stats) {
    Object.assign(state, { stats: stats })
  },

  [types.UPDATE_MEMBER_DONE] (state, memberUpdated) {
    Object.assign(state, { memberUpdated })
  },

  [types.INVITE_MEMBER_DONE] (state, memberInvited) {
    Object.assign(state, { memberInvited })
  },

  [types.DELETE_DATASHEET_DONE] (state, datasheetDeleted) {
    Object.assign(state, { datasheetDeleted })
  },

  [types.DATASHEET_CREATE_TOGGLE_LOADING] (state) {
    Object.assign(state, { datasheetIsLoading: !state.datasheetIsLoading })
  },

  [types.DATASHEET_CREATE_SET_VIOLATIONS] (state, violations) {
    Object.assign(state, { violations: violations })
  },

  [types.GET_PROJECT_STATS_SET_VIOLATIONS] (state, violations) {
    Object.assign(state, { violations: violations })
  },

  [types.JOIN_MEMBER_SET_VIOLATIONS] (state, violations) {
    Object.assign(state, { violations: violations })
  },

  [types.APPROVE_MEMBER_SET_VIOLATIONS] (state, violations) {
    Object.assign(state, { violations: violations })
  },

  [types.DELETE_MEMBER_SET_VIOLATIONS] (state, violations) {
    Object.assign(state, { violations: violations })
  },

  [types.UPDATE_MEMBER_SET_VIOLATIONS] (state, violations) {
    Object.assign(state, { violations: violations })
  },

  [types.INVITE_MEMBER_SET_VIOLATIONS] (state, violations) {
    Object.assign(state, { violations: violations })
  },

  [types.DELETE_DATASHEET_SET_VIOLATIONS] (state, violations) {
    Object.assign(state, { violations: violations })
  },

  [types.SET_SEARCH_TERM] (state, searchTerm) {
    Object.assign(state, { searchTerm })
  },

  [types.UPDATE_ITEM_PROPERTY] (state, data) {
    Object.assign(state.retrieved, data)

  },

  [types.PROJECT_PROFILE_TOGGLE_PICTURE_UPLOADING] (state, isUploading) {
    Object.assign(state, { isUploading })
  },

  [types.GET_PROJECT_ID_BY_PROJECT_NAME_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },
  [types.GET_PROJECT_ID_BY_PROJECT_NAME_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.GET_PROJECT_ID_BY_PROJECT_NAME] (state, data) {
    Object.assign(state, { getProjectIdByName: data })
  },

  [types.ADD_PROJECT_LIKE] (state, data) {
    Object.assign(state, { isLiked: data })
  },

  [types.ADD_PROJECT_LIKE_BEFORE_LOGIN] (state) {
    Object.assign(state, { isLiked: true })
  },

  [types.ADD_PROJECT_LIKE_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.REMOVE_PROJECT_LIKE] (state, data) {
    Object.assign(state, { isLiked: data })
  },

  [types.REMOVE_PROJECT_LIKE_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.GET_PROJECT_DISCUSSION_FORUM_DATA_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.GET_PROJECT_DISCUSSION_FORUM_DATA] (state, data) {
    Object.assign(state, { discussionForumData: data })
  },

  [types.ADD_DISCUSSION_DATA_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoadingDiscussionData: !state.isLoadingDiscussionData })
  },

  [types.ADD_DISCUSSION_DATA_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.ADD_DISCUSSION_DATA] (state, data) {
    Object.assign(state, { discussionForumData : state.discussionForumData.concat([data]) })
  },

  [types.REMOVE_DISCUSSION_POST] (state, data) {
    Object.assign(state, { isDeleted : data })
  },

  [types.REMOVE_DISCUSSION_POST_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.ADD_REPLY_TO_DISCUSSION] (state, data) {
    Object.assign(state, { updatedPostAfterReply: data })
  },

  [types.ADD_REPLY_TO_DISCUSSION_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.PROJECT_CHECK_LOCATION_EXISTS_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoadingCheckLocationExists: !state.isLoadingCheckLocationExists })
  },

  [types.PROJECT_CHECK_LOCATION_EXISTS_SET_ERROR] (state, errorCheckLocationExists) {
    Object.assign(state, { errorCheckLocationExists})
  },

  [types.PROJECT_CHECK_LOCATION_EXISTS_SET_RETRIEVED] (state, checkLocationExists) {
    Object.assign(state, { checkLocationExists })
  },

  [types.ZOONIVERSE_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoadingZooniverse: !state.isLoadingZooniverse })
  },

  [types.SCISTARTER_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoadingSciStarter: !state.isLoadingSciStarter })
  },

  [types.SEND_EMAIL_TO_MANAGER_LOADING] (state) {
    Object.assign(state, { isLoadingContactManager: !state.isLoadingContactManager })
  },

  [types.SEND_EMAIL_TO_MANAGER_SUCCESS] (state, contactManagerSuccess) {
    Object.assign(state, { contactManagerSuccess })
  },

}
