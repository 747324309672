<template>
  <div>
    <div class="bg-light mt-3">
      <div id="app" class="editor-head">
        <AddFile></AddFile>
        <ckeditor
          :editor="editor"
          v-model="discussionText"
          :config="editorConfig"
          autofocus
        ></ckeditor>
      </div>
      <div v-show="showError">
        <span class="required-feedback">The Forum Post cannot be empty</span>
      </div>
      <div class="row py-1">
        <div class="col-lg-2 col-md-2 col-sm-2">
          <b-button type="submit"
                    variant="tertiary" class=""
                    title="Post your reply"
                    alt="Post your reply"
                    @click="onReply()"
                    :disabled='isLoading'>
                    <span v-if='isUploadingCount'>
                        <i class='fa fa-spinner fa-spin'></i>
                        Uploading {{ uploads.length - isUploadingCount + 1 }} of {{ uploads.length }}
                    </span>
            <span v-else-if='isLoading'>
                      <i class='fa fa-spinner fa-spin'></i>
                      Posting
                    </span>
            <span class='' v-else>
                      Submit Reply
                    </span>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import VueLoader from '../../../layout/Loader.vue'
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DOMPurify from 'dompurify';
import AddFile from "./forumPostAddFiles.vue";

export default {
  name: "NewReply",
  components: {
    AddFile,
    VueLoader,
  },

  props: {
    // Incoming discussion
    discussionId: {
      type: Number,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      discussionText: "",
      editor: ClassicEditor,
      editorConfig: {
        placeholder: "Start replying here...",
        removePlugins: [
          "MediaEmbed",
          "TableToolbar",
          "TableCellPropertiesUI",
          "Table",
          "Image",
          "MediaEmbed",
          "MediaEmbedUI",
          "MediaEmbedToolbar",
          "ImageToolbar",
          "ImageUploadEditing",
          "ImageUpload",
          "ImageUploadUI",
        ],
      },
      showRequiredError: false
    };
  },

  created() {
  },
  watch: {
    discussionText(newVal, oldVal) {
      if (!newVal) {
        this.showError = true
      }
      if (newVal) {
        this.showError = false
      }
    }
  },
  computed: {
    ...mapGetters({
      isLoading: "project/forum/isLoading",
      isUploadingCount: "file/multiupload/isUploadingCount",
      uploads: "file/multiupload/uploads",
    }),
    showError: {
      get() {
        return this.showRequiredError
      },
      set(newVal) {
        this.showRequiredError = newVal
      }
    },
  },

  methods: {
    ...mapActions({
      addReplyToDiscussion: "project/forum/create",
    }),
    onReply() {
      this.discussionText = this.purify(this.discussionText);
      // Validate reply exists
      if (this.discussionText.length === 0) {
        this.showError = true
        return
      }
      // Reply is created
      this.addReplyToDiscussion({
        parentPost: `/project_posts/${this.discussionId}`,
        discussionText: this.discussionText,
      }).then((response) => {
        if (response) {
          this.discussionText = "";
          this.$nextTick(() => {
            this.showError = false;
          });
          this.$emit('replied', response)
        }
      });
    },
    purify(unsafeHTML) {
      return DOMPurify.sanitize(unsafeHTML, {
        USE_PROFILES: {
          html: true
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.required-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
</style>
