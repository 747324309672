import { mapActions, mapGetters } from 'vuex'

export default {
  components: { },
  created() {
    this.sendDataToHeader(this.headerData, this)
    this.getStats();
  },
  beforeDestroy () {
    this.reset()
  },

  computed: mapGetters({
    stats: 'about/show/stats'
  }),

  methods: {
    ...mapActions({
      getStats: 'about/show/getStats',
    }),

    /**
     * @function reset - reset the object to the default values
     */
    reset() {
      // TODO reset the object to the default values
      // console.log("Reset object")
    }
  },

  /**
   *
   * @returns {{headerData: {backgroundPath: string, picturePath: string, headerTitle: string}}}
   */
  data() {
    return {
        headerData: {
        backgroundPath: '/assets/images/header/about-us-header.png',
        picturePath: '',
        headerTitle: 'About Us'
    }}
  }
}
