import fetch from '../../../utils/fetch'

const getInitialState = () => {
    return {
        isLoading: false,
        id: null,
        organization: null,
        error: null,
    }
}

const state = getInitialState();

const getters = {
    organization(state) { return state.organization },
    projects(state) {
      if (state.organization) {
        // Return only approved projects + sorted
        return {
          ...state.organization.organizationProjects.filter(op => op.approved).sort((a, b) => a.project.name.localeCompare(b.project.name)).map((op) => op.project)}
      } else {
        return null
      }
    },
    id(state) { return (state.organization && state.organization.url) },
    isLoading(state) { return state.isLoading },
    error(state) { return state.error },
}

const actions = {
    /**
     * @function getOrganization- retrieve from API organization data
     * @param commit
     * @param dispatch
     * @param getters
     * @param organizationName {String}
     * @returns {Promise<T | never> | * | *}
     */
    get({ commit, dispatch }, organizationName) {
        commit(types.RESET)
        commit(types.SET_ORGANIZATION_LOADING);
        return fetch('/organizations/' + organizationName)
        .then(response => response.json())
        .then((organization) => {
          commit(types.SET_ORGANIZATION, organization)
          // Call to fetch scorecards for this organization
          dispatch('organization/scorecards/get', {}, {root: true})
          // Call to fetch stats for this organization
          dispatch('organization/stats/get', {}, {root: true})
          // Call to fetch recent photo observations for this organization
          console.log('org', organization)
          if (organization['url'] === 'big-bend-restoration') {
            dispatch('organization/photo_observations/getBigBend', 6, {root: true})
          } else {
            dispatch('organization/photo_observations/get', 6, {root: true})
          }
        })
        .catch((error) => {
            commit(types.SET_ORGANIZATION_ERROR, error)
        })
    },
    updateOrganization({ commit, state }, orgData) {
      return fetch('/organizations/' + state.organization.url, {
        method: 'PUT',
        headers: new Headers({ 'Content-Type': 'application/ld+json' }),
        body: JSON.stringify(orgData)
      })
        .then(response => response.json())
        .then((data) => {
          commit(types.SET_ORGANIZATION, data)
          return data
        })
        .catch((error) => {
          commit(types.SET_ORGANIZATION_ERROR, error)
        })
    },
    refresh({ commit, state }) {
      commit(types.SET_ORGANIZATION_LOADING);
      return fetch('/organizations/' + state.id)
        .then(response => response.json())
        .then((organization) => {
          commit(types.SET_ORGANIZATION, organization)
        })
        .catch((error) => {
          commit(types.SET_ORGANIZATION_ERROR, error.message)
        })
    },
    organizationAffiliations({ commit, state }) {
      return fetch('/organizations/' + state.id + '/organization_projects')
        .then(response => response.json())
        .then((organization_projects) => {
          return organization_projects
        })
        .catch((error) => {

        })
    }
}

const types = {
    SET_ORGANIZATION_LOADING: 'SET_ORGANIZATION_LOADING',
    SET_ORGANIZATION: 'SET_ORGANIZATION',
    SET_ORGANIZATION_ERROR: 'SET_ORGANIZATION_ERROR',
    RESET_ERROR: 'RESET_ERROR',
    RESET: 'RESET',
}

const mutations = {
    [types.SET_ORGANIZATION_LOADING](state) {
        state.isLoading = true;
    },
    [types.SET_ORGANIZATION](state, organization) {
        state.organization = organization
        state.isLoading = false
    },
    [types.SET_ORGANIZATION_ERROR](state, error) {
        state.error = error;
        state.isLoading = false;
    },
    [types.RESET_ERROR](state) {
        state.isLoading = false;
        state.error = null;
    },
    [types.RESET](state) {
        Object.assign(state, getInitialState());
    },
}

export default {
    namespaced: true,
    state: state,
    getters: getters,
    actions: actions,
    mutations: mutations
}
