<template>
  <div>
    <div v-if="isManager">
      <b-form @submit.prevent="saveChanges" novalidate>
      <div class="position-relative mt-2">
        <ProjectBannerImage></ProjectBannerImage>
        <div class="logo-overlay">
          <ProjectLogoImage></ProjectLogoImage>
        </div>
      </div>
      <div class="card-header">
        <b-row>
          <b-col lg="6">
            <h3 class="mb-0">Edit Project</h3>
          </b-col>
          <b-col lg="3">
            <div v-if="projectVuex" class="mx-3">
        <span>
          Last Saved:
        </span>
              <FromNow :dateTime="projectVuex.updatedAt" class="font-weight-bold">
              </FromNow>
              <i
                  id="timestamps-updated"
                  class="fa fa-question-circle text-secondary"
              ></i>
              <i v-if="isLoadingUpdate" class="fa fa-spinner fa-spin"></i>
            </div>
          </b-col>
          <b-col lg="3" class="d-flex flex-wrap ">
            <b-button
                v-if="!hasChanges && projectLocal && !isLoadingUpdate"
                class="btn btn-success"
                alt="All Changes to Your Citizen Science Project Are Saved"
                title="All Changes to Your Citizen Science Project Are Saved"
                disabled>
              All Changes Saved
            </b-button>
            <b-button
                v-else-if="projectLocal && !isLoadingUpdate"
                class="btn btn-primary"
                type="submit"
                variant="primary"
                size="md"
                alt="Save Changes to Your Citizen Science Project Now"
                title="Save Changes to Your Citizen Science Project Now"
            >
              Save Changes
            </b-button>
            <b-button
                v-if=" projectLocal && projectLocal.projectState === PROJECT_STATE_DRAFT && !isLoadingUpdate"
                class="btn btn-primary ml-2"
                type="submit"
                size="md"
                variant="primary"
                alt="Publish Your Draft Citizen Science Project Now"
                title="Publish Your Draft Citizen Science Project Now"
                @click="projectStateLocal = PROJECT_STATE_PUBLISHED">
              Publish Project
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div class="card-body" v-if="projectLocal">
        <b-row>
          <b-col lg="8" md="12">
            <b-form-group>
              <b-form-group
                  id="project-name-input-group"
                  label="Project Name"
                  label-for="project-name-input"
                  label-class="font-weight-bold"
                  label-cols="12"
              >
                <label class="text-danger text-small">
                  (Changing the project name will change the project URL, breaking
                  any <b>external</b> links to this project.)</label>
                <b-form-input
                    id="project-name-input"
                    type="text"
                    maxlength="100"
                    name="name"
                    placeholder="Project Name"
                    v-model.trim="projectLocal.name"
                    v-validate="'required|min:5'"
                    :class="{
                  'is-invalid': errors.has('name') || errors.has('urlField')
                }"
                    :state="updateURLField"
                    aria-describedby="project-name-input-live-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback id="project-name-input-live-feedback">
                  {{ errors.first("name") || errors.first("urlField") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                  id="project-url-input-group"
                  class="mt-3"
                  label="Project URL"
                  label-for="project-url-input"
                  label-class="font-weight-bold"
                  label-cols-sm="3"
                  label-cols-lg="2"
              >
                <b-input-group-prepend>
                  <font-awesome-icon
                      icon="question-circle"
                      id="projectUrl-info"
                      class="project-url-icon"
                  />
                </b-input-group-prepend>
                <b-form-textarea
                    id="project-url-textarea"
                    name="urlField"
                    placeholder="Project URL"
                    v-model="computedUrlField"
                    rows="1"
                    max-rows="2"
                    no-auto-shrink
                    no-resize
                    :disabled="true"
                >
                </b-form-textarea>
                <b-btn size="sm" @click="copyURLToClipboard">
                  <i class="fa fa-clipboard fa-lg"></i>
                  &nbsp;Copy
                </b-btn>
              </b-form-group>

              <b-form-group
                  id="project-description-input-group"
                  class="mt-3"
                  label="About"
                  label-for="project-description-input"
                  label-class="font-weight-bold h4"
                  label-cols="12"
              >
                <b-form-textarea
                    id="project-description-textarea"
                    name="description"
                    placeholder="Please provide a description of your project (Minimum of 10 characters)"
                    v-model.trim="projectLocal.description"
                    v-validate="'required|min:10'"
                    :class="{ 'is-invalid': errors.has('description') }"
                    :rows="3"
                    :max-rows="8"
                    aria-describedby="project-description-input-live-feedback"
                >
                </b-form-textarea>
                <b-form-invalid-feedback
                    id="project-description-input-live-feedback"
                >
                  {{ errors.first("description") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                  id="project-goals-input-group"
                  class="mt-3"
                  label="Goals"
                  label-for="project-goals-input"
                  label-class="font-weight-bold h4"
                  label-cols="12"
              >
                <b-form-textarea
                    id="project-goals-textarea"
                    name="goals"
                    placeholder="Please describe what your project hopes to accomplish (e.g. Monitor water quality in the St. Lucie River)"
                    v-model="projectLocal.goals"
                    v-validate="'required|min:10'"
                    :class="{ 'is-invalid': errors.has('goals') }"
                    :rows="3"
                    :max-rows="8"
                    aria-describedby="project-goals-input-live-feedback"
                >
                </b-form-textarea>
                <b-form-invalid-feedback id="project-goals-input-live-feedback">
                  {{ errors.first("goals") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                  id="project-tasks-input-group"
                  class="mt-3"
                  label="Tasks"
                  label-for="project-tasks-input"
                  label-class="font-weight-bold h4"
                  label-cols-sm="12"
              >
                <b-form-textarea
                    id="project-tasks-textarea"
                    name="tasks"
                    placeholder="Please describe how the participant will help this project (e.g. Collect and submit water samples for analysis)"
                    v-model.trim="projectLocal.tasks"
                    v-validate="'required|min:10'"
                    :class="{ 'is-invalid': errors.has('tasks') }"
                    :rows="3"
                    :max-rows="8"
                    aria-describedby="project-tasks-input-live-feedback"
                >
                </b-form-textarea>
                <b-form-invalid-feedback id="project-tasks-input-live-feedback">
                  {{ errors.first("tasks") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                  id="project-gettingStarted-input-group"
                  class="mt-3"
                  label="Getting Started"
                  label-for="project-gettingStarted-input"
                  label-class="font-weight-bold h4"
                  label-cols-sm="12"
              >
                <ckeditor
                  :editor="editor"
                  v-model="projectLocal.gettingStarted"
                  :config="gettingStartedEditorConfig"
                ></ckeditor>
<!--                <b-form-textarea-->
<!--                    id="project-gettingStarted-input"-->
<!--                    name="gettingStarted"-->
<!--                    placeholder="Please provide a description about how to get started with this project"-->
<!--                    v-model.trim="projectLocal.gettingStarted"-->
<!--                    :class="{ 'is-invalid': errors.has('gettingStarted') }"-->
<!--                    :rows="3"-->
<!--                    :max-rows="8"-->
<!--                    aria-describedby="project-gettingStarted-input-live-feedback"-->
<!--                >-->
<!--                </b-form-textarea>-->
                <b-form-invalid-feedback
                    id="project-gettingStarted-input-live-feedback"
                >
                  {{ errors.first("gettingStarted") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                  id="project-website-input-group"
                  class="mt-3"
                  label="Website URL"
                  label-for="project-website-input"
                  label-class="font-weight-bold"
                  label-cols-sm="3"
                  label-cols-lg="2"
              >
                <b-input-group-prepend>
                  <font-awesome-icon
                      icon="question-circle"
                      id="website-info"
                      class="website-url-icon"
                  />
                </b-input-group-prepend>
                <b-form-input
                    id="project-website-input"
                    type="text"
                    maxlength="100"
                    name="website"
                    placeholder="Website URL (e.g. https://citsci.org)"
                    v-model.trim="projectLocal && projectLocal.website"
                    :class="{ 'is-invalid': errors.has('website') }"
                    aria-describedby="project-website-input-live-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback id="project-website-input-live-feedback">
                  {{ errors.first("website") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                  id="project-facebookLink-input-group"
                  class="mt-3"
                  label="Facebook Page"
                  label-for="facebook-name-input"
                  label-class="font-weight-bold"
                  label-cols-sm="3"
                  label-cols-lg="2"
              >
                <b-form-input
                    id="project-facebookLink-input"
                    type="text"
                    maxlength="100"
                    name="facebookLink"
                    placeholder="Facebook URL (e.g. https://www.facebook.com/CitSci.org/)"
                    v-model.trim="projectLocal.facebookLink"
                    :class="{ 'is-invalid': errors.has('facebookLink') }"
                    aria-describedby="project-facebookLink-input-live-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback
                    id="project-facebookLink-input-live-feedback"
                >
                  {{ errors.first("facebookLink") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                id="project-instaLink-input-group"
                class="mt-3"
                label="Instagram URL"
                label-for="project-instaLink-input"
                label-class="font-weight-bold"
                label-cols-sm="3"
                label-cols-lg="2"
              >
                <b-form-input
                  id="project-instaLink-input"
                  type="text"
                  maxlength="100"
                  name="instaLink"
                  placeholder="Instagram URL (e.g. https://instagram.com/citsci_org)"
                  v-model.trim="projectLocal.instagramLink"
                  :class="{ 'is-invalid': errors.has('instaLink') }"
                  aria-describedby="project-instaLink-input-live-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback id="project-instaLink-input-live-feedback">
                  {{ errors.first("instaLink") }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group
                id="project-twtLink-input-group"
                class="mt-3"
                label="X(Twitter) URL"
                label-for="project-twtLink-input"
                label-class="font-weight-bold"
                label-cols-sm="3"
                label-cols-lg="2"
              >
                <b-form-input
                  id="project-twtLink-input"
                  type="text"
                  maxlength="100"
                  name="twtLink"
                  placeholder="X(Twitter) URL (e.g. https://x.com/citsci)"
                  v-model.trim="projectLocal.twitterLink"
                  :class="{ 'is-invalid': errors.has('twtLink') }"
                  aria-describedby="project-twtLink-input-live-feedback"
                >
                </b-form-input>
                <b-form-invalid-feedback id="project-twtLink-input-live-feedback">
                  {{ errors.first("twtLink") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-form-group>
          </b-col>

          <b-col lg="4" md="12">
            <div class="card">
              <div class="card-header">
                <h4>
                  Settings
                  <i class="fa fa-xs fa-wrench"></i>
                </h4>
                <b-row>
                  <b-col md="8" sm="12" class="mt-2">
                    <b>Membership</b>
                    <font-awesome-icon
                        icon="question-circle"
                        id="membership-info"
                    />
                  </b-col>
                  <b-col md="4" sm="12" class="mt-2">
                    <toggle-button
                        id="membership-btn"
                        color="#00c8f8"
                        v-model="projectLocal.approveContacts"
                        :sync="true"
                        :labels="{ checked: 'Closed', unchecked: 'Open' }"
                        :width="65"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="8" sm="12" class="mt-2">
                    <b>Privacy</b>
                    <font-awesome-icon icon="question-circle" id="privacy-info"/>
                  </b-col>
                  <b-col md="4" sm="12" class="mt-2">
                    <toggle-button
                        id="privacy-btn"
                        color="#00c8f8"
                        v-model="projectLocal.isPrivate"
                        :sync="true"
                        :labels="{ checked: 'Private', unchecked: 'Public' }"
                        :width="65"
                    />
                  </b-col>
                </b-row>
              </div>
            </div>

            <div class="card mt-3">
              <div class="card-header">
                <h4>
                  Integrations
                  <i class="fa fa-xs fa-cogs"></i>
                </h4>
                <b-row>
                  <b-col md="8" sm="12" class="mt-2">
                    <b>SciStarter</b>
                    <font-awesome-icon
                        icon="question-circle"
                        id="scistarter-info"
                    />
                  </b-col>
                  <b-col md="4" sm="12" class="mt-2">
                    <toggle-button
                        id="scistarter-btn"
                        color="#00c8f8"
                        v-model="projectLocal.isSciStarter"
                        :sync="true"
                        :labels="{ checked: 'ON', unchecked: 'OFF' }"
                        :width="65"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="8" sm="12" class="mt-2">
                    <b>Zooniverse</b>
                    <font-awesome-icon
                        icon="question-circle"
                        id="zooniverse-info"
                    />
                  </b-col>
                  <b-col md="4" sm="12" class="mt-2">
                    <toggle-button
                        id="zooniverse-btn"
                        color="#00c8f8"
                        v-model="projectLocal.isZooniverse"
                        :sync="true"
                        :labels="{ checked: 'ON', unchecked: 'OFF' }"
                        :width="65"
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="8" sm="12" class="mt-2">
                    <b>Airtable</b>
                    <font-awesome-icon
                        icon="question-circle"
                        id="airtable-info"
                    />
                  </b-col>
                  <b-col md="4" sm="12" class="mt-2">
                    <toggle-button
                        id="airtable-btn"
                        color="#00c8f8"
                        v-model="projectLocal.isAirtable"
                        :sync="true"
                        :labels="{ checked: 'ON', unchecked: 'OFF' }"
                        :width="65"
                    />
                  </b-col>
                </b-row>
              </div>
            </div>

            <b-card class="special mt-3">
              <h4>Hub Affiliations&nbsp;&nbsp;
                <span class="h4-icon"><font-awesome-icon icon="sitemap"/>&nbsp;
                  <font-awesome-icon icon="question-circle" id="organization-info"/>
                </span>
              </h4>
              <ProjectOrgAffiliations
                  v-if="projectLocal.projectState === PROJECT_STATE_PUBLISHED"
                  :new-affiliations="newAffiliations">
              </ProjectOrgAffiliations>
              <label v-else>Hub affiliations are not available for draft projects.</label>
            </b-card>
          </b-col>
        </b-row>
      </div>

      <div v-if="project">
        <h4 class="card-header">
          Location
          <span style="font-size: 14px;"
          ><font-awesome-icon icon="question-circle" id="project-location-info"
          /></span>
        </h4>
        <vue-google-map
            v-if="project"
            :item="projectLocal"
            :latitude="projectLocal && projectLocal.latitude"
            :longitude="projectLocal && projectLocal.longitude"
            :is-loading="false"
            :custom-marker="false"
            :zoom-map-to-markers="11"
            :show-locate-address="true"
            :zoom-val="5"
            :show-address-flag="true"
            class="project-create-edit"
        />
      </div>
      <div class="card-header">
        <b-row>
          <b-col lg="6">
            <h3 class="mb-0"></h3>
          </b-col>
          <b-col lg="3">
            <div v-if="projectVuex" class="mx-3">
        <span>
          Last Saved:
        </span>
              <FromNow :dateTime="projectVuex.updatedAt" class="font-weight-bold">
              </FromNow>
              <i
                  id="timestamps-updated"
                  class="fa fa-question-circle text-secondary"
              ></i>
              <i v-if="isLoadingUpdate" class="fa fa-spinner fa-spin"></i>
            </div>
          </b-col>
          <b-col lg="3" class="d-flex flex-wrap ">
            <b-button
                v-if="!hasChanges && projectLocal && !isLoadingUpdate"
                class="btn btn-success"
                alt="All Changes to Your Citizen Science Project Are Saved"
                title="All Changes to Your Citizen Science Project Are Saved"
                disabled>
              All Changes Saved
            </b-button>
            <b-button
                v-else-if="projectLocal && !isLoadingUpdate"
                class="btn btn-primary"
                type="submit"
                variant="primary"
                size="md"
                alt="Save Changes to Your Citizen Science Project Now"
                title="Save Changes to Your Citizen Science Project Now"
            >
              Save Changes
            </b-button>
            <b-button
                v-if="projectLocal && !isLoadingUpdate &&
                    projectLocal.projectState === PROJECT_STATE_DRAFT"
                class="btn btn-primary ml-2"
                type="submit"
                size="md"
                variant="primary"
                alt="Publish Your Draft Citizen Science Project Now"
                title="Publish Your Draft Citizen Science Project Now"
                @click="projectStateLocal = PROJECT_STATE_PUBLISHED"
            >
              Publish Project
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-tooltip target="membership-info" placement="bottom">
        Choose whether project membership is open (anyone can join without your
        approval) or closed (requests to join require your approval)
      </b-tooltip>

      <b-tooltip target="projectUrl-info" placement="right">
        This will be the web address you can use to have volunteers access your
        citizen science project directly. Simply have your volunteers copy and
        paste this into their web browser to easily access your project
      </b-tooltip>

      <b-tooltip target="website-info" placement="right">
        External website URL
      </b-tooltip>

      <b-tooltip target="privacy-info" placement="bottom">
        Choose whether your project data are public (visible to all) or private
        (only visible to members)
      </b-tooltip>

      <b-tooltip target="scistarter-info" placement="bottom" :disabled="true">
        Recruit more participants through SciStarter (a free service) and help
        advance research
      </b-tooltip>

      <b-tooltip target="organization-info" placement="bottom">
        Associate your project with one or more Organizations
      </b-tooltip>

      <b-tooltip target="zooniverse-info" placement="bottom">
        Connect project to Zooniverse for image processing
      </b-tooltip>

      <b-tooltip target="airtable-info" placement="bottom">
        Connect project to Airtable for data transfer
      </b-tooltip>

      <b-tooltip target="project-location-info" placement="bottom">
        Manually enter coordinates, click on the map, or search for an address to
        obtain coordinates. This represents your project headquarters. Please use
        decimal degrees (not degrees, minutes, seconds).
      </b-tooltip>

      <b-tooltip
          v-if="projectVuex"
          target="timestamps-updated"
          variant="light"
          placement="left"
          custom-class="timestamp-tooltip-class"
      >
        <div>
          <div><strong>Updated</strong>: {{ moment(projectVuex.updatedAt) }}</div>
          <hr/>
          <div><strong>Created</strong>: {{ moment(projectVuex.createdAt) }}</div>
        </div>
      </b-tooltip>
    </b-form>
    </div>
    <RequiresManager v-else page="Edit Project"></RequiresManager>
  </div>
</template>

<script>
import VueGoogleMap from "../../../../assets/scripts/layout/GoogleMap";
import VueLoader from "../../../layout/Loader";
import {mapGetters, mapActions} from "vuex";
import ProjectBannerImage from "../../ProjectBannerImage";
import ProjectLogoImage from "../../ProjectLogoImage";
import FromNow from "../../../../components/layout/FromNow";
import {appRootUrl} from "../../../../main";
import ProjectOrgAffiliations from "./ProjectOrgAffiliations.vue";
import RequiresManager from "../tabpermission/requiresManager.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  components: {
    RequiresManager,
    ProjectOrgAffiliations,
    VueGoogleMap,
    VueLoader,
    ProjectBannerImage,
    ProjectLogoImage,
    FromNow,
  },

  data() {
    return {
      projectLocal: null,
      projectStateLocal: 0,
      computedUrlField: "",
      hasChanges: false,
      originalCopy: null,
      priorValue: null,
      orgRequests: [],

      editor: ClassicEditor,
      gettingStartedEditorConfig: {
        placeholder: "Please provide a description about how to get started with this project...",
        removePlugins: [
          "MediaEmbed",
          "TableToolbar",
          "TableCellPropertiesUI",
          "Table",
          "Image",
          "MediaEmbed",
          "MediaEmbedUI",
          "MediaEmbedToolbar",
          "ImageToolbar",
          "ImageUploadEditing",
          "ImageUpload",
          "ImageUploadUI",
        ],
      },

    };
  },

  created() {

  },

  watch: {
    projectLocal: {
      deep: true,
      // Enable save button when any change detected
      handler: function (newVal, oldVal) {
        if (oldVal === null) {
          this.hasChanges = false;
          let fetch = this.project;
        } else {
          this.hasChanges = !(
              JSON.stringify(newVal) === JSON.stringify(this.originalCopy)
          );
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      isManager: "project/get/isManager",
      projectVuex: "project/get/project",
      isLoadingUpdate: "project/update/isLoading",
    }),

    project() {
      if (this.projectVuex) {
        let arrAttr = [
          "name",
          "urlField",
          "description",
          "goals",
          "tasks",
          "gettingStarted",
          "website",
          "facebookLink",
          "twitterLink",
          "instagramLink",
          "latitude",
          "longitude",
          "approveContacts",
          "isPrivate",
          "isSciStarter",
          "isZooniverse",
          "isAirtable",
          "projectState",
          "organization"
        ];
        this.projectLocal = Object.fromEntries(
            Object.entries({...this.projectVuex}).filter(([key]) =>
                arrAttr.includes(key)
            )
        );
        this.projectStateLocal = this.projectLocal.projectState;
        // A deep copy of the original state
        if (this.originalCopy === null) {
          this.originalCopy = {...this.projectLocal};
        }
      }
      return this.projectLocal;
    },

    /**
     * @function updateUrlField
     * @returns {boolean}
     */
    updateURLField() {
      if (this.projectLocal) {
        //Strip all special characters except and replace with hyphen
        const special_char_regex = /(\s+|\/|\\|\[|\]|\{|\}|\,|\<|\>|\:|\;|\"|\.|\?|\-|\=|\+|\*|\&|\^|\%|\#|\@|\`|\'|\||\~|\!|\$|\*|\(|\)|\_|\$)+/g;
        const non_ascii_regex = /[\x00-\x08\x0E-\x1F\x7F-\uFFFF]+/g;
        const replace_char = "-";
        // this.projectLocal.urlField = "";

        // If the name is filled, the url is created
        // - basically we replace all special characters and non-ascii values with a hyphen
        if (this.projectLocal.name && this.projectLocal.name !== "") {
          // Non-ascii characters (e.g. emoji etc...) are replaced followed by all special character
          let sanitisedUrl = this.projectLocal.name.toLowerCase();
          sanitisedUrl = sanitisedUrl
              .replace(special_char_regex, replace_char)
              .replace(non_ascii_regex, replace_char);
          // If the URL ends with a hyphen, it is also removed
          if (sanitisedUrl.endsWith("-")) {
            sanitisedUrl = sanitisedUrl.slice(0, -1);
          }
          // Sanitised URL is set <safe to have only hyphen in the url>
          this.computedUrlField = appRootUrl + "/projects/" + sanitisedUrl;
          if (this.projectLocal.urlField !== sanitisedUrl) {
            this.projectLocal.urlField = sanitisedUrl;
          }
        }
      }
    }

    // // Computed property for Project sciStarterStatus => HasSciStarterProject|DoesNotHaveProject
    // // Translate integer into boolean for toggle's v-model
    // sciStarterStatus: {
    //    get() {
    //      if (this.projectVuex.sciStarterID) { // if it has a value (is not null)
    //        return true
    //      } else {
    //        return false
    //      }
    //    },
    //    set(data) {
    //      if (data) {
    //        this.projectVuex.sciStarterID = 0 // set to 0 upon toggle initially
    //      } else {
    //        this.projectVuex.sciStarterID = null
    //      }
    //    },
    // }
  },

  methods: {
    ...mapActions({
      updateProject: "project/update/updateProject",
      createSciStarterProject: "project/show/createSciStarterProject"
    }),

    async copyURLToClipboard() {
      try {
        await navigator.clipboard.writeText(this.computedUrlField);
        this.$bvToast.toast("Paste Anywhere", {
          title: "Project URL Copied",
          autoHideDelay: 2500,
          variant: "success"
        });
      } catch ($e) {
        // console.log('copy to clipboard failed.')
      }
    },

    moment(value) {
      return moment(value);
    },

    newAffiliations(newOrgs) {
      this.orgRequests = newOrgs
      if (newOrgs && newOrgs.length > 0) {
        this.hasChanges = true
      }
    },

    saveChanges() {
      // Once validation is passed, project changes are saved.
      this.$validator.validateAll().then(result => {
        if (result) {
          // Project state is set to published | draft
          this.projectLocal.projectState = this.projectStateLocal;
          this.updateProject({project: this.projectLocal, orgRequests: this.orgRequests}).then(response => {
            // Must redirect user to new project if project name was edited
            if (this.$route.params.id !== response["urlField"]) {
              this.$router.push({
                name: "ProjectShow",
                params: {id: response["urlField"]}
              });
            }
            this.hasChanges = false;
            this.originalCopy = null;
            this.projectLocal = this.project;
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.vue-js-switch#scistarter-btn {
  font-size: 12px;
}

.vue-js-switch#zooniverse-btn {
  font-size: 12px;
}

input::placeholder,
textarea::placeholder,
#project-longitude-input::placeholder {
  color: #0b7fc7;
  opacity: 0.54;
}

.logo-overlay {
  position: absolute;
  width: 180px;
  height: 180px;
  top: 5px;
  right: 5px;
}

.card-header {
  background-color: #f5f4e8 !important;
}

.disabled {
  cursor: not-allowed;
}

h1,
h2,
h3,
h4 {
  vertical-align: middle;
}

.project-url-icon {
  @media screen and (max-width: 576px) {
    position: absolute;
    top: -25px;
    left: 85px;
  }
  @media (min-width: 577px) and (max-width: 667px) {
    position: absolute;
    top: 8px;
    left: -65px;
  }
  @media (min-width: 668px) and (max-width: 767px) {
    position: absolute;
    top: 8px;
    left: -67px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    position: absolute;
    top: 8px;
    left: -120px;
  }
  @media (min-width: 992px) and (max-width: 1115px) {
    position: absolute;
    top: 8px;
    left: -28px;
  }

  position: absolute;
  top: 8px;
  left: -38px;
}

.website-url-icon {
  @media screen and (max-width: 576px) {
    position: absolute;
    top: -25px;
    left: 95px;
  }
  @media (min-width: 577px) and (max-width: 667px) {
    position: absolute;
    top: 8px;
    left: -55px;
  }
  @media (min-width: 668px) and (max-width: 767px) {
    position: absolute;
    top: 8px;
    left: -60px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    position: absolute;
    top: 8px;
    left: -110px;
  }
  @media (min-width: 992px) and (max-width: 1115px) {
    position: absolute;
    top: 8px;
    left: -18px;
  }

  position: absolute;
  top: 8px;
  left: -30px;
}

.timestamp-tooltip-class {
  margin-top: 15px;
  margin-right: 165px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0;
}

hr {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

//.tooltip .tooltip-inner{
//  max-width: 600px !important;
//  width: 500px !important;
//}
</style>
