import fetch from '../../../../utils/fetch'
import {urlBuilder} from '../../../../helpers/url_builder_helper.js'

const getInitialState = () => {
  return {
    isLoading: false,
    datasheetRecords: [],
    isLoadingResults: false,
    error: null,
    total: 0,
    totalFetched: 0,
  }
}

const state = getInitialState();

const getters = {
  datasheetRecords(state) {
    return state.datasheetRecords.filter((record) => {
      return (record.recordType === 'dropdown' || record.recordType === 'number' || record.recordType === 'radio' || record.recordType === 'organism')
    })
  },
  datasheetRecordResults(state) {
    if (state.datasheetRecordResults && state.datasheetRecordResults.length > 0) {
      return state.datasheetRecordResults
    } else {
      return []
    }
  },
  isLoading(state) {
    return state.isLoading
  },
  isLoadingResults(state) {
    return state.isLoadingResults
  },
  error(state) {
    return state.error
  },
}

 function fetchDSRecordsBlock({commit}, {url, ctx}) {
  return fetch(url)
    .then(response => response.json())
    .then((data) => {
      commit(types.SET_DATASHEET_RECORDS, data['hydra:member'])
      if (ctx.currentPage === 1) {
        return data
      }
    })
    .catch((e) => {
      commit(types.SET_DATASHEET_RECORDS_ERROR, e.message)
    })
}

const actions = {
  getDatasheetRecords({commit, state, rootState}, datasheetId) {
    if (datasheetId === null) {
      commit(types.SET_DATASHEET_RECORDS_ERROR, `No Datasheet ID selected`)
      return
    }
    commit(types.RESET);
    commit(types.SET_DATASHEET_RECORDS_LOADING);
    let arrRecType = ["dropdown", "radio", "number", "organism"];
    let maxFetch = 30;
    const ctx = {currentPage: 1, perPage: maxFetch, sortDesc: false, sortBy: 'orderNumber'};
    let urlBase = '/datasheets/' + datasheetId + '/records';
    let url = urlBase + `${urlBuilder(ctx)}` + '&recordType[]=' + arrRecType.join('&recordType[]=');
    fetchDSRecordsBlock({commit}, {url, ctx}).then(async (data) => {
      commit(types.SET_TOTAL, data['hydra:totalItems'])
      if (state.total > maxFetch) {
        for (let i = 1; i < (state.total / maxFetch); i++) {
          const ctx = {currentPage: i + 1, perPage: maxFetch, sortDesc: false, sortBy: 'orderNumber'};
          const url = urlBase + `${urlBuilder(ctx)}` + '&recordType[]=' + arrRecType.join('&recordType[]=');
          await fetchDSRecordsBlock({commit}, {url, ctx})
        }
      }
    });
  },

  getDatasheetRecordResults({commit, state}, dsRecId) {
    return fetch('/datasheet_records/' + dsRecId + '/results')
      .then(response => response.json())
      .then((data) => {
        commit(types.SET_TOTAL_FETCHED)
        //console.log(data)
        return data
      })
      .catch((error) => {
        commit(types.SET_DATASHEET_RECORDS_ERROR, error)
      })


  },
}

const types = {
  SET_DATASHEET_RECORDS_LOADING: 'SET_DATASHEET_RECORDS_LOADING',
  SET_DATASHEET_RECORDS: 'SET_DATASHEET_RECORDS',
  SET_TOTAL: 'SET_TOTAL',
  SET_DATASHEET_RECORDS_ERROR: 'SET_DATASHEET_RECORDS_ERROR',
  SET_TOTAL_FETCHED: 'SET_TOTAL_FETCHED',
  RESET_ERROR: 'RESET_ERROR',
  RESET: 'RESET',
}

const mutations = {
  [types.SET_DATASHEET_RECORDS_LOADING](state) {
    state.isLoading = true;
  },
  [types.SET_DATASHEET_RECORDS](state, datasheetRecords) {
    state.datasheetRecords = [...state.datasheetRecords, ...datasheetRecords];
    state.isLoading = false;
  },
  [types.SET_TOTAL](state, count) {
    state.total = count;
    state.isLoadingResults = true;
  },
  [types.SET_DATASHEET_RECORDS_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },
  [types.RESET_ERROR](state) {
    state.isLoading = false;
    state.error = null;
  },
  [types.SET_TOTAL_FETCHED](state) {
    state.totalFetched = state.totalFetched + 1;
    if (state.total === state.totalFetched) {
      state.isLoadingResults = false;
    }
  },
  [types.RESET](state) {
    Object.assign(state, getInitialState());
  },
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
}
