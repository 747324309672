import * as types from './mutation_types'

export default {
  [types.OBSERVATION_CREATE_SET_CREATED] (state, created) {
    Object.assign(state, { created })
  },

  [types.DATASHEET_CREATE_SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.DATASHEET_CREATE_SET_VIOLATIONS] (state, createdObservationViolations) {
    Object.assign(state, { createdObservationViolations })
  },

  [types.DATASHEET_CREATE_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.MEMBERS_ASSIGNED_LOCATIONS_RETRIEVE_SET_RETRIEVED] (state, retrievedMembersAssignedLocations) {
    Object.assign(state, { retrievedMembersAssignedLocations })
  },

  [types.MEMBERS_ASSIGNED_LOCATIONS_RETRIEVE_TOGGLE_LOADING] (state) {
    Object.assign(state, { retrievedMembersAssignedLocationsIsLoading: !state.retrievedMembersAssignedLocationsIsLoading })
  },

  [types.MEMBERS_ASSIGNED_LOCATIONS_RETRIEVE_SET_ERROR] (state, retrievedMembersAssignedLocationsError) {
    Object.assign(state, { retrievedMembersAssignedLocationsError })
  },

  [types.LOCATION_CREATE_SET_ERROR] (state, createdLocationError) {
    Object.assign(state, { createdLocationError })
  },

  [types.LOCATION_CREATE_TOGGLE_LOADING] (state) {
    Object.assign(state, { createdLocationIsLoading: !state.createdLocationIsLoading })
  },

  [types.LOCATION_CREATE_SET_CREATED] (state, createdLocation) {
    Object.assign(state, { createdLocation })
  },

  [types.LOCATION_CREATE_SET_VIOLATIONS] (state, createdLocationViolations) {
    Object.assign(state, { createdLocationViolations })
  }
}
