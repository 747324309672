<template>
  <div v-if="organizationId" class="">
    <BigBendData v-if="organizationId === 'big-bend-restoration'"></BigBendData>
    <OtherHubData v-else></OtherHubData>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BigBendData from "./bigbendData.vue";
import OtherHubData from "./otherHubData.vue";

export default {
  name: "OrgObservationsTable",
  components: {
    BigBendData,
    OtherHubData,
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      organizationId: "organization/get/id",
    }),
  },
};
</script>

<style></style>
