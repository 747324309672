import * as types from './mutation_types'

export default {
  [types.FILE_UPLOAD_TOGGLE_LOADING] (state) {
    Object.assign(state, { uploadIsLoading: !state.uploadIsLoading })
  },

  [types.FILE_UPLOAD_SET_RETRIEVED] (state, retrievedFile) {
    Object.assign(state, { retrievedFile })
  },

  [types.FILE_UPLOAD_SET_ERROR] (state, uploadError) {
    Object.assign(state, { uploadError })
  },

  [types.FILE_UPLOAD_RESET] (state) {
    Object.assign(state, {
      uploadIsLoading: false,
      uploadError: '',
      retrieved: null
    })
  }
}
