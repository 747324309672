<template>
  <b-modal id="not-member-modal" title="You must be a member of this project to continue" ref="joinModal">
    <p class="my-4">Please click the JOIN button to become a member.</p>
    <p class="my-4">Thanks, The CitSci.org Team</p>
    <div slot="modal-footer">
      <b-button variant="primary" @click="clickJoinCloseBtn">Close</b-button>
    </div>
  </b-modal>
</template>
<script>
  export default {
    methods: {
      clickJoinCloseBtn() {
        this.$refs.joinModal.hide()
      }
    }
  }
</script>
