export const clickLoggedInUrl = ($root, isPrivate) => {
  const now = Math.ceil(new Date() / 1000)
  const exp = $root.$jwt.hasToken() ? $root.$jwt.decode().exp : (now - 1)

  if (isPrivate && (JSON.parse(localStorage.getItem('sessionData')) == null || exp < now )) {
    $root.$emit('bv::show::modal', 'not-logged-in-modal')
    return new Promise((resolve, reject) => {
      resolve({success: false})
    })
  }

  return new Promise((resolve, reject) => {
    resolve({success: true})
  })
}
