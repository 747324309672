<template lang="">
    <span>
        <b-img v-if='isPrivate' src="/assets/images/private.png" width="50px" height="50px" alt="private project" title="private project"></b-img>
        <b-img v-else src="/assets/images/public.png" width="50px" height="50px" alt="public project" title="public project"></b-img>
    </span>
</template>

<script>
export default {
    props: {
        isPrivate: {
            required: true,
            type: Boolean,
        }
    }
}
</script>

<style lang="">
    
</style>
