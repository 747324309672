// datasheet creator form getters
export const retrievedDatasheet = state => {
    if (state.retrievedDatasheet === null || state.retrievedDatasheet.locations === null){
        return state.retrievedDatasheet
    }
    const sortedLocations = _.orderBy(state.retrievedDatasheet.locations,['name'],['asc']);
    return {...state.retrievedDatasheet, locations: sortedLocations}
}
export const retrievedDatasheetError = state => state.retrievedDatasheetError
export const retrievedDatasheetIsLoading = state => state.retrievedDatasheetIsLoading
export const retrievedViolations = state => state.retrievedViolations
export const updatedDatasheet = state => state.updatedDatasheet
export const updatedDatasheetIsLoading = state => state.updatedDatasheetIsLoading
export const updatedViolations = state => state.updatedViolations
export const updatedDatasheetRecords = state => state.updatedDatasheetRecords
export const updatedDatasheetRecordsIsLoading = state => state.updatedDatasheetRecordsIsLoading
export const updatedDatasheetRecordsViolations = state => state.updatedDatasheetRecordsViolations
export const retrievedDatasheetRecords = state => state.retrievedDatasheetRecords


export const updatedDatasheetTextRecord = state => state.updatedDatasheetTextRecord
export const updatedDatasheetLocations = state => state.updatedDatasheetLocations
export const updatedDatasheetPublished = state => state.updatedDatasheetPublished
export const updatedDatasheetSubRecords = state => state.updatedDatasheetSubRecords
export const updatedDatasheetRecordDetailsError = state => state.updatedDatasheetRecordDetailsError
export const addedSpeciesToOrganismRecord = state => state.addedSpeciesToOrganismRecord

// get locations
export const retrievedLocations = state => state.retrievedLocations
export const retrievedLocationsError = state => state.retrievedLocationsError
export const retrievedLocationsIsLoading = state => state.retrievedLocationsIsLoading
export const checkedLocationExistence = state => state.checkedLocationExistence
export const checkLocationExistenceError = state => state.checkLocationExistenceError

// get organisms
export const retrievedOrganisms = state => state.retrievedOrganisms
export const retrievedOrganismsView = state => state.retrievedOrganismsView
export const retrievedOrganismsError = state => state.retrievedOrganismsError
export const retrievedOrganismsIsLoading = state => state.retrievedOrganismsIsLoading


// New getters
export const datasheet = state => state.datasheet;
export const datasheetProject = state => state.datasheetProject;
export const datasheetRecords = state => state.datasheetRecords;
export const datasheetLocations = state => state.datasheetLocations;
export const datasheetIsLoading = state => state.datasheetIsLoading;
export const datasheetIsUploading = state => state.datasheetIsUploading;
export const datasheetIsPublishing = state => state.datasheetIsPublishing;
export const datasheetError = state => state.datasheetError;
export const datasheetViolations = state => state.datasheetViolations;