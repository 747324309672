import fetch from '../../../../utils/fetch'

const getInitialState = () => {
  return {
    isLoading: false,
    scorecards: null,
    indicators: [],
    observations: [],
    error: null,
  }
}

const state = getInitialState();

const getters = {
  scorecards(state) {
    return state.scorecards
  },
  indicators(state) {
    if (state.scorecards && state.observations.length === state.scorecards.length) {
      // Convert scorecard to indicator using the latest observation
      // - return the bin in which the latest observed value falls
      state.indicators = []
      state.scorecards.forEach(score => {
        let indicatorTemplate = {
          id: score.id,
          title: null,
          data: [],
          label: null,
          value: null,
          desc: null,
          error: null,
        }

        indicatorTemplate.title = score.title
        indicatorTemplate.desc = score.description
        let obsRecord = state.observations.find(rec => rec.scoreId === score.id)
        if (obsRecord.hasOwnProperty('error')) {
          // handle data fetch error
          indicatorTemplate.error = obsRecord.error
          let dataFill = {name: 'fill', y: 0}
          indicatorTemplate.data.push({...dataFill})
          let dataEmpty = {name: 'empty', y: 100}
          indicatorTemplate.data.push({...dataEmpty})
        } else {
          indicatorTemplate.value = obsRecord.value
          let matchingBin = []
          if (score.dataType !== 'number') {
            // Handle categorical bins
            matchingBin = score.bins.find(bin => {
                return bin.optionValues.flatMap(ov => ov.value).indexOf(obsRecord.value) !== -1
              }
            )
          } else {
            // Handle numerical bins
            matchingBin = score.bins.find(bin => {
                if (bin.orderNumber === 1) {
                  // First bin has to be >=
                  return obsRecord.value >= bin.minValue && obsRecord.value <= bin.maxValue
                } else {
                  return obsRecord.value > bin.minValue && obsRecord.value <= bin.maxValue
                }
              }
            )
          }
          // console.log('matching bin', matchingBin, matchingBin.length)
          if (matchingBin) {
            indicatorTemplate.label = matchingBin.label
            let dataFill = {name: 'fill', y: (matchingBin.orderNumber * 100) / score.bins.length}
            indicatorTemplate.data.push({...dataFill})
            let dataEmpty = {name: 'empty', y: 100 - dataFill.y}
            indicatorTemplate.data.push({...dataEmpty})
          } else {
            // No matching bin found
            // - maybe observed value is out of bounds
            indicatorTemplate.error = 'Value out of bounds (' + indicatorTemplate.value + ')'
            let dataFill = {name: 'fill', y: 0}
            indicatorTemplate.data.push({...dataFill})
            let dataEmpty = {name: 'empty', y: 100}
            indicatorTemplate.data.push({...dataEmpty})
          }
        }
        state.indicators.push({...indicatorTemplate})
      })
    }
    return state.indicators
  },
  isLoading(state) {
    return state.isLoading
  },
  error(state) {
    return state.error
  },
}

function fetchObservation({commit, rootState}, datasheetRecord) {
  // Fetch the most recent observation for the datasheet + datasheet record

  // Handle BigBend hub separately
  let fetchURL = datasheetRecord.datasheet['@id'] + '/observations?records.datasheetRecord=' +
    datasheetRecord['@id'] + '&order[observedAt]=desc&itemsPerPage=1'
  if (rootState.organization.get.organization.url === 'big-bend-restoration') {
    let locId = null
    if (datasheetRecord.datasheet.project.urlField === 'rapid-stream-riparian-assessment') {
      locId = ['b4e2fef0-ef1a-4a10-a5fb-0606e0ce70ea']
    } else if (datasheetRecord.datasheet.project.urlField === 'utah-water-watch') {
      locId = ['f0bfbd7c-3d5e-4eba-b9fe-966006071a6c', '8f541cd1-f359-4005-86d2-876fa2365ff1']
    }
    if (locId) {
      fetchURL = fetchURL + "&location.id[]=" + locId.join("&location.id[]=")
    }
  }
  return fetch(fetchURL, {method: 'GET'})
    .then(response => response.json())
    .then((data) => {
      return data
    })
    .catch((error) => {
      return error
    })
}

const actions = {
  /**
   * @function getScorecards - retrieve hub scorecards from API + the latest observation for each scorecard
   * @param commit
   * @param state
   * @param rootState
   * @param orgUrl
   * @returns {Promise<T | never> | * | *}
   */
  get({commit, state, rootState}) {
    commit(types.RESET)
    commit(types.SET_SCORECARDS_LOADING)
    return fetch('/organizations/' + rootState.organization.get.organization.url + '/scorecards')
      .then(response => response.json())
      .then((scorecards) => {
        scorecards['hydra:member'].forEach(score => {
          // Most recent observation for each scorecard is fetched using the datasheet record
          fetchObservation({commit, rootState}, score.datasheetRecord)
            .then((data) => {
              // Handle responses due to lack of permission/no observations
              if (JSON.stringify(data).indexOf('Error:') !== -1) {
                // data.hasOwnProperty('code') && (data.code === 401 || data.code === 403)) {
                commit(types.SET_SCORECARD_OBSERVATIONS, {
                  scoreId: score.id,
                  error: 'No permission to get data'
                })
              } else {
                if (data.hasOwnProperty('hydra:totalItems')) {
                  if (data['hydra:totalItems'] > 0) {
                    let observation = data['hydra:member'].find(v => v)
                    let observationRecord = observation.records.find(rec =>
                      rec.datasheetRecord === score.datasheetRecord['@id']
                    )
                    commit(types.SET_SCORECARD_OBSERVATIONS, {
                      scoreId: score.id,
                      recordId: observationRecord.id,
                      value: observationRecord.value,
                      observation: observation['@id']
                    })
                  } else {
                    // No observations made for this datasheet record
                    commit(types.SET_SCORECARD_OBSERVATIONS, {
                      scoreId: score.id,
                      error: 'No observations made'
                    })
                  }
                } else {
                  commit(types.SET_SCORECARD_OBSERVATIONS, {
                    scoreId: score.id,
                    error: 'No permission to get data'
                  })
                }
              }
            })
        })
        commit(types.SET_SCORECARDS, scorecards['hydra:member'])
      })
      .catch((error) => {
        commit(types.SET_SCORECARDS_ERROR, error)
      })
  },

  createScorecard({commit, state, dispatch}, scorecardData) {
    commit(types.SET_SCORECARDS_LOADING);
    return fetch('/scorecards',
      {method: 'POST', body: JSON.stringify(scorecardData)})
      .then(response => response.json())
      .then((data) => {
        dispatch('get')
        return data
      })
      .catch((error) => {
        commit(types.SET_SCORECARDS_ERROR, error)
      })
  },

  updateScorecard({commit, dispatch}, scorecardData) {
    commit(types.SET_SCORECARDS_LOADING);
    return fetch('/scorecards/' + scorecardData.id, {
      method: 'PUT',
      body: JSON.stringify(scorecardData)
    })
      .then(response => response.json())
      .then((data) => {
        dispatch('get')
      })
      .catch((error) => {
        commit(types.SET_SCORECARDS_ERROR, error)
      })
  },

  deleteScorecard({commit, dispatch}, scorecard) {
    commit(types.SET_SCORECARDS_LOADING);
    return fetch('/scorecards/' + scorecard.id, {method: 'DELETE'})
      .then(response => {
        return response.status === 204
      })
      .then((data) => {
        dispatch('get')
      })
      .catch((error) => {
        commit(types.SET_SCORECARDS_ERROR, error)
      })
  },
}

const types = {
  SET_SCORECARDS_LOADING: 'SET_SCORECARDS_LOADING',
  SET_SCORECARDS: 'SET_SCORECARDS',
  SET_SCORECARD_OBSERVATIONS: 'SET_SCORECARD_OBSERVATIONS',
  SET_SCORECARDS_ERROR: 'SET_SCORECARDS_ERROR',
  RESET_ERROR: 'RESET_ERROR',
  RESET: 'RESET',
}

const mutations = {
  [types.SET_SCORECARDS_LOADING](state) {
    state.isLoading = true;
  },
  [types.SET_SCORECARDS](state, scorecards) {
    state.scorecards = scorecards
    state.isLoading = false
  },
  [types.SET_SCORECARD_OBSERVATIONS](state, observations) {
    state.observations.push(observations)
  },
  [types.SET_SCORECARDS_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },
  [types.RESET_ERROR](state) {
    state.isLoading = false;
    state.error = false;
  },
  [types.RESET](state) {
    Object.assign(state, getInitialState());
  },
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
}
