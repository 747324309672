<template>
  <span v-if="isLoading"><i class="fa fa-spinner"></i> Loading data... Please be patient.</span>
  <b-container class="mx-auto" fluid v-else>
    <label><b>Select Type of Data:</b></label>
    <div class="row">
      <div class="col-md-6">
        <b-form-radio v-model="toggleCategory" value="Numerical" inline>Numerical</b-form-radio>
        <b-form-radio v-model="toggleCategory" value="Categorical" inline>Categorical</b-form-radio>
      </div>
    </div>
    <div class="row mt-2" v-if="toggleCategory === 'Numerical'">
      <div class="col-md-12">
                <b-dropdown v-if="labelsNumeric.length > 0"
                            no-flip
                            text="Select Numerical Measurements to View Trends">
                  <b-dropdown-form>
                    <b-form-checkbox-group
                      stacked
                      :options="labelsNumeric"
                      v-model="selectedMeasurements">
<!--                      class="d-flex flex-column mx-auto">-->
                    </b-form-checkbox-group>
                  </b-dropdown-form>
                </b-dropdown>
        <label v-else>No Numerical Measurements Available</label>
      </div>
    </div>
    <div class="row mt-2" v-if="toggleCategory === 'Categorical'">
      <div class="col-md-12">
        <b-dropdown v-if="labelsCategorical.length > 0"
                    no-flip
                    text="Select Categorical Measurements to View Trends">
          <b-dropdown-form>
            <b-form-checkbox-group
              stacked
              :options="labelsCategorical"
              v-model="selectedMeasurements">
              <!--                      class="d-flex flex-column mx-auto">-->
            </b-form-checkbox-group>
          </b-dropdown-form>
        </b-dropdown>
        <label v-else>No Categorical Measurements Available</label>
      </div>
    </div>
    <br>
    <div v-for="(measurement) in observations">
      <ScatterChart v-if="measurement.type === 'number'" class="mb-2"
        :chart-title=measurement.label
        :chart-data=measurement.values
        :chart-y-categories=measurement.yCat>
      </ScatterChart>
      <ScatterChartCategorical v-else class-mb-2
        :chart-title=measurement.label
        :chart-data=measurement.values
        :chart-y-categories=measurement.yCat>
      </ScatterChartCategorical>
    </div>
  </b-container>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapMutations
} from 'vuex'

import ScatterChart from "./ScatterChartNumerical";
import ScatterChartCategorical from "./ScatterChartCategorical";

export default {
  components: { ScatterChart, ScatterChartCategorical },
  props: {
    locationId: {
      type: String,
      default: () => {
        return null
      },
      required: true,
    },
  },

  data() {
    return {
      toggleCategory: null,
      selectedMeasurements: Array(),
    }
  },

  mounted() {
    this.fetchObservations({
      type: 'location',
      id: this.locationId
    })
  },

  watch: {
    labelsNumeric(newVal) {
      if (newVal && newVal.length > 0 && !this.toggleCategory) {
        this.toggleCategory = 'Numerical'
      }
    },
    labelsCategorical(newVal) {
      if (newVal && newVal.length > 0 && !this.toggleCategory) {
        this.toggleCategory = 'Categorical'
      }
    },
    toggleCategory(newVal) {
      if (newVal === 'Numerical' && this.labelsNumeric.length > 0) {
        this.selectedMeasurements = [this.labelsNumeric[0]]
      } else if (newVal === 'Categorical' && this.labelsCategorical.length > 0) {
        this.selectedMeasurements = [this.labelsCategorical[0]]
      } else {
        this.selectedMeasurements = []
      }
    },
    selectedMeasurements(newVal) {
      this.setLabel(newVal)
    },
  },

  computed: {
    ...mapGetters({
      observations: 'analysis/observationsFiltered',
      labelsNumeric: 'analysis/labelsNumeric',
      labelsCategorical: 'analysis/labelsCategorical',
      isLoading: 'analysis/isLoading',
    }),
    oddEvenArray() {
      const [evens, odds] = this.labelsCategorical.reduce((res, item, i) => {
        res[i % 2].push(item)
        return res
      }, [[], []])
      return {evens: evens.sort((a, b) => a[1] > b[1] ? 1 : -1), odds: odds.sort((a, b) => a[1] > b[1] ? 1 : -1)}
    },
    chunkArray() {
      let result = [];
      const size = Math.ceil(this.labelsCategorical.length / 3);
      for (let i = 0; i < this.labelsCategorical.length; i += size) {
        let chunk = this.labelsCategorical.slice(i, i + size);
        result.push(chunk);
      }
      console.log(result)
      return result;
    },
  },

  methods: {
    ...mapActions({
      fetchObservations: 'analysis/fetchObservations',
    }),
    ...mapMutations({
      setLabel: "analysis/setLabel"
    }),
  }

}
</script>
