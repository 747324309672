<template>
  <div class="row observation-record-orgaism mb-3">
    <div class="col-12 h4 bg-cit-green text-white py-2 mb-0 border border-cit-green-light">
      <span
        >Organism:
        </span
      >

      <span> 
        {{
          record.organism.customName
            ? record.organism.customName
            : record.organism.canonicalName && record.organism.scientificName
            ? record.organism.canonicalName +
              " (" +
              record.organism.scientificName +
              ")"
            : ""
        }}

      </span>
    </div>
    <div
      class="
        col-md-11
        offset-md-1
        bg-white
        border-cit-green-light
        border-left border-right border-bottom
      "
    >
      <observation-record-item
        v-for="subRecord in record.records"
        :record="subRecord"
        v-bind:key="subRecord['@id']"
      >
      </observation-record-item>
    </div>
  </div>
</template>

<script>
export default {
  name: "ObservationRecordOrganism",
  beforeCreate: function () {
  this.$options.components.ObservationRecordItem = require('./ObservationRecordItem.vue').default
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
};
</script>
