//
// using the store for this makes things more complicated,
// however it will allow GLOBAL uploads in the future & parellel uploads
// in the future
//

import SubmissionError from '../../../../error/SubmissionError'
import fetch from '../../../../utils/fetch'


const initialState = {
    files: [
        // un-uploaded files
    ],
    uploads: [
        // array of uploads

        // status
        // isUploading, uploaded, error

        // '@id'
        // either server side uuid or initial id

        // blob
        // will be converted to approriate file for upload
        //

        // path
        // path for file


        // action_type
        // upload type

        // objectID
        // object that this file object is going to asscoiate with
    ],
    uploadTemplate: {
        status: null,
        //progress: null,
        error: null,
        '@id': null,
        blob: null,
        path: null,
        action_type: null,
    }
}

const FILE_STATUS = {
    TO_UPLOAD: "TO_UPLOAD",
    IS_UPLOADING: "IS_UPLOADING",
    ERROR: "ERROR",
    SUCCESS: "SUCCESS",
}

const ACTION_TYPES = {
    // currently supported action types by this store
    forum_file: {
        name: "forum_file",
        extensions: ["jpg", "jpeg", "png"]
    },
}

export const types = {
    ADD_FILES: "ADD_FILES",
    START_UPLOAD: "START_UPLOAD",
    //PROGRESS_UPLOAD: "PROGRESS_UPLOAD",
    FINISH_UPLOAD: "FINISH_UPLOAD",
    ERROR_UPLOAD: "ERROR_UPLOAD",
    REMOVE_UPLOAD: "REMOVE_UPLOAD",
    REMOVE_UPLOADS: "REMOVE_UPLOADS"
}

const getters = {
    isUploadingCount(state) {
        return (state.uploads.filter((upload) => {
            return upload.status === FILE_STATUS.IS_UPLOADING
        }).length)
    },
    files(state){
        return state.files;
    },
    uploads(state) {
        return state.uploads
    },
    uploadIRIS(state){
        return state.uploads.map((upload)=>{
            return upload['@id']
        })
    },
    uploadById: (state) => (id) => {
        return state.uploads.find(upload => upload['@id'] === id)
    }
}

const mutations = {
    [types.ADD_FILES](state, payload) {
        state.files = payload.map((newFile)=>{
            newFile.url = ''
            let URL = window.URL || window.webkitURL
            if (URL && URL.createObjectURL) {
                newFile.url = URL.createObjectURL(newFile.file)
            }
            return newFile
        })
    },
    [types.START_UPLOAD](state, payload) {
        state.uploads.push({
            ...payload,
            status: FILE_STATUS.IS_UPLOADING,
            progress: 10,
        })
    },
    // [types.PROGRESS_UPLOAD](state, payload) {
    //     state.uploads = state.uploads.map((upload) => {
    //         return (payload.name === upload.name
    //             ? { ...upload, progress: payload.progress }
    //             : upload)
    //     })
    // },
    [types.FINISH_UPLOAD](state, payload) {
        const uploadNew = {
            status: FILE_STATUS.SUCCESS,
            progress: 100,
            path: payload.path,
            id: payload.id,
            ...payload.response
        }

        state.uploads = state.uploads.map((upload) => {
            return (payload.name === upload.name
                ? { ...upload, ...uploadNew }
                : upload
            )
        })
    },
    [types.ERROR_UPLOAD](state, payload) {
        const uploadNew = {
            status: FILE_STATUS.ERROR,
            progress: 0,
            error: payload.error instanceof SubmissionError
                ? payload.errors._error
                : payload.error.message
        }
        state.uploads = state.uploads.map((upload) => {
            return (payload["@id"] === upload["@id"]
                ? { ...upload, ...uploadNew }
                : upload
            )
        })
    },
    [types.REMOVE_UPLOAD](state, payload) {
        state.uploads = state.uploads.filter((upload) => {
            return (payload.name !== upload.name)
        })
    },
    [types.REMOVE_UPLOADS](state) {
        state.uploads = []
        state.files = []
    }
}

const actions = {
    // will take an array of objects or a single upload
    addFiles({commit}, files){
        commit(types.ADD_FILES, files)
    },
    async uploadFile({ commit }, filePayload) {

        const fileObject = {
            blob: filePayload.file,
            path: URL.createObjectURL(filePayload.file),
            name: filePayload.file.name,
            action_type: ACTION_TYPES['forum_file'].name,
        }
        commit(types.START_UPLOAD, fileObject)

        // build form data
        const formData = new FormData()
        formData.append('file', fileObject.blob, fileObject.name)
        formData.append('actionType', ACTION_TYPES['forum_file'].name)

        return fetch('/file_objects', {
            method: 'POST',
            body: formData
        }).then((response) => {
            return response.json()
        }).then((data) => {
            commit(types.FINISH_UPLOAD, { response: data, name: fileObject.name })
        }).catch((error) => {
            commit(types.ERROR_UPLOAD, { error, name: fileObject.name })
        })
    },
    uploadFiles({dispatch, state }){
        const uploadRequests = state.files.map((file)=>{
            return dispatch('uploadFile', file).then(()=>{
                // console.log("file upload promise")
            })
        })
        return Promise.all(uploadRequests).then(()=>{
            // console.log("all files uploaded")
        })
    }
}

export default {
    namespaced: true,
    state: initialState,
    getters: getters,
    actions: actions,
    mutations: mutations,
}
