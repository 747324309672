<template>
  <div v-if="locationId" class="pb-3">
    <div class="row mb-3" v-if='false'>
      <h3 class="mb-0 col-md-6">Observations</h3>
      <div class="input-group col-md-6">
        <b-input-group-prepend>
          <span
            class="btn btn-secondary"
            alt="Search by location"
            title="Search by location"
          >
            <i v-if="!isBusy" class="fas fa-search"></i>
            <i v-if="isBusy" class="fas fa-spinner fa-spin"></i>
          </span>
        </b-input-group-prepend>
        <b-form-input
          class="form-control"
          placeholder="Search by Location Name"
          name="search"
          type="search"
          v-model="searchText"
        ></b-form-input>
        <div class="input-group-append" v-if="searchText">
          <b-button @click="searchText = null"
            ><i class="fas fa-times-circle"></i
          ></b-button>
        </div>
      </div>
    </div>
    <b-row align-h="between">
      <div  class="my-1 col-md-3">
        Showing {{ fromItem }} to {{ toItem }} of {{ totalItems }} observations
      </div>

      <div class="col-md-3 my-1">
        <b-pagination
          :total-rows="totalItems"
          :per-page="perPage"
          v-model="currentPage"
          class="my-0 pagination-holder"
          prev-text="Prev"
          next-text="Next"
          align="fill"
        />
      </div>

      <div class="col-md-3 my-1">
        <b-form-group
          label="Per Page"
          label-align="right"
          label-cols-sm="7"
          label-cols-md="8"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
            align="right"
          ></b-form-select>
        </b-form-group>
      </div>
    </b-row>
    <b-table
      show-empty
      stacked="md"
      id="observationsTable"
      ref="observationsTable"
      filter-debounce="1000"
      :filter="`location.name=${searchText}`"
      :busy="isBusy"
      :items="observations"
      :fields="tableFields"
      :current-page="currentPage"
      :per-page="perPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
    >
      <template v-slot:cell(location.name)="row">
        <router-link
          :to="{ name: 'LocationShow', params: { id: row.item.location.id } }"
        >
          {{ row.item.location.name }}
        </router-link>
      </template>
      <template v-slot:cell(options)="row">
        <div>
          <b-btn
            class="mt-1 mr-1"
            :to="{ name: 'ObservationShow', params: { id: row.item.id } }"
            alt="View Observation"
            title="View Observation"
          >
            <font-awesome-icon icon="eye" /> View
          </b-btn>
          <b-btn
            v-if="
              row.item.project.id && membershipMap[row.item.project.id ] && membershipMap[row.item.project.id].isManager
            "
            class="mt-1"
            @click="onClickDelete(row.item)"
            alt="Delete this Observation from the Project"
            title="Delete this Observation from the Project"
          >
            <i class="fa fa-trash"></i> Delete
          </b-btn>
        </div>
      </template>
      <template #table-busy>
        <VueLoader style="height: 200px" text="Loading Observations..." />
      </template>
    </b-table>
    <b-row align-h="between">
      <div  class="my-1 col-md-3">
        Showing {{ fromItem }} to {{ toItem }} of {{ totalItems }} observations
      </div>

      <div class="col-md-3 my-1">
        <b-pagination
          :total-rows="totalItems"
          :per-page="perPage"
          v-model="currentPage"
          class="my-0 pagination-holder"
          prev-text="Prev"
          next-text="Next"
          align="fill"
        />
      </div>

      <div class="col-md-3 my-1">
        <b-form-group
          label="Per Page"
          label-align="right"
          label-cols-sm="7"
          label-cols-md="8"
          label-for="perPageSelect"
          class="mb-0"
        >
          <b-form-select
            v-model="perPage"
            id="perPageSelect"
            size="sm"
            :options="pageOptions"
            align="right"
          ></b-form-select>
        </b-form-group>
      </div>
    </b-row>
    <b-modal id="deleteObservationModal" title="Delete Observation" centered>
      <div v-if="selectedObservation">
        <p class="my-4">
          Do you want to remove this observation from the project? This cannot
          be undone.
        </p>
        <div>
          <span class="font-weight-bold">Observed at: </span>
          {{ displayDate(selectedObservation.observedAt) }}
        </div>
        <div>
          <span class="font-weight-bold">Located at: </span>
          {{ selectedObservation.location.name }}
        </div>
      </div>
      <template slot="modal-footer">
        <div>
          <b-btn variant="danger" @click="onConfirmDelete()">Yes, delete</b-btn>
          <b-btn @click="closeModal">No</b-btn>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueLoader from "../../../components/layout/Loader";

export default {
  name: "ObservationsTable",
  components: {
    VueLoader,
  },
  props: {
    locationId: String,
  },
  data() {
    return {
      pageOptions: [10, 25, 50, 100],
      isBusy: false,
      showModal: null,
      selectedObservation: null,
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      searchText: "",
      sortBy: "observedAt",
      sortDesc: true,
      sortDirection: "desc",
      tableFields: [
        {
          key: "observedAt",
          label: "Date Observed",
          sortable: true,
          tdClass: "text-dark",
          formatter: (val) => {
            return this.displayDate(val);
          },
        },
        {
          key: "createdAt",
          label: "Date Created",
          sortable: true,
          tdClass: "text-dark",
          formatter: (val) => {
            return this.displayDate(val);
          },
        },
        {
          key: "datasheet.name",
          label: "Datasheet",
          sortable: true,
          tdClass: "text-dark",
          formatter: (val) => {
            return val;
          },
        },
        {
          key: "observers.user.username",
          label: "Observed By",
          sortable: false,
          formatter: (val, fieldName, item) => {
            return item && item.observers && item.observers[0] && item.observers[0].user && item.observers[0].user.displayName ;
          },
        },
        {
          key: "options",
          label: "Options",
          sortable: false,
          class: "text-right py-0",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      membershipMap: "user/profile/membershipMap",
    }),
    fromItem() {
      return this.totalItems > 0
        ? (this.currentPage - 1) * this.perPage + 1
        : 0;
    },
    toItem() {
      return this.totalItems < this.currentPage * this.perPage
        ? this.totalItems
        : this.currentPage * this.perPage;
    },
  },
  methods: {
    ...mapActions({
      fetchObservations: "location/show/getObservationForLocation",
      deleteObservation: "observation/show/deleteObservation",
    }),
    observations(ctx) {
      this.isBusy = true;
      let urlParams = `${this.urlBuilder(ctx)}`;
      const data = { id: this.locationId, urlParams };
      return this.fetchObservations(data).then((data) => {
        this.totalItems = data["hydra:totalItems"];
        this.currentPage = ctx.currentPage || 1;
        this.sortBy = ctx.sortBy || "";
        this.isBusy = false;
        return data["hydra:member"].map((observation) => {
          return {
            ...observation,
          };
        });
      });
    },

    closeModal() {
      this.$bvModal.hide("deleteObservationModal");
      this.selectedObservation = null;
    },
    onClickDelete(observation) {
      this.selectedObservation = { ...observation };
      this.$bvModal.show("deleteObservationModal");
    },
    onConfirmDelete() {
      this.deleteObservation({
        id: this.selectedObservation.id,
      }).then(() => {
        this.$refs.observationsTable.refresh();
      });
      this.closeModal();
    },
  },
  mounted() {},
};
</script>

<style>
</style>
