import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('user/forgotPassword')

export default {
  data () {
    return {
      item: {},
      pageTitle: '',
      submitButtonName: '',
      isUpdatePassword: false
    }
  },

  created () {
    this.$validator.validateAll()

    if (this.$route.name === 'UpdatePassword') {
      this.pageTitle = 'Update Password'
      this.submitButtonName = 'Update my password'
      this.isUpdatePassword = true
    } else {
      this.pageTitle = 'Forgot Password'
      this.submitButtonName = 'Reset my password'
      this.isUpdatePassword = false
    }
  },

  computed: {
    ...mapGetters([
      'error',
      'isLoading',
      'violations',
      'sent'
    ]),

    isFilled () {
      return this.item.email && !this.errors.items.length
    }
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    sent: function (sent) {
      if (!sent) {
        return
      }

      this.$router.push({ name: 'Login' })
    }
  },

  methods: {
    ...mapActions([
      'sendRequest'
    ]),

    onForgotPassword () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.sendRequest(this.item)
        }
      })
    },

    isInvalid (key) {
      return (this.violations !== null && Object.keys(this.violations).length > 0 && this.violations[key]) || this.errors.has(key)
    },

    getError (key) {
      if (this.errors.has(key)) {
        return this.errors.first(key)
      }

      if (this.violations && Object.keys(this.violations).length > 0 && this.violations[key]) {
        return this.violations.key
      }
    }
  },
}
