<template>
  <div>
    <h3>Your Assigned Locations</h3>
    <div class="card-body">
      <div class="row justify-content-between mb-3">
      </div>
      <div class="row justify-content-between">
        <b-col md="3" class="my-1">
          Showing {{ fromItem }} to {{ toItem }} of
          {{ totalItems }} assigned location<span
          v-if="totalItems === 0 || totalItems > 1"
        >s</span
        >
        </b-col>

        <b-col sm="2" md="3" class="my-1">
          <b-pagination
            :total-rows="totalItems"
            :per-page="perPage"
            v-model="currentPage"
            class="my-0 pagination-holder"
            prev-text="Prev"
            next-text="Next"
            align="fill"
          />
        </b-col>

        <b-col sm="2" md="3" class="my-1">
          <b-form-group
            label="Per Page"
            label-align="right"
            label-cols-sm="7"
            label-cols-md="8"
            label-for="perPageSelect"
            class="mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
      </div>
    </div>

    <b-table show-empty
             stacked="md"
             id="assignedLocationsTable"
             ref="assignedLocationsTable"
             :busy="isBusy"
             :items="locations"
             :fields="tableFields"
             :current-page="currentPage"
             :per-page="perPage"
             sort-icon-left
             :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc"
             :sort-direction="sortDirection"
             :empty-text="emptyText"
    >
      <template #table-busy>
        <VueLoader style="height: 200px" text="Loading Your Assigned Locations..."/>
      </template>
      <template #cell(type)="row">
        {{ row.item.datasheet ? 'Datasheet Specific' : 'Project Level' }}
      </template>
      <template  #cell(location.name)="row">
        {{ row.item.location.name }} ({{ row.item.location.latitude }}, {{ row.item.location.longitude }})
      </template>
    </b-table>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import VueLoader from "../../../layout/Loader.vue";
import RequiresMember from "../tabpermission/requiresMember.vue";

export default {
  components: {
    RequiresMember,
    VueLoader
  },
  data() {
    return {
      pageOptions: [10, 25, 50, 100, 250, 500],
      isBusy: false,
      tableFields: [
        {key: "type", label: "Assignment Type", sortable: false, sortDirection: "asc",},
        {key: "datasheet.name", label: "Datasheet", sortable: true, sortDirection: "asc",},
        {key: "location.name", label: "Location", sortable: true, sortDirection: "asc",},
        {
          key: "updatedAt",
          label: "Date Created",
          sortable: true,
          sortDirection: "desc",
          formatter: val => {
            return this.displayDate(val);
          }
        },
      ],
      emptyText: "No Assigned Locations Yet",
      searchText: "",
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      sortBy: "updatedAt",
      sortDesc: true,
      sortDirection: "desc",
    };
  },

  created() {
    if (this.projectVuex) {
      if (this.$refs.assignedLocationsTable.refresh())
        this.$refs.assignedLocationsTable.refresh()
    }
  },
  watch: {
    projectVuex(newVal) {
      if (newVal) {
        if (this.$refs.assignedLocationsTable.refresh())
          this.$refs.assignedLocationsTable.refresh()
      }
    },
  },
  destroyed() {
  },
  computed: {
    ...mapGetters({
      projectVuex: "project/get/project",
      isPublicOrMember: "project/get/isPublicOrMember",
      membershipMap: "user/profile/membershipMap",
      isLoading: "project/assigned_locations/isLoading",
    }),
    fromItem() {
      return this.totalItems > 0
        ? (this.currentPage - 1) * this.perPage + 1
        : 0;
    },
    toItem() {
      return this.totalItems < this.currentPage * this.perPage
        ? this.totalItems
        : this.currentPage * this.perPage;
    }
  },
  methods: {
    ...mapActions({
      getAssignedLocations: "project/assigned_locations/getMember",
    }),

    locations(ctx) {
      // Busy state is set
      this.isBusy = true;

      // URL Params are set
      let urlParams = this.urlBuilder(ctx);

      // Assigned locations are fetched
      return this.getAssignedLocations(urlParams).then(data => {
        console.log('data', data)
        this.totalItems = data["hydra:totalItems"];
        this.currentPage = ctx.currentPage || 1;
        this.sortBy = ctx.sortBy || "";
        this.isBusy = false;
        return data["hydra:member"].map(locations => {
          return {
            ...locations
          };
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
