import SubmissionError from '../../../../error/SubmissionError'
import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const manageProjectMembersInvitePeople = ({ commit }, values) => {
  commit(types.INVITE_PEOPLE_SET_ERROR, '')
  commit(types.INVITE_PEOPLE_TOGGLE_LOADING)

  return fetch('/invite-people', { method: 'POST', body: JSON.stringify(values) })
    .then((response) => {
      commit(types.INVITE_PEOPLE_TOGGLE_LOADING)

      return response.json()
    })
    .then((data) => {
      commit(types.INVITE_PEOPLE_SET_CREATED, data)
    })
    .catch((e) => {
      commit(types.INVITE_PEOPLE_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.INVITE_PEOPLE_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.INVITE_PEOPLE_SET_ERROR, e.errors._error)
        return
      }

      commit(types.INVITE_PEOPLE_SET_ERROR, e.message)
    })
}

export const manageProjectMembersEmailMembers = ({ commit }, values) => {
  commit(types.EMAIL_MEMBERS_SET_ERROR, '')
  commit(types.EMAIL_MEMBERS_TOGGLE_LOADING)

  return fetch('/email-members', { method: 'POST', body: JSON.stringify(values) })
    .then((response) => {
      commit(types.EMAIL_MEMBERS_TOGGLE_LOADING)

      return response.json()
    })
    .then((data) => {
      commit(types.EMAIL_MEMBERS_SET_CREATED, data)
    })
    .catch((e) => {
      commit(types.EMAIL_MEMBERS_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.EMAIL_MEMBERS_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.EMAIL_MEMBERS_SET_ERROR, e.errors._error)
        return
      }

      commit(types.EMAIL_MEMBERS_SET_ERROR, e.message)
    })
}

export const resetInvitePeople = ({ commit }) => {
  commit(types.INVITE_PEOPLE_RESET)
}

export const resetEmailMembers = ({ commit }) => {
  commit(types.EMAIL_MEMBERS_RESET)
}
