<template>
  <v-dialog
    v-model="dialogValue"
    :max-width="maxWidth"
    persistent
  >
    <v-card>
      <v-card-title
        v-if="dialogTitle"
        class="headline"
      >
        {{ dialogTitle }}
      </v-card-title>

      <v-card-text>
        <slot name="body"></slot>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          @click.native="dialogValue = false"
          color="#00aaef"
          flat
        >
          {{ disagreeButtonText }}
        </v-btn>

        <v-btn
          @click.native="handleSubmit"
          color="#00aaef"
          flat
        >
          {{ agreeButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <VueLoader
      v-if="isLoading"
    />
  </v-dialog>
</template>

<script>
  import VueLoader from './Loader'

  export default {
    name: 'vue-modal',

    components: {
      VueLoader
    },

    props: {
      dialog: {
        type: Boolean,
        default: false
      },

      dialogTitle: {
        type: String,
        default: ''
      },

      handleSubmit: {
        type: Function,
        default: () => {}
      },

      agreeButtonText: {
        type: String,
        default: 'Agree'
      },

      disagreeButtonText: {
        type: String,
        default: 'Disagree'
      },

      maxWidth: {
        type: Number,
        default: 400
      },

      isLoading: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        dialogVal: this.dialog
      }
    },

    watch: {
      dialog (val) {
        this.dialogValue = val
      }
    },

    computed: {
      dialogValue: {
        // getter
        get: function () {
          return this.dialogVal
        },
        // setter
        set: function (newValue) {
          this.dialogVal = newValue

          // handle data and give it back to parent by interface
          this.$emit('interface', this.dialogVal)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
/deep/ .v-dialog {
  position: relative;
}
</style>
