<template>
  <div>
    <div v-if="isMember" class="card-body">
      <ManagerView v-if="isManager"></ManagerView>
      <MemberView v-else></MemberView>
    </div>
    <RequiresMember v-else page="Assigned Locations"></RequiresMember>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RequiresMember from "../tabpermission/requiresMember.vue";
import MemberView from "./memberView.vue"
import ManagerView from "./managerView.vue"

export default {
  components: {
    RequiresMember,
    MemberView,
    ManagerView
  },
  data() {
    return {};
  },

  created() {},
  watch: {},
  destroyed() {},
  computed: {
    ...mapGetters({
      projectVuex: "project/get/project",
      isMember: "project/get/isMember",
      isManager: "project/get/isManager",
    }),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
</style>
