<template>
  <b-modal id="not-logged-in-modal" title="You are not logged in" ref="loginModal">
    <p class="my-4">Please log in to continue.</p>
    <p class="my-4">Thanks, The CitSci.org Team</p>
    <div slot="modal-footer">
      <b-button variant="primary" @click="clickCloseBtn">Close</b-button>
      <b-button variant="primary" @click="clickLoginBtn">Login</b-button>
    </div>
  </b-modal>
</template>
<script>
  export default {
    methods: {
      clickLoginBtn() {
        this.$router.push({name: 'Login', query: { redirect: this.$router.history.current.fullPath }})
        this.$refs.loginModal.hide()
      },
      clickCloseBtn() {
        this.$refs.loginModal.hide()
      }
    }
  }
</script>
