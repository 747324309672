

export default {
  namespaced: true,
  state: {
        sessionData: JSON.parse(window.localStorage.getItem('sessionData')),
        tokenBearer: window.localStorage.getItem('tokenBearer'),
        tokenRefresh: window.localStorage.getItem('tokenRefresh'),
    },
  actions:{
    updateSession (context) {
        /// call api endpoint
    
    },
  },
  getters:{
    isAuthenticated: state => {

    },
    isAuthenticatedValid: state => {

    },
    myId: state => {

    },
    getMyProjects: state => {
        return state.sessionData.memberships
    }
  },
  mutations:{

  }
}
