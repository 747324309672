<template>
  <div>
    <div v-if="isPublicOrMember">
      <div v-if="projectId && !projectLoading" class="card-body">
        <h3>Results</h3>

        <div>
          <b-form-group
            id="datasheet-selector-group"
            label-cols-sm="auto"
            label-cols-lg="auto"
            content-cols-lg="6"
            label="Results for Datasheet:"
            label-for="datasheet-selector"
            label-class="font-weight-bold"
          >
            <b-form-select id="datasheet-selector" name="datasheet-selector" v-model="selectedDatasheetForResults">
              <b-form-select-option v-for="datasheet in publishedDatasheets" :value="datasheet.id" :key="datasheet.id">
                {{ datasheet.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </div>

        <DatasheetRecords v-if="selectedDatasheetForResults" :key="selectedDatasheetForResults"
                          :datasheet-id="selectedDatasheetForResults">
        </DatasheetRecords>

      </div>
    </div>
    <RequiresMember v-else page="Results"></RequiresMember>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import DatasheetRecords from "./datasheetRecords.vue";
import RequiresMember from "../tabpermission/requiresMember.vue";

export default {
  components: {
    DatasheetRecords,
    RequiresMember,
  },
  data() {
    return {
      selectedDatasheetForResults: null,
    };
  },
  created() {
    if (this.publishedDatasheets) {
      this.selectedDatasheetForResults = this.publishedDatasheets[0].id;
    }
  },
  watch: {
    publishedDatasheets(newVal) {
      if (newVal && newVal.length > 0) {
        this.selectedDatasheetForResults = newVal[0].id;
      }
    }
  },
  computed: {
    ...mapGetters({
      project: 'project/get/project',
      projectId: 'project/get/id',
      projectLoading: 'project/get/isLoading',
      isPublicOrMember: 'project/get/isPublicOrMember',
      publishedDatasheets: 'project/get/publishedDatasheets',
    }),
  },
  methods: {
    ...mapActions({}),
  }
}
</script>

<style lang=""></style>
