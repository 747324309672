<template>
  <b-modal id="new-discussion-modal"
           title="Start a New Forum Post"
           ref="newDiscussionModal"
           size="xl"
           button-size="sm"
           hide-header-close
           no-close-on-backdrop
           no-close-on-esc
           no-enforce-focus
           no-stacking
           centered
           hide-footer>
    <b-form @submit.prevent="submitDiscussionForm()" novalidate class="card mb-3">
      <div v-if="isEmailMembers" class="card-header">
        <span>
          Put the subject and content of your email into a new Forum Post.<br>
          Then hit 'Submit Post' to email all project members.<br>
          An option is provided to hide this forum post from project members.<br>
          Project manager's can see hidden forum post's.<br>
          Hidden forum post's are locked for replies.
        </span>
      </div>
      <div class="card-body p-0">
        <b-form-input
          id="subject-input-group"
          type="text" maxlength="100"
          name="Title"
          autofocus size="lg"
          placeholder="Forum Post Title"
          v-model="subject"
          v-validate="'required|min:5'"
          :class="{ 'is-invalid': errors.has('Title') }"
          aria-describedby="subject-input-live-feedback">
        </b-form-input>
        <b-form-invalid-feedback id="subject-input-live-feedback">
          {{ errors.first("Title") }}
        </b-form-invalid-feedback>
        <AddFile></AddFile>
        <div id="app" class="editor-head">
          <ckeditor
            :editor="editor"
            v-model="discussionText"
            :config="editorConfig"
            @ready="onEditorReady"
          ></ckeditor>
        </div>
        <div v-show="showError" class="required-feedback">
          <span>The Forum Post cannot be empty</span>
        </div>
        <AddTags :discussion=discussion :read-only=false></AddTags>
      </div>

      <div class="card-footer">
        <div class="pb-2" v-if="isManager">
          <div class="row">
            <b-col sm="4" md="4">
              <b-checkbox v-if="isManager" :disabled="isEmailMembers" v-model="isEmailMembersValue"
                          :class="{ shake: animateCheckbox }">
                Email All Members of this Forum Post
              </b-checkbox>
            </b-col>
          </div>
          <div class="row mt-1">
            <div class="mr-4"></div>
            <b-col sm="11" md="11">
              <b-checkbox v-if="isManager && isEmailMembersValue" v-model="isHiddenValue">
                Hide forum post from the forum tab (Use this option to only email all your members, Managers can
                see all posts.)
              </b-checkbox>
            </b-col>
          </div>
        </div>
        <div class="row mt-2 justify-start-ns justify-start">
          <div class="col-2 mr-2">
            <b-button class=""
                      variant="tertiary "
                      type="submit"
                      title="Submit New Forum Post"
                      alt="Submit New Forum Post"
                      :disabled='isLoading'>
                    <span v-if='isUploadingCount'>
                        <i class='fa fa-spinner fa-spin'></i>
                        Uploading {{ uploads.length - isUploadingCount + 1 }} of {{ uploads.length }}
                    </span>
              <span v-else-if='isLoading'>
                      <i class='fa fa-spinner fa-spin'></i>
                      Posting
                    </span>
              <span class='' v-else>
                      Submit Post
                    </span>
            </b-button>
          </div>
          <div class="col-2 ml-2">
            <b-button variant="secondary"
                      title="Cancel Forum Post"
                      alt="Cancel Forum Post"
                      @click="onCancelDiscussion">Cancel
            </b-button>
          </div>
        </div>
      </div>
    </b-form>
  </b-modal>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DOMPurify from 'dompurify';
import VueLoader from '../../../layout/Loader.vue'
import AddTags from "./tagsAddMultiselect.vue"
import AddFile from './forumPostAddFiles.vue'

export default {
  name: "NewDiscussionModal",
  components: {
    VueLoader,
    AddFile,
    AddTags,
  },
  props: {
    isEmailMembers: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      subject: "",
      discussionText: "",
      editor: ClassicEditor,
      editorConfig: {
        placeholder: "Start typing here...",
        removePlugins: [
          "MediaEmbed",
          "TableToolbar",
          "TableCellPropertiesUI",
          "Table",
          "Image",
          "MediaEmbed",
          "MediaEmbedUI",
          "MediaEmbedToolbar",
          "ImageToolbar",
          "ImageUploadEditing",
          "ImageUpload",
          "ImageUploadUI",
        ],
      },
      isEmailMembersValue: this.isEmailMembers,
      animateCheckbox: false,
      isHiddenValue: false,
      discussion: {tags: []},
      showRequiredError: false
    }
  },
  created() {
    if (this.isEmailMembers) {
      this.animateCheckbox = true
      setTimeout(() => {
        this.animateCheckbox = false
      }, 4000)
    }
  },
  watch: {
    discussionText(newVal, oldVal) {
      if (!newVal) {
        this.showError = true
      }
      if (newVal) {
        this.showError = false
      }
    }
  },
  computed: {
    ...mapGetters({
      isManager: "project/get/isManager",
      projectId: "project/get/id",
      isUploadingCount: "file/multiupload/isUploadingCount",
      uploads: "file/multiupload/uploads",
      isLoading: "project/forum/isLoading",
    }),
    showError: {
      get() {
        return this.showRequiredError
      },
      set(newVal) {
        this.showRequiredError = newVal
      }
    },
  },
  methods: {
    ...mapActions({
      startNewDiscussion: "project/forum/create",
    }),

    onEditorReady() {
      let elem = this.$el.ownerDocument.body.querySelector('.ck-balloon-panel')
      const addCustomStyle = () => elem.style.setProperty('z-index', '1071');
      this.$nextTick(addCustomStyle)
    },

    submitDiscussionForm() {
      this.discussionText = this.purify(this.discussionText)
      this.$validator.validateAll().then((result) => {
        // Validate reply exists
        if (this.discussionText.length === 0) {
          this.showError = true
          return
        }
        if (result) {
          this.startNewDiscussion({
            subject: this.subject,
            discussionText: this.discussionText,
            isEmailMembers: this.isEmailMembersValue,
            isHidden: this.isEmailMembersValue ? this.isHiddenValue : false,
            tags: this.discussion.tags,
          }).then((data) => {
            if (data && typeof data === "object") {
              this.$emit('submit')
              this.onCancelDiscussion()
            }
          });
        }
      });
    },
    purify(unsafeHTML) {
      return DOMPurify.sanitize(unsafeHTML, {
        USE_PROFILES: {
          html: true
        }
      })
    },
    onCancelDiscussion() {
      this.subject = ""
      this.discussionText = ""
      this.$bvModal.hide('new-discussion-modal');
    },
  },
}
</script>

<style lang="scss" scoped>

.required-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.shake {
  animation: shake 0.82s;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
