import ObservationShow from '../components/observation/Show'
import ObservationEdit from '../components/observation/edit/ObservationEdit.vue'
import ObservationList from '../components/observation/List'

export default [
  {
    name: 'ObservationShow',
    path: '/observation/show/:id',
    component: ObservationShow,
    props: true,
    meta: {requiresAuth: false}
  },
  {
    name: 'ObservationEdit',
    path: '/observation/edit/:id',
    component: ObservationEdit,
    meta: {hideFooter: true, requiresAuth: true}
  },
  {name: 'ObservationList', path: '/observations/', component: ObservationList, meta: {requiresAuth: false}}
]
