import * as types from './mutation_types'

export default {
  [types.FORGOT_PASSWORD_RESET] (state) {
    Object.assign(state, {
      error: '',
      isLoading: false,
      violations: null,
      sent: false
    })
  },

  [types.FORGOT_PASSWORD_SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },

  [types.FORGOT_PASSWORD_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.FORGOT_PASSWORD_SET_VIOLATIONS] (state, violations) {
    Object.assign(state, { violations })
  },

  [types.FORGOT_PASSWORD_SET_SENT] (state, sent) {
    Object.assign(state, { sent })
  },
}
