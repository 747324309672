<template lang="">
  <div>
    <b-modal
      id="edit-member"
      ref="editMember"
      size="xl"
      ok-title="Save All Changes"
      @ok="onAssignLocationsSaveClick"
      @cancel="onAssignLocationsCloseClick"
      @hidden="resetModal"
      no-close-on-backdrop
      centered
    >
      <template slot="modal-header" class="mx-auto">
        <h5 class="custom-title">Assign Member to Locations by Datasheet</h5>
        <button
          type="button"
          aria-label="Close"
          class="close ml-0 pl-0"
          @click="
            () => {
              this.$refs.editMember.hide();
            }
          "
        >
          &times;
        </button>
      </template>

      <b-container fluid :key="componentKey">
        <b-row class="mx-auto" align-h="between" no-gutters>
          <b-col sm="6" md="8" class="my-1">
            <b-form-group
              label="Member Name: "
              label-align="right"
              label-cols-sm="4"
              label-cols-md="6"
              label-for="memberName"
              class="mb-0"
              label-class="font-weight-bold"
            >
              <b-form-group
                :label="userDisplayName"
                label-align="left"
                label-cols-sm="4"
                label-cols-md="6"
                class="mb-0"
              ></b-form-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mx-auto" no-gutters>
          <b-col sm="6" md="8" class="my-1">
            <b-form-group
              label="Select a Datasheet: "
              label-align="right"
              label-cols-sm="4"
              label-cols-md="6"
              label-for="datasheetSelect"
              class="mb-0"
              label-class="font-weight-bold"
            >
              <b-form-select
                v-model="selectedDatasheet"
                id="datasheetSelect"
                :options="datasheets"
                value-field="id"
                text-field="name"
              >
                <template v-if="datasheets.length > 1" v-slot:first>
                  <option value="">-- Select One --</option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <br />
        <b-container v-if="selectedDatasheet" class="mx-auto">
          <b-container class="mx-auto">
            <b-row class="mx-auto" align-h="between" no-gutters>
              <b-col cols="5" align="left">
                <b-input-group size="md">
                  <b-input-group-prepend>
                    <span
                      class="btn btn-secondary"
                      alt="Start typing to search for locations"
                      title="Start typing to search for locations"
                    >
                      <i v-if="!isBusy" class="fas fa-search"></i>
                      <i v-if="isBusy" class="fas fa-spinner fa-spin"></i>
                    </span>
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="filterObject.filterName"
                    type="search"
                    id="filterInputName"
                    placeholder="Type to Search Locations by Name"
                    debounce="1500"
                    :disabled="isBusy"
                  ></b-form-input>
                  <b-input-group-append>
                    <div
                      :style="{
                        visibility: filterObject.filterName
                          ? 'visible'
                          : 'hidden'
                      }"
                    >
                      <b-button
                        :disabled="!filterObject.filterName"
                        @click="filterObject.filterName = null"
                        ><i class="fas fa-times-circle"></i
                      ></b-button>
                    </div>
                  </b-input-group-append>
                </b-input-group>
              </b-col>

              <b-col
                v-if="perPage > 10 && totalRows > 10"
                sm="2"
                md="3"
                class="my-1"
              >
                <b-form-group
                  v-if="!isBusy"
                  label="Per Page"
                  label-align="right"
                  label-cols-sm="7"
                  label-cols-md="8"
                  label-for="perPageSelect"
                  class="mb-0"
                >
                  <b-form-select
                    v-model="perPage"
                    id="perPageSelect"
                    :options="pageOptions"
                    size="sm"
                    align="right"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>

          <b-table
            :items="projectLocationsProvider"
            :fields="fieldList"
            :filter="filterVal"
            :busy="isBusy"
            :current-page="currentPage"
            :per-page="perPage"
            :total-rows="totalItems"
            sort-icon-left
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
            :empty-text="emptyText"
            :empty-filtered-text="emptyFilteredText"
            :striped="false"
            responsive="md"
            small
            pagination
            show-empty
            fixed
            hover
          >
            <template v-slot:empty="scope">
              <h6 align="center">{{ scope.emptyText }}</h6>
            </template>

            <template v-if="filterVal" v-slot:empty="scope">
              <h6 align="center">{{ scope.emptyFilteredText }}</h6>
            </template>

            <template v-slot:cell(assign)="row" class="options-holder">
              <b-form-checkbox
                :id="'checkbox1-' + row.item.id"
                :value="row.item['@id']"
                unchecked-value="not_assigned"
                checked-value="assigned"
                v-model="assignedLocations"
                :class="row.item.id + '=>' + row.item.checked"
                @change="locationToggled($event, row.item)"
              >
              </b-form-checkbox>
            </template>

            <template #table-busy>
              <VueLoader style="height:75px;" text="Loading Locations..." />
            </template>
          </b-table>

          <b-container class="mx-auto">
            <b-row class="mx-auto" align-h="between" no-gutters>
              <b-col v-if="totalItems > 0 && !isBusy" cols md="3" class="my-1">
                Showing {{ fromItem }} to {{ toItem }} of
                {{ totalItems }} location<span
                  v-if="totalItems === 0 || totalItems > 1"
                  >s</span
                >
              </b-col>

              <b-col sm="2" md="3" class="my-1">
                <b-pagination
                  v-if="!isBusy"
                  :total-rows="totalItems"
                  :per-page="perPage"
                  v-model="currentPage"
                  aria-controls="my-table"
                  prev-text="Prev"
                  next-text="Next"
                  align="fill"
                ></b-pagination>
              </b-col>

              <b-col sm="2" md="3" class="my-1">
                <b-form-group
                  v-if="!isBusy"
                  label="Per Page"
                  label-align="right"
                  label-cols-sm="7"
                  label-cols-md="8"
                  label-for="perPageSelect"
                  class="mb-0"
                >
                  <b-form-select
                    v-model="perPage"
                    id="perPageSelect"
                    :options="pageOptions"
                    size="sm"
                    align="right"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>
        </b-container>
      </b-container>
    </b-modal>

    <b-tooltip target="assign-member-to-location-info" placement="right">
      Some Assign Member to Locations information. We need text that should be
      added here!
    </b-tooltip>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import VueLoader from "../../../layout/Loader.vue";

export default {
  components: {
    VueLoader
  },

  props: {
    // Incoming user data
    projectId: {
      type: String,
      required: true
    },

    passedUserData: {
      type: Object,
      default: () => {
        return {
          memberId: null
        };
      }
    },

    passedDatasheets: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  data() {
    return {
      componentKey: 0,

      fieldList: [
        { key: "name", label: "Location Name", sortable: true },
        { key: "latitude", label: "Latitude", sortable: true },
        { key: "longitude", label: "Longitude", sortable: true },
        { key: "assign", label: "Assigned", sortable: false }
      ],

      userDisplayName: "",
      memberIdVal: null,
      memberIRI: null,

      selectedDatasheet: "",

      originalAssignedLocations: [],
      existingAssignedLocations: [],
      newAssignedLocationsTemplate: {
        datasheet: null,
        member: null,
        locations: []
      },
      assignedLocations: [],

      totalItems: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      sortBy: "name",
      sortDesc: false,
      sortDirection: "asc",
      isBusy: false,
      emptyText: "No Project Locations Available",
      emptyFilteredText: "No Project Locations Available for Search Criteria",
      filterObject: {
        filterName: null
      }
    };
  },

  watch: {
    passedUserData(data) {
      // Passed data is set to modal variables
      if (!data) {
        return;
      }

      if (!data.hasOwnProperty("@id")) {
        return;
      }

      this.memberIRI = data["@id"];
      this.memberId = data.id;
      this.userDisplayName = data.displayName;

      // ProjectMember's existing assigned locations
      this.existingAssignedLocations = data.membersDatasheetsLocations;

      // Original assigned locations are stored for comparison
      // Making a deep copy
      this.originalAssignedLocations = JSON.parse(
        JSON.stringify(data.membersDatasheetsLocations)
      );

      if (this.datasheets.length === 1) {
        this.selectedDatasheet = this.datasheets[0].id;
      }
    },

    // Watch for datasheet dropdown selection change
    selectedDatasheet(newDS) {
      if (!newDS) return;

      this.assignedLocations = [];

      // For existing datasheet+member, locations are appended to array
      this.existingAssignedLocations.forEach(val => {
        // if (val.datasheet['@id'] === newDS || val.datasheet === newDS) {
        if (val.datasheet === newDS) {
          val.locations.forEach(loc => {
            if (typeof loc === "object") {
              this.assignedLocations.push(loc["@id"]);
            } else {
              this.assignedLocations.push(loc);
            }
          });
        }
      });

      // No assigned locations for this datasheet+member
      //  - A new entry is added
      if (this.assignedLocations.length === 0) {
        let newLoc = this.newAssignedLocationsTemplate;
        newLoc.datasheet = newDS;
        newLoc.member = this.memberIRI;
        this.existingAssignedLocations.push(newLoc);
      }
    }
  },

  methods: {
    ...mapActions({
      getProjectLocations: "project/show/getProjectLocations",
      updateAssignedLocationsForMember:
        "project/show/updateAssignedLocationsForMember",
      createAssignedLocationsForMember:
        "project/show/createAssignedLocationsForMember"
    }),

    projectLocationsProvider(ctx) {
      // Busy state is set
      this.isBusy = true;

      // URL Params are set
      let urlParams = this.urlBuilder(ctx);
      const data = { id: this.projectId, urlParams };

      // Projects are fetched from API
      return this.getProjectLocations(data).then(data => {
        this.totalItems = data["hydra:totalItems"];
        this.currentPage = ctx.currentPage || 1;
        this.sortBy = ctx.sortBy || "";
        this.isBusy = false;
        return data["hydra:member"].map(locations => {
          return {
            ...locations
          };
        });
      });
    },

    isChanged(datasheetIRI) {
      // Method checks if there any changes to the assigned locations

      let original = [];
      this.originalAssignedLocations.filter(val => {
        // if (val.datasheet['@id'] === datasheetIRI) {
        if (val.datasheet === datasheetIRI) {
          val.locations.forEach(loc => {
            if (typeof loc === "object") {
              original.push(loc["@id"]);
            } else {
              original.push(loc);
            }
          });
        }
      });

      let changed = [];
      this.existingAssignedLocations.filter(val => {
        // if (val.datasheet['@id'] === datasheetIRI) {
        if (val.datasheet === datasheetIRI) {
          val.locations.forEach(loc => {
            if (typeof loc === "object") {
              changed.push(loc["@id"]);
            } else {
              changed.push(loc);
            }
          });
        }
      });

      // Equality comparer, must compare both ways
      let diff_og = original.filter(function(val) {
        return !changed.includes(val);
      });

      let diff_cg = changed.filter(function(val) {
        return !original.includes(val);
      });

      if (diff_og.length > 0 || diff_cg.length > 0) {
        return true;
      } else {
        return false;
      }
    },

    onAssignLocationsSaveClick(evt) {
      // Save operation is performed for each datasheet, if there are changes
      // Return true to the parent if there are changes (parent will reload the member table)
      this.existingAssignedLocations.forEach(val => {
        if (val.hasOwnProperty("@id")) {
          // if (val.datasheet) {
          // Existing assigned locations have the datasheet IRI
          //  - use a PUT to save the changes
          if (this.isChanged(val.datasheet)) {
            this.updateAssignedLocationsForMember(val).then(() => {
              this.$emit("close-assign-locations", true);
            });
          }
          // TODO: Must remove the table entry if val.location.length === 0
        } else {
          // Newly added assigned locations do not have a datasheet IRI
          if (val.locations.length > 0) {
            // Newly assigned locations - Create is called
            this.createAssignedLocationsForMember(val).then(() => {
              this.$emit("close-assign-locations", true);
            });
          } else {
            // No assigned locations, nothing to do!
          }
        }
      });
    },

    onAssignLocationsCloseClick(evt) {
      // TODO: Need to check for unsaved changes and prompt user before close
      // Return false as there are no changes
      this.$emit("close-assign-locations", false);
    },

    /**
     * @function locationToggled
     * @param evt
     * @param location
     */
    locationToggled(evt, location) {
      // Check if the location was added or removed
      if (evt === null) {
        // Assigned location removed
        this.assignedLocations.splice(
          this.assignedLocations.indexOf(location["@id"]),
          1
        );
      } else {
        // Assigned location added
        this.assignedLocations.push(location["@id"]);
      }

      // Keep the array in sync with user changes
      this.existingAssignedLocations.forEach(val => {
        // if (val.datasheet['@id'] === this.selectedDatasheet || val.datasheet === this.selectedDatasheet) {
        if (val.datasheet === this.selectedDatasheet) {
          val.locations = this.assignedLocations;
        }
      });
    },

    /**
     * @function onFiltered
     * @param filteredItems
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      if (
        this.totalItems !== filteredItems.length &&
        filteredItems.length > this.perPage
      ) {
        this.totalItems = filteredItems.length;
        this.currentPage = 1;
      }
    },

    resetModal() {
      // Called on modal close to reset modal for repeated use
      this.memberIdVal = null;
      this.userDisplayName = "";
      this.originalItemCount = 0;
      this.totalItems = 0;
      this.currentPage = 1;
      this.perPage = 10;
      this.filterObject.filterName = null;
      this.selectedDatasheet = "";
      this.existingAssignedLocations = [];
      this.originalAssignedLocations = [];
      this.assignedLocations = [];
      this.componentKey += 1; // This is needed to ensure the modal re-renders each time
    }
  },

  computed: {
    ...mapGetters({
      projectLocations: "project/show/projectLocations"
    }),

    datasheets() {
      if (!this.passedDatasheets || this.passedDatasheets.length === 0) {
        return [];
      }
      // Only published datasheets with picklist or both
      return this.passedDatasheets
        .filter(datasheet => {
          return datasheet.published === true && datasheet.locationFormat > 0;
        })
        .map(datasheet => {
          return { id: datasheet["@id"], name: datasheet.name };
        });
    },

    fromItem() {
      // Computed value for showing lower bound based on current page and per page value
      return this.totalItems > 0
        ? (this.currentPage - 1) * this.perPage + 1
        : 0;
    },

    toItem() {
      // Computed value for showing upper bound based on current page and per page value
      return this.totalItems < this.currentPage * this.perPage
        ? this.totalItems
        : this.currentPage * this.perPage;
    },

    filterVal: {
      get: function() {
        // Search by the location name
        // - more filters can be added here after adding to the filterObject
        let retVal = "";
        if (this.filterObject.filterName) {
          retVal =
            retVal.length === 0
              ? "name[searchAny]=" + this.filterObject.filterName
              : "&name[searchAny]=" + this.filterObject.filterName;
        }
        return retVal;
      }
    },

    memberId: {
      get() {
        return this.memberIdVal;
      },
      set(newIdVal) {
        this.memberIdVal = newIdVal;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
#edit-member {
  input.search-box {
    height: 25px;
    font-size: 14px;
    padding: 0;

    &::placeholder {
      color: #bdbdbd;
      font-size: 14px;
      text-align: center;
    }
  }
}
</style>
