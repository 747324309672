<template>
  <b-modal id="add-scorecard"
           title="Add an Indicator"
           ref="addScorecard"
           size="xl"
           button-size="sm"
           hide-header-close
           no-close-on-backdrop
           no-close-on-esc
           no-stacking
           centered
           @cancel="resetModal">

    <div class="container mb-2 p-0">
      <!-- Title, Description and Resource -->
      <b-card bg-variant="light">
        <b-form-group
          id="score-title"
          label-cols-lg="2"
          content-cols-lg="8"
          label="Title:"
          label-class="font-weight-bold pt-0"
          label-for="input-title"
          description="Title is for this Indicator">
          <b-form-input id="input-title"
                        name="title"
                        v-model="scoreTitle"
                        placeholder="Enter a title"
                        v-validate="'required|min:5|max:20'"
                        data-vv-as="Indicator Title"
                        :class="{ 'is-invalid': errors.has('title') }"
                        aria-describedby="scorecard-title-input-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="scorecard-title-input-live-feedback">
            {{ errors.first("title") }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          id="score-details"
          label-cols-lg="2"
          content-cols-lg="8"
          label="Details:"
          label-class="font-weight-bold pt-0"
          label-for="input-details"
          description="Provide details explaining this Indicator">
          <b-form-textarea
            id="input-details"
            name="details"
            v-model="scoreDescription"
            placeholder="Describe how this score is calculated..."
            v-validate="'required|min:20|max:1000'"
            data-vv-as="Indicator Details"
            :class="{ 'is-invalid': errors.has('details') }"
            aria-describedby="scorecard-details-input-live-feedback"
            rows="3"
            max-rows="4"
          ></b-form-textarea>
          <b-form-invalid-feedback id="scorecard-details-input-live-feedback">
            {{ errors.first("details") }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-card>
      <!-- Indicator Selection -->
      <b-card v-if="projects" bg-variant="light" class="mt-1 no-gutters">
        <b-form-group class="mb-0"
                      label-cols-lg="3"
                      content-cols-lg="12"
                      label="Indicator Selection:"
                      label-class="font-weight-bold m-0 p-0 label_underline">
          <div v-if="selectionError" class="text-danger">
            {{ selectionError }}
          </div>
          <b-form-group id="score-project"
                        label-cols-lg="2"
                        content-cols-lg="10"
                        label="Project:"
                        label-class="font-weight-bold m-0 p-0"
                        label-for="input-project"
                        class="mt-2">
            <b-form-select id="input-project"
                           v-model="selectedProject"
                           :options="allProjects"
                           placeholder="Pick a project">
            </b-form-select>
          </b-form-group>
          <b-form-group v-if="selectedProject"
                        id="score-datasheet"
                        label-cols-lg="2"
                        content-cols-lg="10"
                        label="Datasheet:"
                        label-class="font-weight-bold m-0 p-0"
                        label-for="input-datasheet">
            <div v-if="isLoadingDatasheets">
              <small>Fetching Datasheets...</small>
              <i class="fa fa fa-spin fa-spinner ml-2"></i>
            </div>
            <b-form-select v-else id="input-datasheet"
                           v-model="selectedDatasheet"
                           :options="getProjectDatasheets"
                           :disabled="isLoadingDatasheets">
            </b-form-select>
          </b-form-group>
          <b-form-group v-if="selectedDatasheet"
                        id="score-record"
                        label-cols-lg="2"
                        content-cols-lg="10"
                        label="Record:"
                        label-class="font-weight-bold m-0 p-0"
                        label-for="input-record">
            <b-form-select id="input-record"
                           v-model="selectedRecord"
                           :options="datasheetRecords"
            ></b-form-select>

          </b-form-group>
        </b-form-group>
      </b-card>

      <!-- Bin Design -->
      <b-card v-if="selectedProject && selectedDatasheet && selectedRecord && dsRecord" bg-variant="light"
              class="mt-1 no-gutters">
        <div v-if="dsRecord && dsRecord.recordType !== 'number'">
          <b-form-group class="mb-0"
                        label-cols-lg="3"
                        content-cols-lg="12"
                        label="Bin Definition:"
                        label-class="font-weight-bold pt-0 label_underline">
            <b-row class="mb-2">
              <b-col cols="2" sm="2">
                # Bins:
              </b-col>
              <b-col cols="2" sm="2">
                <b-form-select v-model="numBins" :options="categoricalBinOptions"></b-form-select>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col>
                <CategoricalBins :key="selectedRecord"
                                 :all-option-values="getCategoricalOptionValues"
                                 :num-bins="numBins"
                                 @update="setCategoricalBinValues"
                ></CategoricalBins>
              </b-col>
            </b-row>
          </b-form-group>
        </div>
        <NumericalBins v-else :key="selectedRecord"
                       :default-min="getDefaultMinMax().min"
                       :default-max="getDefaultMinMax().max"
                       @update="setNumericBinValues"></NumericalBins>
      </b-card>
    </div>
    <template slot="modal-footer">
      <!--      <b-btn v-if="" variant="danger" class="mr-auto" @click="">Delete</b-btn>-->
      <b-btn variant="secondary" @click="() => { this.resetModal(); this.$refs.addScorecard.hide() }">Cancel
      </b-btn>
      <b-btn variant="primary" @click="saveScorecard">Save</b-btn>
    </template>
  </b-modal>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import CategoricalBins from "./CategoricalBins.vue";
import NumericalBins from "./NumericalBins.vue";

export default {
  name: "AddIndicatorModal",
  components: {
    CategoricalBins,
    NumericalBins
  },
  provide() {
    return {parentValidator: this.$validator}
  },
  props: {},
  data() {
    return {
      scoreTitle: "",
      scoreDescription: "",
      selectedProject: null,
      selectedDatasheet: null,
      datasheetRecords: null,
      selectedRecord: null,
      numBins: 0,
      maxBinCount: [1, 2, 3, 4, 5, 6, 7],
      categoricalBins: [],
      numericalBins: [],
      selectionError: null,
    }
  },
  watch: {
    selectedProject(project) {
      if (project) {
        this.datasheetRecords = []
        this.getDatasheets(project)
      }
      this.selectedDatasheet = null
    },
    selectedDatasheet(datasheet) {
      this.selectedRecord = null
      this.datasheetRecords = []
      if (datasheet) {
        let ds = this.datasheets.filter(ds => datasheet === ds.id)
        // Filter datasheet records to only numeric and categorical <radio|dropdown>
        let numericalRecs = ds[0].records.filter(rec => (rec.recordType === 'number'))
        let numericalOptions = {label: 'Numerical', options: []}
        numericalOptions.options = numericalRecs.map(num => {
          return {value: num.id, text: num.label}
        })

        let categoricalRecs = ds[0].records.filter(rec => rec.recordType === 'dropdown' || rec.recordType === 'radio')
        let categoricalOptions = {label: 'Categorical', options: []}
        categoricalOptions.options = categoricalRecs.map(num => {
          return {value: num.id, text: num.label}
        })

        this.datasheetRecords.push({value: null, text: "Pick an Indicator...", disabled: true})
        this.datasheetRecords.push(categoricalOptions)
        this.datasheetRecords.push(numericalOptions)
      }
    },
    selectedRecord(record) {
      if (record) {
        // Fetch the datasheet record
        this.getRecord(record)
        this.numBins = 1
      } else {
        this.numBins = 0
      }
    },
  },
  computed: {
    ...mapGetters({
      organization: 'organization/get/organization',
      projects: 'organization/get/projects',
      isLoadingDatasheets: 'datasheet/get/isLoading',
      datasheets: 'datasheet/get/datasheets',
      isLoadingDSRecord: 'datasheet/record/isLoading',
      dsRecord: 'datasheet/record/record',
    }),
    allProjects() {
      if (this.projects) {
        let arrProjects = []
        arrProjects.push({value: null, text: "Pick a Project...", disabled: true})
        Object.values(this.projects).forEach(p => {
          arrProjects.push({value: p.id, text: p.name})
        })
        return arrProjects
      } else {
        return []
      }
    },
    getProjectDatasheets() {
      if (this.datasheets) {
        let arrDatasheets = []
        arrDatasheets.push({value: null, text: "Pick a Datasheet...", disabled: true})
        Object.values(this.datasheets).forEach(ds => {
          arrDatasheets.push({value: ds.id, text: ds.name})
        })
        return arrDatasheets
      } else {
        return []
      }
    },
    getCategoricalOptionValues() {
      if (this.dsRecord) {
        return this.dsRecord.optionsSet.optionsValues.flatMap(val => {
          return {id: val['@id'], name: val.value}
        })
      } else {
        return []
      }
    },
    categoricalBinOptions() {
      // Return the number of bins to the child component
      // - for categorical records, the maximum is 7 or the number of option values
      return this.maxBinCount.filter(val => val <= this.dsRecord.optionsSet.optionsValues.length)
    },
  },
  methods: {
    ...mapActions({
      getDatasheets: "datasheet/get/get",
      getRecord: "datasheet/record/get",
      createScorecard: "organization/scorecards/createScorecard",
    }),
    resetModal() {
      this.scoreTitle = ""
      this.scoreDescription = ""
      this.selectedProject = null
      this.selectedDatasheet = null
      this.datasheetRecords = null
      this.selectedRecord = null
      this.numBins = 1
      this.categoricalBins = []
      this.numericalBins = []
      this.selectionError = null
      this.$validator.reset()
    },
    getDefaultMinMax() {
      // For numerical bins min & max are set from the datasheetRecord->validators, if available
      let min = this.dsRecord.validators.find(valid => valid.validatorType === 'min')
      let max = this.dsRecord.validators.find(valid => valid.validatorType === 'max')
      if (min && max) {
        return {min: Number(min.value), max: Number(max.value)}
      } else {
        return {min: null, max: null}
      }
    },
    setCategoricalBinValues(bins) {
      this.categoricalBins = Object.values(bins).map(bin => {
        return {
          orderNumber: bin.orderNumber,
          label: bin.label,
          optionValues: Object.values(bin.optionsValues)
            .map(ov => {
              return ov.id
            })
        }
      })
    },
    setNumericBinValues(bins) {
      this.numericalBins = Object.values(bins).map(bin => {
        return {
          orderNumber: bin.orderNumber,
          label: bin.label,
          minValue: bin.min,
          maxValue: bin.max,
        }
      })
    },
    clearErrorMsg() {
      this.selectionError = null
    },
    saveScorecard() {
      this.selectionError = null
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.selectedProject === null ||
            this.selectedDatasheet === null ||
            this.selectedRecord === null) {
            this.selectionError = "Select an indicator..."
            // Hide the error after a timeout
            setTimeout(() => {
              this.clearErrorMsg()
            }, 7500);
          } else {
            // Once validation is passed, scorecard is saved
            let bins = null
            if (this.dsRecord.recordType !== 'number') {
              // Categorical bins
              bins = this.categoricalBins
              if (this.categoricalBins.length < 1) {
                // At least 1 bin must be present
                this.selectionError = "Indicator must contain at least 1 bin..."
                // Hide the error after a timeout
                setTimeout(() => {
                  this.clearErrorMsg()
                }, 7500);
                return;
              }
            } else {
              // Numeric bins
              bins = this.numericalBins
              if (this.numericalBins.length < 1) {
                // At least 1 bin must be present
                this.selectionError = "Indicator must contain at least 1 bin..."
                // Hide the error after a timeout
                setTimeout(() => {
                  this.clearErrorMsg()
                }, 7500);
                return;
              }
            }
            let scorecard = {
              title: this.scoreTitle,
              description: this.scoreDescription,
              organization: this.organization['@id'],
              datasheetRecord: this.dsRecord['@id'],
              bins: bins,
            }
            this.createScorecard(scorecard).then(result => {
              if (result) {
                this.resetModal()
                this.$refs.addScorecard.hide()
              }
            })
          }
        }
      })
    }
  },
}
</script>

<style lang="scss">

.label_underline {
  text-decoration: underline;
}

</style>
