<template>
  <div>
      <b-button pill variant="outline-secondary" class="disabled mr-2 numLikesStyle" size="sm" title="Likes" alt="Likes">
          {{ projectLikes }} &nbsp;<i class="fa fa-heart mr-2"></i>
      </b-button>
      <b-button pill variant="tertiary"
                v-if="isAuthenticated"
                class="LikeButton"
                size="sm"
                title="Like this project"
                alt="Like this project"
                @click="likeProject">
          <span v-if="isLiking"><i class='fa fa-spinner fa-spin mr-3'></i></span>
          <span v-else-if="!hasCurrentUserLiked">Like</span>
          <span v-else>Liked</span>
          &nbsp;
          <i class="fa fa-heart"></i>
      </b-button>
  </div>
</template>

<script>
import {
    mapActions,
    mapGetters,
    mapState,
} from 'vuex'

export default {
    computed: {
        ...mapGetters({
            projectLikes: 'project/get/likes/count',
            isLiking: 'project/get/likes/isLiking',
            hasCurrentUserLiked: 'project/get/likes/hasCurrentUserLiked',
            isAuthenticated: 'user/auth/isAuthenticated'
        }),
        ...mapState({
            isLiking: state => state.project.get.likes.isLiking,
        }),
    },
    methods: {
        ...mapActions({
            likeProject: 'project/get/likes/setLike',
        })
    }
}
</script>

<style lang="scss" scoped>
.numLikesStyle {
  background-color: white;
  opacity: 1;
  cursor: default;
}
</style>
