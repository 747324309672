<template>
<div class="row observation-record-orgaism mb-3">
    <div class="col-12 bg-cit-green text-white py-2 mb-0 border border-cit-green-light">
        <div class="h4">
            <span>Organism:
            </span>

            <span>
                {{
          record.organism.customName
            ? record.organism.customName
            : record.organism.canonicalName && record.organism.scientificName
            ? record.organism.canonicalName +
              " (" +
              record.organism.scientificName +
              ")"
            : ""
        }}

            </span>
        </div>
        <div v-if="!record.datasheetRecord || !record.datasheetRecord.isActive">
            <i class="fa fa-trash text-warning"></i>
            This question has been removed from the current datasheet
        </div>
        <div v-if="record.datasheetRecord && (record.datasheetRecord.organisms.length > 1 || record.datasheetRecord.allowAnyOrganism)">
            <i class="fa fa-warning text-warning"></i>
            Changing the selected species is not supported at this time.
        </div>
    </div>
    <div class="
        col-md-11
        offset-md-1
        bg-white
        border-cit-green-light
        border-left border-right border-bottom
      ">
        <observation-edit-record-item v-for="subRecord in record.records" :record="subRecord" v-bind:key="subRecord['@id']">
        </observation-edit-record-item>
    </div>
</div>
</template>

<script>
export default {
    name: "ObservationRecordOrganism",
    beforeCreate: function () {
        this.$options.components.ObservationEditRecordItem = require('./RecordItem.vue').default
    },
    props: {
        record: {
            type: Object,
            required: true,
        },
    },
};
</script>
