<template>
  <div class="d-block" v-if="project">
    <b-btn
      @click.stop
      :to="{
        name: 'Login',
        query: {
          redirect: `projects/${this.project.urlField}`
        }
      }"
      :class="btnClass"
      class="my-1 btn-block btn-tertiary"
      v-if="!hasSession"
      alt="Login and then you can join this project"
      title="Login and then you can join this project"
    >
      <i class="fa fa-sign-in" /> Login
    </b-btn>
    <router-link
      @click.stop
      :to="{ name: 'ProjectShow', params: { id: this.project.urlField } }"
      :class="btnClass"
      class="my-1 btn btn-secondary btn-block text-white"
      v-else-if="isLoading"
      alt="Joining project"
      title="Joining project"
    >
      <font-awesome-icon icon="spinner" pulse /> Joining
    </router-link>
    <router-link
      @click.stop
      :to="{ name: 'ProjectShow', params: { id: this.project.urlField } }"
      :class="btnClass"
      class="my-1 btn btn-secondary btn-block text-white"
      v-else-if="
        membershipMap[project.id] && membershipMap[project.id].isManager
      "
      alt="Project manager - view your project profile"
      title="Project manager - view your project profile"
    >
      <font-awesome-icon icon="users" /> Manager
    </router-link>
    <router-link
      @click.stop
      :to="{ name: 'ProjectShow', params: { id: this.project.urlField } }"
      :class="btnClass"
      class="my-1 btn btn-secondary btn-block text-white"
      v-else-if="
        membershipMap[project.id] && membershipMap[project.id].isMember
      "
      alt="Member - view this project profile"
      title="Member - view this project profile"
    >
      <font-awesome-icon icon="user" /> Member
    </router-link>
    <router-link
      @click.stop
      :to="{ name: 'ProjectShow', params: { id: this.project.urlField } }"
      :class="btnClass"
      class="my-1 btn btn-secondary btn-block text-white"
      v-else-if="
        membershipMap[project.id] && membershipMap[project.id].isPending
      "
      alt="Approval Pending - view this project profile"
      title="Approval Pending - view this project profile"
    >
      <font-awesome-icon icon="hourglass" /> Pending Approval
    </router-link>
    <b-btn
      @click.stop
      :class="btnClass"
      class="my-1 btn-block btn-tertiary"
      v-else-if="!project.approveContacts"
      @click="clickJoin($event, 'joined')"
      alt="Join this open citizen science project"
      title="Join this open citizen science project"
    >
      <font-awesome-icon icon="plus-circle" /> Join
    </b-btn>

    <b-btn
      @click.stop
      :class="btnClass"
      class="my-1 btn-block btn-tertiary"
      v-else-if="project.approveContacts"
      @click="clickJoin($event, 'pending')"
      alt="Ask to join this member-based project"
      title="Ask to join this member-based project"
    >
      <font-awesome-icon icon="user-plus" /> Ask to Join
    </b-btn>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    project: Object,
    btnClass: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      isLoading: false,
      key: 0,
      newJoin: false,
      newPending: false,

      // variables for showing alerts when user joins a project
      showJoinProjectAlert: false,
      joinAlertMsg: null,
      joinMsg: "Congratulations! You are now a member.",
      askJoinMsg: "Your request to join is awaiting approval by a Manager."
    };
  },
  computed: {
    ...mapGetters({
      membershipMap: "user/profile/membershipMap",
      userId: "user/profile/id",
      hasSession: "user/profile/hasSession",
      isVerified: "user/profile/isVerified",
      sessionData: "user/profile/sessionData"
    })
  },
  methods: {
    ...mapActions({
      joinToProject: "project/show/joinToProject"
    }),
    onProjectShowClicked() {
      this.$router.push({
        name: "ProjectShow",
        params: { id: this.project["urlField"] }
      });
    },
    clickJoin(evt, status) {
      evt.preventDefault();
      if (!this.hasSession) {
        this.$root.$emit("bv::show::modal", "not-logged-in-modal");
      } else if (!this.isVerified) {
        this.$bvModal.show("not-verified-modal");
      } else {
        this.isLoading = true;
        this.joinToProject({ id: this.project.id }).then(data => {});
      }
    }
  },
  watch: {
    sessionData() {
      this.isLoading = false;
    }
  }
};
</script>

<style></style>
