import { mapActions, mapGetters } from 'vuex'

export default {
  data () {
    return {
      token: null,
      verifiedStatus: false,
      // isTokenExpired: false,
      // isTokenInvalid: false,
      // isEmailSent: false,
    }
  },
  created () {
    this.token = decodeURIComponent(this.$route.params.token)
    // Subscribe to account verification mutation
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'user/activate/ACTIVATE_ACCOUNT_SET_ACTIVATED') {
        this.verifiedStatus = true
      }
    });
    if (this.isVerified) {
      // User is already verified...
      this.verifiedStatus = true
    }
  },

  mounted () {
    if (!this.verifiedStatus) {
      this.checkToken(this.token).then(() => {
        if (!this.expiredToken && !this.invalidToken) {
          this.update(this.token)
        }
      })
    }
  },

  beforeDestroy () {
    this.reset()
  },

  computed: {
    ...mapGetters({
      error: 'user/activate/error',
      isLoading: 'user/activate/isLoading',
      isVerified: 'user/profile/isVerified',
      userId: 'user/profile/id',
      userEmail: 'user/profile/email',
      activated: 'user/activate/activated',
      expiredToken: 'user/activate/expiredToken',
      invalidToken: 'user/activate/invalidToken',
      isAuthenticated: 'user/auth/isAuthenticated',
      emailSent: 'user/activate/emailSent',
    }),
  },

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    activated: function (activated) {
      if (!activated) {
        return
      }

      if (this.isAuthenticated) {
        this.$router.push({ name: 'UserProfile' })
      } else {
        this.$router.push({ name: 'Login' })
      }
    },

    // expiredToken (expiredToken) {
    //   if (!expiredToken) {
    //     return
    //   }
    //   this.isTokenExpired = expiredToken
    //
    // },
    //
    // invalidToken (invalidToken) {
    //   console.log(expiredToken)
    //   if (!invalidToken) {
    //     return
    //   }
    //   this.isTokenInvalid = invalidToken
    // },
    //
    // emailSent (sent) {
    //   if(!sent) {
    //     return
    //   }
    //   this.isEmailSent = sent
    //
    // }

  },

  methods: {
    ...mapActions({
      update: 'user/activate/update',
      checkToken: 'user/activate/checkToken',
      reset: 'user/activate/reset',
      resendEmail: 'user/activate/resendEmail',
    }),

    onClickResendEmail () {
      let code = JSON.parse(atob(this.token.split('.')[1])).code
      if (code !== this.userId) {
        // console.log("Mismatched payload and id, send email to correct user")
        code = uid
      }
      this.resendEmail({ "id": code, "email": this.userEmail }).then(()=>{
        // console.log("ActivateEmailSent")
      })
    },
  }
}
