<template>
  <!-- 6 most recent Photo Observations -->
  <div class="card-body">
    <h3>Recent Photo Observations
      <b-btn v-if="observations"
             class="ml-2"
             variant="secondary"
             size="sm"
             alt="Reload Photo Observations" title="Reload Photo Observations"
             @click="getRecentPhotoObservations(6)">
        <font-awesome-icon icon="sync"/>
      </b-btn>
    </h3>
    <div v-if="isLoadingPhotoObs" style="min-height:200px;">
      <i class="fa fa-spin fa-spinner fa-3x mr-3"></i> Loading recent observations with photos...
    </div>
    <div v-else>
      <b-row v-if="observations && observations.length > 0" class="section observation justify-center">
        <PhotoObservationCard
          v-for="(observation, index) in observations"
          :key=index
          :isProjectPage=false
          :observation=observation>
        </PhotoObservationCard>
      </b-row>
      <div v-else class="ml-4">
        <h6>No Recent Observations with Photos...</h6>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import PhotoObservationCard from "../../../layout/PhotoObservationCard";

export default {
  components: {
    PhotoObservationCard
  },
  data() {
    return {
      observationCards: null,
    };
  },
  computed: {
    ...mapGetters({
      observations: "organization/photo_observations/bigBendObservations",
      isLoadingPhotoObs: "organization/photo_observations/isLoading",
    })
  },
  methods: {
    ...mapActions({
      getRecentPhotoObservations: "organization/photo_observations/getBigBend",
    }),
  },
  created: function () {
    // this.getRecentPhotoObservations(6)
  }
};
</script>
<style lang="scss" scoped></style>
