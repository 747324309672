import fetch from '../../../../utils/fetch'

const initialState = {
  isLoading: false,
  resources: null,
  error: null,
}

const getters = {
  resources(state) {
    return state.resources
  },
  isLoading(state) {
    return state.isLoading
  },
  error(state) {
    return state.error
  },
}

const actions = {

  get({commit, state, rootState}, data) {
    commit(types.SET_ORG_RESOURCES_LOADING);
    return fetch('/organizations/' + rootState.organization.get.organization.url + '/resources' + data.urlParams)
      .then(response => response.json())
      .then((resources) => {
        commit(types.SET_ORG_RESOURCES, resources)
        return resources
      })
      .catch((error) => {
        commit(types.SET_ORG_RESOURCES_ERROR, error)
      })
  },

  createResource({ commit, dispatch }, data) {
    commit(types.SET_ORG_RESOURCES_LOADING)
    return fetch(('/organization_resources'), {
      method: 'POST',
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then((resource) => {
          return resource
      })
      .catch((error) => {
        commit(types.SET_ORG_RESOURCES_ERROR, error.message)
      })
  },

  editResource({ commit, dispatch }, data) {
    commit(types.SET_ORG_RESOURCES_LOADING)
    return fetch(('/organization_resources/' + data.id), {
      method: 'PUT',
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then((resource) => {
        return resource
      })
      .catch((error) => {
        commit(types.SET_ORG_RESOURCES_ERROR, error.message)
      })
  },

  deleteResource ({ commit, dispatch }, data) {
    commit(types.SET_ORG_RESOURCES_LOADING)
    return fetch(('/organization_resources/' + data.id), {
      method: 'DELETE'
    })
      .then((response) => {
        return response.status === 204
      })
      .then((isDeleted) => {
        return isDeleted
      })
      .catch((error) => {
        commit(types.SET_ORG_RESOURCES_ERROR, error.message)
      })
  }
}

const types = {
  SET_ORG_RESOURCES_LOADING: 'SET_ORG_RESOURCES_LOADING',
  SET_ORG_RESOURCES: 'SET_ORG_RESOURCES',
  SET_ORG_RESOURCES_ERROR: 'SET_ORG_RESOURCES_ERROR',
  RESET_ERROR: 'RESET_ERROR',
  RESET: 'RESET',
}

const mutations = {
  [types.SET_ORG_RESOURCES_LOADING](state) {
    state.isLoading = true;
  },
  [types.SET_ORG_RESOURCES](state, resources) {
    state.resources = resources
    state.isLoading = false
  },
  [types.SET_ORG_RESOURCES_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },
  [types.RESET_ERROR](state) {
    state.isLoading = false;
    state.error = null;
  },
  [types.RESET](state) {
    state = initialState
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters: getters,
  actions: actions,
  mutations: mutations
}
