import fetch from '../../../../utils/fetch'

const initialState = {
  isLoading: false,
  datasheets: null,
  error: null,
}

const getters = {
  datasheets(state) {
    if (state.datasheets) {
      return state.datasheets
    }  else {
      return null
    }
  },
  isLoading(state) { return state.isLoading },
  error(state) { return state.error },
}

const actions = {
  /**
   * @function get- retrieve datasheets for a project
   * @param commit
   * @param projectId
   */
  get({ commit }, projectId) {
    commit(types.SET_DATASHEETS_LOADING);
    return fetch('/projects/' + projectId + '/datasheets')
      .then(response => response.json())
      .then((data) => {
        commit(types.SET_DATASHEETS, data['hydra:member'])
      })
      .catch((error) => {
        commit(types.SET_DATASHEETS_ERROR, error)
      })
  },
}

const types = {
  SET_DATASHEETS_LOADING: 'SET_DATASHEETS_LOADING',
  SET_DATASHEETS: 'SET_DATASHEETS',
  SET_DATASHEETS_ERROR: 'SET_DATASHEETS_ERROR',
  RESET_ERROR: 'RESET_ERROR',
  RESET: 'RESET',
}

const mutations = {
  [types.SET_DATASHEETS_LOADING](state) {
    state.isLoading = true;
  },
  [types.SET_DATASHEETS](state, datasheets) {
    state.datasheets = datasheets
    state.isLoading = false
  },
  [types.SET_DATASHEETS_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },
  [types.RESET_ERROR](state) {
    state.isLoading = false;
    state.error = false;
  },
  [types.RESET](state) {
    state = initialState
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters: getters,
  actions: actions,
  mutations: mutations
}
