import { PROJECT_ROLE_MANAGER, PROJECT_ROLE_MEMBER, PROJECT_ROLE_PENDING, PROJECT_ROLE_NONE } from "../../../../constants/project_users_roles"

export const hasSession = state => {
    return !!state.sessionData;
}
export const id = state => {
    return state.sessionData ? state.sessionData.id : null
}
export const isVerified = state => {
    return state.sessionData ? state.sessionData.isVerified : false
}
export const displayName = state => {
    return state.sessionData ? state.sessionData.displayName : ""
}
export const email = state => {
    return state.sessionData ? state.sessionData.email : ""
}

export const membershipArray = state => {
    if (!state.sessionData || !state.sessionData.memberships) { return [] }
    return [...state.sessionData.memberships].sort(compareByProjectName);
}
export const membershipMap = state => {
    if (!state.sessionData || !state.sessionData.memberships || state.sessionData.memberships.length === 0) { return {} }
    return state.sessionData.memberships.reduce((accumulator, membership) => {
        if (!membership.projectId) { return accumulator }
        accumulator[membership.projectId] = {
            ...membership,
            isManager: (
                membership.approved === true &&
                membership.role === PROJECT_ROLE_MANAGER
            ),
            isMember: (
                membership.approved === true && (
                    membership.role === PROJECT_ROLE_MANAGER ||
                    membership.role === PROJECT_ROLE_MEMBER
                )
            ),
            isPending: (
                membership.approved === false
            )
        }
        return accumulator
    }, {})// The empty object here is the initial value of the accumulated value

}

export const organizationMembershipMap = state => {
  if (!state.sessionData || !state.sessionData.organizationMemberships || state.sessionData.organizationMemberships.length === 0) { return {} }
  return state.sessionData.organizationMemberships.reduce((accumulator, membership) => {
    if (!membership.organizationUrl) { return accumulator }
    accumulator[membership.organizationUrl] = {
      ...membership,
      isManager: (
        membership.approved === true &&
        membership.role === PROJECT_ROLE_MANAGER
      ),
      isPending: (
        membership.approved === false
      )
    }
    return accumulator
  }, {})// The empty object here is the initial value of the accumulated value

}

export const sessionData = state => state.sessionData
export const locations = state => state.locations
export const projects = state => state.projects


export const allProjects = state => state.allProjects
export const stats = state => state.stats

export const error = state => state.error
export const violations = state => state.violations
export const isLoading = state => state.isLoading

export const isLoadingProjects = state => state.isLoadingProjects

export const isLoadingStats = state => state.isLoadingStats

export const isLoadingSession = state => state.isLoadingSession


export const isPictureUploading = state => state.isPictureUploading
export const isPictureRemoving = state => state.isPictureRemoving

export const pictureError = state => state.pictureError
export const picturePath = state => {
    if (state.sessionData
        && state.sessionData.picture
        && state.sessionData.picture.path
    ) {
        return state.sessionData.picture.path
    }
    return "/assets/images/default_avatar.png"
}

export const isEditingProfile = state => state.isEditingProfile
export const resetPassword = state => state.resetPassword
export const resetPasswordIsLoading = state => state.resetPasswordIsLoading
export const passwordUpdated = state => state.passwordUpdated
export const resetPasswordError = state => state.resetPasswordError
export const resetPasswordViolations = state => state.resetPasswordViolations


const compareByProjectName = (a, b) => {
    var nameA = a.projectName.toUpperCase(); // ignore upper and lowercase
    var nameB = b.projectName.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }
    // names must be equal
    return 0;
}




/*****************
* Get Project Role & isProjectARole functions will be most performant if moved into a getter or computed field....
*/

/**
 * Get the user's effective role in the project
 * @param {string} projectId - current user's membership info
 * @returns {string} - returns role
 */
const getProjectRole = (state, projectId) => {
    if (!projectId) {
        return PROJECT_ROLE_NONE
    }
    //ToDo: store sessionData in VUEX
    const sessionData = JSON.parse(localStorage.getItem('sessionData'))
    if (!sessionData) {
        return PROJECT_ROLE_NONE
    }
    const memberships = sessionData.memberships;
    if (!memberships || memberships.length === 0) {
        return PROJECT_ROLE_NONE
    }
    const projectMembership = memberships.find((membership) => {
        return (projectId === membership.projectId);
    })
    if (projectMembership === undefined) { return PROJECT_ROLE_NONE }
    if (!projectMembership.approved) { return PROJECT_ROLE_PENDING }
    if (projectMembership.role === PROJECT_ROLE_MEMBER) { return PROJECT_ROLE_MEMBER }
    if (projectMembership.role === PROJECT_ROLE_MANAGER) { return PROJECT_ROLE_MANAGER }
    console.error("getProjectRole error: invalid project role")
    return PROJECT_ROLE_NONE;
}
/**
 * Check if loggedin user is manager of a given project
 * @param {string} projectId - current user's membership info
 * @returns {boolean} - true if user is manager for the project false otherwise
 */
export const isProjectARoleManager = (state) => (projectId) => {
    return getProjectRole(state, projectId) === PROJECT_ROLE_MANAGER
}

/**
 * Check if loggedin user is member of a given project
 * @param {string} projectId - current user's membership info
 * @returns {boolean} - true if user is manager for the project false otherwise
 */
export const isProjectARoleMember = (state) => (projectId) => {
    const projectRole = getProjectRole(state, projectId)
    return projectRole === PROJECT_ROLE_MEMBER ||
        projectRole === PROJECT_ROLE_MANAGER
}
/**
 * Check if loggedin user is pending membership of a given project
 * @param {string} projectId - current user's membership info
 * @returns {boolean} - true if user is manager for the project false otherwise
 */
export const isProjectARolePending = (state) => (projectId) => {
    return getProjectRole(state, projectId) === PROJECT_ROLE_PENDING
}

/**
 * Check if loggedin user is not in a given project
 * @param {string} projectId - current user's membership info
 * @returns {boolean} - true if user is manager for the project false otherwise
 */
export const isProjectARoleNone = (state) => (projectId) => {
    return getProjectRole(state, projectId) === PROJECT_ROLE_NONE
}
