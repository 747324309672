/**
 * @function ellipsize is taking the description and ellipsize it to lenghtLimit without breaking the words
 * @param {string} str - the string to be ellipsized
 * @param {integer} lengthLimit - the length limit the the string should be ellipsized (default value 300 chars)
 * @returns {boolean|string} - retun false if the string wasn't ellipsized or ellipsized string
 */
export const ellipsize = function (str, lengthLimit) {
  let _lengthLimit = lengthLimit || 300

  if (typeof str !== "string") {
    // throw new Error('First parameter should be a string')
    console.log('First parameter should be a string')
    return false
  }

  if (typeof _lengthLimit !== "number") {
    // throw new Error('The second parameter should be a number')
    console.log(('The second parameter should be a number'))
    return false
  }

  if (str.length === 0) {
    // throw new Error('The string is empty')
    console.log('The string is empty')
    return str
  } else if (str.length <= _lengthLimit) {
    return str
  }

  if (str.length > _lengthLimit) {
    let splitStr = str.split(" ")
    let returnString = ''

    for(let i = 0; i < splitStr.length; i++ ) {
      if (returnString.length <= _lengthLimit) {
        returnString += (" " + splitStr[i])
      } else {
        returnString = (returnString + '...')
        return returnString
      }
    }
    return returnString
  }

  return false
}

/**
 * @function ellipsizeCharacters is taking the description and ellipsize it to lenghtLimit with breaking the words
 * @param {string} str - the string to be ellipsized
 * @param {integer} lengthLimit - the length limit the the string should be ellipsized (default value 300 chars)
 * @returns {boolean|string} - retun false if the string wasn't ellipsized or ellipsized string
 */
export const ellipsizeCharacters = function (str, lengthLimit) {
  let _lengthLimit = lengthLimit || 300

  if (typeof str !== "string") {
    // throw new Error('First parameter should be a string')
    console.log('First parameter should be a string')
    return false
  }

  if (typeof _lengthLimit !== "number") {
    // throw new Error('The second parameter should be a number')
    console.log(('The second parameter should be a number'))
    return false
  }

  if (str.length === 0) {
    // throw new Error('The string is empty')
    console.log('The string is empty')
    return str
  } else if (str.length <= _lengthLimit) {
    return str
  }

  if (str.length > _lengthLimit) {
    let returnString = ''
    returnString = str.substring(0, _lengthLimit);
    returnString = (returnString + '...')
    return returnString
  }

  return false
}
