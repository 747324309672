import fetch from '../../../../utils/fetch';

const initialState = {
  isLoading: false,
  project: null,
  error: null,
}

const getters = {
  project(state) { return state.project },
  isLoading(state) { return state.isLoading },
  error(state) { return state.error },
}

const actions = {
  /**
   * @function updateProject
   * @param commit
   * @param projectData
   * @returns {Promise<T | never> | * | *}
   */
  updateProject({commit, state, dispatch, rootGetters, rootState}, projectData) {
    commit(types.SET_UPDATE_PROJECT_LOADING);
    return fetch('/projects/' + rootGetters['project/get/id'], {
      method: 'PATCH',
      headers: new Headers({'Content-Type': 'application/ld+json'}),
      body: JSON.stringify(projectData.project)
    })
      .then(response => response.json())
      .then((project) => {
        commit(types.SET_UPDATE_PROJECT, project)
        // User's memberships are re-fetched to reflect altered project name
        dispatch('user/profile/fetchSession', null, {root: true})
        // Update the project in the project/get module
        commit('project/get/SET_PROJECT', project, {root: true})
        // Create new org hub affiliations
        projectData.orgRequests.forEach(req => {
          dispatch('organization_projects/get/createAffiliation',
              { organization: req,
                project: project['@id'],
                requester: rootState.user.profile.sessionData['@id']
              }, {root:true})
        })

        // Update the SciStarter details on edit
        // if (!project.isDraft && project.isSciStarter === true) {
        //   dispatch('project/show/createSciStarterProject', project.id, { root: true })
        // }
        return project
      })
      .catch((e) => {
        commit(types.SET_UPDATE_PROJECT_ERROR, e)
      })
  }
}

const types = {
  SET_UPDATE_PROJECT_LOADING: 'SET_UPDATE_PROJECT_LOADING',
  SET_UPDATE_PROJECT: 'SET_UPDATE_PROJECT',
  SET_UPDATE_PROJECT_ERROR: 'SET_UPDATE_PROJECT_ERROR',
  RESET_ERROR: 'RESET_ERROR',
  RESET: 'RESET',
}

const mutations = {
  [types.SET_UPDATE_PROJECT_LOADING](state) {
    state.isLoading = true;
  },
  [types.SET_UPDATE_PROJECT](state, project) {
    state.project = project
    state.isLoading = false
  },
  [types.SET_UPDATE_PROJECT_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },
  [types.RESET_ERROR](state) {
    state.isLoading = false;
    state.error = false;
  },
  [types.RESET](state) {
    state = initialState
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters: getters,
  actions: actions,
  mutations: mutations,
}
