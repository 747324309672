// datasheet creator form mutation types
export const DATASHEET_RETRIEVE_RESET = 'DATASHEET_CREATE_RESET'
export const DATASHEET_RETRIEVE_SET_RETRIEVED = 'DATASHEET_RETRIEVE_SET_RETRIEVED'
export const DATASHEET_RETRIEVE_SET_ERROR = 'DATASHEET_RETRIEVE_SET_ERROR'
export const DATASHEET_RETRIEVE_SET_VIOLATIONS = 'DATASHEET_RETRIEVE_SET_VIOLATIONS'
export const DATASHEET_RETRIEVE_TOGGLE_LOADING = 'DATASHEET_RETRIEVE_TOGGLE_LOADING'
export const DATASHEET_UPDATE_RESET = 'DATASHEET_CREATE_RESET'
export const DATASHEET_UPDATE_SET_UPDATED = 'DATASHEET_UPDATE_SET_UPDATED'
export const DATASHEET_UPDATE_SET_ERROR = 'DATASHEET_UPDATE_SET_ERROR'
export const DATASHEET_UPDATE_RECORD_DETAILS_SET_ERROR = 'DATASHEET_UPDATE_RECORD_DETAILS_SET_ERROR'
export const DATASHEET_UPDATE_SET_VIOLATIONS = 'DATASHEET_UPDATE_SET_VIOLATIONS'
export const DATASHEET_UPDATE_TOGGLE_LOADING = 'DATASHEET_UPDATE_TOGGLE_LOADING'
export const DATASHEET_RECORD_SET_ERROR = 'DATASHEET_RECORD_SET_ERROR'
export const DATASHEET_RECORD_TOGGLE_LOADING = 'DATASHEET_RECORD_TOGGLE_LOADING'
export const DATASHEET_RECORD_SET_CREATED = 'DATASHEET_RECORD_SET_CREATED'
export const DATASHEET_RECORD_SET_VIOLATIONS = 'DATASHEET_RECORD_SET_VIOLATIONS'
export const DATASHEET_RETRIEVE_RECORDS_SET_RETRIEVED = 'DATASHEET_RETRIEVE_RECORDS_SET_RETRIEVED'
export const DATASHEET_ORGANISM_RECORD_SPECIES_SELECTED = 'DATASHEET_ORGANISM_RECORD_SPECIES_SELECTED'
export const DATASHEET_TEXT_RECORD_SET_UPDATED = 'DATASHEET_TEXT_RECORD_SET_UPDATED'
export const DATASHEET_LOCATIONS_SET_UPDATED = 'DATASHEET_LOCATIONS_SET_UPDATED'
export const DATASHEET_PUBLISHED_SET_UPDATED = 'DATASHEET_PUBLISHED_SET_UPDATED'
export const DATASHEET_UPDATE_RECORDS_SET_UPDATED = 'DATASHEET_UPDATE_RECORDS_SET_UPDATED'
export const DATASHEET_RETRIEVE_LOCATIONS_SET_RETRIEVED = 'DATASHEET_RETRIEVE_LOCATIONS_SET_RETRIEVED'
export const DATASHEET_RETRIEVE_LOCATIONS_TOGGLE_LOADING = 'DATASHEET_RETRIEVE_LOCATIONS_TOGGLE_LOADING'
export const DATASHEET_RETRIEVE_LOCATIONS_SET_ERROR = 'DATASHEET_RETRIEVE_LOCATIONS_SET_ERROR'
export const DATASHEET_CHECK_LOCATION_EXISTENCE_SET_RETRIEVED = 'DATASHEET_CHECK_LOCATION_EXISTENCE_SET_RETRIEVED'
export const DATASHEET_CHECK_LOCATION_EXISTENCE_SET_ERROR = 'DATASHEET_CHECK_LOCATION_EXISTENCE_SET_ERROR'
export const DATASHEET_CHECK_LOCATION_EXISTENCE_TOGGLE_LOADING = 'DATASHEET_CHECK_LOCATION_EXISTENCE_TOGGLE_LOADING'

// get organisms mutation types
export const DATASHEET_RETRIEVE_ORGANISMS_SET_ITEMS = 'DATASHEET_RETRIEVE_ORGANISMS_SET_ITEMS'
export const DATASHEET_RETRIEVE_ORGANISMS_SET_VIEW = 'DATASHEET_RETRIEVE_ORGANISMS_SET_VIEW'
export const DATASHEET_RETRIEVE_ORGANISMS_SET_ERROR = 'DATASHEET_RETRIEVE_ORGANISMS_SET_ERROR'
export const DATASHEET_RETRIEVE_ORGANISMS_TOGGLE_LOADING = 'DATASHEET_RETRIEVE_ORGANISMS_TOGGLE_LOADING'


// Datasheet Mutations
export const DATASHEET_SET_LOADING = 'DATASHEET_SET_LOADING'
export const DATASHEET_SET_UPLOADING = 'DATASHEET_SET_UPLOADING'
export const DATASHEET_SET_PUBLISHING = 'DATASHEET_SET_PUBLISHING'
export const DATASHEET_SET_PUBLISHED = 'DATASHEET_SET_PUBLISHED'

export const DATASHEET_SET_RETRIEVED = 'DATASHEET_SET_RETRIEVED'
export const DATASHEET_SET_ERROR = 'DATASHEET_SET_ERROR'
export const DATASHEET_SET_VIOLATIONS = 'DATASHEET_SET_VIOLATIONS'
export const DATASHEET_RESET = 'DATASHEET_RESET'

