import { mapActions, mapGetters } from 'vuex'

export default {
  components: { },
  created() {
    this.sendDataToHeader(this.headerData, this);
    this.getStats();
  },
  beforeDestroy () {
    this.reset()
  },

  computed: mapGetters({
    stats: 'about/show/stats',
    isLoading: 'staff/show/isLoading'
  }),

  methods: {
    ...mapActions({
      getStats: 'about/show/getStats',
    }),

    /**
     * This function should be removed once all the pages are linked up properly
     */
    preventNavigation( event ) {
      event.preventDefault();
    },

    /**
     * @function reset - reset the object to the default values
     */
    reset() {
      // TODO reset the object to the default values
      // console.log("Reset object")
    }
  },

  /**
   *
   * @returns {{headerData: {backgroundPath: string, picturePath: string, headerTitle: string}}}
   */
  data() {
    return {
        headerData: {
        backgroundPath: '',
        picturePath: '',
        headerTitle: 'CitSci 2.0'
    }}
  }
}
