<template>
  <span v-if="dateTime">
    {{ fromNow }}
  </span>
</template>

<script>
export default {
  name: "MomentFromNow",

  props: {
    dateTime: {
      type: String,
      default: null,
    },
    interval: {
      type: Number,
      default: 30,
    },
  },
  data() {
    return { fromNow: moment(this.dateTime).fromNow() };
  },
  mounted() {
    this.intervalId = setInterval(this.updateFromNow, this.interval * 1000);
  },
  watch: {
    dateTime() {
      this.updateFromNow();
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    updateFromNow() {
      var newFromNow = moment(this.dateTime).fromNow();
      if (newFromNow !== this.fromNow) {
        this.fromNow = newFromNow;
      }
    },
  },
};
</script>
