export const organizationsError = state => state.organizationsError
export const organizations = state => state.organizations
export const isOrganizationsLoading = state => state.isOrganizationsLoading

export const featuredHubs = state => state.organizations.filter((featuredHub) => {
    return featuredHub.name === 'The Land Institute' 
    || featuredHub.name === 'Leave No Trace'
    || featuredHub.name === 'Big Bend Restoration'
    || featuredHub.name === 'Experimental Farm Network'
    || featuredHub.name === 'ALLARM - Alliance for Aquatic Resource Monitoring'
    || featuredHub.name === 'Town of Superior'
})
