import fetch from '../../../../utils/fetch'

const getInitialState = () => {
  return {
    isLoading: false,
    id: null,
    projects: null,
    featuredProjects: null,
    error: null,
    page: 1,
    perPage: 10,
    total: 0,
    searchFilterText: null,
    searchFilterOrg: null,
    sortBy: 'measurementsTotal',
    sortDirection: 'desc',
    sortDesc: true,
  }
}

const state = getInitialState();

const getters = {
  projects(state) {
    return state.project
  },
  featuredProjects(state) {
    return state && state.featuredProjects
  },
  isLoading(state) {
    return state.isLoading
  },
  error(state) {
    return state.error
  },
  page(state) {
    return state && state.page
  },
  perPage(state) {
    return state && state.perPage
  },
  total(state) {
    return state && state.total
  },
  searchFilterText(state) {
    return state && state.searchFilterText
  },
  searchFilterOrg(state) {
    return state && state.searchFilterOrg
  },
  sortBy(state) {
    return state && state.sortBy
  },
  sortDirection(state) {
    return state && state.sortDirection
  },
  sortDesc(state) {
    return state && state.sortDesc
  }
}

const actions = {
  /**
   * @function getProjects- retrieve projects
   * @param commit
   * @param data
   * @param dispatch
   * @returns {Promise<T | never> | * | *}
   */
  get({ commit }, data) {
    commit(types.SET_PROJECTS_LOADING);
    let urlParams = typeof data != 'undefined' && data != null && typeof data.urlParams != 'undefined' && data.urlParams != null ? data.urlParams : ''
    return fetch('/projects' + urlParams)
      .then(response => response.json())
      .then((projects) => {
        commit(types.SET_PROJECTS, projects)
        return projects
      })
      .catch((error) => {
        commit(types.SET_PROJECTS_ERROR, error)
      })
  },

  getFeaturedProjects({ commit }, data) {
    commit(types.SET_PROJECTS_LOADING);
    let urlParams = typeof data != 'undefined' && data != null && typeof data.urlParams != 'undefined' && data.urlParams != null ? data.urlParams : ''
    return fetch('/projects' + urlParams)
      .then(response => response.json())
      .then((projects) => {
        commit(types.SET_FEATURED_PROJECTS, projects['hydra:member'])
        return projects
      })
      .catch((error) => {
        commit(types.SET_PROJECTS_ERROR, error)
      })
  },

  refresh({commit }) {
    commit(types.RESET);
  },

  setPage({ commit }, page) {
    commit(types.SET_PAGE, page)
  },
  setPerPage({ commit }, perPage) {
    commit(types.SET_PER_PAGE, perPage)
  },
  setTotal({ commit }, total) {
    commit(types.SET_TOTAL, total)
  },
  setFilterText({ commit }, text) {
    commit(types.SET_SEARCH_TEXT, text)
  },
  setFilterOrg({ commit }, org) {
    commit(types.SET_SEARCH_ORG, org)
  },
  setSortBy({ commit }, sortBy) {
    commit(types.SET_SORT_BY, sortBy)
  },
  setSortDirection({ commit }, sortDirection) {
    commit(types.SET_SORT_DIRECTION, sortDirection)
  },
  setSortDesc({ commit }, sortDesc) {
    commit(types.SET_SORT_DESC, sortDesc)
  },
}

const types = {
  SET_PROJECTS_LOADING: 'SET_PROJECTS_LOADING',
  SET_PROJECTS: 'SET_PROJECTS',
  SET_FEATURED_PROJECTS: 'SET_FEATURED_PROJECTS',
  SET_PROJECTS_ERROR: 'SET_PROJECTS_ERROR',
  SET_PAGE: 'SET_PAGE',
  SET_PER_PAGE: 'SET_PER_PAGE',
  SET_TOTAL: 'SET_TOTAL',
  SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
  SET_SEARCH_ORG: 'SET_SEARCH_ORG',
  SET_SORT_BY: 'SET_SORT_BY',
  SET_SORT_DIRECTION: 'SET_SORT_DIRECTION',
  SET_SORT_DESC: 'SET_SORT_DESC',
  RESET_ERROR: 'RESET_ERROR',
  RESET: 'RESET',
}
const mutations = {
  [types.SET_PROJECTS_LOADING](state) {
    state.isLoading = true;
  },
  [types.SET_PROJECTS](state, projects) {
    state.projects = projects
    state.isLoading = false
  },
  [types.SET_FEATURED_PROJECTS](state, projects) {
    state.featuredProjects = projects
    state.isLoading = false
  },
  [types.SET_PROJECTS_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },
  [types.SET_PAGE](state, page) {
    state.page = page;
  },
  [types.SET_PER_PAGE](state, perPage) {
    state.perPage = perPage;
  },
  [types.SET_TOTAL](state, total) {
    state.total = total;
  },
  [types.SET_SEARCH_TEXT](state, text) {
    state.searchFilterText = text;
  },
  [types.SET_SEARCH_ORG](state, org) {
    state.searchFilterOrg = org;
  },
  [types.SET_SORT_BY](state, sortBy) {
    state.sortBy = sortBy;
  },
  [types.SET_SORT_DIRECTION](state, direction) {
    state.sortDirection = direction;
  },
  [types.SET_SORT_DESC](state, desc)   {
    state.sortDesc = desc;
  },
  [types.RESET_ERROR](state) {
    state.isLoading = false;
    state.error = null;
  },
  [types.RESET](state) {
    Object.assign(state, getInitialState());
  },
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
}
