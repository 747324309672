import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,
  state: {
    created: null,
    error: '',
    createdObservationViolations: null,
    isLoading: false,
    retrievedMembersAssignedLocations: null,
    retrievedMembersAssignedLocationsIsLoading: false,
    retrievedMembersAssignedLocationsError: '',
    createdLocationError: '',
    createdLocationIsLoading: false,
    createdLocation: null,
    createdLocationViolations: null
  },
  actions,
  getters,
  mutations
}
