<template>
  <div class="card-body">
    <div class="row mb-2">
      <div class="col-md-12">
        <label><b>Select a Project:</b></label>
        <b-form-select
          id="projectSelect"
          class="mb-2"
          v-model="selectedProjectForAnalysis"
        >
          <option v-for="project in publicProjects" :value="project">
            {{ project.name }}
          </option>
        </b-form-select>
      </div>
    </div>
    <div
      class="row mb-2"
      v-if="this.selectedProjectForAnalysis && !this.isLoadingAllLocations"
    >
      <div class="col-md-12" v-if="this.allProjectLocations.length > 0">
        <label><b>Select Location:</b></label>
        <b-form-select
          id="projectLocationsSelect"
          class="mb-2"
          v-model="selectedLocationForAnalysis"
        >
          <option v-for="location in allLocations" :value="location.id">
            {{ location.name }} ({{ location.observationsTotal }} Observations)
          </option>
        </b-form-select>
      </div>
      <div class="col-md-12" v-else>
        <label>No Locations with Observations for this Project</label>
      </div>
    </div>
    <LocationTrends
      v-if="selectedLocationForAnalysis !== null"
      :key="selectedLocationForAnalysis"
      :location-id="selectedLocationForAnalysis"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LocationTrends from "../../../location/LocationTrends";

export default {
  name: "bigBendAnalysis",
  components: {
    LocationTrends
  },
  data() {
    return {
      publicProjects: null,
      selectedProjectForAnalysis: null,
      selectedLocationForAnalysis: null,
      allLocations: null,
      rsraProjectId: '24c609bf-5e00-4499-9cb4-4ff22d309e95',
      rsraAreaId: 'b4e2fef0-ef1a-4a10-a5fb-0606e0ce70ea',
      uwwProjectId: '3e2b9d9c-5d3f-4bc7-83a8-f303904ead8c',
      uwwAreaId1: 'f0bfbd7c-3d5e-4eba-b9fe-966006071a6c',
      uwwAreaId2: '8f541cd1-f359-4005-86d2-876fa2365ff1'
    };
  },
  created() {
    if (this.projects) {
      this.publicProjects = Object.values(this.projects).filter(p => !p.isPrivate)
      this.selectedProjectForAnalysis = this.publicProjects[0]
    }
  },
  watch: {
    projects(newVal) {
      if (newVal) {
        // Select first available project
        //  - this handles scenario where user navigates directly to /trends
        this.publicProjects = Object.values(newVal).filter(p => !p.isPrivate)
        this.selectedProjectForAnalysis = this.publicProjects[0]
      }
    },
    selectedProjectForAnalysis(newVal) {
      if (newVal) {
        this.selectedLocationForAnalysis = null;
        this.getAllProjectLocations({ id: newVal.id });
      }
    },
    allProjectLocations(newVal) {
      if (newVal && newVal.length > 0) {
        if (this.selectedProjectForAnalysis.urlField === 'rapid-stream-riparian-assessment') {
          this.allLocations = newVal.filter(loc => {
            return loc.id === this.rsraAreaId
          })
        } else if (this.selectedProjectForAnalysis.urlField === 'utah-water-watch') {
          this.allLocations = newVal.filter(loc => {
            return loc.id === this.uwwAreaId1 || loc.id === this.uwwAreaId2
          })
        } else {
          this.allLocations = newVal
        }
        if (this.allLocations.length > 0) {
          this.selectedLocationForAnalysis = this.allLocations[0].id;
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      organization: "organization/get/organization",
      projects: "organization/get/projects",
      isLoadingAllLocations: "project/show/isLoadingAllLocations",
      allProjectLocations: "project/show/allProjectLocations"
    })
  },
  methods: {
    ...mapActions({
      getAllProjectLocations: "project/show/getAllProjectLocations"
    })
  }
};
</script>

<style lang="scss"></style>
