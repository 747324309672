import fetch from '../../../utils/fetch'

const getInitialState = () => {
  return {
    isLoading: false,
    error: null,

    projectsTotal: null,
    usersTotal: null,
    locationsTotal: null,
    measurementsTotal: null,
    observationsTotal: null,
  }
}

const state = getInitialState()

const getters = {
  projectsTotal(state) { return state && state.projectsTotal },
  usersTotal(state) { return state && state.usersTotal },
  locationsTotal(state) { return state && state.locationsTotal },
  measurementsTotal(state) { return state && state.measurementsTotal },
  observationsTotal(state) { return state && state.observationsTotal },
}

const actions = {
  /**
   * @function get - retrieve Stats from API for current organization
   * @param commit
   * @param rootGetters
   * @returns {Promise<T | never> | * | *}
   */
  get({ commit, rootState }) {
    commit(types.RESET);
    commit(types.SET_ORGANIZATION_STATS_LOADING);
    return fetch('/organization/' + rootState.organization.get.organization.url + '/stats')
      .then(response => response.json())
      .then((data) => {
        commit(types.SET_ORGANIZATION_STATS, data)
      })
      .catch((error) => {
        commit(types.SET_ORGANIZATION_STATS_ERROR, error.message)
      })
  },

}

const types = {
  SET_ORGANIZATION_STATS_LOADING: 'SET_ORGANIZATION_STATS_LOADING',
  SET_ORGANIZATION_STATS: 'SET_ORGANIZATION_STATS',
  SET_ORGANIZATION_STATS_ERROR: 'SET_ORGANIZATION_STATS_ERROR',
  RESET_ERROR: 'RESET_ERROR',
  RESET: 'RESET',
}

const mutations = {
  [types.SET_ORGANIZATION_STATS_LOADING](state) {
    state.isLoading = true;
    state.error = false;
  },
  [types.SET_ORGANIZATION_STATS](state, stats) {
    state.projectsTotal = stats.projectsTotal
    state.usersTotal = stats.usersTotal
    state.locationsTotal = stats.locationsTotal
    state.measurementsTotal = stats.measurementsTotal
    state.observationsTotal = stats.observationsTotal
    state.isLoading = false;
  },
  [types.SET_ORGANIZATION_STATS_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },
  [types.RESET_ERROR](state) {
    state.isLoading = false;
    state.error = false;
  },
  [types.RESET](state) {
    Object.assign(state, getInitialState());
  },
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations
}
