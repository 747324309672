<template>
  <div class="ProjectOrgAffiliations">
    <b-container class="mt-0 mb-0" v-if="!isLoading">
      <label>Available Hubs:</label>
      <multiselect id="organization-picklist"
                   v-model="newOrgs"
                   @input="(value) => newOrgs = value"
                   :options="availableOrgs"
                   label="name"
                   track-by="id"
                   :disabled="isLoading"
                   select-label="" deselect-label=""
                   :close-on-select="true"
                   :block-keys="['Tab', 'Enter']"
                   :taggable="false"
                   :multiple="true"
                   placeholder="Select Hub(s)"></multiselect>
      <!-- uncomment to view selected values -->
      <!-- <pre class="language-json"><code>{{ this.newOrgs }}</code></pre> -->
    </b-container>

    <b-container class="mt-0 mb-0" v-if="!isLoading">
      <h5>
        <span class="h4-icon"><i class="fa fa-handshake-o fa-lg"></i></span>&nbsp;
        Affiliated Hubs:
      </h5>
      <b-list-group v-if="approved && Object.keys(approved).length > 0">
        <b-list-group-item v-for="affiliation in approved" :key="affiliation.id">
          <b-row>
            <b-col md="12">
              <router-link class="font-weight-bold" title="Visit the Hub (opens in a new tab)"
                           :to="{ name: 'OrganizationShow', params: { name: affiliation.organization.url }}"
                           target="_blank">
                <b>{{ affiliation.organization.name }}</b> Hub
              </router-link>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
      <label v-else>No Hub Affiliations</label>
    </b-container>

    <b-container class="mt-0 mb-0" v-if="!isLoading">
      <h5>
        <span class="h4-icon"><i class="fa fa-compress fa-lg"></i></span>&nbsp;&nbsp;
        Pending Affiliations:
      </h5>
      <b-list-group v-if="pending && Object.keys(pending).length > 0">
        <b-list-group-item v-for="request in pending" :key="request.id">
          <b-row>
            <b-col md="12">
              <router-link class="font-weight-bold" title="Visit the Hub (opens in a new tab)"
                           :to="{ name: 'OrganizationShow', params: { name: request.organization.url }}"
                           target="_blank">
                <b>{{ request.organization.name }}</b> Hub
              </router-link>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
      <label v-else>No Pending Requests</label>
    </b-container>

    <b-container class="mt-0 mb-0" v-if="!isLoading">
      <h5>
        <span class="h4-icon"><i class="fa fa-ban fa-lg"></i></span>&nbsp;&nbsp;
        Denied Affiliations:
      </h5>
      <b-list-group v-if="denied && Object.keys(denied).length > 0">
        <b-list-group-item v-for="request in denied" :key="request.id">
          <b-row>
            <b-col md="12">
              <router-link class="font-weight-bold" title="Visit the Hub (opens in a new tab)"
                           :to="{ name: 'OrganizationShow', params: { name: request.organization.url }}"
                           target="_blank">
                <b>{{ request.organization.name }}</b>
              </router-link>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
      <label v-else>No Requests Denied</label>
    </b-container>
  </div>
</template>

<script>

import {mapGetters, mapActions} from "vuex";

export default {
  name: 'ProjectOrgAffiliations',
  data() {
    return {
      availableOrgs: [],
      newOrgs: [],
    }
  },
  props: {
    newAffiliations: {
      type: Function,
      required: true
    },
  },
  created() {
    this.orgProjects('projects')
    this.getOrganizations()
  },
  watch: {
    allOrgProjects(newValue) {
      if (newValue) {
        this.getOrganizations()
        this.newOrgs = []
      }
    },
    organizationsList(newValue) {
      if (newValue) {
        if (this.allOrgProjects) {
          let usedOrgs = this.allOrgProjects.map(org => org.organization)
          this.availableOrgs = this.organizationsList.filter(org => !usedOrgs.find(orgExist => orgExist.id === org.id)).map(org => ({...org}))
        } else {
          this.availableOrgs = this.organizationsList.map(org => ({...org}))
        }
      }
    },
    newOrgs(newValue) {
      // Emit the new organizations to the parent
      this.newAffiliations(newValue.map(org => org['@id']))
      // TODO: submit the affiliation request from here
      // - this.sendAffiliationRequest(newValue.id)
    }
  },
  computed: {
    ...mapGetters({
      organizationsList: 'organization/list/organizations',
      allOrgProjects: "organization_projects/get/orgProjects",
      isLoading: "organization_projects/get/isLoading",
      pending: "organization_projects/get/pending",
      approved: "organization_projects/get/approved",
      denied: "organization_projects/get/denied",
    })
  },
  methods: {
    ...mapActions({
      orgProjects: "organization_projects/get/get",
      getOrganizations: 'organization/list/getOrganizations',
    }),
    sendAffiliationRequest(organizationId) {
    },
  }
};
</script>

<style>

</style>
