import VueLoader from "../../layout/Loader";
import VueGoogleMap from "../../layout/VuetifyGoogleMap";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import RecordList from "./RecordList";
import ObservationDate from "./ObservationDate.vue";
import FromNow from "../../layout/FromNow";


const LOCATION_COORDINATES = "previewPanelLocationCoordinates";
const LOCATION_PICKLIST = "previewPanelLocationPicklist";
const LOCATION_BOTH = "previewPanelLocationBoth";

export default {
  components: {
    VueLoader,
    VueGoogleMap,
    ObservationDate,
    RecordList,
    FromNow
  },

  provide() {
    return { parentValidator: null };
  },

  data() {
    return {
      hasChanges: false,
      observedAt: null,

      newObservationRecords: [],
      newObservationDatasheetRecords: null,
      LOCATION_COORDINATES: LOCATION_COORDINATES,
      LOCATION_PICKLIST: LOCATION_PICKLIST,
      LOCATION_BOTH: LOCATION_BOTH,


      datetimePickerOptions: {
        allowInputToggle: true,
        format: "YYYY/MM/DD h:mm:ss A",
        showClose: true,
        useStrict: true,
        maxDate: moment()
          .add(30, "m")
          .toDate()
      },

      locationSelected: "",
      locationType: LOCATION_PICKLIST,
      locationTypeDatasheet: null,
      locationModel: {
        latitude: "",
        longitude: "",
        name: ""
      },
      locationsObserver: [],
      parentValidator: null,
      editDateValue: "",

      textAreaValue: "",
      dateValue: "",

    };
  },

  computed: {
    ...mapGetters({
      isLoadingObservation: "observation/show/isLoading",
      isLoadingDatasheet: "datasheet/creator/retrievedDatasheetIsLoading",
      isLoadingLocationUpdate: "location/show/isLoading",
      vuexObservation: "observation/show/retrieved",
      vuexDatasheet: "datasheet/creator/retrievedDatasheet",

      error: "observation/show/error",
      deletedSuccess: "observation/show/deletedSuccess"
    }),
    canEdit() {
      if (!this.vuexObservation || !this.vuexObservation.project) {
        return false;
      }
      return (
        this.isProjectRoleManager(this.vuexObservation.project.id) ||
        this.isObserver(this.vuexObservation)
      );
    },

  },

  watch: {
    /**
     * @function vuexObservation - function that is called when this.observation is changed
     * @param vuexObservation
     */
    vuexObservation(vuexObservation) {
      this.$nextTick(() => {
        this.hasChanges = false;
      });

      this.observedAt = vuexObservation.observedAt;

      this.locationModel = { ...vuexObservation.location };

      this.locationsObserver = [
        {
          ...vuexObservation.location,
          locationId: vuexObservation.location.id
        }
      ];
      this.locationSelected = vuexObservation.location.id;
      // deep copy records so modifying them doesnt affect vuex
      this.newObservationRecords = this.setUsefulRecordFields(
        vuexObservation.records
      );
      if (vuexObservation.datasheet) {
        switch (vuexObservation.datasheet.locationFormat) {
          case 0:
            this.locationTypeDatasheet = LOCATION_COORDINATES;
            break;
          case 1:
            this.locationTypeDatasheet = LOCATION_PICKLIST;
            break;
          case 2:
            this.locationTypeDatasheet = LOCATION_BOTH;
            break;
        }
        this.locationType = LOCATION_PICKLIST;
        this.getDatasheet(this.vuexObservation.datasheet["@id"]);
      } else {
        // observation does not have a datasheet
        // This occurs with some 1.0 data & is OK
        // ToDo - Be sure this case is handled appropriately
      }
    },
    vuexDatasheet(vuexDatasheet) {
      if (!vuexDatasheet) {
        return;
      } else if (vuexDatasheet.status === "draft") {
        this.customErrorMessage =
          "This datasheet must be published to edit this observation.";
      }
      // this.vuexDatasheet = {...vuexDatasheet,
      //   records: vuexDatasheet.records.map((record)=>{
      //     return {
      //       ...record,
      //       records: record.records.map((subRecord)=>{
      //         return {
      //           ...subRecord
      //         }
      //       })
      //     }
      //   })
      // };
      // Locations picklist is handled and location type is set
      // Datasheet locations are set by default
      this.locationsObserver = vuexDatasheet.locations;
      // Fetch this user's assigned locations, if any
      // if (this.vuexDatasheet.locationFormat > 0) {
      //   this.retrieveMembersAssignedLocations(this.membershipMap[this.vuexDatasheet.project.id]['@id'])
      // }

    },
    newObservationRecords: {
      deep: true,
      handler: function () {
        this.hasChanges = true;
      }
    },
    observedAt: {
      handler: function () {
        this.hasChanges = true;
      }
    },
    locationSelected: {
      handler: function () {
        this.hasChanges = true;
      }
    },



  },

  methods: {
    ...mapActions({
      getObservation: "observation/show/getObservation",
      getDatasheet: "datasheet/creator/retrieveDatasheet",

      resetError: "observation/show/resetError",
      setError: "observation/show/setError",
      uploadFile: "observation/show/uploadObservationImage",
      createRecord: "observation/show/createRecord",
      observationUpdate: "observation/show/observationUpdate",
      mainObservationDetailsUpdate:
        "observation/show/mainObservationDetailsUpdate",
      updateLocationInfo: "location/show/updateLocationInfo"
    }),
    moment(value) {
      return moment(value);
    },
    setUsefulRecordFields(records) {
      // This method sets the datasheet records
      if (!records || records.length === 0) {
        return [];
      }
      return records.map(record => {
        // use marker as identifier for modifying records, default to @ID
        // set as random UUID for new records until they receive an ID

        const value =
          record.recordType === "datetime"
            ? moment(record.value.split("+")[0]).format(
              this.datetimePickerOptions.format
            )
            : record.value;

        // reformat datasheet record if applicable
        let datasheetRecord = null;
        if (record.datasheetRecord) {
          datasheetRecord = { ...record.datasheetRecord };
          if (
            ["dropdown", "radio"].includes(record.datasheetRecord.recordType)
          ) {
            datasheetRecord.optionValues = datasheetRecord.optionsSet.optionsValues.map(
              optionValue => {
                return { text: optionValue.value, value: optionValue["@id"] };
              }
            );
          }
        }

        return {
          ...record,
          datasheetRecord: datasheetRecord,
          value: value,
          marker: record.id.toString(),
          records: this.setUsefulRecordFields(record.records)
        };
      });
    },

    stripRecords(records) {
      // This method is for submitting updates & removes fields & changes options to iri
      let recordsSub = [];
      if (!records || records.length === 0) {
        return [];
      }
      const recordsTop = records.map((record, index) => {
        let newRecord = {
          orderNumber: index
        };
        if (record.optionValue) {
          newRecord.optionValue = record.optionValue["@id"];
        }
        if (record.datasheetRecord) {
          newRecord.datasheetRecord = record.datasheetRecord["@id"];
        }
        if (record.fileObject) {
          newRecord.fileObject = record.fileObject["@id"];
        }
        if (record.organism) {
          newRecord.organism = record.organism["@id"];
          recordsSub = this.stripRecords(record.records);
        }
        return {
          ...newRecord,
          value: record.value,
          recordType: record.recordType,
          id: record.id,
          ["@id"]: record["@id"]
        };
      });
      return [...recordsTop, ...recordsSub];
    },

    /**
     * @function onSaveClick
     * @param currItem
     * @param modalVal
     * @param detailsName
     */
    onSaveObservationClick() {
      let data = {
        observedAt: this.observedAt,
        location: `/areas/${this.locationSelected}`,
        records: this.stripRecords(this.newObservationRecords)
      };
      this.observationUpdate({
        id: this.vuexObservation.id,
        observation: data
      });
      return;
    },

    /**
     * @function onDismissedClick - when the user dismiss error alert, we reset error message
     */
    onDismissedClick() {
      this.resetError();
    },
  },

  created() {
    this.resetError();
    this.getObservation({
      id: this.$route.params.id,
      query: "?context=update"
    }).then(data => {
      if (!data) {
        this.$router.push({ name: "NotFound" });
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    // Notify user they have unsaved changes
    if (this.hasChanges) {
      this.$bvModal.show("leaveModal");
      this.$nextTick(() => {
        // Set up hide handler.
        // Done in next tick to ensure Modal is in DOM
        this.$refs.leaveModal.$once("hide", bvEvt => {
          if (bvEvt.trigger === "ok") {
            // User clicked on built in OK button
            // So we set up a handler to listen for modal 'hidden' event
            // and advance to next route once hidden
            this.$refs.leaveModal.$once("hidden", () => {
              next();
            });
          } else {
            // User clicked cancel or the modal 'x' close button
            next(false);
          }
        });
      });
    } else {
      next();
    }
  }
};
