<template>
  <div class="card-body">
    <h2 class="mt-0">Trends</h2>
    <BigBendAnalysis v-if="organizationId === 'big-bend-restoration'"></BigBendAnalysis>
    <OtherHubsAnalysis v-else></OtherHubsAnalysis>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BigBendAnalysis from "./bigBendAnalysis.vue";
import OtherHubsAnalysis from "./otherHubsAnalysis.vue";

export default {
  name: "organizationAnalysis",
  components: {
    BigBendAnalysis,
    OtherHubsAnalysis
  },
  data() {
    return {
    };
  },
  created() {
  },
  watch: {
  },
  computed: {
    ...mapGetters({
      organization: "organization/get/organization",
      organizationId: "organization/get/id",
    })
  },
  methods: {
  }
};
</script>

<style lang="scss"></style>
