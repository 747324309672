<template>
  <div class="card-body">
    <h2 class="m-0">Forum</h2>
    <p class="m-0">Coming Soon...</p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueLoader from "../../../layout/Loader.vue";
export default {
  name: "organizationForum",
  components: {
    VueLoader
  },
  data() {},
  created() {},
  computed: {
    ...mapGetters({
      organizationId: "organzation/get/id"
    })
  },
  methods: {
    ...mapActions({})
  }
};
</script>
