import SubmissionError from '../../../../error/SubmissionError'
import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const register = ({ commit, dispatch }, item, page = '/users') => {
  commit(types.USER_REGISTER_SET_ERROR, '')
  commit(types.USER_REGISTER_TOGGLE_LOADING)

  return fetch(page, { method: 'POST', body: JSON.stringify(item) })
    .then((response) => {
      commit(types.USER_REGISTER_TOGGLE_LOADING)

      return response.json()
    })
    .then((data) => {
      commit(types.USER_REGISTER_SET_CREATED, data)

      const creds = {
        email: data.email,
        password: item.password
      }
      dispatch("user/auth/authenticate", { creds: creds }, { root: true });
      return creds
    })
    .catch((e) => {
      commit(types.USER_REGISTER_TOGGLE_LOADING)

      if (e instanceof SubmissionError) {
        commit(types.USER_REGISTER_SET_VIOLATIONS, e.errors)
        // eslint-disable-next-line
        commit(types.USER_REGISTER_SET_ERROR, e.errors._error)
        return
      }

      commit(types.USER_REGISTER_SET_ERROR, e.message)
    })
}

export const reset = ({ commit }) => {
  commit(types.USER_REGISTER_RESET)
}
