<template>
<div class="row mb-3">
    <label class="col-md-3 font-weight-bold text-muted">
        Observation Date
        <span class="text-danger">&#42;</span>
    </label>
    <div class="col-md-9 date-tipe-picker-wrapper">
        <date-picker :class="{ 'is-invalid': errors.has('Observation date') }" v-model="valueMoment" v-validate="'required'" placeholder="Observation date" data-vv-name="Observation date" :config="datetimePickerOptions" />
        <div v-if="errors.has('Observation date')">
            {{ errors.first("Observation date") }}
        </div>
        <div v-if="observedAtVsCreatedDiff">
            <i class="fa fa-clock text-warning"></i>
            The date this observation was created differs from when the user
            reported observing it by {{ observedAtVsCreatedDiff }} months
        </div>
    </div>
</div>
</template>

<script>
import DatePicker from "vue-bootstrap-datetimepicker";
import moment from "moment";
import FromNow from "../../layout/FromNow";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";

export default {
    components: {
        DatePicker,
    },
    props: {
      // observed ats are always shown in the local time of the user
      // & stored in the database in that raw format.
      // When the API returns these values, it erronously adds +000 for UTC when it is not
        value: {
            type: String,
            default: null
        },
        format: {
            type: String,
            default: "datetime"
        },
        createdAt: {
            type: String,
            default: null
        },
    },
    data() {
        return {
            valueOriginal: this.value
        }
    },
    emits: ['input'],

    computed: {
        valueMoment: {
            get() {
                if(!this.value){return null}
                const date = moment(this.value.split("+")[0])
                if (date) {
                    return date
                }
                return null
            },
            set(val) {
                if (!val) {
                this.$emit('input', null)
                    return 
                }
                const valueString = moment(
                    val,
                    this.datetimePickerOptions.format
                ).format("YYYY-MM-DDTHH:mm:ss")
                if(valueString){
                    this.$emit('input', valueString)
                    return
                }

            }
        },
        createdMoment() {
            if(!this.createdAt){return null}
            const date = moment(this.createdAt.split("+")[0])
            if (date) {
                return date
            }
            return null
        },

        datetimePickerOptions() {
            let formatShape = "YYYY/MM/DD hh:mm:ss A"
            switch (this.format) {
                case "date":
                formatShape = "YYYY/MM/DD";
                break;
                case "date_hour_minutes":
                formatShape = "YYYY/MM/DD hh:mm A";
                break;
                case "datetime":
                formatShape = "YYYY/MM/DD hh:mm:ss A";
                break;
            }
            return {
                allowInputToggle: true,
                format: formatShape,
                showClose: true,
                useStrict: true,
                maxDate: moment()
                    .add(30, "m")
                    .toDate()
            }
        },
        observedAtVsCreatedDiff() {
            if (this.createdMoment) {
                const months = this.valueMoment.diff(
                    this.createdMoment,
                    "months"
                );
                return months
            }
            return null
        }
    },
}
</script>
