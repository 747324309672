import fetch from '../../../../utils/fetch'
import {urlBuilder} from "../../../../helpers/url_builder_helper";

const getInitialState = () => {
  return {
    isLoading: false,
    error: null,
    allLocations: [],
    total: 0,
    totalFetched: 0,
  }
}

const state = getInitialState();

const getters = {}

function fetchProjectLocationsBlock({commit}, {url, ctx}) {
  return fetch(url + `${urlBuilder(ctx)}`)
    .then(response => response.json())
    .then((data) => {
      // Save the locations
      commit(types.SET_ALL_PROJECT_LOCATIONS, data['hydra:member'])
      if (ctx.currentPage === 1) {
        // Save the total expected
        commit(types.SET_TOTAL_PROJECT_LOCATIONS, data['hydra:totalItems'])
      }
      // Increment the count
      commit(types.SET_TOTAL_PROJECT_LOCATIONS_FETCHED, data['hydra:member'].length)
      if (ctx.currentPage === 1) return data
    })
    .catch((e) => {
      commit(types.SET_ALL_PROJECT_LOCATIONS_ERROR, e.message)
    })
}

const actions = {
  get({commit, state, rootGetters}) {
    commit(types.RESET)
    commit(types.SET_ALL_PROJECT_LOCATIONS_LOADING)
    const maxFetch = 500;
    const ctx = {currentPage: 1, perPage: maxFetch, sortDesc: true, sortBy: 'observationsTotal'};
    let url = '/projects/' + rootGetters['project/get/id'] + '/locations'
    fetchProjectLocationsBlock({commit}, {url, ctx})
      .then(async (data) => {
        if (state.total > maxFetch) {
          for (let i = 1; i < (state.total / maxFetch); i++) {
            const ctx = {currentPage: i + 1, perPage: maxFetch, sortDesc: true, sortBy: 'observationsTotal'};
            await fetchProjectLocationsBlock({commit}, {url, ctx})
          }
        }
      });
  },
}

const types = {
  SET_ALL_PROJECT_LOCATIONS_LOADING: 'SET_ALL_PROJECT_LOCATIONS_LOADING',
  SET_ALL_PROJECT_LOCATIONS: 'SET_ALL_PROJECT_LOCATIONS',
  SET_TOTAL_PROJECT_LOCATIONS: 'SET_TOTAL_PROJECT_LOCATIONS',
  SET_TOTAL_PROJECT_LOCATIONS_FETCHED: 'SET_TOTAL_PROJECT_LOCATIONS_FETCHED',
  SET_ALL_PROJECT_LOCATIONS_ERROR: 'SET_ALL_PROJECT_LOCATIONS_ERROR',
  RESET_ERROR: 'RESET_ERROR',
  RESET: 'RESET',
}

const mutations = {
  [types.SET_ALL_PROJECT_LOCATIONS_LOADING](state) {
    state.isLoading = true;
    state.error = false;
  },
  [types.SET_ALL_PROJECT_LOCATIONS](state, locations) {
    state.allLocations = [...state.allLocations, ...locations];
  },
  [types.SET_TOTAL_PROJECT_LOCATIONS](state, count) {
    state.total = count;
    state.isLoading = true;
  },
  [types.SET_TOTAL_PROJECT_LOCATIONS_FETCHED](state, count) {
    state.totalFetched = state.totalFetched + count;
    if (state.total === state.totalFetched) {
      state.isLoading = false;
    }
  },
  [types.SET_ALL_PROJECT_LOCATIONS_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },
  [types.RESET_ERROR](state) {
    state.isLoading = false;
    state.error = false;
  },
  [types.RESET](state) {
    Object.assign(state, getInitialState());
  },
}

export default {
  namespaced: true,
  state: getInitialState(),
  getters: getters,
  actions: actions,
  mutations: mutations
}
