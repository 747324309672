import * as types from './mutation_types'

export default {

  [types.LOCATION_SHOW_DETAILS_SET_ERROR] (state, error) {
    Object.assign(state, {error: error })
  },

  [types.LOCATION_SHOW_OBSERVATIONS] (state, observationDetails) {
    Object.assign(state, { observationDetails })
  },

  [types.LOCATION_SHOW_DATA] (state, locationDetails) {
    Object.assign(state, { locationDetails })
  },

  [types.LOCATION_SHOW_DETAILS_TOGGLE_LOADING] (state) {
    Object.assign(state, { isLoading: !state.isLoading })
  },

  [types.AREA_SHOW_SET_UPDATED] (state, locationDetails) {
    Object.assign(state, { locationDetails: locationDetails })
  }
}
