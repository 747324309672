<template>
<div class="text-center mb-3" id="addDataDropDownSection">
    <ProjectJoinButton
        v-if='!isMember'
        v-bind:project="project"
        btnClass="btn-lg">
    </ProjectJoinButton>

    <AddDataButton v-else></AddDataButton>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex'

import ProjectJoinButton from '../../../../assets/scripts/project/projectJoinButton.vue'
import AddDataButton from './AddDataButton.vue'

export default {
    components: {
        ProjectJoinButton,
        AddDataButton
    },
    computed: {
        ...mapGetters({
            project: 'project/get/project',
            isMember: 'project/get/isMember',
            // membershipMap: 'user/profile/membershipMap',
            projectId: 'project/get/id',
        }),
    }
}
</script>

<style lang="">

.addDataSection {
  background-color: rgba(0, 0, 0, 0.5);
  height: 80px;
  z-index:990 !important;
}

</style>
