import { mapActions, mapGetters } from 'vuex';
// import VueGoogleMap from '../../../components/layout/GoogleMap';
// import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import VueTable from '../../../components/layout/BootstrapVueTable';
import VueLoader from '../../../components/layout/Loader';
import VueTabs from '../../../components/layout/BootstrapVueTabs';
import ObservationRecordsTable from '../../../components/location/ObservationRecordsTable'
import ObservationsTable from './observationsTable.vue'
import LocationTrends from "../../../components/location/LocationTrends";

export default {
  name: 'LocationPage',
  components: {
    // VueGoogleMap,
    // GmapCluster,
    VueTable,
    VueTabs,
    VueLoader,
    ObservationRecordsTable,
    ObservationsTable,
    LocationTrends
  },

  provide () {
    return {
      parentValidator: null
    }
  },

  data() {

    const tabs = [
      { name: 'Observations', active: true, hash: '#observations', icon: 'binoculars', cssClass: 'navClass' },
      { name: 'Trends', active: false, hash: '#trends', icon: 'chart-line', cssClass: 'navClass' }
    ];

    return {
      mapOptions:{
        streetViewControl: false,
        fullscreenControl: false,
        scaleControl: false,
        zoomControl: true,
        mapTypeControl: false
      },
      parentValidator: null,
      trendsTabs: tabs,
      markers: [],
      isLatitudeEdit: false,
      isLongitudeEdit: false,
      isLocationNameEdit : false,
      locationModel : {
        latitude: '',
        longitude: '',
        locationName : ''
      },
      locationData: null,
      item: null,
      toProjectPage: false,
      project: null,
      projectId: null,
      locationId: null,
    }

  },

  created() {
    if (this.$route.hash) {
      this.toProjectPage = true
      this.projectId = this.$route.hash.replace('#', '')
    }
    this.locationId = this.$route.params.id;
    this.getLocationDetails(this.locationId)
  },

  beforeDestroy () {

  },

  computed: {
    ...mapGetters({
      isLoading: 'location/show/isLoading',
      isLocationUpdateLoading: 'location/show/isLoading',
      error: 'location/show/error',
      observationDetails: 'location/show/observationDetails',
      locationDetails: 'location/show/locationDetails'
    }),

    projectUrl: {
      get() {
        return this.projectId
      }
    }

  },

  watch: {

    locationDetails( newval ) {
      this.locationData = newval
      let marker = {
        lat: this.locationData.latitude,
        lng: this.locationData.longitude
      };
      this.markers.push({position: marker})
    },

    observationDetails (newVal) {
      this.tableItems = []
      newVal.forEach((obsRecord) => {
        this.tableItems.push( {
          observationId: obsRecord.id,
          observationRecorder: obsRecord.observers[0].displayName,
          observationDate: obsRecord.observedAt,
          // observationLocation: obsRecord.location.name
        })
      })
    }

  },

  methods: {
    ...mapActions({
      getLocationDetails: 'location/show/getLocationDetails',
      updateLocationInfo: 'location/show/updateLocationInfo'
    }),

    handler() {
      let args = arguments;
      for (let arg of args) {
        if (arg instanceof Function) {
          arg();
        }
      }
    },


    onEditLocationModel( flag ) {
      this.locationModel.latitude = this.item[0].location.latitude;
      this.locationModel.longitude = this.item[0].location.longitude;
      this.locationModel.locationName = this.item[0].location.name;
      this[flag] = true;

    },

    onSubmitLocationChange() {
      this.$root.$emit('bv::show::modal', 'location-change-model');
    },

    onYesClick( itemDetails ){

      let validCoordinates = this.isValidCoordinates(this.locationModel.latitude, this.locationModel.longitude, itemDetails);

      if( !validCoordinates ) {
        return;
      }

      const data = {
        id: this.$route.params.id,
        locationInfo: itemDetails.location
      };
      this.updateLocationInfo(data).then( ( result ) => {
        if( result && typeof result == 'object') {
          this.onNoClick();
        }
        // console.log("Updated ", result);
      })
    },

    onNoClick() {
      this.$refs.locationChangeModel.hide();
    },

    onCancelLocationChange( flag ) {
      this[flag] =false;
    }
  },

}
