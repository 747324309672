<template>
  <div>
    <b-form-group id="score-record"
                  label-cols-lg="0"
                  content-cols-lg="12"
                  label-class="font-weight-bold pt-0"
                  label-for="input-bin">
      <template slot="label">Bin {{ orderNumber }}:</template>
      <b-row>
        <b-col cols="2">
          <Donut class="justify-content-center"
                 :score-id=null
                 :score-data="example"
                 :score-height=50></Donut>
        </b-col>
        <b-col cols="2">
<!--          :data-vv-scope="'numericalBin' + this.orderNumber"-->
          <b-form-input id="input-label"
                        :name="'label'+orderNumber"
                        v-model="binLabel"
                        debounce="1000"
                        placeholder="Bin label"
                        v-validate="'required|min:3'"
                        data-vv-as="label"
                        :class="{ 'is-invalid': errors.has('label'+orderNumber) }"
                        aria-describedby="bin-label-input-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="bin-label-input-live-feedback">
            {{ errors.first('label' + orderNumber) }}
          </b-form-invalid-feedback>
        </b-col>
        <b-col cols="3" class="" align-v="center">
          <b-form-group
            id="min"
            label-cols-lg="4"
            content-cols-lg="6"
            label="Min:"
            label-class="font-weight-bold pt-0"
            label-for="input-min">
            <b-form-input id="input-min"
                          :name="'min'+orderNumber"
                          type="text"
                          v-model="getMinValue"
                          :disabled="true"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="3" class="" align-v="center">
          <b-form-group
            id="max"
            label-cols-lg="4"
            content-cols-lg="6"
            label="Max:"
            label-class="font-weight-bold pt-0"
            label-for="input-max">
            <b-form-input id="input-max"
                          :name="'max'+orderNumber"
                          type="number"
                          v-model.number="maxValue"
                          :disabled=getMaxBinDisabled
                          placeholder="Bin max."
                          v-validate="{ required: true, integer: true , min_value: minValue+1, max_value: maxAllowed-1 }"
                          data-vv-as="Maximum Value"
                          :class="{ 'is-invalid': errors.has('max'+orderNumber) }"
                          aria-describedby="bin-max-input-live-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="bin-max-input-live-feedback">
              {{ errors.first("max" + orderNumber) }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form-group>
  </div>
</template>

<script>
import Donut from './Donut.vue';

export default {
  name: "NumericalBinInput",
  props: {
    minValue: {
      type: Number | null,
      required: true,
    },
    initialMax: {
      type: Number | null,
      required: true,
    },
    initialLabel: {
      type: String | null,
      required: true,
    },
    orderNumber: {
      type: Number,
      required: true
    },
    maxAllowed: {
      type: Number,
      required: true,
    },
    numBins: {
      type: Number,
      required: true,
    },
    binPct: {
      type: Number,
      required: true,
    },
  },
  components: {Donut},
  inject: ['parentValidator'],
  data() {
    return {
      binLabel: this.initialLabel,
      binPercent: this.binPct,
      maxValue: this.initialMax,
    }
  },
  created() {
    this.$validator = this.parentValidator
  },
  watch: {
    minValue: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.minValue = newVal
        }
      },
    },
    initialMax: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.maxValue = newVal
        }
      },
    },
    binPct: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.binPercent = newVal
          // Reset validation when bin value changes
          this.$validator.reset()
        }
      }
    },
    binLabel(newVal) {
      if (newVal) {
        // Parent is notified of bin label change
        this.$emit('maxchange', { orderNumber: this.orderNumber, label: newVal, min: this.minValue, max: this.maxValue })
      }
    },
    maxValue(newVal) {
      // Parent is notified of bin max value change
      this.$emit('maxchange', { orderNumber: this.orderNumber, label: this.binLabel, min: this.minValue, max: this.maxValue })
    },
  },
  computed: {
    example() {
      return [{
        name: "fill",
        y: this.binPercent
      },
        {
          name: "empty",
          y: 100 - this.binPercent
        }]
    },
    getMinValue() {
      if (this.orderNumber === 1) {
        return this.minValue !== null ? '>= ' + this.minValue : null

      } else {
        return this.minValue !== null ? '> ' + this.minValue : null
      }
    },
    getMaxBinDisabled() {
      return this.orderNumber === this.numBins
    },
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
