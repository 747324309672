import { mapActions, mapGetters } from 'vuex'
import observations from "../../../router/observation";
import VueLoader from "../../../components/layout/Loader";

export default {
  components: {
    VueLoader
  },

  data () {
    return {
      observationsAll: [],
      selected: null,
        options: [
          { value: null, text: 'Please select a project' },
          { value: 'a', text: 'This is First option' },
          { value: 'b', text: 'This is Second option' },
          { value: 'c', text: 'This is Third option' },
          { value: 'd', text: 'Disabled option', disabled: true }
        ],

      isBusy: false,
      emptyText: "No Observations Available",
      emptyFilteredText: "No Observations Available for Search Criteria",
      originalItemCount: 0,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      sortBy: 'observedAt',
      sortDesc: true,
      sortDirection: 'desc',
      filterObject: {
        filterText: null,
      },

      // Boolean indicating if the advanced filter is toggled
      advFilterVisible: false,

      allObservationsListFields: [
        { key: 'observedAt', label: 'Date Observed', sortable: true, tdClass: 'color-gray',  formatter: (val) => { return this.displayDate(val) } },
        { key: 'projectName', label: 'Project Name', sortable: true, formatter: (val, fieldName, item) => { return item && item.project && this.ellipsizeCharacters(item.project.name,45) } },
        { key: 'locationName', label: 'Location Name', sortable: true, formatter: (val, fieldName, item) => { return item && item.location && this.ellipsizeCharacters(item.location.name,45) } },
        { key: 'locationLatitude', label: 'Latitude', sortable: true, formatter: (val, fieldName, item) => {
          if (item.project.isPrivate) return 'private'
          else return item && item.location && item.location.latitude
        } },
        { key: 'locationLongitude', label: 'Longitude', sortable: true, formatter: (val, fieldName, item) => {
          if (item.project.isPrivate) return 'private'
          else return item && item.location && item.location.longitude
        } },
        //{ key: 'createdAt', label: 'Date Created', sortable: true, tdClass: 'color-gray',  formatter: (val) => { return this.displayDate(val) } },
        { key: 'photo', label: 'Photo', sortable: false },
        { key: 'options', label: 'Options', tdClass: 'color-blue', thStyle: { width: '190px!important'} }
      ],

    }
  },

  mounted() {
    // Set the initial number of projects
    this.totalRows = this.observationsAll.length
  },

  computed: {
    ...mapGetters({
      error: 'observation/list/error',
      isLoading: 'observation/list/isLoadingAll',
    }),

    filterVal: {
      get: function() {
        // This is our multi-filter computed value
        // - search the name of the location
        //More filters can be added here

        let retVal = ''
        if (this.filterObject.filterText) {
          retVal = retVal.length === 0 ? 'location.name=' + this.filterObject.filterText : '&location.name=' + this.filterObject.filterText
        }

        return retVal
      }
    },

    fromItem () {
      // Computed value for showing lower bound based on current page and per page value
      return this.totalRows > 0 ? (this.currentPage - 1) * this.perPage + 1 : 0
    },

    toItem () {
      // Computed value for showing upper bound based on current page and per page value
      return this.totalRows < (this.currentPage * this.perPage) ? this.totalRows : this.currentPage * this.perPage
    },

  },

  methods: {
    ...mapActions({
      getItems: 'observation/list/getAllObservationsList',
    }),

    myProvider(ctx, callback) {

      // Busy state is set
      this.isBusy = true

      // Per page value is set for the initial call when table is mounted
      if (ctx.perPage === 0) {
        ctx.perPage = 10
      }

      // Set the callback at all times
      let _callback = null
      if (!callback) {
        _callback = this.providerCallback
      }
      if (typeof callback === 'function') {
        _callback = callback
      }

      // URL Params are built
      let urlParams = this.urlBuilder(ctx)

      // Observations are fetched from API
      try {
        this.getItems({ urlParams: urlParams }).then(data => {

            // Total rows are set
            this.totalRows = data['hydra:totalItems']
            this.originalItemCount = this.totalRows
            // Pluck the array of observations off our response
            const items = data['hydra:member']

            // Provide the array of items to the callback
            _callback(items)
          })
      } catch (e) {
          console.log('An error occurred on getting data from API')
          _callback([])
      }

      // Busy state is unset
      this.isBusy = false

      // Must return null or undefined to signal b-table that callback is being used
      return null

    },

    providerCallback(items) {
      // This is the callback which refreshes the table with the newly fetched items
      this.observationsAll = []
      this.observationsAll =  (items && items.length > 0) ? items.slice() : []
      this.$refs.observationsAllTable.refresh()
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      if (this.totalRows !== filteredItems.length && filteredItems.length > this.perPage) {
        this.totalRows = filteredItems.length
        this.currentPage = 1
      }
    },

    firstImageRecord(records) {
      let bFirst = false
      return records.filter((rec) => {
        if (rec.recordType === 'image' && !bFirst) {
          bFirst = true
          return rec
        }
      })
    },

    /**
     * Button click handler
     * @function onClickViewObservation
     * @param event
     * @param item
     */
     /*
    onClickViewObservation(evt, item) {
      evt.preventDefault()
      this.$router.push({name: 'ObservationShow', params: { id: item.id}})
    },
    */

  },

}
