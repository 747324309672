<template>
<ObservationRecordOrganism v-if="record.recordType === 'organism'" :record="record"></ObservationRecordOrganism>

<div v-else class="row observation-record-item">
    <div class='col-12 pt-1' v-if='["textarea","text","number","dropdown","radio","datetime","location"].includes(record.recordType)'>
        <div class=" font-weight-bold text-muted" v-if="!record.description">
            {{ record.label }}
        </div>
        <div class="" v-if="record.description">
            <v-tooltip top>
                <span class='font-weight-bold text-muted' slot="activator">
                    {{ record.label }}
                    <i class="fa fa-question-circle ml-2 user-select-none" style="cursor: pointer"></i>
                </span>
                <span> {{ record.description }}</span>
            </v-tooltip>
        </div>
    </div>

    <div class="col-12 font-weight-bold pb-1 border-bottom" v-if='["textarea","text","number"].includes(record.recordType)'>
        {{ record.value }}
    </div>
    <div class="col-12 font-weight-bold pb-1 border-bottom" v-if='["dropdown","radio"].includes(record.recordType)'>
        {{ record.value || record.optionValue.value }}
    </div>
    <div class="col-12 font-weight-bold pb-1 border-bottom" v-if='["datetime"].includes(record.recordType)'>
        {{recordDate}}
    </div>
    <div class="col-12 py-2 font-weight-bold bg-light " v-if='record.recordType ==="image"'>

        <div class="text-secondary pb-1" v-if="!record.description">
            {{ record.label }}
        </div>
        <div class='text-dark pb-1' v-if="record.description">
            <v-tooltip top>
                <span slot="activator">
                    {{ record.label }}
                    <i class="fa fa-question-circle ml-2 user-select-none" style="cursor: pointer"></i>
                </span>
                <span> {{ record.description }}</span>
            </v-tooltip>
        </div>
        <b-img fluid thumbnail style='max-height: 400px;' :src="record.fileObject.path" left alt="`${record.label}. ${record.description}`" />

    </div>
    <div class="col-12 font-weight-bold pb-1 border-bottom" v-if='["location"].includes(record.recordType)'>
        <ObservationRecordItemLocation v-model="record.value"></ObservationRecordItemLocation>
    </div>
</div>
</template>

<script>
import ObservationRecordOrganism from "./ObservationRecordOrganism.vue";
import ObservationRecordItemLocation from "./ObservationRecordItemLocation.vue";
export default {
    name: "observation-record-item-view",
    components: {
        ObservationRecordOrganism,
        ObservationRecordItemLocation,
    },
    props: {
        record: {
            type: Object,
            required: true,
        },
    },
    computed: {
        recordDate() {
            return moment(this.record.value.split("+")[0]).format("MMMM DD, YYYY h:mm:ss a")
        }
    }
};
</script>
