<template>
  <div class="logout text-center mt-5" style="margin-bottom:600px;">
    <h1>Login Changes are Here</h1>
    <p>You have been logged out of CitSci</p>
    <p>CitSci now requires you to login with your registered Email.</p>
    <p>This is to streamline user accounts and to keep up with best practices.</p>
    <p>We apologize for the inconvenience caused.</p><br>
    <p>Read more <a href="https://blog.citsci.org/2023/09/19/change-to-the-login-experience/" target="_blank">here</a>.</p><br>

    <p v-if="userEmail"><u>Your Email Address:</u><br> {{ userEmail }}</p><br>
    <b-button :to="{name: 'Login'}" class="btn-primary mr-2" alt="Login" title="Login">Login Again</b-button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      userEmail: '',
    };
  },
  computed: {
    ...mapGetters({
      hasSession: 'user/profile/hasSession',
      sessionData: 'user/profile/sessionData',
    }),
  },
  methods: {
    ...mapActions({
      logout: "user/auth/logout",
      clearUserData: "user/profile/reset",
    }),
  },
  created() {
    if (this.hasSession) {
      this.userEmail = this.sessionData['email'];
    }
    this.clearUserData();
    this.logout();
  },
};
</script>

<style lang="scss" scoped>
</style>
