import fetch from '../../../../utils/fetch'

const initialState = {
    isLoading: false,
    error: null,

    likesTotal: null,
    locationsTotal: null,
    observationsRecordsTotal: null,
    observationsTotal: null,
    postsTotal: null,
    usersTotal: null,
}

const getters = {

}

const actions = {
    /**
     * @function getProject - retrieve from API project data
     * @param commit
     * @param rootGetters
     * @returns {Promise<T | never> | * | *}
     */
    get({ commit, rootGetters }) {
        commit(types.RESET)
        commit(types.SET_PROJECT_STATS_LOADING);
        return fetch('/projects/' + rootGetters['project/get/id'] + '/stats')
            .then(response => response.json())
            .then((data) => {
                commit(types.SET_PROJECT_STATS, data)
            })
            .catch((error) => {
                commit(types.SET_PROJECT_STATS_ERROR, error.message)
            })
    },
}

const types = {
    SET_PROJECT_STATS_LOADING: 'SET_PROJECT_STATS_LOADING',
    SET_PROJECT_STATS: 'SET_PROJECT_STATS',
    SET_PROJECT_STATS_ERROR: 'SET_PROJECT_STATS_ERROR',
    RESET_ERROR: 'RESET_ERROR',
    RESET: 'RESET',
}

const mutations = {
    [types.SET_PROJECT_STATS_LOADING](state) {
        state.isLoading = true;
        state.error = false;
    },
    [types.SET_PROJECT_STATS](state, stats) {
        state.likesTotal = stats.likesTotal
        state.locationsTotal = stats.locationsTotal
        state.observationsRecordsTotal = stats.observationsRecordsTotal
        state.observationsTotal = stats.observationsTotal
        state.postsTotal = stats.postsTotal
        state.usersTotal = stats.usersTotal

        state.isLoading = false;
    },
    [types.SET_PROJECT_STATS_ERROR](state, error) {
        state.error = error;
        state.isLoading = false;
    },
    [types.RESET_ERROR](state) {
        state.isLoading = false;
        state.error = false;
    },
    [types.RESET](state) {
        state = initialState
    },
}

export default {
    namespaced: true,
    state: initialState,
    getters: getters,
    actions: actions,
    mutations: mutations
}
