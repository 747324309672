// import vuex actions and getters
import { mapActions, mapGetters } from "vuex";

// import vue-bootstrap-datetimepicker library
import DatePicker from "vue-bootstrap-datetimepicker";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";

// import font-awesome 5 icons (vue-bootstrap-datetimepicker library requires it)
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";

// import vue components
import VueLoader from "../../../components/layout/Loader";
import VueGoogleMap from "../../../components/observation/location/LocationSelect";
import VueCustomModal from "../../../components/layout/CustomModal";
import VueTable from "../../../components/layout/Table";
import Record from "../../../components/datasheet/Record";

// use font-awesome 5 icons
$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle"
  }
});

const LOCATION_COORDINATES = "previewPanelLocationCoordinates";
const LOCATION_PICKLIST = "previewPanelLocationPicklist";
const LOCATION_BOTH = "previewPanelLocationBoth";


const DATASHEET_DETAILS = {
  records: []
};

const SPECIES_TABLE_COLUMNS = [
  {
    value: "scientificName",
    text: "Scientific Name",
    align: "left",
    sortable: true
  },
  {
    value: "canonicalName",
    text: "Common Name(s)",
    align: "left",
    sortable: true
  }
];

export default {
  components: {
    DatePicker,
    VueLoader,
    VueGoogleMap,
    VueCustomModal,
    VueTable,
    Record
  },

  props: {
    isSubmitButton: {
      type: Boolean,
      default: false
    }
  },

  provide() {
    return {
      parentValidator: this.$validator
    };
  },

  data() {
    return {
      observer: null,
      datetimePickerDate: moment().format("YYYY/MM/DD h:mm:ss A"),
      datetimePickerOptions: {
        allowInputToggle: true,
        format: "YYYY/MM/DD h:mm:ss A",
        showClear: true,
        showClose: true,
        useStrict: true,
        maxDate: moment().add(30, 'm').toDate()
      },
      organismRecordSelectedSpecies: null,
      selectedSpecies: [],
      speciesTableColumns: SPECIES_TABLE_COLUMNS,
      uploadLoadingStatus: false,
      locationType: LOCATION_COORDINATES,
      selectedLocation: "",
      addedLocation:{
        name: "",
        latitude: "",
        longitude: ""
      },
      isNewLocation: false,
      customErrorMessage: "",
      datasheetDetails: DATASHEET_DETAILS,
      projectMembers: [],
      observerLocations: []
    };
  },

  computed: {
    ...mapGetters({
      created: "datasheet/form/created",
      isLoading: "datasheet/form/isLoading",
      error: "datasheet/form/error",
      membershipMap: 'user/profile/membershipMap',
      membersAssignedLocationsIsLoading: "project/assigned_locations/isLoading",
      membersAssignedLocations: "project/assigned_locations/datasheetMemberAssignedLocations",
      membersAssignedLocationsError: "project/assigned_locations/error",
      createdLocation: "datasheet/form/createdLocation",
      createdLocationIsLoading: "datasheet/form/createdLocationIsLoading",
      retrievedDatasheet: "datasheet/creator/retrievedDatasheet",
      retrievedDatasheetIsLoading:
        "datasheet/creator/retrievedDatasheetIsLoading",
      retrievedDatasheetError: "datasheet/creator/retrievedDatasheetError",
      retrievedOrganisms: "datasheet/creator/retrievedOrganisms",
      retrievedOrganismsView: "datasheet/creator/retrievedOrganismsView",
      retrievedOrganismsError: "datasheet/creator/retrievedOrganismsError",
      retrievedOrganismsIsLoading:
        "datasheet/creator/retrievedOrganismsIsLoading",
      updatedDatasheetRecordDetailsError:
        "datasheet/creator/updatedDatasheetRecordDetailsError",
      checkedLocationExistence: "datasheet/creator/checkedLocationExistence",
      retrievedLocationsIsLoading:
        "datasheet/creator/retrievedLocationsIsLoading",
      checkLocationExistenceError:
        "datasheet/creator/checkLocationExistenceError",

      userId: "user/profile/id",
      displayName: "user/profile/displayName"
    }),

    // Property for the selected Observer's assigned locations
    observerLocationsVal: {
      get() {
        return this.observerLocations;
      },
      set(newVal) {
        this.observerLocations = newVal;
      }
    }
  },

  watch: {
    created(observation) {
      if (!observation) {
        return;
      }

      // TODO: pass parameter to notify user of auto-assigned location for subsequent observations
      if (this.locationType !== LOCATION_PICKLIST && this.datasheetDetails.locationAutoAssign && this.isNewLocation) {
        // Pass new location + auto assign parameter to view observation route
        this.$router.push({
          name: "ObservationShow",
          params: {id: observation.id, isNewAutoAssign: true}
        });
      } else {
        this.$router.push({
          name: "ObservationShow",
          params: {id: observation.id}
        });
      }
    },

    retrievedDatasheet(datasheet) {
      if (!datasheet) {
        return;
      } else if (datasheet.status === "draft") {
        this.customErrorMessage =
          "This datasheet must be published to create observations.";
      }

      this.datasheetDetails = {...datasheet,
        records: datasheet.records.map((record)=>{
          return {
            ...record,
            records: record.records.map((subRecord)=>{
              return {
                ...subRecord
              }
            })
          }
        })
      };
      // console.log(this.datasheetDetails);

      // Locations picklist is handled and location type is set
      switch (this.datasheetDetails.locationFormat) {
        case 0:
          this.locationType = LOCATION_COORDINATES;
          break;
        case 1:
          this.locationType = LOCATION_PICKLIST;
          break;
        case 2:
          this.locationType = LOCATION_BOTH;
          break;
      }
      this.observerLocationsVal = this.datasheetDetails.locations;
      // Fetch this user's assigned locations, if any
      if (this.datasheetDetails.locationFormat > 0) {
        // Datasheet locations are set by default
        this.observerLocationsVal = this.datasheetDetails.locations
        this.getMemberAssignedLocations({id: this.datasheetDetails.id})
      }
      const oldDateTimeFormat = this.datetimePickerOptions.format
      switch (this.datasheetDetails.dateFormat) {
        case "date":
          this.datetimePickerOptions.format = "YYYY/MM/DD";
          break;
        case "date_hour_minutes":
          this.datetimePickerOptions.format = "YYYY/MM/DD hh:mm A";
          break;
        case "datetime":
          this.datetimePickerOptions.format = "YYYY/MM/DD hh:mm:ss A";
          break;
      }
      this.datetimePickerDate = moment(this.datetimePickerDate, oldDateTimeFormat).format(
        this.datetimePickerOptions.format
      );
      if (
        this.datasheetDetails.records &&
        this.datasheetDetails.records.length > 0
      ) {
        this.datasheetDetails.records.forEach(record => {
          this.setUsefulRecordFields(record);

          if (record.records && record.records.length > 0) {
            record.records.forEach(subRecord => {
              this.setUsefulRecordFields(subRecord);
            });
          }
        });
      }
    },

    membersAssignedLocations(data) {
      // Assigned locations for the datasheet and member
      if (!data || data.length === 0) {
        return;
      }
      const assignedLocations = data.map((location)=>{
        // Datasheet locations have a locationId property not id or @id
        // The Vue-Google-Map component is expecting locationId when making observations
        // So, we add the locationId property to the assigned locations
        return {
          ...location,
          locationId: location.id
        }
      })
      if(assignedLocations.length > 0){
        this.observerLocationsVal = assignedLocations;
      }
    },

    createdLocation(location) {
      if (!location) {
        return;
      }

      this.addedLocation = location;
      this.onCreateObservation();
    },

    checkedLocationExistence(location) {
      if (!location) {
        return;
      }

      const locationData = location["hydra:member"];

      // check if the location exists in the Locations table
      if (locationData.length > 0) {
        this.addedLocation = locationData[0];
        this.onCreateObservation();
      } else {
        this.createLocation({
          project: '/projects/' + this.datasheetDetails.project.id,
          name: this.addedLocation.name,
          latitude: parseFloat(this.addedLocation.latitude),
          longitude: parseFloat(this.addedLocation.longitude)
        });
      }
    }
  },

  created() {
    this.datasheetId = this.$route.params.id;
    this.observer = {
      displayName: this.displayName,
      userId: this.userId
    };
    this.projectMembers = [this.observer];
    this.retrieveDatasheet(`/datasheets/${this.datasheetId}`);
  },

  mounted() {
    this.toggleBodyId("addId", "custom-vuetify-style");
  },

  destroyed() {
    this.toggleBodyId("removeId", "custom-vuetify-style");
  },

  methods: {
    ...mapActions({
      create: "datasheet/form/create",
      createLocation: "datasheet/form/createLocation",
      retrieveDatasheet: "datasheet/creator/retrieveDatasheet",
      retrieveMembersAssignedLocations: "datasheet/form/retrieveMembersAssignedLocations",
      getMemberAssignedLocations: "project/assigned_locations/getDatasheetMemberAssignedLocations",
      retrieveOrganisms: "datasheet/creator/retrieveOrganisms",
      checkLocationExistence: "datasheet/creator/checkLocationExistence"
    }),

    openOrganismsModal() {
      this.$modal.show("add-organism");
    },

    onSubmitSelectedSpecies() {
      this.organismRecordSelectedSpecies = this.selectedSpecies[0];

      // hide the modal
      this.$modal.hide("add-organism");
    },

    setUsefulRecordFields(record) {
      record.marker = record["@id"];
      record.optionValue = null;
      record.fileObject = [];
      record.requiredField = false;
      record.validatorRules = "";
      record.files = [];
      record.isUploading = false;
      record.initialDefaultValue = record.defaultValue
        ? record.defaultValue
        : null;
      record.selectedSpecies =
        record.organisms && record.organisms.length === 1
          ? record.organisms[0]
          : null;

      // Set the validation rules for the record
      if (record && record.validators && record.validators.length > 0) {
        record.validators.forEach(validator => {
          if (validator.validatorType && validator.value) {
            if (
              record.validatorRules &&
              record.validatorRules !== "" &&
              validator.validatorType !== "isRequired"
            ) {
              record.validatorRules += "|";
            }

            if (validator.validatorType === "isRequired" && validator.value) {
              record.validatorRules = "required|" + record.validatorRules;
              record.requiredField = true;
            } else if (
              validator.validatorType === "isInteger" &&
              validator.value
            ) {
              record.validatorRules = "integer|" + record.validatorRules;
            } else {
              // Vee-Validate needs min_value and max_value for numeric field validation
              if (record.recordType === "number") {
                record.validatorRules +=
                  validator.validatorType + "_value:" + validator.value;
              } else {
                record.validatorRules +=
                  validator.validatorType + ":" + validator.value;
              }
            }
          }
        });
      }

      // For number fields, set vee-validate as decimal
      if (record.validatorRules === "" && record.recordType === "number") {
        record.validatorRules = "decimal"
      }

      // Default option value, if set, is assigned for radio and dropdown
      if (record && record.optionsSet && record.optionsSet.optionsValues) {
        // Use the newly added default value from OptionsSet instead of searching through Options Values for the default values
        // const defaultSelectedObject = this.$_.find(record.optionsSet.optionsValues, option => {
        //   return option.isDefault
        // })

        const defaultSelectedObject = record.optionsSet.defaultOptionValue;
        if (defaultSelectedObject) {
          if (record.recordType === "radio") {
            if (defaultSelectedObject && defaultSelectedObject["@id"]) {
              record.optionValue = defaultSelectedObject["@id"];
            }
          } else {
            record.optionValue = defaultSelectedObject;
          }
        }
      }
    },

    submit() {
      // On button pressed run validation
      if (this.datasheetDetails.status === "draft") {
        this.customErrorMessage =
          "This datasheet must be published to create observations.";

        $("html,body")
          .stop()
          .animate(
            {
              scrollTop: 0
            },
            "slow",
            "swing"
          );

        return;
      } else {
        this.customErrorMessage = "";
      }

      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.selectedLocation) {
            this.customErrorMessage = "";
            this.onCreateObservation();
          } else if (
            this.addedLocation.name &&
            this.addedLocation.latitude &&
            this.addedLocation.longitude
          ) {
            this.customErrorMessage = "";

            // check location existence
            this.addedLocation.projectId = this.datasheetDetails.project.id
            this.checkLocationExistence(this.addedLocation);
          } else {
            this.customErrorMessage = "A location must be added.";

            $("html,body")
              .stop()
              .animate(
                {
                  scrollTop: 0
                },
                "slow",
                "swing"
              );
          }
        } else {
          $("html,body")
            .stop()
            .animate(
              {
                scrollTop: 0
              },
              "slow",
              "swing"
            );
        }
      });
    },

    onCreateObservation() {
      // Method to parse and create the new observation for submission
      const observation = {
        datasheet: this.datasheetDetails["@id"],
        observedAt: moment(
          this.datetimePickerDate,
          this.datetimePickerOptions.format
        ).format("YYYY-MM-DDTHH:mm:ss"),
        observers: [
          {
            user: "/users/" + this.observer.userId
          }
        ],
        records: []
      };

      // Location is set
      observation.location = null;
      if (this.addedLocation && this.addedLocation["@id"]) {
        // New location
        observation.location = this.addedLocation["@id"];
        this.isNewLocation = true;
        observation.isNewLocation = this.isNewLocation;
      } else if (this.selectedLocation) {
        // Existing location
        observation.location = "/areas/" + this.selectedLocation;
      }

      const datasheetRecords = this.datasheetDetails.records;

      // Each datasheet record is processed
      datasheetRecords.forEach(record => {
        let selectedOrganism = null;
        const subRecords = [];

        // Handle organism records
        if (record.organisms) {
          if (record.organisms.length > 0 || record.allowAnyOrganism) {
            if (record.selectedSpecies) {
              this.customErrorMessage = "";

              selectedOrganism = {
                organism: "/organisms/" + record.selectedSpecies.organismId,
                customName: record.selectedSpecies.customName
              };
            } else if (this.organismRecordSelectedSpecies) {
              this.customErrorMessage = "";

              selectedOrganism = {
                organism: this.organismRecordSelectedSpecies["@id"],
                customName: null
              };
            } else {
              // this.customErrorMessage =
              //   "Each organism list must have an organism selected";
            }
          }

          // Child organism records are handled here
          if (record.records.length > 0) {
            record.records.forEach(record => {
              let fieldValue = null;
              if (record.recordType === "datetime" && record.defaultValue) {
                fieldValue = moment(
                  record.defaultValue,
                  "YYYY/MM/DD h:mm:ss A"
                ).format("YYYY-MM-DDTHH:mm:ss");
              } else if (record.recordType !== "datetime") {
                fieldValue = record.defaultValue;
              }

              const fileObject = this.$_.find(
                record.fileObject.reverse(),
                fileObj => {
                  return (
                    fileObj.recordId == record.id &&
                    record.files &&
                    record.files.length > 0
                  );
                }
              );

              const subRecord = {
                datasheetRecord: record["@id"],
                description: record.description,
                label: record.label,
                location: record.location,
                orderNumber: record.orderNumber,
                recordType: record.recordType,
                fileObject: fileObject ? fileObject.fileId : null,
                value: fieldValue ? fieldValue : null,
                optionValue: record.optionValue
              };

              // Final validation check before pushing the observation sub record to the array
              if (
                subRecord.value !== null ||
                subRecord.optionValue !== null ||
                subRecord.fileObject !== null
              ) {
                subRecords.push(subRecord);
              }
            });
          }
        }

        let fieldValue = null;
        if (record.recordType === "datetime" && record.defaultValue) {
          fieldValue = moment(
            record.defaultValue,
            "YYYY/MM/DD h:mm:ss A"
          ).format("YYYY-MM-DDTHH:mm:ss");
        } else if (record.recordType !== "datetime") {
          fieldValue = record.defaultValue;
        }

        const fileObject = this.$_.find(
          record.fileObject.reverse(),
          fileObj => {
            return (
              fileObj.recordId == record.id &&
              record.files &&
              record.files.length > 0
            );
          }
        );

        const observationRecord = {
          datasheetRecord: record["@id"],
          description: record.description,
          label: record.label,
          location: record.location,
          orderNumber: record.orderNumber,
          recordType: record.recordType,
          records: subRecords,
          fileObject: fileObject ? fileObject.fileId : null,
          value: fieldValue ? fieldValue : null,
          optionValue: record.optionValue
        };

        if (record.recordType === "organism") {
          if(selectedOrganism){
            observationRecord.organism = selectedOrganism;
          } else if (observationRecord.records.length > 0) {
            this.customErrorMessage =
              "Please select an organism. You have an organism that contains records, but you did not identify which organism it is for.";
          }
        }

        // Final validation check before pushing the observation to the array
        if (
          observationRecord.value !== null ||
          observationRecord.optionValue !== null ||
          observationRecord.records.length > 0 ||
          observationRecord.fileObject !== null
        ) {
          observation.records.push(observationRecord);
        }
      });

      // const str = JSON.stringify(observation, null, 2)
      // console.log("observation request", str)

      if (!this.customErrorMessage) {
        this.create(observation);
      }
    },


    toggleBodyId(addRemoveId, idName) {
      const el = document.body;

      if (addRemoveId === "addId") {
        el.setAttribute("id", idName);
      } else {
        el.removeAttribute("id");
      }
    },

    onDuplicateDatasheetRecord(record) {
      const recordToDuplicate = {
        "@id": record["@id"],
        marker: moment()
          .valueOf()
          .toString(),
        files: [],
        allowReplica: false,
        optionValue: record.optionValue,
        fileObject: [],
        id: record.id,
        initialDefaultValue: record.initialDefaultValue,
        isReady: record.isReady,
        location: record.location,
        orderNumber: record.orderNumber,
        parentRecord: record.parentRecord,
        recordType: record.recordType,
        requiredField: record.requiredField,
        selectedSpecies: record.selectedSpecies,
        appearanceOptions: record.appearanceOptions,
        defaultValue: record.defaultValue,
        optionsSet: record.optionsSet,
        updatedAt: record.updatedAt,
        validators: []
      };

      if (record.recordType === "organism") {
        recordToDuplicate.allowAnyOrganism = record.allowAnyOrganism;
        recordToDuplicate.organisms = [];
        recordToDuplicate.records = [];

        record.organisms.forEach(organism => {
          recordToDuplicate.organisms.push({
            "@id": organism["@id"],
            canonicalName: organism.canonicalName,
            customName: organism.customName,
            organismId: organism.organismId,
            rank: organism.rank,
            scientificName: organism.scientificName
          });
        });

        record.records.forEach(r => {
          const rToDuplicate = {
            "@id": r["@id"],
            marker: moment()
              .valueOf()
              .toString(),
            files: [],
            allowReplica: false,
            optionValue: r.optionValue,
            fileObject: [],
            id: r.id,
            initialDefaultValue: r.initialDefaultValue,
            isReady: r.isReady,
            location: r.location,
            orderNumber: r.orderNumber,
            parentRecord: r.parentRecord,
            recordType: r.recordType,
            requiredField: r.requiredField,
            selectedSpecies: r.selectedSpecies,
            appearanceOptions: r.appearanceOptions,
            defaultValue: r.defaultValue,
            optionsSet: r.optionsSet,
            validators: []
          };

          rToDuplicate.label = r.label;
          rToDuplicate.description = r.description;

          recordToDuplicate.records.push(rToDuplicate);
        });
      } else {
        recordToDuplicate.label = record.label;
        recordToDuplicate.description = record.description;
      }

      this.datasheetDetails.records.splice(
        record.orderNumber,
        0,
        recordToDuplicate
      );
      this.adjustRecordsOrderNumber(this.datasheetDetails.records);
    },

    adjustRecordsOrderNumber(records) {
      let recordOrderNumber = 1;

      records.forEach(record => {
        if (record.orderNumber) {
          record.orderNumber = recordOrderNumber++;
        }

        if (record.records && record.records.length > 0) {
          let subrecordOrderNumber = 1;

          record.records.forEach(subrecord => {
            subrecord.orderNumber = subrecordOrderNumber++;
          });
        }
      });
    }
  }
};
