import {mapGetters} from 'vuex'

export default {
  props: {
    headerData: {
      type: Object,
      default: () => ({
        backgroundPath: '/assets/images/header/default-project-header-banner.png',
        picturePath: '',
        headerTitle: 'CitSci 2.0',
        hasAccess: false,
        subHeaderTitle: '',
        showHomepageButtons: false
      })
    },
    isProjectProfile: {
      type: Boolean,
      default: true
    },
    isHomepage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      headerCarousel: [
        {
          id: 1,
          headerTitle: 'Helping you do great science',
          headerSubtitle: 'Create projects, build datasheets, collect data, and view results in real-time.',
          imagePath: '/assets/images/header/Home_Image_1.jpg'
        },
        {
          id: 2,
          headerTitle: 'Create your own projects',
          headerSubtitle: 'Make projects public, private, local, or global.',
          imagePath: '/assets/images/header/Home_Image_2.jpg'
        },
        {
          id: 3,
          headerTitle: 'Manage volunteers',
          headerSubtitle: 'Approve trained members, invite new members, email members, and create notifications.',
          imagePath: '/assets/images/header/Home_Image_3.jpg'
        },
        {
          id: 4,
          headerTitle: 'Build custom datasheets',
          headerSubtitle: 'Add species, picklists, site characteristics, locations, and more.',
          imagePath: '/assets/images/header/Home_Image_4.jpg'
        },
        {
          id: 5,
          headerTitle: 'Analyze your own data',
          headerSubtitle: 'Visualize trends. Make comparisons between locations. Assess relationships between variables.',
          imagePath: '/assets/images/header/Home_Image_5.jpg'
        },
        {
          id: 6,
          headerTitle: 'Get participant feedback',
          headerSubtitle: 'Create pre- and post-data collection surveys. Evaluate project effectiveness.',
          imagePath: '/assets/images/header/Home_Image_6.jpg'
        },
      ],
      fontSize: '60'
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'user/auth/isAuthenticated'
    }),

  },

  watch: {
    isHomepage(newIsHomepageValue) {
      this.isHomepage = newIsHomepageValue
    },
    headerData(newHeaderData) {
      if (newHeaderData.headerTitle.length <= 30) {
        this.fontSize = '60'
      } else if (newHeaderData.headerTitle.length > 30 && newHeaderData.headerTitle.length <= 60) {
        this.fontSize = '40'
      } else if (newHeaderData.headerTitle.length > 60 && newHeaderData.headerTitle.length <= 100) {
        this.fontSize = '30'
      }
    }
  }
}
