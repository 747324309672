<template>
  <div class="container">
    <h2>
      Delete My Account
    </h2>
    <div class="row justify-content-center">
      <div class="col-9 col-md-auto">
        <DeleteAccount :show-profile-link=false></DeleteAccount>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-9 col-md-auto">
        <h4>Instructions</h4>
        <p>
          <ol>
            <li>Press the ‘Request Delete’ button. This will take you to a form to submit a support request to delete
              the account.
            </li>
            <li>Specify what you want deleted:</li>
            <ul>
              <li>Account only</li>
              <li>Account and scientific data (including observations and photos submitted for scientific research)</li>
            </ul>
          </ol>

          The account deletion process is manual. One of our team members will email you to confirm your request to have
          your account deleted. The account deletion process can take two weeks or more.
        </p>
      </div>
    </div>
    <div class="row d-flex justify-content-center mb-4">
      <div class="col-md-2">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLScRTCF9EGQ1QMlr_yo75HIgrOYCsFQwBkRztUP_neuLhwuXnA/viewform"
           class="btn btn-danger btn-block">
          Request Delete
        </a>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from 'vuex';
import DeleteAccount from '../terms/DeleteAccount.vue'

export default {
  components: {
    DeleteAccount
  },
  computed: {
    ...mapGetters({
      user: "user/profile/sessionData",
    })
  }

}
</script>

<style lang="">

</style>
