<template lang="">
<span>
    <b-img v-if='approveContacts' class='mr-2' src="/assets/images/closed-access.png" width="50px" height="50px" alt="private project" title="private project"></b-img>
    <b-img v-else class='mr-2' src="/assets/images/open-access.png" width="50px" height="50px" alt="public project" title="public project"></b-img>
</span>
</template>

<script>
export default {
    props: {
        approveContacts: {
            required: true,
            type: Boolean,
        }
    }
}
</script>

<style lang="">
    
</style>
