import Vue from 'vue'

export const isAuthenticated = state => {
    if (
    state.error !== '' ||
    state.isLoading !== false ||
    state.resetPassword !== false ||
    state.violations !== null ||
    state.tokenBearer === null 
    ) {
        return null

    }
    return true
}

export const resetPassword = state => state.resetPassword
export const error = state => state.error
export const isLoading = state => state.isLoading
export const violations = state => state.violations
export const tokenBearer = state => state.tokenBearer
export const tokenBearerExpires = state => {
    return (state.tokenBearer) ? 
        Vue.$jwt.decode(state.tokenBearer).exp :
        null 
}
export const tokenRefresh = state => state.tokenRefresh
export const tokenRefreshExpires = state => {
    return (state.tokenRefresh) ? 
        Vue.$jwt.decode(state.tokenRefresh).exp :
         null 
};