<template>
  <div v-if="!isLoading" class="card-body">
    <h2 class="m-0">Results</h2>
    <p class="m-0">Coming Soon...</p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueLoader from "../../../layout/Loader.vue";
export default {
  name: "OrganizationResults",
  components: {
    VueLoader
  },
  data() {},
  created() {},
  computed: {
    ...mapGetters({
      organizationId: "organzation/get/id",
      isLoading: "organization/get/isLoading",
    })
  },
  methods: {
    ...mapActions({})
  }
};
</script>
