import * as actions from './actions'
import * as getters from './getters'
import mutations from './mutations'
import {initialState} from './mutations'


export default {
  namespaced: true,
  state: {...initialState},
  actions,
  getters,
  mutations
}
