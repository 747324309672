<template lang="html">
<div>
    <div class="text-right mb-3">
        <Likes></Likes>
    </div>
    <div class="text-right">
        <ApproveContacts :approveContacts="project.approveContacts"></ApproveContacts>
        <IsPrivate :isPrivate="project.isPrivate"></IsPrivate>
    </div>
</div>
</template>

<script lang="js">
import {
    mapActions,
    mapGetters
} from 'vuex'

import IsPrivate from './IsPrivate.vue'
import ApproveContacts from './ApproveContacts'
import Likes from './Likes'

export default {
    components: {
        IsPrivate,
        ApproveContacts,
        Likes,
    },
    computed: {
        ...mapGetters({
            project: 'project/get/project',
        }),
    }
}
</script>

<style lang="scss" scoped>

</style>
