import { createNamespacedHelpers } from 'vuex'

const { mapGetters, mapActions } = createNamespacedHelpers('project/manageMembers')

export default {
  data () {
    return {
      projectId: null,
      showInvitePeople: false,
      showMemberRoles: false,
      showEmailMembers: false,
      showAssignMembers: false,
      showUnassignMembers: false,
      invitePeople: {
        firstname: '',
        lastname: '',
        email: '',
        notes: ''
      },
      toOptions: [
        { value: '0', text: 'All project members' },
        { value: '1', text: 'Only project manager(s)' },
        { value: '2', text: 'All managers, authorities, reviewers' }
      ],
      emailMembers: {
        to: null,
        content: ''
      },
      userRequestItems: [],
      userRequestTableFields: [
        'user',
        'email',
        'requested_role',
        'options'
      ],
      memberRolesItems: [],
      memberRolesTableFields: [
        'member',
        'email',
        {
          key: 'role',
          label: 'Role(s)'
        },
        'options'
      ]
    }
  },

  computed: mapGetters([
    // invite people form
    'invitePeopleError',
    'invitePeopleIsLoading',
    'invitePeopleCreated',
    'invitePeopleViolations',
    // email members form
    'emailMembersError',
    'emailMembersIsLoading',
    'emailMembersCreated',
    'emailMembersViolations'
  ]),

  watch: {
    // eslint-disable-next-line object-shorthand,func-names
    invitePeopleCreated: function (created) {
      if (!created) {
        return
      }

      this.resetInvitePeople()
      this.invitePeople.firstname = ''
      this.invitePeople.lastname = ''
      this.invitePeople.email = ''
      this.invitePeople.notes = ''
    },

    // eslint-disable-next-line object-shorthand,func-names
    emailMembersCreated: function (created) {
      if (!created) {
        return
      }

      this.resetEmailMembers()
      this.emailMembers.to = null
      this.emailMembers.content = ''
    }
  },

  methods: {
    ...mapActions([
      'manageProjectMembersInvitePeople',
      'resetInvitePeople',
      'manageProjectMembersEmailMembers'
    ]),

    onInvitePeople (scope) {
      // On button pressed run validation
      this.$validator.validateAll(scope)
      .then(result => {
        if (result) {
          this.manageProjectMembersInvitePeople(this.invitePeople)
        }
      })
    },

    onEmailMembers (scope) {
      // On button pressed run validation
      this.$validator.validateAll(scope)
      .then(result => {
        if (result) {
          this.manageProjectMembersEmailMembers(this.emailMembers)
        }
      })
    }
  },

  created () {
    //this.projectId = this.$route.params.id
    this.projectId = window.ClickedProjectId;
  }
}
