<template lang="html">
<div v-if="!isLoading" class="row text-center text-light no-gutters">
    <div class="col-6 col-sm-3">
        <div v-if="usersTotal" class="stat-box bg-dark py-2 mt-2 mx-1">
            <div class="stat-value">
                {{usersTotal}}
            </div>
            <div class="stat-label">
                <i class="mr-2 fa fa-user"></i>
                <span class="">
                    Members
                </span>
            </div>
        </div>
    </div>
    <div class="col-6 col-sm-3">
        <div v-if="observationsTotal" class="stat-box bg-dark py-2 mt-2 mx-1">
            <div class="stat-value">
                {{observationsTotal}}
            </div>
            <div class="stat-label">
                <i class="mr-2 fa fa-binoculars"></i>
                <span class="">
                    Observations
                </span>
            </div>
        </div>
    </div>
    <div class="col-6 col-sm-3">
        <div v-if="locationsTotal" class="stat-box bg-dark py-2 mt-2 mx-1">
            <div class="stat-value">
                {{locationsTotal}}
            </div>
            <div class="stat-label">
                <i class="mr-2 fa fa-map-marker-alt"></i>
                <span class="">
                    Locations
                </span>
            </div>
        </div>
    </div>
    <div class="col-6 col-sm-3">
        <div v-if="observationsRecordsTotal" class="stat-box bg-dark py-2 mt-2 mx-1">
            <div class="stat-value">
                {{observationsRecordsTotal}}
            </div>
            <div class="stat-label">
                <i class="mr-2 fa fa-pencil"></i>
                <span class="">
                    Measurements
                </span>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters,
    mapState,
} from 'vuex'

export default {
    created() {
      if (!this.projectVuex || (this.projectVuex.urlField !== this.$route.params.id)) {
        this.getProjectStats()
      }
    },
    computed: {
        ...mapGetters({
          projectVuex: "project/get/project",
        }),
        ...mapState({
            isLoading: state => state.project.get.stats.isLoading,
            locationsTotal: state => state.project.get.stats.locationsTotal,
            observationsRecordsTotal: state => state.project.get.stats.observationsRecordsTotal,
            observationsTotal: state => state.project.get.stats.observationsTotal,
            usersTotal: state => state.project.get.stats.usersTotal,
        }),
    },
    methods: {
        ...mapActions({
            getProjectStats: 'project/get/stats/get',
        })
    }
}
</script>

<style lang="">
.stat-value {
    letter-spacing: -2px;
    height: 42px;
    line-height: 48px;
    font-size: 30px;
}

.stat-label {
    letter-spacing: -0.5px;
    color: #b0bb81;
    margin-top: -7px;
    font-size: 16px;
}
</style>
