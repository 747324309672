export const OBSERVATION_SHOW_RESET = 'OBSERVATION_SHOW_RESET'
export const OBSERVATION_SHOW_SET_ERROR = 'OBSERVATION_SHOW_SET_ERROR'
export const OBSERVATION_SHOW_SET_RETRIEVED = 'OBSERVATION_SHOW_SET_RETRIEVED'
export const OBSERVATION_SHOW_SET_UPDATED = 'OBSERVATION_SHOW_SET_UPDATED'
export const OBSERVATION_RECORD_SHOW_SET_UPDATED = 'OBSERVATION_RECORD_SHOW_SET_UPDATED'
export const OBSERVATION_RECORD_SHOW_CREATED = 'OBSERVATION_RECORD_SHOW_CREATED'
export const OBSERVATION_SHOW_TOGGLE_LOADING = 'OBSERVATION_SHOW_TOGGLE_LOADING'
export const OBSERVATION_SHOW_DONE = 'OBSERVATION_SHOW_DONE'
export const OBSERVATION_SHOW_SET_DELETED = 'OBSERVATION_SHOW_SET_DELETED'
export const OBSERVATION_SHOW_SET_VIOLATIONS = 'OBSERVATION_SHOW_SET_VIOLATIONS'
export const OBSERVATION_UPDATE_SET_VIOLATIONS = 'OBSERVATION_UPDATE_SET_VIOLATIONS'
