<template>
  <b-modal id="view-scorecard"
           title="View Indicator"
           ref="viewScorecard"
           size="md"
           button-size="sm"
           hide-header-close
           no-close-on-backdrop
           no-close-on-esc
           no-stacking
           centered>

    <div v-if="scorecardData" class="container mb-2 p-0">
      <!-- Title, Description and Resource -->
      <b-card bg-variant="light">
        <b-form-group
          id="score-title"
          label-cols-lg="2"
          content-cols-lg="8"
          label="Title:"
          label-class="font-weight-bold pt-0"
          label-for="input-title">
          <label>{{ scorecardData.title }}</label>
        </b-form-group>
        <b-form-group
          id="score-details"
          label-cols-lg="2"
          content-cols-lg="8"
          label="Details:"
          label-class="font-weight-bold pt-0"
          label-for="input-details">
          <div class="show-newline">
            <v-linkify :html="scorecardData.desc"/>
          </div>
        </b-form-group>
      </b-card>
      <!-- Indicator Selection -->
      <b-card bg-variant="light" class="mt-1 no-gutters">
        <b-form-group class="mb-0"
                      label-cols-lg="3"
                      content-cols-lg="12"
                      label="Indicator:"
                      label-class="font-weight-bold m-0 p-0 label_underline">
          <b-form-group id="score-project"
                        label-cols-lg="2"
                        content-cols-lg="10"
                        label="Project:"
                        label-class="font-weight-bold m-0 p-0"
                        label-for="input-project"
                        class="mt-2">
            <label>{{ scorecardData.project }}</label>
          </b-form-group>
          <b-form-group id="score-datasheet"
                        label-cols-lg="2"
                        content-cols-lg="10"
                        label="Datasheet:"
                        label-class="font-weight-bold m-0 p-0"
                        label-for="input-datasheet">
            <label>{{ scorecardData.datasheet }}</label>
          </b-form-group>
          <b-form-group id="score-record"
                        label-cols-lg="2"
                        content-cols-lg="10"
                        label="Record:"
                        label-class="font-weight-bold m-0 p-0"
                        label-for="input-record">
            <label>{{ scorecardData.record }} {{ scorecardData.dataType === 'number' ? ' (Numerical)' : ' (Categorical)' }}</label>
          </b-form-group>
        </b-form-group>
      </b-card>
      <div class="row mt-4">
        <div class="col-12">
          <Donut class="justify-content-center mb-1"
                 :score-id=null
                 :score-data="scorecardData.data"></Donut>
        </div>
      </div>
      <b-row class="text-center justify-content-center" align-v="center">
        <div class="col">
          <h5 class="mt-1" v-if="!scorecardData.error">{{ scorecardData.label + ': ' + scorecardData.value }}</h5>
          <h5 class="mt-1" v-else>{{ scorecardData.error }}</h5>
        </div>
      </b-row>
    </div>
    <template slot="modal-footer">
      <b-btn variant="secondary" @click="() => { this.$refs.viewScorecard.hide() }">Close
      </b-btn>
    </template>
  </b-modal>
</template>

<script>
import Donut from "./Donut.vue";

export default {
  name: "ViewIndicatorModal",
  components: {
    Donut
  },
  props: {
    scorecardData: {
      type: Object | null,
      required: true,
    },
  },
  data() {
    return {
      linkifyOptions: { }
    }
  },
}
</script>

<style lang="scss">

.label_underline {
  text-decoration: underline;
}

</style>
