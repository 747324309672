import * as types from './mutation_types'

export default {
  [types.USER_AUTH_SET_BEARER] (state, tokenBearer) {
    window.localStorage.setItem('tokenBearer', tokenBearer);
    Object.assign(state, { tokenBearer })
  },

  [types.USER_AUTH_SET_REFRESH] (state, tokenRefresh) {
    window.localStorage.setItem('tokenRefresh', tokenRefresh)
    Object.assign(state, { tokenRefresh })
  },

  [types.USER_AUTH_LOGOUT] (state) {
    localStorage.clear()
    Object.assign(state, {
      resetPassword: false,
      error: '',
      isLoading: false,
      violations: null,
      tokenBearer: null,
      tokenRefresh: null
    })
  },

  [types.USER_AUTH_LOADING] (state) {
    state = {
      ...state,
      isLoading: true
    }
  },
  [types.USER_AUTH_LOADED] (state) {
    state = {
      ...state,
      isLoading: false
    }
  },

  [types.USER_AUTH_SET_ERROR] (state, error) {
    Object.assign(state, { error })
  },
  [types.USER_AUTH_RESET_PASSWORD_ERROR] (state, resetPassword) {
    Object.assign(state, { resetPassword })
  },
  [types.USER_AUTH_SET_VIOLATIONS] (state, violations) {
    Object.assign(state, { violations })
  },

  
}
