import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    /**
     * @var {Number} currentPage
     */
    currentPage: {
      type: Number,
      default: () => {
        return 1
      }
    },

    /**
     * @var {Number} totalRows
     */
    totalRows: {
      type: Number,
      default: () => {
        return 0
      }
    },

    /**
     * @var {Number} projectState
     */
    projectState: {
      type: Number,
      default: () => {
        return null
      }
    },

    /**
     * @var {Number} perPage
     */
    perPage: {
      type: Number,
      default: () => {
        return 100
      }
    },

    /**
     * @var {Array} pageOptions
     */
    pageOptions: {
      type: Array,
      default: () => {
        return [ 10, 25, 50, 100, 200, 250 ]
      }
    },

    /**
     * @var {String} sortBy
     */
    sortBy: {
      type: String,
      default: () => {
        return null
      }
    },

    /**
     * @var {Boolean} sortDesc
     */
    sortDesc: {
      type: Boolean,
      default: () => {
        return false
      }
    },

    /**
     * @var {String} sortDirection
     */
    sortDirection: {
      type: String,
      default: () => {
        return 'asc'
      }
    },

    /**
     * @var {Object, String} filter
     */
    filter: {
      type: [Object, String],
      default: () => {}
    },

    /**
     * @var {Function} filterFunction
     */
    filterFunction: {
      type: Function
    },

    /**
     * @var {String} parentFilter
     */
    parentFilter: {
      type: String,
      default: () => {
        return null
      }
    },

    /**
     * @var {Boolean} filteredItemsNote
     */
    filteredItemsNote: {
      type: Boolean,
      default: () => {
        return false
      }
    },

    /**
     * @var {Array} items
     */
    items: {
      type: Array,
      default: () => {}
    },

    /**
     * @var {String} tableScope
     */
    tableScope: {
      type: String,
      default: () => {
        return ''
      }
    },

    /**
     * @var {Array} fields
     */
    fields: {
      type: Array,
      default: () => {}
    },

    /**
     * @var {String} searchTermStr
     */
    searchTermStr: {
      type: String,
      default: () => {return null}
    },

    /**
     * @var {String} emptyText
     */
    emptyText: {
      type: String,
      default: () => {return "There are no records to show"}
    },

    /**
     * @var {Array} managers
     */
    managers: {
      type: Array,
      default: () => {}
    },

    /**
     * @var {Object} createLink
     */
    createLink: {
      type: Object,
      default: () => {
        return {
          to: {
            name: ''
          },
          linkName: ''
        }
      }
    },

    /**
     * @var {Function} onRowClicked
     */
    onRowClicked: {
      type: Function,
      default: () => {}
    },

    /**
     * @var {Function} callback
     */
    callback: {
      type: Function,
      default: undefined
    },

    /**
     * @var {Boolean} showTotalEntriesInfo
     */
    showTotalEntriesInfo: {
      type: Boolean,
      default: () => {
        return true
      }
    },

    /**
     * @var {String} tableId
     */
    tableId: {
      type: String,
      default: () => {
        let date = new Date()
        let dateTime = date.getTime().toString()

        return (dateTime.toString() + '-table')
      }
    },

    /**
     * @var {Array} userLocations
     */
    userLocations: {
      type: Array,
      default: () => {
        return []
      }
    },

    /**
     * @var {Array} assignedLocationsPassed
     */
    assignedLocationsPassed: {
      type: Array,
      default: () => {
        return []
      }
    },

    /**
     * @var {Boolean} executeCallback
     */
    executeCallback: {
      type: Boolean,
      default: () => {
        return false
      }
    },

    /**
     * @var {Boolean} refreshResourcesItems
     */
    refreshResourcesItems: {
      type: Boolean,
      default: () => {
        return false
      }
    },

    /**
     * @var {Boolean} refreshAllObservationsItems
     */
    refreshAllObservationsItems: {
      type: Boolean,
      default: () => {
        return false
      }
    },

    /**
     * @var {Boolean} refreshObservationItems
     */
    refreshObservationItems: {
      type: Boolean,
      default: () => {
        return false
      }
    },

    /**
     * @var {Boolean} refreshProjectsItems
     */
    refreshProjectsItems: {
      type: Boolean,
      default: () => {
        return false
      }
    },

    /**
     * @var {String } currentProjectId
     */
     currentProjectId: {
       type: String,
       default: () => {return null}
     }

  },

  data () {
    return {
      filterObject: this.filter,
      perPageValue: this.perPage,
      currentPageValue: this.currentPage,
      totalRowsValue: this.totalRows,
      filteredItemsNoteValue: this.filteredItemsNote,
      sortByValue: this.sortBy,
      sortDescValue: this.sortDesc,
      userData: null,
      showTotalEntriesInfoValue: this.showTotalEntriesInfo,
      tableIdValue: this.tableId,
      locationsAssigned: this.userLocations,
      assignedLocations: this.assignedLocationsPassed,
      itemsValue: this.items,
      forMVP: false,
      userRoleVal: 'member',
      originalItemCount: 0,
    }
  },

  computed: {
    ...mapGetters({
      projectLocations: 'project/show/projectLocations',
      isLoadingObservations: 'observation/show/isLoading',
    }),

    userRole: {
      get() {
        return this.userRoleVal
      },
      set(newUserRoleVal) {
        this.userRoleVal = newUserRoleVal
      }
    },

    filterObj: {
      // getter
      get: function () {
        return this.filterObject
      },
      // setter
      set: function (newValue) {
        this.filterObject = newValue
      }
    },

    searchTermStrGet: {
      // getter
      get: function () {
        return this.searchTermStr
      },
      // setter
      set: function (newValue) {
        this.searchTermStr = newValue
      }
    },

    projectStateVal: {
      // getter
      get: function () {
        return this.projectState
      },
      // setter
      set: function (newValue) {
        this.projectSTate = newValue
      }
    },

    perPageVal: {
      // getter
      get: function () {
        return this.perPageValue
      },
      // setter
      set: function (newValue) {
        this.perPageValue = newValue
      }
    },

    currentPageVal: {
      // getter
      get: function () {
        return this.currentPageValue
      },
      // setter
      set: function (newValue) {
        this.currentPageValue = newValue
      }
    },

    totalRowsVal: {
      // getter
      get: function () {
        return this.totalRowsValue
      },
      // setter
      set: function (newValue) {
        this.totalRowsValue = newValue
      }
    },

    filteredItemsNoteVal: {
      // getter
      get: function () {
        return this.filteredItemsNoteValue
      },
      // setter
      set: function (newValue) {
        this.filteredItemsNoteValue = newValue
      }
    },

    sortByVal: {
      // getter
      get: function () {
        return this.sortByValue
      },
      // setter
      set: function (newValue) {
        this.sortByValue = newValue
      }
    },

    sortDescVal: {
      // getter
      get: function () {
        return this.sortDescValue
      },
      // setter
      set: function (newValue) {
        this.sortDescValue = newValue
      }
    },

    userDataVal: {
      get: function() {
        return this.userData
      },
      set: function(newVal) {
        this.userData = newVal
      }
    },

    userName: {
      get: function() {
        if (this.userDataVal)
          return this.userDataVal.displayName
      }
    },

    showTotalEntriesInfoVal: {
      get: function() {
        return this.showTotalEntriesInfoValue
      },
      set: function(newVal) {
        this.showTotalEntriesInfoValue = newVal
      }
    },

    tableIdVal: {
      get: function() {
        return this.tableIdValue
      },
      set: function(newVal) {
        this.tableIdValue = newVal
      }
    },

    itemsVal: {
      get() {
        return this.itemsValue
      },

      set(items) {
        this.itemsValue = items
      }
    },

    fromItem () {
      return this.totalRowsVal > 0 ? (this.currentPageVal - 1) * this.perPageVal + 1 : 0
    },

    toItem () {
      // console.log("toItem:", this.totalRowsVal, this.currentPageVal, this.perPageVal)
      return this.totalRowsVal < (this.currentPageVal * this.perPageVal) ? this.totalRowsVal : this.currentPageVal * this.perPageVal
    }
  },

  methods: {
    ...mapActions({
      deleteDatasheet: 'project/show/deleteDatasheet',
      deleteResourceItem: 'project/resources/deleteResourceItem',
      deleteProjectMember: 'project/show/deleteProjectMember',
    }),

    onProjectShowClicked( routeName, data ) {
      //const projectRouteName = data['name'].replace(/\s/g, '-' );, hash: '#about'
      this.$router.push({name: routeName, params: { id: data['urlField'] }})
    },

    /**
     * @function approveMember
     * @param evt
     * @param userData
     */
    approveMember(evt, userData) {
      this.$emit('approve-member', {evt: evt, userData: userData})
    },

    /**
     * @function onCreateClick
     * @param callback
     * @param evt
     */
    onCreateClick(callback, evt) {
      evt.preventDefault()

      this.$emit('create-click', { callback: callback, event: evt })
    },

    /**
     * @function onClickEditMember
     * @param evt
     * @param userData
     */
    onClickEditMember(evt, userData) {
      evt.preventDefault()
      // Calling modal for assigning locations to project member
      this.$emit('edit-member', { userData: userData, event: evt } )
    },

    /**
     * @function onClickEditMemberRole
     * @param evt
     * @param userData
     */
    onClickEditMemberRole(evt, userData) {
      evt.preventDefault()
      this.userRole = userData.role
      this.$refs.editMemberRole.show();
      this.userDataVal = userData;
    },

    /**
     * @function onEditRoleConfirm
     * @param evt
     */
    onEditMemberRoleConfirm(evt) {

      // Modal is hidden
      this.$refs.editMemberRole.hide();

      // User role change is handled in a reactive manner
      if (this.userRole !== this.userDataVal.role){
        let sendData = {
          id: this.userDataVal.id,
          role: this.userRole,
        }
        // Change in member role is bubbled to parent for handling
        this.$emit('edit-memberrole-success', sendData)
      }

    },

    /**
     * @function onClickDeleteMemberRequest
     * @param evt
     * @param userData
     */
    onClickDeletePendingMemberRequest(evt, userData) {
      evt.preventDefault();
      this.$refs.deletePendingMember.show();
      this.userDataVal = userData;
    },

    /**
     * @function onDeleteConfirm
     * @param evt
     */
    onRejectMemberConfirm(evt) {
      // Modal is hidden
      this.$refs.deletePendingMember.hide();

      // Reject is bubbled to parent for handling
      this.$emit('delete-member', { userData: this.userDataVal })

    },

    /**
     * @function onClickDeleteMember
     * @param evt
     * @param userData
     */
    onClickDeleteMember(evt, userData) {
      evt.preventDefault();
      this.$refs.deleteMember.show();
      this.userDataVal = userData;
    },

    /**
     * @function onDeleteConfirm
     * @param evt
     */
    onDeleteConfirm(evt) {
      // Modal is hidden
      this.$refs.deleteMember.hide();

      // Delete is bubbled to parent for handling
      this.$emit('delete-member', { userData: this.userDataVal })

    },

    /**
     * @function onCloseDeleteModal
     */
    onCloseDeleteModal() {
      this.$refs.deleteMember.hide()
    },

    /**
     * @function onClickViewDatasheet
     * @param evt
     * @param data
     */
    onClickViewDatasheet(evt, data) {
      evt.preventDefault()
      // /datasheets/preview/<datahseet_id>

      let routeData = this.$router.resolve({name: 'DatasheetView', params: { id: data.id}})
      window.open(routeData.href, '_self')
      // this.$root.$emit('bv::show::modal', 'feature-coming-soon')
    },

    /**
     * @function onClickEditDatasheet
     * @param evt
     * @param data
     */
    onClickEditDatasheet(evt, data) {
      evt.preventDefault()

      // /datasheets/creator/<datahseet_id>
      let routeData = this.$router.resolve({name: 'DatasheetCreator', params: { id: data.id}})
      window.open(routeData.href, '_self')
      // this.$root.$emit('bv::show::modal', 'feature-coming-soon')
    },

    /**
     * @function onClickPlusDatasheet
     * @param evt
     * @param data
     */
    onClickPlusDatasheet(evt, data) {
      evt.preventDefault()

      this.onClickViewDatasheet(evt, data)
    },

    /**
     * @function onClickPrintDatasheet
     * @param evt
     * @param data
     */
    onClickPrintDatasheet(evt, data) {
      evt.preventDefault()

      let routeData = this.$router.resolve({name: 'DatasheetPrint', params: { id: data.id }})
      window.open(routeData.href, '_self')
      // /datasheets/preview/<datahseet_id>
      // this.$root.$emit('bv::show::modal', 'feature-coming-soon')
    },

    /**
     * @function onClickDeleteDatasheet
     * @param evt
     * @param data
     */
    onClickDeleteDatasheet(evt, data) {
      evt.preventDefault()

      this.deleteDatasheet(data.id).then((success) => {
        if (success) {
          this.$emit('delete-datasheet-success', data.id)
        }
      })
    },

    /**
     * @function onClickViewResource
     * @param evt
     * @param item
     */
    onClickViewResource(evt, item) {
      evt.preventDefault()

      if (item.url != null) {
        window.open(item.url, '_blank')
      } else if (item.file != null && item.file.path != null) {
        window.open(item.file.path, '_self')
      }
    },

    /**
     * @function onClickEditResource
     * @param evt
     * @param item
     */
    onClickEditResource(evt, item) {
      evt.preventDefault()
      this.$emit('edit-resource', { evt: evt, resource: item })
    },

    /**
     * @function onClickDeleteResource
     * @param evt
     * @param item
     */
    onClickDeleteResource(evt, item) {
      evt.preventDefault()

      this.deleteResourceItem({id: item.id}).then((data) => {
        this.$emit('refresh-table', 'resource-table')
      })
    },

    /**
     * @function onClickDeleteObservation
     * @param evt
     * @param item
     */
    onClickDeleteObservation(evt, item) {
      evt.preventDefault()

      this.$emit('delete-observation', item)
    },

    /**
     * @function onClickViewObservation
     * @param event
     * @param item
     */
     /*
    onClickViewObservation(evt, item) {
      evt.preventDefault()
      this.$router.push({name: 'ObservationShow', params: { id: item.id}})
    },
    */

    /**
     * @function onClickDeleteLocation
     * @param evt
     * @param userData
     */
    onClickDeleteLocation(evt, userData) {
      evt.preventDefault()
      this.$refs.deleteLocation.show()
      this.userDataVal = userData
    },

    /**
     * @function onDeleteLocationConfirm
     * @param evt
     */
    onDeleteLocationConfirm(evt) {
      // Modal is hidden
      this.$refs.deleteLocation.hide()

      // Delete location is bubbled to parent for handling
      this.$emit('delete-location', this.userDataVal)

    },

    /**
     * @function onClickViewLocation
     * @param evt
     * @param locationData
     */
    onClickViewLocation(evt, locationData) {
      evt.preventDefault()
      this.userDataVal = locationData

      // View location is bubbled to parent for handling
      this.$emit('view-location', this.userDataVal)

    },

    /**
     * @function isObservationObserver
     * @param observationItem
     * @returns {boolean}
     */
    isObservationObserver(observationItem) {
      let sessionData = JSON.parse(localStorage.getItem('sessionData'))
      let observers = observationItem.observers

      if ((typeof observers != 'undefined' && observers.length > 0) && sessionData) {
        observers.forEach((item, index) => {
          if (item.userId == sessionData['id']) {
            return true
          }
        })
      }

      return false
    },

    /**
     * @function clickJoin
     * - Function to handle the join button press
     * - Bubbled up to parent for handling, projectId is passed
     */
    clickJoin(evt, projectId) {
      evt.preventDefault()

      // Join project is bubbled up to parent for handling
      this.$emit('join-click', projectId)
    },

    /**
     * @function onFiltered
     * @param filteredItems
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      if (this.totalRowsVal !== filteredItems.length && filteredItems.length > this.perPageVal) {
        this.totalRowsVal = filteredItems.length
        this.currentPageVal = 1
        this.items.count = filteredItems.length
      }
    },

    /**
     * @function onSortingChanged
     * @param ctx
     */
    onSortingChanged(ctx) {
      if (typeof this.items == 'undefined') {
        return
      }

      if (ctx && ctx.sortBy) {
        const sortByColumnName = ctx.sortBy.indexOf("__") !== -1 ? ctx.sortBy.split("__")[1] : ctx.sortBy
        const sortDescBoolean = ctx.sortDesc

        this.itemsVal.some(e => {
          if (e[sortByColumnName]) {
            this.itemsVal.sort((a,b) => {
              if (sortDescBoolean) {
                if (typeof(b[sortByColumnName]) === 'number' && typeof(a[sortByColumnName]) === 'number') {
                  return (b[sortByColumnName] > a[sortByColumnName] ? 1 : a[sortByColumnName] > b[sortByColumnName] ? -1 : 0)
                } else {
                  return ((b[sortByColumnName]).toString().toLowerCase() > (a[sortByColumnName]).toString().toLowerCase()) ? 1 : (((a[sortByColumnName]).toString().toLowerCase() > (b[sortByColumnName]).toString().toLowerCase()) ? -1 : 0)
                }
              } else {
                if (typeof(b[sortByColumnName]) === 'number' && typeof(a[sortByColumnName]) === 'number') {
                  return (a[sortByColumnName] > b[sortByColumnName] ? 1 : b[sortByColumnName] > a[sortByColumnName] ? -1 : 0)
                } else {
                  return ((a[sortByColumnName]).toString().toLowerCase() > (b[sortByColumnName]).toString().toLowerCase()) ? 1 : (((b[sortByColumnName]).toString().toLowerCase() > (a[sortByColumnName]).toString().toLowerCase()) ? -1 : 0)
                }
              }
            })
          }
        })
      }
    },

    mySortCompare(valOne, valTwo, key) {

      if( key.toLowerCase() === 'locationname' ) {
        const valFirst = valOne && valOne.location && valOne.location.name,
          valSec = valTwo && valTwo.location && valTwo.location.name;
        return this.sortCustomByValue( valFirst, valSec );
      }

      if( key.toLowerCase() === 'locationlatitude' ) {
        const valFirst = valOne && valOne.location && valOne.location.latitude,
          valSec = valTwo && valTwo.location && valTwo.location.latitude;

        return this.sortCustomByValue( valFirst, valSec );
      }

      if( key.toLowerCase() === 'locationlongitude' ) {
        const valFirst = valOne && valOne.location && valOne.location.longitude,
          valSec = valTwo && valTwo.location && valTwo.location.longitude;

        return this.sortCustomByValue( valFirst, valSec );
      }
    },

    /**
     * @function sortCustomByValue
     * accept value and sort based on value
     */
    sortCustomByValue( valOne, valTwo ) {
      return valOne < valTwo ? -1 :   valOne > valTwo  ? 1 : 0
    },

    /**
     * @function onContextChanged
     */
    onContextChanged () {
      // Handle empty filter object
      let isEmpty = true
      if (this.filterObject) {
        Object.keys(this.filterObject).forEach((key) => {
          if (this.filterObject[key] !== null) {
            isEmpty = false
          }
        })
        this.filteredItemsNoteVal = !isEmpty
      }

    },

    /**
     * @function myProvider
     * @param ctx
     * @param callback
     * @returns {*}
     */
    myProvider(ctx, callback) {
      // console.log('myProvider:', ctx)
      this.projectStateVal = ctx.projectState
      this.perPageVal = ctx.perPage
      this.currentPageVal = ctx.currentPage
      if (ctx.sortBy) {
        ctx.sortBy = ctx.sortBy.indexOf("__") !== -1 ? ctx.sortBy.split("__")[1] : ctx.sortBy
      } else {
        ctx.sortBy = this.sortBy
        if ( this.sortDirection === "desc" )
          ctx.sortDesc = this.sortDesc
      }
      this.sortByVal = ctx.sortBy
      this.sortDescVal = ctx.sortDesc

      let offset = 0
      if (this.currentPageVal === 1 && this.totalRowsVal <= this.perPageVal) {
        offset = 0
      } else if (this.totalRowsVal <= (this.currentPageVal * this.perPageVal)) {
        offset = (this.currentPageVal * this.perPageVal)
      } else {
        offset = (this.currentPageVal - 1) * this.perPageVal
      }

      let _callback = null

      if (typeof callback === 'function') {
        _callback = callback
      } else if (typeof this.callback === 'function') {
        _callback = this.callback
      }

      if (typeof _callback === 'function') {
        let urlParams = this.urlBuilder(ctx)
        try {
          return _callback({urlParams: urlParams}).then((data) => {
            if (typeof data === 'object' && typeof data['hydra:totalItems'] !== 'undefined' && typeof data['hydra:member'] !== 'undefined') {
              this.totalRowsVal = data['hydra:totalItems']
              this.originalItemCount = this.totalRowsVal
              this.itemsVal = data['hydra:member']
              return data['hydra:member']
            }
            return []
          })
        } catch (e) {
          // console.log('Some error occurred on getting data from API')
          return []
        }
      }
      return this.items && this.items.length ? this.items.slice(offset, this.perPageVal) : []
    },

  },

  watch: {

    items(newItems) {
      this.$root.$emit('bv::refresh::table', this.tableIdVal)
    },

    itemsVal(newValue) {
      this.$emit('locations-loaded', newValue);
      this.$emit('items-loaded', this.itemsValue);
    },

    filter(val) {
      setTimeout(() => {this.filterObj = val}, 1000)
    },

    executeCallback(newValue) {
      if (newValue) {
        this.myProvider({
          apiUrl: "",
          currentPage: this.currentPage || 1,
          filter: null,
          projectState: this.projectState,
          perPage: this.perPage || 10,
          sortBy: this.sortByVal,
          sortDesc: this.sortDescVal
        })
      }
    },

    refreshLocationsItems(newValue) {
      if (newValue) {
        this.myProvider({
          apiUrl: "",
          currentPage: this.currentPage || 1,
          filter: null,
          perPage: this.perPage || 10,
          sortBy: this.sortByVal,
          sortDesc: this.sortDescVal
        })
      }
    },

    refreshResourcesItems(newValue) {
      if (newValue) {
        this.myProvider({
          apiUrl: "",
          currentPage: this.currentPage || 1,
          filter: null,
          perPage: this.perPage || 10,
          sortBy: null,
          sortDesc: false
        })
      }
    },


    refreshAllObservationsItems(newValue) {
      if (newValue) {
        this.myProvider({
          apiUrl: "",
          currentPage: this.currentPage || 1,
          filter: null,
          perPage: this.perPage || 10,
          sortBy: this.sortByVal,
          sortDesc: this.sortDescVal
        })
      }
    },

    refreshObservationItems(newValue) {
      if (newValue) {
        this.myProvider({
          apiUrl: "",
          currentPage: this.currentPage || 1,
          filter: null,
          perPage: this.perPage || 10,
          sortBy: this.sortByVal,
          sortDesc: this.sortDescVal
        })
      }
    },

    refreshProjectsItems(newValue) {
      if (newValue) {
        this.myProvider({
          apiUrl: "",
          currentPage: this.currentPage,
          filter: null,
          projectState: this.projectStateVal,
          perPage: this.perPage,
          sortBy: this.sortByVal,
          sortDesc: this.sortDescVal
        })
      }
    },

    perPageVal(newValue) {
      if (newValue) {
        this.myProvider({
          apiUrl: "",
          currentPage: this.currentPageVal,
          filter: null,
          projectState: this.projectStateVal,
          perPage: newValue,
          sortBy: this.sortByVal,
          sortDesc: this.sortDescVal
        })
      }
    },

    currentPageVal(newValue) {
      if (newValue) {
        this.myProvider({
          apiUrl: "",
          currentPage: newValue,
          filter: null,
          projectState: this.projectStateVal,
          perPage: this.perPageVal,
          sortBy: this.sortByVal,
          sortDesc: this.sortDescVal
        })
      }
    },

  },

  created() {
    // console.log ( "BootstrapVueTable created() : ", window.ClickedProjectId )
    if (this.executeCallback) {
      this.myProvider({
        apiUrl: "",
        currentPage: this.currentPage || 1,
        filter: null,
        projectState: this.projectState,
        perPage: this.perPage || 10,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc
      })
    }
  }
}
