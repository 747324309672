<template>
  <div v-if="projectVuex">
    <div v-if="isPublicOrMember" class="card-body">
      <div class="row mb-3">
        <div class="col-md-6 col-sm-2 h3 mb-0">Forum</div>
        <div class="col-md-3 col-sm-3"></div>
        <div class="col-md-3 col-sm-7 h6 mb-0" v-if="isMember">
          <toggle-button v-model="forumNotifications"
                         color="#00c8f8"
                         :sync="true"
                         :labels="{ checked: 'On', unchecked: 'Off' }"/>
          <span>Subscribe to Forum Post Notifications</span><br>
          <span class="text muted small">You are {{ membershipMap[projectId].settings['forumNotify'] ? '' : 'not' }} receiving forum post notifications via email</span>
        </div>
      </div>

      <div class="row">
        <!-- Sidebar New Discussion Button, TagFilter, PopularTags -->
        <div class="col-lg-3 d-none d-lg-block sidebar">
          <NewDiscussionButton v-if="isMember"></NewDiscussionButton>
          <div class="horizontal-separator"></div>
          <TagsFilter :set-tag="tagClick" @selectedTag="handleTagFilter"></TagsFilter>
          <TagsPopular @popularTagClicked="handlePopularTagClick"></TagsPopular>
        </div>

        <!-- Forum Posts -->
        <div class="col-lg-9 col-md-12">

          <!-- Top SortBy, Pagination, Search -->
          <div class="card-body">
            <div class="row align-items-center justify-content-between mb-3">
              <div class="col-lg-3 d-lg-none">
                <NewDiscussionButton v-if="isMember"></NewDiscussionButton>
              </div>
            </div>
            <div v-if="isManager" class="row align-items-center justify-content-end mb-3">
              <b-form-checkbox
                v-model="hiddenPost"
                value="true"
                unchecked-value=null
                v-b-tooltip.hover title="Click to filter by Email Only Forum Post's">
                <b-badge variant="secondary">Email Only Forum Post</b-badge>
              </b-form-checkbox>
            </div>
            <div class="row align-items-center justify-content-between mb-3">
              <div class="input-group col-xs-12 col-sm-12 col-md-6 mb-2">
                <b-input-group-prepend>
                  <span class="btn btn-secondary" title="Search Forum Posts">
                    <i v-if="!isBusy" class="fas fa-search"></i>
                    <i v-if="isBusy" class="fas fa-spinner fa-spin"></i>
                  </span>
                </b-input-group-prepend>
                <b-form-input
                  placeholder="Search Forum Posts"
                  name="search"
                  type="search"
                  v-model="searchText"
                  debounce="1000"
                ></b-form-input>
                <div class="input-group-append" v-if="searchText">
                  <b-button @click="searchText = ''"
                  ><i class="fas fa-times-circle"></i>
                  </b-button>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 mb-2">
                <b-form-group
                  label="Sort By: "
                  label-for="sort-by-select"
                  label-cols-md="2"
                  label-align-sm="left"
                  label-size="sm"
                  class="mb-0 font-weight-bold"
                  v-slot="{ ariaDescribedby }"
                >
                  <b-input-group size="sm">
                    <b-form-select
                      id="sort-by-select"
                      v-model="sortBy"
                      :options="sortOptions"
                      :aria-describedby="ariaDescribedby"
                      class="col-xs-7 col-md-9"
                    >
                    </b-form-select>

                    <b-form-select
                      v-model="sortDesc"
                      :disabled="!sortBy"
                      :aria-describedby="ariaDescribedby"
                      size="sm"
                      class="col-xs-5 col-md-3"
                    >
                      <option :value="false">asc</option>
                      <option :value="true">desc</option>
                    </b-form-select>
                  </b-input-group>
                </b-form-group>
              </div>
            </div>
            <div class="row justify-content-between">
              <b-col md="3" class="my-1">
                Showing {{ fromItem }} to {{ toItem }} of {{ totalItems }}
                forum post<span v-if="totalItems === 0 || totalItems > 1">s</span>
                <span v-if="filterTag"> (filtered by tag '{{ filterTag }}') </span>
              </b-col>

              <b-col sm="4" md="3" class="my-1">
                <b-pagination
                  :total-rows="totalItems"
                  :per-page="perPage"
                  v-model="currentPage"
                  class="my-0 pagination-holder"
                  prev-text="Prev"
                  next-text="Next"
                  align="fill"
                />
              </b-col>

              <b-col sm="4" md="3" class="my-1">
                <b-form-group
                  label="Per Page"
                  label-align="right"
                  label-cols-sm="7"
                  label-cols-md="8"
                  label-for="perPageSelect"
                  class="mb-0"
                >
                  <b-form-select
                    v-model="perPage"
                    id="perPageSelect"
                    size="sm"
                    :options="pageOptions"
                  >
                  </b-form-select>
                </b-form-group>
              </b-col>
            </div>
          </div>

          <!-- Hidden table -->
          <b-table v-show="false"
                   show-empty
                   stacked="md"
                   id="forumPostsTable"
                   ref="forumPostsTable"
                   :filter="filterVal"
                   :busy="isBusy"
                   :items="posts"
                   :fields="tableFields"
                   :current-page="currentPage"
                   :per-page="perPage"
                   :sort-by.sync="sortBy"
                   :sort-desc.sync="sortDesc"
                   :sort-direction="sortDirection"
          >
          </b-table>

          <div v-if="isLoading" style="min-height:200px;">
            <i class="fa fa-spin fa-spinner fa-3x mr-3"></i> Loading forum posts...
          </div>
          <div v-else>
            <div v-if="allPosts && allPosts.length === 0">
              <p v-if="this.forumSearch || this.forumTag || this.forumHiddenPost">
                <b>No Matching Results...</b>
              </p>
              <p v-else>
                <b>Be the first to start a forum post...</b>
              </p>
            </div>
            <div v-else
                 v-for="post in allPosts"
                 :key="post.id">
              <ParentPost v-if="post.parentPost === null" :discussion="post" @tagClicked="handleTagClick"></ParentPost>
              <ChildPost v-else :reply="post"></ChildPost>
            </div>
          </div>

          <!-- Bottom Pagination -->
          <div class="card-footer">
            <div class="row justify-content-between">
              <b-col md="3" class="my-1">
                Showing {{ fromItem }} to {{ toItem }} of {{ totalItems }}
                forum post<span v-if="totalItems === 0 || totalItems > 1">s</span>
                <span v-if="filterTag"> (filtered by tag '{{ filterTag }}') </span>
              </b-col>

              <b-col sm="4" md="3" class="my-1">
                <b-pagination
                  :total-rows="totalItems"
                  :per-page="perPage"
                  v-model="currentPage"
                  class="my-0 pagination-holder"
                  prev-text="Prev"
                  next-text="Next"
                  align="fill"
                />
              </b-col>

              <b-col sm="4" md="3" class="my-1">
                <b-form-group
                  label="Per Page"
                  label-align="right"
                  label-cols-sm="7"
                  label-cols-md="8"
                  label-for="perPageSelect"
                  class="mb-0"
                >
                  <b-form-select
                    v-model="perPage"
                    id="perPageSelect"
                    size="sm"
                    :options="pageOptions"
                  >
                  </b-form-select>
                </b-form-group>
              </b-col>
            </div>
          </div>

        </div>
      </div>
      <NewDiscussionModal @submit="onNewDiscussion"></NewDiscussionModal>
    </div>
    <RequiresMember v-else page="Forum"></RequiresMember>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import RequiresMember from "../tabpermission/requiresMember.vue";
import NewDiscussionModal from "./NewDiscussionModal.vue";
import NewDiscussionButton from "./newDiscussionButton.vue";
import ParentPost from "./forumPostParent.vue";
import ChildPost from "./forumPostReply.vue";
import TagsFilter from "./tagsFilter.vue"
import TagsPopular from "./tagsPopular.vue"

export default {
  name: "index",
  components: {
    RequiresMember,
    NewDiscussionModal,
    NewDiscussionButton,
    ParentPost,
    ChildPost,
    TagsFilter,
    TagsPopular,
  },
  props: {},
  data() {
    return {
      pageOptions: [10, 25, 50, 100, 250, 500],
      isBusy: false,
      tagClick: null,
      tableFields: [
        {key: "subject", label: "Title", sortable: true},
        {key: "createdAt", label: "Created", sortable: true},
      ],
    };
  },
  created() {
    if (this.projectVuex) {
      this.forumTags()
    }
    if (this.forumTag) {
      // Trigger child component
      this.tagClick = this.forumTag
    }
  },

  watch: {
    projectVuex(newVal) {
      if (newVal) {
        this.forumTags()
      }
    },
  },
  destroyed() {
  },
  computed: {
    ...mapGetters({
      projectVuex: "project/get/project",
      projectId: "project/get/id",
      isPublicOrMember: "project/get/isPublicOrMember",
      isManager: "project/get/isManager",
      isMember: "project/get/isMember",
      membershipMap: "user/profile/membershipMap",

      isLoading: "project/forum/isLoading",
      allPosts: "project/forum/posts",

      forumPage: "project/get/forumPage",
      forumItems: "project/get/forumItems",
      forumTotal: "project/get/forumTotal",
      forumSearch: "project/get/forumSearch",
      forumTag: "project/get/forumTag",
      forumHiddenPost: "project/get/forumHiddenPost",
      forumSortBy: "project/get/forumSortBy",
      forumSortDirection: "project/get/forumSortDirection",
      forumSortDesc: "project/get/forumSortDesc",
    }),

    currentPage: {
      get() {
        return this.forumPage
      },
      set(newValue) {
        this.setForumTablePage(newValue)
      }
    },
    totalItems: {
      get() {
        return this.forumTotal
      },
      set(newValue) {
        this.setForumTableTotal(newValue)
      }
    },
    perPage: {
      get() {
        return this.forumItems
      },
      set(newValue) {
        this.setForumTableItems(newValue)
      }
    },
    searchText: {
      get() {
        return this.forumSearch
      },
      set(newValue) {
        this.setForumTableSearch(newValue)
      }
    },
    filterTag: {
      get() {
        return this.forumTag
      },
      set(newValue) {
        this.setForumTableTag(newValue)
      }
    },
    hiddenPost: {
      get() {
        return this.forumHiddenPost
      },
      set(newValue) {
        this.setForumHiddenPost(newValue)
      }
    },
    sortBy: {
      get() {
        return this.forumSortBy
      },
      set(newValue) {
        this.setForumSortBy(newValue)
      }
    },
    sortDirection: {
      get() {
        return this.forumSortDirection
      },
      set(newValue) {
        this.setForumSortDirection(newValue)
      }
    },
    sortDesc: {
      get() {
        return this.forumSortDesc
      },
      set(newValue) {
        this.setForumSortDesc(newValue)
      }
    },

    fromItem() {
      return this.totalItems > 0
        ? (this.currentPage - 1) * this.perPage + 1
        : 0;
    },
    toItem() {
      return this.totalItems < this.currentPage * this.perPage
        ? this.totalItems
        : this.currentPage * this.perPage;
    },

    sortOptions() {
      // Create an options list from our fields
      return this.tableFields
        .filter(f => f.sortable)
        .map(f => {
          return {text: f.label, value: f.key}
        })
    },

    // clickedTag: {
    //   get() {
    //     return this.clickedTag
    //   },
    //   set(newVal) {
    //     this.clickedTag = newVal
    //   }
    // },

    filterVal: {
      get: function () {
        // This is our multi-filter computed value
        // - search by title and post content
        // - filter by tags

        let retVal = ''
        // This boolean is used to show replied posts when searching by title/content
        this.hideReplies = true
        if (this.searchText.length > 0) {
          retVal = retVal.length === 0 ? 'search=' + this.searchText : retVal + '&search=' + this.searchText
          this.hideReplies = false
        }
        if (this.filterTag) {
          retVal = retVal.length === 0 ? 'tags=' + this.filterTag : retVal + '&tags=' + this.filterTag
        }
        if (this.hiddenPost) {
          retVal = retVal.length === 0 ? 'isHidden=' + this.hiddenPost : retVal + '&isHidden=' + this.hiddenPost
        }
        return retVal
      }
    },

    forumNotifications: {
      get() {
        return this.membershipMap[this.projectId].settings['forumNotify']
      },
      set(newVal) {
        //
        console.log('update forum notify', newVal, this.membershipMap[this.projectId].settings)
        this.updateForumNotify({id: this.membershipMap[this.projectId].id, forumNotify: newVal})
      }
    }
  },

  methods: {
    ...mapActions({
      forumData: "project/forum/get",
      forumTags: "project/forum/tags",
      setForumTablePage: "project/get/setForumTablePage",
      setForumTableItems: "project/get/setForumTableItems",
      setForumTableTotal: "project/get/setForumTableTotal",
      setForumTableSearch: "project/get/setForumTableSearch",
      setForumTableTag: "project/get/setForumTableTag",
      setForumHiddenPost: "project/get/setForumHiddenPost",
      setForumSortBy: "project/get/setForumTableSortBy",
      setForumSortDirection: "project/get/setForumTableSortDirection",
      setForumSortDesc: "project/get/setForumTableSortDesc",
      updateForumNotify: "project/forum/updateForumNotify",
    }),
    handleTagFilter(tag) {
      // Handle TagFilter selection
      // - Skip if values match, prevent infinite loop
      // - TagFilter will emit when a PopularTag/PostTag is clicked
      if (this.filterTag !== tag) {
        this.filterTag = tag
        if (this.tagClick && !tag) {
          // External tag is cleared
          this.tagClick = null
        }
      }
    },
    handlePopularTagClick(tag) {
      // Notify TagFilter component of Popular Tag clicked
      this.tagClick = tag
      // Filter the forum posts in this component
      this.filterTag = tag
    },
    handleTagClick(tag) {
      // Notify TagFilter component of Post Tag clicked
      this.tagClick = tag
      // Filter the forum posts in this component
      this.filterTag = tag
    },
    onNewDiscussion() {
      // Posts and tags reloaded when a new forum post is added
      this.forumTags()
      this.$refs.forumPostsTable.refresh()
    },
    posts(ctx) {
      this.isBusy = true;
      let urlParams = `${this.urlBuilder(ctx)}`;
      // Show replies only when searching
      if (this.hideReplies) {
        urlParams = urlParams + '&parentPost[exists]=false'
      }
      return this.forumData({urlParams}).then(data => {
        this.totalItems = data["hydra:totalItems"];
        this.currentPage = ctx.currentPage || 1;
        this.sortBy = ctx.sortBy || "";
        this.isBusy = false;
        return data["hydra:member"].map(post => {
          return {
            ...post
          };
        });
      });
    },
  }
}
</script>

<style scoped lang="scss">
.sidebar {
  overflow-y: auto;
  border-right: 1px solid #5a5b54;
}

.horizontal-separator {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #5a5b54;
}
</style>

