<script>
import NewDiscussionModal from "./NewDiscussionModal.vue";

export default {
  name: "newDiscussionButton",
  components: {
    NewDiscussionModal,
  },
}
</script>

<template>
  <div>
    <button class="btn btn-primary has-icon btn-block mb-2"
            @click="() => { this.$bvModal.show('new-discussion-modal') }">
      <i class="fa fa-commenting" aria-hidden="true"></i>
      New Forum Post
    </button>
  </div>
</template>

<style scoped lang="scss">

</style>
