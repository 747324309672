<template>
  <div>
    <div class="card-header bg-light">
      Select a Datasheet to Send Images
    </div>
    <div class="card-body">
      <b-form-group id="datasheets"
                    label-cols-lg="2"
                    content-cols-lg="10"
                    label="Datasheets:"
                    label-class="font-weight-bold"
                    label-for="input-bases">
        <b-form-select id="input-bases"
                       v-model="selectedZooniverseDS"
                       :options="getDatasheets"
                       placeholder="Pick a Datasheet">
        </b-form-select>
      </b-form-group>
      <b-btn v-if="selectedZooniverseDS"
             type="submit"
             variant="primary"
             alt="Send your images to Zooniverse"
             title="Send your images to Zooniverse"
             @click="submitImagesToZooniverse"
             :disabled="isLoading">
        Transfer Images to Zooniverse
        <i v-if="isLoadingDS" class="fas fa-spinner fa-spin ml-2"></i>
      </b-btn><br>
      <i v-if="transferError">{{ transferError }}</i>
    </div>
    <div v-if="selectedZooniverseDS">
      <TransferHistory :datasheetId="selectedZooniverseDS"></TransferHistory>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import TransferHistory from "./transferHistory.vue";

export default {
  name: "ZooniverseProject",
  props: {},
  components: {TransferHistory},
  data() {
    return {
      selectedZooniverseDS: null};
  },
  created() {
  },
  watch: {},

  computed: {
    ...mapGetters({
      isLoading: "project/get/isLoading",
      projectVuex: "project/get/project",
      publishedDatasheets: "project/get/publishedDatasheets",
      isLoadingDS: "project/get/zooniverse/isLoadingDatasheets",
      transferError: "project/get/zooniverse/messageLoadingDatasheets"
    }),
    getDatasheets() {
      if (this.publishedDatasheets) {
        let arrDatasheets = []
        arrDatasheets.push({value: null, text: "Pick a Datasheet...", disabled: true})
        Object.values(this.publishedDatasheets).forEach(ds => {
          arrDatasheets.push({value: ds.id, text: ds.name})
        })
        if (arrDatasheets.length > 2) {
          arrDatasheets.push({value: "all", text: "All Datasheets", disabled: false})
        }
        return arrDatasheets
      } else {
        return []
      }
    },
  },

  methods: {
    ...mapActions({
      sendImagesToZooniverse: "project/get/zooniverse/syncDatasheets",
    }),

    submitImagesToZooniverse() {
      if (!this.projectVuex.zooniverseUserName) {
        this.$bvModal.msgBoxOk(
          "A Zooniverse Collaborator must be set before Images can be transferred to Zooniverse.",
          {
            title: "Zooniverse Collaborator Not Set",
            size: "md",
            okVariant: "info",
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true
          }
        );
        return;
      }

      if (this.projectVuex.zooniverseID === null) {
        this.$bvModal.msgBoxOk(
          "A Zooniverse Project must be created before Images can be transferred to Zooniverse.",
          {
            title: "Zooniverse Project Not Created",
            size: "md",
            okVariant: "info",
            headerClass: "p-2 border-bottom-0",
            footerClass: "p-2 border-top-0",
            centered: true
          }
        );
        return;
      }

      let selectedDS = []
      if (this.selectedZooniverseDS === "all") {
        selectedDS = this.publishedDatasheets.map(ds => {
          return ds.id;
        });
      } else {
        selectedDS.push(this.selectedZooniverseDS);
      }
      this.sendImagesToZooniverse(selectedDS);
    },
  }
};
</script>

<style lang="scss" scoped></style>

