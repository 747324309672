import fetch from '../../../../utils/fetch'

const initialState = {
  isLoading: false,
  resources: null,
  resourceMaps: null,
  error: null,
}

const getters = {
  resources(state) {
    return state.resources
  },
  resourceMaps(state) {
    return state.resourceMaps
  },
  isLoading(state) {
    return state.isLoading
  },
  error(state) {
    return state.error
  },
}

const actions = {

  get({commit, state, rootState}, data) {
    commit(types.SET_PROJECT_RESOURCES_LOADING);
    return fetch('/projects/' + rootState.project.get.project.id + '/resources' + data.urlParams)
      .then(response => response.json())
      .then((resources) => {
        commit(types.SET_PROJECT_RESOURCES, resources)
        return resources
      })
      .catch((error) => {
        commit(types.SET_PROJECT_RESOURCES_ERROR, error)
      })
  },

  getMapTypes({commit, state, rootState}) {
    commit(types.SET_PROJECT_RESOURCES_LOADING);
    return fetch('/projects/' + rootState.project.get.project.id + '/resources?type[]=raster&type[]=vector')
      .then(response => response.json())
      .then((resources) => {
        commit(types.SET_PROJECT_RESOURCE_MAPS, resources['hydra:member'])
        return resources
      })
      .catch((error) => {
        commit(types.SET_PROJECT_RESOURCES_ERROR, error)
      })
  },

  create({ commit, dispatch }, data) {
    commit(types.SET_PROJECT_RESOURCES_LOADING)
    return fetch(('/project_resources'), {
      method: 'POST',
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then((resource) => {
        return resource
      })
      .catch((error) => {
        commit(types.SET_PROJECT_RESOURCES_ERROR, error.message)
      })
  },

  edit({ commit, dispatch }, data) {
    commit(types.SET_PROJECT_RESOURCES_LOADING)
    return fetch(('/project_resources/' + data.id), {
      method: 'PUT',
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then((resource) => {
        return resource
      })
      .catch((error) => {
        commit(types.SET_PROJECT_RESOURCES_ERROR, error.message)
      })
  },

  delete ({ commit, dispatch }, data) {
    commit(types.SET_PROJECT_RESOURCES_LOADING)
    return fetch(('/project_resources/' + data.id), {
      method: 'DELETE'
    })
      .then((response) => {
        return response.status === 204
      })
      .then((isDeleted) => {
        return isDeleted
      })
      .catch((error) => {
        commit(types.SET_PROJECT_RESOURCES_ERROR, error.message)
      })
  }
}

const types = {
  SET_PROJECT_RESOURCES_LOADING: 'SET_PROJECT_RESOURCES_LOADING',
  SET_PROJECT_RESOURCES: 'SET_PROJECT_RESOURCES',
  SET_PROJECT_RESOURCE_MAPS: 'SET_PROJECT_RESOURCE_MAPS',
  SET_PROJECT_RESOURCES_ERROR: 'SET_PROJECT_RESOURCES_ERROR',
  RESET_ERROR: 'RESET_ERROR',
  RESET: 'RESET',
}

const mutations = {
  [types.SET_PROJECT_RESOURCES_LOADING](state) {
    state.isLoading = true;
  },
  [types.SET_PROJECT_RESOURCES](state, resources) {
    state.resources = resources
    state.isLoading = false
  },
  [types.SET_PROJECT_RESOURCE_MAPS](state, resourceMaps) {
    state.resourceMaps = resourceMaps
    state.isLoading = false
  },
  [types.SET_PROJECT_RESOURCES_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },
  [types.RESET_ERROR](state) {
    state.isLoading = false;
    state.error = null;
  },
  [types.RESET](state) {
    state = initialState
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters: getters,
  actions: actions,
  mutations: mutations
}
