import fetch from '../../../utils/fetch'
import {urlBuilder} from '../../../helpers/url_builder_helper.js'

const getInitialState = () => {
  return {
    observations: [],
    labelSelected: [],
    isLoading: false,
    error: null
  }
}

export const types = {
  RESET: "RESET",
  GET_OBSERVATIONS_START: "GET_OBSERVATIONS_START",
  ADD_OBSERVATIONS: "ADD_OBSERVATIONS",
  GET_OBSERVATIONS_ERROR: "GET_OBSERVATIONS_ERROR",
  STOP_LOADING: "STOP_LOADING",
  SET_LABEL: "SET_LABEL",
  ADD_LABEL: "ADD_LABEL",
  REMOVE_LABEL: "REMOVE_LABEL",
}

const state = getInitialState();

const getters = {
  observations(state) {
    return state.observations
  },
  isLoading(state) {
    return state.isLoading
  },
  observationRecords(state, getters) {
    return getters.observations.reduce((records, observation) => {
      const currentRecords = observation.records.map((record) => {
        return {
          ...record,
          orderNumber: record['optionValue'] ? record['optionValue'].orderNumber: null,
          observedAt: observation.observedAt,
        }
      })
      return [...records, ...currentRecords];
    }, [])
  },
  observationsFiltered(state, getters) {
    if (state.labelSelected.length > 0) {
      return state.labelSelected.map(label => {

        const filteredObservations = getters.observationRecords.filter((record) => {
          return (record.label === label && record.recordType !== 'organism')
        })

        // RecordType is found for the label selected
        const labelType = getters.labels.find((labelVal) => {
          return labelVal.name === label
        })['type']

        if (labelType === 'number') {
          // Numerical variable
          return {
            label: label,
            type: labelType,
            yCat: [],
            values: [{
              name: label, data: filteredObservations.map((obsRecs) => {
                return ([(new Date(obsRecs.observedAt)).getTime(), parseFloat(obsRecs.value)])
              })
            }]
          }
        } else {
          // Categorical variable
          // - Find unique values and set a level for the y-axis
          // - Try to use the optionValue.orderNumber first

          const uniqueValues = []
          const uniqueLevels = []

          // let uniqueOrderNumbers = [...new Set(filteredObservations.map((recs) => {
          //   return recs.orderNumber
          // }))]
          // console.log('uniqueOrderNumbers', uniqueOrderNumbers)

          // 'null' order numbers present, custom y-axis values are used
          // uniqueOrderNumbers.includes(null)
          if (uniqueValues.length === 0) {
            const tempUniqueLevels = [...new Set(filteredObservations.map((obsRecs) => obsRecs.value.toString() ))]
            tempUniqueLevels.map((uLevel) => {
              uniqueLevels.push(uLevel)
            })
            let iter = -1
            const tempUniqueValues = uniqueLevels.map((val) => {
              iter++
              return { name: val, value: iter }
            })
            tempUniqueValues.map((uVal) => {
              uniqueValues.push(uVal)
            })
          }
          // else {
          //   // All order numbers are non-null, order numbers are used as y-axis values
          //   const tempValues = filteredObservations.map((obsRecs) => {
          //     return {
          //       name: obsRecs.value,
          //       value: obsRecs.orderNumber ? obsRecs.orderNumber-1 : -1
          //     }
          //   })
          //   const tempUniqueLevels = [... new Set(tempValues.map((uLevel) => uLevel.name))]
          //   tempUniqueLevels.map((uLevel) => {
          //     uniqueLevels.push(uLevel)
          //   })
          //   const tempUniqueValues = [...new Map(tempValues.map((item) => {
          //       return [item['name'], item]
          //     }
          //   )).values()]
          //   tempUniqueValues.map((uVal) => {
          //     uniqueValues.push(uVal)
          //   })
          // }
          // console.log(uniqueValues)

          // - Fetch the corresponding data for each unique level
          const pairedValues = []
          uniqueValues.forEach((val) => {
            pairedValues.push({
              name: val.name,
              data: filteredObservations.map(obsRecs => {
                return ([
                  (new Date(obsRecs.observedAt)).getTime(),
                  obsRecs.value === val.name ? val.value : null
                ])
              })
            })
          })
          // console.log(pairedValues)

          return {
            label: label,
            type: labelType,
            yCat: uniqueLevels,
            // uniqueLevels.sort((a,b) => a[1]>b[1]?-1:1).map(u => u.value),
            values: pairedValues
          }
        }
      })
    } else {
      return []
    }
    //TODO: use this for comparison purposes
    // const pairedValues1 = []
    // uniqueValues.forEach(val => {
    //   pairedValues1.push ([ filteredObservations.filter(obsRecs => obsRecs.value === val).length])
    // })
    // console.log(pairedValues1)
  },
  labels(state, getters) {
    return getters.observationRecords.map((record) => {
      return {
        name: record.label,
        type: record.recordType
      }
    })
  },
  labelsNumeric(state, getters) {
    // Return unique numeric labels
    const numericLabels = getters.labels.filter((label) => {
      return label.type === 'number'
    }).map(numericLabel => numericLabel.name)
    return [...new Set(numericLabels)].sort()
  },
  labelsCategorical(state, getters) {
    // Return unique categorical labels
    const categoricalLabels = getters.labels.filter((label) => {
      return label.type === 'dropdown' || label.type === 'radio'
    }).map(categoricalLabel => categoricalLabel.name)
    return [...new Set(categoricalLabels)].sort()
  }
}

function fetchObservationBlock({commit}, {url, ctx}) {
  commit(types.GET_OBSERVATIONS_START)
  return fetch(url + `${urlBuilder(ctx)}`)
    .then(response => response.json())
    .then((data) => {
      commit(types.STOP_LOADING)
      commit(types.ADD_OBSERVATIONS, data['hydra:member'])
      if (ctx.currentPage === 1) return data
    })
    .catch((e) => {
      commit(types.STOP_LOADING)
      commit(types.GET_OBSERVATIONS_ERROR, e.message)
    })
}

const actions = {
  fetchObservations({commit}, {type = 'location', id}) {
    if (id === null) {
      commit(types.GET_OBSERVATIONS_ERROR, `No ${type} ID selected`)
      commit(types.STOP_LOADING)
      return
    }
    commit(types.RESET)

    const maxFetch = 500;
    const ctx = {currentPage: 1, perPage: maxFetch, sortDesc: true, sortBy: 'observedAt'};
    let url = (type === 'location' ?  '/areas/' : '/projects/') + id + '/observations';
    let totalItems = null;
    fetchObservationBlock({commit}, {url, ctx}).then(async (data) => {
      totalItems = data['hydra:totalItems'];
      if (totalItems > maxFetch) {
        for (let i = 1; i < (totalItems / maxFetch); i++) {
          const ctx = {currentPage: i + 1, perPage: maxFetch, sortDesc: true, sortBy: 'observedAt'};
          await fetchObservationBlock({commit}, {url, ctx})
        }
      }
    });
  },
}

const mutations = {
  [types.GET_OBSERVATIONS_ERROR](state, error) {
    state.error = error
  },
  [types.GET_OBSERVATIONS_START](state) {
    state.isLoading = true;
  },
  [types.STOP_LOADING](state) {
    state.isLoading = false;
  },
  [types.ADD_OBSERVATIONS](state, observations) {
    state.observations = [...state.observations, ...observations];
  },
  [types.RESET](state) {
    Object.assign(state, getInitialState());
  },
  setLabel(state, value) {
    state.labelSelected = value;
  }
}

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
}
