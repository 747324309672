import VueGoogleMap from '../../../components/layout/GoogleMap'
import VueLoader from '../../../components/layout/Loader'
import { mapGetters, mapActions } from 'vuex'
import Cropper from 'cropperjs';
import {appRootUrl} from "../../../main";
import {resetError} from "../../../store/modules/project/show/actions";
import ProjectOrgAffiliations from "../../../components/project/view/edit/ProjectOrgAffiliations.vue";

export default {
  inject: [ 'parentValidator' ],

  components: {
    VueGoogleMap,
    VueLoader,
    ProjectOrgAffiliations,
  },

  data() {
    return {
      isPictureLoaded: false,
      isLogoLoaded: false,
      cropper: {},
      logoCropper: {},
      destination: null,
      imageTest: {},
      imageTestLogo: {},
      isImageLogo : false, /*Just to differentiate between banner and logo image; we have used this flag*/
      uploadFieldReady: true,
      uploadInProgressLogo: false,
      uploadInProgressBanner: false,
      uploadIsLoading: false,
      fdProfile: new FormData(),
      fdBanner: new FormData(),
      project: null,
      itemVal: this.values,
      itemSave: null,
      currentLogoImage: null,
      currentBannerImage: null,
      oldLogoImage: '',
      oldBannerImage: '',
      organizations: [],
      isDraft: false,
      tabNameValue : this.tabName,
      currentLogoImageToCrop: '',
      currentBannerImageToCrop: '',
      orgRequests: [],
    }
  },

  props: {
    /**
     * @var {String} tabName
     */
    tabName : {
      type: String,
      default: () => {
        return ''
      }
    },
    /**
     * @var {Function} handleSubmit
     */
    handleSubmit: {
      type: Function,
      required: true
    },

    /**
     * @var {Function} handleUpdateField
     */
    handleUpdateField: {
      type: Function,
      required: true
    },

    /**
     * @var {Object} values
     */
    values: {
      type: Object,
      required: true
    },

    /**
     * @var {Object} projectErrors
     */
    projectErrors: {
      type: Object,
      default: () => {}
    },

    /**
     * @var {Object} initialValues
     */
    initialValues: {
      type: Object,
      default: () => {}
    },

    /**
     * @var {String} publishButtonTitle
     */
    publishButtonTitle: {
      type: String,
      default: () => {
        return 'Publish'
      }
    },

    /**
     * @var {String} submitButtonTitle
     */
    draftButtonTitle: {
      type: String,
      default: () => {
        return 'Draft'
      }
    },

    /**
     * @var {Boolean} isLoading
     */
    isLoading: {
      type: Boolean,
      default: () => {
        return false
      }
    },

    /**
     * @var {String} buttonClass
     */
    buttonClass: {
      type: String,
      default: () => {
        return ''
      }
    }
  },

  computed: {
    ...mapGetters({
      isLoadingUpload: 'project/show/isLoading',
      isLoadingUpdate: 'project/update/isLoading',
      error: 'project/show/error',

      organizationsError: 'organization/list/organizationsError',
      isOrganizationsLoading: 'organization/list/isOrganizationsLoading',
      organizationsList: 'organization/list/organizations',
    }),

    // eslint-disable-next-line
    item: {
      get() {
        return this.itemVal
      },
      set(data) {
        this.itemVal = data
      }
    },

    selectedOrganizations: {
      get() {
        if (this.item) {
          return [...this.itemVal.organizationProjects.map((op) => op.organization)]
        } else {
          return null
        }
      },
      set(data) {
        // console.log(data)
        this.itemVal.organizationProjects = data
      }
    },

    /**
     * @function updateUrlField
     * @returns {boolean}
     */
    updateURLField() {
      //Strip all special characters except and replace with hyphen
      const special_char_regex = /(\s+|\/|\\|\[|\]|\{|\}|\,|\<|\>|\:|\;|\"|\.|\?|\-|\=|\+|\*|\&|\^|\%|\#|\@|\`|\'|\||\~|\!|\$|\*|\(|\)|\_|\$)+/g;
      const non_ascii_regex = /[\x00-\x08\x0E-\x1F\x7F-\uFFFF]+/g;
      const replace_char = '-'
      this.item.urlField = '';

      // If the name is filled, the url is created
      // - basically we replace all special characters and non-ascii values with a hyphen
      if( this.item.name && this.item.name  !== '') {
        // Non-ascii characters (e.g. emoji etc...) are replaced followed by all special character
        let sanitisedUrl = this.item.name.toLowerCase()
        sanitisedUrl = sanitisedUrl.replace(special_char_regex, replace_char).replace(non_ascii_regex, replace_char)
        // If the URL ends with a hyphen, it is also removed
        if (sanitisedUrl.endsWith('-')) {
          sanitisedUrl = sanitisedUrl.slice(0, -1)
        }
        // Sanitised URL is set <safe to have only hyphen in the url>
        this.item.urlField = appRootUrl +'/projects/' + sanitisedUrl
      }
      return true;
    },

    violations () {
      return this.projectErrors || {}
    },

    // // Computed property for Project sciStarterStatus => HasSciStarterProject|DoesNotHaveProject
    // // Translate integer into boolean for toggle's v-model
    // sciStarterStatus: {
    //    get() {
    //      if (this.item.sciStarterID) { // if it has a value (is not null)
    //        return true
    //      } else {
    //        return false
    //      }
    //    },
    //    set(data) {
    //      if (data) {
    //        this.item.sciStarterID = 0 // set to 0 upon toggle initially
    //      } else {
    //        this.item.sciStarterID = null
    //      }
    //    },
    // }

  },

  methods: {
    ...mapActions({
      newUpload: 'project/show/newUpload',
      uploadInProgress: 'project/show/uploadInProgress',
      uploadFile: 'project/show/uploadFile',
      resetError: 'project/show/resetError',
      setErrorShow: 'project/show/setError',
      setErrorCreate: 'project/create/setError',
      getOrganizations: 'organization/list/getOrganizations',
      createSciStarterProject: 'project/show/createSciStarterProject'
    }),

    /**
     * @function uploadBannerClickToOpenPopUp
     * @param event
     * @param type
     * @returns {void}
     */

    uploadBannerClickToOpenPopUp( event, type, modalName, ref ) {
      event.stopPropagation();
      event.preventDefault();
      if( type !== '' ) {
        this.isImageLogo = true;
        this.$root.$emit('bv::show::modal', modalName );
      } else {
        this.isImageLogo = false;
        this.$root.$emit('bv::show::modal', modalName );
      }
      setTimeout(() => {
        this.onUploadClick(this.$refs[ref]);
      });
    },

    /**
     * To reset all the local state related to image and pop-up dialog
     * @function onNoClick
     * @returns {void}
     */
    onNoClick( bModalRef, fileInputRef ) {
      this.currentBannerImageToCrop = '';
      this.currentLogoImageToCrop = '';
      this.isPictureLoaded = false;
      this.isLogoLoaded = false;
      this.isImageLogo = false;
      this.$refs[fileInputRef].value = null;
      this.$refs[bModalRef].hide();


    },

    /**
     * @function isInvalid
     * @param key
     * @returns {boolean|*}
     */
    isInvalid (key) {
      return Object.keys(this.violations).length > 0 && this.violations[key]
    },

    /**
     * @function onFilePickedProjectBanner
     * @param files
     * @returns {void|*}
     */
    onFilePickedProjectBanner(files) {

      if( this.cropper &&  this.cropper.canvas) {
        this.isPictureLoaded = false;
      }
      this.destroyCropper( 'project_banner' );

      const _files = files.target.files || files.dataTransfer.files
      const fileTypesAllowed = ['jpg', 'jpeg', 'png']

      if (!_files.length)
        return

      const filename = _files[0].name

      if (filename.lastIndexOf('.') <= 0 || fileTypesAllowed.indexOf(filename.split('.').pop().toLowerCase()) == -1) {
        return this.setErrorShow('Please select a valid file')
      }

      if (_files[0].size > 100000 * 10 * 10) {
        return this.setErrorShow('Maximum filesize is 10M')
      }

      this.newUpload().then(() => {
        const fileReader = new FileReader();

        fileReader.onload = (e) => {
          this.currentBannerImageToCrop = e.target.result;
          this.isPictureLoaded = true;
          setTimeout(() => {
            this.imageTest = this.$refs.imageTest;
            this.setCropperData(this.imageTest, 36/9 , 440, 110, 'cropper' )
          }, 250);
        };
        fileReader.readAsDataURL(_files[0]);
      })
    },

    /**
     * @function onFilePickedProjectLogo
     * @param files
     * @returns {void|*}
     */
    onFilePickedProjectLogo(files) {

      if( this.logoCropper &&  this.logoCropper.canvas) {
        this.isLogoLoaded = false;
      }
      this.destroyCropper( 'project_image' );

      const _files = files.target.files || files.dataTransfer.files
      const fileTypesAllowed = ['jpg', 'jpeg', 'png']

      if (!_files.length)
        return

      const filename = _files[0].name

      if (filename.lastIndexOf('.') <= 0 || fileTypesAllowed.indexOf(filename.split('.').pop().toLowerCase()) == -1) {
        return this.setErrorShow('Please select a valid file')
      }

      if (_files[0].size > 100000 * 10 * 10) {
        return this.setErrorShow('Maximum filesize is 10M')
      }

      this.newUpload().then(() => {
        const fileReader = new FileReader();

        fileReader.onload = (e) => {
          this.currentLogoImageToCrop = e.target.result;
          this.isLogoLoaded = true;
          setTimeout(() => {
            this.imageTestLogo = this.$refs.imageTestLogo;
            this.setCropperData(this.imageTestLogo, 1 , 100, 100, 'logoCropper' )
          }, 250);
        };
        fileReader.readAsDataURL(_files[0]);
      })
    },

    setCropperData (imageRef, aspectRation, width, height , cropperName ) {
      this[cropperName] = new Cropper( imageRef,  {
        zoomable: false,
        scalable: false,
        highlight: false,
        aspectRatio: aspectRation,
        ready: ( e ) => {
          var imageData = this[cropperName].getImageData();
          this[cropperName].options.minCropBoxWidth = imageData.width ;
          this[cropperName].options.minCropBoxHeight = imageData.height;
          //this.cropper.limitCropBox(true, false);

          this[cropperName].setCropBoxData({
            width: width ,
            height: height
          })
        },
        dragCrop: false
      });
    },

    /**
     * @function onCropImage
     * @returns {void|*}
     */
    onCropImage( ref, cropperName ){
      this.destination = null;
      const canvas = this[cropperName].getCroppedCanvas();
      this.destination = canvas.toDataURL("image/png");
      if( this.isImageLogo ) {
        this.currentLogoImage = this.destination;
        this.uploadInProgressLogo = true;
      } else {
        this.currentBannerImage = this.destination;
        this.uploadInProgressBanner = true;
      }
      this.$refs[ref].hide();

    },
    /**
     * @function onUploadProjectLogo
     */
    onUploadProjectLogo( cropperName ) {
      const canvas = this[cropperName].getCroppedCanvas();

      canvas.toBlob((blob) => {
        this.fdProfile.append('file', blob, 'logo.png');
        this.fdProfile.append('actionType', 'project_image');

        this.uploadFile({fd: this.fdProfile, isCreateRes: true, currentId: ''}).then((data) => {
          this.oldLogoImage = this.values.picture;
          this.$emit('item-changed', {picture: data});
          this.uploadInProgressLogo = false;
        })
      });
    },

    /**
     * @function onUploadProjectBanner
     */
    onUploadProjectBanner( cropperName ) {
      const canvas = this[cropperName].getCroppedCanvas();

      canvas.toBlob((blob) => {
        this.fdBanner.append('file', blob, 'banner.png');
        this.fdBanner.append('actionType', 'project_banner');

        this.uploadFile({fd: this.fdBanner, isCreateRes: true, currentId: '' }).then((data) => {

          if (typeof data == 'undefined' || data == null || data == false) {
            this.setErrorCreate('Upload maximum size exceeded!');
            return
          }

          this.oldBannerImage = this.values.bannerPicture;
          this.$emit('item-changed', { bannerPicture: data });

          this.uploadInProgressBanner = false
        })
      })
    },

    /**
     * @function onCancelProjectLogoImage
     */
    onCancelProjectLogoImage() {
      this.uploadInProgressLogo = false
      this.currentLogoImage = null
      let oldPicture = null

      if (this.oldLogoImage && this.oldLogoImage.picture && this.oldLogoImage.picture.path) {
        this.currentLogoImage = this.oldLogoImage.picture.path
        oldPicture = this.oldLogoImage.picture['@id']
      }

      //this.$refs.fileInputProjectLogo.value = null
      this.isLogoLoaded = false;
      this.$emit('item-changed', { picture: this.oldLogoImage })
    },

    /**
     * @function onCancelProjectBannerImage
     */
    onCancelProjectBannerImage() {
      this.uploadInProgressBanner = false
      this.currentBannerImage = null
      let oldPicture = null

      if (this.item && this.item.bannerPicture && this.item.bannerPicture.path) {
        this.currentBannerImage = this.item.bannerPicture.path
      }

      if (this.oldBannerImage && this.oldBannerImage.picture && this.oldBannerImage.picture.path) {
        this.currentBannerImage = this.oldBannerImage.picture.path
        oldPicture = this.oldLogoImage.picture['@id']
      }

      //this.$refs.fileInputProjectBanner.value = null
      this.isPictureLoaded = false;
      this.$emit('item-changed', { bannerPicture: oldPicture })
    },

    /**
     * @function onRemoveProjectBannerImage
     */
    onRemoveProjectBannerImage() {
      this.currentBannerImage = null;
      this.uploadInProgressBanner = false;
      //this.$refs.fileInputProjectBanner.value = null
      this.$emit('item-changed', { bannerPicture: null })
    },

    /**
     * @function removeLogo
     */
    onRemoveProjectLogoImage() {
      this.currentLogoImage = null;
      this.uploadInProgressLogo = false;
      //this.$refs.fileInputProjectLogo.value = null
      this.$emit('item-changed', { picture: null })

    },

    destroyCropper( cropperToDestroy ) {
      if( cropperToDestroy === 'project_banner' && this.cropper &&  this.cropper.canvas ) {
        this.cropper.destroy();
      }
      if( cropperToDestroy === 'project_image' && this.logoCropper && this.logoCropper.canvas ) {
        this.logoCropper.destroy();
      }
    },

    /**
     * @function onUploadClick
     */
    onUploadClick(refFileInput) {
      //this.destroyCropper();
      this.setErrorCreate();
      refFileInput.click();

    },

    newAffiliations(newOrgs) {
      this.orgRequests = newOrgs
    },

    /**
     * @function submitForm - submit the form to the API for saving
     * @param {Object} item
     */
    submitForm(item) {
      if (this.uploadInProgressLogo) {
        window.scrollTo(0,0);
        this.$bvToast.show('logo-save-toast');
        return;
      }
      if (this.uploadInProgressBanner) {
        window.scrollTo(0,0);
        this.$bvToast.show('banner-save-toast');
        return;
      }

      // // Allow projects to be created without an image
      // //  - when saving as draft
      // //  - when editing a draft/already published project
      // if ((item.hasOwnProperty('id') && !this.isDraft) || (!item.hasOwnProperty('id'))) {
      //   if ((this.currentBannerImage === null && this.currentLogoImage === null)) {
      //     window.scrollTo(0,0);
      //     this.$bvToast.show('image-banner-required-toast');
      //     return;
      //   }
      // }

      // Send the draft\publish button click
      // console.log(this.currentBannerImage, this.currentLogoImage)
      this.handleSubmit(item, this.orgRequests, this.isDraft);
    }
  },

  created () {

    this.$validator = this.parentValidator

    if (this.values) {
      this.currentLogoImage = this.values && this.values.picture && this.values.picture.path ? this.values.picture.path : null
      this.currentBannerImage = this.values && this.values.bannerPicture && this.values.bannerPicture.path ? this.values.bannerPicture.path : null
    }

    // Get the Organizations for the picklist
    // this is done asynchronously
    this.getOrganizations();

  }


}
