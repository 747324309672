<template>

  <b-modal id="add-location-modal" title="Add Locations to Project"
           size="xl" ok-title="Submit"
           @ok="handleSubmit"
           @hidden="resetAddLocationModal"
           ref="addLocationModal"
           no-close-on-backdrop
           centered>

<!--    <div id="add-location-modal" class="modal-body">-->

    <b-container class="mx-auto" fluid>

      <b-tabs content-class="mx-auto"
              v-model="tabIndex"
              nav-class="navClass"
              card
              lazy>
        <b-tab title="Add a Location" active>

          <b-card>
            <h4 slot="header" class="mb-0"> Create a Location</h4>
            <b-row class="justify-content-lg-center">
              <b-col col lg="12">
                <vue-google-map :item="item"
                                :latitude="item && item.latitude"
                                :longitude="item && item.longitude"
                                :custom-marker="false"
                                :zoom-map-to-markers="11"
                                :show-locate-address="true"
                                :zoom-val="4"
                                :show-address-flag="true"
                                showNameField
                                class="project-create-edit"
                />
              </b-col>
            </b-row>
            <div v-if="errorCheckLocationExists">{{ errorCheckLocationExists }}</div>

          </b-card>

        </b-tab>

        <b-tab title="Upload Locations from .CSV" :key="componentKey">

          <b-row class="justify-content-lg-center">
            <b-col col lg="12">

              <b-form-group>

                <template>
                  <section>
                    <div class="row">
                      <b-card class="col-8 offset-2">

                        <h4 class="mb-4">Upload Project Locations:</h4>

                        <b-button class="btn btn-sm dropdown-toggle" @click="collapse = !collapse" variant="secondary">
                          <font-awesome-icon icon="info" />
                          &emsp;Instructions
                        </b-button><br>
                        <b-card v-show="collapse" bg-variant="light">
                            <span>
                              1. Prepare a .csv file containing your locations<br>
                              2. Ensure the file has the these columns - location name, latitude, longitude<br>
                              3. Click 'Browse' and select your file<br>
                              4. Click 'Upload File'<br>
                              5. Pick and Match the Available columns from the file to the Required columns<br>
                              6. Verify that the Locations are accurate<br>
                              7. Click 'Submit' to add the displayed Locations to this Project<br><br>
                              <i>Note: Invalid Locations and Locations already added to the Project are removed automatically.</i>
                            </span>
                        </b-card>
                        <br>

                        <vue-csv-import v-model="csv"
                                        :map-fields=mappedFields
                                        autoMatchFields
                                        autoMatchIgnoreCase
                                        checkboxClass="form-check-input"
                                        buttonClass="btn btn-primary"
                                        inputClass="form-control-file"
                                        tableClass="table table-sm table-bordered w-auto"
                                        tableSelectClass="custom-select"
                                        loadBtnText="Upload File">

                          <template slot="error">
                            File type is invalid
                          </template>

    <!--                      <template slot="hasHeaders" slot-scope="{headers, toggle}">-->
    <!--                        <label>-->
    <!--                          <input type="checkbox" id="hasHeaders" :value="headers" @change="toggle">-->
    <!--                          My File has Headers-->
    <!--                        </label>-->
    <!--                      </template>-->

                          <template slot="thead">
                            <tr>
                              <th>Required Columns</th>
                              <th>Available Columns</th>
                            </tr>
                          </template>

                        </vue-csv-import>
                      </b-card>
                    </div>
                  </section>
                </template>

                <b-container v-if="tableCSV" class="mx-auto" style="width: 72%">
                  <label>
                    <b>File Locations: </b>
                    <b v-if="this.tableCSV.length > 0">&nbsp&nbsp(Valid: {{ this.tableCSV.length }})</b>
<!--                    <b v-if="this.tableExisting.length === 1">&nbsp&nbsp(Already Added: {{ this.tableExisting.length }} Location)</b>-->
                    <b v-if="this.tableExisting.length > 0">&nbsp&nbsp(Already Added: {{ this.tableExisting.length }})</b>
<!--                    <b v-if="this.numDuplicates === 1">&nbsp&nbsp(Duplicates: {{ this.numDuplicates }} Location)</b>-->
                    <b v-if="this.numDuplicates > 0">&nbsp&nbsp(Duplicates: {{ this.numDuplicates }})</b>
<!--                    <b v-if="this.tableInvalid.length === 1">&nbsp&nbsp(Invalid Lat/Lon: {{ this.tableInvalid.length }} Location)</b>-->
                    <b v-if="this.tableInvalid.length > 0">&nbsp&nbsp(Invalid Lat/Lon: {{ this.tableInvalid.length }})</b>
                  </label>
                  <b-row class="mx-auto" align-h="between" no-gutters>

                    <b-col sm="2" md="3" class="my-1">
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        aria-controls="my-table"
                        prev-text="Prev"
                        next-text="Next"
                        align="fill"
                      ></b-pagination>
                    </b-col>

                    <b-col sm="2" md="3" class="my-1">
                      <b-form-group label="Per Page" label-align="right"
                                    label-cols-sm="6" label-cols-md="5"
                                    label-for="perPageSelect" class="mb-0">
                        <b-form-select
                          v-model="perPage"
                          id="perPageSelect"
                          :options="pageOptions"
                        ></b-form-select>
                      </b-form-group>

                    </b-col>

                  </b-row>

                  <b-table
                           hover
                           :items="tableCSV"
                           :fields="addLocationFields"
                           :sort-by="addLocationFields[1].toString()"
                           show-empty
                           fixed
                           sort-icon-left
                           :total-rows="totalRows"
                           :per-page="perPage"
                           :current-page="currentPage"
                           responsive="md"
                           outlined
                           bordered
                           small
                           pagination>

                  </b-table>

                  <b-row class="mx-auto">
                    <b-col sm="5" md="6" class="my-1">
                      Showing {{ fromItem }} to {{ toItem }} of {{ tableCSV.length }} entries
                    </b-col>
                  </b-row>

                </b-container>

              </b-form-group>

            </b-col>
          </b-row>

        </b-tab>
      </b-tabs>

    </b-container>
<!--    </div>-->

  </b-modal>

</template>

<script>

  import VueGoogleMap from './GoogleMap'
  import { mapActions, mapGetters } from 'vuex'

  const tabs = [
    { name: 'Add a Location', active: true, hash: '#single', icon: 'home', cssClass: 'navClass' },
    { name: 'Upload Location from a .CSV File', active: false, hash: '#multiple', icon: 'binoculars', cssClass: 'navClass' },
  ]

  export default {

    components: {
      VueGoogleMap,
    },

    provide () {
      // Provide the parent validator for the GoogleMap component
      return {
        parentValidator: this.$validator
      }
    },

    data () {
      return {
        componentKey: 0,

        csv: [],
        tableCSV: null,
        tableExisting: null,
        tableInvalid: null,
        numDuplicates: 0,

        collapse: true,
        // mappedFields:['name', 'latitude', 'longitude'],
        mappedFields: {'name': 'Location Name', 'latitude': 'Latitude', 'longitude': 'Longitude'},
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,

        tabIndex: 1,

        addLocationFields: [
          { key: 'name', label: 'Location Name', sortable: true, tdClass: 'text-center', thClass: 'text-center' },
          { key: 'latitude', label: 'Latitude', sortable: true, tdClass: 'text-right', thClass: 'text-center' },
          { key: 'longitude', label: 'Longitude', sortable: true, tdClass: 'text-right', thClass: 'text-center' },
        ],
        item: {
          // 'name': 'LocationTest1',
          // 'latitude': 40.55011,
          // 'longitude': -105.07269
        },

      }
    },

    created () {
      this.$validator.validateAll()
    },

    watch: {

      csv (csv) {
        if(!csv){
          return
        }

        let tempArray = []
        csv.forEach((loc) => {
          tempArray.push(loc.name.concat(',').concat(loc.latitude).concat(',').concat(loc.longitude))
        })
        // Duplicates are removed & counter is set
        tempArray = [... new Set(tempArray)]
        this.numDuplicates = csv.length - tempArray.length

        // Concatenated values are converted to object array
        this.tableCSV = []
        this.tableInvalid = []
        this.tableExisting = []
        tempArray.forEach((loc) => {
          let strParts = loc.split(",")

          // Check latitude and longitude are numeric
          if (!isNaN(strParts[1]) && !isNaN(strParts[2])) {

            let loc = {}
            loc.name = strParts[0]

            const latVal = /^-?([0-8]?[0-9]|90)(\.)?[0-9]{1,6}$/
            const lonVal = /^-?((1?[0-7]?|[0-9]?)[0-9]|180)(\.)?[0-9]{1,6}$/

            if (latVal.test(strParts[1]) && lonVal.test(strParts[2])) {

              loc.latitude = parseFloat(strParts[1])
              loc.longitude = parseFloat(strParts[2])

              // If location already exists, skip
              this.checkProjectLocationExists(loc).then(data => {
                if (data.length === 0) {
                  this.tableCSV.push(loc)
                  // this.tableCSV.push({"name": strParts[0], "latitude": parseFloat(strParts[1]), "longitude": parseFloat(strParts[2]) })
                } else {
                  // Location already exists
                  this.tableExisting.push({"name": strParts[0], "latitude": strParts[1], "longitude": strParts[2] })
                }
              })
            } else {
              // Invalid lat long
              this.tableInvalid.push({"name": strParts[0], "latitude": strParts[1], "longitude": strParts[2] })
            }
          }
        })

        this.perPage = 10
        this.currentPage = 1
        this.totalRows = this.tableCSV.length

        // Instructions accordion is collapsed
        this.collapse = false

      },

    },

    computed: {
      ...mapGetters({
        errorCheckLocationExists: 'project/show/errorCheckLocationExists'
      }),

      fromItem () {
        return this.tableCSV.length > 0 ? (this.currentPage - 1) * this.perPage + 1 : 0
      },

      toItem () {
        return this.tableCSV.length < (this.currentPage * this.perPage) ? this.tableCSV.length : this.currentPage * this.perPage
      }

    },

    methods: {
      ...mapActions({
        checkProjectLocationExists: 'project/show/checkProjectLocationExists'
      }),

      handleSubmit (evt) {
        evt.preventDefault()

        // Submit is bubbled to parent for handling
        if (this.tabIndex > 0) {

          if (this.tableCSV && this.tableCSV.length === 0) {
            this.$bvModal.msgBoxOk('Uploaded .CSV file has no valid Locations', {
              title: 'Select a different .CSV File',
              size: 'sm',
              okVariant: 'info',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
            this.forceRender()
          }

          if (this.tableCSV && this.tableCSV.length > 0) {
              this.$emit('add-locations', this.tableCSV)
          } else {
            this.$bvModal.msgBoxOk('Select and Upload a .CSV file and then click "Submit"', {
              title: 'No Data Uploaded',
              size: 'sm',
              okVariant: 'info',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
          }

        } else {

          // On button press run validation
          this.$validator.validateAll().then(result => {

              if (result) {
                // Check the location already exists
                this.checkProjectLocationExists(this.item).then(data =>{
                  if (data.length > 0) {
                    this.$bvModal.msgBoxOk('Location Already Added to Project', {
                      title: 'Try Adding a Different Location',
                      size: 'sm',
                      okVariant: 'info',
                      headerClass: 'p-2 border-bottom-0',
                      footerClass: 'p-2 border-top-0',
                      centered: true
                    })
                  } else {
                    this.$emit('add-locations', [this.item])
                    return true
                  }
                })
              } else {
                // Errors present, validation failed
              }
            })
        }
      },

      resetAddLocationModal() {
        this.item = {}
        this.tableCSV = null
        this.csv = null
        this.collapse = true
        this.currentPage = 1
        this.perPage = 20
        this.tabIndex = 1
      },

      forceRender() {
        // Reload the vue-csv-import plugin
        this.resetAddLocationModal()
        this.componentKey += 1
      }

    },


  }
</script>
