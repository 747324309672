<template>
  <div v-if="userSession && (userSession['isAirtable'] && !userSession['isAirtableExpired'])">
    <span v-if="basesError">Bases Not Fetched</span>
    <b-card v-else class="col-6 mb-2" border-variant="light" bg-variant="light">
      <b><u>Data Transfer:</u></b>
      <b-container fluid>
        <div v-if="isLoadingBases">
          <small>Fetching data from Airtable...</small>
          <i class="fa fa fa-spin fa-spinner ml-2"></i>
        </div>
        <b-form-group v-show="bases"
                      id="bases"
                      label-cols-lg="4"
                      content-cols-lg="8"
                      label="Bases:"
                      label-class="font-weight-bold"
                      label-for="input-bases">
          <b-form-select id="input-bases"
                         v-model="selectedBase"
                         :options="getAirtableBases">
          </b-form-select>
        </b-form-group>
        <b-form-group v-show="selectedBase" label="Select Table Type: " label-class="font-weight-bold">
          <b-form-radio v-model="toggleTableType" value="new" inline>Send Data to a New Table</b-form-radio>
          <b-form-radio v-model="toggleTableType" value="existing" inline>Send Data to an Existing Table</b-form-radio>
        </b-form-group>

        <b-form-group v-show="toggleTableType" v-if="toggleTableType === 'new'"
                      id="table-name"
                      label-cols-lg="4"
                      content-cols-lg="8"
                      label="Table Name:"
                      label-class="font-weight-bold pt-0"
                      label-for="input-name"
                      description="Table to be created at Airtable">
          <b-form-input id="input-name"
                        name="tablename"
                        v-model="newTableName"
                        placeholder="Enter a table name"
                        v-validate="'required|min:5|max:128'"
                        data-vv-as="Table Name"
                        :class="{ 'is-invalid': errors.has('tablename') }"
                        aria-describedby="table-name-input-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="table-name-input-live-feedback">
            {{ errors.first("tablename") }}
          </b-form-invalid-feedback>
        </b-form-group>

        <div v-if="isLoadingTables">
          <small>Fetching data from Airtable...</small>
          <i class="fa fa fa-spin fa-spinner ml-2"></i>
        </div>
        <b-form-group v-else v-show="selectedBase && tables && toggleTableType === 'existing'"
                      id="tables"
                      label-cols-lg="4"
                      content-cols-lg="8"
                      label="Tables:"
                      label-class="font-weight-bold"
                      label-for="input-tables">
          <b-form-select id="input-tables"
                         v-model="selectedTable"
                         :options="getAirtableTables">
          </b-form-select>
        </b-form-group>

        <b-button v-show="selectedBase && (newTableName || selectedTable)"
                  class="primary"
                  @click="onClickSend">Send Data
        </b-button>
      </b-container>
    </b-card>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data() {
    return {
      selectedBase: null,
      toggleTableType: null,
      newTableName: '',
      selectedTable: null,
    };
  },

  created() {
    if (this.userSession && (this.userSession['isAirtable'] && !this.userSession['isAirtableExpired'])) {
      // Bases are fetched
      this.getBases()
    }
  },

  watch: {
    toggleTableType(newValue) {
      if (newValue && newValue === 'existing') {
        this.getTables(this.selectedBase)
      } else {
        this.selectedTable = null
      }
    }
  },

  computed: {
    ...mapGetters({
      userSession: 'user/profile/sessionData',
      isLoadingBases: "integration_airtable/bases/isLoadingBases",
      bases: "integration_airtable/bases/bases",
      basesError: "integration_airtable/bases/basesError",
      isLoadingTables: "integration_airtable/bases/isLoadingTables",
      tables: "integration_airtable/bases/tables",
      tablesError: "integration_airtable/bases/tablesError"
    }),
    getAirtableBases() {
      if (this.bases) {
        let arrBases = []
        arrBases.push({value: null, text: "Pick a Base...", disabled: true})
        Object.values(this.bases).forEach(b => {
          arrBases.push({value: b.id, text: b.name})
        })
        return arrBases
      } else {
        return []
      }
    },
    getAirtableTables() {
      if (this.tables) {
        let arrTables = []
        arrTables.push({value: null, text: "Pick a Table...", disabled: true})
        Object.values(this.tables).forEach(b => {
          arrTables.push({value: b.id, text: b.name})
        })
        return arrTables
      } else {
        return []
      }
    },
  },
  methods: {
    ...mapActions({
      saveProjectId: "integration_airtable/authorize/saveProjectId",
      getBases: "integration_airtable/bases/get",
      getTables: "integration_airtable/bases/getTables",
      createHistory: "integration_airtable/data/createHistory",
    }),
    onClickSend() {
      let data = {
        airtableBaseId: this.selectedBase,
        airtableTableId: this.selectedTable,
        airtableTableName: this.newTableName
      }
      this.createHistory(data).then(data => {
        this.$root.$emit('refreshHistory', true);
      })
    },
  }
}
</script>

<style lang="scss">

</style>
