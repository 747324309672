<template>
  <div class="">
    <div class="row justify-content-center mb-2">
      <h3>Indicators</h3>
      <b-btn class="justify-end"
             variant="secondary"
             size="sm"
             alt="Reload Indicators" title="Reload Indicators"
             @click="getScorecardsData">
        <font-awesome-icon icon="sync"/>
      </b-btn>
    </div>
    <div v-if="!isLoading">
      <div v-if="indicators.length > 0">
        <div class="row text-center align-items-center justify-content-center">
          <div v-for="score in indicators" :key="score.id" class="col-lg-2">
            <div class="row">
              <div class="col-12">
                <p class="m-0 p-0">{{ score.title }}</p>
                <Donut class="justify-content-center mb-1"
                       :score-id="score.id"
                       :score-name="score.title"
                       :score-value="score.value"
                       :score-data="score.data"
                       @view="handleViewDonut"></Donut>
              </div>
            </div>
            <b-row class="text-center" align-v="center">
              <div class="col-1">
                <OverlayButton :indicator-id="score.id"
                               :button-type="'delete'"
                               @overlayClicked="handleOverlayClick"></OverlayButton>
              </div>
              <div class="col-10">
                <h6 v-if="score['error']" class="mt-1">{{ score.error }}</h6>
                <h5 v-else class="mt-1">{{ score.label + ': ' + score.value }}</h5>
              </div>
            </b-row>
          </div>
          <div v-if="indicators.length < 5" class="col-lg-2 p-0">
            <b-button class="btn btn-secondary btn-md py-0" @click="$bvModal.show('add-scorecard')">
              Add Indicator
            </b-button>
          </div>
        </div>
      </div>
      <!-- No Scorecards, Show Example Indicator and Allow Add -->
      <div v-else>
        <b-row class="text-center justify-content-center mb-0 pb-0" align-v="center">
          <div class="col-lg-2">
            <p class="m-0 p-0">Example Indicator</p>
            <Donut class="justify-content-center mb-1"
                   :score-id="example.id"
                   :score-name="example.name"
                   :score-value="example.value"
                   :score-data="example.data"></Donut>
            <h5 class="mt-1">{{ example.label + ': ' + example.value }}</h5>
          </div>
          <div class="col-lg-offset-1 col-lg-3 p-0">
            <p>Thinking about adding an Indicator?</p>
            <b-button class="btn btn-secondary btn-md py-0" @click="$bvModal.show('add-scorecard')">
              Add Indicator
            </b-button>
          </div>
        </b-row>
      </div>
    </div>
    <AddIndicatorModal></AddIndicatorModal>
    <ViewIndicatorModal :scorecard-data="viewScorecardData"></ViewIndicatorModal>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import AddIndicatorModal from "./AddIndicatorModal.vue";
import Donut from "./Donut.vue";
import OverlayButton from "./OverlayButton.vue";
import ViewIndicatorModal from "./ViewIndicatorModal.vue";

export default {
  components: {
    Donut,
    AddIndicatorModal,
    OverlayButton,
    ViewIndicatorModal,
  },
  data() {
    return {
      example: {
        id: null,
        pct: "40%",
        label: "Fair",
        name: "Example Indicator",
        value: "1000",
        data: [
          {
            name: "fill",
            y: 60
          },
          {
            name: "empty",
            y: 40
          }
        ]
      },
      viewScorecardId: null,
    };
  },
  computed: {
    ...mapGetters({
      organizationVuex: "organization/get/organization",
      isLoading: "organization/scorecards/isLoading",
      scorecards: "organization/scorecards/scorecards",
      indicators: "organization/scorecards/indicators",
    }),
    viewScorecardData() {
      if (this.viewScorecardId && this.scorecards && this.indicators.length > 0) {
        let score = this.scorecards.find(s => s.id === this.viewScorecardId)
        let indicator = this.indicators.find(i => i.id === this.viewScorecardId)
        return {
          title: score.title,
          desc: score.description,
          project: score.datasheetRecord.datasheet.project.name,
          datasheet: score.datasheetRecord.datasheet.name,
          record: score.datasheetRecord.label,
          dataType: score.dataType,
          data: indicator.data,
          label: indicator.label,
          value: indicator.value,
          error: indicator.error
        }
      } else {
        return null
      }
    },
  },
  methods: {
    ...mapActions({
      getScorecardsData: "organization/scorecards/get",
      deleteScorecard: "organization/scorecards/deleteScorecard",
    }),
    handleOverlayClick(id) {
      this.boxTwo = ''
      this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this Indicator.', {
        title: 'Are you sure?',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if (value) {
            this.deleteScorecard(id)
          }
        })
        .catch(err => {
          // An error occurred
        })
    },
    handleViewDonut(id) {
      this.viewScorecardId = id
      this.$bvModal.show('view-scorecard')
    }
  }
}
</script>
