<template>
  <div id="delete" ref="delete">
    <h2 v-if="showProfileLink">Deleting Your Account</h2>
    <p>
      At CitSci – we respect your privacy. You may delete your CitSci account by asking us to remove your account and
      any personal information tied to you.
      When making this request, please be advised that our policy is to remove your personal account details.
      The scientific data you have contributed will be retained as stated in our
      <b-link to="/terms#data">Data Use Policy</b-link>
      <i class="fas fa-external-link-alt fa-xs"></i>
      and the
      <b-link href="https://creativecommons.org/licenses/by/3.0/deed.en_US"
              target="_blank">Creative Commons CC BY 3.0 License
      </b-link>
      <i class="fas fa-external-link-alt fa-xs"></i>
      .
      If you wish for scientific data you have submitted to be removed along with your account details when we delete
      your account, you may ask us to do so
      <span v-if="showProfileLink">
      by visiting your
      <b-link to="/users/profile" target="_blank">user profile
      </b-link>
      <i class="fas fa-external-link-alt fa-xs"></i> and clicking 'Delete My Account'
      </span>
      <span v-else>below</span>.
    </p>
  </div>
</template>
<script>

export default {
  props: {
    showProfileLink: {
      type: Boolean,
      default: true
    }
  }
}
</script>
