// import DateTIme from 'App/Entity/DateTime'

/**
 * @function addZeroAHead - add 0 in from of minute or hours
 * @param minutes
 * @returns {*}
 */
export const addZeroAHead = (numberWithoutZeroLead) => {
  if (numberWithoutZeroLead) {
    if (numberWithoutZeroLead < 10) {
      return ('0' + numberWithoutZeroLead)
    } else {
      return numberWithoutZeroLead
    }
  }

  return '00'
}

/**
 * @function returnAMPM - get AM or PM by hour
 * @param hours
 * @returns {string}
 */
const getAMPM = (hours) => {
  if (hours) {
    if (hours < 12) {
      return 'AM'
    } else {
      return 'PM'
    }
  }

  return ''
}

const get12HoursFormat = (hours) => {
  if (hours) {
    if (hours <= 12) {
      return hours
    } else {
      return hours - 12
    }
  } else {
    return 0
  }
}

/**
 * @function displayDate - function that is formatting the date that we are receive from api into the M DD, YY format
 * @param {string} dateStr
 * @returns {string}
 */
export const displayDate = (dateStr) => {
  if (typeof dateStr == 'undefined') {
    return ''
  }

  const monthNames = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];
  let date = new Date(dateStr);
  let returnDate = (monthNames[date.getUTCMonth()] + ' ' + date.getUTCDate() + ', ' + date.getUTCFullYear())
  return returnDate
}

/**
 * @function displayObservationDateTime - function that is formatting the date that we are receive from api into the M DD, YY format time
 * @param {string} dateStr
 * @returns {string}
 */
export const displayObservationDateTime = (dateStr) => {
  if (typeof dateStr == 'undefined') {
    return ''
  }

  const monthNames = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];
  let date = new Date(dateStr)
  let returnDate = ((monthNames[date.getUTCMonth()]) + ' ' + date.getUTCDate() + ', ' + date.getFullYear() + ' ' + get12HoursFormat(date.getUTCHours()) + ':' + addZeroAHead(date.getUTCMinutes()) + ':' + addZeroAHead(date.getSeconds()) + ' ' + getAMPM(date.getUTCHours()))
  return returnDate
}

/**
 * @function displayCreatedAtDate - function that is formatting the date that we are receive from api into the M DD, YY format time
 * @param {string} dateStr
 * @returns {string}
 */
export const displayCreatedAtDate = (dateStr) => {
  if (typeof dateStr == 'undefined') {
    return ''
  }

  const monthNames = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];

  let date = new Date(dateStr)
  let returnDate = ((date.getUTCMonth() + 1) + '/' + date.getUTCDate() + '/' + date.getFullYear() + ' ' + addZeroAHead(get12HoursFormat(date.getHours())) + ':' + addZeroAHead(date.getUTCMinutes()) + ':' + addZeroAHead(date.getSeconds()) + ' ' + getAMPM(date.getHours()))
  return returnDate
}

/**
 * @function displayFormattedDate - function that is formatting the date that we are receive from api
 * @param {string} dateStr
 * @returns {string} formattedDate
 */
export const displayFormattedDate = (dateStr) => {

  if (typeof dateStr == 'undefined') {
    return ''
  }

  let formattedDate = ""
  let date = new Date(dateStr)

  if ((date.getUTCHours()==0) && (date.getMinutes()==0) && (date.getSeconds()==0)) {
    formattedDate = displayDate(date);
  } else {
    formattedDate = displayObservationDateTime(date);
  }

  return formattedDate
};

/**
 * @function dateForServer:
 * @param dateValue in DD/MM/YYYY h:mm A format
 * @returns {Date}
 */
export const dateForServer = ( dateValue ) => {
  let split = dateValue.split(' ');

  let date = split[0].split('/');
  let hour = split[1].split(':');
  let AMPM = split[2];

  if (AMPM.toLowerCase() === 'pm') {
    if (hour[0] === 12) {
      hour[0] = 0
    } else {
      hour[0] = parseInt(hour[0]) + 12
    }
  }

  return new Date (parseInt(date[2]), (parseInt(date[1]) - 1), parseInt(date[0]), parseInt(hour[0]), parseInt(hour[1]))
}
