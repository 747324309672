import SubmissionError from "../../../../error/SubmissionError";
import fetch from '../../../../utils/fetch';

const initialState = {
  upload: {
    // status
    // isUploading, success, error

    // '@id'
    // either server side uuid or initial id

    // blob
    // will be converted to appropriate file for upload

    // path
    // path for file

    // action_type
    // upload type

    // objectID
    // object that this file object is going to associate with
  },
  uploadTemplate: {
    status: null,
    error: null,
    '@id': null,
    blob: null,
    path: null,
    action_type: null,
  }
}

const FILE_STATUS = {
  IS_UPLOADING: "IS_UPLOADING",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
}

const ACTION_TYPES = {
  // currently supported action types by this store
  project_banner: {
    name: "project_banner",
    dbname: "bannerPicture",
    extensions: ["jpg", "jpeg", "png"]
  },
  project_image: {
    name: "project_image",
    dbname: "picture",
    extensions: ["jpg", "jpeg", "png"]
  },
  project_resource: {
    name: "project_resource",
    dbname: "file",
    extensions: [".jpg", ".jpeg", ".png", ".pdf", ".txt", ".doc", ".docx", ".xls", ".xlsx", ".csv", ".ppt", ".pptx"]
  }
}

export const types = {
  START_UPLOAD: "START_UPLOAD",
  FINISH_UPLOAD: "FINISH_UPLOAD",
  ERROR_UPLOAD: "ERROR_UPLOAD",
}

const getters = {
  upload(state) {
    return state.upload
  },
  resourceExtensions() {
    return ACTION_TYPES.project_resource.extensions
  }
}

const mutations = {
  [types.START_UPLOAD](state, payload) {
    state.upload = {
      ...payload,
      status: FILE_STATUS.IS_UPLOADING,
      type: payload.type,
      progress: 10,
    }
  },
  [types.FINISH_UPLOAD](state, payload) {
    state.upload = {
      status: FILE_STATUS.SUCCESS,
      type: payload.type,
      progress: 100,
      path: payload.path,
      id: payload.id,
      ...payload.response
    }
  },
  [types.ERROR_UPLOAD](state, payload) {
    state.upload = {
      status: FILE_STATUS.ERROR,
      type: payload.type,
      progress: 0,
      error:
        payload.error instanceof SubmissionError ?
          payload.errors._error :
          payload.error.message
    }
  },
}

const actions = {
  // Upload an image/file
  async uploadImage({commit, state, dispatch}, filePayload) {
    // Check if ACTION_TYPE is set
    if (!filePayload.type && !ACTION_TYPES[fileObject.type]) {
      console.log('actionType not set or incorrect.')
      return
    }
    const fileObject = {
      blob: filePayload.blob,
      name: filePayload.name,
      type: filePayload.type,
    }
    commit(types.START_UPLOAD, fileObject)

    // build form data
    const formData = new FormData()
    formData.append('file', fileObject.blob, fileObject.name)
    formData.append('actionType', ACTION_TYPES[fileObject.type].name)

    return fetch('/file_objects', {
      method: 'POST',
      body: formData
    }).then((response) => {
      return response.json()
    }).then((data) => {
      commit(types.FINISH_UPLOAD, {response: data, name: fileObject.name, type: fileObject.type})
      if (ACTION_TYPES[fileObject.type].name !== ACTION_TYPES.project_resource.name) {
        // Dispatch patch to update image for the project
        dispatch('project/update/updateProject', {project: {[ACTION_TYPES[fileObject.type].dbname]: state.upload['@id']}}, {root: true})
      } else {
        // Return the file id for project resources
        return data
      }
    }).catch((error) => {
      commit(types.ERROR_UPLOAD, {error, name: fileObject.name, type: fileObject.type})
    })
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters: getters,
  actions: actions,
  mutations: mutations,
}
