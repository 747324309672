// invite people form getters
export const invitePeopleCreated = state => state.invitePeopleCreated
export const invitePeopleError = state => state.invitePeopleError
export const invitePeopleIsLoading = state => state.invitePeopleIsLoading
export const invitePeopleViolations = state => state.invitePeopleViolations
// email members form getters
export const emailMembersCreated = state => state.emailMembersCreated
export const emailMembersError = state => state.emailMembersError
export const emailMembersIsLoading = state => state.emailMembersIsLoading
export const emailMembersViolations = state => state.emailMembersViolations
