import { mapActions, mapGetters } from 'vuex'

export default {
  created() {

  },

  computed: {
  },

  methods: {
    ...mapActions({
      preparePage: 'privacy/show/preparePage'
    })
  },

}
