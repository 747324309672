import fetch from '../../../../utils/fetch'

const initialState = {
  isLoading: false,
  record: null,
  error: null,
}

const getters = {
  record(state) {
    if (state.record) {
      return state.record
    }  else {
      return null
    }
  },
  isLoading(state) { return state.isLoading },
  error(state) { return state.error },
}

const actions = {
  /**
   * @function get- retrieve record for a datasheet record
   * @param commit
   * @param recordId
   */
  get({ commit }, recordId) {
    commit(types.SET_RECORD_LOADING);
    return fetch('/datasheet_records/' + recordId)
      .then(response => response.json())
      .then((data) => {
        commit(types.SET_RECORD, data)
      })
      .catch((error) => {
        commit(types.SET_RECORD_ERROR, error)
      })
  },
}

const types = {
  SET_RECORD_LOADING: 'SET_RECORD_LOADING',
  SET_RECORD: 'SET_RECORD',
  SET_RECORD_ERROR: 'SET_RECORD_ERROR',
  RESET_ERROR: 'RESET_ERROR',
  RESET: 'RESET',
}

const mutations = {
  [types.SET_RECORD_LOADING](state) {
    state.isLoading = true;
  },
  [types.SET_RECORD](state, record) {
    state.record = record
    state.isLoading = false
  },
  [types.SET_RECORD_ERROR](state, error) {
    state.error = error;
    state.isLoading = false;
  },
  [types.RESET_ERROR](state) {
    state.isLoading = false;
    state.error = false;
  },
  [types.RESET](state) {
    state = initialState
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters: getters,
  actions: actions,
  mutations: mutations
}
