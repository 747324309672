import fetch from '../../../utils/fetch'

const initialState = {
  isLoadingBases: false,
  bases: null,
  basesError: null,
  isLoadingTables: false,
  tables: null,
  tablesError: null,
}

const getters = {
  isLoadingBases(state) {
    return state.isLoadingBases
  },
  bases(state) {
    return state.bases
  },
  basesError(state) {
    return state.basesError
  },
  isLoadingTables(state) {
    return state.isLoadingTables
  },
  tables(state) {
    return state.tables
  },
  tablesError(state) {
    return state.tablesError
  },
}

const actions = {
  get({commit, rootState}) {
    commit(types.GET_AIRTABLE_BASES_LOADING);
    return fetch(rootState.user.profile.sessionData['@id'] + '/airtable/bases', {method: 'GET'})
      .then(response => response.json())
      .then((data) => {
        commit(types.GET_AIRTABLE_BASES, data)
      })
      .catch((error) => {
        commit(types.GET_AIRTABLE_BASES_ERROR, error)
      })
  },
  getTables({commit, rootState}, baseId) {
    commit(types.GET_AIRTABLE_TABLES_LOADING);
    return fetch(rootState.user.profile.sessionData['@id'] + '/airtable/base/' + baseId + '/schema', {method: 'GET'})
      .then(response => response.json())
      .then((data) => {
        commit(types.GET_AIRTABLE_TABLES, data)
      })
      .catch((error) => {
        commit(types.GET_AIRTABLE_TABLES_ERROR, error)
      })
  },
  reset({commit, state}) {
    commit(types.GET_AIRTABLE_BASES_LOADING);
    commit(types.RESET)
  },
}

const types = {
  GET_AIRTABLE_BASES_LOADING: 'GET_AIRTABLE_BASES_LOADING',
  GET_AIRTABLE_BASES: 'GET_AIRTABLE_BASES',
  GET_AIRTABLE_BASES_ERROR: 'GET_AIRTABLE_BASES_ERROR',
  GET_AIRTABLE_TABLES_LOADING: 'GET_AIRTABLE_TABLES_LOADING',
  GET_AIRTABLE_TABLES: 'GET_AIRTABLE_TABLES',
  GET_AIRTABLE_TABLES_ERROR: 'GET_AIRTABLE_TABLES_ERROR',
  RESET: 'RESET',
}

const mutations = {
  [types.GET_AIRTABLE_BASES_LOADING](state) {
    state.isLoadingBases = true;
  },
  [types.GET_AIRTABLE_BASES](state, data) {
    state.bases = data
    state.isLoadingBases = false
  },
  [types.GET_AIRTABLE_BASES_ERROR](state, error) {
    state.basesError = error;
    state.isLoadingBases = false;
  },
  [types.GET_AIRTABLE_TABLES_LOADING](state) {
    state.isLoadingTables = true;
  },
  [types.GET_AIRTABLE_TABLES](state, data) {
    state.tables = data
    state.isLoadingTables = false
  },
  [types.GET_AIRTABLE_TABLES_ERROR](state, error) {
    state.tablesError = error;
    state.isLoadingTables = false;
  },
  [types.RESET](state) {
    state = initialState
  },
}

export default {
  namespaced: true,
  state: initialState,
  getters: getters,
  actions: actions,
  mutations: mutations
}
