<template>
  <b-modal id="createResourceModal"
           ref="CreateResourceModal"
           title-html="<b>Create a Resource</b>"
           size="md"
           no-close-on-backdrop
           no-close-on-esc
           centered
           ok-title-html="<b>Create</b>"
           @ok="handleSubmit">

    <b-container fluid>
      <b-row>
        <b-alert variant="danger" dismissible v-model="error.length > 0">
          {{ error }}
        </b-alert>
      </b-row>
      <!-- Resource Name -->
      <b-row class="mt-3 mb-3">
        <label
          id="resource-name-input-label"
          for="resource-name-input"><b>Resource Name: </b>
        </label>
        <b-col lg="9" md="12" class="mt-0">
          <b-form-input id="resource-name-input"
                        type="text"
                        maxlength="220"
                        name="resource name"
                        placeholder="Resource Display Name..."
                        v-model.trim="name"
                        v-validate="'required|min:5'"
                        :class="{ 'is-invalid': errors.has('resource name') }"
                        aria-describedby="resource-name-input-live-feedback"></b-form-input>
          <b-form-invalid-feedback id="resource-name-input-live-feedback">
            {{ errors.first('resource name') }}
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <!-- Resource Type -->
      <b-row class="mt-3 mb-3">
        <label
          id="resource-name-type-label"
          for="resource-type-input"><b>Resource Type: </b>
        </label>
        <b-col lg="9" md="12" class="mt-0 ml-2">
          <b-form-radio-group
            id="resource-type-input"
            :options=resourceTypes
            name="resource type"
            v-model="type"
            v-validate="'required'"
            :class="{ 'is-invalid': errors.has('resource type') }"
            aria-describedby="resource-name-input-live-feedback"></b-form-radio-group>
          <b-form-invalid-feedback id="resource-type-input-live-feedback">
            {{ errors.first('resource type') }}
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <!-- Resource URL -->
      <b-row class="mt-3" v-if="type === 'url'">
        <label
          id="resource-url-input-label"
          for="resource-url-input"><b>Website Link: </b>
        </label>
        <b-col lg="9" md="12" class="mt-0 ml-4">
          <b-form-input id="resource-url-input"
                        type="text"
                        class=""
                        maxlength="500"
                        name="website link"
                        placeholder="Link or URL for the Resource..."
                        v-model.trim="url"
                        v-validate="'required|min:5'"
                        :class="{ 'is-invalid': errors.has('website link') }"
                        aria-describedby="resource-url-input-live-feedback"></b-form-input>
          <b-form-invalid-feedback id="resource-url-input-live-feedback">
            {{ errors.first('website link') }}
          </b-form-invalid-feedback>
        </b-col>
      </b-row>
      <!-- Resource File -->
      <b-row class="mt-3" v-if="type === 'file'">
        <label class="mr-2"
               id="resource-file-input-label"
               for="resource-file-input"><b>Upload File: </b>
        </label>
        <b-col class="mt-0 ml-4">
          <input class="d-none" @change="onFileUploadChange" type="file" ref="addFileChoose"/>
          <b-button class="btn-green" @click="$refs.addFileChoose.click()">
            Select a File
          </b-button>
          <div class="small">(jpg, jpeg, png, pdf, doc, docx, xls, xlsx, ppt, or pptx, txt, or csv)</div>
        </b-col>
      </b-row>
    </b-container>

    <template slot="modal-footer">
      <div v-if="isUploadingFile">
        <font-awesome-icon v-if="isUploadingFile" icon="spinner"/>
        &nbsp;Uploading Resource File...
      </div>
    </template>

  </b-modal>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  components: {},
  data() {
    return {
      resourceTypes: [
        {text: "Website Link", value: "url"},
        {text: "File (10MB max.)", value: "file"}
      ],
      name: null,
      url: null,
      type: null,
      file: null,
      fd: new FormData()
    }
  },
  created() {
    this.$validator.validateAll()
  },
  computed: {
    ...mapGetters({
      error: 'project/resources/error',
      isLoading: 'project/resources/isLoading',
      isUploadingFile: 'project/resources/isUploadingFile'
    })
  },
  methods: {
    ...mapActions({
      createResource: 'project/resources/createResource',
      startUpload: 'project/resources/startUpload',
      uploadFile: 'project/resources/uploadFile',
      setUploadFrontendError: 'project/resources/setUploadFrontendError'
    }),
    handleSubmit(evt) {
      evt.preventDefault()
      // Validation
      this.$validator.validateAll().then(result => {
        if (result) {
          console.log(result)
        }
      })
    },

    onFileUploadChange(files) {
      const _files = files.target.files || files.dataTransfer.files
      const fileTypesAllowed = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'txt']
      let object = {}

      if (!_files.length)
        return

      const filename = _files[0].name

      if (filename.lastIndexOf('.') <= 0 || fileTypesAllowed.indexOf(filename.split('.').pop().toLowerCase()) === -1) {
        return this.setUploadFrontendError('Invalid file type')
      }

      this.fd.append('file', _files[0], _files[0].name)
      this.fd.append('actionType', 'project_resource')
    },

    onClickResourceSave(evt, item) {
      evt.preventDefault()

      let isCreateRes = true

      if (this.editedResource.id) {
        isCreateRes = false
      }

      if (this.editedResource.type === 'link') {
        let saveData = {
          project: '/projects/' + this.projectId,
          name: this.editedResource.name,
          url: this.editedResource.url,
          isCreateRes: isCreateRes,
          resourceId: this.editedResource.id,
          file: null
        }

        this.createResource(saveData).then((data) => {
          if (typeof data === 'undefined') {
            return
          }

          this.$root.$emit('bv::hide::modal', 'create-edit-resource')
          this.$emit('refresh-table', 'resource-table')
        })
      } else if (this.editedResource.type === 'file') {
        let object = null

        if (this.editedResource.file != null) {
          object = {fd: this.fd, isCreateRes: false, currentId: this.editedResource.file.id}
        } else {
          object = {fd: this.fd, isCreateRes: true}
        }

        this.uploadFile(object).then((data) => {
          this.file = data
          this.editedResource.url = null
          let saveData = {
            project: '/projects/' + this.projectId,
            name: this.editedResource.name,
            file: this.file['@id'],
            url: null,
            resourceId: this.editedResource.id,
            isCreateRes: isCreateRes
          }

          this.createResource(saveData).then((data) => {
            if (typeof data === 'undefined') {
              return
            }

            this.$root.$emit('bv::hide::modal', 'create-edit-resource')
            this.$emit('refresh-table', 'resource-table')
            this.fd = new FormData()
          })
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.custom-title {
  width: 100%;
  text-align: center;
}
</style>
