import VueCustomModal from '../../../components/layout/CustomModal'
import VueLoader from '../../../components/layout/Loader'
import VueModal from '../../../components/layout/Modal'
import VueTable from '../../../components/layout/Table'
import VueTabs from '../../../components/layout/Tabs'
import VueDraggable from 'vuedraggable'
import VueGoogleMap from '../../../components/layout/VuetifyGoogleMap'
import EditMode from '../../../components/datasheet/EditMode'
import RecordEdit from '../../../components/datasheet/RecordEdit'
import Record from '../../../components/datasheet/DatasheetRecord'
import FromNow from '../../../components/layout/FromNow'
import { mapActions, mapGetters } from 'vuex'

// import { createNamespacedHelpers  } from 'vuex'
// const { mapGetters, mapActions } = createNamespacedHelpers('datasheet/creator')
// import font-awesome 5 icons (vue-bootstrap-datetimepicker library requires it)
import DatePicker from 'vue-bootstrap-datetimepicker'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import '@fortawesome/fontawesome-free/css/fontawesome.css'
import '@fortawesome/fontawesome-free/css/regular.css'
import '@fortawesome/fontawesome-free/css/solid.css'
import DatasheetAutoAssignSelector from "../../../components/datasheet/DatasheetAutoAssignSelector.vue";

// use font-awesome 5 icons
$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle'
  }
})


const dRecordObject = {
  label: '',
  required: false,
  allowReplica: true,
  min: null,
  max: null,
  customLength: false,
  integer: false,
  defaultOption: null,
  recordType: null,
}

const dActiveRecord = {
    'recordType': null
}



const dcAddLocationToPicklistModalTableColumns = [
  { value: 'name', text: 'Location Name', align: 'left', sortable: false },
  { value: 'latitude', text: 'Latitude', align: 'left', sortable: false },
  { value: 'longitude', text: 'Longitude', align: 'left', sortable: false }
]

const templateRecordsPrimary = [
  {
    recordType: "organism",
    display:  { name: 'Organisms', leftSymbol: 'img', imgSrc: '/assets/images/DeerIcon.png' },
    allowAnyOrganism: true,
    allowReplica: false,
    validators: [],
    appearanceOptions: [],
    records: [],
    organisms: [],
  },
]

const templateRecordsSecondary = [
  {
    recordType: "text",
    display:{ name: 'Text', leftSymbol: 'vIcon', vIcon: 'description' },
    label: "",
    allowReplica: false,
    validators: [],
    appearanceOptions: [],
    records: [],
  },
  {
    recordType: "number",
    display:{ name: 'Number', leftSymbol: 'vIcon', vIcon: 'looks_one' },
    label: "",
    allowReplica: false,
    validators: [],
    appearanceOptions: [],
    records: [],
  },
  {
    recordType: "dropdown",
    display:{ name: 'Dropdown', leftSymbol: 'vIcon', vIcon: 'view_list' },
    label: "",
    allowReplica: false,
    validators: [],
    appearanceOptions: [],
    records: [],
    optionsSet: {
      "optionsValues": [
        {
          "value": "Choice 1",
          "dataType": "text",
          "orderNumber": 1
        },
        {
          "value": "Choice 2",
          "dataType": "text",
          "orderNumber": 2
        },
        {
          "value": "Choice 3",
          "dataType": "text",
          "orderNumber": 3
        }
      ]
    }
  },
  {
    recordType: "datetime",
    display:{ name: 'Date/Time', leftSymbol: 'vIcon', vIcon: 'date_range' },
    label: "",
    allowReplica: false,
    validators: [],
    appearanceOptions:[
      {
        "optionType": "date_time"
      }
    ],
    records: [],
  },
  {
    recordType: "radio",
    display:{ name: 'Single Choice', leftSymbol: 'vIcon', vIcon: 'radio_button_checked' },
    label: "",
    allowReplica: false,
    validators: [],
    appearanceOptions: [],
    records: [],
    optionsSet: {
      "optionsValues": [
        {
          "value": "Choice 1",
          "dataType": "text",
          "orderNumber": 1
        },
        {
          "value": "Choice 2",
          "dataType": "text",
          "orderNumber": 2
        },
        {
          "value": "Choice 3",
          "dataType": "text",
          "orderNumber": 3
        }
      ]
    }
  },
  {
    recordType: "image",
    display:{ name: 'Image', leftSymbol: 'vIcon', vIcon: 'image' },
    label: "",
    allowReplica: false,
    validators: [],
    appearanceOptions: [],
    records: [],
  },
  {
    recordType: "description",
    display:{ name: 'Header', leftSymbol: 'faIcon', faIcon: 'fa-header' },
    label: "",
    allowReplica: false,
    validators: [],
    appearanceOptions: [],
    records: [],
  },
  // disable location until ready
  // {
  //   recordType: "location",
  //   display:{ name: 'Location', leftSymbol: 'vIcon', vIcon: 'map' },
  //   label: "",
  //   allowReplica: false,
  //   validators: [],
  //   appearanceOptions: [],
  //   records: [],
  // },
]

// Datasheet validation - error messages
const REQUIRED_DATASHEET_NAME = "The Datasheet Name field is required."
const ORGANISM_REQUIRED_MEASUREMENT = "The Datasheet must have at least one measurement associated with each organism(s)."
const ORGANISM_LIST_REQUIRED_SELECTED_ORGANISM = "Each organism list must have at least one organism selected."
const REQUIRED_MEASUREMENT = "The Datasheet must have at least one measurement."
const REQUIRED_MEASUREMENT_LABEL = "Each measurement must have a label."

export default {
  components: {
    DatasheetAutoAssignSelector,
    DatePicker,
    VueCustomModal,
    VueLoader,
    VueModal,
    VueTable,
    VueTabs,
    VueDraggable,
    VueGoogleMap,
    EditMode,
    Record,
    RecordEdit,
    FromNow,
  },

  data () {
    return {
      leftPanelActiveTab: 0,
      datetimePickerDate: moment().format('YYYY/MM/DD h:mm:ss A'),
      observer: null,
      dObject: null,
      hasChanges: false,
      activeRecord: null,
      dRecords: [],
      dRecordsRemoved: [],
      projectMembers: [],



      locations: [],
      projectLocations: [],
      dLocationsNextPageObject: {},
      removedLocations: [],
      locationDetails: null,
      hasEditedLocation: false,
      locationErrorMessage: "",
      editedLocationIndex: -1,
      isOpenEditLocationModal: false,



      dRecordType: '',
      // dRecordDataType: '',
      dRecordOrderNumber: 0,
      dRecordObject: dRecordObject,
      dActiveRecord: dActiveRecord,
      dSubRecordOrderNumber: 0,
      dTextRecordLabel: null,
      dTextRecordHint: null,
      dTextRecordDataType: null,
      dTextRecordDefaultValue: null,
      dtextRecordInteger: null,
      dTextRecordOptionValue: null,
      dTextRecordMinLength: null,
      dTextRecordMaxLength: null,
      dTextRecordAllowReplica: true,
      actionType: '',
      changeLeftPanelTab: '',
      removeDatasheetRecordModalIsOpen: false,
      dcAddLocationToPicklistModalTableColumns: dcAddLocationToPicklistModalTableColumns,
      templateRecordsPrimary: templateRecordsPrimary,
      templateRecordsSecondary: templateRecordsSecondary,
      dClickedRecord: null,
      dClickedRecordOrderNumber: null,
      dIsSubRecord: null,
      dClickedSubRecordOrderNumber: null,
      isOpenEditMode: false,
      isRequiredRecord: false,
      isRequiredDefaultState: false,
      minValidatorOrderNumber: null,
      maxValidatorOrderNumber: null,
      minRecord: false,
      maxRecord: false,
      isIntegerValue: false,
      isEditedValue: false,
      defaultOptionValue: null,
      textRecordObject: {},

      datasheetValidationErrors: [],
      turnSubrecordIntoRecord: false,
    }
  },

  provide () {
    // allows child components to share field validation
    // https://v3.vuejs.org/guide/component-provide-inject.html
    return { parentValidator: this.$validator }
  },
  created () {
    this.$validator.validateAll();
    this.fetchDatasheet(decodeURIComponent(this.$route.params.id));

    this.observer = {
      displayName:this.userDisplayName,
      userId: this.userId
    };
    this.projectMembers = [this.observer];
  },
  mounted () {
    document.body.setAttribute('id','custom-vuetify-style')
  },
  beforeRouteLeave(to, from, next) {
    // Notify user they have unsaved changes
    if (this.hasChanges) {
      this.$bvModal.show('leaveModal')
      this.$nextTick(() => {
        // Set up hide handler.
        // Done in next tick to ensure Modal is in DOM
        this.$refs.leaveModal.$once('hide', (bvEvt) => {
          if (bvEvt.trigger === 'ok') {
            // User clicked on built in OK button
            // So we set up a handler to listen for modal 'hidden' event
            // and advance to next route once hidden
            this.$refs.leaveModal.$once('hidden', () => { next() })
          } else {
            // User clicked cancel or the modal 'x' close button
            next(false)
          }
        })
      })
    } else {
      next();
    }
  },
  destroyed () {
    document.body.removeAttribute('id','custom-vuetify-style')
  },
  beforeDestroy () {
    this.resetDatasheet()
  },
  computed: {
    ...mapGetters('datasheet/creator', [
      'retrievedDatasheet',
      'retrievedDatasheetIsLoading',
      'retrievedDatasheetError',
      // 'retrievedDatasheetRecords',
      'updatedDatasheet',
      'updatedDatasheetIsLoading',
      'updatedDatasheetRecords',
      'updatedDatasheetRecordsIsLoading',
      'updatedDatasheetRecordsViolations',
      'updatedDatasheetSubRecords',
      'updatedDatasheetTextRecord',


      'updatedDatasheetLocations',
      'updatedDatasheetPublished',
      'updatedDatasheetRecordDetailsError',
      'retrievedLocations',
      'retrievedLocationsError',
      'retrievedLocationsIsLoading',
      'checkedLocationExistence',
      'checkLocationExistenceError',
    ]),
    ...mapGetters('datasheet/creator',{
      vuexDatasheet: 'datasheet',
      vuexRecords: 'datasheetRecords',
      vuexLocations: 'datasheetLocations',
      datasheetIsLoading: 'datasheetIsLoading',
      datasheetIsUploading: 'datasheetIsUploading',
      datasheetError: 'datasheetError',
      datasheetViolations: 'datasheetViolations',
      datasheetProject: 'datasheetProject',
    }),
    ...mapGetters({
      userId: 'user/profile/id',
      userDisplayName: 'user/profile/displayName'
    }),
    activeRecordMarker:function(){
      return this.activeRecord ? this.activeRecord.marker : null
    },
    datetimePickerOptions:function(){
      let format = 'YYYY/MM/DD h:mm A'
      switch (this.dObject.dateFormat) {
        case "datetime":
          format = "YYYY/MM/DD h:mm:ss A"
          break
        case "date_hour_minutes":
          format = "YYYY/MM/DD h:mm A"
          break
        case "date":
          format = "YYYY/MM/DD"
      }
      return {
        allowInputToggle: true,
        format: format,
        showClear: true,
        showClose: true,
        useStrict: true,
        maxDate: new Date(Date.now())
      }
    },
    locationPickerOptions:function(){
      if(!this.dObject){
        return 'previewPanelLocationCoordinates'
      }
      switch(this.dObject.locationFormat) {
        case 0:
          return "previewPanelLocationCoordinates"
        case 1:
          return "previewPanelLocationPicklist"
        case 2:
          return "previewPanelLocationBoth"
      }
    },
  },

  watch: {
    // Watch for changes to the datasheet
    vuexDatasheet (datasheet) {
      if (!datasheet) {
        return
      }
      this.$nextTick(() => {
        this.hasChanges = false;
      })
      // deep(ish) copy to prevent modifying the VUEX store
      // we maintain records separately to ensure reactivity
      this.dObject = {...datasheet}
    },
    datasheetProject (newer,old){
      if(newer === null || old === null || newer.id !== old.id){
        // call API to get all existing project locations
        this.retrieveLocations();
      }
    },
    vuexRecords (records){
      if (!records) {
        return
      }
      this.dRecords = this.setUsefulRecordFieldsB(records);
    },
    vuexLocations (locations){
      this.locations = [...locations]
    },
    dObject:{deep: true,
      handler:function(){
      this.hasChanges = true;
    }},
    dRecords:{deep: true,
      handler:function(){
      this.hasChanges = true;
    }},
    locations:{deep: true,
      handler:function(){
      this.hasChanges = true;
    }},
    // updatedDatasheet (datasheet) {
    //   if (!datasheet) {
    //     return
    //   }
    //   this.dObject.published = datasheet.published
    // },

    // updatedDatasheetRecords (datasheet) {
    //   if (!datasheet) {
    //     return
    //   }

    //   this.dObject = datasheet
    //   this.turnSubrecordIntoRecord = false

    //   if (this.actionType !== "removeDRecord") {
    //     let record = {}
    //     if (this.dRecordType !== "sub-record") {
    //       record = datasheet.records[this.dRecordOrderNumber - 1]
    //     } else {
    //       record = datasheet.records[this.dRecordOrderNumber].records[this.dSubRecordOrderNumber - 1]
    //     }

    //     if (this.actionType !== "moveDRecord" && this.actionType !== "turnRecordIntoSubRecord") {
    //       this.activeRecordDetails(record)

    //       if (this.dActiveRecord && this.dActiveRecord.recordType === "organism" && this.actionType === 'createDRecord' && this.dRecordType !== "sub-record") {
    //         this.openOrganismsModal()
    //       } else if (this.dActiveRecord && this.dActiveRecord.recordType !== "organism" && this.actionType === 'createDRecord') {
    //         this.dTextRecordDataType = this.dActiveRecord.recordType
    //       }
    //     } else if (this.dActiveRecord && this.dActiveRecord.id && record && record.id && this.dActiveRecord.id === record.id) {
    //       this.dActiveRecord = record
    //     } else if (this.actionType === "turnRecordIntoSubRecord" && record) {
    //       this.setLeftTab('edit-main-tab')
    //       this.dActiveRecord = record
    //       this.dRecordObject = record
    //     }

    //     // add the created record to dRecords array
    //     if (this.dRecordType !== "sub-record") {
    //       this.dRecords = datasheet.records
    //     } else {
    //       this.dRecords[this.dRecordOrderNumber].records.splice(this.dSubRecordOrderNumber - 1, 1, this.dActiveRecord)
    //     }
    //   } else {
    //     this.setLeftTab('add-main-tab')

    //     this.dActiveRecord = dActiveRecord
    //   }

    //   this.isEditedValue = false
    // },

    // updatedDatasheetSubRecords (record) {
    //   if (!record) {
    //     return
    //   }

    //   this.dObject.records = this.dObject.records.map(obj => record.id === obj.id ? record : obj)

    //   if (this.actionType !== "removeDRecord") {
    //     const subRecord = record.records[this.dSubRecordOrderNumber - 1]

    //     if (this.actionType !== "moveDRecord") {
    //       this.activeRecordDetails(subRecord)
    //       if (this.dActiveRecord && this.actionType === 'createDRecord') {
    //         this.dTextRecordDataType = this.dActiveRecord.recordType
    //       }
    //     } else if (this.dActiveRecord.id === record.id) {
    //       this.dActiveRecord = record
    //     }

    //     this.dRecords[record.orderNumber - 1].records = record.records
    //   } else {
    //     this.setLeftTab('add-main-tab')
    //     this.dActiveRecord = dActiveRecord
    //   }

    //   this.isEditedValue = false

    //   if (record.recordType === "organism" && record.records && record.records.length === 0) {
    //     this.publishDatasheet(false)
    //   }
    // },

    // // This is the computed property when a datasheet record is saved
    // updatedDatasheetTextRecord (record) {
    //   if (!record) {
    //     return
    //   }

    //   // Add attributes for displaying the record
    //   this.setUsefulRecordFields(record)

    //   if (this.isOpenEditMode) {
    //     // The edit panel is still active
    //     this.setActiveRecord(record, this.dIsSubRecord, this.dClickedSubRecordOrderNumber)
    //   } else {
    //     if (record.recordType !== "organism") {
    //       this.checkRequiredRecordValidator(record.validators)
    //     }

    //     if (record && (record.recordType === 'dropdown' || record.recordType === 'radio')) {
    //       this.getDefaultOptionValue(record.optionsSet.optionsValues)
    //     }

    //     this.setInitialRecordDetails(record)

    //     if (this.changeLeftPanelTab !== '') {
    //       this.setLeftTab(this.changeLeftPanelTab)
    //     } else {
    //       this.setLeftTab('add-main-tab')

    //     }

    //     this.dActiveRecord = dActiveRecord
    //   }

    //   this.isEditedValue = false

    //   if (record.parentRecord) {
    //     const parentRecord = this.$_.find(this.dRecords, record => {
    //       return record["@id"] === this.dActiveRecord.parentRecord
    //     })
    //     const parentRecordOrderNumber = parentRecord.orderNumber
    //     this.dRecords[parentRecordOrderNumber - 1].records[record.orderNumber - 1] = record
    //   } else {
    //     this.dRecords[record.orderNumber - 1] = record
    //   }

    //   // Update the local copy of the record
    //   this.dObject.records = this.dObject.records.map(obj => record.id === obj.id ? record : obj)

    //   // Update the local copy of the child records
    //   if (this.dObject.records && this.dObject.records.length > 0) {
    //     this.dObject.records.forEach(r => {
    //       if (r.recordType === "organism") {
    //         r.records = r.records.map(obj => record.id === obj.id ? record : obj)
    //       }
    //     })
    //   }

    //   if (record.recordType !== "organism" && !record.label) {
    //     this.publishDatasheet(false)
    //   }
    // },



    ////////////////////////////////////////
    // locations Watchters


    // updatedDatasheetLocations (datasheet) {
    //   if (!datasheet) {
    //     return
    //   }

    //   this.dObject = datasheet
    //   this.removedLocations = []
    //   this.$modal.hide("location-picklist")
    // },

    // updatedDatasheetPublished (datasheet) {
    //   if (!datasheet) {
    //     return
    //   }

    //   this.dObject.published = datasheet.published
    // },



    // retrievedLocations (locations) {
    //   if (!locations) {
    //     return
    //   }
    //   this.projectLocations = locations['hydra:member']
    // },

    // checkedLocationExistence (location) {
    //   return;
    //   if (!location) {
    //     return
    //   }

    //   const locationData = location['hydra:member']

    //   // check if the location exists in the Locations table
    //   if (locationData.length > 0) {
    //     this.locationDetails = locationData[0]
    //   }
    //   const hasLocation = this.$_.find(this.locations, location => {
    //     return location.name === this.locationDetails.name
    //       && location.latitude === this.locationDetails.latitude
    //       && location.longitude === this.locationDetails.longitude
    //   })

    //   if (hasLocation) {
    //     this.locationErrorMessage = "This location has already been added."
    //   } else {
    //     if (this.hasEditedLocation) {
    //       if (locationData.length === 0) {
    //         this.locationDetails.edited = true
    //       }

    //       Object.assign(this.locations[this.editedLocationIndex], this.locationDetails)
    //     } else {
    //       const isRemovedLocation = this.$_.find(this.removedLocations, location => {
    //         return location.name === this.locationDetails.name && location.latitude === this.locationDetails.latitude && location.longitude === this.locationDetails.longitude
    //       })

    //       if (isRemovedLocation) {
    //         this.locations.unshift(isRemovedLocation)
    //       } else {
    //         this.locations.unshift(this.locationDetails)
    //       }
    //     }

    //     this.hasEditedLocation = false
    //     this.locationDetails = null
    //     this.isOpenEditLocationModal = false
    //   }
    // }
  },

  methods: {
    ...mapActions('datasheet/creator', [
      //'retrieveDatasheet',
      'updateDatasheet',
      // 'retrieveDatasheetRecords',
      'updateDatasheetRecords',
      'setDatasheetRecordInactive',
      'updateDatasheetSubRecords',
      'updateDatasheetRecordDetails',
      'updateDatasheetLocations',
      'updateDatasheetLocationFormat',
      'updateDatasheetDateFormat',
      'updateDatasheetPublished',
      'retrieveLocations',
      'checkLocationExistence',
      'checkLocationExistence2',
      'resetDatasheet',
    ]),
    ...mapActions('datasheet/creator',{
      fetchDatasheet: 'fetchDatasheet',
      uploadDatasheet: 'uploadDatasheet',
      publishDatasheet: 'publishDatasheet',
    }),
    moment(value){
      return moment(value);
    },
    uploadDatasheetClick(){
      // if the datasheet will not be published, we should always save
      // if the datasheet will be published, we should validate
      // if the datasheet is valid, we should always publish
      let shouldUpload = (this.dObject.published === false || this.validateDatasheet());
      if(!shouldUpload){return}
      const projectIRI = typeof this.datasheetProject === 'string'
        ? this.datasheetProject
        : this.datasheetProject['@id']
      const allRecords = [...this.dRecords, ...this.dRecordsRemoved];
      const newDatasheet = {
        ...this.dObject,
        locations: this.locations,
        records: allRecords,
        updatedAt: undefined
      }
      // Submit datasheet
      this.uploadDatasheet(newDatasheet);
    },
    setUsefulRecordFieldsB (records) {
    // This method sets the datasheet records
      if (!records || records.length === 0){return [];}
      return records.map((record)=>{
        // use marker as identifier for modifying records, default to @ID
        // set as random UUID for new records until they receive an ID
        return {
          ...record,
          marker: record.id.toString(),
          records: this.setUsefulRecordFieldsB(record.records)
        }
      })
    },
    // setUsefulRecordFields (record) {
    //   if (record instanceof String){
    //     console.error('setUsefulRecordFields recieved a string, this is a bug!')
    //   }
    //   record.marker = record["@id"];
    //   record.optionValue = null;
    //   record.requiredField = false;
    //   record.initialDefaultValue = record.defaultValue ? record.defaultValue : null
    //   record.selectedSpecies = record.organisms && record.organisms.length === 1 ? record.organisms[0] : null

    //   // Default option value, if set, is assigned for radio and dropdown
    //   if (record && record.optionsSet && record.optionsSet.optionsValues) {

    //     // Use the newly added default value from OptionsSet instead of searching through Options Values for the default values
    //     // const defaultSelectedObject = this.$_.find(record.optionsSet.optionsValues, option => {
    //     //   return option.isDefault
    //     // })

    //     const defaultSelectedObject = record.optionsSet.defaultOptionValue
    //     if (defaultSelectedObject) {
    //       if (record.recordType === "radio") {
    //         if (defaultSelectedObject && defaultSelectedObject["@id"]) {
    //           record.optionValue = defaultSelectedObject["@id"]
    //         }
    //       } else {
    //         record.optionValue = defaultSelectedObject
    //       }
    //     }
    //   }

    //   // Sub-records are also set recursively
    //   if (record.records && record.records.length > 0) {
    //     record.records.forEach(subRecord => {
    //       this.setUsefulRecordFields(subRecord)
    //     })
    //   }

    // },
    templateToRecord(template){
      return {
        ...template,
        display: undefined,
        marker: moment().format('DD-h-mm-ss-S')
      }
    },
    updateRecordOrder(){
      this.dRecords = this.adjustRecordsOrderNumber(this.dRecords)
      console.log('updateRecordOrder')

    },
    onCreateDatasheetRecord (event) {
      // recalculate postions
      this.dRecords = this.adjustRecordsOrderNumber(this.dRecords)
      // console.log('onCreateDatasheetRecord')
      this.setActiveRecord(this.dRecords[event.newIndex]);
      // if (!this.turnSubrecordIntoRecord) {
      //   this.actionType = 'createDRecord'
      //   this.dRecordOrderNumber = event.newIndex + 1
      //   this.isRequiredDefaultState = false
      //   this.minValidatorOrderNumber = null
      //   this.maxValidatorOrderNumber = null

      //   if (event.from.id !== "add-organisms") {
      //     this.dRecordObject.orderNumber = event.newIndex
      //   }

      //   switch (event.from.id) {
      //     case "add-organisms":
      //       this.dRecordType = 'organism'
      //       this.addOrganismsModalSelectedSpecies = []
      //       this.dOrganismRecordSpecies = []
      //       this.dOrganismRecordEditModeSelectedSpecies = []
      //       break

      //     case "add-text":
      //       this.dRecordType = 'text'
      //       this.dRecordObject.recordType = 'text'
      //       break

      //     case "add-number":
      //       this.dRecordType = 'number'
      //       this.dRecordObject.recordType = 'number'
      //       break

      //     case "add-dropdown":
      //       this.dRecordType = 'dropdown'
      //       this.dRecordObject.recordType = 'dropdown'
      //       break

      //     case "add-datetime":
      //       this.dRecordType = 'datetime'
      //       this.dRecordObject.recordType = 'datetime'
      //       break

      //     case "add-radio":
      //       this.dRecordType = 'radio'
      //       this.dRecordObject.recordType = 'radio'
      //       break

      //     case "add-image":
      //       this.dRecordType = 'image'
      //       this.dRecordObject.recordType = 'image'
      //       break
      //   }

      //   this.dRecords = this.adjustRecordsOrderNumber(this.dRecords)
      //   // flatten records
      //   const datasheetRecordsArray = this.dRecords
      //   datasheetRecordsArray.forEach(record => {
      //     if (record.records) {
      //       record.records.forEach(r => {
      //         datasheetRecordsArray.push(r['@id'])
      //       })
      //     }
      //   })

      //   this.updateDatasheetRecords({
      //     records: datasheetRecordsArray,
      //     published: false
      //   })
      // }
    },
    onTurnRecordIntoOrganismSubrecord (event) {
      // prepare records
      console.log("onTurnRecordIntoOrganismSubrecord")
      return;
      // if (event.removed || (event.added && event.added.element["@id"])) {
      //   this.actionType = 'turnRecordIntoSubRecord'
      //   this.dRecords = this.adjustRecordsOrderNumber(this.dRecords)
      //   const datasheetRecordsArray = this.dRecords

      //   if (event.removed) {
      //     datasheetRecordsArray.forEach(record => {
      //       if (record.records) {
      //         record.records.forEach(r => {
      //           if (r.parentRecord) {
      //             datasheetRecordsArray.push(r['@id'])
      //           } else {
      //             delete r["@id"]
      //             delete r["@type"]
      //             delete r.id
      //             delete r.allowAnyOrganism
      //             delete r.organisms
      //             delete r.records
      //             delete r.updatedAt
      //             delete r.isReady

      //             r.parentRecord = record["@id"]
      //             r.allowReplica = false
      //           }
      //         })
      //       }
      //     })
      //   } else if (event.added && event.added.element["@id"]) {
      //     this.turnSubrecordIntoRecord = true

      //     datasheetRecordsArray.forEach(record => {
      //       if (record.parentRecord) {
      //         delete record["@id"]
      //         delete record["@type"]
      //         delete record.allowAnyOrganism
      //         delete record.id
      //         delete record.parentRecord
      //         delete record.organisms
      //         delete record.updatedAt
      //         delete record.records
      //         delete record.isReady

      //         record.allowReplica = true
      //       }

      //       if (record.records) {
      //         record.records.forEach(r => {
      //           if (r.parentRecord) {
      //             datasheetRecordsArray.push(r['@id'])
      //           }
      //         })
      //       }
      //     })
      //   }

      //   this.updateDatasheetRecords({
      //     records: datasheetRecordsArray,
      //     published: this.dObject.published
      //   })
      // }
    },
    checkCreateDatasheetSubRecord(item, from, to, moved, test, other){
      // organisms cannot be sub records
      // this function is used to prevent dragging them into other organisms
      if(to.dataset.recordtype != 'organism'){
        return true
      }
      return false
    },
    onCreateDatasheetSubRecord (event, parentRecord) {
      this.dRecords = this.adjustRecordsOrderNumber(this.dRecords);
      console.log('onCreateDatasheetSubRecord')
      // get pointer to actual parent record,
      // so that modifications happen to the same memory address
      // rather than a copy
      const parentRecordRef = this.dRecords.find((record)=>{
        return (record.marker === parentRecord.marker);
      })

      this.setActiveRecord(parentRecordRef.records[event.newIndex]);


      return;
      // const createNewSubRecordData = this.$_.find(record.records, subRecord => {
      //   return !subRecord["@id"]
      // })

      // const addNewSubRecordData = this.$_.find(record.records, subRecord => {
      //   return subRecord.parentRecord && subRecord.parentRecord !== record["@id"]
      // })

      // if (createNewSubRecordData || addNewSubRecordData) {
      //   this.dRecordType = 'sub-record'
      //   this.dRecordOrderNumber = event.to.id.split('_')[1]
      //   this.dSubRecordOrderNumber = event.newIndex + 1
      //   this.dRecordObject.orderNumber = event.newIndex + 1
      //   this.isRequiredDefaultState = false
      //   this.minValidatorOrderNumber = null
      //   this.maxValidatorOrderNumber = null

      //   if (createNewSubRecordData) {
      //     this.actionType = 'createDRecord'

      //     switch (event.from.id) {
      //       case "add-text":
      //         this.dRecordObject.recordType = 'text'
      //         break

      //       case "add-number":
      //         this.dRecordObject.recordType = 'number'
      //         break

      //       case "add-dropdown":
      //         this.dRecordObject.recordType = 'dropdown'
      //         break

      //       case "add-datetime":
      //         this.dRecordObject.recordType = 'datetime'
      //         break

      //       case "add-radio":
      //         this.dRecordObject.recordType = 'radio'
      //         break

      //       case "add-image":
      //         this.dRecordObject.recordType = 'image'
      //         break
      //     }

      //     this.datasheetSubRecordsHolder()
      //     this.dRecords = this.adjustRecordsOrderNumber(this.dRecords)

      //     // TODO: call PATCH /datasheets/{id} (this.updateDatasheetRecords(this.dRecords))
      //     //   instead of PATCH /datasheet_records/{id} (updateDatasheetSubRecords()) for sub-records
      //     const parentRecord = this.dRecords[this.dRecordOrderNumber]
      //     this.updateDatasheetSubRecords({
      //       id: parentRecord['@id'],
      //       records: parentRecord.records
      //     })

      //   } else if (addNewSubRecordData) {
      //     this.actionType = 'turnRecordIntoSubRecord'
      //     this.dRecords = this.adjustRecordsOrderNumber(this.dRecords)

      //     const datasheetRecordsArray = [
      //       ...this.dRecords
      //     ]

      //     datasheetRecordsArray.forEach(record => {
      //       if (record.records && record.records.length > 0) {
      //         record.records.forEach(r => {
      //           if (r.parentRecord && r.parentRecord === record["@id"]) {
      //             datasheetRecordsArray.push(r['@id'])
      //           } else {
      //             delete r["@id"]
      //             delete r["@type"]
      //             delete r.id
      //             delete r.allowAnyOrganism
      //             delete r.organisms
      //             delete r.records
      //             delete r.updatedAt

      //             r.parentRecord = record["@id"]
      //             r.allowReplica = false
      //           }
      //         })
      //       }
      //     })

      //     this.updateDatasheetRecords({
      //       records: datasheetRecordsArray,
      //       published: this.dObject.published
      //     })
      //   }
      // }
    },


    datasheetSubRecordsHolder () {
      return;
      // const datasheetSubRecord = {
      //   "orderNumber": this.dSubRecordOrderNumber,
      //   "recordType": this.dRecordObject.recordType
      // }

      // switch (this.dRecordType) {
      //   case "dropdown":
      //   case "radio":
      //     datasheetSubRecord.optionsSet = {
      //       "optionsValues": [
      //         {
      //           "value": "Choice 1",
      //           "dataType": "text",
      //           "orderNumber": 1
      //         },
      //         {
      //           "value": "Choice 2",
      //           "dataType": "text",
      //           "orderNumber": 2
      //         },
      //         {
      //           "value": "Choice 3",
      //           "dataType": "text",
      //           "orderNumber": 3
      //         }
      //       ]
      //     }
      //     break

      //   case "datetime":
      //     datasheetSubRecord.appearanceOptions = [
      //       {
      //         "optionType": "date_time"
      //       }
      //     ]
      //     break
      // }

      // this.dRecords[this.dRecordOrderNumber].records.splice(this.dRecordObject.orderNumber - 1, 1, datasheetSubRecord)
    },

    onEditModeDatasheetRecord (e, record, recordOrderNumber, isSubRecord, subRecordOrderNumber) {
      // prevent bubbling of organism subrecords
      if (!e) {
        let e = window.event
      }
      e.cancelBubble = true
      if (e.stopPropagation) {
        e.stopPropagation()
      }
      this.setActiveRecord(record, isSubRecord, subRecordOrderNumber)

      return;

      // this.dClickedRecord = record
      // this.dClickedRecordOrderNumber = recordOrderNumber
      // this.dIsSubRecord = isSubRecord
      // this.dClickedSubRecordOrderNumber = subRecordOrderNumber

      // if (record && record.validators) {
      //   this.isRequiredDefaultState = this.$_.find(record.validators, validator => {
      //     return validator.validatorType === "isRequired" && validator.id
      //   })

      //   this.minValidatorOrderNumber = this.$_.find(record.validators, validator => {
      //     return validator.validatorType === "min"
      //   })

      //   this.maxValidatorOrderNumber = this.$_.find(record.validators, validator => {
      //     return validator.validatorType === "max"
      //   })
      // }
      // if(!this.dActiveRecord){
      //   console.error('Bug #379 dActiveRecord was not set');
      // }
      // if (this.dActiveRecord.recordType && record.id !== this.dActiveRecord.id) {
      //   this.updateDatasheetTextRecordHolder(true, '', true)
      // } else {
      //   this.setActiveRecord(record, isSubRecord, subRecordOrderNumber)
      // }
    },

    setActiveRecord (record, isSubRecord, subRecordOrderNumber) {
      this.setLeftTab('edit-main-tab')
      this.activeRecord = record

      return
      // this.dActiveRecord = record
      // if (this.dActiveRecord && this.dActiveRecord.recordType === 'organism') {
      //   this.dRecordType = this.dActiveRecord.recordType
      //   this.addOrganismsModalSelectedSpecies = this.dActiveRecord.organisms
      //   this.dOrganismRecordEditModeSelectedSpecies = this.dActiveRecord.organisms
      //   this.dOrganismRecordSpecies = this.dActiveRecord.organisms
      //   this.dOrganismRecordSpecies.forEach(species => {
      //     species.initialCustomName = species.customName
      //   })
      //   this.dRecordObject.allowReplica = this.dActiveRecord.allowReplica
      //   this.setInitialRecordDetails(this.dActiveRecord)
      // } else if (this.dActiveRecord && this.dActiveRecord.recordType !== 'organism') {
      //   if (!isSubRecord) {
      //     this.dRecordType = this.dActiveRecord.recordType
      //     this.dRecordObject.orderNumber = this.dActiveRecord.orderNumber - 1
      //   } else {
      //     this.dRecordType = 'sub-record'
      //     this.dRecordOrderNumber = this.dActiveRecord.orderNumber - 1
      //     this.dRecordObject.orderNumber = subRecordOrderNumber
      //   }

      //   this.checkRequiredRecordValidator(this.dActiveRecord.validators)

      //   if (this.dActiveRecord.recordType === 'dropdown' || this.dActiveRecord.recordType === 'radio') {
      //     this.getDefaultOptionValue(this.dActiveRecord.optionsSet.optionsValues)
      //   }

      //   this.dRecordObject.recordType = this.dActiveRecord.recordType
      //   this.dRecordObject.label = this.dActiveRecord.label
      //   this.dRecordObject.allowReplica = this.dActiveRecord.allowReplica
      //   this.dRecordObject.required = this.isRequiredRecord
      //   this.dRecordObject.min = this.minRecord
      //   this.dRecordObject.max = this.maxRecord
      //   this.dRecordObject.integer = this.isIntegerValue
      //   this.dRecordObject.defaultOption = this.defaultOptionValue
      //   this.dRecordObject.customLength = !!this.minRecord || !!this.maxRecord || false
      //   this.setInitialRecordDetails(this.dActiveRecord)
      // }
    },

    onDuplicateDatasheetRecord () {
      this.actionType = 'duplicateDRecord'
      this.addOrganismsModalSelectedSpecies = []
      this.dOrganismRecordEditModeSelectedSpecies = []
      this.dRecordType = this.dActiveRecord.recordType
      this.dRecordOrderNumber = this.dActiveRecord.orderNumber + 1

      const recordToDuplicate = {
        datasheet: this.dObject.id,
        orderNumber: this.dRecordOrderNumber,
        recordType: this.dRecordType,
        isReady: this.dActiveRecord.isReady,
        allowReplica: this.dActiveRecord.allowReplica
      }

      if (this.dRecordType === 'organism') {
        recordToDuplicate.allowAnyOrganism = this.dActiveRecord.allowAnyOrganism
        recordToDuplicate.organisms = []

        this.dActiveRecord.organisms.forEach(organism => {
          recordToDuplicate.organisms.push({
            "organism": '/organisms/' + organism.organismId,
            "customName": organism.customName || null
          })
        })
      } else {
        recordToDuplicate.label =  this.dActiveRecord.label
        recordToDuplicate.description = this.dActiveRecord.description
        recordToDuplicate.recordType = this.dActiveRecord.recordType
        recordToDuplicate.defaultValue = this.dActiveRecord.defaultValue
        recordToDuplicate.validators = []

        this.dActiveRecord.validators.forEach(validator => {
          recordToDuplicate.validators.push({
            "validatorType": validator.validatorType,
            "value": validator.value
          })
        })

        switch (this.dRecordType) {
          case "dropdown":
          case "radio":
            recordToDuplicate.optionsSet = {
              "optionsValues": []
            }

            this.dActiveRecord.optionsSet.optionsValues.forEach(optionValues => {
              recordToDuplicate.optionsSet.optionsValues.push({
                "value": optionValues.value,
                "dataType": optionValues.dataType,
                "orderNumber": optionValues.orderNumber,
                "isDefault": optionValues.isDefault
              })
            })
            break

          case "datetime":
            recordToDuplicate.appearanceOptions = []

            this.dActiveRecord.appearanceOptions.forEach(appearanceOption => {
              recordToDuplicate.appearanceOptions.push({
                "optionType": appearanceOption.optionType
              })
            })
            break
        }
      }

      this.dRecords.splice(this.dRecordOrderNumber - 1, 0, recordToDuplicate)
      this.dRecords = this.adjustRecordsOrderNumber(this.dRecords)
      this.updateDatasheetRecords({
        records: this.dRecords,
        published: this.dObject.published
      })
    },

    onReorderRecords (event, parentRecord) {
      console.log('onReorderRecords')
      this.dRecords = this.adjustRecordsOrderNumber(this.dRecords)
      if(parentRecord){
        this.setActiveRecord(parentRecord.records[event.newIndex]);
      } else {
        this.setActiveRecord(this.dRecords[event.newIndex]);
      }
      return;
      // this.actionType = 'moveDRecord'
      // this.dRecordOrderNumber = event.newIndex + 1
      // this.dRecords = this.adjustRecordsOrderNumber(this.dRecords)

      // const recordsArray = [
      //   ...this.dRecords
      // ]

      // recordsArray.forEach(record => {
      //   if (record.records && record.records.length > 0) {
      //     record.records.forEach(r => {
      //       if (r.parentRecord) {
      //         recordsArray.push(r)
      //       }
      //     })
      //   }
      // })

      // if (!parentRecord) {
      //   this.updateDatasheetRecords({
      //     records: recordsArray,
      //     published: this.dObject.published
      //   })
      // } else {
      //   this.updateDatasheetSubRecords({
      //     id: parentRecord['@id'],
      //     records: parentRecord.records
      //   })
      // }
    },
    removeDatasheetRecord(recordRemoved){
      // We don't delete datasheet records anymore, we mark them as inactive
      // attempting to do this on the backend, hopefully it works
      // unless that record was new and did not have an ID
      if(recordRemoved['@id']){
        // Inactivate if has id, when we save, this needs to be merged back in
        // Order number is 1 to prevent future issues with validation
        this.dRecordsRemoved = [...this.dRecordsRemoved, {
          ...recordRemoved,
          isActive: false,
          orderNumber: 1
        }]
      }
      // remove record from records array
      // filter parent records
      // Consider using a reducer, however that might be harder to maintain
      const parentRecords = this.dRecords.filter((record)=>{
        return record.marker !== recordRemoved.marker;
      })
      // Filter the parent records children
      const parentRecordsChildren = parentRecords.map((record)=>{
        if (record.records.length === 0){return record}
        const subRecords = record.records.filter((subRecord)=>{
          return subRecord.marker !== recordRemoved.marker;
        })
        return {
          ...record,
          records: subRecords
        }
      })
      this.dRecords = this.adjustRecordsOrderNumber(parentRecordsChildren)
    },
    clickRemoveDatasheetRecord() {
      this.removeDatasheetRecord(this.activeRecord);
      this.activeRecord = null;
      this.setLeftTab('settings-main-tab');
      this.removeDatasheetRecordModalIsOpen = false

      return
      // this.actionType = 'removeDRecord'
      // if(!this.dActiveRecord || !this.dActiveRecord['@id']){
      //   console.error('Bug #379 This record did not have an ID. Probably because something went wrong creating that record');
      //   return;
      // }
      // // Set the record to inactive
      // this.setDatasheetRecordInactive({ id: this.dActiveRecord['@id'] }).then(data => {

      //   if (data) {

      //     // Record is removed from local copy and other records are re-ordered
      //     if (this.dActiveRecord.parentRecord) {

      //       // remove sub-record from dRecords array
      //       const parentRecord = this.$_.find(this.dRecords, record => {
      //         return record["@id"] === this.dActiveRecord.parentRecord
      //       })
      //       const parentRecordOrderNumber = parentRecord.orderNumber
      //       this.dRecords[parentRecordOrderNumber - 1].records.splice(this.dActiveRecord.orderNumber - 1, 1)

      //       // Order number is updated and all records are saved
      //       this.dRecords = this.adjustRecordsOrderNumber(this.dRecords)
      //       this.updateDatasheetSubRecords({
      //         id: this.dActiveRecord.parentRecord,
      //         records: this.dRecords[parentRecordOrderNumber - 1].records
      //       })

      //     } else {
      //       // remove record from dRecords array
      //       this.dRecords.splice(this.dActiveRecord.orderNumber - 1, 1)

      //       // Order number is updated
      //       this.dRecords = this.adjustRecordsOrderNumber(this.dRecords)

      //       const datasheetRecordsArray = this.dRecords
      //       datasheetRecordsArray.forEach(record => {
      //         if (record.records) {
      //           record.records.forEach(r => {
      //             datasheetRecordsArray.push(r['@id'])
      //           })
      //         }
      //       })

      //       // datasheetRecordsArray.push(this.dActiveRecord)
      //       this.updateDatasheetRecords({
      //         records: datasheetRecordsArray,
      //         published: this.dObject.published
      //       })
      //     }

      //   }
      // })

    },

    changeLocationFormat (locationPicklist) {
      switch (locationPicklist) {
        case 0:
          break
        case 1:
        case 2:
          // open the location picklist modal
          this.$modal.show("location-picklist")
          break
      }
    },
    onAutoAssign(value) {
      // Set the auto-assign value from component
      this.dObject.locationAutoAssign = value
    },
    onPrivacyChange(value) {
      this.dObject.isPrivate = value
    },
    onAddLocation (newLocation) {
      this.checkLocationExistence2(
          {newLocation: newLocation, locations: this.locations}
        ).then((existingLocation)=>{
        // if it already exists in the local copy, do nothing
        // if it already exists on the server, add the server version
        // else add the current version as it is a fresh location
        if(existingLocation === true){
          return
        } if(typeof existingLocation === 'object' && existingLocation !== null){
          this.locations = [
            {
              location: existingLocation['@id'],
              locationId: existingLocation.id,
              name: existingLocation.name,
              latitude: existingLocation.latitude,
              longitude: existingLocation.longitude,
            },
            ...this.locations
          ]
        } else {
          this.locations = [
            {
              location: newLocation,
              name: newLocation.name,
              latitude: newLocation.latitude,
              longitude: newLocation.longitude,
            },
            ...this.locations
          ]
        }
      }).finally(()=>{
        this.locationDetails = null
        this.isOpenEditLocationModal = false
      });
              // if (locations) {
        //   locations.forEach((location, index) => {
        //     if (location.edited) {
        //       delete location["@id"]
        //       delete location["@type"]
        //       delete location.edited
        //     }

        //     if (location && location['@type'] === "DatasheetsLocations") {
        //       locations.locations[index] = location['@id']
        //     } else if (location && location['@type'] === "Area") {
        //       locations.locations[index] = {
        //         location: location['@id']
        //       }
        //     } else {
        //       locations.locations[index] = {
        //         location: location
        //       }
        //     }
        //   })
        // }
    },
    onRemoveLocation (index) {
      this.locations.splice(index, 1)
      // keep the initial state of the removed location into removedLocations array
      const removedLocation = this.vuexLocations[index]
      this.removedLocations.push(removedLocation)
    },
    onUpdateLocation (updatedLocation) {
      this.checkLocationExistence2({
        newLocation: updatedLocation,
        locations: this.locations
      }).then(existingLocation => {
        // if it already exists in the local copy, do nothing
        // if it already exists on the server, add the server version
        // else add the current version as it is a fresh location
        if (existingLocation === true) {
        } else if (typeof existingLocation === "object" && existingLocation !== null) {
          Object.assign(this.locations[this.editedLocationIndex],   {
              location: existingLocation,
              locationId: existingLocation.id,
              name: existingLocation.name,
              latitude: existingLocation.latitude,
              longitude: existingLocation.longitude,
            });
        } else {
          Object.assign(this.locations[this.editedLocationIndex],
            {
              location: updatedLocation,
              name: updatedLocation.name,
              latitude: updatedLocation.latitude,
              longitude: updatedLocation.longitude,
            }
          )
        }
      }).finally(()=>{
        this.locationDetails = null
        this.isOpenEditLocationModal = false
      });
      return;
      this.locationDetails = locationDetails
      this.locationErrorMessage = ""
      this.hasEditedLocation = true
      const hasLocation = this.$_.find(this.locations, location => {
        return location.name === this.locationDetails.name
          && location.latitude === this.locationDetails.latitude
          && location.longitude === this.locationDetails.longitude
      })
      if (hasLocation) {
        this.locationErrorMessage = "This location has already been added."
      } else {
        this.checkLocationExistence(this.locationDetails)
      }
    },

    updateLocationList (option) {
      this.$modal.hide("location-picklist")


      return;
      // if (option === "This was checking if the name or instructions were changed") {
      //   const datasheetDetails = {}
      //   datasheetDetails.name = this.dObject.name
      //   datasheetDetails.instructions = this.dObject.instructions
      //   datasheetDetails.projection = this.dObject.projection

      //   if (!this.dObject.name) {
      //     datasheetDetails.published = false
      //   }

      //   this.updateDatasheet(datasheetDetails)

      // } else if (option === 'location') {

      //   const datasheet = {
      //     id: this.dObject.id,
      //     locationStatus: this.locationStatus,
      //     locations: this.locations
      //   }

      //   // Datasheet location format and custom location boolean are set
      //   this.updateDatasheetLocationFormat(datasheet)

      //   // For coordinates, leave the datasheet_locations as is, do not delete
      //   if (this.locationStatus !== 0) {
      //     this.updateDatasheetLocations(datasheet)
      //   }
      // }
    },


    publishDatasheetClick () {
      // validate the datasheet can be published
      // this needs to be thought about
      // should we save the current changes? we will for now
      this.dObject.published = !this.dObject.published
      this.uploadDatasheetClick();
    },
    validateDatasheet (){
      this.datasheetValidationErrors = []
      if (!this.dObject.name) {
        this.datasheetValidationErrors.push(REQUIRED_DATASHEET_NAME)
      }
      if (this.dRecords) {
        if (this.dRecords.length > 0) {
          this.dRecords.forEach(record => {
            if (record.recordType === "organism") {
              if (!record.allowAnyOrganism && record.organisms.length === 0) {
                this.datasheetValidationErrors.push(ORGANISM_LIST_REQUIRED_SELECTED_ORGANISM)
              }
              if (record.records.length === 0 && !this.isDatasheetMessageError(ORGANISM_REQUIRED_MEASUREMENT)) {
                this.datasheetValidationErrors.push(ORGANISM_REQUIRED_MEASUREMENT)
              } else {
                const noMeasurementLabel = record.records.filter(record => !record.label)

                if (noMeasurementLabel.length > 0 && !this.isDatasheetMessageError(REQUIRED_MEASUREMENT_LABEL)) {
                  this.datasheetValidationErrors.push(REQUIRED_MEASUREMENT_LABEL)
                }
              }
            } else if (!record.label) {
              if (!this.isDatasheetMessageError(REQUIRED_MEASUREMENT_LABEL)) {
                this.datasheetValidationErrors.push(REQUIRED_MEASUREMENT_LABEL)
              }
            }
          })
        } else {
          this.datasheetValidationErrors.push(REQUIRED_MEASUREMENT)
        }
      }

      if (this.datasheetValidationErrors.length > 0) {
        $('html,body').stop().animate({
          scrollTop: 0
        }, 'slow', 'swing')
        return false;
      } else {
        return true;
      }
    },

    isDatasheetMessageError (string) {
      const datasheetStringError = this.datasheetValidationErrors.filter(error => error === string)
      return datasheetStringError.length > 0
    },

    updateActiveRecord(newRecord){
      this.activeRecord = newRecord;
      // search for this record in the stack by marker
      this.dRecords = this.dRecords.map((oldRecord)=>{
        if(oldRecord.marker === newRecord.marker){
          return newRecord;
        } else if (oldRecord.records.length == 0){
          return oldRecord;
        } else {
          oldRecord.records = oldRecord.records.map((oldSubRecord)=>{
            if(oldSubRecord.marker === newRecord.marker){
              return newRecord;
            } else {
              return oldSubRecord;
            }
          })
          return oldRecord
        }
      })
    },

    adjustRecordsOrderNumber (records) {
      // use map to ensure new objects are created
      let parentOrder = 0
      return records.map((parentRecord)=>{
        parentOrder++
        let subOrder = 0
        const newRecords = parentRecord.records.map((subRecord)=>{
          subOrder++
          return {
            ...subRecord,
            datasheet: this.dObject['@id'],
            orderNumber: subOrder,
          }
        })
        return {
          ...parentRecord,
          datasheet: this.dObject['@id'],
          orderNumber: parentOrder,
          records: newRecords,
          parentRecord: undefined

        }
      })
    },


    // watchDatasheetRecordChanges () {
    //   // This method tracks if the user clicks within the Edit tab or outside
    //   // - triggers the save event if the user clicks outside

    //   document.addEventListener("click", (evt) => {
    //     const recordEditMode = document.getElementById("record-edit-mode")
    //     const addOrganismsModal = document.getElementById("vue-js-modal-box")
    //     const saveChangesBtn = document.getElementById("save-changes-button")

    //     let targetElement = evt.target
    //     let targetElem = evt.target

    //     do {
    //       if (targetElement === recordEditMode || targetElement === addOrganismsModal ||
    //           (targetElement.classList && targetElement.classList.contains("v-overlay--active")) ||
    //           (targetElement.id &&
    //             (targetElement.id === "white-overlay-whole-page" || targetElement.id === "edit-main-tab" ||
    //             targetElement.id.split("-")[0] === "textrecord")))
    //       {
    //         // This is a click inside
    //         return
    //       }
    //       else if (this.dActiveRecord && this.dActiveRecord.recordType &&
    //         (targetElement.id === "add-main-tab" || targetElement.id === "template-main-tab" ||
    //           targetElement.id === "advanced-main-tab" || targetElement === saveChangesBtn))
    //       {
    //         this.updateDatasheetTextRecordHolder(false, targetElement.id, false)
    //         return
    //       }

    //       // Go up the DOM
    //       targetElement = targetElement.parentNode
    //     } while (targetElement)

    //     // This is a click outside
    //     if (this.dActiveRecord && this.dActiveRecord.recordType) {
    //       this.updateDatasheetTextRecordHolder(false, '', false)
    //     } else {
    //       this.dActiveRecord = dActiveRecord
    //       this.setLeftTab('add-main-tab')


    //       do {
    //         if (targetElem.id) {
    //           this.setLeftTab(targetElem.id)
    //         }

    //         // Go up the DOM
    //         targetElem = targetElem.parentNode
    //       } while (targetElem)
    //     }
    //   })
    // },

    updateDatasheetTextRecordHolder (isActiveRecord, changeLeftPanelTab, isOpenEditMode) {
      return
      // This  method updates the Edit tab with details of the record clicked on the right
      // if (this.dActiveRecord.recordType !== "organism") {
      //   this.checkRequiredRecordValidator(this.dActiveRecord.validators)

      //   if (this.dActiveRecord && (this.dActiveRecord.recordType === 'dropdown' || this.dActiveRecord.recordType === 'radio')) {
      //     this.getDefaultOptionValue(this.dActiveRecord.optionsSet.optionsValues)
      //   }

      //   if (this.dActiveRecord && this.dActiveRecord.validators) {
      //     const minObject = this.$_.findWhere(this.dActiveRecord.validators, { validatorType: "min" })
      //     const maxObject = this.$_.findWhere(this.dActiveRecord.validators, { validatorType: "max" })

      //     if (minObject && minObject.value === 0) {
      //       this.dActiveRecord.validators.splice(this.$_.indexOf(this.dActiveRecord.validators, minObject), 1)
      //     }

      //     if (maxObject && maxObject.value === 0) {
      //       this.dActiveRecord.validators.splice(this.$_.indexOf(this.dActiveRecord.validators, maxObject), 1)
      //     }
      //   }

      //   // Check if anything has changed
      //   if ((this.dTextRecordLabel) !== (this.dActiveRecord.label) ||
      //        this.dTextRecordHint !== (this.dActiveRecord.description || null) ||
      //        this.dTextRecordDataType !== this.dActiveRecord.recordType ||
      //        this.dTextRecordDefaultValue !== (this.dActiveRecord.defaultValue || null) ||
      //        !!this.dtextRecordInteger !== this.isIntegerValue ||
      //        this.dTextRecordOptionValue !== this.defaultOptionValue ||
      //        this.dTextRecordMinLength !== this.minRecord ||
      //        this.dTextRecordMaxLength !== this.maxRecord ||
      //        this.isEditedValue ||
      //        (this.dTextRecordAllowReplica !== this.dActiveRecord.allowReplica && this.dRecordType !== 'sub-record')
      //   ) {
      //     this.isOpenEditMode = isOpenEditMode
      //     this.changeLeftPanelTab = changeLeftPanelTab
      //     this.buildTextRecord()
      //     //this.updateDatasheetRecordDetails(this.textRecordObject)
      //   } else {
      //     if (isActiveRecord) {
      //       this.setActiveRecord(this.dClickedRecord, this.dIsSubRecord, this.dClickedSubRecordOrderNumber)
      //     } else {
      //       this.dActiveRecord = dActiveRecord
      //       this.setLeftTab(changeLeftPanelTab)
      //     }
      //   }
      // } else {

      //   if (typeof this.dActiveRecord.allowReplica !== "undefined" &&
      //       this.dTextRecordAllowReplica !== this.dActiveRecord.allowReplica)
      //   {
      //     this.isOpenEditMode = isOpenEditMode
      //     this.changeLeftPanelTab = changeLeftPanelTab
      //     this.textRecordObject = {
      //       "@id": this.dActiveRecord['@id'],
      //       "allowReplica": this.dActiveRecord.allowReplica
      //     }
      //     //this.updateDatasheetRecordDetails(this.textRecordObject)
      //   } else {
      //     if (isActiveRecord)
      //     {
      //       this.setActiveRecord(this.dClickedRecord, this.dIsSubRecord, this.dClickedSubRecordOrderNumber)
      //     } else {
      //       this.dActiveRecord = dActiveRecord
      //       this.setLeftTab(changeLeftPanelTab)
      //     }
      //   }

      // }
    },

    checkRequiredRecordValidator (validators) {
      const requiredRecordValidator = this.$_.find(validators, validator => { return validator.validatorType === "isRequired" })
      const integerValueValidator = this.$_.find(validators, validator => { return validator.validatorType === "isInteger" })
      const minRecordValidator = this.$_.find(validators, validator => { return validator.validatorType === "min" })
      const maxRecordValidator = this.$_.find(validators, validator => { return validator.validatorType === "max" })

      this.isRequiredRecord = requiredRecordValidator && requiredRecordValidator.value || false
      this.isIntegerValue = integerValueValidator && integerValueValidator.value || false
      this.minRecord = minRecordValidator && minRecordValidator.value || null
      this.maxRecord = maxRecordValidator && maxRecordValidator.value || null
    },

    getDefaultOptionValue (values) {
      const defaultOption = this.$_.find(values, value => { return value.isDefault })
      this.defaultOptionValue = defaultOption && defaultOption.orderNumber || null
    },

    buildTextRecord () {
      this.textRecordObject = {
        "@id": this.dActiveRecord['@id'],
        "label": this.dActiveRecord.label,
        "description": this.dActiveRecord.description || null,
        "allowReplica": this.dActiveRecord.allowReplica,
        "recordType": this.dActiveRecord.recordType,
        "defaultValue": this.dActiveRecord.defaultValue || null,
        "validators": this.dActiveRecord.validators || null,
        "optionsSet": this.dActiveRecord.optionsSet || null
      }
    },

    activeRecordDetails (record) {
      this.setLeftTab('edit-main-tab')

      this.dActiveRecord = record

      if (this.actionType !== "moveDRecord" && this.actionType !== "duplicateDRecord") {
        this.dRecordObject.label = ''
        this.dRecordObject.required = false
        this.dRecordObject.allowReplica = true
        this.dRecordObject.min = null
        this.dRecordObject.max = null
        this.dRecordObject.integer = false
        this.dRecordObject.defaultOption = null
        this.dRecordObject.customLength = false
      }
    },

    // setInitialRecordDetails (record) {
    //   return;
    //   if (record.recordType !== "organism") {
    //     this.dTextRecordLabel = record.label
    //     this.dTextRecordHint = record.description || null
    //     this.dTextRecordDataType = record.recordType
    //     this.dTextRecordDefaultValue = record.defaultValue || null
    //     this.dtextRecordInteger = this.isIntegerValue
    //     this.dTextRecordOptionValue = this.defaultOptionValue
    //     this.dTextRecordMinLength = this.minRecord
    //     this.dTextRecordMaxLength = this.maxRecord
    //   }

    //   this.dTextRecordAllowReplica = record.allowReplica
    // },

    setLeftTab (tabId) {
      switch (tabId) {
        case '':
          break;
        case 'settings-main-tab':
          this.leftPanelActiveTab = 0
          break ;
        case 'add-main-tab':
          this.leftPanelActiveTab = 1
          break;
        case 'edit-main-tab':
          this.leftPanelActiveTab = 2
          break;
        case 'template-main-tab':
          this.leftPanelActiveTab = 3
          break;
        case 'advanced-main-tab':
          this.leftPanelActiveTab = 4
          break;
      }
    },


  }
}
