import fetch from '../../../../utils/fetch'
import * as types from './mutation_types'

export const sendRequest = ({ commit }, item, page = '/users/password') => {
  commit(types.FORGOT_PASSWORD_TOGGLE_LOADING)

  return fetch(page, { method: 'POST', body: JSON.stringify(item) })
    .then(response => {})
    .then((data) => {
      commit(types.FORGOT_PASSWORD_TOGGLE_LOADING)
      commit(types.FORGOT_PASSWORD_SET_SENT, true)
    })
    .catch((e) => {
      commit(types.FORGOT_PASSWORD_TOGGLE_LOADING)
      commit(types.FORGOT_PASSWORD_SET_ERROR, e.message)
    })
}

export const reset = ({ commit }) => {
  commit(types.FORGOT_PASSWORD_RESET)
}
