<template>
  <div v-if="organizationId">
    <div class="card-body">
      <b-row align-h="between" no-gutters>
        <b-col cols="3">
          <h3 class="">Resources</h3>
        </b-col>
        <b-col cols="6" class="mb-3">
          <b-input-group size="md">
            <b-input-group-prepend>
              <span class="btn btn-secondary" title="Search by resource name">
                <i v-if="!isBusy" class="fas fa-search"></i>
                <i v-if="isBusy" class="fas fa-spinner fa-spin"></i>
              </span>
            </b-input-group-prepend>
            <b-form-input
              class="form-control"
              placeholder="Search by Resource Name"
              name="search"
              type="search"
              v-model="searchText"
              debounce="1000"
            ></b-form-input>
            <b-input-group-append v-if="searchText">
              <b-button @click="searchText = ''"
                ><i class="fas fa-times-circle"></i
              ></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col class="mb-3" v-if="isManager">
          <div class="flex-shrink-1 text-right">
            <button class="btn btn-primary" @click="$bvModal.show('create-org-resource')">
              <i class="fa fa-plus text-white"></i>
              New Resource
            </button>
          </div>
        </b-col>
      </b-row>

      <b-row align-h="between">
        <b-col cols="3" md="3" class="my-1">
          Showing {{ fromItem }} to {{ toItem }} of
          {{ totalItems }} resource<span
            v-if="totalItems === 0 || totalItems > 1"
            >s</span
          >
        </b-col>
        <b-col sm="2" md="3" class="my-1">
          <b-pagination
            :total-rows="totalItems"
            :per-page="perPage"
            v-model="currentPage"
            class="my-0 pagination-holder"
            prev-text="Prev"
            next-text="Next"
            align="fill"
          />
        </b-col>
        <b-col sm="2" md="3" class="my-1">
          <b-form-group
            label="Per Page"
            label-align="right"
            label-cols-sm="7"
            label-cols-md="8"
            label-for="perPageSelect"
            class="mb-0"
          >
            <b-form-select
              v-model="perPage"
              id="perPageSelect"
              size="sm"
              :options="pageOptions"
              align="right"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <b-table
      show-empty
      stacked="md"
      id="resourcesTable"
      class="mb-0"
      ref="resourcesTable"
      :filter="`name=${searchText}`"
      :busy="isBusy"
      :items="resources"
      :fields="tableFields"
      :current-page="currentPage"
      :per-page="perPage"
      sort-icon-left
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
    >
      <template v-slot:cell(options)="row">
        <div>
          <b-btn
            class="mt-1 mr-1"
            @click="onViewResource(row.item)"
            alt="View Resource"
            title="View Resource"
          >
            <font-awesome-icon icon="eye" />&nbsp;&nbsp;View
          </b-btn>
          <b-btn v-if="isManager"
                 class="mt-1 mr-1" @click="onEditResource(row.item)" alt="Edit this Resource"
                 title="Edit this Resource">
            <i class="fa fa-pencil"></i>&nbsp;&nbsp;Edit
          </b-btn>
          <b-btn
            v-if="isManager"
            class="mt-1"
            @click="onClickDelete(row.item)"
            alt="Delete this Resource from the Hub"
            title="Delete this Resource from the Hub"
          >
            <i class="fa fa-trash"></i>&nbsp;&nbsp;Delete
          </b-btn>
        </div>
      </template>
      <template #table-busy>
        <VueLoader style="height: 200px" text="Loading Resources..." />
      </template>
      <template #empty>
        <h6>{{ emptyText }}</h6>
      </template>
      <template #emptyfiltered>
        <h6 class="center" v-if="searchText.length>0">{{ emptyFilteredText }}</h6>
        <h6 class="center" v-else>{{ emptyText }}</h6>
      </template>
    </b-table>
    <div class="card-body border-top">
      <b-row align-h="between">
        <b-col cols md="3" class="my-1">
          Showing {{ fromItem }} to {{ toItem }} of
          {{ totalItems }} resource<span
            v-if="totalItems === 0 || totalItems > 1"
            >s</span>
        </b-col>

        <b-col sm="2" md="3" class="my-1">
          <b-pagination
            :total-rows="totalItems"
            :per-page="perPage"
            v-model="currentPage"
            class="my-0 pagination-holder"
            prev-text="Prev"
            next-text="Next"
            align="fill"
          />
        </b-col>

        <b-col sm="2" md="3" class="my-1">
          <b-form-group
            label="Per Page"
            label-align="right"
            label-cols-sm="7"
            label-cols-md="8"
            label-for="perPageSelect"
            class="mb-0"
          >
            <b-form-select
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              align="right"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <b-modal id="deleteResourceModal" title="Delete Resource" centered>
      <div v-if="selectedResource">
        <p class="my-4">
          Do you want to remove this resource from the project? This cannot be
          undone.
        </p>
        <div>
          <span class="font-weight-bold">Date Uploaded: </span>
          {{ displayDate(selectedResource.createdAt) }}
        </div>
        <div>
          <span class="font-weight-bold">Resource Name: </span>
          {{ selectedResource.name }}
        </div>
      </div>
      <template slot="modal-footer">
        <div>
          <b-btn variant="danger" @click="onConfirmDelete()">Yes, delete</b-btn>
          <b-btn @click="closeModal">No</b-btn>
        </div>
      </template>
    </b-modal>
    <create-org-resource-modal @refresh-table="reloadTable"></create-org-resource-modal>
    <edit-org-resource-modal :resource="getResourceToEdit" @refresh-table="reloadTable"></edit-org-resource-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VueLoader from "../../../layout/Loader";
import CreateOrgResourceModal from './createOrgResourceModal.vue'
import EditOrgResourceModal from "./editOrgResourceModal.vue";

export default {
  name: "OrganizationResources",
  components: {
    VueLoader,
    CreateOrgResourceModal,
    EditOrgResourceModal
  },
  data() {
    return {
      pageOptions: [10, 25, 50, 100, 250, 500],
      isBusy: false,
      showModal: null,
      selectedResource: null,
      emptyText: "No Resources Available",
      emptyFilteredText: "No Resources Available for Search Criteria",
      currentPage: 1,
      perPage: 10,
      totalItems: 0,
      searchText: "",
      sortBy: "createdAt",
      sortDesc: true,
      sortDirection: "desc",
      tableFields: [
        { key: "name", label: "Name", sortable: true },
        { key: "type", label: "Resource Type", sortable: false },
        {
          key: "createdAt",
          label: "Date Uploaded",
          sortable: true,
          formatter: val => {
            return this.displayDate(val);
          }
        },
        {
          key: "options",
          label: "Options",
          sortable: false,
          tdClass: "color-blue",
          thStyle: { width: "280px!important" }
        }
      ],
      resourceToEdit: null,
    };
  },
  computed: {
    ...mapGetters({
      membershipMap: "user/profile/organizationMembershipMap",
      organization: "organization/get/organization",
      organizationId: "organization/get/id"
    }),
    isManager() {
      return (
        this.organization &&
        this.membershipMap[this.organizationId] &&
        this.membershipMap[this.organizationId].isManager
      );
    },
    fromItem() {
      return this.totalItems > 0
        ? (this.currentPage - 1) * this.perPage + 1
        : 0;
    },
    toItem() {
      return this.totalItems < this.currentPage * this.perPage
        ? this.totalItems
        : this.currentPage * this.perPage;
    },
    getResourceToEdit() {
      return this.resourceToEdit
    }
  },
  methods: {
    ...mapActions({
      fetchResources: "organization/resources/get",
      deleteResource: "organization/resources/deleteResource"
    }),
    resources(ctx) {
      this.isBusy = true;
      let urlParams = `${this.urlBuilder(ctx)}`;
      const data = { id: this.organization.url, urlParams };
      return this.fetchResources(data).then(data => {
        this.isBusy = false;
        if (data) {
          this.totalItems = data["hydra:totalItems"];
          this.currentPage = ctx.currentPage || 1;
          this.sortBy = ctx.sortBy || "";
          return data["hydra:member"].map(resource => {
            return {
              ...resource
            };
          });
        } else {
          return []
        }
      });
    },
    onViewResource(resource) {
      let href = "";
      if (resource.type === "link") {
        href = resource.url;
      } else if (resource.type === "file") {
        href = resource.file.path;
      }
      window.open(href, "_blank");
    },
    onEditResource(resource) {
      this.resourceToEdit = {...resource}
      this.$bvModal.show('edit-org-resource')
    },
    reloadTable() {
      this.$refs.resourcesTable.refresh();
    },
    closeModal() {
      this.$bvModal.hide("deleteResourceModal");
      this.selectedResource = null;
    },
    onClickDelete(resource) {
      this.selectedResource = { ...resource };
      this.$bvModal.show("deleteResourceModal");
    },
    onConfirmDelete() {
      this.deleteResource({ id: this.selectedResource.id }).then(() => {
        this.reloadTable()
      });
      this.closeModal();
    }
  }
};
</script>

<style class="scss"></style>
