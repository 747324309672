import { mapActions, mapGetters } from 'vuex'
import VueLoader from '../../../components/layout/Loader'

export default {
  created() {

  },

  computed: {
    isLoading(){
      return true;
    }
  },

  methods: {
    ...mapActions({
      preparePage: 'features/show/preparePage'
    })
  },

  components: {
    VueLoader
  }
}
