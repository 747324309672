<template>
  <div>
    <h3>Projects</h3>
    <b-row v-if="projects && Object.keys(projects).length > 0" class="section justify-center">
      <PhotoProjectCard
        v-for="(project, index) in projects"
        :key=index
        :project=project>
      </PhotoProjectCard>
    </b-row>
    <span v-else>No projects affiliated...</span>
  </div>
</template>
<script>
// import PhotoProjectCard from "../../../layout/PhotoProjectCard";
import PhotoProjectCard from "./ProjectCard";
import {mapActions, mapGetters} from "vuex";
import PhotoObservationCard from "../../../layout/PhotoObservationCard.vue";

export default {
  components: {
    PhotoObservationCard,
    PhotoProjectCard
  },
  computed: {
    ...mapGetters({
      projects: "organization/get/projects"
    })
  }
};
</script>
<style lang="scss" scoped></style>
