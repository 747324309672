<template>
  <div>
    <div v-if="isPublicOrMember">
      <div class="card-body">
        <div class="row justify-content-between mb-3">
          <div class="input-group col-xs-6 col-sm-7 col-md-9">
            <b-input-group-prepend>
              <span
                class="btn btn-secondary"
                title="Search by Resource Name"
              >
                <i v-if="!isBusy" class="fas fa-search"></i>
                <i v-if="isBusy" class="fas fa-spinner fa-spin"></i>
              </span>
            </b-input-group-prepend>
            <b-form-input
              placeholder="Search by Resource Name"
              name="search"
              type="search"
              v-model="searchText"
              debounce="1000"
            ></b-form-input>
            <div class="input-group-append" v-if="searchText">
              <b-button @click="searchText = ''"
              ><i class="fas fa-times-circle"></i>
              </b-button>
            </div>
          </div>
          <div v-if="isManager" class="col-xs-6 col-sm-5 col-md-3 text-right">
            <button class="btn btn-primary" @click="$bvModal.show('create-project-resource')">
              <i class="fa fa-plus text-white"></i>
              New Resource
            </button>
          </div>
        </div>

        <div class="row justify-content-between">
          <b-col md="3" class="my-1">
            Showing {{ fromItem }} to {{ toItem }} of
            {{ totalItems }} resource<span
            v-if="totalItems === 0 || totalItems > 1"
          >s</span
          >
          </b-col>

          <b-col sm="2" md="3" class="my-1">
            <b-pagination
              :total-rows="totalItems"
              :per-page="perPage"
              v-model="currentPage"
              class="my-0 pagination-holder"
              prev-text="Prev"
              next-text="Next"
              align="fill"
            />
          </b-col>

          <b-col sm="2" md="3" class="my-1">
            <b-form-group
              label="Per Page"
              label-align="right"
              label-cols-sm="7"
              label-cols-md="8"
              label-for="perPageSelect"
              class="mb-0"
            >
              <b-form-select
                v-model="perPage"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
        </div>
      </div>

      <b-table v-show="!resourceShowCard"
               show-empty
               stacked="md"
               id="projectResourcesTable"
               ref="projectResourcesTable"
               :filter="`name=${searchText}`"
               :busy="isBusy"
               :items="resources"
               :fields="tableFields"
               :current-page="currentPage"
               :per-page="perPage"
               sort-icon-left
               :sort-by.sync="sortBy"
               :sort-desc.sync="sortDesc"
               :sort-direction="sortDirection"
      >
        <template #cell(file.mimeType)="data">
          <b-badge v-if="data.value" variant="secondary">{{ data.value }}</b-badge>
        </template>
        <template v-slot:cell(options)="row">
          <div>
            <b-btn
              class="mt-1 mr-1"
              @click="onViewResource(row.item)"
              alt="View Resource"
              title="View Resource"
            >
              <font-awesome-icon icon="eye"/>&nbsp;&nbsp;View
            </b-btn>
            <b-btn
              v-if="isManager"
              class="mt-1 mr-1"
              @click="onEditResource(row.item)"
              alt="Edit this Resource"
              title="Edit this Resource"
            >
              <i class="fa fa-pencil"></i>&nbsp;&nbsp;Edit
            </b-btn>
            <b-btn
              v-if="isManager"
              class="mt-1"
              @click="onClickDelete(row.item)"
              alt="Delete this Resource from the Project"
              title="Delete this Resource from the Project"
            >
              <i class="fa fa-trash"></i>&nbsp;&nbsp;Delete
            </b-btn>
          </div>
        </template>
        <template #table-busy>
          <VueLoader style="height: 200px" text="Loading Resources..."/>
        </template>
      </b-table>

      <div v-show="resourceShowCard">
        <div v-if="isBusy">
          <i class="fa fa-spin fa-spinner fa-3x mr-3"></i> Loading resources...
        </div>
        <div v-else>
          <div class="row justify-content-center mb-2 m-0">
            <div class="col-xs-6 col-md-6">
              <b-form-group
                label="Sort By: "
                label-for="sort-by-select"
                label-cols-sm="2"
                label-cols-md="2"
                label-align-sm="right"
                label-size="sm"
                class="mb-0 font-weight-bold"
                v-slot="{ ariaDescribedby }"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sort-by-select"
                    v-model="sortBy"
                    :options="sortOptions"
                    :aria-describedby="ariaDescribedby"
                    class="col-xs-8 col-md-10"
                  >
                  </b-form-select>

                  <b-form-select
                    v-model="sortDesc"
                    :disabled="!sortBy"
                    :aria-describedby="ariaDescribedby"
                    size="sm"
                    class="col-xs-4 col-md-2"
                  >
                    <option :value="false">asc</option>
                    <option :value="true">desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="mb-2"
                 v-for="resourceValue in cards"
                 :key="resourceValue.id">
              <ResourceCard :resource=resourceValue
                            :handle-view="onViewResource"
                            :handle-edit="onEditResource"
                            :handle-delete="onClickDelete"
              ></ResourceCard>
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <div class="row justify-content-between">
          <b-col md="3" class="my-1">
            Showing {{ fromItem }} to {{ toItem }} of
            {{ totalItems }} resource<span
            v-if="totalItems === 0 || totalItems > 1"
          >s</span
          >
          </b-col>

          <b-col sm="2" md="3" class="my-1">
            <b-pagination
              :total-rows="totalItems"
              :per-page="perPage"
              v-model="currentPage"
              class="my-0 pagination-holder"
              prev-text="Prev"
              next-text="Next"
              align="fill"
            />
          </b-col>

          <b-col sm="2" md="3" class="my-1">
            <b-form-group
              label="Per Page"
              label-align="right"
              label-cols-sm="7"
              label-cols-md="8"
              label-for="perPageSelect"
              class="mb-0"
            >
              <b-form-select
                v-model="perPage"
                id="perPageSelect"
                size="sm"
                :options="pageOptions"
              >
              </b-form-select>
            </b-form-group>
          </b-col>
        </div>
      </div>

      <b-modal id="deleteResourceModal" title="Delete Resource" centered>
        <div v-if="selectedResource">
          <p class="my-4">
            Do you want to remove this resource from the project? This cannot be
            undone.
          </p>
          <div>
            <span class="font-weight-bold">Date Uploaded: </span>
            {{ displayDate(selectedResource.createdAt) }}
          </div>
          <div>
            <span class="font-weight-bold">Resource Name: </span>
            {{ selectedResource.name }}
          </div>
        </div>
        <template slot="modal-footer">
          <div>
            <b-btn variant="danger" @click="onConfirmDelete()">Yes, delete</b-btn>
            <b-btn @click="closeModal">No</b-btn>
          </div>
        </template>
      </b-modal>
      <CreateProjectResourceModal @refresh-table="reloadTable"></CreateProjectResourceModal>
      <EditProjectResourceModal :resource="getResourceToEdit" @refresh-table="reloadTable"></EditProjectResourceModal>
    </div>
    <RequiresMember v-else page="Resources"></RequiresMember>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import VueLoader from "../../../layout/Loader";
import CreateResourceModal from "../../CreateResourceModal";
import EditResource from "../../EditResource";
import RequiresMember from "../tabpermission/requiresMember.vue";
import CreateProjectResourceModal from "./createProjectResourceModal.vue";
import EditProjectResourceModal from "./editProjectResourceModal.vue";
import ResourceCard from "./resourceCard.vue";

export default {
  name: "ResourcesTable",
  components: {
    ResourceCard,
    CreateProjectResourceModal,
    EditProjectResourceModal,
    RequiresMember,
    VueLoader,
    CreateResourceModal,
    EditResource
  },
  props: {},
  data() {
    return {
      pageOptions: [10, 25, 50, 100, 250, 500],
      isBusy: false,
      showModal: null,
      selectedResource: null,
      // currentPage: 1,
      // perPage: 10,
      // totalItems: 0,
      // searchText: "",
      // sortBy: "updatedAt",
      // sortDesc: true,
      // sortDirection: "desc",
      tableFields: [
        {key: "name", label: "Name", sortable: true},
        {
          key: "type",
          label: "Resource Type",
          sortable: false,
          formatter: val => {
            return val.charAt(0).toUpperCase() + val.slice(1)
          }
        },
        {
          key: "file.mimeType",
          label: "File Type",
          sortable: true,
          formatter: val => {
            // Convert mime type to simpler values
            // - For example application/pdf to just pdf
            return this.convertMimeType(val);
          }
        },
        {
          key: "updatedAt",
          label: "Date Updated",
          sortable: true,
          formatter: val => {
            return this.displayDate(val);
          }
        },
        {
          key: "options",
          label: "Options",
          sortable: false,
          class: "text-right",
          thStyle: {width: "280px!important"}
        }
      ],
      resourceToEdit: null
    };
  },
  computed: {
    ...mapGetters({
      projectId: "project/get/id",
      isPublicOrMember: "project/get/isPublicOrMember",
      isManager: "project/get/isManager",
      resourcesCard: "project/resources/resources",

      resourceShowCard: "project/get/resourceShowCard",
      resourcePage: "project/get/resourcePage",
      resourceItems: "project/get/resourceItems",
      resourceTotal: "project/get/resourceTotal",
      resourceSearch: "project/get/resourceSearch",
      resourceSortBy: "project/get/resourceSortBy",
      resourceSortDirection: "project/get/resourceSortDirection",
      resourceSortDesc: "project/get/resourceSortDesc",
    }),
    currentPage: {
      get() {
        return this.resourcePage
      },
      set(newValue) {
        this.setResourceTablePage(newValue)
      }
    },
    totalItems: {
      get() {
        return this.resourceTotal
      },
      set(newValue) {
        this.setResourceTableTotal(newValue)
      }
    },
    perPage: {
      get() {
        return this.resourceItems
      },
      set(newValue) {
        this.setResourceTableItems(newValue)
      }
    },
    searchText: {
      get() {
        return this.resourceSearch
      },
      set(newValue) {
        this.setResourceTableSearch(newValue)
      }
    },
    sortBy: {
      get() {
        return this.resourceSortBy
      },
      set(newValue) {
        this.setResourceSortBy(newValue)
      }
    },
    sortDirection: {
      get() {
        return this.resourceSortDirection
      },
      set(newValue) {
        this.setResourceSortDirection(newValue)
      }
    },
    sortDesc: {
      get() {
        return this.resourceSortDesc
      },
      set(newValue) {
        this.setResourceSortDesc(newValue)
      }
    },

    fromItem() {
      return this.totalItems > 0
        ? (this.currentPage - 1) * this.perPage + 1
        : 0;
    },
    toItem() {
      return this.totalItems < this.currentPage * this.perPage
        ? this.totalItems
        : this.currentPage * this.perPage;
    },

    filterVal: {
      get: function () {
        // This is our multi-filter computed value
        // - search observations by location name
        //More filters can be added here
        // - also add an equivalent to the store

        let retVal = ''
        if (this.searchText.length > 0) {
          retVal = retVal.length === 0 ? 'name=' + this.searchText : retVal + '&name=' + this.searchText
        }
        return retVal
      }
    },
    cards() {
      if (this.resourcesCard) {
        return this.resourcesCard['hydra:member']
      } else {
        return []
      }
    },
    getResourceToEdit() {
      return this.resourceToEdit
    },
    sortOptions() {
      // Create an options list from our fields
      return this.tableFields
        .filter(f => f.sortable)
        .map(f => {
          return {text: f.label, value: f.key}
        })
    }
  },
  methods: {
    ...mapActions({
      fetchResources: "project/resources/get",
      deleteResource: "project/resources/delete",

      setResourceTablePage: "project/get/setResourceTablePage",
      setResourceTableItems: "project/get/setResourceTableItems",
      setResourceTableTotal: "project/get/setResourceTableTotal",
      setResourceTableSearch: "project/get/setResourceTableSearch",
      setResourceSortBy: "project/get/setResourceTableSortBy",
      setResourceSortDirection: "project/get/setResourceTableSortDirection",
      setResourceSortDesc: "project/get/setResourceTableSortDesc",
    }),
    resources(ctx) {
      this.isBusy = true;
      let urlParams = `${this.urlBuilder(ctx)}`;
      const data = {urlParams};
      return this.fetchResources(data).then(data => {
        this.totalItems = data["hydra:totalItems"];
        this.currentPage = ctx.currentPage || 1;
        this.sortBy = ctx.sortBy || "";
        this.isBusy = false;
        return data["hydra:member"].map(resource => {
          return {
            ...resource
          };
        });
      });
    },
    onViewResource(resource) {
      let href = "";
      if (resource.type === "file") {
        href = resource.file.path;
      } else {
        href = resource.url;
      }
      window.open(href, "_blank");
    },
    onEditResource(resource) {
      this.resourceToEdit = {...resource}
      this.$bvModal.show('edit-project-resource')
    },
    onSaveEditResource() {
      this.$refs.projectResourcesTable.refresh();
    },
    reloadTable() {
      this.$refs.projectResourcesTable.refresh();
    },
    closeModal() {
      this.$bvModal.hide("deleteResourceModal");
      this.selectedResource = null;
    },
    onClickDelete(resource) {
      this.selectedResource = {...resource};
      this.$bvModal.show("deleteResourceModal");
    },
    onConfirmDelete() {
      this.deleteResource({id: this.selectedResource.id}).then(() => {
        this.$refs.projectResourcesTable.refresh();
      });
      this.closeModal();
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
